import { gql } from "@apollo/client";

export const DRIVER_FRAGMENT = gql`
  fragment DriverFragment on Driver {
    id
    address
    createdAt
    driverCode
    email
    mobile
    name
    profileImageUrl
    status
    updatedAt
  }
`;

export const GET_DRIVERS = gql`
  ${DRIVER_FRAGMENT}
  query FetchDrivers($status: Boolean) {
    fetchDrivers(status: $status) {
      ...DriverFragment
    }
  }
`;

export const GET_DRIVER_BY_ID = gql`
  ${DRIVER_FRAGMENT}
  query FetchDriver($id: ID!) {
    fetchDriver(id: $id) {
      ...DriverFragment
    }
  }
`;

export const REMOVE_DRIVER = gql`
  ${DRIVER_FRAGMENT}
  mutation DriverDelete($id: ID!) {
    driverDelete(input: { id: $id }) {
      driver {
        ...DriverFragment
      }
    }
  }
`;

export const ADD_DRIVER = gql`
  ${DRIVER_FRAGMENT}
  mutation driverCreate(
    $name: String!
    $email: String!
    $mobile: String
    $address: String
    $profileImageUrl: String
    $status: Int!
  ) {
    driverCreate(
      input: {
        params: {
          name: $name
          email: $email
          mobile: $mobile
          address: $address
          profileImageUrl: $profileImageUrl
          status: $status
        }
      }
    ) {
      driver {
        ...DriverFragment
      }
    }
  }
`;

export const EDIT_DRIVER = gql`
  ${DRIVER_FRAGMENT}
  mutation DriverUpdate(
    $id: ID!
    $name: String!
    $email: String!
    $mobile: String
    $address: String
    $profileImageUrl: String
    $status: Int!
  ) {
    driverUpdate(
      input: {
        id: $id
        params: {
          name: $name
          email: $email
          mobile: $mobile
          address: $address
          profileImageUrl: $profileImageUrl
          status: $status
        }
      }
    ) {
      driver {
        ...DriverFragment
      }
    }
  }
`;

export enum DriverStatus {
  INACTIVE,
  SEND_REQUEST,
  APPROVE_ACTIVE,
  BANNED,
}
export type Driver = {
  id: string;
  address: string;
  driverCode: string;
  email: string;
  mobile: string;
  name: string;
  profileImageUrl: string;
  status: DriverStatus;
  createdAt: string;
  updatedAt: string;
};
