import { classNames } from "../../utils";

interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  progress?: number;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: "round" | "square";
  spinnerMode?: boolean;
  spinnerSpeed?: number;
}
export const ProgressBar = ({
  size = 60,
  progress = 0,
  trackWidth = 6,
  trackColor = `rgba(0,0,0,0.1)`,
  indicatorWidth = 8,
  indicatorColor = `rgb(var(--color-primary))`,
  indicatorCap = `round`,
  spinnerMode = false,
  spinnerSpeed = 1,
  className = "",
  ...props
}: ProgressBarProps) => {
  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  let hideLabel = spinnerMode ? true : false;

  return (
    <>
      <div
        className={classNames("relative", className)}
        style={{ width: size, height: size }}
        {...props}
      >
        <svg className="-rotate-90" style={{ width: size, height: size }}>
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <circle
            className={`${spinnerMode ? "origin-center animate-spin" : ""}`}
            style={{ animationDuration: String(spinnerSpeed * 1000) }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>

        {!hideLabel && (
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
            {!spinnerMode && (
              <span className="block font-medium">
                {`${progress > 100 ? 100 : progress}%`}
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressBar;
