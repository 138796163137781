import { gql } from "@apollo/client/core";

export const GET_PRICINGLEVELS = gql`
  query FetchPricingLevels {
    fetchPricingLevels {
      id
      name
      pricingMethod {
        id
        name
      }
      productType
      products {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export const REMOVE_PRICINGLEVEL = gql`
  mutation PricingLevelDelete($id: ID!) {
    pricingLevelDelete(input: { id: $id }) {
      pricingLevel {
        id
        name
      }
    }
  }
`;

export const ADD_PRICINGLEVEL = gql`
  mutation PricingLevelCreate(
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingLevelCreate(
      input: {
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingLevel {
        id
        name
        pricingMethod {
          id
          name
        }
        productType
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const EDIT_PRICINGLEVEL = gql`
  mutation PricingLevelUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingLevelUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingLevel {
        id
        name
        pricingMethod {
          id
          name
        }
        productType
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const GET_PRICINGLEVEL_BY_ID = gql`
  query FetchPricingLevel($id: ID!) {
    fetchPricingLevel(id: $id) {
      id
      name
      pricingMethod {
        id
        name
      }
      productType
      products {
        id
        name
        featureImageUrl
        status
      }
      createdAt
      status
    }
  }
`;

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

export type PricingMethod = {
  id?: string;
  name: string;
  handle: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type Product = {
  id: string;
  name: string;
};

export type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: Product[];
  createdAt: string;
  status: boolean;
};
