import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_FLEETVENDOR,
  EDIT_FLEETVENDOR,
  FleetVendor,
  FleetVendorForm,
  GET_FLEETVENDOR_BY_ID,
  GET_FLEETVENDORS,
} from "../../../../graphql/fleets/settings/vendors";
import Form from "./components/Form";

const FleetVendorUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { fleetVendorId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchFleetVendor: FleetVendor;
  }>(GET_FLEETVENDOR_BY_ID, {
    variables: {
      id: fleetVendorId,
    },
  });
  const fleetVendor = useMemo<FleetVendor | undefined>(
    () => data?.fetchFleetVendor,
    [data]
  );

  const [updateFleetVendor] = useMutation(EDIT_FLEETVENDOR, {
    refetchQueries: [{ query: GET_FLEETVENDORS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: FleetVendorForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateFleetVendor({
      variables: {
        id: fleetVendorId,
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        webAddress: values.webAddress,
        address: values.address,
        latitude: values.latitude,
        longitude: values.longitude,
        notes: values.notes,
        contactName: values.contactName,
        contactPhoneNumber: values.contactPhoneNumber,
        classification: values.classification,
        profileImageUrl: values.profileImageUrl,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.fleetVendorUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vendor updated successfully",
          });
          return navigate(`/fleets/settings/vendors`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vendor update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Vendor update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={FleetVendorUpdateResource.name}
        heading="Vendors"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Fleet Settings",
            href: "/fleets/settings",
          },
          {
            name: "Vendors",
            href: "/fleets/settings/vendors",
          },
          {
            name: FleetVendorUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_fleet_vendor")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_fleet_vendor")}
              </p>
            </div>
          </div>

          {loading || !fleetVendor ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                name: fleetVendor?.name ?? "",
                email: fleetVendor?.email ?? "",
                phoneNumber: fleetVendor?.phoneNumber ?? "",
                webAddress: fleetVendor?.webAddress ?? "",
                address: fleetVendor?.address ?? "",
                latitude: fleetVendor?.latitude ?? "",
                longitude: fleetVendor?.longitude ?? "",
                notes: fleetVendor?.notes ?? "",
                contactName: fleetVendor?.contactName ?? "",
                contactPhoneNumber: fleetVendor?.contactPhoneNumber ?? "",
                classification: fleetVendor?.classification ?? [],
                profileImageUrl: fleetVendor?.profileImageUrl ?? "",
                status: fleetVendor?.status ?? false,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FleetVendorUpdate;
export const FleetVendorUpdateResource: ResourceProps = {
  name: "Edit Vendor",
  description: "Update an existing vendor",
  access: ["update-fleetVendors"],
  path: "vendors/:fleetVendorId",
};
