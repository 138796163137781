import { gql } from "@apollo/client";

export const GET_PERMISSIONS = gql`
  query FetchPermissions {
    fetchPermissions {
      id
      permissionName
      permissionDisplayName
      permissionDescription
      dependencies
      createdAt
      status
      resource {
        id
        resourceName
        resourceDescription
        status
      }
    }
  }
`;

export const EDIT_PERMISSION = gql`
  mutation PermissionUpdate(
    $id: ID!
    $permissionName: String!
    $permissionDisplayName: String!
    $permissionDescription: String!
    $dependencies: [Int!]
    $resourceId: Int!
    $status: Boolean!
  ) {
    permissionUpdate(
      input: {
        id: $id
        params: {
          permissionName: $permissionName
          permissionDisplayName: $permissionDisplayName
          permissionDescription: $permissionDescription
          dependencies: $dependencies
          resourceId: $resourceId
          status: $status
        }
      }
    ) {
      permission {
        id
        permissionName
        permissionDisplayName
        permissionDescription
        dependencies
        createdAt
        status
        resource {
          id
          resourceName
          resourceDescription
          status
        }
      }
    }
  }
`;

export const ADD_PERMISSION = gql`
  mutation PermissionCreate(
    $permissionName: String!
    $permissionDisplayName: String!
    $permissionDescription: String!
    $dependencies: [Int!]
    $resourceId: Int!
    $status: Boolean!
  ) {
    permissionCreate(
      input: {
        params: {
          permissionName: $permissionName
          permissionDescription: $permissionDescription
          permissionDisplayName: $permissionDisplayName
          dependencies: $dependencies
          resourceId: $resourceId
          status: $status
        }
      }
    ) {
      permission {
        id
        permissionName
        permissionDisplayName
        permissionDescription
        dependencies
        createdAt
        status
        resource {
          id
          resourceName
          resourceDescription
          status
        }
      }
    }
  }
`;

export const REMOVE_PERMISSION = gql`
  mutation PermissionDelete($id: ID!) {
    permissionDelete(input: { id: $id }) {
      permission {
        id
        permissionName
      }
    }
  }
`;
