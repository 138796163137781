import { gql } from "@apollo/client";

import { UserModel } from "../../../modules/auth/@types/auth";
import { PackingVerificationStatus } from "../../pickpack/packing";
import { Customer } from "../../sales/customers";
import { Order, OrderStatus } from "../../sales/orders";
import { Driver } from "../settings/drivers";

export const DELIVERY_ALLOCATION_FRAGMENT = gql`
  fragment DeliveryAllocationFragment on Deliveryallocation {
    id
    orderNumber
    purchaseNumber
    packingNo
    packingDate
    deliveryRun
    deliveryDate
    loadingDock
    packingVerificationStatus
    loadingDock
    boxCount
    notes
    operator {
      id
      name
      profileImageUrl
    }
    customer {
      id
      customerName
    }
    customerId
    status
    createdAt
  }
`;

export const GET_DELIVERY_ALLOCATIONS = gql`
  ${DELIVERY_ALLOCATION_FRAGMENT}
  query FetchDeliveryAllocations(
    $searchQuery: String!
    $searchFilter: [String!]
    $deliveryrunId: ID
    $fromDate: String
    $toDate: String
  ) {
    fetchDeliveryAllocations(
      searchQuery: $searchQuery
      searchFilter: $searchFilter
      deliveryrunId: $deliveryrunId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      ...DeliveryAllocationFragment
    }
  }
`;

export const GET_DELIVERY_ALLOCATION = gql`
  ${DELIVERY_ALLOCATION_FRAGMENT}
  query FetchDeliveryAllocation($id: ID!) {
    fetchDeliveryAllocation(id: $id) {
      ...DeliveryAllocationFragment
    }
  }
`;

export const GET_DELIVERY_ALLOCATIONS_ID_BY_OPERATOR = gql`
  ${DELIVERY_ALLOCATION_FRAGMENT}
  query FetchAllocationIdByOperator($operatorId: ID!) {
    fetchAllocationByOperator(operatorId: $operatorId) {
      ...DeliveryAllocationFragment
    }
  }
`;

export const UPDATE_OPERATOR_ALLOCATION = gql`
  mutation OperatorAllocationUpdate($ids: [ID!]!, $operatorId: ID!) {
    operatorAllocationUpdate(input: { ids: $ids, operatorId: $operatorId }) {
      message
    }
  }
`;

export enum AllocationMessageType {
  READY,
  NORMAL,
  MODIFIED,
  COMPLETED,
}

export type DeliveryAllocationMessage = {
  id: string;
  comments: string;
  messageType: AllocationMessageType;
  order: Pick<Order, "id" | "orderNumber" | "purchaseNumber">;
  orderId: number;
  user: Pick<UserModel, "id" | "fullName" | "firstName" | "lastName">;
  userId: number;
  createdAt: string;
};

export type DeliveryAllocation = {
  id: string;
  boxCount: number;
  customerId: string;
  customer: Pick<Customer, "id" | "customerName">;
  deliveryDate: string;
  deliveryRun: string | null;
  loadingDock: string;
  notes: string;
  operator: Pick<Driver, "id" | "name"> | null;
  orderNumber: string;
  packingDate: string;
  packingNo: string;
  packingVerificationStatus: PackingVerificationStatus;
  purchaseNumber: string;
  status: OrderStatus;
};
