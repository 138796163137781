import { gql } from "@apollo/client";

export const FETCH_GENERAL_SETTINGS = gql`
  query FetchGeneralSettings {
    fetchGeneralSettings {
      id
      senderEmail
      address
      postcode
      state
      suburb
      zonePicking
    }
  }
`;

export const CREATE_GENERAL_SETTING = gql`
  mutation GeneralSettingCreate(
    $senderEmail: String!
    $address: String
    $suburb: String
    $state: String
    $postcode: String
    $zonePicking: Int
  ) {
    generalSettingCreate(
      input: {
        params: {
          senderEmail: $senderEmail
          address: $address
          suburb: $suburb
          state: $state
          postcode: $postcode
          zonePicking: $zonePicking
        }
      }
    ) {
      general {
        id
        senderEmail
        address
        postcode
        state
        suburb
        zonePicking
      }
    }
  }
`;

export const UPDATE_GENERAL_SETTING = gql`
  mutation GeneralSettingUpdate(
    $id: ID!
    $senderEmail: String!
    $address: String
    $suburb: String
    $state: String
    $postcode: String
    $zonePicking: Int
  ) {
    generalSettingUpdate(
      input: {
        id: $id
        params: {
          senderEmail: $senderEmail
          address: $address
          suburb: $suburb
          state: $state
          postcode: $postcode
          zonePicking: $zonePicking
        }
      }
    ) {
      general {
        id
        senderEmail
        address
        postcode
        state
        suburb
        zonePicking
      }
    }
  }
`;

export enum ZonePicking {
  MANUAL,
  AUTOMATIC,
}
export type General = {
  id: string;
  senderEmail: string;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  zonePicking: ZonePicking;
};
