import { useMutation, useQuery } from "@apollo/client/react";
import { useContext, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import { GenContext } from "../../../../contexts/GenContext";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  ADD_VEHICLELIFECYCLE,
  EDIT_VEHICLELIFECYCLE,
  GET_VEHICLELIFECYCLE_BY_VEHICLE_ID,
  VehicleLifecycle,
  VehicleLifecycleForm,
} from "../../../../graphql/fleets/vehicles/pages/lifecycle";
import Form from "../components/LifecycleForm";

const Lifecycle = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const { vehicleForm, storeVehicleForm } = useContext(GenContext);

  const { data, loading, error, refetch } = useQuery<{
    fetchLifecycleByVehicle: VehicleLifecycle;
  }>(GET_VEHICLELIFECYCLE_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });
  const lifecycle = useMemo<VehicleLifecycle | null>(
    () => data?.fetchLifecycleByVehicle || null,
    [data]
  );

  const [createVehicleLifecycle] = useMutation(ADD_VEHICLELIFECYCLE);
  const [updateVehicleLifecycle] = useMutation(EDIT_VEHICLELIFECYCLE);

  const handleSubmit = (
    values: VehicleLifecycleForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!vehicleId) return;
    if (lifecycle?.id) {
      updateVehicleLifecycle({
        variables: {
          id: lifecycle.id,
          vehicleId: parseInt(vehicleId),
          inServiceDate: values.inServiceDate,
          inServiceOdometer: values.inServiceOdometer,
          estimatedServiceLifeInMonths: values.estimatedServiceLifeInMonths,
          estimatedServiceLifeInMeter: values.estimatedServiceLifeInMeter,
          estimatedResaleValue: values.estimatedResaleValue,
          outOfServiceDate: values.outOfServiceDate,
          outOfServiceOdometer: values.outOfServiceOdometer,
        },
      })
        .then(async ({ data }) => {
          if (data?.vehicleLifecycleUpdate) {
            await refetch();
            toast.success("Vehicle lifecycle updated successfully");
          } else {
            toast.error("Something went wrong, please try again later");
          }
          actions.setSubmitting(false);
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
      return;
    }

    createVehicleLifecycle({
      variables: {
        vehicleId: parseInt(vehicleId),
        inServiceDate: values.inServiceDate,
        inServiceOdometer: values.inServiceOdometer,
        estimatedServiceLifeInMonths: values.estimatedServiceLifeInMonths,
        estimatedServiceLifeInMeter: values.estimatedServiceLifeInMeter,
        estimatedResaleValue: values.estimatedResaleValue,
        outOfServiceDate: values.outOfServiceDate,
        outOfServiceOdometer: values.outOfServiceOdometer,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleLifecycleCreate) {
          await refetch();
          storeVehicleForm({
            ...vehicleForm,
            lifecycle: null,
          });
          toast.success("Vehicle lifecycle created successfully");
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={LifecycleResource.name}
        heading={LifecycleResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: LifecycleResource.name,
            href: null,
          },
        ]}
      />
      <div className="py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_lifecycle")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_lifecycle")}
              </p>
            </div>
          </div>

          {loading ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                inServiceDate: (vehicleForm.id === vehicleId && vehicleForm?.lifecycle) ? vehicleForm?.lifecycle?.inServiceDate : lifecycle?.inServiceDate ?? null, // prettier-ignore
                inServiceOdometer: (vehicleForm.id === vehicleId && vehicleForm?.lifecycle) ? vehicleForm?.lifecycle?.inServiceOdometer : lifecycle?.inServiceOdometer ?? null, // prettier-ignore
                estimatedServiceLifeInMonths: (vehicleForm.id === vehicleId && vehicleForm?.lifecycle) ? vehicleForm?.lifecycle?.estimatedServiceLifeInMonths : lifecycle?.estimatedServiceLifeInMonths ?? null, // prettier-ignore
                estimatedServiceLifeInMeter: (vehicleForm.id === vehicleId && vehicleForm?.lifecycle) ? vehicleForm?.lifecycle?.estimatedServiceLifeInMeter : lifecycle?.estimatedServiceLifeInMeter ?? null, // prettier-ignore
                estimatedResaleValue: (vehicleForm.id === vehicleId && vehicleForm?.lifecycle) ? vehicleForm?.lifecycle?.estimatedResaleValue : lifecycle?.estimatedResaleValue ?? null, // prettier-ignore
                outOfServiceDate: (vehicleForm.id === vehicleId && vehicleForm?.lifecycle) ? vehicleForm?.lifecycle?.outOfServiceDate : lifecycle?.outOfServiceDate ?? null, // prettier-ignore
                outOfServiceOdometer: (vehicleForm.id === vehicleId && vehicleForm?.lifecycle) ? vehicleForm?.lifecycle?.outOfServiceOdometer : lifecycle?.outOfServiceOdometer ?? null, // prettier-ignore
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_save")}
              vehicleBasic={vehicleBasic}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Lifecycle;
export const LifecycleResource: ResourceProps = {
  name: "Lifecycle",
  description: "Update an existing vehicle lifecycle",
  access: ["update-vehicles"],
  path: "lifecycle",
  icon: "bi bi-person",
};
