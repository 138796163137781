import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  ADD_PERMISSION,
  EDIT_PERMISSION,
  GET_PERMISSIONS,
  REMOVE_PERMISSION,
} from "../../../graphql/user-management/permissions";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { formatDate } from "../../../utils";
import { AuthShield } from "../../auth/core";
import Form from "./components/Form";

type Resource = {
  id: string;
  resourceName: string;
  resourceDescription: string;
  status: string;
};

type Dependency = {
  label: string;
  value: string;
};

type Permission = {
  id?: string;
  permissionName: string;
  permissionDisplayName: string;
  permissionDescription: string;
  dependencies?: MultiValue<Dependency>;
  createdAt: string;
  resource: Resource;
  status: boolean;
};

const PermissionList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery<{
    fetchPermissions: Permission[];
  }>(GET_PERMISSIONS);
  const permissions = useMemo(
    () =>
      data?.fetchPermissions.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data?.fetchPermissions]
  );

  const [activePermission, setActivePermission] = useState<
    Permission | undefined
  >(undefined);
  const [newPermission, setNewPermission] = useState<boolean | undefined>(
    undefined
  );

  const [updatePermission] = useMutation(EDIT_PERMISSION);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updatePermission({
      variables: {
        id: activePermission?.id,
        permissionName: values.permissionName,
        permissionDisplayName: values.permissionDisplayName,
        permissionDescription: values.permissionDescription,
        dependencies:
          values?.dependencies.map((d: Dependency) => {
            return parseInt(d.value);
          }) ?? [],
        resourceId: parseInt(values.resource.id),
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.permissionUpdate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Permission updated successfully",
          });
          setActivePermission(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Permission update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Permission update failed",
          message: error.message,
        });
      });
  };

  const [createPermission] = useMutation(ADD_PERMISSION);
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createPermission({
      variables: {
        permissionName: values.permissionName,
        permissionDisplayName: values.permissionDisplayName,
        permissionDescription: values.permissionDescription,
        dependencies: values?.dependencies.map((d: Dependency) => {
          return parseInt(d.value);
        }),
        resourceId: parseInt(values.resource.id),
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.permissionCreate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Permission created successfully",
          });
          setNewPermission(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Permission create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Permission create failed",
          message: error.message,
        });
      });
  };

  const [deletePermission] = useMutation(REMOVE_PERMISSION);
  const handleDelete = async (id: string) => {
    deletePermission({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.permissionDelete) {
          const deletedPermission = data?.permissionDelete?.permission;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Permission ${deletedPermission.permissionName} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Permission deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Permission deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const updateHandler = useCallback(
    (
      dependencies: MultiValue<Dependency> | undefined,
      permission: Permission
    ) => {
      const filterDependencies = permissions.filter((p: Permission) => {
        return dependencies?.includes(parseInt(p.id!) as any);
      });
      const filteredDependencies = filterDependencies?.map((p: any) => {
        return {
          value: p.id,
          label: p.permissionName,
        };
      });
      const formattedPermission = {
        ...permission,
        dependencies: filteredDependencies,
      };
      setActivePermission(formattedPermission);
    },
    [permissions]
  );

  const columns = useMemo<ColumnDef<Permission, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "permissionDisplayName",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.permissionDisplayName,
      },
      {
        accessorKey: "resource",
        header: "Resource",
        cell: (info: any) => info.getValue().resourceName,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-permissions"]}>
              <Button
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
                onClick={() => {
                  updateHandler(
                    props.row.original?.dependencies,
                    props.row.original
                  );
                }}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.permissionName}
                </span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-permissions"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.permissionName,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.permissionName}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    [updateHandler]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={PermissionListResource.name}
        heading={PermissionListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: PermissionListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PermissionListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PermissionListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-permissions"]}>
              <Button onClick={() => setNewPermission(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add permission
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={permissions}
          columns={columns}
          loading={loading}
          totalRows={permissions.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No permissions found"
          fallbackText="You can add a new permission by clicking on add permission button."
        />
      </div>

      <Transition.Root
        show={activePermission ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePermission(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Permission - "
                  initialValues={{
                    id: activePermission?.id,
                    permissionName: activePermission?.permissionName ?? "",
                    permissionDisplayName:
                      activePermission?.permissionDisplayName ?? "",
                    permissionDescription:
                      activePermission?.permissionDescription ?? "",
                    dependencies: activePermission?.dependencies ?? [],
                    resource: activePermission?.resource ?? ({} as Resource),
                    status: activePermission?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActivePermission(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newPermission ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPermission(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Permission - "
                  initialValues={{
                    permissionName: "",
                    permissionDisplayName: "",
                    permissionDescription: "",
                    dependencies: [],
                    resource: {} as Resource,
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewPermission(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* <Transition.Root show={activePermission ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePermission(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="Edit Permission - "
                      initialValues={{
                        id: activePermission?.id,
                        permissionName: activePermission?.permissionName ?? "",
                        permissionDisplayName:
                          activePermission?.permissionDisplayName ?? "",
                        permissionDescription:
                          activePermission?.permissionDescription ?? "",
                        dependencies: activePermission?.dependencies ?? [],
                        resource:
                          activePermission?.resource ?? ({} as Resource),
                        status: activePermission?.status ?? true,
                      }}
                      onSubmit={handleUpdate}
                      submitLabel={t("text_update")}
                      onCancel={() => setActivePermission(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}

      {/* <Transition.Root show={newPermission ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPermission(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="New Permission - "
                      initialValues={{
                        permissionName: "",
                        permissionDisplayName: "",
                        permissionDescription: "",
                        dependencies: [],
                        resource: {} as Resource,
                        status: true,
                      }}
                      onSubmit={handleCreate}
                      submitLabel={t("text_create")}
                      onCancel={() => setNewPermission(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
};

export default PermissionList;
export const PermissionListResource: ResourceProps = {
  name: "Permissions",
  description: "A list of permissions",
  access: ["create-permissions", "read-permissions"],
  path: "permissions",
};
