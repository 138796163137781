import { classNames } from ".";

export type AppearanceVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "warning"
  | "info"
  | "text"
  | "icon";
export function bottonClassess(
  className: string,
  variant: AppearanceVariant,
  border?: boolean,
  loading?: boolean
): string {
  let classes =
    "inline-flex justify-center items-center whitespace-nowrap transition focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-70";

  let buttonClasses = " rounded-md border text-sm font-normal";
  let linkClasses = "";
  if (
    !className?.includes("pl-") &&
    !className?.includes("pr-") &&
    !className?.includes("px-")
  ) {
    buttonClasses += " px-3 md:px-4";
    linkClasses += " px-1";
  }
  if (
    !className?.includes("pt-") &&
    !className?.includes("pb-") &&
    !className?.includes("py-")
  ) {
    buttonClasses += " py-2.5";
    linkClasses += " py-2.5";
  }

  switch (variant) {
    case "secondary":
      border
        ? (classes +=
            buttonClasses +
            " border-gray-800 bg-transparent text-gray-800 border-gray-800 hover:bg-gray-100")
        : (classes +=
            buttonClasses +
            " border-gray-300 bg-white text-black hover:bg-gray-50");

      break;
    case "tertiary":
      border
        ? (classes +=
            buttonClasses +
            " border-gray-600 bg-transparent text-gray-800 border-gray-600 hover:bg-gray-50")
        : (classes +=
            buttonClasses +
            " border-transparent bg-secondary text-white hover:bg-gray-800");
      break;
    case "text":
      classes += " border-0 bg-transparent px-0 py-2";
      break;
    case "icon":
      classes += linkClasses + " bg-transparent";
      break;
    case "danger":
      border
        ? (classes +=
            buttonClasses +
            " border-red-600 bg-transparent text-red-600 border-red-600 hover:bg-red-50")
        : (classes +=
            buttonClasses +
            " border-transparent bg-red-700 text-white hover:bg-red-600");
      break;
    case "warning":
      border
        ? (classes +=
            buttonClasses +
            " border-yellow-600 bg-transparent text-yellow-600 border-yellow-600 hover:bg-yellow-50")
        : (classes +=
            buttonClasses +
            " border-transparent bg-yellow-500 text-white hover:bg-yellow-600");
      break;
    case "info":
      border
        ? (classes +=
            buttonClasses +
            " border-blue-600 bg-transparent text-blue-600 border-blue-600 hover:bg-blue-50")
        : (classes +=
            buttonClasses +
            " border-transparent bg-blue-700 text-white hover:bg-blue-600");
      break;
    default:
      border
        ? (classes +=
            buttonClasses +
            " border-primary-600 bg-transparent text-primary-700 border-primary-600 hover:bg-primary-50")
        : (classes +=
            buttonClasses +
            " border-transparent bg-primary-700 text-white hover:bg-primary-600");
      break;
  }

  classes = classNames(classes, className);

  return classes;
}
