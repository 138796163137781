import { FormikErrors, isObject } from "formik";
import { DateTime } from "luxon";
import * as math from "mathjs";
import toast from "react-hot-toast";
import slugify from "slugify";
import { twMerge } from "tailwind-merge";
import { getLatLng } from "use-places-autocomplete";

import { COUNTRIES } from "../components/form";
import { VariantTitle } from "../graphql/inventory/products";
import { TradingAddress } from "../graphql/sales/orders";
import i18n from "../i18n";

export * from "./botton-classess";

/**
 * Will set the callback for the given script
 */
function setupCallback(script: any, callback: () => void) {
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => {
      callback();
    };
  }
}

export const loadScript = (url: string, callback: () => void, id: string) => {
  let script: any;
  if (document.getElementById(id)) {
    // If the script already exists then add the new callback to the existing one
    script = document.getElementById(id);
    const oldFunc = script.onload;
    setupCallback(script, () => {
      oldFunc();
      callback();
    });
  } else {
    // If the script doesn't exists then create it
    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.setAttribute("id", id);
    setupCallback(script, callback);
    document.getElementsByTagName("head")[0].appendChild(script);
  }
};

export const toggleBoolean = (prev: boolean) => !prev;

const isValidArrayIndex = (arr: any[], idx: number) => {
  return !(idx < 0 || idx >= arr.length);
};

export function addValueAtIndex(arr: any[], idx: number, value: any) {
  if (!isValidArrayIndex(arr, idx) && idx !== arr.length) {
    throw new Error(`Cannot add value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), value, ...arr.slice(idx)];
}

export function replaceValueAtIndex(arr: any[], idx: number, newValue: any) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot replace value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), newValue, ...arr.slice(idx + 1)];
}

export function updateValueAtIndex(
  arr: any[],
  idx: number,
  updater: (arg0: any) => any
) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot update value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), updater(arr[idx]), ...arr.slice(idx + 1)];
}

export function removeValueAtIndex(arr: any[], idx: number) {
  if (!isValidArrayIndex(arr, idx)) {
    throw new Error(`Cannot remove value. Array index out of bounds.`);
  }
  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export function latency(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toCapitalize(text: string, all = false) {
  if (!text) return "";
  if (all) {
    return text
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function toSlug(text: string) {
  return slugify(text, {
    replacement: "-",
    remove: /[*+~.()'"!:@]/g,
    lower: true,
  });
  // return string
  //   .toLowerCase()
  //   .replace(/ /g, "-")
  //   .replace(/[^\w-]+/g, "");
}

export function toCamelCase(string: string) {
  return string
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
}
export function toCleanString(string: string) {
  return string.replaceAll(/^(-*)/g, "").trim();
}
export function toVariableName(string: string) {
  let result = string;
  result = string
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    })
    .replace(/[^a-zA-Z0-9]/g, "");
  return `$${result}`;
}

export function classNames(...classes: string[]) {
  // return classes.filter(Boolean).join(" ");
  return twMerge(...classes);
}

export function renderTabClass({ selected }: { selected: boolean }) {
  return classNames(
    "group mr-5 flex whitespace-nowrap border-b-4 border-solid border-transparent pt-3.5 pb-2.5 px-4 text-base text-gray-500 transition-all outline-none focus:outline-none",
    selected ? "border-primary font-medium text-black" : "hover:text-gray-700"
  );
}

export function toNestedTable<
  T extends {
    id: string;
    parent: {
      id: string;
    } | null;
    subRows?: T[];
    depth?: number;
  }
>(data: T[], pid: string | null = null, depth = 0): T[] {
  return data?.reduce((r, e) => {
    if ((pid === null && e.parent === null) || pid === e.parent?.id) {
      const object = { ...e, depth };
      const subRows = toNestedTable(data, e.id, depth + 1);
      if (subRows.length) {
        object.subRows = subRows.map((v) => ({ ...v, depth: v.depth }));
      }
      r.push(object);
    }
    return r;
  }, [] as T[]);
}

export function toNestedOptions<
  T extends {
    id: string;
    parent: {
      id: string;
    } | null;
    subRows?: T[];
    depth?: number;
  }
>(
  data: T[],
  cid: string | null = null,
  pid: string | null = null,
  depth = 0
): T[] {
  return data?.reduce((r, e) => {
    if (
      ((pid === null && e.parent === null) || pid === e.parent?.id) &&
      e.id !== cid
    ) {
      const object = { ...e, depth };
      r.push(object);
      const subRows = toNestedOptions(data, cid, e.id, depth + 1);
      if (subRows.length) {
        r.push(...subRows.map((v) => ({ ...v, depth: v.depth })));
      }
    }
    return r;
  }, [] as T[]);
}

export function findById<
  T extends {
    id: string;
    subRows?: T[];
  }
>(arr: T[], id: string): T | undefined {
  return arr?.find((a) => {
    if (a.subRows && a.subRows.length > 0) {
      return a.id === id ? true : findById(a.subRows, id);
    }
    return a.id === id;
  });
}

export function orderOptions<
  T extends {
    isFixed?: boolean;
  }
>(values: readonly T[]) {
  return values
    .filter((v) => v.isFixed)
    .concat(values.filter((v) => !v.isFixed));
}

export function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

export interface Address {
  // [key: string]: string;
  street: string;
  state: string;
  suburb: string;
  postcode: string;
  country: string;
  latitude: number;
  longitude: number;
}

export function getAddressFromGeocoder(
  results: google.maps.GeocoderResult[]
): Address {
  const response: Address = {
    street: "",
    state: "",
    suburb: "",
    postcode: "",
    country: "",
    latitude: 0,
    longitude: 0,
  };
  let street = "";
  let postcode = "";

  if (results.length > 0) {
    const { address_components } = results[0];
    const { lat, lng } = getLatLng(results[0]);
    response["latitude"] = lat;
    response["longitude"] = lng;

    for (const component of address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number":
          street = `${component.long_name} ${street}`;
          break;

        case "route":
          street += component.short_name;
          break;

        case "postal_code":
          postcode = `${component.long_name}${postcode}`;
          break;

        case "postal_code_suffix":
          postcode = `${postcode}-${component.long_name}`;
          break;

        case "locality":
          response["suburb"] = component.long_name;
          break;

        case "administrative_area_level_1":
          response["state"] = component.short_name;
          break;

        case "country":
          response["country"] = component.long_name;
          break;
      }
    }
  }

  response["street"] = street;
  response["postcode"] = postcode;

  return response;
}

/**
 * @param {string} string - ISO string
 * @returns {string} - formatted date string 'Oct 14, 1983'
 * @example formatDate('2021-10-14T00:00:00.000Z')
 * @example formatDate('2021-10-14T00:00:00.000Z', 'en')
 *
 * @see https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 * @see https://moment.github.io/luxon/docs/manual/i18n.html#table-of-tokens
 */

export function formatDate(
  string: string | null | undefined,
  format?: string
): string {
  if (!string) return "";
  if (format)
    return DateTime.fromISO(string, {
      locale: i18n.language,
    }).toFormat(format);
  return DateTime.fromISO(string, {
    locale: i18n.language,
  }).toLocaleString(DateTime.DATE_MED);
}

/**
 * @param {string} string - ISO string
 * @returns {string} - formatted datetime string 'Oct 14, 1983, 1:30:23 PM'
 * @example formatTime('2021-10-14T00:00:00.000Z')
 *
 * @see https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 * @see https://moment.github.io/luxon/docs/manual/i18n.html#table-of-tokens
 */

export function formatTime(string: string | null, locale?: string): string {
  if (!string) return "";

  return (
    DateTime.fromISO(string, {
      locale: locale ?? i18n.language,
    }).toRelative() || ""
  );
}

/**
 * @param {string} string - ISO string
 * @returns {string} - formatted datetime string 'Oct 14, 1983, 1:30:23 PM'
 * @example formatDateTime('2021-10-14T00:00:00.000Z')
 */
export function formatDateTime(string: string | null): string {
  if (!string) return "";
  return DateTime.fromISO(string, {
    locale: i18n.language,
  }).toLocaleString(DateTime.DATETIME_MED);
}

/* Compare date */
export function formatDateDays(
  startDate: string | null | undefined,
  endDate: string | null | undefined
): string {
  if (!startDate || !endDate) return "-";
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);
  const diff = end.diff(start, ["days", "hours", "minutes"]);
  return diff.toFormat("d'd' h'h' m'm'");
}

/**
 * @param {url} url - file path
 * @returns {object} - { title: string, extension: string }
 * @example getFileNameExt('file-name.pdf') => { title: 'file name', extension: 'pdf' }
 * @example getFileNameExt('file-name') => { title: 'file name', extension: undefined }
 */
export function getFileNameExt(url: string): {
  title: string | undefined;
  extension: string | undefined;
} {
  if (!url)
    return {
      title: undefined,
      extension: undefined,
    };
  const f = url?.split("\\")?.pop()?.split("/")?.pop();
  const extension = f?.split(".")?.pop();
  let arrName = f?.split("-");
  const title = arrName?.splice(1).join(" ");
  return { title, extension };
}

/**
 * @param {bytes} bytes - file size in bytes
 * @param {decimals} decimals - number of decimal places
 * @returns {string} - formatted file size
 * @example formatBytes(1024) => '1 KiB'
 * @example formatBytes(1024, 0) => '1 KiB'
 * @example formatBytes(1024, 2) => '1.00 KiB'
 * @example formatBytes(1024, 3) => '1.000 KiB'
 */

export function formatBytes(
  bytes: number | string,
  decimals: number = 2
): string {
  const bytesNumber = Number(bytes);
  if (!+bytesNumber) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytesNumber) / Math.log(k));

  return `${parseFloat((bytesNumber / Math.pow(k, i)).toFixed(dm))} ${
    sizes[i]
  }`;
}

export function formatVariantTitle(variantTitle?: VariantTitle[]): string {
  if (!variantTitle || !variantTitle.length) return "";
  return variantTitle.map((v) => v.name).join(" / ");
}

export function getImageDimensions(
  url: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height,
      });
    img.onerror = (error) => reject(error);
    img.src = url;
  });
}

function debugColor(index: number) {
  const colors = [
    "bg-red-100",
    "bg-yellow-100",
    "bg-green-100",
    "bg-blue-100",
    "bg-indigo-100",
    "bg-purple-100",
    "bg-pink-100",
  ];
  return colors[index % colors.length];
}

export function debug(...args: any[]) {
  if (process.env.NODE_ENV === "production") return;
  const results: any[] = [];
  args.forEach((arg) => {
    if (arg instanceof Error) {
      console.error(arg);
      return results.push(
        <pre>{JSON.stringify(arg, Object.getOwnPropertyNames(arg), 2)}</pre>
      );
    }
    results.push(<pre>{JSON.stringify(arg, null, 2)}</pre>);
  });

  return results.map((result, index) => (
    <div
      key={index}
      className={classNames(
        "m-5 rounded-md p-5 text-sm text-gray-800",
        debugColor(index)
      )}
    >
      {result}
    </div>
  ));
}

export function formatCurrency(amount: number): string {
  return new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(amount);
}

export function formatFloat(amount: number, digit?: number): number {
  if (isNaN(Number(amount))) return 0.0;
  let str = amount.toString();
  let index = str.indexOf(".");
  if (index > -1) {
    if (digit) {
      str = str.substring(0, index + digit + 1);
    } else {
      str = str.substring(0, index + 3);
    }
  }
  amount = parseFloat(str);
  return amount;
}

export function formatAmount(amount: number): number {
  if (isNaN(Number(amount))) return 0.0;
  let str = amount.toString();
  let index = str.indexOf(".");
  if (index > -1) {
    str = str.substring(0, index + 4);
  }
  amount = parseFloat(str);
  amount = math.round(math.round(amount / 0.05, 2), 1) * 0.05;
  return formatFloat(amount);
}

export const renderAddress = (
  shippingAddress: TradingAddress | null | undefined
) => {
  let address = [];
  if (!shippingAddress) return <></>;
  const { address: postalAddress, suburb, state, postcode } = shippingAddress;
  if (postalAddress) address.push(postalAddress);
  if (suburb) address.push(suburb);
  if (state) {
    if (postcode) {
      const fullState =
        COUNTRIES.find((c) => c.value.toLowerCase() === state.toLowerCase())
          ?.label ?? state;
      address.push(`${fullState} ${postcode}`);
    } else {
      address.push(state);
    }
  }
  return (
    <address className="not-italic">
      {address.map((a, i) => (
        <span key={`ad-${i}`} className="block">
          {a}
        </span>
      ))}
    </address>
  );
};

export const getFirstErrorKey = (object: FormikErrors<unknown>, keys = []) => {
  const firstErrorKey: unknown = Object.keys(object)[0];
  if (isObject(object[firstErrorKey as keyof typeof object])) {
    return object[firstErrorKey as keyof typeof object];
  }
  return [...keys, firstErrorKey as keyof typeof object].join(".");
};

export const useFormikErrors = ({
  focus = true,
  notify = true,
}: {
  focus?: boolean;
  notify?: boolean;
} = {}) => {
  const init = (
    isValid: boolean,
    submitCount: number,
    isSubmitting: boolean,
    errors: FormikErrors<unknown>
  ) => {
    console.log("isValid", isValid);
    console.log("submitCount", submitCount);
    console.log("isSubmitting", isSubmitting);
    console.log("errors", errors);
    if (!isValid && submitCount !== 0 && isSubmitting) {
      const firstErrorKey = getFirstErrorKey(errors);
      if (!firstErrorKey) return;
      if (notify)
        toast.error(
          `${toCapitalize(JSON.stringify(firstErrorKey))}: ${
            errors[firstErrorKey as keyof typeof errors]
          }`,
          {
            duration: 3000,
            position: "top-center",
          }
        );
      const errorElements =
        global.window.document.getElementsByName(firstErrorKey);
      if (errorElements.length) {
        focus && errorElements[0].focus();
      }
    }
  };
  return { init };
};
