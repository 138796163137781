import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  ADD_CATEGORY,
  GET_CATEGORIES,
} from "../../../graphql/inventory/categories";
import Form from "./components/Form";

const CategoryCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createCategory] = useMutation(ADD_CATEGORY, {
    refetchQueries: [
      {
        query: GET_CATEGORIES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCategory({
      variables: {
        name: values.name,
        handle: values.handle,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        productsIds: values.productsIds ? values.productsIds : [],
        imageUrl: values.imageUrl,
        categoryType: values.categoryType,
        conditionType: values.conditionType,
        conditions: values.conditions,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.categoryCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Category created successfully",
          });
          return navigate(`/inventory/categories`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Category creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Category creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={CategoryCreateResource.name}
        heading={CategoryCreateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Categories",
            href: "/inventory/categories",
          },
          {
            name: CategoryCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_category")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_category")}
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="md:col-span-2">
              <Form
                initialValues={{
                  name: "",
                  handle: "",
                  description: "",
                  parent: null,
                  productsIds: [],
                  imageUrl: "",
                  categoryType: 0,
                  conditionType: 0,
                  conditions: [],
                  status: true,
                }}
                products={[]}
                onSubmit={handleSubmit}
                actionLabel={t("text_create")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryCreate;
export const CategoryCreateResource: ResourceProps = {
  name: "Create Category",
  description: "Create a new category",
  access: ["create-categories"],
  path: "categories/add",
};
