import { t } from "i18next";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Logo } from "../../animations";
import { Head } from "../../components/core";
import { Button, Field } from "../../components/form";
import { NotifyType, useNotifyContext } from "../../contexts/NotifyContext";
import HeaderLayout from "../../layouts/HeaderLayout";
import { classNames } from "../../utils";
import Modal from "./components/Modal";
import TabeRow from "./components/TabeRow";
import Uploader from "./components/Uploader";
import { fetchMedia } from "./core/MediaHelper";
import { MediaProps } from "./core/types";

const Box = styled.div`
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.025);
`;

const Media = () => {
  const { addNotify } = useNotifyContext();
  const [loading, setLoading] = useState(false);
  const [showUploader, setShowUploader] = useState(false);
  const [media, setMedia] = useState<MediaProps[]>([]);
  const [open, setOpen] = useState(false);
  const [activeFile, setActiveFile] = useState<MediaProps | null>(null);

  const [query, setQuery] = useState("");

  const filteredMedia =
    query === ""
      ? media
      : media.filter((url) => {
          return url.name.toLowerCase().includes(query.toLowerCase());
        });

  const didMount = useRef(false);
  const getMedia = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchMedia();
      console.log(data);
      setMedia(data);
    } catch (error: any) {
      addNotify({
        type: NotifyType.ERROR,
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [addNotify]);

  const handleModal = useCallback((file: MediaProps) => {
    setActiveFile(file);
    setTimeout(() => {
      setOpen(true);
    }, 100);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      setActiveFile(null);
    }, 500);
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      getMedia();
      didMount.current = true;
    }
  }, [getMedia]);

  return (
    <Fragment>
      <Head
        title={t("heading_media")}
        heading={t("heading_media")}
        description={t("description_media")}
        breadcrumbs={[
          {
            name: "Alpha Fresh",
            href: "/",
          },
          {
            name: t("heading_media"),
            href: "/media",
          },
        ]}
      />
      <div className="mx-auto max-w-6xl px-4 pb-10 sm:px-6 lg:px-8">
        <HeaderLayout />
        <div className="mb-3 flex justify-end">
          <Button
            onClick={() => {
              setShowUploader((prev) => !prev);
            }}
          >
            Add New
          </Button>
        </div>
        {showUploader && (
          <div className="mb-5">
            <Uploader
              onUpload={(files) => {
                setMedia((prev) => [...files, ...prev]);
              }}
            />
          </div>
        )}
        <Box
          className={classNames(
            "mt-10 min-h-[20rem] rounded-xl bg-greyish p-5"
          )}
        >
          <div className="rounded-lg bg-white px-4 sm:px-6 lg:px-8">
            <div className="w-full py-5 md:flex md:justify-end">
              <div className="w-3/4 sm:w-1/2 md:w-1/3">
                <Field
                  type="search"
                  placeholder="Search"
                  onChange={(event) => setQuery(event.target.value)}
                  value={query}
                  isLabel={false}
                />
              </div>
            </div>
            {loading ? (
              <div className="flex w-full justify-center py-10 md:py-16 xl:py-24">
                <Logo />
              </div>
            ) : (
              <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            File name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Date added
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Size
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Copy link
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                          >
                            <span className="sr-only">Delete</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {filteredMedia.map((file) => (
                          <TabeRow
                            key={file.name}
                            file={file}
                            onDelete={(url: string) => {
                              setMedia((prev) =>
                                prev.filter((f) => f.url !== url)
                              );
                            }}
                            onClick={handleModal}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Box>
      </div>
      <Modal
        file={activeFile}
        open={open}
        onClose={closeModal}
        onDelete={(url: string) => {
          setMedia((prev) => prev.filter((f) => f.url !== url));
          setOpen(false);
          setActiveFile(null);
        }}
      />
    </Fragment>
  );
};

export default Media;
export const MediaResource: ResourceProps = {
  name: "Media Library",
  description: "Manage your media files",
  path: "/media",
  icon: "bi bi-speedometer2",
  access: [],
};
