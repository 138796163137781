import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../../animations";
import { Button, Field, FieldCustomers } from "../../../../../components/form";
import { PreviewCustomer } from "../../../../../graphql/sales/customers";
import { classNames } from "../../../../../utils";

type customerGroup = {
  id?: number;
  name: string;
  description: string;
  customers: number[];
  status: boolean;
};

export default function Form({
  initialValues,
  initialCustomers,
  onSubmit,
  actionLabel,
}: {
  initialValues: customerGroup;
  initialCustomers: PreviewCustomer[];
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState<PreviewCustomer[]>([]);

  useEffect(() => {
    setCustomers(initialCustomers);
  }, [initialCustomers]);

  const customerGroupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    description: Yup.string().nullable(),
    customers: Yup.array().of(Yup.number()).nullable(),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: customerGroupSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={touched.name}
            errors={errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <Field
            title={t("text_description")}
            name="description"
            type="textarea"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            touched={formik.touched.description}
            errors={formik.errors.description}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <FieldCustomers
            title={t("text_customers")}
            data={customers}
            value={formik.values.customers}
            onChange={(_customers) => {
              formik.setFieldValue(
                "customers",
                _customers.flatMap((c) => Number(c.id))
              );
              setCustomers(_customers);
            }}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              {t("text_status")}
            </legend>
            <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
              <Switch
                checked={formik.values.status}
                onChange={() => {
                  formik.setFieldValue("status", !formik.values.status);
                }}
                id="status"
                className={classNames(
                  formik.values.status ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formik.values.status ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor="status"
                className="mb-0 ml-2 block text-sm font-normal text-gray-700"
              >
                {formik.values.status ? t("text_active") : t("text_inactive")}
              </Switch.Label>
            </Switch.Group>
          </fieldset>
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/sales/settings/customer-groups" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
