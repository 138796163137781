import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Link } from "react-router-dom";

import placeholder from "../../assets/placeholder.svg";
import {
  GET_PRODUCTS_BY_CUSTOMER,
  Product,
} from "../../graphql/inventory/products";
import { RowProduct } from "../../graphql/sales/orders";
import { classNames } from "../../utils";
import { ErrorMessage } from "../core";
import { TableCursorInfinite } from "../table";
import { Button } from ".";

export function FieldModifyProduct({
  title,
  customerId,
  value,
  onChange,
  excludeIds = [],
  excludeNote = "",
  disabled = false,
  className = "",
  children,
}: {
  title: string;
  customerId: number;
  value: number | null;
  onChange: (newValue: RowProduct) => void;
  excludeIds?: number[];
  excludeNote?: string;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}) {
  const [isMorefetching, setIsMorefetching] = useState(false);
  const [pageLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");

  const { data, loading, error, fetchMore } = useQuery(
    GET_PRODUCTS_BY_CUSTOMER,
    {
      variables: {
        customerId,
        first: pageLimit,
        searchQuery: searchQuery ? searchQuery : "",
        searchFilter,
        status: null,
        stockCode: "",
        vendorId: null,
        categoryId: null,
        departmentId: null,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  const products: RowProduct[] = useMemo(() => {
    const updatedProducts: RowProduct[] =
      data?.fetchProducts.edges?.flatMap(
        (edge: { node: Product }, index: number) =>
          edge.node.variants.flatMap((variant, idx) => ({
            ...variant,
            index: index + idx,
            name: (
              <div className="text-gray-500">
                {edge.node.name}
                <span className="ml-2 inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
                  {variant.variantTitle?.map((v) => v.name)?.join(" / ")}
                </span>
              </div>
            ),
            productId: edge.node.id,
            featureImageUrl:
              variant.variantImageUrl ?? edge.node.featureImageUrl,
            stockCode: variant.stockCode,
            price: variant.customerSpecialPrice ?? variant.customerBasePrice,
            quantity: variant.minimumQuantity ? variant.minimumQuantity : 1,
            customerBasePrice: variant.customerBasePrice,
            customerSpecialPrice: variant.customerSpecialPrice,
            status: edge.node.status,
          }))
      ) ?? [];

    const flatData = updatedProducts.flat();

    if (value) {
      const updatedRowSelection: Record<string, boolean> = {};
      flatData.forEach((p, i) => {
        updatedRowSelection[String(i)] = value === Number(p.id);
      });
      setRowSelection(updatedRowSelection);
    } else {
      setRowSelection({});
    }

    return flatData;
  }, [data?.fetchProducts.edges, value]);

  const filtering: boolean = useMemo(
    () => (searchFilter.length > 0 || !!searchQuery ? true : false),
    [searchFilter.length, searchQuery]
  );

  const fetchNext = useCallback(() => {
    setIsMorefetching(true);
    fetchMore({
      variables: {
        cursor: data?.fetchProducts?.pageInfo?.endCursor,
      },
    }).finally(() => {
      setIsMorefetching(false);
    });
  }, [data?.fetchProducts?.pageInfo?.endCursor, fetchMore]);

  const columns = useMemo<ColumnDef<RowProduct>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
        enableHiding: false,
        cell: (props) => (
          <Link
            to={`/inventory/products/${props.row.original.id}`}
            target="_blank"
            className="flex items-center whitespace-nowrap"
          >
            <div className="relative z-10 mr-2 h-12 w-12 overflow-hidden rounded-full">
              <img
                className="h-full w-full object-cover"
                src={
                  props.row.original.featureImageUrl
                    ? props.row.original.featureImageUrl
                    : placeholder
                }
                alt={props.row.original.name}
              />
            </div>
            <span className="mr-2">{props.row.original.name}</span>
            {excludeNote ? (
              excludeIds.includes(Number(props.row.original.id)) ? (
                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                  {excludeNote}
                </span>
              ) : null
            ) : (
              <Fragment>
                {props.getValue() === 0 ? (
                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                    Inactive
                  </span>
                ) : props.getValue() === 1 ? (
                  <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
                    Draft
                  </span>
                ) : null}
              </Fragment>
            )}
          </Link>
        ),
      },
      {
        accessorKey: "stockCode",
        header: () => <span className="whitespace-nowrap">Stock Code</span>,
        size: 200,
      },
      {
        accessorKey: "customerBasePrice",
        header: "Sell Price",
        cell: ({ row }) => (
          <div className="whitespace-nowrap">
            {row.original.customerSpecialPrice &&
            row.original.customerSpecialPrice > 0 ? (
              <s className="text-red-700">
                ${`${row.original.customerBasePrice}`}
              </s>
            ) : (
              <span>${`${row.original.customerBasePrice}`}</span>
            )}
          </div>
        ),
      },
      {
        accessorKey: "customerSpecialPrice",
        header: "Special Price",
        cell: ({ row }) => (
          <div className="whitespace-nowrap">
            {row.original.customerSpecialPrice &&
            row.original.customerSpecialPrice > 0 ? (
              <span>${`${row.original.customerSpecialPrice}`}</span>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: ({ row }) => (
          <div className="flex space-x-2 md:space-x-4">
            <Button
              variant="info"
              border
              onClick={() => {
                onChange(row.original);
                setSearchQuery(null);
              }}
              disabled={excludeIds.includes(Number(row.original.id))}
            >
              Insert<span className="sr-only">, {row.original.name}</span>
            </Button>
          </div>
        ),
      },
    ],
    [excludeNote, excludeIds, onChange]
  );

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Button
        variant="info"
        border
        onClick={() => {
          setSearchQuery("");
        }}
        disabled={disabled}
        className={classNames("px-2", className)}
      >
        {children}
      </Button>
      <Transition.Root
        show={searchQuery === null ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setSearchQuery(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-4xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-greyish p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="mb-2 flex space-x-2">
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for name"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery ?? ""}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string | null> };
                      }) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    variant="icon"
                    onClick={() => {
                      setSearchQuery(null);
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 w-5" />
                  </Button>
                </div>
                <TableCursorInfinite
                  data={products}
                  columns={columns}
                  loading={loading}
                  totalRows={data?.fetchProducts?.totalCount || 0}
                  pageLimit={pageLimit}
                  fetching={isMorefetching}
                  hasNextPage={
                    data?.fetchProducts?.pageInfo?.hasNextPage ?? false
                  }
                  fetchNextPage={fetchNext}
                  filtering={filtering}
                  state={{
                    rowSelection,
                  }}
                  enableRowSelection={(row) =>
                    !excludeIds.includes(Number(row.original.id))
                  }
                  onRowSelectionChange={setRowSelection}
                  getCoreRowModel={getCoreRowModel()}
                  enableHeader={false}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
