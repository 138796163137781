import { useLocation } from "react-router-dom";

import { Head } from "../../../components/core";

export const Error400 = () => {
  const location: any = useLocation();

  return (
    <>
      <Head title="Error 401" heading="Error 400" />
      <div className="sm:flex">
        <p className="text-4xl font-extrabold text-primary-600 sm:text-5xl">
          400
        </p>
        <div className="sm:ml-6">
          <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 className="mb-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              {`${
                location.state?.title ? location.state.title : "Bad Request"
              }`}
            </h1>
            <p className="mt-1 text-base text-gray-500 capitalize-first">
              {/* {`${
                location.state?.message
                  ? location.state.message
                  : "The request could not be understood by the server due to malformed syntax."
              }`} */}
              Some of the information you are trying to access is restricted.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
