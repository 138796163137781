import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowsUpDownIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Link } from "react-router-dom";

import { Spinner } from "../../animations";
import placeholder from "../../assets/placeholder.svg";
import {
  GET_PRODUCTS_BY_CUSTOMER,
  Product,
} from "../../graphql/inventory/products";
import { RowProduct } from "../../graphql/sales/orders";
import { classNames } from "../../utils";
import { ErrorFallback } from "../core";
import { Button } from "../form";
import { IndeterminateCheckbox } from "../table";
import { TableCursorInfinite } from "../table";

export function FieldOrderProducts({
  customerId,
  data: previews,
  value = [],
  onChange,
  excludeIds = [],
  excludeNote = "",
}: {
  customerId: number;
  data: RowProduct[];
  value: number[];
  onChange: (newValue: RowProduct[]) => void;
  excludeIds?: number[];
  excludeNote?: string;
}) {
  const [isMorefetching, setIsMorefetching] = useState(false);
  const [pageLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");

  const { data, loading, error, fetchMore } = useQuery(
    GET_PRODUCTS_BY_CUSTOMER,
    {
      variables: {
        customerId,
        first: pageLimit,
        searchQuery: searchQuery ? searchQuery : "",
        searchFilter,
        status: null,
        stockCode: "",
        vendorId: null,
        categoryId: null,
        departmentId: null,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  const products: RowProduct[] = useMemo(() => {
    const updatedProducts: RowProduct[] =
      data?.fetchProducts.edges?.flatMap(
        (edge: { node: Product }, index: number) =>
          edge.node.variants.flatMap((variant, idx) => ({
            ...variant,
            index: index + idx,
            name: (
              <div className="text-gray-500">
                {edge.node.name}
                <span className="ml-2 inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
                  {variant.variantTitle?.map((v) => v.name)?.join(" / ")}
                </span>
              </div>
            ),
            productId: edge.node.id,
            featureImageUrl:
              variant.variantImageUrl ?? edge.node.featureImageUrl,
            stockCode: variant.stockCode,
            price: variant.customerSpecialPrice ?? variant.customerBasePrice,
            quantity: variant.minimumQuantity ? variant.minimumQuantity : 1,
            customerBasePrice: variant.customerBasePrice,
            customerSpecialPrice: variant.customerSpecialPrice,
            status: edge.node.status,
          }))
      ) ?? [];

    const flatData = updatedProducts.flat();

    if (value.length > 0) {
      const updatedRowSelection: Record<string, boolean> = {};
      flatData.forEach((p, i) => {
        updatedRowSelection[String(i)] = value.includes(Number(p.id));
      });
      setRowSelection(updatedRowSelection);
    } else {
      setRowSelection({});
    }

    return flatData;
  }, [data?.fetchProducts.edges, value]);

  const filtering: boolean = useMemo(
    () => (searchFilter.length > 0 || !!searchQuery ? true : false),
    [searchFilter.length, searchQuery]
  );

  const fetchNext = useCallback(() => {
    setIsMorefetching(true);
    fetchMore({
      variables: {
        cursor: data?.fetchProducts?.pageInfo?.endCursor,
      },
    }).finally(() => {
      setIsMorefetching(false);
    });
  }, [data?.fetchProducts?.pageInfo?.endCursor, fetchMore]);

  const columns = useMemo<ColumnDef<RowProduct>[]>(
    () => [
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target;
                    row.getToggleSelectedHandler()(e);
                    if (checked) {
                      onChange([...previews, row.original]);
                      return;
                    }
                    onChange(previews.filter((p) => p.id !== row.original.id));
                  },
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
        enableHiding: false,
        cell: (props) => (
          <Link
            to={`/inventory/products/${props.row.original.id}`}
            target="_blank"
            className="flex items-center whitespace-nowrap"
          >
            <div className="relative z-10 mr-2 h-12 w-12 overflow-hidden rounded-full">
              <img
                className="h-full w-full object-cover"
                src={
                  props.row.original.featureImageUrl
                    ? props.row.original.featureImageUrl
                    : placeholder
                }
                alt={props.row.original.name}
              />
            </div>
            <span className="mr-2">{props.row.original.name}</span>
            {excludeNote ? (
              excludeIds.includes(Number(props.row.original.id)) ? (
                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                  {excludeNote}
                </span>
              ) : null
            ) : (
              <Fragment>
                {props.getValue() === 0 ? (
                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                    Inactive
                  </span>
                ) : props.getValue() === 1 ? (
                  <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
                    Draft
                  </span>
                ) : null}
              </Fragment>
            )}
          </Link>
        ),
      },
      {
        accessorKey: "stockCode",
        header: () => <span className="whitespace-nowrap">Stock Code</span>,
        size: 200,
      },
      {
        accessorKey: "customerBasePrice",
        header: "Sell Price",
        cell: ({ row }) => (
          <div className="whitespace-nowrap">
            {row.original.customerSpecialPrice &&
            row.original.customerSpecialPrice > 0 ? (
              <s className="text-red-700">
                ${`${row.original.customerBasePrice}`}
              </s>
            ) : (
              <span>${`${row.original.customerBasePrice}`}</span>
            )}
          </div>
        ),
      },
      {
        accessorKey: "customerSpecialPrice",
        header: "Special Price",
        cell: ({ row }) => (
          <div className="whitespace-nowrap">
            {row.original.customerSpecialPrice &&
            row.original.customerSpecialPrice > 0 ? (
              <span>${`${row.original.customerSpecialPrice}`}</span>
            ) : (
              "-"
            )}
          </div>
        ),
      },
    ],
    [onChange, previews, excludeNote, excludeIds]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <header className="grid grid-cols-1 gap-4 sm:grid-cols-4">
        <div className="relative col-span-3">
          <label htmlFor="search" className="sr-only">
            Search products
          </label>
          <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className={classNames(
              "relative block w-full appearance-none rounded-md border border-gray-300 py-2.5 pl-10 pr-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
              "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
            )}
            placeholder="Search products"
            value={""}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
        </div>
        <div className="col-span-1 flex">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={() => {
              setSearchQuery("");
            }}
          >
            Browse
          </Button>
        </div>
      </header>
      <div className="mt-2">
        {loading ? (
          <div className="flex justify-center py-6 md:py-12">
            <Spinner className="h-8 w-8 text-primary-700" />
          </div>
        ) : !previews.length ? (
          <div className="py-6 text-center text-sm md:py-12">
            <ExclamationCircleIcon
              type="outline"
              name="exclamation-circle"
              className="mx-auto h-6 w-6 text-gray-400"
            />
            <p className="mt-4 font-medium text-gray-900">
              No products selected
            </p>
            <p className="mt-2 text-gray-500">
              Please{" "}
              <Button
                variant="text"
                onClick={() => {
                  setSearchQuery("");
                }}
              >
                browse
              </Button>{" "}
              products to add to the order.
            </p>
          </div>
        ) : null}
      </div>
      <Transition.Root
        show={searchQuery === null ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setSearchQuery(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-greyish p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for name"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery ?? ""}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string | null> };
                      }) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setSearchQuery(null);
                    }}
                  >
                    <ArrowsUpDownIcon className="mr-2 h-5 w-5" />
                    Done
                  </Button>
                </div>
                <TableCursorInfinite
                  data={products}
                  columns={columns}
                  loading={loading}
                  totalRows={data?.fetchProducts?.totalCount || 0}
                  pageLimit={pageLimit}
                  fetching={isMorefetching}
                  hasNextPage={
                    data?.fetchProducts?.pageInfo?.hasNextPage ?? false
                  }
                  fetchNextPage={fetchNext}
                  filtering={filtering}
                  state={{
                    rowSelection,
                  }}
                  enableRowSelection={(row) =>
                    !excludeIds.includes(Number(row.original.id))
                  }
                  onRowSelectionChange={setRowSelection}
                  getCoreRowModel={getCoreRowModel()}
                  enableHeader={false}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
