import { lazy, Suspense } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import {
  LayoutSplashScreen,
  NotAuthorized,
  NotFound,
} from "../../components/core";
import PageLayout from "../../layouts/PageLayout";
import { isAuthorizedForResource, useAuth } from "../auth";
import { CustomerCreateResource } from "./customers/Create";
import { CustomerListResource } from "./customers/List";
import { CustomerPricingResource } from "./customers/Pricing";
import { CustomerProductsResource } from "./customers/Products";
import { CustomerUpdateResource } from "./customers/Update";
import { OrderApprovalResource } from "./orders/Approval";
import { OrderCreateResource } from "./orders/Create";
import { OrderListResource } from "./orders/List";
import { OrderUpdateResource } from "./orders/Update";
import { SettingsResource } from "./settings/Settings";

const CustomerList = lazy(() => import("./customers/List"));
const CustomerCreate = lazy(() => import("./customers/Create"));
const CustomerUpdate = lazy(() => import("./customers/Update"));
const CustomerPricing = lazy(() => import("./customers/Pricing"));
const CustomerProducts = lazy(() => import("./customers/Products"));

const OrderList = lazy(() => import("./orders/List"));
const OrderApproval = lazy(() => import("./orders/Approval"));
const OrderCreate = lazy(() => import("./orders/Create"));
const OrderUpdate = lazy(() => import("./orders/Update"));

const Settings = lazy(() => import("./settings/Settings"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
];

export function SalePage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={OrderListResource.path} replace={true} />,
        },

        {
          path: CustomerListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerProductsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerProductsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerProducts breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerProductsResource.access.join(", "))}
            />
          ),
        },

        {
          path: OrderListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            OrderListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <OrderList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(OrderListResource.access.join(", "))}
            />
          ),
        },
        {
          path: OrderApprovalResource.path,
          element: isAuthorizedForResource(
            currentRole,
            OrderApprovalResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <OrderApproval breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(OrderApprovalResource.access.join(", "))}
            />
          ),
        },
        {
          path: OrderCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            OrderCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <OrderCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(OrderCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: OrderUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            OrderUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <OrderUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(OrderUpdateResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: CustomerPricingResource.path,
      element: isAuthorizedForResource(
        currentRole,
        CustomerPricingResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <CustomerPricing breadcrumbs={breadcrumbs} />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(CustomerPricingResource.access.join(", "))}
        />
      ),
    },
    {
      path: SettingsResource.path,
      element: isAuthorizedForResource(currentRole, SettingsResource.access) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Settings breadcrumbs={breadcrumbs} />
        </Suspense>
      ) : (
        <NotAuthorized error={new Error(SettingsResource.access.join(", "))} />
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
