import { useQuery } from "@apollo/client/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import { Button } from "../../../../../components/form";
import { VehicleBasic } from "../../../../../graphql/fleets/vehicles";
import {
  GET_METERENTRY_BY_ID,
  VehicleMeterEntry,
} from "../../../../../graphql/fleets/vehicles/pages/meter-entry";
import { AuthShield } from "../../../../auth/core";
import { MeterEntryDetail } from "../../components/MeterEntryDetail";

const MeterEntryView = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId, meterEntryId } = useParams();
  const { t } = useTranslation();

  const { data, loading, error } = useQuery<{
    fetchMeterEntry: VehicleMeterEntry;
  }>(GET_METERENTRY_BY_ID, {
    variables: {
      id: meterEntryId,
    },
  });

  const meterEntry = useMemo<VehicleMeterEntry | undefined>(
    () => data?.fetchMeterEntry,
    [data]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={MeterEntryViewResource.name}
        heading={MeterEntryViewResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: MeterEntryViewResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_meter_entry")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_meter_entry")}
              </p>
            </div>
            <div className="ml-auto flex items-center space-x-2 pr-3">
              <AuthShield access={["read-vehicles"]}>
                <Button
                  type="link"
                  href={`/fleets/vehicles/${vehicleId}/meter-entry/${meterEntryId}/edit`}
                >
                  <PencilIcon className="mr-2 h-5 w-5" />
                  {t("text_edit")}
                </Button>
              </AuthShield>
            </div>
          </div>

          {loading || !meterEntry ? (
            <Waiting />
          ) : (
            <MeterEntryDetail
              meterEntry={meterEntry}
              vehicleBasic={vehicleBasic}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MeterEntryView;
export const MeterEntryViewResource: ResourceProps = {
  name: "Meter Entry",
  description: "View an existing meter entry",
  access: ["read-vehicles"],
  path: "/meter-entry/:meterEntryId",
};
