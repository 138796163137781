import { useMutation, useQuery } from "@apollo/client/react";
import { FireIcon } from "@heroicons/react/24/outline";
import { useContext, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import { GenContext } from "../../../../contexts/GenContext";
import {
  GET_VEHICLES,
  VehicleBasic,
} from "../../../../graphql/fleets/vehicles";
import {
  ADD_VEHICLESETTINGS,
  EDIT_VEHICLESETTINGS,
  GET_VEHICLESETTINGS,
  GET_VEHICLESETTINGS_BASIC_BY_VEHICLE_ID,
  VehicleSetting,
  VehicleSettingForm,
} from "../../../../graphql/fleets/vehicles/pages/settings";
import Form from "../components/SettingsForm";

const Settings = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const { vehicleForm, storeVehicleForm } = useContext(GenContext);

  const { data, loading, error, refetch } = useQuery<{
    fetchSettingsByVehicle: VehicleSetting;
  }>(GET_VEHICLESETTINGS, {
    variables: {
      vehicleId,
    },
  });
  const settings = useMemo<VehicleSetting | null>(
    () => data?.fetchSettingsByVehicle || null,
    [data]
  );

  const [createVehicleSetting] = useMutation(ADD_VEHICLESETTINGS);
  const [updateVehicleSetting] = useMutation(EDIT_VEHICLESETTINGS, {
    refetchQueries: [
      { query: GET_VEHICLES },
      {
        query: GET_VEHICLESETTINGS_BASIC_BY_VEHICLE_ID,
        variables: {
          vehicleId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: VehicleSettingForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!vehicleId) return;
    if (settings?.id) {
      updateVehicleSetting({
        variables: {
          id: settings.id,
          vehicleId: parseInt(vehicleId),
          fuelUnit: values.fuelUnit,
          measurementUnit: values.measurementUnit,
          primaryMeter: values.primaryMeter,
        },
      })
        .then(async ({ data }) => {
          if (data?.vehicleSettingUpdate) {
            await refetch();
            storeVehicleForm({
              ...vehicleForm,
              settings: null,
            });
            toast.custom((t) => (
              <div
                className={`${
                  t.visible ? "animate-enter" : "animate-leave"
                } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
              >
                <div className="w-0 flex-1 p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <FireIcon className="h-10 w-10 text-red-500" />
                    </div>
                    <div className="ml-3 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        Vehicle settings updated
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        You are about to change the type of meter, but meter
                        entries already exist on this vehicle. We won't convert
                        previous meter entries on this vehicle to to the new
                        unit of measurement. You should probably delete the old
                        meter entries for this vehicle if you change the meter
                        type since those meter entries will be inaccurate.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
                  >
                    Close
                  </button>
                </div>
              </div>
            ));
          } else {
            toast.error("Something went wrong, please try again later");
          }
          actions.setSubmitting(false);
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
      return;
    }

    createVehicleSetting({
      variables: {
        vehicleId: parseInt(vehicleId),
        fuelUnit: values.fuelUnit,
        measurementUnit: values.measurementUnit,
        primaryMeter: values.primaryMeter,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleSettingCreate) {
          await refetch();
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
            >
              <div className="w-0 flex-1 p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5">
                    <FireIcon className="h-10 w-10 text-red-500" />
                  </div>
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      Vehicle settings created
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      You are about to change the type of meter, but meter
                      entries already exist on this vehicle. We won't convert
                      previous meter entries on this vehicle to to the new unit
                      of measurement. You should probably delete the old meter
                      entries for this vehicle if you change the meter type
                      since those meter entries will be inaccurate.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={SettingsResource.name}
        heading={SettingsResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: SettingsResource.name,
            href: null,
          },
        ]}
      />
      <div className="py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_settings")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_settings")}
              </p>
            </div>
          </div>

          {loading ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                fuelUnit: (vehicleForm.id === vehicleId && vehicleForm?.settings) ? vehicleForm?.settings?.fuelUnit : settings?.fuelUnit ?? null, // prettier-ignore
                measurementUnit: (vehicleForm.id === vehicleId && vehicleForm?.settings) ? vehicleForm?.settings?.measurementUnit : settings?.measurementUnit ?? null, // prettier-ignore
                primaryMeter: (vehicleForm.id === vehicleId && vehicleForm?.settings) ? vehicleForm?.settings?.primaryMeter : settings?.primaryMeter ?? null, // prettier-ignore
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_save")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Settings;
export const SettingsResource: ResourceProps = {
  name: "Settings",
  description: "Update an existing vehicle settings",
  access: ["update-vehicles"],
  path: "settings",
  icon: "bi bi-person",
};
