import { gql } from "@apollo/client";

import { Vehicle, VEHICLE_FRAGMENT } from "../..";

export const ADD_VEHICLEDETAILS = gql`
  ${VEHICLE_FRAGMENT}
  mutation VehicleCreate(
    $name: String!
    $color: String
    $fleetManagerId: Int
    $licensePlate: String!
    $msrp: Float
    $operatorId: Int
    $trim: String
    $vehicleBodySubtypeId: Int
    $vehicleBodyTypeId: Int
    $vehicleFuelTypeId: Int
    $vehicleImageUrl: String
    $vehicleMakeId: Int
    $vehicleModelId: Int
    $vehicleStatusId: Int!
    $vehicleTypeId: Int!
    $vinSn: String!
    $year: Int
  ) {
    vehicleCreate(
      input: {
        params: {
          name: $name
          color: $color
          fleetManagerId: $fleetManagerId
          licensePlate: $licensePlate
          msrp: $msrp
          operatorId: $operatorId
          trim: $trim
          vehicleBodySubtypeId: $vehicleBodySubtypeId
          vehicleBodyTypeId: $vehicleBodyTypeId
          vehicleFuelTypeId: $vehicleFuelTypeId
          vehicleImageUrl: $vehicleImageUrl
          vehicleMakeId: $vehicleMakeId
          vehicleModelId: $vehicleModelId
          vehicleStatusId: $vehicleStatusId
          vehicleTypeId: $vehicleTypeId
          vinSn: $vinSn
          year: $year
        }
      }
    ) {
      vehicle {
        ...VehicleFragment
      }
    }
  }
`;

export const EDIT_VEHICLEDETAILS = gql`
  ${VEHICLE_FRAGMENT}
  mutation VehicleUpdate(
    $id: ID!
    $name: String!
    $color: String
    $fleetManagerId: Int
    $licensePlate: String!
    $msrp: Float
    $operatorId: Int
    $trim: String
    $vehicleBodySubtypeId: Int
    $vehicleBodyTypeId: Int
    $vehicleFuelTypeId: Int
    $vehicleImageUrl: String
    $vehicleMakeId: Int
    $vehicleModelId: Int
    $vehicleStatusId: Int!
    $vehicleTypeId: Int!
    $vinSn: String!
    $year: Int
  ) {
    vehicleUpdate(
      input: {
        id: $id
        params: {
          name: $name
          color: $color
          fleetManagerId: $fleetManagerId
          licensePlate: $licensePlate
          msrp: $msrp
          operatorId: $operatorId
          trim: $trim
          vehicleBodySubtypeId: $vehicleBodySubtypeId
          vehicleBodyTypeId: $vehicleBodyTypeId
          vehicleFuelTypeId: $vehicleFuelTypeId
          vehicleImageUrl: $vehicleImageUrl
          vehicleMakeId: $vehicleMakeId
          vehicleModelId: $vehicleModelId
          vehicleStatusId: $vehicleStatusId
          vehicleTypeId: $vehicleTypeId
          vinSn: $vinSn
          year: $year
        }
      }
    ) {
      vehicle {
        ...VehicleFragment
      }
    }
  }
`;

export type VehicleDetailsForm = Pick<
  Vehicle,
  | "name"
  | "color"
  | "licensePlate"
  | "vehicleImageUrl"
  | "year"
  | "msrp"
  | "trim"
  | "vinSn"
> & {
  fleetManagerId: number | null;
  operatorId: number | null;
  vehicleBodySubtypeId: number | null;
  vehicleBodyTypeId: number | null;
  vehicleFuelTypeId: number | null;
  vehicleMakeId: number | null;
  vehicleModelId: number | null;
  vehicleTypeId: number | null;
  vehicleStatusId: number | null;
};
