import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import Form from "./components/Form";
import { TreeItems } from "./components/types";

const CREATE_STOREMENU = gql`
  mutation StoreMenuCreate(
    $name: String!
    $handle: String!
    $menuItems: [StorenavigationmenuitemInput!]
    $status: Boolean!
  ) {
    storeMenuCreate(
      input: {
        params: {
          name: $name
          handle: $handle
          menuItems: $menuItems
          status: $status
        }
      }
    ) {
      menu {
        id
        name
      }
    }
  }
`;

export default function MenuCreate({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [createStoreMenu] = useMutation(CREATE_STOREMENU);

  const handleSubmit = (
    values: {
      name: string;
      handle: string;
      menuItems: TreeItems;
      status: boolean;
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const menuItems = values.menuItems.map((item) => ({
      menuName: item.title,
      parentId: null,
      linkType: item.type,
      linkId: item.link,
    }));

    createStoreMenu({
      variables: {
        name: values.name,
        handle: values.handle,
        menuItems: menuItems,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.storeMenuCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Menu created successfully",
          });
          return navigate("/store/menu", { replace: true });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Menu create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Menu create failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Menu"
        heading="Create Menu"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: MenuCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          <Form
            initialValues={{
              name: "",
              handle: "",
              menuItems: [],
              status: true,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
}

export const MenuCreateResource: ResourceProps = {
  name: "Create Menu",
  description: "A create of menu items",
  access: ["create-menus"],
  path: "menu/add",
};
