import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowsUpDownIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import placeholder from "../../assets/placeholder.svg";
import {
  GET_PRODUCTS,
  PreviewProduct,
  Product,
} from "../../graphql/inventory/products";
import { classNames } from "../../utils";
import { ErrorFallback } from "../core";
import { Button } from "../form";
import { IndeterminateCheckbox } from "../table";
import { TableCursorInfinite } from "../table";

interface RowProduct extends PreviewProduct {
  index: number;
}

export function FieldInsertProducts({
  title,
  onChange,
  excludeIds = [],
  excludeNote = "",
}: {
  title: string;
  onChange: (newValue: PreviewProduct[]) => void;
  excludeIds?: number[];
  excludeNote?: string;
}) {
  const { t } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<PreviewProduct[]>(
    []
  );

  const [isMorefetching, setIsMorefetching] = useState(false);
  const pageLimit = useRef(10);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");

  const { data, loading, error, fetchMore } = useQuery(GET_PRODUCTS, {
    variables: {
      first: pageLimit.current,
      searchQuery: searchQuery ? searchQuery : "",
      searchFilter,
      status: null,
      stockCode: "",
      vendorId: null,
      categoryId: null,
      departmentId: null,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const products: RowProduct[] = useMemo(() => {
    const updatedProducts: RowProduct[] =
      data?.fetchProducts.edges?.flatMap(
        (edge: { node: Product }, index: number) => ({
          index,
          id: edge.node.id,
          name: edge.node.name,
          featureImageUrl: edge.node.featureImageUrl,
          status: edge.node.status,
        })
      ) ?? [];
    return updatedProducts;
  }, [data?.fetchProducts.edges]);

  const filtering: boolean = useMemo(
    () => (searchFilter.length > 0 || !!searchQuery ? true : false),
    [searchFilter.length, searchQuery]
  );

  const fetchNext = useCallback(() => {
    setIsMorefetching(true);
    fetchMore({
      variables: {
        cursor: data?.fetchProducts?.pageInfo?.endCursor,
      },
    }).finally(() => {
      setIsMorefetching(false);
    });
  }, [data?.fetchProducts?.pageInfo?.endCursor, fetchMore]);

  const columns = useMemo<ColumnDef<RowProduct>[]>(
    () => [
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect() || row.depth > 0,
                  indeterminate: row.getIsSomeSelected(),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target;
                    row.getToggleSelectedHandler()(e);
                    if (checked) {
                      setSelectedProducts([...selectedProducts, row.original]);
                      return;
                    }
                    setSelectedProducts((prev) =>
                      prev.filter((p) => p.id !== row.original.id)
                    );
                  },
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
        enableHiding: false,
        cell: (props) => (
          <Link
            to={`/inventory/products/${props.row.original.id}`}
            target="_blank"
            className="flex items-center whitespace-nowrap"
          >
            <div className="relative z-10 mr-2 h-12 w-12 overflow-hidden rounded-full">
              <img
                className="h-full w-full object-cover"
                src={
                  props.row.original.featureImageUrl
                    ? props.row.original.featureImageUrl
                    : placeholder
                }
                alt={props.row.original.name}
              />
            </div>
            <span className="mr-2">{props.row.original.name}</span>
            {excludeNote &&
            excludeIds.includes(Number(props.row.original.id)) ? (
              <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                {excludeNote}
              </span>
            ) : (
              <Fragment>
                {props.getValue() === 0 ? (
                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                    Inactive
                  </span>
                ) : props.getValue() === 1 ? (
                  <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
                    Draft
                  </span>
                ) : null}
              </Fragment>
            )}
          </Link>
        ),
      },
    ],
    [excludeIds, excludeNote, selectedProducts]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setSearchQuery("");
        }}
      >
        <PlusCircleIcon className="mr-2 h-5 w-5" />
        {t("text_add_products")}
      </Button>
      <Transition.Root
        show={searchQuery === null ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setSearchQuery(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-greyish p-3 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all md:p-4 xl:p-5">
                <div className="pb-4">
                  <div className="flex items-center justify-between">
                    <Dialog.Title className="text-lg font-medium text-black">
                      {title}
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                        onClick={() => {
                          setSearchQuery(null);
                        }}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-gray-500">
                      Please select products from the list below.
                    </p>
                  </div>
                </div>
                <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for name"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery ?? ""}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string | null> };
                      }) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      onChange(selectedProducts);
                      setSearchQuery(null);
                    }}
                    loading={loading}
                    disabled={loading || selectedProducts.length === 0}
                  >
                    <ArrowsUpDownIcon className="mr-2 h-5 w-5" />
                    Done
                  </Button>
                </div>
                <TableCursorInfinite
                  data={products}
                  columns={columns}
                  loading={loading}
                  totalRows={data?.fetchProducts?.totalCount || 0}
                  pageLimit={pageLimit.current}
                  fetching={isMorefetching}
                  hasNextPage={
                    data?.fetchProducts?.pageInfo?.hasNextPage ?? false
                  }
                  fetchNextPage={fetchNext}
                  filtering={filtering}
                  state={{
                    rowSelection,
                  }}
                  enableRowSelection={(row) =>
                    !excludeIds.includes(Number(row.original.id))
                  }
                  enableSubRowSelection={false}
                  onRowSelectionChange={setRowSelection}
                  getCoreRowModel={getCoreRowModel()}
                  enableHeader={false}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
