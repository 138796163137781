import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  EDIT_PRODUCT,
  GET_PRODUCT_BY_ID,
  GET_PRODUCTS,
  PreviewProduct,
  VariantEx,
  VariantOption,
} from "../../../graphql/inventory/products";
import Form from "./components/FormUpdate";

type BinLocation = {
  id: string;
  name: string;
  description: string;
  branch: string;
  address: string;
  createdAt: string;
  status: boolean;
};
type Category = {
  id: string;
  name: string;
  description: string;
  categoryType: number;
  parent: Category;
  createdAt: string;
  status: boolean;
};
type Department = {
  id: string;
  name: string;
  description: string;
  parent: Department;
  createdAt: string;
  status: boolean;
};
type Vendor = {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  vendorCode: string;
  webAddress: string;
  address: string;
  comments: string;
  createdAt: string;
  status: boolean;
};

type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
  costUpdated: boolean;
};

type Variant = {
  id: string;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  pricingLevelDetails?: Details[];
  basePrice?: number;
  comparePrice?: number;
  optionValues: {
    option: string;
    optionValue: string[];
  }[];
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
  variantTitle?: {
    id: string;
    name: string;
    option: {
      id: string;
      name: string;
    };
  }[];
  type: VariantEx;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: {
    id: string;
    name: string;
  }[];
  status: boolean;
};

type Product = {
  id: string;
  name: string;
  handle: string;
  description: string;
  basePrice: number;
  binLocation: BinLocation;
  categories: Category[];
  comparePrice: number;
  department: Department;
  featureImageUrl?: string;
  options: string[];
  inventory: number;
  pricingLevelName: PricingLevel[];
  hasVariants: boolean;
  variants: Variant[];
  returnableItem: boolean;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  vendor: Vendor | null;
  galleryImages: {
    id: number;
    imageUrl: string;
  }[];
  relatedProducts: PreviewProduct[];
  createdAt: string;
  status: any;
};

type VariantItemFeed = {
  id: number;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  basePrice: number;
  comparePrice: number;
  cost: number;
  marketCost: number;
  minimumQuantity: number;
  inventory: number;
  variantImageUrl: string;
  optionValues: {
    option: string;
    optionValue: string[];
  }[];
  variantTitle?: {
    id: string;
    name: string;
    option: {
      id: string;
      name: string;
    };
  }[];
};

const ProductUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();
  let { productId } = useParams();
  const [productOptions, setProductOptions] = useState<string[]>([]);
  const [variantItems, setVariantItems] = useState<Variant[]>([]);
  const [variantOptions, setVariantOptions] = useState<VariantOption[]>([]);

  const { data, loading, error } = useQuery<{ fetchProduct: Product }>(
    GET_PRODUCT_BY_ID,
    {
      variables: {
        id: productId,
      },
    }
  );
  const product = useMemo<Product | undefined>(() => {
    if (!data?.fetchProduct) return undefined;
    const initiaProduct = data.fetchProduct;
    if (
      initiaProduct.variants?.length &&
      initiaProduct.variants[0].variantTitle?.length
    ) {
      const updatedVariantOptions: VariantOption[] = [];
      const updatedVariantItems = initiaProduct.variants?.map((variant) => {
        const optionValues =
          variant?.variantTitle?.map((vt: any) => {
            return {
              option: vt.option.name,
              optionValue: vt.name,
            };
          }) || [];

        const options = optionValues.flatMap((ov) => ov.option);
        const values = optionValues.flatMap((ov) => ov.optionValue);

        options.forEach((option, index) => {
          const optionIndex = updatedVariantOptions.findIndex(
            (v) => v.type === option
          );
          if (optionIndex === -1) {
            updatedVariantOptions.push({
              id: String(updatedVariantOptions.length + 1),
              type: option,
              value: [values[index]],
            });
          } else {
            if (
              !updatedVariantOptions[optionIndex].value.includes(values[index])
            ) {
              updatedVariantOptions[optionIndex].value.push(values[index]);
            }
          }
        });

        return {
          id: variant.id,
          stockCode: variant.stockCode,
          barCode: variant.barCode,
          plu: variant.plu || null,
          basePrice: variant.basePrice,
          comparePrice: variant.comparePrice,
          optionValues: optionValues,
          cost: variant.cost,
          marketCost: variant.marketCost,
          minimumQuantity: variant.minimumQuantity,
          inventory: variant.inventory,
          variantImageUrl: variant.variantImageUrl,
          variantTitle: variant.variantTitle,
          type: VariantEx.OLD,
        };
      });
      setProductOptions(updatedVariantOptions.map((v) => v.type) || []);
      setVariantItems(updatedVariantItems || []);
      setVariantOptions(updatedVariantOptions);
    } else {
      setProductOptions([]);
      setVariantItems([]);
      setVariantOptions([]);
    }

    return data?.fetchProduct;
  }, [data]);

  const [updateProduct] = useMutation(EDIT_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          first: 50,
          searchQuery: "",
          searchFilter: [],
          status: null,
          stockCode: "",
          vendorId: null,
          categoryId: null,
          departmentId: null,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!product) return;

    updateProduct({
      variables: {
        id: productId,
        name: values.name,
        handle: values.handle,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        departmentId: values.department
          ? parseInt(values.department.value)
          : null,
        categoryIds: values.categories
          ? values.categories.map((item: any) => parseInt(item.value))
          : [],
        vendorId: values.vendor ? parseInt(values.vendor.value) : null,
        binLocationId: values.binLocation
          ? parseInt(values.binLocation.value)
          : null,
        featureImageUrl: values.featureImageUrl,
        options: values.options,
        hasVariants: values.variants?.length ? true : false,
        variants: values.hasVariants
          ? values.variants.map((v: VariantItemFeed) => {
              let costUpdated = false;
              const isExisting = product.variants?.find(
                (variant) => variant.id === String(v.id)
              );
              if (isExisting) {
                if (isExisting.cost !== v.cost) {
                  costUpdated = true;
                }
              }
              return {
                variantId: String(v.id).length < 10 ? v.id : "",
                stockCode: v.stockCode,
                barCode: v.barCode ? v.barCode : null,
                plu: v.plu ? v.plu : null,
                basePrice: v.basePrice,
                comparePrice: v.comparePrice ? v.comparePrice : 0,
                optionValues: v.optionValues,
                cost: v.cost,
                marketCost: v.marketCost,
                minimumQuantity: v.minimumQuantity,
                inventory: v.inventory,
                variantImageUrl: v.variantImageUrl,
                costUpdated: costUpdated,
              };
            })
          : [
              {
                variantId: product.variants[0].id,
                stockCode: values.stockCode,
                barCode: values.barCode ? values.barCode : null,
                plu: values.plu ? values.plu : null,
                basePrice: values.basePrice,
                comparePrice: values.comparePrice ? values.comparePrice : 0,
                optionValues: [],
                cost: values.cost,
                marketCost: values.marketCost,
                minimumQuantity: values.minimumQuantity,
                inventory: values.inventory,
                variantImageUrl: product.variants[0].variantImageUrl,
                costUpdated: product.variants[0].cost !== values.cost,
              },
            ],
        returnableItem: values.returnableItem,
        relatedIds: values?.relatedIds ? values.relatedIds : [],
        galleryImageUrls: values?.galleryImageUrls,
        status: values.status ? parseInt(values.status.value) : null,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.productUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Product updated successfully",
          });
          navigate(`/inventory/products/${productId}`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Product update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Product update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={ProductUpdateResource.name}
        heading={ProductUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Products",
            href: "/inventory/products",
          },
          {
            name: ProductUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-6xl px-5 py-6 sm:py-8">
        {loading || !product ? (
          <Waiting />
        ) : (
          <Form
            initialValues={{
              id: product.id,
              name: product.name ?? "",
              handle: product.handle ?? "",
              description: product.description ?? "",
              basePrice: product.variants[0]?.basePrice ?? 0,
              binLocation: product.binLocation
                ? {
                    label: product.binLocation.name,
                    value: product.binLocation.id,
                  }
                : null,

              categories: product.categories
                ? product.categories.map((item: any) => {
                    return {
                      label: item.name,
                      value: item.id,
                      disabled: item.categoryType === 1,
                      isFixed: item.categoryType === 1,
                    };
                  })
                : [],
              comparePrice: product.variants[0]?.comparePrice ?? 0,
              department: product.department
                ? {
                    label: product.department.name,
                    value: product.department.id,
                  }
                : null,
              featureImageUrl: product.featureImageUrl ?? "",
              pricingLevel: product.pricingLevelName.length
                ? product.pricingLevelName[0]
                : null,
              options: productOptions,
              cost: product.variants[0]?.cost ?? 0,
              marketCost: product.variants[0]?.marketCost ?? 0,
              minimumQuantity: product.variants[0]?.minimumQuantity ?? 0,
              inventory: product.variants[0]?.inventory ?? 0,
              hasVariants: product.hasVariants && product.variants.length > 0,
              variants: variantItems,
              returnableItem: product.returnableItem ?? false,
              stockCode: product.variants[0]?.stockCode ?? "",
              barCode: product.variants[0]?.barCode ?? "",
              plu: product.variants[0]?.plu ? product.variants[0].plu : null,
              vendor: product.vendor
                ? {
                    label: product.vendor.companyName,
                    value: product.vendor.id,
                  }
                : null,
              relatedIds: product.relatedProducts
                ? product.relatedProducts.map((p) =>
                    typeof p.id === "string" ? parseInt(p.id) : p.id
                  )
                : [],
              galleryImageUrls: product.galleryImages
                ? product.galleryImages.map((g) => g.imageUrl)
                : [],
              status: product.status
                ? {
                    label:
                      product.status === 2
                        ? "Active"
                        : product.status === 0
                        ? "Inactive"
                        : "Draft",
                    value: String(product.status),
                  }
                : null,
            }}
            initialVariantOptions={variantOptions}
            relatedProducts={product.relatedProducts}
            initialStockCodes={[
              ...product.variants.flatMap((v) => v.stockCode),
            ]}
            initialBarCodes={[
              ...product.variants.flatMap((v) => v.barCode ?? ""),
            ]}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  );
};

export default ProductUpdate;
export const ProductUpdateResource: ResourceProps = {
  name: "Edit Product",
  description: "Update an existing product",
  access: ["update-products"],
  path: "products/:productId",
};
