import { useMutation, useQuery } from "@apollo/client/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";

import { ErrorFallback, Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import { LimitBy, TableCursor } from "../../../../components/table";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  FleetVendor,
  FleetVendorClassification,
  GET_FLEETVENDORS,
  REMOVE_FLEETVENDOR,
} from "../../../../graphql/fleets/settings/vendors";
import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import { formatDate } from "../../../../utils";
import { AuthShield } from "../../../auth/core";

const FleetVendorList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();

  const { data, loading, error, refetch } = useQuery<{
    fetchFleetVendors: FleetVendor[];
  }>(GET_FLEETVENDORS);
  const fleetVendors = useMemo<FleetVendor[]>(
    () =>
      data?.fetchFleetVendors.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data?.fetchFleetVendors]
  );

  const [deleteFleetVendor] = useMutation(REMOVE_FLEETVENDOR, {
    refetchQueries: [{ query: GET_FLEETVENDORS }],
  });
  const handleDelete = async (id: string) => {
    return await deleteFleetVendor({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.fleetVendorDelete) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vendor deleted successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vendor delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Vendor delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<FleetVendor, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "address",
        header: "Address",
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "classification",
        header: "Type",
        cell: (info) => {
          return info.getValue() === FleetVendorClassification.FUEL
            ? "Fuel"
            : info.getValue() === FleetVendorClassification.SERVICE
            ? "Service"
            : info.getValue() === FleetVendorClassification.VEHICLE
            ? "Vehicle"
            : "";
        },
      },
      {
        accessorKey: "email",
        header: "Email",
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.email,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-fleetVendors"]}>
              <Button
                type="link"
                href={`/fleets/settings/vendors/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-fleetVendors"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={FleetVendorListResource.name}
        heading={FleetVendorListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: FleetVendorListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {FleetVendorListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {FleetVendorListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-fleetVendors"]}>
              <Button type="link" href="/fleets/settings/vendors/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add Vendor
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={fleetVendors}
          columns={columns}
          loading={loading}
          totalRows={fleetVendors.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No vendors found"
          fallbackText="You can add a new vendor by clicking on add vendor button."
        />
      </div>
    </>
  );
};

export default FleetVendorList;
export const FleetVendorListResource: ResourceProps = {
  name: "Vendors",
  description: "A list of fleet vendors",
  access: ["read-fleetVendors"],
  path: "vendors",
};
