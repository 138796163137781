import { useMutation, useQuery } from "@apollo/client/react";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { BookOpenIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import placeholder from "../../../assets/placeholder.svg";
import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import {
  IndeterminateCheckbox,
  LimitBy,
  TableCursor,
} from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  CustomerType,
  GET_CUSTOMERS,
  REMOVE_CUSTOMER,
  SEND_ACCOUNT_INVITATION,
  SEND_BULK_ACCOUNT_INVITATION,
  UPDATE_CUSTOMER_STATUS,
} from "../../../graphql/sales/customers";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { formatDate } from "../../../utils";
import { AuthShield } from "../../auth/core";
import FormImport from "./components/FormImport";

enum CustomerStatus {
  INACTIVE,
  PENDING,
  ACTIVE,
  REJECTED,
  INVITAION,
}

type User = {
  id: string;
  fullName: string;
  email: string;
  roles: {
    roleName: string;
  }[];
  createdAt: string;
  status: boolean;
};

type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: Customer[];
  createdAt: string;
  status: boolean;
};

type DeliveryRun = {
  id?: string;
  name: string;
  dock: {
    id: string;
    name: string;
  };
  createdAt: string;
  status: boolean;
};

type TradingAddresses = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  primaryAddress: boolean;
};

type Customer = {
  id: string;
  abnNo: string;
  email: string;
  createdAt: string;
  creditApplicationStatus: boolean;
  creditLimit: number;
  customerCode: string;
  customerGroups: CustomerGroup[];
  customerName: string;
  companyName: string;
  customerContact: {
    id: string;
  }[];
  profileImageUrl: string;
  customerPlu: string;
  customerType: number;
  deliveryInstruction: string;
  deliveryRuns: DeliveryRun;
  packingInstruction: string;
  salesRep: User;
  storeContact: string;
  storeEmail: string;
  storeFax: string;
  storePhoneNumber: string;
  tradingAddresses: TradingAddresses[];
  status: number;
};

const CustomerList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();

  const [pendingCustomers, setPendingCustomers] = useState<Customer[]>([]);
  const [loaderRefetch, setLoaderRefetch] = useState<boolean>(false);
  const [showImportForm, setShowImportForm] = useState<boolean>(false);

  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const [rowSelection, setRowSelection] = useState({});

  const { data, loading, error, refetch } = useQuery<{
    fetchCustomers: Customer[];
  }>(GET_CUSTOMERS);

  const customers = useMemo<Customer[]>(() => {
    if (data?.fetchCustomers) {
      const updatedCustomers = data.fetchCustomers.map((item: Customer) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      }));
      if (updatedCustomers.length > 0) {
        const pendingCustomers = updatedCustomers.filter(
          (customer) => customer.status === 1
        );
        setPendingCustomers(pendingCustomers);
      }
      return updatedCustomers;
    }
    return [];
  }, [data]);

  const [customerStatusUpdate] = useMutation(UPDATE_CUSTOMER_STATUS, {
    refetchQueries: [{ query: GET_CUSTOMERS }],
  });

  const [sendAccountInvitation, { loading: loadingInvitation }] = useMutation(
    SEND_ACCOUNT_INVITATION,
    {
      refetchQueries: [{ query: GET_CUSTOMERS }],
    }
  );

  const [sendBulkAccountInvitation] = useMutation(
    SEND_BULK_ACCOUNT_INVITATION,
    {
      refetchQueries: [{ query: GET_CUSTOMERS }],
    }
  );

  const updateCustomerStatus = useCallback(
    (id: string, status: number) => {
      customerStatusUpdate({
        variables: {
          id,
          status,
        },
      })
        .then(({ data }) => {
          if (data?.customerStatusUpdate) {
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              message: data?.customerStatusUpdate?.message,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              message: "Something went wrong",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            message: error.message,
          });
        });
    },
    [customerStatusUpdate, addNotify, refetch]
  );

  const inviteCustomer = useCallback(
    (id: string) => {
      sendAccountInvitation({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data?.sendAccountInvitation) {
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              message: data?.sendAccountInvitation?.message,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              message: "Something went wrong",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            message: error.message,
          });
        });
    },
    [sendAccountInvitation, addNotify, refetch]
  );

  const inviteCustomers = useCallback(() => {
    if (Object.keys(rowSelection).length === 0) {
      return addNotify({
        type: NotifyType.ERROR,
        message: "Please select at least one customer to invite.",
      });
    }
    const selectedIdx = Object.keys(rowSelection);
    const selectedCustomers = customers.filter((customer, idx) =>
      selectedIdx.includes(idx.toString())
    );
    const validCustomers = selectedCustomers.filter(
      (customer) => customer.status === CustomerStatus.INVITAION
    );
    if (validCustomers.length === 0) {
      return addNotify({
        type: NotifyType.ERROR,
        message: "Please select at least one uninvited customer to invite.",
      });
    }
    const ids = validCustomers.map((customer) => customer.id);
    const emails = validCustomers.map((customer) => customer.email);

    sendBulkAccountInvitation({
      variables: {
        ids,
      },
    })
      .then(({ data }) => {
        if (data?.sendBulkAccountInvitation) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            message: `Invitation sent to (${emails.join(", ")}) successfully.`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            message: "Something went wrong",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          message: error.message,
        });
      });
  }, [rowSelection, customers, sendBulkAccountInvitation, addNotify, refetch]);

  const [deleteCustomer] = useMutation<{
    customerDelete: {
      customer: Customer;
    };
  }>(REMOVE_CUSTOMER, {
    refetchQueries: [{ query: GET_CUSTOMERS }],
  });
  const handleDelete = useCallback(
    async (id: string) => {
      return await deleteCustomer({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data?.customerDelete) {
            const deletedCustomer = data.customerDelete.customer;
            const customerType = deletedCustomer.customerType;
            const name =
              customerType === CustomerType.INDIVIDUAL
                ? deletedCustomer.customerName
                : deletedCustomer.companyName;
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: `Customer ${name} deleted`,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Customer delete failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer delete failed",
            message: error.message,
          });
        });
    },
    [deleteCustomer, addNotify, refetch]
  );

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const renderStatus = (status: CustomerStatus): JSX.Element => {
    switch (status) {
      case CustomerStatus.INACTIVE:
        return (
          <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
            Inactive
          </span>
        );
      case CustomerStatus.PENDING:
        return (
          <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
            Pending
          </span>
        );
      case CustomerStatus.ACTIVE:
        return (
          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
            Approve/Active
          </span>
        );
      case CustomerStatus.REJECTED:
        return (
          <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
            Rejected
          </span>
        );
      case CustomerStatus.INVITAION:
        return (
          <span className="inline-flex rounded-full bg-violet-100 px-2 text-xs font-medium leading-5 text-violet-800">
            Invited
          </span>
        );
    }
  };

  const columns = useMemo<ColumnDef<Customer, any>[]>(
    () => [
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "customerName",
        header: "Name",
        cell: ({ row }) => (
          <div className="flex items-center space-x-2 whitespace-nowrap">
            <Link
              to={`/sales/customers/${row.original.id}`}
              className="relative z-10 mr-2 h-12 w-12 overflow-hidden rounded-md"
            >
              <img
                className="h-full w-full object-cover"
                src={
                  row.original.profileImageUrl
                    ? row.original.profileImageUrl
                    : placeholder
                }
                alt={row.original.customerName}
              />
            </Link>
            <Link
              to={`/sales/customers/${row.original.id}`}
              className="inline-flex transition-all hover:text-gray-900"
            >
              {row.original.customerType === CustomerType.COMPANY
                ? row.original.companyName
                : row.original.customerName}
            </Link>
            {row.original.customerContact.length ? (
              <Link
                to={`/sales/settings/customer-contacts/${row.original.customerContact[0].id}`}
                target="_blank"
                className="inline-flex items-center rounded-full bg-violet-100 pl-1.5 pr-2 text-xs font-normal leading-6 text-violet-900 transition hover:bg-violet-200 hover:text-violet-900"
              >
                <span className="mr-1 h-3 w-3 rounded-full bg-white"></span>
                Contact Details
              </Link>
            ) : null}
          </div>
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        cell: (info) => {
          return (
            <div>
              <a href={`mailto:${info.getValue()}`} className="mr-3">
                {info.getValue()}
              </a>
              {info.row.original.status === CustomerStatus.INVITAION ? (
                <Button
                  variant="info"
                  border
                  onClick={() => {
                    inviteCustomer(info.row.original.id);
                  }}
                  className="mt-2 px-2 py-1 text-xs"
                >
                  Send Invitation
                </Button>
              ) : null}
            </div>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) => renderStatus(info.getValue()),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-customers"]}>
              <Button
                type="link"
                href={`/sales/customers/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.customerName}
                </span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-customers"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.customerName,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.customerName}
                </span>
              </Button>
            </AuthShield>
            {props.row.original.status === 1 ? (
              <AuthShield access={["update-customers"]}>
                <Fragment>
                  <Button
                    variant="primary"
                    border
                    onClick={() => {
                      updateCustomerStatus(props.row.original.id, 2);
                    }}
                    className="px-2"
                  >
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="text-md h-6 w-6"
                    />
                    <span className="sr-only">
                      Approve, {props.row.original.customerName}
                    </span>
                  </Button>
                  <Button
                    variant="warning"
                    border
                    onClick={() => {
                      updateCustomerStatus(props.row.original.id, 3);
                    }}
                    className="px-2"
                  >
                    <XMarkIcon aria-hidden="true" className="text-md h-6 w-6" />
                    <span className="sr-only">
                      Reject, {props.row.original.customerName}
                    </span>
                  </Button>
                </Fragment>
              </AuthShield>
            ) : null}
          </div>
        ),
        size: 220,
      },
    ],
    [inviteCustomer, updateCustomerStatus]
  );

  const handleRefetch = useCallback(() => {
    setLoaderRefetch(true);
    refetch().finally(() => {
      setLoaderRefetch(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={CustomerListResource.name}
        heading={CustomerListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: CustomerListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {CustomerListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {CustomerListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-customers"]}>
              <Button type="link" href="/sales/customers/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add customer
              </Button>
            </AuthShield>
            <AuthShield access={["update-customers"]}>
              <Button
                variant="tertiary"
                onClick={() => {
                  setShowImportForm(true);
                }}
              >
                <UserPlusIcon className="mr-2 h-5 w-5" />
                Import
              </Button>
            </AuthShield>
            <AuthShield access={["read-customercontacts"]}>
              <Button
                type="link"
                href="/sales/settings/customer-contacts"
                variant="info"
                border
              >
                <BookOpenIcon className="mr-2 h-5 w-5" />
                Customer contacts
              </Button>
            </AuthShield>
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className={loaderRefetch ? "animate-spin" : ""}
            >
              <ArrowPathIcon aria-hidden="true" className="h-5 w-5" />
              <span className="sr-only">refresh customers list</span>
            </Button>
          </div>
        </div>

        {pendingCustomers.length ? (
          <div className="mb-5 rounded-md border border-yellow-300 bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  {pendingCustomers.length}{" "}
                  {pendingCustomers.length === 1
                    ? "customer is"
                    : "customers are"}{" "}
                  waiting for approval.{" "}
                  <span className="font-medium text-yellow-700 underline hover:text-yellow-600">
                    Please review{" "}
                    {pendingCustomers.length === 1 ? "him" : "them"} for
                    approval.
                  </span>
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <TableCursor
          data={customers}
          columns={columns}
          loading={loading}
          totalRows={customers.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          state={{
            rowSelection,
          }}
          enableRowSelection={(row) => row.original.status === 1}
          renderSelection={() =>
            Object.keys(rowSelection).length ? (
              <Button
                loading={loading || loadingInvitation}
                onClick={inviteCustomers}
                variant="info"
                border
              >
                Send invitation
              </Button>
            ) : (
              <></>
            )
          }
          onRowSelectionChange={setRowSelection}
          getCoreRowModel={getCoreRowModel()}
          fallbackTitle="No customers found"
          fallbackText="You can add a new customer by clicking on add customer button."
        />
      </div>
      <FormImport open={showImportForm} setOpen={setShowImportForm} />
    </>
  );
};

export default CustomerList;
export const CustomerListResource: ResourceProps = {
  name: "Customers",
  description: "A list of Customers",
  access: ["read-customers"],
  path: "customers",
};
