import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, Field } from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  renderFuelUnit,
  renderMeasurementUnit,
} from "../../../../graphql/fleets/vehicles/pages/settings";
import { VehicleSpecForm } from "../../../../graphql/fleets/vehicles/pages/specifications";
import { useFormikErrors } from "../../../../utils";

export default function Form({
  initialValues,
  onChange = () => {},
  onClear = () => {},
  onSubmit,
  actionLabel,
  vehicleBasic,
}: {
  initialValues: VehicleSpecForm;
  onChange?: (values: VehicleSpecForm) => void;
  onClear?: () => void;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
  vehicleBasic: VehicleBasic | null;
}) {
  const { t } = useTranslation();

  const ValidationSchema = Yup.object().shape({
    bedLength: Yup.number().nullable(),
    compression: Yup.number().nullable(),
    curbWeight: Yup.number().nullable(),
    cylinders: Yup.number().nullable(),
    displacement: Yup.number().nullable(),
    engineBore: Yup.number().nullable(),
    engineBrand: Yup.string().nullable(),
    engineSummary: Yup.string().nullable(),
    epaCity: Yup.string().nullable(),
    epaCombined: Yup.string().nullable(),
    epaHighway: Yup.string().nullable(),
    fuelQuality: Yup.number().nullable(),
    fuelTankOneCapacity: Yup.number().nullable(),
    fuelTankTwoCapacity: Yup.number().nullable(),
    grossVehicleWeightRating: Yup.number().nullable(),
    groundClearance: Yup.number().nullable(),
    height: Yup.number().nullable(),
    interiorVolume: Yup.number().nullable(),
    length: Yup.number().nullable(),
    maxPayload: Yup.number().nullable(),
    maxTorque: Yup.number().nullable(),
    oilCapacity: Yup.number().nullable(),
    passengerVolume: Yup.number().nullable(),
    stroke: Yup.number().nullable(),
    towingCapacity: Yup.number().nullable(),
    valves: Yup.number().nullable(),
    width: Yup.number().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  useEffect(() => {
    onChange(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">
          Dimensions
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_width")}
              name="width"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.width || ""}
              touched={formik.touched.width}
              errors={formik.errors.width}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_height")}
              name="height"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.height || ""}
              touched={formik.touched.height}
              errors={formik.errors.height}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_length")}
              name="length"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.length || ""}
              touched={formik.touched.length}
              errors={formik.errors.length}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_interior_volume")}
              name="interiorVolume"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.interiorVolume || ""}
              touched={formik.touched.interiorVolume}
              errors={formik.errors.interiorVolume}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_passenger_volume")}
              name="passengerVolume"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.passengerVolume || ""}
              touched={formik.touched.passengerVolume}
              errors={formik.errors.passengerVolume}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_ground_clearance")}
              name="groundClearance"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.groundClearance || ""}
              touched={formik.touched.groundClearance}
              errors={formik.errors.groundClearance}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_bed_length")}
              name="bedLength"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bedLength || ""}
              touched={formik.touched.bedLength}
              errors={formik.errors.bedLength}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">Weight</div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_curb_weight")}
              name="curbWeight"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.curbWeight || ""}
              touched={formik.touched.curbWeight}
              errors={formik.errors.curbWeight}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_gross_vehicle_weight_rating")}
              name="grossVehicleWeightRating"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.grossVehicleWeightRating || ""}
              touched={formik.touched.grossVehicleWeightRating}
              errors={formik.errors.grossVehicleWeightRating}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">
          Performance
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_towing_capacity")}
              name="towingCapacity"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.towingCapacity || ""}
              touched={formik.touched.towingCapacity}
              errors={formik.errors.towingCapacity}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_max_payload")}
              name="maxPayload"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.maxPayload || ""}
              touched={formik.touched.maxPayload}
              errors={formik.errors.maxPayload}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">
          Fuel Economy
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_epa_city")}
              name="epaCity"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.epaCity || ""}
              touched={formik.touched.epaCity}
              errors={formik.errors.epaCity}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_epa_highway")}
              name="epaHighway"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.epaHighway || ""}
              touched={formik.touched.epaHighway}
              errors={formik.errors.epaHighway}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_epa_combined")}
              name="epaCombined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.epaCombined || ""}
              touched={formik.touched.epaCombined}
              errors={formik.errors.epaCombined}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">Engine</div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-12">
            <Field
              title={t("text_engine_summary")}
              name="engineSummary"
              type="textarea"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.engineSummary || ""}
              touched={formik.touched.engineSummary}
              errors={formik.errors.engineSummary}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_engine_brand")}
              name="engineBrand"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.engineBrand || ""}
              touched={formik.touched.engineBrand}
              errors={formik.errors.engineBrand}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_engine_bore")}
              name="engineBore"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.engineBore || ""}
              touched={formik.touched.engineBore}
              errors={formik.errors.engineBore}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_max_torque")}
              name="maxTorque"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.maxTorque || ""}
              touched={formik.touched.maxTorque}
              errors={formik.errors.maxTorque}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_cylinders")}
              name="cylinders"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cylinders || ""}
              touched={formik.touched.cylinders}
              errors={formik.errors.cylinders}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_displacement")}
              name="displacement"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.displacement || ""}
              touched={formik.touched.displacement}
              errors={formik.errors.displacement}
              isSuffix
              suffix={renderMeasurementUnit(vehicleBasic?.measurementUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_compression")}
              name="compression"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.compression || ""}
              touched={formik.touched.compression}
              errors={formik.errors.compression}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_stroke")}
              name="stroke"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stroke || ""}
              touched={formik.touched.stroke}
              errors={formik.errors.stroke}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_valves")}
              name="valves"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.valves || ""}
              touched={formik.touched.valves}
              errors={formik.errors.valves}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">Fuel</div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_fuel_quality")}
              name="fuelQuality"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fuelQuality || ""}
              touched={formik.touched.fuelQuality}
              errors={formik.errors.fuelQuality}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_fuel_tank_one_capacity")}
              name="fuelTankOneCapacity"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fuelTankOneCapacity || ""}
              touched={formik.touched.fuelTankOneCapacity}
              errors={formik.errors.fuelTankOneCapacity}
              isSuffix
              suffix={renderFuelUnit(vehicleBasic?.fuelUnit)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_fuel_tank_two_capacity")}
              name="fuelTankTwoCapacity"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fuelTankTwoCapacity || ""}
              touched={formik.touched.fuelTankTwoCapacity}
              errors={formik.errors.fuelTankTwoCapacity}
              isSuffix
              suffix={renderFuelUnit(vehicleBasic?.fuelUnit)}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">Oil</div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_oil_capacity")}
              name="oilCapacity"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.oilCapacity || ""}
              touched={formik.touched.oilCapacity}
              errors={formik.errors.oilCapacity}
              isSuffix
              suffix={renderFuelUnit(vehicleBasic?.fuelUnit)}
            />
          </div>
        </div>
      </fieldset>
      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/fleets/vehicles" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
