import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Popover } from "@headlessui/react";
import { PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { classNames, toVariableName } from "../../utils";
import { Button } from "./";
import Item from "./formbuilder/Item";
import { fieldItems, IField } from "./formbuilder/types";
import Integer from "./formulabuilder/Integer";
import { SortableItem } from "./formulabuilder/SortableItem";
import { IFieldType, IFormula } from "./formulabuilder/types";
export interface IFormulaProps {
  name: string;
  type: IFieldType;
}

const symbols: IFormula[] = [
  {
    id: nanoid(11),
    name: "+",
    type: IFieldType.symbol,
    color: "red",
    description: "Addition",
  },
  {
    id: nanoid(11),
    name: "-",
    type: IFieldType.symbol,
    color: "violet",
    description: "Subtraction",
  },
  {
    id: nanoid(11),
    name: "*",
    type: IFieldType.symbol,
    color: "green",
    description: "Multiplication",
  },
  {
    id: nanoid(11),
    name: "/",
    type: IFieldType.symbol,
    color: "blue",
    description: "Division",
  },
  {
    id: nanoid(11),
    name: "%",
    type: IFieldType.symbol,
    color: "purple",
    description: "Modulo",
  },
  {
    id: nanoid(11),
    name: "(",
    type: IFieldType.symbol,
    color: "orange",
    description: "Left parenthesis",
  },
  {
    id: nanoid(11),
    name: ")",
    type: IFieldType.symbol,
    color: "orange",
    description: "Right parenthesis",
  },
];

export type IFieldValues = {
  fieldName: string;
  fieldKey: string;
  fieldType: string;
  fieldOptions: string[];
};

export function FieldFormulaBuilder({
  values,
  onChange,
}: {
  values: {
    priceFields: IFieldValues[];
    priceFormula: string[];
  };
  onChange: (priceFields: IFieldValues[], priceFormula: string[]) => void;
}) {
  const { t } = useTranslation();
  const [items, setItems] = useState<IFormula[]>([]);
  const [fieldPriceItems, setFieldPriceItems] = useState<IField[]>([]);
  const [variables, setVariables] = useState<IFormula[]>([]);

  const [openNum, setOpenNum] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const updatedVariables = [
      ...values.priceFields.map((v) => {
        return {
          id: nanoid(),
          name: toVariableName(v.fieldName),
          type: IFieldType.variable,
          color: "light-green",
          description: "Variable",
        };
      }),
    ];
    if (values.priceFields.length) {
      const updatedPriceFields = [
        ...values.priceFields.map((f) => {
          const itemOrg = fieldItems.find(
            (i) => i.fieldType === f.fieldType
          ) as IField;
          const id =
            updatedVariables.find(
              (vr) => vr.name === toVariableName(f.fieldName)
            )?.id || nanoid();
          return {
            ...itemOrg,
            id: id,
            fieldName: f.fieldName,
            fieldKey: f.fieldKey,
            fieldType: f.fieldType,
            fieldOptions: f.fieldOptions,
          };
        }),
      ];
      setFieldPriceItems(updatedPriceFields);
      setVariables([...symbols, ...updatedVariables]);
    } else {
      setVariables(symbols);
      setFieldPriceItems([]);
    }

    if (values.priceFormula.length) {
      const updatedPriceFormula = [
        ...values.priceFormula.map((v) => {
          const id =
            updatedVariables.find((vr) => vr.name === v)?.id || nanoid();
          return {
            id: id,
            name: v,
            type: IFieldType.symbol,
            color: "light-green",
            description: "Variable",
          };
        }),
      ];
      setItems(updatedPriceFormula);
    } else {
      setItems([]);
    }
  }, [values]);

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;

    if (active.id !== over.id) {
      let newItems: IFormula[];

      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      newItems = arrayMove(items, oldIndex, newIndex);

      setItems(newItems);
      const formatedFieldItems = fieldPriceItems.map((fi) => {
        const { id, icon, description, ...rest } = fi;
        return rest;
      });
      onChange(
        formatedFieldItems,
        newItems.map((i) => i.name)
      );
    }
  }

  function handleSymbolVariable(item: IFormula) {
    const exist = items.some((i) => i.id === item.id);
    if (exist) return toast.error("Variable already added to the formula.");
    const newItems: IFormula[] = [...items, item];

    setItems(newItems);
    const formatedFieldItems = fieldPriceItems.map((fi) => {
      const { icon, description, ...rest } = fi;
      return rest;
    });
    onChange(
      formatedFieldItems,
      newItems.map((i) => i.name)
    );
  }

  function deleteItem(id: UniqueIdentifier | string) {
    let newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
    const formatedFieldItems = fieldPriceItems.map((fi) => {
      const { id, icon, description, ...rest } = fi;
      return rest;
    });
    onChange(
      formatedFieldItems,
      newItems.map((i) => i.name)
    );
  }

  return (
    <div className="relative">
      <div className="mb-4 grid grid-cols-3 gap-4">
        <div className="col-span-2 rounded-md border border-gray-300 bg-greyish">
          <div className="px-4 pb-6 pt-2">
            {fieldPriceItems.length > 0
              ? fieldPriceItems.map((element) => (
                  <Item
                    key={element.id}
                    id={element.id}
                    element={element}
                    updateItem={(id, item) => {
                      const newItems = fieldPriceItems.map((fi) => {
                        if (fi.id === id) {
                          return item;
                        }
                        return fi;
                      });
                      setFieldPriceItems(newItems);
                      const found = variables.findIndex((v) => v.id === id);
                      if (found !== -1) {
                        const newVariables = [...variables];
                        newVariables[found] = {
                          ...newVariables[found],
                          name: toVariableName(item.fieldName),
                        };
                        setVariables(newVariables);
                      } else {
                        setVariables([
                          ...variables,
                          {
                            id: item.id,
                            name: toVariableName(item.fieldName),
                            type: IFieldType.variable,
                            color: "light-green",
                            description: "Variable",
                          },
                        ]);
                      }
                      const foundItem = items.findIndex((i) => i.id === id);
                      if (foundItem !== -1) {
                        const newItems = [...items];
                        newItems[foundItem] = {
                          ...newItems[foundItem],
                          name: toVariableName(item.fieldName),
                        };
                        setItems(newItems);
                      }
                    }}
                    deleteItem={(id) => {
                      const newItems = fieldPriceItems.filter(
                        (fi) => fi.id !== id
                      );
                      setFieldPriceItems(newItems);
                      //   const formatedFieldItems = newItems.map((fi) => {
                      //     const { id, icon, description, ...rest } = fi;
                      //     return rest;
                      //   });
                      //   onChange(
                      //     formatedFieldItems,
                      //     items.map((i) => i.name)
                      //   );
                      setVariables((prev) => prev.filter((v) => v.id !== id));
                      setItems((prev) => prev.filter((i) => i.id !== id));
                    }}
                  />
                ))
              : null}
          </div>
        </div>
        <div className="py-4">
          <div className="space-y-2">
            <div className="mb-1 block text-sm font-medium text-gray-900">
              {t("text_builder_tools")}
            </div>
            {fieldItems.map((field: IField, index) => (
              <button
                key={`field-${index}`}
                type="button"
                className={classNames(
                  "relative inline-flex items-center rounded-md border border-gray-300 bg-white text-sm font-normal text-gray-700 hover:bg-gray-50",
                  "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
                )}
                onClick={() => {
                  setFieldPriceItems([
                    ...fieldPriceItems,
                    {
                      ...field,
                      id: nanoid(),
                    },
                  ]);
                }}
                disabled={fieldPriceItems.some(
                  (item) =>
                    (field.fieldKey === "$minimumMarkup" ||
                      field.fieldKey === "$maximumMarkup") &&
                    item.fieldKey === field.fieldKey
                )}
              >
                <span className="px-4 py-2">
                  <span
                    className={classNames(
                      "-ml-1 mr-2 h-5 w-5 text-gray-400",
                      field.icon ? field.icon : ""
                    )}
                    aria-hidden="true"
                  ></span>
                  {t(
                    field.fieldKey === "$minimumMarkup" ||
                      field.fieldKey === "$maximumMarkup"
                      ? field.fieldName
                      : field.fieldType
                  )}
                </span>
                <span className="border-l border-gray-300 p-2">
                  <PlusIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "relative isolate z-10 inline-flex w-full rounded-md"
        )}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <div className="relative inline-flex min-h-[3rem] w-full flex-wrap items-center space-x-0.5 rounded-md border border-primary-300 bg-greyish px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 md:min-h-[4rem] md:py-4">
            <SortableContext
              items={items.map((item) => item.id)}
              strategy={rectSortingStrategy}
            >
              {items.map((item) => (
                <SortableItem key={item.id} {...item} deleteItem={deleteItem} />
              ))}
            </SortableContext>
          </div>
        </DndContext>
        <Popover className="relative flex">
          <Popover.Button className="relative -ml-px inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-700 focus:z-10 focus:outline-none focus-visible:outline-none">
            <QuestionMarkCircleIcon
              className="h-6 w-6 text-gray-700"
              aria-hidden="true"
            />
            <span className="sr-only">
              Help with <span className="font-medium">Formula</span>
            </span>
          </Popover.Button>
          <Popover.Panel className="absolute right-5 top-full z-10 mt-2 w-72 rounded-md bg-primary-300 p-2.5 text-xs font-normal text-black">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 95 95"
              enableBackground="new 0 0 95 95"
              className="absolute -top-2.5 right-0 h-5 w-5 rotate-1 text-primary-300"
              aria-hidden="true"
            >
              <polygon fill="currentColor" points="95,95 0,95 95,0" />
            </svg>
            Click to add or remove a variable/symbol to the formula
          </Popover.Panel>
        </Popover>
      </div>
      <div className="flex flex-wrap">
        {variables.map((variable) => (
          <Button
            key={variable.id}
            variant="icon"
            className={classNames(
              "text-md mr-2 mt-2 rounded-md border-0",
              variable.type === IFieldType.variable
                ? "bg-primary-100 px-2 py-1"
                : "flex h-8 w-8 items-center justify-center bg-blue-50"
            )}
            style={{ color: variable.color }}
            onClick={() => handleSymbolVariable(variable)}
          >
            <div className="scale-90">{variable.name}</div>
          </Button>
        ))}
        <Button
          variant="icon"
          className={classNames(
            "text-md mr-2 mt-2 rounded-md border-0 bg-violet-100 px-2 py-1"
          )}
          onClick={() => {
            setOpenNum(true);
          }}
        >
          <div className="scale-90">add number</div>
        </Button>

        <Integer
          openNum={openNum}
          setOpenNum={setOpenNum}
          onChange={(value) => {
            setItems([
              ...items,
              {
                id: nanoid(),
                name: value,
                type: IFieldType.number,
                color: "#673ab7",
                description: `Number ${value}`,
              },
            ]);
          }}
        />
      </div>
    </div>
  );
}
