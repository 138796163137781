import { useQuery } from "@apollo/client";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Menu, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useEffect, useMemo, useState } from "react";

import { Spinner } from "../../../animations";
import { ErrorFallback } from "../../../components/core";
import { Button } from "../../../components/form";
import {
  GET_STOCK_ITEMS_WIDGET,
  StockItemsWidget,
  Widget,
} from "../../../graphql/dashboard";
import { classNames } from "../../../utils";

export function StockItems({
  item,
  updateBlocks,
}: {
  item: Widget;
  updateBlocks: (blocks: StockItemsWidget[]) => void;
}) {
  const { data, error, loading, refetch } = useQuery<{
    fetchStockItemsWidget: StockItemsWidget[];
  }>(GET_STOCK_ITEMS_WIDGET, {
    variables: {
      id: item.id,
    },
  });

  const blocks = useMemo<StockItemsWidget[]>(
    () => data?.fetchStockItemsWidget ?? [],
    [data?.fetchStockItemsWidget]
  );

  const [items, setItems] = useState<StockItemsWidget[]>([]);

  useEffect(() => {
    setItems(blocks);
  }, [blocks]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd({ active, over }: DragEndEvent) {
    if (!over) return;
    if (active.id !== over.id) {
      const overIndex = items.findIndex((item) => item.id === over.id);
      const activeIndex = items.findIndex((item) => item.id === active.id);

      const newItems = [...items];
      newItems.splice(overIndex, 0, newItems.splice(activeIndex, 1)[0]);
      updateBlocks(newItems);
      setItems(newItems);
    }
  }

  function removeBlock(id: string) {
    const newItems = items.map((block) => ({
      ...block,
      status: block.id === id ? false : block.status,
    }));
    updateBlocks(newItems);
    setItems(newItems);
  }

  function addBlock(id: string) {
    const newItems = items.map((block) => ({
      ...block,
      status: block.id === id ? true : block.status,
    }));
    updateBlocks(newItems);
    setItems(newItems);
  }

  if (error) return <ErrorFallback error={error} />;

  return (
    <Fragment>
      <div className="absolute right-12 top-5">
        <Menu as="div" className="relative z-10 inline-block text-left">
          <Menu.Button className="-my-2 flex items-center py-2 text-gray-500 hover:text-gray-400 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50">
            <span className="sr-only">Edit block from {item.name}</span>
            <PlusIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className="absolute right-0 mt-2 w-44 origin-top-right rounded-bl-lg rounded-br-lg rounded-tl-lg bg-white px-2 py-1 shadow-lg ring-1 ring-black ring-opacity-5  focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
              static
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 110.9 96"
                enableBackground="new 0 0 110.9 96"
                className="absolute -top-3 right-0 -z-0"
                fill="#ffffff"
                style={{
                  filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.1))",
                }}
              >
                <polygon points="110.9,0 0,96 110.9,96 " />
              </svg>
              <div className="py-1">
                {items.map((item, i) => (
                  <Menu.Button
                    key={`${item.handle}-${i}`}
                    onClick={() => {
                      if (!item.status) {
                        addBlock(item.id);
                      } else {
                        removeBlock(item.id);
                      }
                    }}
                    className="w-full"
                  >
                    <div className="py-0.5">
                      <div
                        className={classNames(
                          "group flex w-full items-center rounded-md px-1.5 py-1.5 text-left text-sm text-gray-700 transition ease-in-out",
                          item.status ? "bg-primary-700/10" : ""
                        )}
                      >
                        {item.status ? (
                          <XMarkIcon
                            className={classNames(
                              "mr-1 h-5 w-5 flex-shrink-0 text-center text-lg leading-5 text-primary-700"
                            )}
                            aria-hidden="true"
                          />
                        ) : (
                          <CheckIcon
                            className={classNames(
                              "mr-1 h-5 w-5 flex-shrink-0 text-center text-lg leading-5 text-gray-400 opacity-50"
                            )}
                            aria-hidden="true"
                          />
                        )}
                        <span className="flex-1 truncate">{item.name}</span>
                      </div>
                    </div>
                  </Menu.Button>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      {loading ? (
        <div className="flex justify-center py-10 text-primary md:py-16 xl:py-20">
          <Spinner />
        </div>
      ) : blocks.length ? (
        <div className="flex flex-col divide-y divide-gray-200 rounded-lg bg-white p-4 text-xs font-light shadow-sm">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              {items
                .filter((block) => block.status)
                .map((block, i) => {
                  return (
                    <SortableItem
                      key={block.id}
                      index={i}
                      {...block}
                      removeBlock={removeBlock}
                    />
                  );
                })}
            </SortableContext>
          </DndContext>
        </div>
      ) : (
        <div
          className={classNames(
            "px-0 py-10 text-center font-light md:py-16 xl:py-20"
          )}
        >
          <ExclamationCircleIcon
            type="outline"
            name="exclamation-circle"
            className="mx-auto h-6 w-6 text-gray-400"
          />
          <p className="mt-4 font-normal text-gray-900">No data available</p>
          <p className="mt-2 text-sm text-gray-500">
            There is no data available for this widget.
          </p>
        </div>
      )}
    </Fragment>
  );
}

function SortableItem({
  id,
  index,
  name,
  count,
  status,
  removeBlock,
  ...props
}: StockItemsWidget & {
  index: number;
  removeBlock: (id: string) => void;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...props} {...attributes}>
      <div className="group relative">
        <Button
          type="button"
          variant="icon"
          className={classNames(
            "absolute -right-2 top-1/2 z-10 -translate-y-1/2 text-red-500 opacity-0 transition-all ease-in-out hover:text-red-700 group-hover:opacity-100",
            isDragging ? "opacity-0" : ""
          )}
          onClick={() => removeBlock(id)}
        >
          <span className="sr-only">Remove block {name}</span>
          <XMarkIcon className={classNames("h-5 w-5")} aria-hidden="true" />
        </Button>
        <div
          {...listeners}
          className={classNames(
            "group relative flex w-full items-center py-2",
            index === 0 ? "text-red-700" : "text-current",
            isDragging ? "opacity-75" : ""
          )}
        >
          <div className="flex-1 cursor-move">{name}</div>
          <strong className="text-xl transition-all group-hover:opacity-0">
            {count}
          </strong>
        </div>
      </div>
    </div>
  );
}
