import { gql } from "@apollo/client";

export const BASIC_USER_FRAGMENT = gql`
  fragment BasicUserFragment on User {
    id
    fullName
    firstName
    lastName
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    fullName
    userName
    email
    phoneNumber
    roles {
      id
      roleName
    }
    createdAt
    status
  }
`;

export const GET_USERS = gql`
  ${USER_FRAGMENT}
  query FetchUsers {
    fetchUsers {
      ...UserFragment
    }
  }
`;

export const REMOVE_USER = gql`
  ${USER_FRAGMENT}
  mutation UserDelete($id: ID!) {
    userDelete(input: { id: $id }) {
      user {
        ...UserFragment
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  ${USER_FRAGMENT}
  query FetchUser($id: ID!) {
    fetchUser(id: $id) {
      ...UserFragment
    }
  }
`;

export const EDIT_USER = gql`
  ${USER_FRAGMENT}
  mutation UserUpdate(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $userName: String!
    $roles: [Int!]!
    $status: Int!
  ) {
    userUpdate(
      input: {
        id: $id
        params: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          userName: $userName
          roles: $roles
          status: $status
        }
      }
    ) {
      user {
        ...UserFragment
      }
    }
  }
`;

export const ADD_USER = gql`
  ${USER_FRAGMENT}
  mutation UserCreate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $userName: String!
    $roles: [Int!]!
    $status: Int!
  ) {
    userCreate(
      input: {
        params: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          phoneNumber: $phoneNumber
          userName: $userName
          roles: $roles
          status: $status
        }
      }
    ) {
      user {
        ...UserFragment
      }
    }
  }
`;

export enum UserStatus {
  INACTIVE,
  INVITATION_SENT,
  ACTIVE,
}
export const renderStatusText = (status: UserStatus) => {
  switch (status) {
    case UserStatus.INACTIVE:
      return "Inactive";
    case UserStatus.INVITATION_SENT:
      return "Invitation Sent";
    case UserStatus.ACTIVE:
      return "Active";
    default:
      return "Unknown";
  }
};
export const renderStatus = (status: UserStatus) => {
  switch (status) {
    case UserStatus.INACTIVE:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
          Inactive
        </span>
      );
    case UserStatus.INVITATION_SENT:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
          Invitation Sent
        </span>
      );
    case UserStatus.ACTIVE:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
          Active
        </span>
      );
    default:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-gray-100 px-2 text-xs font-medium leading-5 text-gray-800">
          Unknown
        </span>
      );
  }
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  userName: string;
  email: string;
  phoneNumber: string;
  roles: {
    id: string;
    roleName: string;
  }[];
  createdAt: string;
  status: UserStatus;
};
