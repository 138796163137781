import { useMutation, useQuery } from "@apollo/client/react";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { ButtonDots, itemClasses } from "../../../../../components/appearance";
import { ErrorFallback, Head } from "../../../../../components/core";
import { Button } from "../../../../../components/form";
import { LimitBy, TableCursor } from "../../../../../components/table";
import {
  GET_FUELENTRIES,
  REMOVE_FUELENTRY,
} from "../../../../../graphql/fleets/vehicles/pages/fuel-entry";
import { VehicleFuelEntry } from "../../../../../graphql/fleets/vehicles/pages/fuel-entry";
import {
  renderFuelUnit,
  renderPrimaryMeter,
} from "../../../../../graphql/fleets/vehicles/pages/settings";
import { AlertModal, AlertType, useAlert } from "../../../../../hooks/useAlert";
import {
  classNames,
  formatCurrency,
  formatDate,
  formatFloat,
} from "../../../../../utils";
import { AuthShield } from "../../../../auth/core";

const FuelEntryList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { t } = useTranslation();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);

  const { data, loading, error, refetch } = useQuery<{
    fetchFuelEntries: VehicleFuelEntry[] | null;
  }>(GET_FUELENTRIES);

  const fuelEntries = useMemo<VehicleFuelEntry[]>(() => {
    const updatedFuelEntry = data?.fetchFuelEntries?.length
      ? data?.fetchFuelEntries.map((item) => {
          return {
            ...item,
            fuelEntryDate: formatDate(item.fuelEntryDate),
            createdAt: formatDate(item.createdAt),
          };
        })
      : [];
    return updatedFuelEntry;
  }, [data]);

  const [deleteVehicleFuelEntry] = useMutation(REMOVE_FUELENTRY);
  const handleDelete = async (id: string) => {
    deleteVehicleFuelEntry({
      variables: {
        id: Number(id),
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleFuelEntryDelete) {
          await refetch();
          toast.success(`Vehicle fuel entry deleted successfully`);
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<VehicleFuelEntry, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "fuelEntryDate",
        header: "Fuel Date",
        enableHiding: false,
      },
      {
        accessorKey: "vendor.name",
        header: "Vendor",
        enableHiding: false,
      },
      {
        accessorKey: "odometer",
        header: "Odometer",
        cell: (props) => {
          const odometer = props.row.original.odometer;
          const meterUnit = renderPrimaryMeter(
            props.row.original.vehicle.settings?.length
              ? props.row.original.vehicle.settings[0].primaryMeter
              : null
          );
          return `${odometer} ${meterUnit}`;
        },
      },
      {
        id: "usage",
        header: "Usage",
        cell: (props) => {
          const meterUnit = renderPrimaryMeter(
            props.row.original.vehicle.settings?.length
              ? props.row.original.vehicle.settings[0].primaryMeter
              : null
          );

          const currentOdometer = props.row.original.odometer ?? 0;
          const previousOdometer = props.row.original.previousMeterEntry ?? 0;
          const usage = currentOdometer - previousOdometer;

          return (
            <span className="whitespace-nowrap">
              {usage} {meterUnit}
            </span>
          );
        },
      },
      {
        accessorKey: "fuelAmount",
        header: "Volume",
        cell: (props) => {
          const fuelAmount = props.row.original.fuelAmount ?? 0;
          const fuelUnit = renderFuelUnit(
            props.row.original.vehicle.settings?.length
              ? props.row.original.vehicle.settings[0].fuelUnit
              : null
          );
          return `${fuelAmount} ${fuelUnit}`;
        },
      },
      {
        id: "total",
        header: "Total",
        cell: (props) => {
          const fuelAmount = props.row.original.fuelAmount ?? 0;
          const pricePerUnit = props.row.original.pricePerUnit ?? 0;
          const total = formatFloat(fuelAmount * pricePerUnit);
          return formatCurrency(total);
        },
      },
      {
        id: "costPerDistance",
        header: "Cost/Distance",
        cell: (props) => {
          const fuelAmount = props.row.original.fuelAmount ?? 0;
          const pricePerUnit = props.row.original.pricePerUnit ?? 0;
          const total = fuelAmount * pricePerUnit;

          const meterUnit = renderPrimaryMeter(
            props.row.original.vehicle.settings?.length
              ? props.row.original.vehicle.settings[0].primaryMeter
              : null
          );
          const currentOdometer = props.row.original.odometer ?? 0;
          const previousOdometer = props.row.original.previousMeterEntry ?? 0;
          const usage = currentOdometer - previousOdometer;

          const costDistance = formatFloat(total / usage);
          return `${formatCurrency(costDistance)}/${meterUnit}`;
        },
      },
      {
        id: "fuelEconomy",
        header: "Fuel Economy",
        cell: (props) => {
          const fuelAmount = props.row.original.fuelAmount ?? 0;

          const meterUnit = renderPrimaryMeter(
            props.row.original.vehicle.settings?.length
              ? props.row.original.vehicle.settings[0].primaryMeter
              : null
          );
          const fuelUnit = renderFuelUnit(
            props.row.original.vehicle.settings?.length
              ? props.row.original.vehicle.settings[0].fuelUnit
              : null
          );
          const currentOdometer = props.row.original.odometer ?? 0;
          const previousOdometer = props.row.original.previousMeterEntry ?? 0;
          const usage = currentOdometer - previousOdometer;
          const fuelEconomy = formatFloat(usage / fuelAmount);
          return (
            <span className="whitespace-nowrap">{`${fuelEconomy} ${meterUnit}/${fuelUnit}`}</span>
          );
        },
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <ButtonDots>
            <AuthShield access={["read-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/fuel-entries/${props.row.original.id}`}
              >
                <div className="flex items-center">
                  <EyeIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    View
                    <span className="sr-only">
                      , {props.row.original.fuelEntryDate}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["update-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/fuel-entries/${props.row.original.id}/edit`}
              >
                <div className="flex items-center">
                  <PencilIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Edit
                    <span className="sr-only">
                      , {props.row.original.fuelEntryDate}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original?.fuelEntryDate ?? "",
                  });
                }}
              >
                <div className="flex items-center">
                  <TrashIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Delete
                    <span className="sr-only">
                      , {props.row.original.fuelEntryDate}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
          </ButtonDots>
        ),
      },
    ],
    [fuelEntries]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={FuelEntryListResource.name}
        heading={FuelEntryListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: FuelEntryListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-6 sm:flex sm:items-center md:mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_vehicle_fuel_entry")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_vehicle_fuel_entry")}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-vehicles"]}>
              <Button type="link" href="/fleets/vehicles/fuel-entries/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add fuel entry
              </Button>
            </AuthShield>
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh fuel entry list</span>
            </Button>
          </div>
        </div>

        <TableCursor
          data={fuelEntries}
          columns={columns}
          loading={loading}
          totalRows={fuelEntries.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          enableRowSelection={false}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No fuel entries found"
          fallbackText="You can add a new fuel entry by clicking on add fuel entry button."
        />
      </div>
    </>
  );
};

export default FuelEntryList;
export const FuelEntryListResource: ResourceProps = {
  name: "Fuel Entries",
  description: "Update an existing vehicle fuel entries",
  access: ["update-vehicles"],
  path: "/fuel-entries",
  icon: "bi bi-person",
};
