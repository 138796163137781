import { useFormik } from "formik";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldServiceTask,
  FieldUsers,
  FieldVehicle,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleServiceReminderForm } from "../../../../graphql/fleets/vehicles/pages/service-reminder";
import { classNames, useFormikErrors } from "../../../../utils";
export default function Form({
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
}: {
  initialValues: VehicleServiceReminderForm;
  onSubmit: (values: VehicleServiceReminderForm, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
}) {
  const { vehicleId } = useParams();
  const { t } = useTranslation();

  const [vehicleBasic, setVehicleBasic] = useState<VehicleBasic | null>(null);

  const ValidationSchema = Yup.object().shape({
    vehicleId: Yup.number().when(".", {
      is: (val: unknown) => vehicleId === undefined,
      then: Yup.number().typeError("Must be a number").required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    vehicleServiceTaskId: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    remainderType: Yup.boolean().required("Required"),
    timeInterval: Yup.number().nullable(),
    timeIntervalUnit: Yup.number().nullable(),
    timeDueSoonThreshold: Yup.number().nullable(),
    timeDueSoonThresholdUnit: Yup.number().nullable(),
    notification: Yup.boolean().required("Required"),
    status: Yup.number().typeError("Must be a number").required("Required"),
    watchers: Yup.array()
      .of(Yup.number().typeError("Must be a number").required("Required"))
      .nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="pb-4 md:pb-5 xl:pb-6">
          <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            {vehicleId === undefined ? (
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_vehicle")}
                </label>
                <FieldVehicle
                  value={formik.values.vehicleId}
                  onChange={(value) => {
                    formik.setFieldValue("vehicleId", value ? value.id : null);
                    setVehicleBasic(value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.vehicleId && formik.errors.vehicleId
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.vehicleId && formik.errors.vehicleId ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="vehicleId-errors"
                  >
                    {formik.errors.vehicleId}
                  </p>
                ) : null}
              </div>
            ) : null}
            <div className="sm:col-span-6">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_vehicle_service_task")}
              </label>
              <FieldServiceTask
                title={t("text_vehicle_service_task")}
                value={formik.values.vehicleServiceTaskId}
                onChange={(value) => {
                  formik.setFieldValue("vehicleServiceTaskId", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.vehicleServiceTaskId &&
                    formik.errors.vehicleServiceTaskId
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.vehicleServiceTaskId &&
              formik.errors.vehicleServiceTaskId ? (
                <p
                  className="mt-2 text-sm text-red-600"
                  id="vehicleServiceTaskId-errors"
                >
                  {formik.errors.vehicleServiceTaskId}
                </p>
              ) : null}
            </div>

            <div className="flex items-start sm:col-span-3">
              <div className="mr-2 flex-1">
                <Field
                  title={t("text_time_interval")}
                  name="timeInterval"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.timeInterval ?? ""}
                  touched={formik.touched.timeInterval}
                  errors={formik.errors.timeInterval}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_unit")}
                </label>
                <FieldTimeIntervalUnit
                  value={formik.values.timeIntervalUnit}
                  onChange={(value) => {
                    formik.setFieldValue("timeIntervalUnit", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.timeIntervalUnit &&
                      formik.errors.timeIntervalUnit
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.timeIntervalUnit &&
                formik.errors.timeIntervalUnit ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="timeIntervalUnit-errors"
                  >
                    {formik.errors.timeIntervalUnit}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex items-start sm:col-span-3">
              <div className="mr-2 flex-1">
                <Field
                  title={t("text_time_due_soon_threshold")}
                  name="timeDueSoonThreshold"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.timeDueSoonThreshold ?? ""}
                  touched={formik.touched.timeDueSoonThreshold}
                  errors={formik.errors.timeDueSoonThreshold}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_unit")}
                </label>
                <FieldTimeDueSoonThresholdUnit
                  value={formik.values.timeDueSoonThresholdUnit}
                  onChange={(value) => {
                    formik.setFieldValue("timeDueSoonThresholdUnit", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.timeDueSoonThresholdUnit &&
                      formik.errors.timeDueSoonThresholdUnit
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.timeDueSoonThresholdUnit &&
                formik.errors.timeDueSoonThresholdUnit ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="timeDueSoonThresholdUnit-errors"
                  >
                    {formik.errors.timeDueSoonThresholdUnit}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="sm:col-span-6">
              <Field
                title={t("text_remainder_type")}
                id="remainderType"
                name="remainderType"
                type="checkbox"
                checked={formik.values.remainderType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <p className="ml-6 text-sm text-gray-700">
                {t("text_remainder_type_caption")}
              </p>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
          <div className="mb-4 text-base font-medium text-gray-900">
            Notification
          </div>
          <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <Field
                title={t("text_remainder_notification")}
                id="notification"
                name="notification"
                type="checkbox"
                checked={formik.values.notification}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {/* <div className="sm:col-span-3">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_status")}
              </label>
              <FieldStatus
                value={formik.values.status}
                onChange={(value) => {
                  formik.setFieldValue("status", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.status && formik.errors.status
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.status && formik.errors.status ? (
                <p
                  className="mt-2 text-sm text-red-600"
                  id="status-errors"
                >
                  {formik.errors.status.toString()}
                </p>
              ) : null}
            </div> */}
            <div className="col-span-12 sm:col-span-6">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_watchers")}
              </label>
              <FieldUsers
                value={formik.values.watchers}
                onChange={(value) => {
                  formik.setFieldValue("watchers", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.watchers && formik.errors.watchers
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.watchers && formik.errors.watchers ? (
                <p className="mt-2 text-sm text-red-600" id="watchers-errors">
                  {formik.errors.watchers}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={onCancel}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <Fragment>
                <Spinner />
                {t("text_processing")}
              </Fragment>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export function FieldTimeIntervalUnit({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Day(s)", value: "0" },
      { label: "Week(s)", value: "1" },
      { label: "Month(s)", value: "2" },
      { label: "Year(s)", value: "3" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}

export function FieldTimeDueSoonThresholdUnit({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Day(s)", value: "0" },
      { label: "Week(s)", value: "1" },
      { label: "Month(s)", value: "2" },
      { label: "Year(s)", value: "3" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}

export function FieldStatus({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Upcoming", value: "0" },
      { label: "Resolved", value: "1" },
      { label: "Overdue", value: "2" },
      { label: "Due Soon", value: "3" },
      { label: "Snoozed", value: "4" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}
