import { useMutation, useQuery } from "@apollo/client/react";
import { ArrowLeftIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useCallback, useMemo, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner, Waiting } from "../../../animations";
import { Head } from "../../../components/core";
import { Button, FieldPriceCategoryCalculator } from "../../../components/form";
import {
  type PricingMethod,
  type Product,
  type ProductPricing,
  ProductPricingStatus,
} from "../../../graphql/inventory/pricing/pricing-categories/pricing";
import {
  ADD_PRICINGSTRUCTUREPRICING,
  EDIT_PRICINGSTRUCTUREPRICING,
  GET_PRICINGSTRUCTURE_BY_ID,
} from "../../../graphql/sales/customers";
import { classNames } from "../../../utils";

const CustomerPricing = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let { customerId, pricingStructureId } = useParams();
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { data, loading } = useQuery(GET_PRICINGSTRUCTURE_BY_ID, {
    variables: {
      id: pricingStructureId,
      structureId: pricingStructureId ? parseInt(pricingStructureId) : null,
    },
  });

  const products: Product[] = useMemo(() => {
    if (data?.fetchPricingStructure?.structureProducts) {
      const fetchedProducts: Product[] =
        data.fetchPricingStructure.structureProducts;
      if (globalFilter === "") return fetchedProducts;
      const updatedProducts = fetchedProducts.filter((product) => {
        const isMatchName = product.name
          .toLowerCase()
          .includes(globalFilter.toLowerCase());
        const isMatchSku = product.variants.some((variant) =>
          variant.stockCode.toLowerCase().includes(globalFilter.toLowerCase())
        );
        return isMatchName || isMatchSku;
      });

      return updatedProducts;
    }
    return [];
  }, [data, globalFilter]);

  const pricingMethod: PricingMethod = useMemo(() => {
    if (data?.fetchPricingStructure?.pricingMethod) {
      return data.fetchPricingStructure.pricingMethod;
    }
    return {};
  }, [data]);

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: products.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
  });
  const items = virtualizer.getVirtualItems();

  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);

  const [createProductPricing, { loading: loadingCreate }] = useMutation(
    ADD_PRICINGSTRUCTUREPRICING
  );
  const [updateProductPricing, { loading: loadingUpdate }] = useMutation(
    EDIT_PRICINGSTRUCTUREPRICING
  );

  const handleSave = useCallback(async () => {
    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const {
        pricingId,
        pricingStructureId,
        productId,
        productSkuId,
        priceFields,
        sellPrice,
      } = product;

      if (pricingId) {
        await updateProductPricing({
          variables: {
            id: pricingId,
            pricingStructureId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructurePricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createProductPricing({
          variables: {
            pricingStructureId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructurePricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    setProductPricing(newProductPricing);
  }, [productPricing, createProductPricing, updateProductPricing]);

  const handleCancel = () => {
    return navigate(`/sales/customers/${customerId}`);
  };

  return (
    <>
      <Head
        title="Customer Pricing"
        heading="Customer Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: "Customer Pricing",
            href: `/sales/customers/${customerId}`,
          },
          {
            name: CustomerPricingResource.name,
            href: null,
          },
        ]}
      />

      <div className="h-[calc(100vh-2rem)] rounded-xl bg-greyish">
        <header className="absolute left-0 top-0 z-20 w-full justify-between bg-greyish p-5 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {CustomerPricingResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {CustomerPricingResource.description}
            </p>
          </div>

          <div className="flex space-x-4">
            <div className="flex w-80 max-w-full items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
              <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <DebounceInput
                type="search"
                className={classNames(
                  "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                  "shadow-none outline-none focus:ring-0",
                  "placeholder-black/50 placeholder-opacity-100"
                )}
                placeholder="Search product name or stock code"
                minLength={2}
                debounceTimeout={300}
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
              />
            </div>
            {productPricing.filter(
              (pp) =>
                pp.status === ProductPricingStatus.pending ||
                pp.status === ProductPricingStatus.error
            ).length ? (
              <Button onClick={handleSave}>
                {loadingCreate || loadingUpdate ? (
                  <>
                    <Spinner />
                    {t("text_save")}
                  </>
                ) : (
                  t("text_save")
                )}
              </Button>
            ) : null}
            <Button variant="tertiary" onClick={handleCancel}>
              <span className="sr-only">{t("text_back")}</span>
              <ArrowLeftIcon className="h-5 w-5" />
            </Button>
          </div>
        </header>

        {loading ? (
          <Waiting />
        ) : (
          <div
            ref={parentRef}
            className="h-full overflow-y-auto px-5 pb-5 pt-24"
            style={{
              contain: "strict",
            }}
          >
            <div
              style={{
                height: virtualizer.getTotalSize(),
                width: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${items[0].start}px)`,
                }}
              >
                {items.map((virtualRow) => (
                  <div
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={virtualizer.measureElement}
                    className={virtualRow.index % 2 ? "bg-greyish" : "bg-white"}
                  >
                    <FieldPriceCategoryCalculator
                      key={products[virtualRow.index].id}
                      product={products[virtualRow.index]}
                      pricingMethod={pricingMethod}
                      productPricing={productPricing}
                      setProductPricing={setProductPricing}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerPricing;
export const CustomerPricingResource: ResourceProps = {
  name: "Customer Pricing",
  description: "Manage pricing list for customer",
  access: ["read-customers"],
  path: "customers/:customerId/structure/:pricingStructureId/pricing",
};
