import { useLazyQuery } from "@apollo/client/react";
import adaptivePlugin from "@fullcalendar/adaptive";
import {
  Calendar,
  DatesSetArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
} from "@fullcalendar/core";
import { EventSourceFuncArg } from "@fullcalendar/core";
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import { DateTime, Interval } from "luxon";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";

import { Loader } from "../../../animations";
import placeholder from "../../../assets/placeholder.svg";
import {
  GET_VEHICLE_ASSIGNMENTS,
  VehicleAssignment,
} from "../../../graphql/fleets/vehicle-assignments";
import { VehicleBasic } from "../../../graphql/fleets/vehicles";
import { formatDateTime } from "../../../utils";
import { Assignment } from "./components/Assignment";

export default function CalendarView({
  deliveryRunId,
  vehicles,
}: {
  deliveryRunId: number | null;
  vehicles: VehicleBasic[];
}) {
  const [calendarEl, setCalendarEl] = useState<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState("resourceTimelineWeekAF");

  const [openViewAssignment, setOpenViewAssignment] = useState(false);
  const [activeVehicle, setActiveVehicle] = useState<VehicleBasic | null>(null);
  const [activeAssignmentId, setActiveAssignmentId] = useState<string | null>(
    null
  );
  const [currentDate, setCurrentDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [openEditAssignment, setOpenEditAssignment] = useState(false);

  const activeEvent = useRef<EventChangeArg | null>(null);

  const [fetchingAssignments] = useLazyQuery<{
    fetchVehicleAssignments: VehicleAssignment[];
  }>(GET_VEHICLE_ASSIGNMENTS);

  const fetchEventsByDate = useCallback(
    async (fromDate: string | null, toDate: string | null) => {
      if (!fromDate || !toDate) throw new Error("Invalid date range");
      if (!deliveryRunId) throw new Error("Missing deliveryRunId");
      try {
        const response = await fetchingAssignments({
          variables: {
            fromDate,
            toDate,
            deliveryRunId,
          },
        });
        return response?.data?.fetchVehicleAssignments ?? [];
      } catch (error) {
        throw error;
      }
    },
    [deliveryRunId, fetchingAssignments]
  );

  const fetchEventsHandler = useCallback(
    (
      arg: EventSourceFuncArg,
      successCallback: (eventInputs: EventInput[]) => void,
      failureCallback: (error: Error) => void
    ) => {
      const fromDate = arg.start.toISOString();
      const toDate = arg.end.toISOString();
      if (!deliveryRunId) throw new Error("Missing deliveryRunId");
      fetchingAssignments({
        variables: {
          fromDate,
          toDate,
          deliveryRunId,
        },
      })
        .then(({ data }) => {
          const updatedEvents = data?.fetchVehicleAssignments ?? [];
          const updatedEventsFormated = updatedEvents.flatMap((assignment) => {
            return {
              id: assignment.id,
              resourceId: assignment.vehicle.id,
              title: `${assignment.vehicle.name} - ${assignment.operator.name}`,
              start: assignment.startDate,
              end: assignment.endDate,
              description: `${formatDateTime(assignment.startDate)} - ${
                assignment.endDate ? formatDateTime(assignment.endDate) : "-"
              }`,
              borderColor: assignment.vehicle.status.color + "90",
              backgroundColor: assignment.vehicle.status.color + "30",
              textColor: assignment.vehicle.status.color,
              extendedProps: {
                vehicleId: assignment.vehicle.id,
              },
            };
          });
          successCallback(updatedEventsFormated);
        })
        .catch((error) => {
          failureCallback(error);
        });
    },
    [deliveryRunId, fetchingAssignments]
  );

  const clickingDate = useCallback(
    async (info: DateClickArg) => {
      setLoading(true);
      const selectedDate = DateTime.fromISO(info.date.toISOString());
      const selectedVehicle = vehicles.find(
        (vehicle) => vehicle.id.toString() === info.resource?._resource.id
      );
      if (!selectedVehicle) return toast.error("Vehicle not found");

      try {
        const fromDate = selectedDate.minus({ days: 15 }).toISO();
        const toDate = selectedDate.plus({ days: 15 }).toISO();

        const selectedVehicleAssignments = await fetchEventsByDate(
          fromDate,
          toDate
        );
        // check if there is an assignment includes for the selected date
        const isExistAssignment = selectedVehicleAssignments.some(
          (assignment) => {
            if (info.resource?.id !== assignment.vehicle.id) return false;
            console.log(148, assignment, info);
            const interval = Interval.fromDateTimes(
              DateTime.fromISO(assignment.startDate),
              DateTime.fromISO(assignment.endDate)
            );
            return interval.contains(DateTime.fromISO(info.date.toISOString()));
          }
        );
        if (isExistAssignment) return toast.error("Assignment already exists");

        setActiveVehicle(selectedVehicle);
        setCurrentDate(info.date.toISOString());
        setStartDate(info.date.toISOString());
        setEndDate(null);
        setOpenEditAssignment(true);
      } catch (error) {
        toast.error((error as Error).message);
      } finally {
        setLoading(false);
      }
    },
    [fetchEventsByDate, vehicles]
  );

  const clickingEvent = useCallback(
    (info: EventClickArg) => {
      const selectedVehicle =
        vehicles.find(
          (vehicle) =>
            info.event._def?.resourceIds?.length &&
            vehicle.id.toString() === info.event._def.resourceIds[0]
        ) || null;
      if (!selectedVehicle) return toast.error("Vehicle not found");
      setActiveVehicle(selectedVehicle);
      setActiveAssignmentId(info.event.id);
      setCurrentDate(info.event.start?.toISOString() || null);
      setOpenViewAssignment(true);
    },
    [vehicles]
  );

  const changingEvent = useCallback(
    (info: EventChangeArg | EventResizeDoneArg) => {
      activeEvent.current = info;
      const selectedVehicle =
        vehicles.find(
          (vehicle) =>
            info.event._def?.resourceIds?.length &&
            vehicle.id.toString() === info.event._def.resourceIds[0]
        ) || null;
      if (!selectedVehicle) return toast.error("Vehicle not found");
      setActiveVehicle(selectedVehicle);
      setActiveAssignmentId(info.event.id);
      setCurrentDate(info.event.start?.toISOString() || null);
      setStartDate(info.event.start?.toISOString() || null);
      setEndDate(info.event.end?.toISOString() || null);
      setOpenEditAssignment(true);
    },
    [vehicles]
  );

  const calendar = useMemo<Calendar | null>(() => {
    if (calendarEl) {
      const initiedCalendar = new Calendar(calendarEl, {
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        plugins: [
          adaptivePlugin,
          interactionPlugin,
          timeGridPlugin,
          resourceTimelinePlugin,
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimelineWeekAF,resourceTimelineMonth",
        },
        views: {
          resourceTimelineWeekAF: {
            type: "resourceTimeline",
            duration: { weeks: 1 },
            buttonText: "Week",
            slotDuration: { days: 1 },
            slotLabelInterval: { days: 1 },
            slotLabelFormat: {
              day: "numeric",
              weekday: "short",
            },
            slotLabelContent: (info, createElement) => {
              return createElement(
                "div",
                {},
                info.date.toLocaleDateString("en-AU", {
                  day: "numeric",
                  weekday: "short",
                })
              );
            },
          },
        },
        // headerToolbar: false,
        initialView: "resourceTimelineWeekAF",
        datesSet: (info: DatesSetArg) => {
          setCurrentView(info.view.type);
        },
        height: "auto",
        resourceAreaHeaderContent: (info, createElement) => {
          return createElement("div", {}, "Vehicles");
        },
        editable: true,
        resourceAreaWidth: "25%",
        lazyFetching: false,
        loading: setLoading,
        eventOverlap: false,
        eventResizableFromStart: true,
        resources: vehicles.map((vehicle) => {
          return {
            id: vehicle.id.toString(),
            title: vehicle.name,
            extendedProps: {
              vehicleImageUrl: vehicle.vehicleImageUrl,
              fuelUnit: vehicle.fuelUnit,
              measurementUnit: vehicle.measurementUnit,
              primaryMeter: vehicle.primaryMeter,
              status: vehicle.status,
              operator: vehicle.operator,
              make: vehicle.make,
              model: vehicle.model,
              year: vehicle.year,
            },
          };
        }),
        resourceLabelContent: (info, createElement) => {
          return createElement(
            "div",
            { className: "flex w-full items-center p-1" },
            [
              createElement(
                "div",
                {
                  className:
                    "min-w-[3rem] cursor-pointer w-[3rem] h-[3rem] mr-3 relative",
                },
                [
                  createElement(
                    "div",
                    {
                      className:
                        "block w-full h-full appearance-none overflow-hidden rounded-md ring-2 ring-white filter transition hover:hue-rotate-15",
                    },
                    [
                      createElement("img", {
                        className: "h-full w-full object-cover",
                        src:
                          info.resource.extendedProps.vehicleImageUrl ||
                          placeholder,
                      }),
                    ]
                  ),
                  createElement(
                    "span",
                    {
                      className:
                        "absolute -bottom-0.5 -right-0.5 w-3 h-3 rounded-full",
                      style: {
                        backgroundColor:
                          info.resource.extendedProps?.status.color,
                      },
                    },
                    ""
                  ),
                ]
              ),
              createElement("div", {}, [
                createElement(
                  "div",
                  {
                    className:
                      "max-w-[12rem] text-sm truncate font-medium lg:max-w-[14rem] 2xl:max-w-[18rem]",
                  },
                  info.resource.title
                ),
                createElement(
                  "div",
                  {
                    className:
                      "text-xs flex items-center font-light space-x-2 text-gray-700",
                  },
                  [
                    createElement(
                      "span",
                      {
                        className:
                          "max-w-[12rem] truncate lg:max-w-[14rem] 2xl:max-w-[18rem]",
                      },
                      info.resource.extendedProps?.status.name || ""
                    ),
                    info.resource.extendedProps?.make?.name
                      ? createElement(
                          "span",
                          {
                            className:
                              "w-1 h-1 rounded-full bg-gray-400 inline-block",
                          },
                          "·"
                        )
                      : null,
                    createElement(
                      "span",
                      {
                        className:
                          "max-w-[12rem] truncate lg:max-w-[14rem] 2xl:max-w-[18rem]",
                      },
                      info.resource.extendedProps?.make?.name || ""
                    ),
                    info.resource.extendedProps?.model?.name
                      ? createElement(
                          "span",
                          {
                            className:
                              "w-1 h-1 rounded-full bg-gray-400 inline-block",
                          },
                          "·"
                        )
                      : null,
                    createElement(
                      "span",
                      {
                        className:
                          "max-w-[12rem] truncate lg:max-w-[14rem] 2xl:max-w-[18rem]",
                      },
                      info.resource.extendedProps?.model?.name || ""
                    ),
                  ]
                ),
              ]),
            ]
          );
        },
        initialEvents: fetchEventsHandler,
        // events: fetchEventsHandler,
        eventContent: (info, createElement) => {
          return createElement("dl", { className: "p-1" }, [
            createElement(
              "dt",
              { className: "truncate text-sm font-medium text-current" },
              info.event.title
            ),
            createElement(
              "dd",
              { className: "text-xs font-light text-current" },
              info.event.extendedProps.description
            ),
          ]);
        },
        eventChange: changingEvent,
        eventClick: clickingEvent,
        eventResize: changingEvent,
        dateClick: clickingDate,
      });
      initiedCalendar.render();
      return initiedCalendar;
    }
    return null;
  }, [
    calendarEl,
    changingEvent,
    clickingDate,
    clickingEvent,
    fetchEventsHandler,
    vehicles,
  ]);

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll(".fc-timeline-lane-frame")?.forEach((e) => {
        if (e) {
          const cellHoverElementsContainer = document.createElement("div");
          cellHoverElementsContainer.className =
            "cell-hover-elements-container";
          let count = 7;
          if (currentView === "resourceTimelineDay") {
            count = 48;
          }
          if (currentView === "resourceTimelineMonth") {
            const days = calendarEl?.querySelectorAll(
              ".fc-timeline-slot-label"
            );
            count = days?.length || 0;
          }
          for (let i = 0; i < count; i += 1) {
            cellHoverElementsContainer.appendChild(
              document.createElement("div")
            );
          }
          e?.appendChild(cellHoverElementsContainer);
        }
      });
    }, 1000);
  }, [calendarEl, currentView]);

  const refetchCalendar = useCallback(async () => {
    if (!calendar) throw new Error("Calendar not found");
    if (!currentDate) throw new Error("Active date not found");
    const selectedDate = DateTime.fromISO(currentDate);
    const fromDate = selectedDate.minus({ days: 15 }).toISO();
    const toDate = selectedDate.plus({ days: 15 }).toISO();

    try {
      if (!deliveryRunId) throw new Error("Missing deliveryRunId");
      const { data } = await fetchingAssignments({
        variables: {
          fromDate,
          toDate,
          deliveryRunId,
        },
      });
      const updatedEvents = data?.fetchVehicleAssignments ?? [];
      const updatedEventsFormated = updatedEvents.flatMap((assignment) => {
        return {
          id: assignment.id,
          resourceId: assignment.vehicle.id,
          title: `${assignment.vehicle.name} - ${assignment.operator.name}`,
          start: assignment.startDate,
          end: assignment.endDate,
          description: `${formatDateTime(assignment.startDate)} - ${
            assignment.endDate ? formatDateTime(assignment.endDate) : "-"
          }`,
          borderColor: assignment.vehicle.status.color + "90",
          backgroundColor: assignment.vehicle.status.color + "30",
          textColor: assignment.vehicle.status.color,
        };
      });
      calendar?.removeAllEventSources();
      calendar?.addEventSource(updatedEventsFormated);
      calendar?.refetchEvents();
    } catch (error) {
      throw error;
    }
  }, [calendar, currentDate, deliveryRunId, fetchingAssignments]);

  const handleCloseEditAssignment = useCallback(() => {
    setOpenEditAssignment(false);
    if (activeEvent.current) {
      activeEvent.current.revert();
      activeEvent.current = null;
    }
    setTimeout(() => {
      setCurrentDate(null);
      setStartDate(null);
      setEndDate(null);
    }, 500);
  }, []);

  const handleCloseAssignment = useCallback(() => {
    setOpenViewAssignment(false);
    setTimeout(() => {
      setActiveAssignmentId(null);
      setCurrentDate(null);
      setStartDate(null);
      setEndDate(null);
    }, 500);
  }, []);

  return (
    <Fragment>
      <Loader loading={loading} />
      <div
        ref={(el) => {
          setCalendarEl(el);
        }}
        id="fullcalendar"
        className="w-full"
      />
      <Assignment
        showView={openViewAssignment}
        setShowView={setOpenViewAssignment}
        onCloseView={handleCloseAssignment}
        showEdit={openEditAssignment}
        setShowEdit={setOpenEditAssignment}
        onCloseEdit={handleCloseEditAssignment}
        assignmentId={activeAssignmentId}
        deliveryRunId={deliveryRunId}
        vehicleBasic={activeVehicle}
        refetchCalendar={refetchCalendar}
        currentDate={currentDate}
        startDate={startDate}
        endDate={endDate}
      />
    </Fragment>
  );
}
