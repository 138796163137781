import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { SpinnerInline } from "../../../../animations";
import {
  Avatar,
  ButtonPopover,
  PopoverPosition,
} from "../../../../components/appearance";
import { Button, FieldUser } from "../../../../components/form";
import {
  CREATE_VEHICLE_WATCHER,
  DELETE_VEHICLE_WATCHER,
  GET_VEHICLE_BY_ID,
  GET_VEHICLE_WATCHERS,
  SUBSCRIPTION_VEHICLE_WATCHERS,
  VehicleWatcher,
  Watcher,
} from "../../../../graphql/fleets/vehicles";
import { User } from "../../../../graphql/user-management/users";
import { classNames } from "../../../../utils";
import { useAuth } from "../../../auth";

export function VehicleWatchers({
  vehicleId: vehicleIdProp,
}: {
  vehicleId?: string;
}) {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { currentUser } = useAuth();
  const [watchers, setWatchers] = useState<Watcher[]>([]);
  const [openWatchers, setOpenWatchers] = useState(false);

  const handleCloseWatchers = () => {
    setOpenWatchers(false);
  };

  const isWatching = useMemo(() => {
    return watchers.some((watcher) => watcher.id === currentUser?.id);
  }, [currentUser, watchers]);

  const { loading } = useQuery<{
    fetchVehicle: {
      watchers: VehicleWatcher["user"][];
    };
  }>(GET_VEHICLE_WATCHERS, {
    variables: {
      id: vehicleIdProp || vehicleId,
    },
    onCompleted(data) {
      const updatedWatchers = data?.fetchVehicle?.watchers?.length
        ? data.fetchVehicle.watchers
        : [];
      setWatchers(updatedWatchers);
    },
  });

  useSubscription<{
    vehicleWatchers: VehicleWatcher[];
  }>(SUBSCRIPTION_VEHICLE_WATCHERS, {
    variables: {
      id: vehicleIdProp || vehicleId,
    },
    onData({ data }) {
      console.log(1, data?.data?.vehicleWatchers);
      const filteredWatchers = data?.data?.vehicleWatchers?.length
        ? data.data.vehicleWatchers.filter(
            (watcher) => !watchers.some((w) => w.id === watcher.user.id)
          )
        : [];
      const updatedWatchers = [
        ...watchers,
        ...filteredWatchers.map((w) => w.user),
      ];
      setWatchers(updatedWatchers);
    },
  });

  const [createWatcher, { loading: loadingCreateWatcher }] = useMutation(
    CREATE_VEHICLE_WATCHER,
    {
      refetchQueries: [
        {
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: vehicleId,
          },
        },
      ],
    }
  );

  const handleAddWatcher = (id: number | null, user: User | null) => {
    if (!id || !user) return;
    createWatcher({
      variables: {
        vehicleId: vehicleId,
        watcherId: id,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleWatcherCreate) {
          toast.success(`Added ${user.fullName} as watcher of this vehicle`);
        } else {
          toast.error("Something went wrong. Please try again later");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const [deleteWatcher, { loading: loadingDeleteWatcher }] = useMutation(
    DELETE_VEHICLE_WATCHER,
    {
      refetchQueries: [
        {
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: vehicleId,
          },
        },
      ],
    }
  );

  const handleRemoveWatcher = (id: number, user: VehicleWatcher["user"]) => {
    deleteWatcher({
      variables: {
        vehicleId: vehicleId,
        watcherId: id,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleWatcherDelete) {
          toast.success(`Added ${user.fullName} as watcher of this vehicle`);
        } else {
          toast.error("Something went wrong. Please try again later");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <Fragment>
      {watchers.length ? (
        <button
          type="button"
          className="background-none isolate flex cursor-pointer appearance-none -space-x-1.5 overflow-hidden border-0 outline-0"
          onClick={() => {
            setOpenWatchers(true);
          }}
        >
          {watchers.map((watcher, index) => (
            <Avatar
              key={`${watcher.id}-${index}`}
              firstName={watcher.firstName}
              lastName={watcher.lastName}
              className={classNames(
                "relative h-8 w-8 ring-2 ring-white",
                `z-${(watchers.length - index) * 10}`
              )}
            />
          ))}
        </button>
      ) : null}
      <ButtonPopover
        title="Watchers"
        child={
          <span>
            {loadingCreateWatcher || loadingDeleteWatcher ? (
              <SpinnerInline />
            ) : (
              <UserPlusIcon className="h-5 w-5" />
            )}
          </span>
        }
        isArrow={false}
        timeout={0}
        position={PopoverPosition.RIGHT}
        className="h-8 w-8 justify-center rounded-full border border-dashed border-secondary-800 bg-gray-200 text-secondary-800"
      >
        <div className="w-72 max-w-full space-y-4 px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:py-4">
          <div>
            {isWatching ? (
              <Fragment>
                <h3 className="mb-1 font-medium">Watching</h3>
                <p className="text-sm font-light">
                  You are watching this vehicle so you will recieve all watched
                  notifications
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <h3 className="mb-1 font-medium">Not Watching</h3>
                <p className="text-sm font-light">
                  You are not watching this vehicle
                </p>
              </Fragment>
            )}
          </div>
          <hr />
          <div>
            <h4 className="mb-2 text-xs font-medium uppercase text-gray-700">
              Watchers - {watchers.length}
            </h4>
            <div className="space-y-2">
              {watchers.map((watcher, index) => (
                <div
                  key={`${watcher.id}-${index}`}
                  className="flex items-center"
                >
                  <Avatar
                    firstName={watcher.firstName}
                    lastName={watcher.lastName}
                    className="h-8 w-8 text-sm"
                  />
                  <span className="ml-1 text-sm">{watcher.fullName}</span>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h4 className="mb-2 text-xs font-medium uppercase text-gray-700">
              Add Watcher
            </h4>
            <FieldUser
              title="Watcher"
              value={null}
              excludeIds={watchers.map((watcher) => parseInt(watcher.id))}
              onChange={handleAddWatcher}
              className={classNames(
                "rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
              )}
            />
          </div>
        </div>
      </ButtonPopover>
      <Transition.Root show={openWatchers} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpenWatchers(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1">
                    <div className="mb-6 border-b border-gray-200 px-4 pb-6 pt-8 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          Watchers
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={handleCloseWatchers}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Manage watchers of this vehicle.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="space-y-6 pb-6">
                        <div className="px-4 sm:px-6">
                          <h3 className="mb-3 text-base font-medium text-gray-900">
                            Add Watcher
                          </h3>
                          <FieldUser
                            title="Watcher"
                            value={null}
                            excludeIds={watchers.map((watcher) =>
                              parseInt(watcher.id)
                            )}
                            onChange={handleAddWatcher}
                            className={classNames(
                              "rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                            )}
                          />
                        </div>
                        <hr />
                        <div className="px-4 sm:px-6">
                          <h3 className="mb-3 text-base font-medium text-gray-900">
                            Current Watchers - ({watchers.length})
                          </h3>
                          <div className="space-y-4">
                            {watchers.map((watcher, index) => (
                              <div
                                key={`${watcher.id}-${index}`}
                                className="flex items-center"
                              >
                                <Avatar
                                  firstName={watcher.firstName}
                                  lastName={watcher.lastName}
                                  className="h-12 w-12 text-lg"
                                />
                                <div className="ml-3">
                                  <div>{watcher.fullName}</div>
                                  <span className="text-sm text-gray-500">
                                    {watcher.email}
                                  </span>
                                </div>
                                <div className="ml-auto">
                                  <Button
                                    variant="icon"
                                    onClick={() => {
                                      handleRemoveWatcher(
                                        parseInt(watcher.id),
                                        watcher
                                      );
                                    }}
                                  >
                                    <TrashIcon className="h-5 w-5" />
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}
