import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

// import styled from "styled-components";
import { classNames } from "../utils";
// const Box = styled.div`
//   box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.025);
// `;
export function Layout({
  title,
  handle,
  count = 0,
  className = "",
  navigation,
  children,
  ...props
}: {
  title: string;
  handle: string;
  count?: number;
  className?: string;
  navigation?: {
    name: string;
    icon: string;
    onClick: () => void;
  }[];
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames("h-full rounded-xl bg-greyish p-5", className)}
      {...props}
    >
      {(title || navigation) && (
        <header className="mb-4 flex w-full items-start">
          {title ? (
            <div className="widget-drag-handle text-md h-6 flex-1 cursor-move font-medium">
              {title}{" "}
              {count > 0 && (
                <span className="ml-0.5 text-gray-400">{count}</span>
              )}
            </div>
          ) : (
            <div className="widget-drag-handle h-6 flex-1 cursor-move" />
          )}

          {navigation ? (
            <div className="ml-4 flex items-center justify-between sm:flex-shrink-0 sm:justify-start">
              <Menu as="div" className="relative z-10 inline-block text-left">
                <div>
                  <Menu.Button className="-my-2 flex items-center py-2 text-gray-500 hover:text-gray-400 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50">
                    <span className="sr-only">Open {title} widget menu</span>
                    <EllipsisHorizontalIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="absolute right-0 mt-2 w-32 origin-top-right rounded-bl-lg rounded-br-lg rounded-tl-lg bg-white px-2 py-1 shadow-lg ring-1 ring-black ring-opacity-5  focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
                    static
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="16px"
                      height="16px"
                      viewBox="0 0 110.9 96"
                      enableBackground="new 0 0 110.9 96"
                      className="absolute -top-3 right-0 -z-0"
                      fill="#ffffff"
                      style={{
                        filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.1))",
                      }}
                    >
                      <polygon points="110.9,0 0,96 110.9,96 " />
                    </svg>
                    <div className="py-1">
                      {navigation.map((item, i) => (
                        <Menu.Button
                          key={`${handle}-${i}`}
                          onClick={item.onClick}
                          className="w-full"
                        >
                          <div className="py-0.5">
                            <div
                              className={classNames(
                                "group flex w-full items-center rounded-md px-1 py-1 text-left text-xs text-gray-700 transition ease-in-out"
                              )}
                            >
                              <span
                                className={classNames(
                                  "mr-1 h-6 w-6 flex-shrink-0 text-center text-lg leading-6 text-current",
                                  item.icon
                                )}
                                aria-hidden="true"
                              />
                              <span className="flex-1 truncate">
                                {item.name}
                              </span>
                            </div>
                          </div>
                        </Menu.Button>
                      ))}
                      {/* <Menu.Item>
                        {({ active }) => (
                          <div className="py-0.5">
                            <button
                              type="button"
                              className={classNames(
                                active
                                  ? "bg-primary-700/10 text-primary-700"
                                  : "",
                                "group flex w-full items-center text-left rounded-md px-1 py-1 text-xs text-gray-700 transition ease-in-out"
                              )}
                            >
                              <span
                                className={classNames(
                                  "mr-1 h-6 w-6 flex-shrink-0 text-center text-lg leading-6 text-current",
                                  "bi bi-arrows-angle-contract"
                                )}
                                aria-hidden="true"
                              />
                              <span className="flex-1 truncate">
                                {t("text_hide")}
                              </span>
                            </button>
                          </div>
                        )}
                      </Menu.Item> */}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          ) : (
            <div />
          )}
        </header>
      )}
      <div>{children}</div>
    </div>
  );
}
