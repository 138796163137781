import { gql } from "@apollo/client";

export const EXPENSEENTRY_FRAGMENT = gql`
  fragment VehicleExpenseEntryFragment on VehicleExpenseEntry {
    id
    amount
    expenseDate
    fleetVendorId
    notes
    vehicleExpenseTypeId
    vehicleId
    createdAt
    updatedAt
  }
`;

export const GET_EXPENSEENTRIES = gql`
  ${EXPENSEENTRY_FRAGMENT}
  query FetchExpenseEntries {
    fetchExpenseEntries {
      ...VehicleExpenseEntryFragment
    }
  }
`;

export const GET_EXPENSEENTRY_BY_ID = gql`
  ${EXPENSEENTRY_FRAGMENT}
  query FetchExpenseEntry($id: ID!) {
    fetchExpenseEntry(id: $id) {
      ...VehicleExpenseEntryFragment
    }
  }
`;

export const GET_EXPENSEENTRY_BY_VEHICLE_ID = gql`
  ${EXPENSEENTRY_FRAGMENT}
  query FetchExpenseEntriesByVehicle($vehicleId: ID!) {
    fetchExpenseEntriesByVehicle(vehicleId: $vehicleId) {
      ...VehicleExpenseEntryFragment
    }
  }
`;

export const ADD_EXPENSEENTRY = gql`
  ${EXPENSEENTRY_FRAGMENT}
  mutation VehicleExpenseEntryCreate(
    $vehicleId: Int!
    $vehicleExpenseTypeId: Int!
    $fleetVendorId: Int
    $amount: Float!
    $expenseDate: ISO8601DateTime!
    $notes: String
  ) {
    vehicleExpenseEntryCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          vehicleExpenseTypeId: $vehicleExpenseTypeId
          fleetVendorId: $fleetVendorId
          amount: $amount
          expenseDate: $expenseDate
          notes: $notes
        }
      }
    ) {
      vehicleExpenseEntry {
        ...VehicleExpenseEntryFragment
      }
    }
  }
`;

export const EDIT_EXPENSEENTRY = gql`
  ${EXPENSEENTRY_FRAGMENT}
  mutation VehicleExpenseEntryUpdate(
    $id: ID!
    $vehicleId: Int!
    $vehicleExpenseTypeId: Int!
    $fleetVendorId: Int
    $amount: Float!
    $expenseDate: ISO8601DateTime!
    $notes: String
  ) {
    vehicleExpenseEntryUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          vehicleExpenseTypeId: $vehicleExpenseTypeId
          fleetVendorId: $fleetVendorId
          amount: $amount
          expenseDate: $expenseDate
          notes: $notes
        }
      }
    ) {
      vehicleExpenseEntry {
        ...VehicleExpenseEntryFragment
      }
    }
  }
`;

export const REMOVE_EXPENSEENTRY = gql`
  ${EXPENSEENTRY_FRAGMENT}
  mutation VehicleExpenseEntryDelete($id: ID!) {
    vehicleExpenseEntryDelete(input: { id: $id }) {
      vehicleExpenseEntry {
        ...VehicleExpenseEntryFragment
      }
    }
  }
`;

export type VehicleExpenseEntry = {
  id: string;
  amount: number | null;
  expenseDate: string | null;
  fleetVendorId: number | null;
  notes: string;
  vehicleExpenseTypeId: number | null;
  vehicleId: number;
  createdAt: string;
  updatedAt: string;
};

export type VehicleExpenseEntryForm = Omit<
  VehicleExpenseEntry,
  "id" | "vehicleId" | "createdAt" | "updatedAt"
> & {
  id?: string;
  vehicleId: number | null;
};
