import { Link } from "react-router-dom";

import logoIcon from "../../assets/logo-icon.svg";
const { REACT_APP_NAME } = process.env;

export function NotFound() {
  return (
    <div className="flex min-h-screen flex-col bg-white pb-12 pt-16">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">{REACT_APP_NAME}</span>
            <img
              className="mx-auto h-14 w-auto sm:h-16 md:h-20 xl:h-24"
              src={logoIcon}
              alt={REACT_APP_NAME}
            />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-medium text-primary-600">Oops</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              404
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-6">
              <Link
                to="/"
                className="text-base font-medium text-primary-600 hover:text-primary-500"
              >
                Go back home <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
