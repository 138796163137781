import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { ADD_ROLE, GET_ROLES } from "../../../graphql/user-management/roles";
import Form from "./components/Form";

const RoleCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createRole] = useMutation(ADD_ROLE, {
    refetchQueries: [{ query: GET_ROLES }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createRole({
      variables: {
        roleName: values.roleName,
        roleDescription: values.roleDescription,
        permissions: values.permissions,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.roleCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Role created successfully",
          });
          return navigate("/user-management/roles", { replace: true });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Role creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Role creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Roles"
        heading="Roles"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Roles",
            href: "/user-management/roles",
          },
          {
            name: RoleCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_create_role")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_create_role")}
            </p>
          </div>
        </div>
        <div className="mt-6 flex flex-col md:mt-8">
          <div className="md:col-span-2">
            <Form
              initialValues={{
                roleName: "",
                roleDescription: "",
                permissions: [],
                status: true,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_create")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleCreate;
export const RoleCreateResource: ResourceProps = {
  name: "Create Role",
  description: "Create a new role",
  access: ["create-roles"],
  path: "roles/add",
};
