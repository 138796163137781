import { Dialog, Transition } from "@headlessui/react";
import { Tab } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { CheckIcon, MinusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Logo, Spinner } from "../../animations";
import { NotifyType, useNotifyContext } from "../../contexts/NotifyContext";
import Modal from "../../modules/media/components/Modal";
import Preview from "../../modules/media/components/Preview";
import Uploader from "../../modules/media/components/Uploader";
import { fetchMedia } from "../../modules/media/core/MediaHelper";
import { MediaProps } from "../../modules/media/core/types";
import { classNames } from "../../utils";
import { Button } from "./Button";
import { Field } from "./Field";

type FieldImageProps = {
  title: string;
  value: string | string[] | undefined | null;
  onChange: (value: string | string[]) => void;
  isMulti?: boolean;
  touched?: boolean | undefined;
  errors?: string | undefined;
};

const handleTabClass = ({ selected }: { selected: boolean }) =>
  classNames(
    "group mr-5 flex border-b-2 border-solid border-transparent py-1.5 text-sm text-gray-500 transition-all",
    selected ? "border-black font-medium text-black" : "hover:text-gray-700"
  );
export function FieldImage(props: FieldImageProps) {
  const { title, value, onChange, isMulti = false, touched, errors } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [activeFile, setActiveFile] = useState<MediaProps | null>(null);

  const { addNotify } = useNotifyContext();
  const [media, setMedia] = useState<MediaProps[]>([]);
  const [selected, setSelected] = useState<string[]>([]);

  const [query, setQuery] = useState("");

  const filteredMedia =
    query === ""
      ? media
      : media.filter((url) => {
          return url.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    setSelected(
      Array.isArray(value)
        ? value.filter((v) => v !== "")
        : value
        ? [value]
        : []
    );
  }, [value]);

  const didMount = useRef(false);
  const getMedia = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchMedia();
      setMedia(data);
    } catch (error: any) {
      addNotify({
        type: NotifyType.ERROR,
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [addNotify]);

  useEffect(() => {
    if (!didMount.current) {
      getMedia();
      didMount.current = true;
    }
  }, [getMedia]);

  const openUpload = useCallback(() => {
    // setActiveTab(0);
    setOpen(true);
  }, []);

  // const openLibrary = useCallback(() => {
  //   setActiveTab(1);
  //   setOpen(true);
  // }, []);

  const handleModal = useCallback((file: MediaProps) => {
    setActiveFile(file);
    setTimeout(() => {
      setOpenModal(true);
    }, 100);
  }, []);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setTimeout(() => {
      setActiveFile(null);
    }, 500);
  }, []);

  const handleSelect = useCallback(
    (url: string) => {
      if (isMulti) {
        if (selected.includes(url)) {
          setSelected(selected.filter((item) => item !== url));
        } else {
          setSelected([...selected, url]);
        }
      } else {
        if (selected.includes(url)) {
          setSelected([]);
        } else {
          setSelected([url]);
        }
      }
    },
    [selected, isMulti]
  );

  return (
    <div>
      <h4 className="mb-1 block text-sm font-medium text-gray-900">{title}</h4>
      <div className="flex flex-wrap items-end">
        {(Array.isArray(value) && value.length) || value ? (
          <Fragment>
            {isMulti && Array.isArray(value) && value.length ? (
              <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {value?.map((url: string) => (
                  <div key={url} className="relative w-full">
                    <PreviewThumbnail url={url} />
                    <Button
                      variant="icon"
                      onClick={() => {
                        onChange(value.filter((item) => item !== url));
                      }}
                      className={classNames(
                        "z-1 absolute right-1 top-1 rounded-bl-md rounded-tr-md bg-white px-2 py-2"
                      )}
                    >
                      <TrashIcon className="h-4 w-4 text-secondary" />
                    </Button>
                  </div>
                ))}
              </div>
            ) : !Array.isArray(value) ? (
              <div className="w-42 relative mr-4  md:w-44">
                <Button
                  variant="icon"
                  onClick={() => {
                    onChange("");
                  }}
                  className={classNames(
                    "z-1 absolute right-1 top-1 rounded-bl-md rounded-tr-md bg-white px-2 py-2"
                  )}
                >
                  <TrashIcon className="h-4 w-4 text-secondary" />
                </Button>
                <PreviewThumbnail key={value} url={value} />
              </div>
            ) : (
              <PreviewPlaceholder />
            )}
          </Fragment>
        ) : (
          <PreviewPlaceholder />
        )}
        <div className="mt-3 space-x-2">
          <Button variant="secondary" onClick={openUpload}>
            Upload Image
          </Button>
        </div>
      </div>

      {touched && errors ? (
        <p className="mt-2 text-sm text-red-600" id="image-errors">
          {errors}
        </p>
      ) : null}

      <Transition.Root show={open} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-4xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1">
                    <div className="px-4 py-8 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pb-5">
                          <Tab.Group
                            selectedIndex={activeTab}
                            onChange={setActiveTab}
                          >
                            <Tab.List className="flex border-b border-solid border-gray-200 px-4">
                              <Tab className={handleTabClass}>Upload Files</Tab>
                              <Tab className={handleTabClass}>
                                Media Library
                              </Tab>
                              <div className="ml-auto flex items-center space-x-4">
                                <div className="text-sm">
                                  Selected:
                                  <span
                                    className={classNames(
                                      "ml-2 inline-block rounded-full border border-solid px-1.5 py-[0.2rem] text-[0.8rem] font-medium leading-none",
                                      selected.length > 0
                                        ? "border-black bg-black font-medium text-white"
                                        : "border-gray-200 text-gray-500"
                                    )}
                                  >
                                    {selected.length}
                                  </span>
                                </div>
                                <Link
                                  to="/media"
                                  target="_blank"
                                  className="text-base text-gray-500 hover:text-black"
                                >
                                  <span className="sr-only">
                                    Open media library
                                  </span>
                                  <span className="bi bi-gear"></span>
                                </Link>
                              </div>
                            </Tab.List>
                            <Tab.Panels>
                              <Tab.Panel>
                                <Uploader
                                  onUpload={(files) => {
                                    setMedia((prev) => [...files, ...prev]);
                                    setActiveTab(1);
                                  }}
                                />
                              </Tab.Panel>
                              <Tab.Panel>
                                <nav className="mb-5 md:flex md:justify-end">
                                  <div className="md:w-1/3">
                                    <Field
                                      type="search"
                                      placeholder="Search"
                                      onChange={(event) =>
                                        setQuery(event.target.value)
                                      }
                                      value={query}
                                      isLabel={false}
                                    />
                                  </div>
                                </nav>
                                {loading ? (
                                  <div className="flex w-full justify-center py-10 md:py-16">
                                    <Logo />
                                  </div>
                                ) : (
                                  <div className="grid grid-cols-2 items-end gap-4 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-5">
                                    {filteredMedia.map((file) => {
                                      const isSelected = selected.some(
                                        (f) => f === file.url
                                      );
                                      return (
                                        <div
                                          key={file.name}
                                          className={classNames(
                                            "relative rounded-md border border-dashed border-gray-200 p-2",
                                            isSelected
                                              ? "bg-primary-50"
                                              : "bg-transparent"
                                          )}
                                        >
                                          <Preview
                                            file={file}
                                            onDelete={(url: string) => {
                                              setMedia((prev) =>
                                                prev.filter(
                                                  (f) => f.url !== url
                                                )
                                              );
                                            }}
                                            onClick={() => {
                                              handleSelect(file.url);
                                            }}
                                            onOpen={handleModal}
                                          />
                                          <button
                                            onClick={() =>
                                              handleSelect(file.url)
                                            }
                                            className={classNames(
                                              "absolute right-0 top-0 rounded-bl-md px-2 py-2",
                                              isSelected
                                                ? "bg-primary-50"
                                                : "bg-white"
                                            )}
                                          >
                                            {isSelected ? (
                                              <CheckIcon className="h-4 w-4 text-primary" />
                                            ) : (
                                              <MinusIcon className="h-4 w-4 text-secondary" />
                                            )}
                                          </button>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              </Tab.Panel>
                            </Tab.Panels>
                          </Tab.Group>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        {t("text_cancel")}
                      </Button>
                      <Button
                        onClick={() => {
                          onChange(isMulti ? selected : selected[0]);
                          setOpen(false);
                        }}
                        disabled={loading || selected.length === 0}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                            {t("text_processing")}
                          </>
                        ) : (
                          t("text_insert")
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal
        file={activeFile}
        open={openModal}
        onClose={closeModal}
        onDelete={(url: string) => {
          setMedia((prev) => prev.filter((f) => f.url !== url));
          setOpenModal(false);
          setActiveFile(null);
        }}
      />
    </div>
  );
}

function PreviewThumbnail({ url }: { url: string }) {
  return (
    <div className="relative w-full rounded-md border border-dashed border-gray-300 bg-white p-2">
      <div className="relative w-full overflow-hidden pb-[100%]">
        <img
          src={url}
          alt="file"
          className="absolute left-0 top-0 h-full w-full rounded-md object-contain"
        />
      </div>
    </div>
  );
}

function PreviewPlaceholder() {
  return (
    <div className="relative mr-4 flex h-40 w-40 items-center justify-center space-x-2 overflow-hidden rounded-md border border-dashed border-gray-300 bg-white px-6 md:h-36 md:w-36">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 48 48"
        aria-hidden="true"
      >
        <path
          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
