import { gql } from "@apollo/client";

export const GET_LOCATION_TYPES = gql`
  query FetchBinLocationTypes {
    fetchBinLocationTypes {
      id
      name
      createdAt
      updatedAt
      status
    }
  }
`;

export const ADD_LOCATION_TYPE = gql`
  mutation BinLocationTypeCreate(
    $name: String!
    $description: String
    $status: Boolean!
  ) {
    binLocationTypeCreate(
      input: {
        params: { name: $name, description: $description, status: $status }
      }
    ) {
      type {
        id
        name
        createdAt
        status
      }
    }
  }
`;

export const EDIT_LOCATION_TYPE = gql`
  mutation BinLocationTypeUpdate(
    $id: ID!
    $name: String!
    $description: String
    $status: Boolean!
  ) {
    binLocationTypeUpdate(
      input: {
        id: $id
        params: { name: $name, description: $description, status: $status }
      }
    ) {
      type {
        id
        name
        createdAt
        status
      }
    }
  }
`;

export const REMOVE_LOCATION_TYPE = gql`
  mutation BinLocationTypeDelete($id: ID!) {
    binLocationTypeDelete(input: { id: $id }) {
      type {
        id
        name
      }
    }
  }
`;

export type LocationType = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  status: boolean;
};
