import { RadioGroup } from "@headlessui/react";
import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { getIn, useFormik } from "formik";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import slugify from "slugify";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldFleetVendor,
  FieldImage,
  FieldVehicle,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { FleetVendorClassification } from "../../../../graphql/fleets/settings/vendors";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  FuelFile,
  FuelFileType,
  VehicleFuelEntryForm,
} from "../../../../graphql/fleets/vehicles/pages/fuel-entry";
import {
  renderFuelUnit,
  renderPrimaryMeter,
} from "../../../../graphql/fleets/vehicles/pages/settings";
import { classNames, useFormikErrors } from "../../../../utils";
import { FieldVehicleFuelTypes } from "./DetailsForm";

export default function Form({
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  vehicleBasic: vehicleBasicProp,
}: {
  initialValues: VehicleFuelEntryForm;
  onSubmit: (values: VehicleFuelEntryForm, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  vehicleBasic: VehicleBasic | null;
}) {
  const { vehicleId } = useParams();
  const { t } = useTranslation();

  const [vehicleBasic, setVehicleBasic] = useState<VehicleBasic | null>(null);
  useEffect(() => {
    if (vehicleId === undefined) return;
    setVehicleBasic(vehicleBasicProp);
  }, [vehicleBasicProp, vehicleId]);

  const ValidationSchema = Yup.object().shape({
    vehicleFuelTypeId: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    referenceNo: Yup.string().nullable(),
    pricePerUnit: Yup.number().nullable(),
    odometer: Yup.number().nullable(),
    fuelFlags: Yup.number().nullable(),
    fuelEntryDate: Yup.string().typeError("Required").required("Required"),
    fuelAmount: Yup.number().nullable(),
    fleetVendorId: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    files: Yup.array()
      .of(
        Yup.object().shape({
          fileType: Yup.number()
            .typeError("Must be a number")
            .required("Required"),
          fileUrl: Yup.string().required("Required"),
        })
      )
      .nullable(),
    comments: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  const handleMoreFile = () => {
    formik.setFieldValue("files", [
      ...formik.values.files,
      { fileType: null, fileUrl: "" },
    ]);
  };

  const handleRemoveFile = (index: number) => {
    const files = [...formik.values.files];
    files.splice(index, 1);
    formik.setFieldValue("files", files);
  };

  const fuleFileTypes = useMemo(
    () => [
      {
        label: t("text_image"),
        value: FuelFileType.IMAGE,
        description: "Image file",
      },
      {
        label: t("text_document"),
        value: FuelFileType.DOCUMENT,
        description: "Document file",
      },
    ],
    [t]
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          {vehicleId === undefined ? (
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_vehicle")}
              </label>
              <FieldVehicle
                value={formik.values.vehicleId}
                onChange={(value) => {
                  formik.setFieldValue("vehicleId", value ? value.id : null);
                  setVehicleBasic(value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.vehicleId && formik.errors.vehicleId
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.vehicleId && formik.errors.vehicleId ? (
                <p className="mt-2 text-sm text-red-600" id="vehicleId-errors">
                  {formik.errors.vehicleId}
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_vehicle_fuel_type")}
            </label>
            <FieldVehicleFuelTypes
              value={formik.values.vehicleFuelTypeId}
              onChange={(value) => {
                formik.setFieldValue("vehicleFuelTypeId", value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.vehicleFuelTypeId &&
                  formik.errors.vehicleFuelTypeId
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            <p className="mt-1 text-xs text-gray-500">
              Type to create new vehicle flue type
            </p>
            {formik.touched.vehicleFuelTypeId &&
            formik.errors.vehicleFuelTypeId ? (
              <p
                className="mt-2 text-sm text-red-600"
                id="vehicleFuelTypeId-errors"
              >
                {formik.errors.vehicleFuelTypeId.toString()}
              </p>
            ) : null}
          </div>

          <div className="sm:col-span-3">
            <Field
              title={t("text_reference_no")}
              name="referenceNo"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.referenceNo}
              touched={formik.touched.referenceNo}
              errors={formik.errors.referenceNo}
            />
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t("text_price_per_unit")}
              name="pricePerUnit"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pricePerUnit ?? ""}
              touched={formik.touched.pricePerUnit}
              errors={formik.errors.pricePerUnit}
            />
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t("text_odometer")}
              name="odometer"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.odometer ?? ""}
              touched={formik.touched.odometer}
              errors={formik.errors.odometer}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_fuel_flags")}
            </label>
            <FieldFuelFlag
              value={formik.values.fuelFlags}
              onChange={(value) => {
                formik.setFieldValue("fuelFlags", value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.fuelFlags && formik.errors.fuelFlags
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.fuelFlags && formik.errors.fuelFlags ? (
              <p className="mt-2 text-sm text-red-600" id="fuelFlags-errors">
                {formik.errors.fuelFlags}
              </p>
            ) : null}
          </div>
          <div className="sm:col-span-3">
            <FieldDatepicker
              title={t("text_fuel_entry_date")}
              name="fuelEntryDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "fuelEntryDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.fuelEntryDate
                  ? new Date(formik.values.fuelEntryDate)
                  : null
              }
              touched={formik.touched.fuelEntryDate}
              errors={formik.errors.fuelEntryDate}
            />
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t(
                `text_fuel_unit_${slugify(
                  renderFuelUnit(vehicleBasic?.fuelUnit),
                  {
                    replacement: "_",
                    lower: true,
                    strict: true,
                  }
                )}`
              )}
              name="fuelAmount"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fuelAmount ?? ""}
              touched={formik.touched.fuelAmount}
              errors={formik.errors.fuelAmount}
            />
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_fleet_vendor")}
            </label>
            <FieldFleetVendor
              title={t("text_fleet_vendor")}
              value={formik.values.fleetVendorId}
              onChange={(value) => {
                formik.setFieldValue("fleetVendorId", value);
              }}
              classification={FleetVendorClassification.FUEL}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.fleetVendorId && formik.errors.fleetVendorId
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.fleetVendorId && formik.errors.fleetVendorId ? (
              <p
                className="mt-2 text-sm text-red-600"
                id="fleetVendorId-errors"
              >
                {formik.errors.fleetVendorId}
              </p>
            ) : null}
          </div>
          <div className="sm:col-span-6">
            <Field
              title={t("text_comments")}
              name="comments"
              type="textarea"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comments}
              touched={formik.touched.comments}
              errors={formik.errors.comments}
            />
          </div>
          <div className="sm:col-span-6">
            <div className="mb-1 block text-sm font-medium text-gray-900">
              Photos or Documents
            </div>
            {formik.values.files.map((file: FuelFile, index: number) => (
              <div key={`file-${index}`} className="flex items-start">
                <div className="grid flex-1 gap-4 sm:grid-cols-2">
                  <div>
                    <RadioGroup
                      name={`files[${index}].fileType`}
                      value={file.fileType}
                      onChange={(value: number) => {
                        formik.setFieldValue(`files[${index}].fileType`, value);
                      }}
                    >
                      <RadioGroup.Label className="sr-only text-sm font-medium text-gray-900">
                        {t("text_type")}
                      </RadioGroup.Label>

                      <div className="isolate mt-1 -space-y-px rounded-md bg-white">
                        {fuleFileTypes.map((type, typeIdx) => (
                          <RadioGroup.Option
                            key={type.label}
                            value={type.value}
                            className={({ checked }) =>
                              classNames(
                                typeIdx === 0
                                  ? "rounded-tl-md rounded-tr-md"
                                  : "",
                                typeIdx === fuleFileTypes.length - 1
                                  ? "rounded-bl-md rounded-br-md"
                                  : "",
                                checked
                                  ? "z-10 border-primary-200 bg-primary-50"
                                  : "border-gray-200",
                                "relative flex cursor-pointer border p-4 focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    checked
                                      ? "border-transparent bg-primary-600"
                                      : "border-gray-300 bg-white",
                                    active
                                      ? "ring-2 ring-primary-500 ring-offset-2"
                                      : "",
                                    "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                </span>
                                <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-primary-900"
                                        : "text-gray-900",
                                      "block text-sm font-medium"
                                    )}
                                  >
                                    {type.label}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-primary-700"
                                        : "text-gray-500",
                                      "block text-sm"
                                    )}
                                  >
                                    {type.description}
                                  </RadioGroup.Description>
                                </span>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                    {getIn(formik.touched, `files[${index}].fileType`) &&
                    getIn(formik.errors, `files[${index}].fileType`) ? (
                      <p className="mt-2 text-sm text-red-600">
                        {getIn(formik.errors, `files[${index}].fileType`)}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <FieldImage
                      title={t("text_file")}
                      onChange={(value) => {
                        formik.setFieldValue(`files[${index}].fileUrl`, value);
                      }}
                      value={formik.values.files[index].fileUrl}
                      touched={getIn(formik.errors, `files[${index}].fileUrl`)}
                      errors={getIn(formik.errors, `files[${index}].fileUrl`)}
                    />
                  </div>
                </div>
                <Button
                  variant="text"
                  onClick={() => {
                    handleRemoveFile(index);
                  }}
                  className="ml-4"
                >
                  <TrashIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-gray-700"
                  />
                </Button>
              </div>
            ))}
            {formik.values.files.length === 0 ? (
              <div className="mt-2 py-6 text-center text-sm md:py-12">
                <ExclamationCircleIcon
                  type="outline"
                  name="exclamation-circle"
                  className="mx-auto h-6 w-6 text-gray-400"
                />
                <p className="mt-4 text-gray-900">No files added</p>
                <Button
                  variant="secondary"
                  onClick={handleMoreFile}
                  className="mt-3"
                >
                  Add File
                </Button>
              </div>
            ) : (
              <div className="mt-2 flex justify-end">
                <Button variant="secondary" onClick={handleMoreFile}>
                  <span className="sr-only">Add More</span>
                  <PlusIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-gray-700"
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={onCancel}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <Fragment>
                <Spinner />
                {t("text_processing")}
              </Fragment>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export function FieldFuelFlag({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Personal", value: "0" },
      { label: "Partial Fill", value: "1" },
      { label: "Missed Entry", value: "2" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}
