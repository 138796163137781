import { gql } from "@apollo/client";

export const GET_CUSTOMERCONTACTS = gql`
  query FetchCustomerContacts {
    fetchCustomerContacts {
      id
      contactDetails {
        id
        name
        contactType {
          id
          name
        }
        phoneNumber
        email
        sendSms
      }
      createdAt
      customer {
        id
        customerName
      }
      emailImportantUpdates
      emailSpecials
      firstName
      mobileNumber
      phoneNumber
      postalAddress
      postcode
      state
      suburb
      surName
    }
  }
`;
