import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  EDIT_VENDOR,
  GET_VENDOR_BY_ID,
  GET_VENDORS,
} from "../../../graphql/purchases/vendors";
import Form from "./components/Form";

const VendorUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { vendorId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type Vendor = {
    id: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    vendorCode: string;
    webAddress: string;
    vendorType: any;
    address: string;
    comments: string;
    status: boolean;
  };

  const { data, loading, error } = useQuery<{
    fetchVendor: Vendor;
  }>(GET_VENDOR_BY_ID, {
    variables: {
      id: vendorId,
    },
  });
  const vendor = useMemo<Vendor | undefined>(() => data?.fetchVendor, [data]);

  const [updateVendor] = useMutation(EDIT_VENDOR, {
    refetchQueries: [{ query: GET_VENDORS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateVendor({
      variables: {
        id: vendorId,
        companyName: values.companyName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        webAddress: values.webAddress,
        vendorType: values.vendorType
          ? parseInt(values.vendorType.value)
          : null,
        address: values.address,
        comments: values.comments,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.vendorUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vendor updated successfully",
          });
          return navigate(`/purchases/vendors`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vendor update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Vendor update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={VendorUpdateResource.name}
        heading="Vendors"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Vendors",
            href: "/purchases/vendors",
          },
          {
            name: VendorUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_vendor")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_vendor")}
              </p>
            </div>
          </div>

          {loading || !vendor ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                companyName: vendor.companyName ?? "",
                email: vendor.email ?? "",
                phoneNumber: vendor.phoneNumber ?? "",
                vendorCode: vendor.vendorCode ?? "",
                webAddress: vendor.webAddress ?? "",
                vendorType: vendor.vendorType
                  ? {
                      value: vendor.vendorType,
                      label: vendor.vendorType === 1 ? "Company" : "Individual",
                    }
                  : null,
                address: vendor.address ?? "",
                comments: vendor.comments ?? "",
                status: vendor.status ? true : false,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VendorUpdate;
export const VendorUpdateResource: ResourceProps = {
  name: "Edit Vendor",
  description: "Update an existing vendor",
  access: ["update-vendors"],
  path: "vendors/:vendorId",
};
