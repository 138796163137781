import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, FieldDepartmentPicker } from "../../../../components/form";
import { Picking } from "../../../../graphql/pickpack/picking";
import { classNames, formatVariantTitle } from "../../../../utils";

type InitialValues = {
  orders: {
    id: string;
    orderProducts: {
      pickerId: number | null;
      productSkuId: string;
    }[];
  }[];
};

export default function FormManual({
  heading,
  initialValues,
  pickings,
  department,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: InitialValues;
  pickings: Picking[];
  department: SingleValue<OptionProps>;
  onSubmit: (values: InitialValues, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();
  const [selectedPicker, setSelectedPicker] = useState<number | null>(null);

  const ValidationSchema = Yup.object().shape({
    orders: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        orderProducts: Yup.array().of(
          Yup.object().shape({
            pickerId: Yup.number().nullable(),
            productSkuId: Yup.string(),
          })
        ),
      })
    ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 pb-4 pt-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Please assign zone pickers to picking products.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="pb-5">
                {department ? (
                  <header className="mb-4 mt-4">
                    <FieldDepartmentPicker
                      title="Picker"
                      departmentId={department.value}
                      value={selectedPicker}
                      onChange={(value) => {
                        console.log(123, value);
                        formik.setFieldValue(
                          "orders",
                          formik.values.orders.map((item) => {
                            return {
                              ...item,
                              orderProducts: item.orderProducts.map((item) => {
                                return {
                                  ...item,
                                  pickerId: value,
                                };
                              }),
                            };
                          })
                        );
                        setSelectedPicker(value);
                      }}
                      className={classNames(
                        "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                      )}
                    />
                  </header>
                ) : null}

                <div className="space-y-4 divide-y divide-gray-200">
                  {formik.values.orders.map((item, index) => {
                    const picking = pickings.find((p) => p.id === item.id);
                    if (!picking) return null;
                    return (
                      <div
                        key={`picking-assign-${picking.orderNumber}`}
                        className="py-4"
                      >
                        <header className="py-2">
                          <h3 className="text-base font-medium">
                            Order No. #{picking.orderNumber}
                          </h3>
                        </header>

                        <div className="max-h-[50vh] min-w-full max-w-full">
                          <table className="relative z-0 min-w-full table-fixed pt-20">
                            <thead className="border-b border-gray-200 bg-greyish">
                              <tr>
                                <th className="aling-top whitespace-nowrap py-2 pl-6 pr-2 text-left align-top text-xs font-medium uppercase tracking-wider">
                                  <div className="group flex w-full items-center rounded-md px-1.5 py-1.5 text-gray-700 transition ease-in-out md:px-2 md:py-2">
                                    No.
                                  </div>
                                </th>
                                <th className="aling-top whitespace-nowrap py-2 pl-2 pr-2 text-left align-top text-xs font-medium uppercase tracking-wider">
                                  <div className="group flex w-full items-center rounded-md px-1.5 py-1.5 text-gray-700 transition ease-in-out md:px-2 md:py-2">
                                    Product Name
                                  </div>
                                </th>
                                <th className="aling-top whitespace-nowrap py-2 pl-2 pr-6 text-left align-top text-xs font-medium uppercase tracking-wider">
                                  <div className="group flex w-full items-center rounded-md px-1.5 py-1.5 text-gray-700 transition ease-in-out md:px-2 md:py-2">
                                    Picker
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="relative z-[-1]">
                              {item.orderProducts.map((item, idx) => {
                                const pickingProduct =
                                  picking.orderDetails.find(
                                    (p) => p.variant.id === item.productSkuId
                                  );
                                if (!pickingProduct) return null;
                                return (
                                  <tr
                                    key={`picking-assign-${picking.orderNumber}-${pickingProduct.variant.id}`}
                                    className="group shadow-transparent transition hover:bg-primary-50 hover:shadow-base"
                                  >
                                    <td className="py-3 pl-8 pr-4 text-sm">
                                      <span className="whitespace-nowrap">
                                        {idx + 1}.
                                      </span>
                                    </td>
                                    <td className="py-3 pl-4 pr-4 text-sm">
                                      <span className="whitespace-nowrap">
                                        {pickingProduct.variant.product?.name} -{" "}
                                        {formatVariantTitle(
                                          pickingProduct.variant.variantTitle
                                        )}
                                      </span>
                                    </td>
                                    <td className="py-3 pl-4 pr-4 text-sm">
                                      {pickingProduct.variant.product ? (
                                        <FieldDepartmentPicker
                                          title="Picker"
                                          departmentId={
                                            pickingProduct.variant.product
                                              .department.id
                                          }
                                          value={item.pickerId}
                                          onChange={(value) => {
                                            formik.setFieldValue(
                                              `orders.${index}.orderProducts.${idx}.pickerId`,
                                              value
                                            );
                                          }}
                                          className={classNames(
                                            "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                                          )}
                                          isPreview={department ? true : false}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
