import { useQuery } from "@apollo/client/react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import {
  Fragment,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DebounceInput } from "react-debounce-input";
import {
  Outlet,
  RouteObject,
  useLocation,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { useReactToPrint } from "react-to-print";

import placeholder from "../../../assets/placeholder.svg";
import {
  ErrorFallback,
  Head,
  NotAuthorized,
  NotFound,
} from "../../../components/core";
import {
  Button,
  FieldDatepicker,
  selectStyles,
  SelectWrapper,
} from "../../../components/form";
import {
  IndeterminateCheckbox,
  LimitBy,
  TableCursor,
} from "../../../components/table";
import {
  DeliveryRun,
  GET_DELIVERYRUNS,
} from "../../../graphql/fleets/settings/delivery-runs";
import {
  Department,
  GET_DEPARTMENTS,
} from "../../../graphql/inventory/departments";
import {
  GET_PACKING_PRODUCTS,
  type Packing,
} from "../../../graphql/pickpack/packing";
import { Customer, GET_CUSTOMERS } from "../../../graphql/sales/customers";
import { OrderStatus, renderStatus } from "../../../graphql/sales/orders";
import {
  OrderSlip,
  OrderSlipDepartment,
  OrderSlipProps,
} from "../../../print/orders";
import { IconFilterClear } from "../../../svgs";
import {
  classNames,
  formatCurrency,
  formatDate,
  formatVariantTitle,
} from "../../../utils";
import { isAuthorizedForResource, useAuth } from "../../auth";
import { AuthShield } from "../../auth/core";
import { PackingDetailResource } from "./Detail";
const PackingDetail = lazy(() => import("./Detail"));
interface RowOrderSlipProps extends OrderSlipProps {
  departments?: OrderSlipDepartment[];
}

const PackingList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  let location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string | null>(
    searchParams.get("query") || ""
  );
  const [customer, setCustomer] = useState<SingleValue<OptionProps>>(null);
  const [deliveryRun, setDeliveryRun] =
    useState<SingleValue<OptionProps>>(null);
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [department, setDepartment] = useState<SingleValue<OptionProps>>(null);
  const [status, setStatus] = useState<SingleValue<OptionProps>>(null);
  const [refetching, setRefetching] = useState<boolean>(false);

  const [isMorefetching, setIsMorefetching] = useState(false);
  const [pageLimit] = useState<LimitBy>(LimitBy.L4);
  const [rowSelection, setRowSelection] = useState({});

  const [printData, setPrintData] = useState<RowOrderSlipProps | null>(null);
  const [printsData, setPrintsData] = useState<RowOrderSlipProps[] | null>(
    null
  );
  const orderComponentRef = useRef(null);
  const ordersComponentRef = useRef(null);
  const [loadingPrints, setLoadingPrints] = useState(false);

  const orderPrintContent = useCallback(() => {
    return orderComponentRef.current;
  }, []);

  const ordersPrintContent = useCallback(() => {
    return ordersComponentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: orderPrintContent,
    documentTitle: `Alpha Fresh - Packing Slip - ${new Date().toISOString()}`,
    removeAfterPrint: true,
  });

  const handlePrints = useReactToPrint({
    content: ordersPrintContent,
    documentTitle: `Alpha Fresh - Packing Slip - ${new Date().toISOString()}`,
    onBeforeGetContent: () => {
      setLoadingPrints(true);
    },
    onAfterPrint: () => {
      setLoadingPrints(false);
    },
    removeAfterPrint: true,
  });

  useEffect(() => {
    setSearchQuery(searchParams.get("query") || "");
  }, [searchParams]);

  const handleClearFilters = useCallback(() => {
    setSearchParams({ query: "" });
    setCustomer(null);
    setDeliveryRun(null);
    setDeliveryDate("");
    setDepartment(null);
    setStatus(null);
  }, [setSearchParams]);

  const {
    data: customersData,
    loading: customersLoading,
    refetch: customersRefetch,
  } = useQuery(GET_CUSTOMERS);

  const {
    data: deliveryRunsData,
    loading: deliveryRunsLoading,
    refetch: deliveryRunsRefetch,
  } = useQuery(GET_DELIVERYRUNS);

  const {
    data: departmentsData,
    loading: departmentsLoading,
    refetch: departmentsRefetch,
  } = useQuery(GET_DEPARTMENTS);

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");
  if (customer) searchFilter.push("customer");
  if (deliveryRun) searchFilter.push("deliveryrun");
  if (deliveryDate) searchFilter.push("deliverydate");
  if (department) searchFilter.push("department");
  if (status) searchFilter.push("status");

  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_PACKING_PRODUCTS,
    {
      variables: {
        first: pageLimit,
        searchQuery,
        searchFilter,
        customerId: customer ? customer.value : null,
        deliveryrunId: deliveryRun ? deliveryRun.value : null,
        deliveryDate,
        departmentId: department ? department.value : null,
        status: status ? parseInt(status.value) : null,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    if (location.pathname === "/pickpack/packing") {
      refetch();
    }
  }, [location.pathname, refetch]);

  const totalProducts = useMemo(
    () => data?.fetchPackingProducts?.totalCount || 0,
    [data?.fetchPackingProducts?.totalCount]
  );

  const products: Packing[] = useMemo(
    () =>
      data?.fetchPackingProducts?.edges?.flatMap((edge: { node: Packing }) => {
        const filteredItems = department
          ? edge.node.items.filter(
              (item) =>
                item.variant?.product?.department?.id === department?.value
            )
          : edge.node.items;
        return {
          ...edge.node,
          items: filteredItems,
        };
      }) ?? [],
    [data?.fetchPackingProducts?.edges, department]
  );

  const filtering: boolean = useMemo(
    () =>
      searchFilter.length > 0 ||
      deliveryRun ||
      department ||
      customer ||
      typeof status === "number" ||
      !!deliveryDate ||
      !!searchQuery
        ? true
        : false,
    [
      searchFilter.length,
      deliveryRun,
      department,
      customer,
      status,
      deliveryDate,
      searchQuery,
    ]
  );

  const fetchNextPage = useCallback(
    (table: any) => {
      setIsMorefetching(true);
      fetchMore({
        variables: {
          cursor: data?.fetchPackingProducts?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setTimeout(() => {
          setIsMorefetching(false);
          table.setPageIndex(table.getPageCount() - 1);
        }, 500);
      });
    },
    [data?.fetchPackingProducts?.pageInfo?.endCursor, fetchMore]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  const columns = useMemo<ColumnDef<Packing>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        id: "no",
        header: "SL. No",
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.row.index + 1}</span>
        ),
      },
      {
        accessorKey: "customer",
        header: "To",
        enableHiding: false,
        cell: ({ row }) => (
          <span className="whitespace-nowrap">
            {row.original.customer?.customerName}
          </span>
        ),
      },
      {
        accessorKey: "salesRep",
        header: () => <span className="whitespace-nowrap">Sales Rep</span>,
      },
      {
        accessorKey: "deliveryRun",
        header: () => <span className="whitespace-nowrap">Delivery Run</span>,
      },
      {
        accessorKey: "loadingDock",
        header: () => <span className="whitespace-nowrap">Loading Dock</span>,
      },
      {
        accessorKey: "createdAt",
        header: () => <span className="whitespace-nowrap">Order Date</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {formatDate(props.getValue<string>())}
          </span>
        ),
      },
      {
        accessorKey: "boxCount",
        header: () => <span className="whitespace-nowrap">Box Count</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue<string>()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: () => <span className="whitespace-nowrap">Status</span>,
        cell: (props) => renderStatus(props.getValue<number>()),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-packings"]}>
              <Button
                type="link"
                href={`/pickpack/packing/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, packing of {props.row.original.customer?.customerName}
                </span>
              </Button>
            </AuthShield>
            <Button
              variant="icon"
              className="text-primary-700 hover:text-primary-700"
              onClick={() => {
                const pdata = { ...props.row.original };
                const departments =
                  pdata?.items?.map((item) => ({
                    id: item.variant.product?.department.id ?? "",
                    name: item.variant.product?.department.name ?? "",
                  })) || [];
                const departmentsFiltered = departments.reduce(
                  (acc, current) => {
                    const x = acc.find(
                      (item) =>
                        item.id === current.id && item.name === current.name
                    );
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  },
                  [] as OrderSlipDepartment[]
                );

                setPrintData({
                  ...pdata,
                  departmentName: "",
                  departments: departmentsFiltered,
                });
                setTimeout(() => {
                  handlePrint();
                }, 250);
              }}
            >
              <PrinterIcon aria-hidden="true" className="text-md h-4 w-4" />
              <span className="sr-only">
                Print, packing of {props.row.original.customer?.customerName}
              </span>
            </Button>
          </div>
        ),
      },
    ],
    [handlePrint]
  );

  const statusData = [
    { value: String(OrderStatus.PACKING), label: "Packing" },
    { value: String(OrderStatus.SHIPPED), label: "Ready To Ship" },
  ];

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Packing Products"
        heading="Packing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: PackingListResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PackingListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PackingListResource.description}
            </p>
          </div>
        </div>

        <TableCursor
          data={products}
          columns={columns}
          loading={loading}
          totalRows={totalProducts}
          pageLimit={pageLimit}
          pageLimits={[pageLimit]}
          filtering={filtering}
          state={{
            rowSelection,
          }}
          enableRowSelection={true}
          onRowSelectionChange={setRowSelection}
          getRowCanExpand={() => true}
          getCoreRowModel={getCoreRowModel()}
          getExpandedRowModel={getExpandedRowModel()}
          renderSubComponent={(row) => {
            return (
              <div className="bg-primary-700/20 px-4 md:px-6 xl:px-16">
                <table className="min-w-full divide-y divide-gray-900/60">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell lg:pl-0"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell lg:w-44"
                      >
                        Product Name
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        PLU
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        Stock Code
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        Variant
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        Department
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium sm:table-cell lg:text-center"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium lg:text-center"
                      >
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-900/10">
                    {row.original.items?.length
                      ? row.original.items.map((item, index) => (
                          <Fragment key={item.id}>
                            <tr key={item.id}>
                              <td className="px-3 py-4 text-sm lg:pl-0">
                                {index + 1}
                              </td>
                              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm sm:w-auto sm:max-w-none">
                                <div className="flex items-center">
                                  <div className="h-11 w-11 flex-shrink-0 overflow-hidden rounded-md">
                                    {item.variant.variantImageUrl ? (
                                      <img
                                        className={classNames(
                                          "h-full w-full object-cover"
                                        )}
                                        src={item.variant.variantImageUrl}
                                        alt={item.variant?.product?.name}
                                      />
                                    ) : (
                                      <img
                                        className={classNames(
                                          "h-full w-full object-cover"
                                        )}
                                        src={
                                          item.variant?.product?.featureImageUrl
                                            ? item.variant.product
                                                .featureImageUrl
                                            : placeholder
                                        }
                                        alt={item.variant?.product?.name}
                                      />
                                    )}
                                  </div>
                                  <div className="ml-4">
                                    <div className="font-normal text-gray-900">
                                      {item.variant?.product?.name}
                                    </div>
                                  </div>
                                </div>
                                <dl className="font-normal lg:hidden">
                                  <dt className="sr-only">PLU</dt>
                                  <dd className="mt-1 truncate">
                                    {item.variant.plu}
                                  </dd>
                                  <dt className="sr-only">Stock Code</dt>
                                  <dd className="mt-1 truncate">
                                    {item.variant.stockCode}
                                  </dd>
                                  <dt className="sr-only">Variant</dt>
                                  <dd className="mt-1 truncate">
                                    {formatVariantTitle(
                                      item.variant.variantTitle
                                    )}
                                  </dd>
                                  <dt className="sr-only">Department</dt>
                                  <dd className="mt-1 truncate">
                                    {item.variant.product?.department?.name}
                                  </dd>
                                  <dt className="sr-only sm:hidden">
                                    Quantity
                                  </dt>
                                  <dd className="mt-1 truncate sm:hidden">
                                    {item.quantity}
                                  </dd>
                                </dl>
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {item.variant.plu}
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {item.variant.stockCode}
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {formatVariantTitle(item.variant.variantTitle)}
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {item.variant.product?.department?.name}
                              </td>
                              <td className="hidden px-3 py-4 text-center text-sm sm:table-cell">
                                <span className="inline-block rounded-md border border-gray-900/30 px-4 py-2">
                                  {item.quantity}
                                </span>
                              </td>
                              <td className="px-3 py-4 text-center text-sm">
                                {formatCurrency(item.price)}
                              </td>
                            </tr>
                            {item.packingComments ? (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="w-full px-4 py-4 text-sm sm:px-0"
                                >
                                  <div className="flex bg-white/50">
                                    <div className="bg-black px-4 py-2 text-white md:px-6 md:py-3 xl:px-8 xl:py-4">
                                      Comments
                                    </div>
                                    <div className="px-4 py-2 md:px-6 md:py-3 xl:px-8 xl:py-4">
                                      {item.packingComments ??
                                        "No comments found for item"}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </Fragment>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            );
          }}
          enableNavigation
          renderNavigation={(table) => (
            <Button
              variant="icon"
              onClick={() => {
                fetchNextPage(table);
              }}
              disabled={!data?.fetchPackingProducts?.pageInfo?.hasNextPage}
              loading={isMorefetching}
              className="flex h-8 w-8 items-center justify-center rounded-md bg-primary-300 text-black disabled:bg-gray-200"
            >
              <span className="sr-only"> Go to next page</span>
              <span className="bi bi-chevron-bar-right"></span>
            </Button>
          )}
          enableAction
          renderAction={(table) => (
            <Fragment>
              <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                  <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <DebounceInput
                    type="search"
                    className={classNames(
                      "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                      "shadow-none outline-none focus:ring-0",
                      "placeholder-black/50 placeholder-opacity-100"
                    )}
                    placeholder="Search for name"
                    minLength={2}
                    debounceTimeout={300}
                    value={searchQuery ?? ""}
                    onChange={(e) => {
                      setSearchParams({
                        query: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                    <Select
                      className="h-11 w-full xl:whitespace-nowrap"
                      closeMenuOnSelect={true}
                      styles={selectStyles}
                      value={customer}
                      options={customersData?.fetchCustomers.map(
                        (d: Customer) => ({
                          value: d.id,
                          label: d.customerName,
                        })
                      )}
                      onChange={setCustomer}
                      isLoading={customersLoading}
                      placeholder="Filter by customer"
                      isClearable
                      onMenuOpen={() => {
                        customersRefetch();
                      }}
                    />
                  </SelectWrapper>
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                    <Select
                      className="h-11 w-full xl:whitespace-nowrap"
                      closeMenuOnSelect={true}
                      styles={selectStyles}
                      value={deliveryRun}
                      options={deliveryRunsData?.fetchDeliveryRuns.map(
                        (d: DeliveryRun) => ({
                          value: d.id,
                          label: d.name,
                        })
                      )}
                      onChange={setDeliveryRun}
                      isLoading={deliveryRunsLoading}
                      placeholder="Filter by delivery run"
                      isClearable
                      onMenuOpen={() => {
                        deliveryRunsRefetch();
                      }}
                    />
                  </SelectWrapper>
                </div>
              </div>
              <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                    <Select
                      className="h-11 w-full xl:whitespace-nowrap"
                      closeMenuOnSelect={true}
                      styles={selectStyles}
                      value={department}
                      options={departmentsData?.fetchDepartments.map(
                        (d: Department) => ({
                          value: d.id,
                          label: d.name,
                        })
                      )}
                      onChange={setDepartment}
                      isLoading={departmentsLoading}
                      placeholder="Filter by department"
                      isClearable
                      onMenuOpen={() => {
                        departmentsRefetch();
                      }}
                    />
                  </SelectWrapper>
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <CalendarDaysIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <FieldDatepicker
                    title="Delivery Date"
                    name="deliveryDate"
                    onChange={(value) => {
                      if (!Array.isArray(value)) {
                        setDeliveryDate(
                          value ? new Date(value).toISOString() : ""
                        );
                      }
                    }}
                    selected={deliveryDate ? new Date(deliveryDate) : null}
                    isClearable
                    isLabel={false}
                    placeholderText="Delivery Date"
                    className="border-none focus-visible:ring-0"
                    disableIcon
                  />
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <Listbox value={status} onChange={setStatus}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="sr-only">
                          Status
                        </Listbox.Label>
                        <div className="relative">
                          <Listbox.Button className="relative h-11 w-full cursor-default pl-3 pr-10 text-left text-sm xl:whitespace-nowrap">
                            <span className="flex items-center">
                              <span
                                aria-label={status?.value}
                                className={classNames(
                                  Number(status?.value) === OrderStatus.PACKING
                                    ? "bg-primary-700"
                                    : Number(status?.value) ===
                                      OrderStatus.SHIPPED
                                    ? "bg-violet-700"
                                    : "bg-gray-300",
                                  "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                                )}
                              />
                              <span
                                className={classNames(
                                  "ml-3 block truncate",
                                  status ? "text-black" : "text-black/50"
                                )}
                              >
                                {status ? status.label : "Filter by status"}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute right-0 z-20 mt-5 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-3 md:w-44 md:px-2.5 md:py-3">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="16px"
                                height="16px"
                                viewBox="0 0 110.9 96"
                                enableBackground="new 0 0 110.9 96"
                                className="absolute -top-3 right-0 -z-0"
                                fill="#ffffff"
                                style={{
                                  filter:
                                    "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                                }}
                              >
                                <polygon points="110.9,0 0,96 110.9,96 " />
                              </svg>
                              {statusData.map((_status) => (
                                <Listbox.Option
                                  key={`status-${_status.value}`}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-primary-700/10 text-primary-700"
                                        : "",
                                      "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2"
                                    )
                                  }
                                  value={_status}
                                >
                                  {({ selected, active }) => (
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          Number(_status?.value) ===
                                            OrderStatus.PACKING
                                            ? "bg-primary-700"
                                            : Number(_status?.value) ===
                                              OrderStatus.SHIPPED
                                            ? "bg-violet-700"
                                            : "bg-gray-300",
                                          "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span
                                        className={classNames(
                                          selected ? "text-gray-900" : "",
                                          "ml-3 block truncate"
                                        )}
                                      >
                                        {_status.label}
                                        <span className="sr-only">
                                          {" "}
                                          is {status?.label}
                                        </span>
                                      </span>
                                    </div>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>

                <Button
                  variant="icon"
                  className="relative flex h-11 w-11 items-center justify-center rounded-md text-gray-600 transition-all hover:bg-primary-200 hover:text-primary-900"
                  onClick={handleClearFilters}
                  disabled={loading}
                >
                  <IconFilterClear className="h-5 w-5" />
                  <span className="sr-only">Clear Filters</span>
                </Button>
                <Button
                  onClick={() => {
                    const printsData = table
                      .getSelectedRowModel()
                      .rows.map((row) => {
                        const pdata = { ...row.original };
                        const departments =
                          pdata?.items?.map((item) => ({
                            id: item.variant.product?.department.id ?? "",
                            name: item.variant.product?.department.name ?? "",
                          })) || [];
                        const departmentsFiltered = departments.reduce(
                          (acc, current) => {
                            const x = acc.find(
                              (item) =>
                                item.id === current.id &&
                                item.name === current.name
                            );
                            if (!x) {
                              return acc.concat([current]);
                            } else {
                              return acc;
                            }
                          },
                          [] as OrderSlipDepartment[]
                        );
                        return {
                          ...pdata,
                          departmentName: "",
                          departments: departmentsFiltered,
                        };
                      });
                    setPrintsData(printsData);
                    setTimeout(() => {
                      handlePrints();
                    }, 250);
                  }}
                  disabled={
                    loading ||
                    table.getSelectedRowModel().rows.length === 0 ||
                    refetching
                  }
                >
                  <PrinterIcon
                    aria-hidden="true"
                    className="text-md mr-2 h-5 w-5"
                  />
                  {loadingPrints ? "Printing..." : "Print Selected"}
                </Button>
                <Button
                  variant="icon"
                  onClick={handleRefetch}
                  disabled={loading}
                  className="flex h-11 items-center justify-center px-2 text-blue-700"
                >
                  <ArrowPathIcon
                    aria-hidden="true"
                    className={classNames(
                      "h-5 w-5",
                      refetching ? "animate-spin" : ""
                    )}
                  />
                  <span className="sr-only">Refresh picking list</span>
                </Button>
              </div>
            </Fragment>
          )}
          fallbackTitle="No packing products found"
          fallbackText="Please create a order and move it to packing."
        />
      </div>

      <div className="hidden">
        <div ref={orderComponentRef}>
          {printData
            ? printData.departments?.map((department) => {
                const items = printData.items.filter(
                  (item) =>
                    item.variant?.product?.department?.id === department.id
                );
                return (
                  <Fragment key={`print-${department.id}`}>
                    <div className="page-break" />
                    <OrderSlip
                      data={{
                        ...printData,
                        items,
                        departmentName: department.name,
                      }}
                    />
                  </Fragment>
                );
              })
            : null}
        </div>
        <div ref={ordersComponentRef}>
          {printsData?.map((printData) => (
            <Fragment key={`print-${printData.orderNumber}`}>
              {printData
                ? printData.departments?.map((department) => {
                    const items = printData.items.filter(
                      (item) =>
                        item.variant?.product?.department?.id === department.id
                    );
                    return (
                      <Fragment key={`print-${department.id}`}>
                        <div className="page-break" />
                        <OrderSlip
                          data={{
                            ...printData,
                            items,
                            departmentName: department.name,
                          }}
                        />
                      </Fragment>
                    );
                  })
                : null}
            </Fragment>
          ))}
        </div>
      </div>

      <Outlet />
    </>
  );
};

function PackingPage({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) {
  const { currentRole } = useAuth();
  let routes: RouteObject[] = [
    {
      path: "/",
      element: <PackingList breadcrumbs={breadcrumbs} />,
      children: [
        {
          path: PackingDetailResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PackingDetailResource.access
          ) ? (
            <PackingDetail />
          ) : (
            <NotAuthorized
              error={new Error(PackingDetailResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
export default PackingPage;

export const PackingListResource: ResourceProps = {
  name: "Packing",
  description: "A list of products that are ready to be picked",
  access: ["read-packings"],
  path: "packing/*",
};
