import { useQuery } from "@apollo/client/react";
import { Combobox, Dialog, Menu, Tab, Transition } from "@headlessui/react";
import { Bars3Icon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import ms from "ms";
import { Fragment, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Avatar } from "../components/appearance";
import { GenContext } from "../contexts/GenContext";
import {
  GET_RECENT_ACTIVITIES,
  GET_USER_NOTIFICATIONS,
  RecentActivity,
  RecentActivityApi,
} from "../graphql/layout";
import { useAuth } from "../modules/auth";
import { classNames } from "../utils";

export default function HeaderLayout() {
  const { heading, breadcrumbs, handleMenu } = useContext(GenContext);
  const { currentUser } = useAuth();

  const { data: dataRecentActivities } = useQuery<{
    fetchRecentActivities: RecentActivityApi[];
  }>(GET_RECENT_ACTIVITIES, {
    variables: {
      limit: 5,
    },
  });
  const recentActivities = useMemo<RecentActivity[]>(() => {
    if (!dataRecentActivities?.fetchRecentActivities) return [];
    const recentList = dataRecentActivities.fetchRecentActivities.map(
      (recent) => {
        const date = new Date(recent.updatedAt).getTime();
        return {
          ...recent,
          title: `${recent.user.fullName} ${
            recent.event === "delete"
              ? "deleted"
              : recent.event === "create"
              ? "created"
              : recent.event === "update"
              ? "updated"
              : recent.event
          } ${recent.eventDescription}`,
          updatedAt: `${ms(Date.now() - date, { long: true })} ago`,
          user: recent.user,
        };
      }
    );
    return recentList;
  }, [dataRecentActivities]);

  type NotificationsApi = {
    id: string;
    message: string;
    updatedAt: string;
  };
  interface Notification extends NotificationsApi {
    title: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      fullName: string;
    };
  }

  const { data: dataUserNotifications } = useQuery<{
    me: {
      id: string;
      firstName: string;
      lastName: string;
      fullName: string;
      notifications: NotificationsApi[];
    };
  }>(GET_USER_NOTIFICATIONS);
  const notifications = useMemo<Notification[]>(() => {
    if (!dataUserNotifications?.me?.notifications) return [];
    const user = dataUserNotifications.me;
    const filteredList = dataUserNotifications.me.notifications.slice(0, 5);
    const recentList = filteredList.map((recent) => {
      const date = new Date(recent.updatedAt).getTime();
      return {
        ...recent,
        title: recent.message,
        updatedAt: `${ms(Date.now() - date, { long: true })} ago`,
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          fullName: user.fullName,
        },
      };
    });
    return recentList;
  }, [dataUserNotifications?.me]);

  const notificationTypes = useMemo(
    () => [
      { label: "Recent", value: "recent", count: recentActivities.length },
      {
        label: "Notifications",
        value: "notifications",
        count: notifications.length,
      },
    ],
    [notifications.length, recentActivities.length]
  );

  const userNavigation: {
    name: string;
    href: string;
    icon: string;
  }[] = [
    { name: "Your Profile", href: "/settings/account", icon: "bi bi-person" },
    { name: "Logout", href: "/logout", icon: "bi bi-box-arrow-left" },
  ];

  return (
    <>
      {/* <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8"> */}
      {/* <div className="relative z-10 flex h-20 flex-shrink-0 bg-white sm:h-24 lg:h-32 lg:border-none lg:bg-transparent"> */}
      <div className="relative z-10 flex flex-shrink-0 bg-white pb-6 pt-9 lg:border-none lg:bg-transparent">
        <button
          type="button"
          className="-ml-2 mr-2 border-0 px-2 text-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 sm:px-4 lg:hidden"
          onClick={() => handleMenu(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="flex flex-1 items-center justify-between">
          <div className="flex flex-col">
            <div>
              {breadcrumbs && (
                <nav className="hidden sm:flex" aria-label="Breadcrumb">
                  <ol className="flex items-center space-x-3">
                    {breadcrumbs.map(
                      (
                        breadcrumb: {
                          name: string;
                          href: string;
                        },
                        index: number
                      ) => (
                        <li key={index}>
                          <div className="flex items-center">
                            {index !== 0 && (
                              <ChevronRightIcon
                                className="h-4 w-4 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                            {breadcrumb.href ? (
                              <Link
                                to={breadcrumb.href}
                                className={classNames(
                                  index !== 0 ? "ml-2" : "",
                                  "text-sm font-normal text-gray-500 hover:text-gray-700"
                                )}
                              >
                                {breadcrumb.name}
                              </Link>
                            ) : (
                              <span
                                className={classNames(
                                  index !== 0 ? "ml-2" : "",
                                  "text-sm font-medium text-gray-500"
                                )}
                              >
                                {breadcrumb.name}
                              </span>
                            )}
                          </div>
                        </li>
                      )
                    )}
                  </ol>
                </nav>
              )}
            </div>

            <h1 className="text-md font-bold capitalize leading-7 text-gray-900 sm:mt-2 sm:truncate sm:text-lg sm:tracking-tight md:text-xl lg:text-2xl">
              {heading}
            </h1>
            {/* <SearchBar /> */}
          </div>

          <div className="ml-4 flex items-center self-start md:ml-6">
            <Menu as="div" className="relative ml-3">
              <div className="lg:rounded-md lg:bg-transparent lg:p-2 lg:hover:bg-gray-50">
                <Menu.Button className="h-8 w-8 rounded-md bg-gray-200 text-center leading-8 text-gray-700 hover:text-gray-500  focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50">
                  <span
                    className="bi bi-bell text-md h-6 w-6"
                    aria-hidden="true"
                  />
                  <span className="sr-only">View notifications</span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 mt-4 w-64 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white py-3 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-2 md:w-96"
                  static
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="16px"
                    height="16px"
                    viewBox="0 0 110.9 96"
                    enableBackground="new 0 0 110.9 96"
                    className="absolute -top-3 right-0 -z-0"
                    fill="#ffffff"
                    style={{
                      filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                    }}
                  >
                    <polygon points="110.9,0 0,96 110.9,96 " />
                  </svg>
                  <header className="flex items-center justify-between px-4 pb-2 pt-1">
                    <span className="text-sm font-medium">Notifications</span>
                    <button className="cursor-default appearance-none p-0 text-sm text-gray-500 opacity-30 transition-colors">
                      Mark all as read
                    </button>
                  </header>

                  <Tab.Group>
                    <Tab.List className="flex border-b border-solid border-gray-200 px-4">
                      {notificationTypes.map((item) => (
                        <Tab
                          key={item.value}
                          className={({ selected }) =>
                            classNames(
                              "group mr-5 flex border-b-2 border-solid border-transparent py-1.5 text-sm text-gray-500 transition-all",
                              selected
                                ? "border-black font-medium text-black"
                                : "hover:text-gray-700"
                            )
                          }
                        >
                          {({ selected }) => (
                            <>
                              {item.label}
                              <span
                                className={classNames(
                                  "ml-3 hidden rounded-full border border-solid px-1.5 py-0.5 text-xs font-medium md:inline-block",
                                  selected
                                    ? "border-black bg-black font-medium text-white"
                                    : "border-gray-200 text-gray-500"
                                )}
                              >
                                {item.count}
                              </span>
                            </>
                          )}
                        </Tab>
                      ))}
                      <div className="relative -bottom-1 ml-auto">
                        <Link
                          to="/settings/notification"
                          className="text-md text-gray-500 hover:text-black"
                        >
                          <span className="sr-only">
                            Open notification settings
                          </span>
                          <span className="bi bi-gear"></span>
                        </Link>
                      </div>
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                      <Tab.Panel
                        className={classNames("rounded-xl bg-white px-2")}
                      >
                        <ul>
                          {recentActivities.map((post) => (
                            <li
                              key={post.id}
                              className="relative flex items-start rounded-md px-3 py-3 hover:bg-gray-100"
                            >
                              <div className="mr-3">
                                <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary-700 md:h-10 md:w-10">
                                  <span className="text-sm font-medium leading-none text-white md:text-base">
                                    {`${post?.user?.firstName?.charAt(
                                      0
                                    )}${post?.user?.lastName?.charAt(0)}`}
                                  </span>
                                </span>
                              </div>
                              <div>
                                <h3 className="text-sm font-medium leading-5">
                                  {post.title}
                                </h3>
                                <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                  <li>{post.updatedAt}</li>
                                  <li>&middot;</li>
                                  <li>{post.user.fullName}</li>
                                </ul>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Tab.Panel>
                      <Tab.Panel
                        className={classNames("rounded-xl bg-white px-2")}
                      >
                        <ul>
                          {notifications.map((post) => (
                            <li
                              key={post.id}
                              className="relative flex items-start rounded-md px-3 py-3 hover:bg-gray-100"
                            >
                              <div className="mr-3">
                                <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary-700 md:h-10 md:w-10">
                                  <span className="text-sm font-medium leading-none text-white md:text-base">
                                    {`${post?.user?.firstName?.charAt(
                                      0
                                    )}${post?.user?.lastName?.charAt(0)}`}
                                  </span>
                                </span>
                              </div>
                              <div>
                                <h3 className="text-sm font-medium leading-5">
                                  {post.title}
                                </h3>
                                <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                  <li>{post.updatedAt}</li>
                                  <li>&middot;</li>
                                  <li>{post.user.fullName}</li>
                                </ul>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </Menu.Items>
              </Transition>
            </Menu>

            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50  lg:rounded-md lg:bg-transparent lg:p-2 lg:hover:bg-gray-50">
                  <Avatar
                    firstName={currentUser?.firstName}
                    lastName={currentUser?.lastName}
                    className="h-8 w-8"
                  />
                  <span className="sr-only">Open user menu </span>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 mt-4 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-2 md:w-44 md:px-2.5 md:py-3"
                  static
                >
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="16px"
                    height="16px"
                    viewBox="0 0 110.9 96"
                    enableBackground="new 0 0 110.9 96"
                    className="absolute -top-3 right-0 -z-0"
                    fill="#ffffff"
                    style={{
                      filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                    }}
                  >
                    <polygon points="110.9,0 0,96 110.9,96 " />
                  </svg>
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.href}>
                      {({ active }) => (
                        <div className="py-0.5">
                          <Link
                            to={item.href}
                            className={classNames(
                              active
                                ? "bg-primary-700/10 text-primary-700"
                                : "",
                              "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2"
                            )}
                          >
                            <span
                              className={classNames(
                                "mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                                item.icon
                              )}
                              aria-hidden="true"
                            />
                            <span className="flex-1 truncate">{item.name}</span>
                          </Link>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}

type Item = {
  id: number;
  name: string;
  description: string;
  url: string;
  color: string;
  icon: React.ElementType;
};
const items: Item[] = [
  {
    id: 1,
    name: "Text",
    description: "Add freeform text with basic formatting options.",
    url: "#",
    color: "bg-indigo-500",
    icon: PencilIcon,
  },
  // More items...
];

export function SearchBar() {
  const [query, setQuery] = useState("");

  const [open, setOpen] = useState(true);

  const filteredItems =
    query === ""
      ? []
      : items.filter((item: Item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(value) => {
                  // window.location = item.url
                  console.log(value);
                }}
                value={query}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {filteredItems.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-96 scroll-py-3 overflow-y-auto p-3"
                  >
                    {filteredItems.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                          classNames(
                            "flex cursor-default select-none rounded-xl p-3",
                            active ? "bg-gray-100" : ""
                          )
                        }
                      >
                        {({ active }) => (
                          <>
                            <div
                              className={classNames(
                                "flex h-10 w-10 flex-none items-center justify-center rounded-lg",
                                item.color
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4 flex-auto">
                              <p
                                className={classNames(
                                  "text-sm font-medium",
                                  active ? "text-gray-900" : "text-gray-700"
                                )}
                              >
                                {item.name}
                              </p>
                              <p
                                className={classNames(
                                  "text-sm",
                                  active ? "text-gray-700" : "text-gray-500"
                                )}
                              >
                                {item.description}
                              </p>
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== "" && filteredItems.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-medium text-gray-900">
                      No results found
                    </p>
                    <p className="mt-2 text-gray-500">
                      No components found for this search term. Please try
                      again.
                    </p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
