import { SplashScreenProvider } from "../components/core";
import PageProvider from "./GenContext";
import NotifyProvider from "./NotifyContext";

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <SplashScreenProvider>
      <PageProvider>
        <NotifyProvider>{children}</NotifyProvider>
      </PageProvider>
    </SplashScreenProvider>
  );
};

export default Providers;
