import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import {
  EDIT_VEHICLEISSUE,
  GET_VEHICLEISSUE_BY_ID,
  GET_VEHICLEISSUES,
  VehicleIssue,
  VehicleIssueForm,
} from "../../../../../graphql/fleets/vehicles/pages/issues";
import Form from "../../components/IssuesForm";

const IssuesUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  let { issueId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchVehicleIssue: VehicleIssue;
  }>(GET_VEHICLEISSUE_BY_ID, { variables: { id: issueId } });

  const issue = useMemo<VehicleIssue | undefined>(
    () => data?.fetchVehicleIssue,
    [data]
  );

  const [updateVehicleIssue] = useMutation(EDIT_VEHICLEISSUE, {
    refetchQueries: [{ query: GET_VEHICLEISSUES }],
    awaitRefetchQueries: true,
  });
  const handleUpdate = (
    values: VehicleIssueForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!issueId) return;
    updateVehicleIssue({
      variables: {
        id: issueId,
        vehicleId: values.vehicleId,
        reportedDate: values.reportedDate,
        summary: values.summary,
        issueType: values.issueType,
        reportedById: values.reportedById,
        assignedToId: values.assignedToId,
        priority: values.priority,
        overDueDate: values.overDueDate,
        description: values.description,
        status: values.status,
        statusNotes: values.statusNotes,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleIssueUpdate) {
          toast.success(`Vehicle issue updated successfully`);
          return navigate("/fleets/vehicles/issues");
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={IssuesUpdateResource.name}
        heading={IssuesUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: IssuesUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_issues_update")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_issues_update")}
              </p>
            </div>
          </div>

          {loading || !issue ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                vehicleId: issue.vehicleId ?? null,
                reportedDate: issue?.reportedDate ?? null,
                summary: issue?.summary ?? "",
                issueType: issue?.issueType ?? "",
                primaryMeter: issue?.primaryMeter ?? null,
                reportedById: issue?.reportedById ?? null,
                assignedToId: issue?.assignedToId ?? null,
                priority: issue?.priority ?? null,
                overDueDate: issue?.overDueDate ?? null,
                description: issue?.description ?? "",
                status: issue?.status ?? 0,
                statusNotes: issue?.statusNotes ?? "",
              }}
              onSubmit={handleUpdate}
              submitLabel={t("text_update")}
              onCancel={() => {
                navigate(`/fleets/vehicles/issues/${issueId}`);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default IssuesUpdate;
export const IssuesUpdateResource: ResourceProps = {
  name: "Edit Issue Entry",
  description: "Update an existing issue",
  access: ["update-vehicles"],
  path: "/issues/:issueId/edit",
};
