import { gql } from "@apollo/client";
import { DateTime } from "luxon";

import { UserModel } from "../../../../../modules/auth/@types/auth";
import { classNames } from "../../../../../utils";
import { VehicleBase } from "../..";

export const SERVICEREMAINDER_FRAGMENT = gql`
  fragment VehicleServiceRemainderFragment on VehicleServiceRemainder {
    id
    notification
    remainderType
    timeDueSoonThreshold
    timeDueSoonThresholdUnit
    timeInterval
    timeIntervalUnit
    lastCompletionDate
    nextDueDate
    vehicle {
      id
      name
      vehicleImageUrl
      settings {
        fuelUnit
        measurementUnit
        primaryMeter
      }
      status {
        id
        name
        color
      }
      operator {
        id
        name
      }
      make {
        id
        name
      }
      model {
        id
        name
      }
      year
      currentMeter
    }
    vehicleServiceTask {
      id
      name
    }
    watchers {
      id
      fullName
      firstName
      lastName
      email
      phoneNumber
    }
    status
    updatedAt
    createdAt
  }
`;

export const GET_SERVICEREMAINDERS = gql`
  ${SERVICEREMAINDER_FRAGMENT}
  query FetchServiceRemainders {
    fetchServiceRemainders {
      ...VehicleServiceRemainderFragment
    }
  }
`;

export const GET_SERVICEREMAINDER_BY_VEHICLE_ID = gql`
  ${SERVICEREMAINDER_FRAGMENT}
  query FetchServiceRemaindersByVehicle($vehicleId: ID!) {
    fetchServiceRemaindersByVehicle(vehicleId: $vehicleId) {
      ...VehicleServiceRemainderFragment
    }
  }
`;

export const GET_SERVICEREMAINDER_BY_ID = gql`
  ${SERVICEREMAINDER_FRAGMENT}
  query FetchServiceRemainder($id: ID!) {
    fetchServiceRemainder(id: $id) {
      ...VehicleServiceRemainderFragment
    }
  }
`;

export const ADD_SERVICEREMAINDER = gql`
  ${SERVICEREMAINDER_FRAGMENT}
  mutation VehicleServiceRemainderCreate(
    $vehicleId: Int!
    $vehicleServiceTaskId: Int!
    $remainderType: Boolean!
    $timeInterval: Int
    $timeIntervalUnit: Int
    $timeDueSoonThreshold: Int
    $timeDueSoonThresholdUnit: Int
    $notification: Boolean!
    $status: Int!
    $watchers: [Int!]
  ) {
    vehicleServiceRemainderCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          vehicleServiceTaskId: $vehicleServiceTaskId
          remainderType: $remainderType
          timeInterval: $timeInterval
          timeIntervalUnit: $timeIntervalUnit
          timeDueSoonThreshold: $timeDueSoonThreshold
          timeDueSoonThresholdUnit: $timeDueSoonThresholdUnit
          notification: $notification
          status: $status
          watchers: $watchers
        }
      }
    ) {
      vehicleServiceRemainder {
        ...VehicleServiceRemainderFragment
      }
    }
  }
`;

export const EDIT_SERVICEREMAINDER = gql`
  ${SERVICEREMAINDER_FRAGMENT}
  mutation VehicleServiceRemainderUpdate(
    $id: ID!
    $vehicleId: Int!
    $vehicleServiceTaskId: Int!
    $remainderType: Boolean!
    $timeInterval: Int
    $timeIntervalUnit: Int
    $timeDueSoonThreshold: Int
    $timeDueSoonThresholdUnit: Int
    $notification: Boolean!
    $status: Int!
    $watchers: [Int!]
  ) {
    vehicleServiceRemainderUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          vehicleServiceTaskId: $vehicleServiceTaskId
          remainderType: $remainderType
          timeInterval: $timeInterval
          timeIntervalUnit: $timeIntervalUnit
          timeDueSoonThreshold: $timeDueSoonThreshold
          timeDueSoonThresholdUnit: $timeDueSoonThresholdUnit
          notification: $notification
          status: $status
          watchers: $watchers
        }
      }
    ) {
      vehicleServiceRemainder {
        ...VehicleServiceRemainderFragment
      }
    }
  }
`;

export const REMOVE_SERVICEREMAINDER = gql`
  ${SERVICEREMAINDER_FRAGMENT}
  mutation VehicleServiceRemainderDelete($id: ID!) {
    vehicleServiceRemainderDelete(input: { id: $id }) {
      vehicleServiceRemainder {
        ...VehicleServiceRemainderFragment
      }
    }
  }
`;

export enum ServiceRemainderStatus {
  UPCOMING,
  RESOLVED,
  OVERDUE,
  DUE_SOON,
  SNOOZED,
}
export enum TimeIntervalUnit {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}
export type VehicleServiceReminder = {
  id: string;
  notification: boolean;
  remainderType: boolean;
  timeDueSoonThreshold: number | null;
  timeDueSoonThresholdUnit: TimeIntervalUnit | null;
  timeInterval: number | null;
  timeIntervalUnit: TimeIntervalUnit | null;
  lastCompletionDate: string;
  nextDueDate: string;
  vehicle: Omit<VehicleBase, "id"> & {
    id: string;
    settings: {
      fuelUnit: string;
      measurementUnit: string;
      primaryMeter: string;
    };
    currentMeter: number | null;
  };
  vehicleServiceTask: {
    id: string;
    name: string;
    nameWithCode: string;
  };
  watchers: Pick<
    UserModel,
    "id" | "fullName" | "firstName" | "lastName" | "email" | "phoneNumber"
  >[];
  status: ServiceRemainderStatus | null;
  createdAt: string;
  updatedAt: string;
};

export type VehicleServiceReminderForm = Omit<
  VehicleServiceReminder,
  | "id"
  | "vehicle"
  | "vehicleServiceTask"
  | "watchers"
  | "createdAt"
  | "updatedAt"
  | "lastCompletionDate"
  | "nextDueDate"
> & {
  id?: string;
  vehicleId: number | null;
  vehicleServiceTaskId: number | null;
  watchers: number[];
};

export const renderStatus = (status: ServiceRemainderStatus | null) => {
  switch (status) {
    case ServiceRemainderStatus.UPCOMING:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-gray-200")}
            aria-hidden="true"
          />
          Open
        </span>
      );
    case ServiceRemainderStatus.SNOOZED:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-red-800")}
            aria-hidden="true"
          />
          Archived
        </span>
      );
    case ServiceRemainderStatus.OVERDUE:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-yellow-800")}
            aria-hidden="true"
          />
          Draft
        </span>
      );
    case ServiceRemainderStatus.DUE_SOON:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-green-500")}
            aria-hidden="true"
          />
          Packing
        </span>
      );
    case ServiceRemainderStatus.RESOLVED:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-green-800")}
            aria-hidden="true"
          />
          Completed
        </span>
      );
    default:
      return null;
  }
};

export const renderStatusText = (status: ServiceRemainderStatus | null) => {
  switch (status) {
    case ServiceRemainderStatus.SNOOZED:
      return "Snoozed";
    case ServiceRemainderStatus.OVERDUE:
      return "Overdue";
    case ServiceRemainderStatus.UPCOMING:
      return "Upcoming";
    case ServiceRemainderStatus.DUE_SOON:
      return "Due Soon";
    case ServiceRemainderStatus.RESOLVED:
      return "Resolved";
    default:
      return "Unknown";
  }
};

export const renderTimeIntervalUnit = (
  status: TimeIntervalUnit | null,
  type: boolean = false,
  lower: boolean = false
) => {
  let result = "";
  switch (status) {
    case TimeIntervalUnit.DAY:
      result = type ? "Days" : "Day";
      return lower ? result.toLowerCase() : result;
    case TimeIntervalUnit.WEEK:
      result = type ? "Weeks" : "Week";
      return lower ? result.toLowerCase() : result;
    case TimeIntervalUnit.MONTH:
      result = type ? "Months" : "Month";
      return lower ? result.toLowerCase() : result;
    case TimeIntervalUnit.YEAR:
      result = type ? "Years" : "Year";
      return lower ? result.toLowerCase() : result;
    default:
      result = type ? "Unknowns" : "Unknown";
      return lower ? result.toLowerCase() : result;
  }
};

export const getDateByTimeInterval = (
  interval: number | null,
  unit: TimeIntervalUnit | null = 0
): DateTime => {
  let date = DateTime.now();
  if (interval && unit !== null) {
    date = DateTime.now().plus({
      [renderTimeIntervalUnit(unit, true)]: interval,
    });
    return date;
  }
  return date;
};
