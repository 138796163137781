import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  EDIT_CATEGORY,
  GET_CATEGORIES,
  GET_CATEGORY_BY_ID,
} from "../../../graphql/inventory/categories";
import { PreviewProduct } from "../../../graphql/inventory/products";
import Form from "./components/Form";

const CategoryUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { categoryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type Category = {
    id: string;
    name: string;
    handle: string;
    description: string;
    parent: Category;
    products: PreviewProduct[];
    imageUrl: string;
    categoryType: number;
    conditionType: number;
    conditions: any[];
    createdAt: string;
    status: boolean;
  };

  const { data, loading, error } = useQuery(GET_CATEGORY_BY_ID, {
    variables: {
      id: categoryId,
    },
  });
  const category = useMemo<Category>(() => data?.fetchCategory, [data]);

  const [updateCategory] = useMutation(EDIT_CATEGORY, {
    refetchQueries: [
      {
        query: GET_CATEGORIES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateCategory({
      variables: {
        id: categoryId,
        name: values.name,
        handle: values.handle,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        productsIds: values.productsIds ? values.productsIds : [],
        imageUrl: values.imageUrl,
        categoryType: values.categoryType,
        conditionType: values.conditionType,
        conditions: values.conditions,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.categoryUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Category updated successfully",
          });
          return navigate(`/inventory/categories`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Category update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Category update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={CategoryUpdateResource.name}
        heading={CategoryUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Categories",
            href: "/inventory/categories",
          },
          {
            name: CategoryUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_category")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_category")}
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="md:col-span-2">
              {loading || !category ? (
                <Waiting />
              ) : (
                <Form
                  initialValues={{
                    name: category.name ?? "",
                    handle: category.handle ?? "",
                    description: category.description ?? "",
                    parent: category.parent
                      ? {
                          label: category.parent.name,
                          value: category.parent.id,
                        }
                      : null,
                    productsIds: category.products
                      ? category.products?.map((p) => Number(p.id))
                      : [],
                    imageUrl: category.imageUrl ?? "",
                    categoryType: category.categoryType ?? 0,
                    conditionType: category.conditionType ?? 0,
                    conditions: category.conditions
                      ? category.conditions.map((c) => {
                          const {
                            conditionTitle,
                            conditionSymbol,
                            conditionValue,
                          } = c;
                          return {
                            conditionTitle,
                            conditionSymbol,
                            conditionValue,
                          };
                        })
                      : [],
                    status: category.status ? true : false,
                  }}
                  products={category.products}
                  onSubmit={handleSubmit}
                  actionLabel={t("text_update")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryUpdate;
export const CategoryUpdateResource: ResourceProps = {
  name: "Edit Category",
  description: "Update an existing category",
  access: ["update-categories"],
  path: "categories/:categoryId",
};
