import { useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Logo } from "../../../animations";
import { PopoverPosition } from "../../../components/appearance";
import { FieldCustomer, FieldDriver } from "../../../components/form";
import {
  DeliveryAllocation,
  GET_DELIVERY_ALLOCATION,
} from "../../../graphql/fleets/delivery-allocations";
import { renderVerificationStatus } from "../../../graphql/pickpack/packing";
import { renderStatus } from "../../../graphql/sales/orders";
import { formatDate } from "../../../utils";

export default function DeliveryAllocationsDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      return navigate(`/fleets/delivery-allocations`, { replace: true });
    }, 400);
  }, [navigate]);

  const { data, loading } = useQuery<{
    fetchDeliveryAllocation: DeliveryAllocation | undefined;
  }>(GET_DELIVERY_ALLOCATION, {
    variables: { id },
  });

  const deliveryAllocation = useMemo(() => {
    setOpen(true);
    return data?.fetchDeliveryAllocation;
  }, [data]);

  console.log("deliveryAllocation", deliveryAllocation);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="mb-4 border-b border-gray-200 p-6">
                      <div className="flex items-start justify-between">
                        <div className="mr-3">
                          <Dialog.Title className="text-xl font-medium text-gray-900">
                            Delivery Allocation - #
                            {deliveryAllocation?.orderNumber}
                          </Dialog.Title>
                        </div>
                        <div className="flex items-center space-x-4">
                          <button
                            type="button"
                            className="appearance-none rounded-md text-primary-900 transition-colors hover:text-primary-900 focus:outline-none focus-visible:border-primary-800 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={handleClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {loading ? (
                      <div className="flex h-full flex-col items-center justify-center">
                        <Logo />
                      </div>
                    ) : deliveryAllocation ? (
                      <div>
                        <div className="flex-1 divide-y divide-gray-200 overflow-y-auto px-6 pb-6">
                          <div className="relative mt-4 space-y-4 md:space-y-6">
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_order_number")}</span>
                              <span>{deliveryAllocation.orderNumber}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_operator")}</span>
                              {deliveryAllocation.operator ? (
                                <div>
                                  <FieldDriver
                                    title={t("text_operator")}
                                    value={parseInt(
                                      deliveryAllocation.operator.id
                                    )}
                                    isPreview
                                    position={PopoverPosition.RIGHT}
                                  />
                                </div>
                              ) : (
                                <span>-</span>
                              )}
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_customer")}</span>
                              <div>
                                <FieldCustomer
                                  title={t("text_customer")}
                                  value={parseInt(
                                    deliveryAllocation.customerId
                                  )}
                                  isPreview
                                  position={PopoverPosition.RIGHT}
                                />
                              </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_purchase_number")}</span>
                              <span>
                                {deliveryAllocation.purchaseNumber ?? "-"}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_packing_number")}</span>
                              <span>{deliveryAllocation.packingNo}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_packing_date")}</span>
                              <span>
                                {formatDate(deliveryAllocation.packingDate)}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_delivery_run")}</span>
                              <span>{deliveryAllocation.deliveryRun}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_delivery_date")}</span>
                              <span>
                                {formatDate(deliveryAllocation.deliveryDate)}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_loading_dock")}</span>
                              <span>{deliveryAllocation.loadingDock}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_packing_verification")}</span>
                              <span>
                                {renderVerificationStatus(
                                  deliveryAllocation.packingVerificationStatus
                                )}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_box_count")}</span>
                              <span>{deliveryAllocation.boxCount}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_notes")}</span>
                              <span>{deliveryAllocation.notes}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_status")}</span>
                              <span>
                                {renderStatus(deliveryAllocation.status)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export const DeliveryAllocationsDetailResource: ResourceProps = {
  name: "Delivery Allocations Detail",
  description: "Delivery Allocations Detail Page",
  access: ["update-deliveryAllocations"],
  path: "/:id",
};
