import { gql } from "@apollo/client";

export const VEHICLEBODYSUBTYPE_FRAGMENT = gql`
  fragment VehicleBodySubtypeFragment on VehicleBodySubtype {
    id
    name
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLEBODYSUBTYPES = gql`
  ${VEHICLEBODYSUBTYPE_FRAGMENT}
  query FetchVehicleBodySubtypes {
    fetchVehicleBodySubtypes {
      ...VehicleBodySubtypeFragment
    }
  }
`;

// export const GET_VEHICLEBODYSUBTYPE_BY_ID = gql`
//   ${VEHICLEBODYSUBTYPE_FRAGMENT}
//   query FetchVehicleBodySubtype($id: ID!) {
//     fetchVehicleBodySubtype(id: $id) {
//       ...VehicleBodySubtypeFragment
//     }
//   }
// `;

// export const REMOVE_VEHICLEBODYSUBTYPE = gql`
//   ${VEHICLEBODYSUBTYPE_FRAGMENT}
//   mutation VehicleBodySubtypeDelete($id: ID!) {
//     vehicleBodySubtypeDelete(input: { id: $id }) {
//       vehicleBodySubtype {
//         ...VehicleBodySubtypeFragment
//       }
//     }
//   }
// `;

export const ADD_VEHICLEBODYSUBTYPE = gql`
  ${VEHICLEBODYSUBTYPE_FRAGMENT}
  mutation VehicleBodySubtypeCreate($name: String!) {
    vehicleBodySubtypeCreate(input: { params: { name: $name } }) {
      vehicleBodySubtype {
        ...VehicleBodySubtypeFragment
      }
    }
  }
`;

// export const EDIT_VEHICLEBODYSUBTYPE = gql`
//   ${VEHICLEBODYSUBTYPE_FRAGMENT}
//   mutation VehicleBodySubtypeUpdate($id: ID!, $name: String!) {
//     vehicleBodySubtypeUpdate(input: { id: $id, params: { name: $name } }) {
//       vehicleBodySubtype {
//         ...VehicleBodySubtypeFragment
//       }
//     }
//   }
// `;

export type VehicleBodySubtype = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};
