import { useMutation, useQuery } from "@apollo/client/react";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ButtonDots, itemClasses } from "../../../../../components/appearance";
import { ErrorFallback, Head } from "../../../../../components/core";
import { Button, FieldFleetVendor } from "../../../../../components/form";
import { LimitBy, TableCursor } from "../../../../../components/table";
import { VehicleBasic } from "../../../../../graphql/fleets/vehicles";
import {
  GET_SERVICEENTRY_BY_VEHICLE_ID,
  REMOVE_SERVICEENTRY,
  VehicleServiceEntry,
} from "../../../../../graphql/fleets/vehicles/pages/service-entry";
import { renderPrimaryMeter } from "../../../../../graphql/fleets/vehicles/pages/settings";
import { AlertModal, AlertType, useAlert } from "../../../../../hooks/useAlert";
import { classNames, formatCurrency, formatDate } from "../../../../../utils";
import { AuthShield } from "../../../../auth/core";

const ServiceEntryList = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);

  const { data, loading, error, refetch } = useQuery<{
    fetchServiceEntriesByVehicle: VehicleServiceEntry[] | null;
  }>(GET_SERVICEENTRY_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });
  const serviceEntries = useMemo<VehicleServiceEntry[]>(() => {
    const updatedServiceEntry = data?.fetchServiceEntriesByVehicle?.length
      ? data?.fetchServiceEntriesByVehicle.map((item) => {
          return {
            ...item,
            completionDate: formatDate(item.completionDate),
            createdAt: formatDate(item.createdAt),
          };
        })
      : [];
    return updatedServiceEntry;
  }, [data]);

  const [deleteVehicleServiceEntry] = useMutation(REMOVE_SERVICEENTRY);
  const handleDelete = async (id: string) => {
    deleteVehicleServiceEntry({
      variables: {
        id: Number(id),
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleServiceEntryDelete) {
          await refetch();
          toast.success(`Vehicle service entry deleted successfully`);
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<VehicleServiceEntry, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "completionDate",
        header: "Completion Date",
        enableHiding: false,
      },
      {
        accessorKey: "odometer",
        header: "Odometer",
        enableHiding: false,
        cell: (props) => {
          const meterUnit = renderPrimaryMeter(
            props.row.original.vehicle.settings?.length
              ? props.row.original.vehicle.settings[0].primaryMeter
              : null
          );
          return (
            <span className="whitespace-nowrap">
              {props.row.original.odometer} {meterUnit}
            </span>
          );
        },
      },
      {
        id: "serviceTasks",
        header: "Service Tasks",
        enableHiding: false,
        cell: (props) =>
          props.row.original.lineItems.map((item) => item.notes).join(", "),
      },
      {
        id: "issues",
        header: "Issues",
        enableHiding: false,
        cell: (props) =>
          props.row.original.serviceIssues
            .map((item) => item.summary)
            .join(", "),
      },
      {
        accessorKey: "fleetVendor.name",
        header: "Vendor",
        enableHiding: false,
        cell: (props) => (
          <FieldFleetVendor
            title="Vendor"
            value={
              props.row.original.fleetVendor
                ? parseInt(props.row.original.fleetVendor.id)
                : null
            }
            isPreview
          />
        ),
      },
      {
        accessorKey: "total",
        header: "Total",
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {formatCurrency(props.row.original.total ?? 0)}
          </span>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <ButtonDots>
            <AuthShield access={["read-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/${vehicleId}/service-entry/${props.row.original.id}`}
              >
                <div className="flex items-center">
                  <EyeIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    View
                    <span className="sr-only">
                      , {props.row.original.completionDate}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["update-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/${vehicleId}/service-entry/${props.row.original.id}/edit`}
              >
                <div className="flex items-center">
                  <PencilIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Edit
                    <span className="sr-only">
                      , {props.row.original.completionDate}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original?.completionDate ?? "",
                  });
                }}
              >
                <div className="flex items-center">
                  <TrashIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Delete
                    <span className="sr-only">
                      , {props.row.original.completionDate}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
          </ButtonDots>
        ),
      },
    ],
    [vehicleId]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={ServiceEntryListResource.name}
        heading={ServiceEntryListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ServiceEntryListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_service_entry")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_service_entry")}
              </p>
            </div>
            <div className="ml-auto flex items-center space-x-2 pr-3">
              <AuthShield access={["create-vehicles"]}>
                <Button
                  type="link"
                  href={`/fleets/vehicles/${vehicleId}/service-entry/add`}
                >
                  <PlusCircleIcon className="mr-2 h-5 w-5" />
                  Add service entry
                </Button>
              </AuthShield>
              <Button
                variant="icon"
                onClick={handleRefetch}
                disabled={loading}
                className="flex h-9 items-center justify-center px-2 text-blue-700"
              >
                <ArrowPathIcon
                  aria-hidden="true"
                  className={classNames(
                    "h-5 w-5",
                    refetching ? "animate-spin" : ""
                  )}
                />
                <span className="sr-only">Refresh service entry list</span>
              </Button>
            </div>
          </div>

          <TableCursor
            data={serviceEntries}
            columns={columns}
            loading={loading}
            totalRows={serviceEntries.length}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            enableRowSelection={false}
            getCoreRowModel={getCoreRowModel()}
            enableGlobalFilter={true}
            fallbackTitle="No service entries found"
            fallbackText="You can add a new service entry by clicking on add service entry button."
          />
        </div>
      </div>
    </>
  );
};

export default ServiceEntryList;
export const ServiceEntryListResource: ResourceProps = {
  name: "Service Entry",
  description: "List of all vehicle service entries",
  access: ["update-vehicles"],
  path: "/service-entry",
  icon: "bi bi-person",
};
