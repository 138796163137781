import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import { VehicleBasic } from "../../../../../graphql/fleets/vehicles";
import {
  EDIT_SERVICEREMAINDER,
  GET_SERVICEREMAINDER_BY_ID,
  GET_SERVICEREMAINDER_BY_VEHICLE_ID,
  VehicleServiceReminder,
  VehicleServiceReminderForm,
} from "../../../../../graphql/fleets/vehicles/pages/service-reminder";
import Form from "../../components/ServiceReminderForm";

const ServiceReminderUpdate = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId, serviceReminderId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchServiceRemainder: VehicleServiceReminder;
  }>(GET_SERVICEREMAINDER_BY_ID, {
    variables: {
      id: serviceReminderId,
    },
  });

  const serviceReminder = useMemo<VehicleServiceReminder | undefined>(
    () => data?.fetchServiceRemainder,
    [data]
  );

  const [updateVehicleServiceReminder] = useMutation(EDIT_SERVICEREMAINDER, {
    refetchQueries: [
      { query: GET_SERVICEREMAINDER_BY_VEHICLE_ID, variables: { vehicleId } },
    ],
    awaitRefetchQueries: true,
  });
  const handleUpdate = (
    values: VehicleServiceReminderForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!serviceReminderId || !vehicleId) return;
    updateVehicleServiceReminder({
      variables: {
        id: serviceReminderId,
        vehicleId: parseInt(vehicleId),
        vehicleServiceTaskId: values.vehicleServiceTaskId,
        remainderType: values.remainderType,
        timeInterval: values.timeInterval,
        timeIntervalUnit: values.timeIntervalUnit,
        timeDueSoonThreshold: values.timeDueSoonThreshold,
        timeDueSoonThresholdUnit: values.timeDueSoonThresholdUnit,
        notification: values.notification,
        status: values.status,
        watchers: values.watchers,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleServiceRemainderUpdate) {
          toast.success(`Vehicle service reminder updated successfully`);
          return navigate(`/fleets/vehicles/${vehicleId}/service-reminder`);
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={ServiceReminderUpdateResource.name}
        heading={ServiceReminderUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ServiceReminderUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-4xl">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_service_reminder_update")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_service_reminder_update")}
              </p>
            </div>
          </div>

          {loading || !serviceReminder ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                vehicleId: Number(vehicleId),
                vehicleServiceTaskId: serviceReminder?.vehicleServiceTask
                  ? parseInt(serviceReminder.vehicleServiceTask.id)
                  : null,
                remainderType: serviceReminder?.remainderType ?? false,
                timeInterval: serviceReminder?.timeInterval ?? null,
                timeIntervalUnit: serviceReminder?.timeIntervalUnit ?? null,
                timeDueSoonThreshold:
                  serviceReminder?.timeDueSoonThreshold ?? null,
                timeDueSoonThresholdUnit:
                  serviceReminder?.timeDueSoonThresholdUnit ?? null,
                notification: serviceReminder?.notification ?? false,
                status: serviceReminder?.status ?? 0,
                watchers: serviceReminder?.watchers.length
                  ? serviceReminder?.watchers.map((item) => parseInt(item.id))
                  : [],
              }}
              onSubmit={handleUpdate}
              submitLabel={t("text_update")}
              onCancel={() => {
                navigate(
                  `/fleets/vehicles/${vehicleId}/service-reminder/${serviceReminderId}`
                );
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceReminderUpdate;
export const ServiceReminderUpdateResource: ResourceProps = {
  name: "Edit Service Reminder",
  description: "Update an existing service reminder",
  access: ["update-vehicles"],
  path: "/service-reminder/:serviceReminderId/edit",
};
