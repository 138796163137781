import { useMutation } from "@apollo/client/react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../../contexts/NotifyContext";
import {
  ADD_SERVICEENTRY,
  GET_SERVICEENTRIES,
  VehicleServiceEntryForm,
} from "../../../../../graphql/fleets/vehicles/pages/service-entry";
import Form from "../../components/ServiceEntryForm";

const ServiceEntryCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();

  const [createVehicleServiceEntry] = useMutation(ADD_SERVICEENTRY, {
    refetchQueries: [{ query: GET_SERVICEENTRIES }],
  });
  const handleCreate = (
    values: VehicleServiceEntryForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createVehicleServiceEntry({
      variables: {
        vehicleId: values.vehicleId,
        repairPriority: values.repairPriority,
        odometer: values.odometer,
        completionDate: values.completionDate,
        startDate: values.startDate,
        referenceNo: values.referenceNo,
        fleetVendorId: values.fleetVendorId,
        totalLaborPrice: values.totalLaborPrice,
        totalPartsPrice: values.totalPartsPrice,
        subtotal: values.subtotal,
        total: values.total,
        discount: values.discount,
        discountType: values.discountType,
        tax: values.tax,
        generalNotes: values.generalNotes,
        comments: values.comments,
        issues: values.issues,
        lineItems: values.lineItems,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleServiceEntryCreate) {
          return navigate(
            `/fleets/vehicles/service-entries/${data.vehicleServiceEntryCreate.vehicleServiceEntry.id}`
          );
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Service entry create failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={ServiceEntryCreateResource.name}
        heading={ServiceEntryCreateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ServiceEntryCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_service_entry_create")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_service_entry_create")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              vehicleId: null,
              repairPriority: null,
              odometer: null,
              completionDate: null,
              startDate: null,
              referenceNo: "",
              fleetVendorId: null,
              totalLaborPrice: null,
              totalPartsPrice: null,
              subtotal: null,
              total: null,
              discount: null,
              discountType: 0,
              tax: null,
              generalNotes: "",
              comments: "",
              issues: [],
              lineItems: [],
            }}
            issues={[]}
            serviceRemainders={[]}
            onSubmit={handleCreate}
            submitLabel={t("text_create")}
            onCancel={() => {
              navigate("/fleets/vehicles/service-entries");
            }}
            vehicleBasic={null}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceEntryCreate;
export const ServiceEntryCreateResource: ResourceProps = {
  name: "Service Entry",
  description: "Update an existing vehicle service entries",
  access: ["update-vehicles"],
  path: "/service-entries/add",
  icon: "bi bi-person",
};
