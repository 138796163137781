import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import momentTz from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import {
  Field,
  FieldImage,
  FieldPlaces,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { LocationType } from "../../../../graphql/inventory/location-types";
import { classNames, toNestedOptions } from "../../../../utils";

type Location = {
  id?: number;
  name: string;
  parent: SingleValue<OptionProps>;
  type: SingleValue<OptionProps>;
  status: boolean;
  branch: string;
  description: string;
  address: string;
  returnAddress: string;
  imageUrl: string;
  latitude: string;
  longitude: string;
  timezone: SingleValue<OptionProps>;
};

export default function Form({
  initialValues,
  onSubmit,
  header,
}: {
  initialValues: Location;
  onSubmit: (values: any, actions: any) => void;
  header?: JSX.Element;
}) {
  const { t } = useTranslation();

  const LocationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    description: Yup.string(),
    parent: Yup.object().nullable(),
    imageUrl: Yup.string().nullable(),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: LocationSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      {header}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
        <div className="md:col-span-8">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_location_name")}
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  touched={touched.name}
                  errors={errors.name}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <Field
                  title={t("text_branch")}
                  name="branch"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.branch}
                  touched={touched.branch}
                  errors={errors.branch}
                />
              </div>
              <div className="col-span-12 sm:col-span-12">
                <Field
                  title={t("text_description")}
                  name="description"
                  type="textarea"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  touched={touched.description}
                  errors={errors.description}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <FieldPlaces
                  title={t("text_address")}
                  name="address"
                  onUpdate={(value, address) => {
                    formik.setFieldValue("address", value);
                    const { latitude = "", longitude = "" } = address;
                    formik.setFieldValue("latitude", latitude.toString());
                    formik.setFieldValue("longitude", longitude.toString());
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  touched={touched.address}
                  errors={errors.address}
                />
              </div>
              <div className="col-span-12 sm:col-span-6">
                <FieldPlaces
                  title={t("text_return_address")}
                  name="returnAddress"
                  onUpdate={(value) => {
                    formik.setFieldValue("returnAddress", value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.returnAddress}
                  touched={touched.returnAddress}
                  errors={errors.returnAddress}
                />
              </div>
              <div className="col-span-12 sm:col-span-12">
                <FieldImage
                  title={t("text_image")}
                  onChange={(value) => {
                    formik.setFieldValue("imageUrl", value);
                  }}
                  value={formik.values.imageUrl}
                  touched={touched.imageUrl}
                  errors={errors.imageUrl}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 space-y-4">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <fieldset>
              <legend className="text-sm font-medium text-gray-900">
                {t("text_status")}
              </legend>
              <Switch.Group
                as="div"
                className="mt-1.5 inline-flex items-center"
              >
                <Switch
                  checked={formik.values.status}
                  onChange={() => {
                    formik.setFieldValue("status", !formik.values.status);
                  }}
                  id="status"
                  className={classNames(
                    formik.values.status ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      formik.values.status ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label
                  passive
                  htmlFor="status"
                  className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                >
                  {formik.values.status ? t("text_active") : t("text_inactive")}
                </Switch.Label>
              </Switch.Group>
            </fieldset>
          </div>

          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <fieldset>
              <label className="block text-sm font-medium text-gray-900">
                {t("text_location_parent")}
              </label>
              <FieldLocationParent
                value={formik.values.parent}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("parent", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.parent && formik.errors.parent
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.parent && formik.errors.parent ? (
                <p className="mt-2 text-sm text-red-600" id="roles-errors">
                  {formik.errors.parent.toString()}
                </p>
              ) : null}
            </fieldset>

            <fieldset className="mt-4">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_location_type")}
              </label>
              <FieldLocationType
                value={formik.values.type}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("type", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.type && formik.errors.type
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              <p className="mt-1 text-xs text-gray-500">
                Type to create new location type
              </p>
              {formik.touched.type && formik.errors.type ? (
                <p className="mt-2 text-sm text-red-600" id="roles-errors">
                  {formik.errors.type.toString()}
                </p>
              ) : null}
            </fieldset>

            <fieldset className="mt-4">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_timezone")}
              </label>
              <FieldTimezone
                value={formik.values.timezone}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("timezone", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.timezone && formik.errors.timezone
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.timezone && formik.errors.timezone ? (
                <p className="mt-2 text-sm text-red-600" id="roles-errors">
                  {formik.errors.timezone.toString()}
                </p>
              ) : null}
            </fieldset>
          </div>
        </div>
      </div>
    </form>
  );
}

const FETCH_LOCATIONS = gql`
  query FetchBinLocations($status: Boolean) {
    fetchBinLocations(status: $status) {
      id
      name
      parent {
        id
        name
      }
      status
    }
  }
`;

export function FieldLocationParent({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  let { locationId } = useParams();
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery(FETCH_LOCATIONS, {
    variables: {
      status: true,
    },
  });

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchBinLocations) {
      const fetchBinLocations: OptionsData[] = data.fetchBinLocations;
      const optionsWithDepth: Array<OptionsData & { depth?: number }> =
        toNestedOptions(fetchBinLocations, locationId);
      const updatedOptions = optionsWithDepth?.map((p) => {
        return {
          label: `${Array.from(
            Array(typeof p.depth === "number" ? p.depth : 0).keys()
          )
            .map((_) => "-")
            .join("")} ${p.name}`,
          value: p.id,
        };
      });
      return updatedOptions;
    }
    return [];
  }, [data, locationId]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

export function FieldTimezone({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const options = useMemo(() => {
    return momentTz.tz.zonesForCountry("AU").map((zone: string) => {
      return {
        label: zone,
        value: zone,
      };
    });
  }, []);

  useEffect(() => {
    if (value) {
      setValues(value);
    } else {
      if (options) {
        const guessValue = momentTz.tz.guess(true);
        const guess = options.find((o) => o.value === guessValue);
        setValues(
          guess
            ? guess
            : {
                label: "",
                value: "",
              }
        );
      }
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}

const FETCH_LOCATION_TYPES = gql`
  query FetchBinLocationTypes {
    fetchBinLocationTypes {
      id
      name
      createdAt
      status
    }
  }
`;

export function FieldLocationType({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { data, loading, refetch } = useQuery<{
    fetchBinLocationTypes: LocationType[];
  }>(FETCH_LOCATION_TYPES, {
    variables: {
      status: true,
    },
  });

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchBinLocationTypes) {
      return data.fetchBinLocationTypes?.map((p: LocationType) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data?.fetchBinLocationTypes]);

  const CREATE_TYPE = gql`
    mutation BinLocationTypeCreate(
      $name: String!
      $description: String
      $status: Boolean!
    ) {
      binLocationTypeCreate(
        input: {
          params: { name: $name, description: $description, status: $status }
        }
      ) {
        type {
          id
          name
          createdAt
          status
        }
      }
    }
  `;
  const [createLocationType] = useMutation(CREATE_TYPE, {
    refetchQueries: [{ query: FETCH_LOCATION_TYPES }],
  });

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    createLocationType({
      variables: {
        name: inputValue,
        status: true,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.binLocationTypeCreate) {
          const newValue = data.binLocationTypeCreate.type;
          const newOption = {
            label: newValue.name,
            value: newValue.id,
          };
          onChange(newOption);
          refetch();
        } else {
          return navigate("/error/401", {
            state: {
              message:
                "Locations create location type is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChange}
        onCreateOption={handleCreate}
        options={options}
        value={value}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
