import { gql } from "@apollo/client";

export const FLEETVENDOR_FRAGMENT = gql`
  fragment FleetVendorFragment on FleetVendor {
    address
    classification
    contactName
    contactPhoneNumber
    createdAt
    email
    id
    latitude
    longitude
    name
    notes
    phoneNumber
    profileImageUrl
    status
    updatedAt
    webAddress
  }
`;

export const GET_FLEETVENDORS = gql`
  ${FLEETVENDOR_FRAGMENT}
  query FetchFleetVendors {
    fetchFleetVendors {
      ...FleetVendorFragment
    }
  }
`;

export const REMOVE_FLEETVENDOR = gql`
  ${FLEETVENDOR_FRAGMENT}
  mutation FleetVendorDelete($id: ID!) {
    fleetVendorDelete(input: { id: $id }) {
      fleetVendor {
        ...FleetVendorFragment
      }
    }
  }
`;

export const ADD_FLEETVENDOR = gql`
  ${FLEETVENDOR_FRAGMENT}
  mutation FleetVendorCreate(
    $name: String!
    $email: String!
    $phoneNumber: String
    $webAddress: String
    $address: String
    $latitude: String
    $longitude: String
    $notes: String
    $contactName: String
    $contactPhoneNumber: String
    $classification: [Int!]
    $profileImageUrl: String
    $status: Boolean!
  ) {
    fleetVendorCreate(
      input: {
        params: {
          name: $name
          email: $email
          phoneNumber: $phoneNumber
          webAddress: $webAddress
          address: $address
          latitude: $latitude
          longitude: $longitude
          notes: $notes
          contactName: $contactName
          contactPhoneNumber: $contactPhoneNumber
          classification: $classification
          profileImageUrl: $profileImageUrl
          status: $status
        }
      }
    ) {
      fleetVendor {
        ...FleetVendorFragment
      }
    }
  }
`;

export const EDIT_FLEETVENDOR = gql`
  ${FLEETVENDOR_FRAGMENT}
  mutation FleetVendorUpdate(
    $id: ID!
    $name: String!
    $email: String!
    $phoneNumber: String
    $webAddress: String
    $address: String
    $latitude: String
    $longitude: String
    $notes: String
    $contactName: String
    $contactPhoneNumber: String
    $classification: [Int!]
    $profileImageUrl: String
    $status: Boolean!
  ) {
    fleetVendorUpdate(
      input: {
        id: $id
        params: {
          name: $name
          email: $email
          phoneNumber: $phoneNumber
          webAddress: $webAddress
          address: $address
          latitude: $latitude
          longitude: $longitude
          notes: $notes
          contactName: $contactName
          contactPhoneNumber: $contactPhoneNumber
          classification: $classification
          profileImageUrl: $profileImageUrl
          status: $status
        }
      }
    ) {
      fleetVendor {
        ...FleetVendorFragment
      }
    }
  }
`;

export const GET_FLEETVENDOR_BY_ID = gql`
  ${FLEETVENDOR_FRAGMENT}
  query FetchFleetVendor($id: ID!) {
    fetchFleetVendor(id: $id) {
      ...FleetVendorFragment
    }
  }
`;

export enum FleetVendorClassification {
  FUEL,
  VEHICLE,
  SERVICE,
}

export type FleetVendor = {
  id: string;
  name: string;
  address: string;
  classification: FleetVendorClassification[];
  contactName: string;
  contactPhoneNumber: string;
  profileImageUrl: string;
  createdAt: string;
  email: string;
  latitude: string;
  longitude: string;
  notes: string;
  phoneNumber: string;
  status: boolean;
  updatedAt: string;
  webAddress: string;
};

export type FleetVendorForm = Omit<
  FleetVendor,
  "id" | "createdAt" | "updatedAt"
> & {
  id?: string;
};

export const renderClassification = (
  classification: FleetVendorClassification
) => {
  switch (classification) {
    case FleetVendorClassification.FUEL:
      return "Fuel";
    case FleetVendorClassification.VEHICLE:
      return "Vehicle";
    case FleetVendorClassification.SERVICE:
      return "Service";
    default:
      return "Unknown";
  }
};
