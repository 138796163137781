import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { MultiValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  ADD_VEHICLE_EXPENSETYPE,
  GET_VEHICLE_EXPENSETYPES,
  VehicleExpenseType,
} from "../../graphql/fleets/vehicles/sections/expense-type";
import { ButtonPopover, PopoverPosition } from "../appearance";
import { selectStyles, SelectWrapper } from ".";

type FieldVehicleExpenseTypeProps = {
  title: string;
  value: number | null;
  onChange?: (newValue: number | null) => void;
  className?: string;
  isPreview?: boolean;
  position?: PopoverPosition;
};
export function FieldVehicleExpenseType({
  title,
  value: initialValue,
  onChange,
  className = "",
  isPreview = false,
  position = PopoverPosition.RIGHT,
}: FieldVehicleExpenseTypeProps) {
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);
  const [createVehicleExpenseType] = useMutation(ADD_VEHICLE_EXPENSETYPE);

  const { data, loading, refetch } = useQuery<{
    fetchVehicleExpenseTypes: VehicleExpenseType[];
  }>(GET_VEHICLE_EXPENSETYPES);

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchVehicleExpenseTypes) {
      return data.fetchVehicleExpenseTypes?.map((p: VehicleExpenseType) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === String(initialValue)) ?? null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [initialValue, options]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    typeof onChange === "function" &&
      onChange(newValue ? Number(newValue.value) : null);
  };

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    createVehicleExpenseType({
      variables: {
        name: inputValue,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.vehicleExpenseTypeCreate) {
          const newValue = data.vehicleExpenseTypeCreate.vehicleExpenseType;
          const newOption = {
            label: newValue.name,
            value: newValue.id,
          };
          refetch();
          onChangeHandler(newOption);
        } else {
          toast.error("Error fetching vehicle model");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  if (isPreview) {
    if (value) {
      const expenseType = data?.fetchVehicleExpenseTypes?.find(
        (ex: VehicleExpenseType) => ex.id === value?.value
      );
      if (expenseType) {
        return (
          <ButtonPopover
            title={expenseType.name}
            child={<div>{expenseType.name}</div>}
            position={position}
          >
            <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
              <h3 className="mb-1 font-medium">{expenseType.name}</h3>
            </div>
          </ButtonPopover>
        );
      }
    }
    return <Fragment>-</Fragment>;
  }

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading || loading}
        isLoading={isLoading || loading}
        onChange={onChangeHandler}
        onCreateOption={handleCreate}
        options={options}
        value={value}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
