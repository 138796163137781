import { Combobox } from "@headlessui/react";
import { GlobeAsiaAustraliaIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

import { SpinnerInline } from "../../animations";
import {
  Address,
  classNames,
  getAddressFromGeocoder,
  loadScript,
} from "../../utils";

interface FieldProps extends React.ComponentProps<typeof Combobox.Input> {
  title: string;
  name: string;
  value: string;
  touched?: boolean | undefined;
  errors?: string | undefined;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  label?: boolean;
  onUpdate: (value: string, address: Address) => void;
}

export function FieldPlaces(props: FieldProps) {
  const {
    title,
    name,
    value: initialValue,
    onUpdate,
    onBlur,
    readOnly = false,
    disabled = false,
    touched,
    errors,
    label = true,
    id,
    className,
    placeholder,
    ...rest
  } = props;

  const {
    ready = true,
    value,
    suggestions: { status, data },
    setValue,
    // init,
  } = usePlacesAutocomplete({
    initOnMount: true,
    defaultValue: initialValue,
    requestOptions: {
      componentRestrictions: { country: "au" },
    },
  });

  // useEffect(() => {
  //   loadScript(
  //     `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=initMap`,
  //     init,
  //     "google-maps-script"
  //   );
  // }, [init]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setValue(value);
  };

  const handleSelect = async (val: string) => {
    setValue(val, false);
    const results = await getGeocode({ address: val });
    const addressObject = getAddressFromGeocoder(results);
    const { street, postcode, state, suburb, country, latitude, longitude } =
      addressObject;
    onUpdate(val, addressObject);
  };

  return (
    <>
      <Combobox
        as="div"
        value={value}
        onChange={handleSelect}
        disabled={disabled}
      >
        <Combobox.Label
          htmlFor={id ? id : name}
          className={`block text-sm font-medium text-gray-900 ${
            label ? "mb-1" : "sr-only"
          }`}
        >
          {title}
        </Combobox.Label>
        <div className="relative">
          <Combobox.Input
            className={classNames(
              "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
              "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
            )}
            onChange={handleInput}
            {...rest}
          />
          <Combobox.Button className="absolute inset-y-1 right-1 flex items-center rounded-r-md bg-white px-2 focus:outline-none">
            {ready ? (
              <GlobeAsiaAustraliaIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <SpinnerInline />
            )}
          </Combobox.Button>
          {data.length > 0 ? (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {status === "OK"
                ? data.map(({ place_id, description }) => (
                    <Combobox.Option
                      key={place_id}
                      value={description}
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                          active ? "bg-primary-600 text-white" : "text-gray-900"
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <span
                          className={classNames(
                            "ml-3 block",
                            selected ? "font-medium" : ""
                          )}
                        >
                          {description}
                        </span>
                      )}
                    </Combobox.Option>
                  ))
                : null}
            </Combobox.Options>
          ) : null}
        </div>
      </Combobox>
    </>
  );
}
