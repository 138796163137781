import { lazy, Suspense } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import {
  LayoutSplashScreen,
  NotAuthorized,
  NotFound,
} from "../../components/core";
import PageLayout from "../../layouts/PageLayout";
import { isAuthorizedForResource, useAuth } from "../auth";
import { DeliveryAllocationsResource } from "./delivery-allocations/List";
import { SettingsResource } from "./settings/Settings";
import { VehicleAssignmentsResource } from "./vehicle-assignments/List";
import { VehiclesResource } from "./vehicles/Layout";

const DeliveryAllocations = lazy(() => import("./delivery-allocations/List"));
const VehicleAssignments = lazy(() => import("./vehicle-assignments/List"));
const VehiclesPage = lazy(() => import("./vehicles/Layout"));
const Settings = lazy(() => import("./settings/Settings"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
  {
    name: "Fleets",
    href: "/fleets",
  },
];

export function FleetsPage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      children: [
        {
          path: "/",
          element: <Navigate to={VehicleAssignmentsResource.path} />,
        },
        {
          element: <PageLayout />,
          children: [
            {
              path: VehicleAssignmentsResource.path,
              element: isAuthorizedForResource(
                currentRole,
                VehicleAssignmentsResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <VehicleAssignments breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(VehicleAssignmentsResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: DeliveryAllocationsResource.path,
              element: isAuthorizedForResource(
                currentRole,
                DeliveryAllocationsResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <DeliveryAllocations breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(DeliveryAllocationsResource.access.join(", "))
                  }
                />
              ),
            },
          ],
        },

        {
          path: VehiclesResource.path,
          element: isAuthorizedForResource(
            currentRole,
            VehiclesResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <VehiclesPage breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(VehiclesResource.access.join(", "))}
            />
          ),
        },
        {
          path: SettingsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SettingsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Settings breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SettingsResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
