import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { VehicleThumb } from "../../../../components/appearance";
import { FieldFleetVendor } from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  renderFuelFileType,
  renderFuelFlags,
  VehicleFuelEntry,
} from "../../../../graphql/fleets/vehicles/pages/fuel-entry";
import {
  renderFuelUnit,
  renderPrimaryMeter,
} from "../../../../graphql/fleets/vehicles/pages/settings";
import { formatCurrency, formatDate, formatFloat } from "../../../../utils";

type FuelEntryDetailProps = {
  fuelEntry: VehicleFuelEntry;
  fuelEntries: VehicleFuelEntry[];
  vehicleBasic: VehicleBasic | null;
};
export function FuelEntryDetail({
  fuelEntry,
  fuelEntries,
  vehicleBasic,
}: FuelEntryDetailProps) {
  const { t } = useTranslation();

  const {
    fuelUnit,
    meterUnit,
    fuelAmount,
    pricePerUnit,
    total,
    usage,
    fuelEconomy,
    costDistance,
  } = useMemo(() => {
    const fuelUnit = renderFuelUnit(vehicleBasic?.fuelUnit);
    const meterUnit = renderPrimaryMeter(vehicleBasic?.primaryMeter);

    const fuelAmount = fuelEntry.fuelAmount ?? 0;
    const pricePerUnit = fuelEntry.pricePerUnit ?? 0;

    const total = fuelAmount * pricePerUnit;

    const currentOdometer = fuelEntry.odometer ?? 0;
    const previousOdometer = fuelEntry.previousMeterEntry ?? 0;
    const usage = currentOdometer - previousOdometer;

    const fuelEconomy = usage / fuelAmount;
    const costDistance = total / usage;

    return {
      fuelUnit,
      meterUnit,
      fuelAmount,
      pricePerUnit,
      total,
      usage,
      fuelEconomy,
      costDistance,
    };
  }, [fuelEntry, vehicleBasic]);

  const {
    averageFuelAmountDecimal,
    averageFuelAmountPercentage,
    averagePricePerUnitDecimal,
    averagePricePerUnitPercentage,
    averageTotalDecimal,
    averageTotalPercentage,
    averageUsageDecimal,
    averageUsagePercentage,
    averageFuelEconomyDecimal,
    averageFuelEconomyPercentage,
    averageCostDistanceDecimal,
    averageCostDistancePercentage,
  } = useMemo(() => {
    if (fuelEntries.length) {
      const totalFuelAmount = fuelEntries.reduce(
        (acc, cur) => acc + (cur.fuelAmount ?? 0),
        0
      );
      const averageFuelAmount = totalFuelAmount / fuelEntries.length;

      const averageFuelAmountDecimal = fuelAmount - averageFuelAmount;
      const averageFuelAmountPercentage =
        (averageFuelAmountDecimal / averageFuelAmount) * 100;

      const totalPricePerUnit = fuelEntries.reduce(
        (acc, cur) => acc + (cur.pricePerUnit ?? 0),
        0
      );
      const averagePricePerUnit = totalPricePerUnit / fuelEntries.length;

      const averagePricePerUnitDecimal = pricePerUnit - averagePricePerUnit;
      const averagePricePerUnitPercentage =
        (averagePricePerUnitDecimal / averagePricePerUnit) * 100;

      const averageTotal = averageFuelAmount * averagePricePerUnit;
      const averageTotalDecimal = total - averageTotal;
      const averageTotalPercentage = (averageTotalDecimal / averageTotal) * 100;

      const totalUsage = fuelEntries.reduce(
        (acc, cur) =>
          acc + ((cur.odometer ?? 0) - (cur.previousMeterEntry ?? 0)),
        0
      );
      const averageUsage = totalUsage / fuelEntries.length;
      const averageUsageDecimal = usage - averageUsage;
      const averageUsagePercentage = (averageUsageDecimal / averageUsage) * 100;

      const totalFuelEconomy = fuelEntries.reduce((acc, cur) => {
        const fuelAmount = cur.fuelAmount ?? 0;
        const currentOdometer = cur.odometer ?? 0;
        const previousOdometer = cur.previousMeterEntry ?? 0;
        const usage = currentOdometer - previousOdometer;
        const fuelEconomy = usage / fuelAmount;
        return acc + fuelEconomy;
      }, 0);
      const averageFuelEconomy = totalFuelEconomy / fuelEntries.length;
      const averageFuelEconomyDecimal = fuelEconomy - averageFuelEconomy;
      const averageFuelEconomyPercentage =
        (averageFuelEconomyDecimal / averageFuelEconomy) * 100;

      const totalCostDistance = fuelEntries.reduce((acc, cur) => {
        const fuelAmount = cur.fuelAmount ?? 0;
        const pricePerUnit = cur.pricePerUnit ?? 0;
        const total = fuelAmount * pricePerUnit;
        const currentOdometer = cur.odometer ?? 0;
        const previousOdometer = cur.previousMeterEntry ?? 0;
        const usage = currentOdometer - previousOdometer;
        const costDistance = total / usage;
        return acc + costDistance;
      }, 0);
      const averageCostDistance = totalCostDistance / fuelEntries.length;
      const averageCostDistanceDecimal = costDistance - averageCostDistance;
      const averageCostDistancePercentage =
        (averageCostDistanceDecimal / averageCostDistance) * 100;

      return {
        averageFuelAmountDecimal,
        averageFuelAmountPercentage,
        averagePricePerUnitDecimal,
        averagePricePerUnitPercentage,
        averageTotalDecimal,
        averageTotalPercentage,
        averageUsageDecimal,
        averageUsagePercentage,
        averageFuelEconomyDecimal,
        averageFuelEconomyPercentage,
        averageCostDistanceDecimal,
        averageCostDistancePercentage,
      };
    }
    return {
      averageFuelAmountDecimal: 0,
      averageFuelAmountPercentage: 0,
      averagePricePerUnitDecimal: 0,
      averagePricePerUnitPercentage: 0,
      averageTotalDecimal: 0,
      averageTotalPercentage: 0,
      averageUsageDecimal: 0,
      averageUsagePercentage: 0,
      averageFuelEconomyDecimal: 0,
      averageFuelEconomyPercentage: 0,
      averageCostDistanceDecimal: 0,
      averageCostDistancePercentage: 0,
    };
  }, [
    costDistance,
    fuelAmount,
    fuelEconomy,
    fuelEntries,
    pricePerUnit,
    total,
    usage,
  ]);

  return (
    <div className="space-y-4">
      <article>
        <h3 className="text-md mb-3 font-medium text-gray-900">Details</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="space-y-4 md:space-y-6 xl:space-y-8">
            {vehicleBasic ? (
              <li className="grid grid-cols-2 gap-4">
                <span>Vehicle:</span>
                <VehicleThumb {...vehicleBasic} />
              </li>
            ) : null}
            <li className="grid grid-cols-2 gap-4">
              <span>Fuel Entry Date:</span>
              <span className="text-gray-500">
                {formatDate(fuelEntry.fuelEntryDate)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Odometer:</span>
              <span className="text-gray-500">
                {fuelEntry.odometer}{" "}
                {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Vendor:</span>
              <FieldFleetVendor
                title={t("text_fleet_vendor")}
                value={fuelEntry.vendor ? parseInt(fuelEntry.vendor.id) : null}
                isPreview
              />
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Fuel Type:</span>
              <span className="text-gray-500">{fuelEntry.fuelType.name}</span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Reference No:</span>
              <span className="text-gray-500">{fuelEntry.referenceNo}</span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Previous Entry:</span>
              <span className="text-gray-500">
                {fuelEntry.previousMeterEntry ? (
                  <Fragment>
                    {fuelEntry.previousMeterEntry}{" "}
                    {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                  </Fragment>
                ) : (
                  "-"
                )}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Comments:</span>
              <span className="text-gray-500">{fuelEntry.comments}</span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Price Per Unit:</span>
              <span className="text-gray-500">
                {formatCurrency(pricePerUnit)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Fuel Flags:</span>
              <span className="text-gray-500">
                {renderFuelFlags(fuelEntry.fuelFlags)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Fuel Amount:</span>
              <span className="text-gray-500">
                {fuelAmount} {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Files:</span>
              <span className="text-gray-500">
                {fuelEntry.files.length
                  ? fuelEntry.files.map((item, index) => (
                      <a
                        key={item.id}
                        href={item.fileUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-700 underline transition-all hover:text-primary-900 hover:no-underline"
                      >
                        Attachment {index + 1} -{" "}
                        {renderFuelFileType(item.fileType)}
                      </a>
                    ))
                  : null}
              </span>
            </li>
          </ul>
        </div>
      </article>
      <article>
        <h3 className="text-md mb-3 font-medium text-gray-900">
          Average Fuel Consumption
        </h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <li>
              <div>Volume:</div>
              <div className="whitespace-nowrap text-gray-900">
                <span className="mr-1 font-medium">{fuelAmount}</span>
                <span className="lowercase text-gray-500">{fuelUnit}</span>
              </div>
              <div className="space-x-1 text-sm text-red-500">
                {averageFuelAmountDecimal > 0 ? (
                  <span className="bi bi-caret-up-fill" />
                ) : (
                  <span className="bi bi-caret-down-fill" />
                )}
                <span>{formatFloat(averageFuelAmountDecimal)}</span>
                <span>
                  {formatFloat(Math.abs(averageFuelAmountPercentage))}%
                </span>
              </div>
            </li>
            <li>
              <div>Fuel Price:</div>
              <div className="whitespace-nowrap text-gray-900">
                <span className="mr-1 font-medium">{pricePerUnit}</span>
                <span className="text-gray-500">/{fuelUnit}</span>
              </div>
              <div className="space-x-1 text-sm text-red-500">
                {averagePricePerUnitDecimal > 0 ? (
                  <span className="bi bi-caret-up-fill" />
                ) : (
                  <span className="bi bi-caret-down-fill" />
                )}
                <span className="mr-1 font-medium">
                  {formatCurrency(formatFloat(averagePricePerUnitDecimal))}
                </span>
                <span>
                  {formatFloat(Math.abs(averagePricePerUnitPercentage))}%
                </span>
              </div>
            </li>
            <li>
              <div>Total:</div>
              <div className="whitespace-nowrap text-gray-900">
                <span className="mr-1 font-medium">
                  {formatCurrency(formatFloat(total))}
                </span>
              </div>
              <div className="space-x-1 text-sm text-red-500">
                {averageTotalDecimal > 0 ? (
                  <span className="bi bi-caret-up-fill" />
                ) : (
                  <span className="bi bi-caret-down-fill" />
                )}
                <span className="mr-1 font-medium">
                  {formatCurrency(formatFloat(averageTotalDecimal))}
                </span>
                <span>{formatFloat(Math.abs(averageTotalPercentage))}%</span>
              </div>
            </li>
            <li>
              <div>Usage:</div>
              <div className="whitespace-nowrap text-gray-900">
                <span className="mr-1 font-medium">{usage}</span>
                <span className="text-gray-500">{meterUnit}</span>
              </div>
              <div className="space-x-1 text-sm text-red-500">
                {averageUsageDecimal > 0 ? (
                  <span className="bi bi-caret-up-fill" />
                ) : (
                  <span className="bi bi-caret-down-fill" />
                )}
                <span className="mr-1 font-medium">
                  {formatFloat(averageUsageDecimal)}
                </span>
                <span>{formatFloat(Math.abs(averageUsagePercentage))}%</span>
              </div>
            </li>
            <li>
              <div>Fuel Economy:</div>
              <div className="whitespace-nowrap text-gray-900">
                <span className="mr-1 font-medium">
                  {formatFloat(fuelEconomy)}
                </span>
                <span className="text-gray-500">
                  {meterUnit}/{fuelUnit}
                </span>
              </div>
              <div className="space-x-1 text-sm text-red-500">
                {averageFuelEconomyDecimal > 0 ? (
                  <span className="bi bi-caret-up-fill" />
                ) : (
                  <span className="bi bi-caret-down-fill" />
                )}
                <span className="mr-1 font-medium">
                  {formatFloat(averageFuelEconomyDecimal)}
                </span>
                <span>
                  {formatFloat(Math.abs(averageFuelEconomyPercentage))}%
                </span>
              </div>
            </li>
            <li>
              <div>Cost:</div>
              <div className="whitespace-nowrap text-gray-900">
                <span className="mr-1 font-medium">
                  {formatCurrency(formatFloat(costDistance))}
                </span>
                <span className="text-gray-500">/{meterUnit}</span>
              </div>
              <div className="space-x-1 text-sm text-red-500">
                {averageCostDistanceDecimal > 0 ? (
                  <span className="bi bi-caret-up-fill" />
                ) : (
                  <span className="bi bi-caret-down-fill" />
                )}
                <span className="mr-1 font-medium">
                  {formatCurrency(formatFloat(averageCostDistanceDecimal))}
                </span>
                <span>
                  {formatFloat(Math.abs(averageCostDistancePercentage))}%
                </span>
              </div>
            </li>
          </ul>
        </div>
      </article>
    </div>
  );
}
