import { Listbox, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useEffect, useState } from "react";

import { VehicleServiceEntryDiscountType } from "../../graphql/fleets/vehicles/pages/service-entry";
import { classNames } from "../../utils";

type DiscountType = {
  label: string;
  symbol: string;
  value: number;
  disabled: boolean;
};

const discountTypes: DiscountType[] = [
  { label: "Percentage", symbol: "%", value: 0, disabled: false },
  { label: "Fixed", symbol: "$", value: 1, disabled: false },
];

type SelectProps = React.ComponentProps<typeof Menu>;
type SelectOptionalProps = Omit<SelectProps, "onChange" | "value">;
type FieldDiscountTypeProps = SelectOptionalProps & {
  value: number | null;
  onChange: (newValue: number | null) => void;
};

export function FieldDiscountType({
  value: initialValue,
  onChange,
  ...props
}: FieldDiscountTypeProps) {
  const [value, setValue] = useState<DiscountType | null>(discountTypes[0]);

  useEffect(() => {
    if (initialValue === null) return setValue(null);
    const found = discountTypes.find((p) => p.value === initialValue) ?? null;
    setValue(found);
  }, [initialValue]);

  const handleChange = (newValue: DiscountType | null) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : null);
  };
  return (
    <Listbox as="div" value={value} onChange={handleChange} {...props}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Open discount type options
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="flex items-center">
              <span className="mr-1">{value ? value.symbol : "-"}</span>
              <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-20 mt-5 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-3 md:w-44 md:px-2.5 md:py-3">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="16px"
                  height="16px"
                  viewBox="0 0 110.9 96"
                  enableBackground="new 0 0 110.9 96"
                  className="absolute -top-3 right-0 -z-0"
                  fill="#ffffff"
                  style={{
                    filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                  }}
                >
                  <polygon points="110.9,0 0,96 110.9,96 " />
                </svg>
                {discountTypes.map((item) => (
                  <Listbox.Option
                    key={item.label}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-primary-700/10 text-primary-700" : "",
                        "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2"
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            item.value ===
                              VehicleServiceEntryDiscountType.PERCENTAGE
                              ? "bg-primary-700"
                              : item.value ===
                                VehicleServiceEntryDiscountType.FIXED
                              ? "bg-violet-700"
                              : "bg-gray-300",
                            "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                          )}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            selected ? "text-gray-900" : "",
                            "ml-3 block truncate"
                          )}
                        >
                          {item.label}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
