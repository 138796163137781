import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  EDIT_ROLE,
  GET_ROLE_BY_ID,
  GET_ROLES,
} from "../../../graphql/user-management/roles";
import Form from "./components/Form";

const RoleUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { roleId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type Permissions = {
    id: string;
    permissionName: string;
    permissionDisplayName: string;
    permissionDescription: string;
    createdAt: string;
    status: string;
  };

  type Role = {
    id: string;
    roleName: string;
    roleDescription: string;
    permissions: Permissions[];
    createdAt: string;
    status: boolean;
  };

  const { data, loading, error } = useQuery<{
    fetchRole: Role;
  }>(GET_ROLE_BY_ID, {
    variables: {
      id: roleId,
    },
  });
  const role = useMemo<Role | undefined>(
    () => data?.fetchRole,
    [data?.fetchRole]
  );

  const [updateRole] = useMutation(EDIT_ROLE, {
    refetchQueries: [{ query: GET_ROLES }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateRole({
      variables: {
        id: roleId,
        roleName: values.roleName,
        roleDescription: values.roleDescription,
        permissions: values.permissions,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.roleUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            message: t("Role updated successfully"),
          });
          return navigate("/user-management/roles");
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: t("Role update failed"),
            message: t("Something went wrong, please try again later"),
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: t("Role update failed"),
          message: t(error.message),
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Roles"
        heading="Roles"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Roles",
            href: "/user-management/roles",
          },
          {
            name: RoleUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_update_role")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_update_role")}
            </p>
          </div>
        </div>

        {loading || !role ? (
          <Waiting />
        ) : (
          <div className="mt-6 flex flex-col md:mt-8">
            <div className="md:col-span-2">
              <Form
                initialValues={{
                  roleName: role.roleName ?? "",
                  roleDescription: role.roleDescription ?? "",
                  permissions:
                    role.permissions.map((p) => parseInt(p.id)) ?? [],
                  status: role.status ?? true,
                }}
                onSubmit={handleSubmit}
                actionLabel={t("text_update")}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RoleUpdate;
export const RoleUpdateResource: ResourceProps = {
  name: "Edit Role",
  description: "Update an existing role",
  access: ["update-roles"],
  path: "roles/:roleId",
};
