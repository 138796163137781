import { useMutation } from "@apollo/client/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { GET_WAREHOUSE_PICKERS } from "../../../graphql/user-management/assign-zone-pickers";
import { ADD_USER, GET_USERS } from "../../../graphql/user-management/users";
import { AlertType, useAlert } from "../../../hooks/useAlert";
import Form from "./components/Form";

type RoleProps = {
  readonly label: string;
  readonly value: string;
};

const UserCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type InfoProps = {
    name: string;
  };
  const [alert, setAlert] = useState<InfoProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Invitation sent successfully",
    message: (
      <p className="text-sm text-gray-500">
        An invitation has been sent to{" "}
        <b className="text-gray-900">{alert?.name}</b>. Please confirm with them
        to complete the sign up process.
      </p>
    ),
    type: AlertType.SUCCESS,
    delay: 10000,
    onDismiss: () => {
      setAlert(null);
      return navigate("/user-management/users");
    },
  });

  const [createUser] = useMutation(ADD_USER, {
    refetchQueries: [{ query: GET_USERS }, { query: GET_WAREHOUSE_PICKERS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createUser({
      variables: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        userName: values.userName,
        roles: values.roles.map((role: RoleProps) => parseInt(role.value)),
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.userCreate) {
          setAlert({
            name: values.email,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "User creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "User creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create User"
        heading="Users"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Users",
            href: "/user-management/users",
          },
          {
            name: UserCreateResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-3">
          <div className="flex flex-col">
            <div className="md:col-span-2">
              <Form
                initialValues={{
                  firstName: "",
                  lastName: "",
                  userName: "",
                  email: "",
                  phoneNumber: "",
                  roles: [],
                  status: 0,
                }}
                onSubmit={handleSubmit}
                actionLabel={t("text_create")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCreate;
export const UserCreateResource: ResourceProps = {
  name: "Create User",
  description: "Create a new user",
  access: ["create-users"],
  path: "users/add",
};
