import { gql } from "@apollo/client";

export const VEHICLEGROUP_FRAGMENT = gql`
  fragment VehicleGroupFragment on VehicleGroup {
    id
    name
    default
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLEGROUPS = gql`
  ${VEHICLEGROUP_FRAGMENT}
  query FetchVehicleGroups {
    fetchVehicleGroups {
      ...VehicleGroupFragment
    }
  }
`;

export const GET_VEHICLEGROUP_BY_ID = gql`
  ${VEHICLEGROUP_FRAGMENT}
  query FetchVehicleGroup($id: ID!) {
    fetchVehicleGroup(id: $id) {
      ...VehicleGroupFragment
    }
  }
`;

export const REMOVE_VEHICLEGROUP = gql`
  ${VEHICLEGROUP_FRAGMENT}
  mutation VehicleGroupDelete($id: ID!) {
    vehicleGroupDelete(input: { id: $id }) {
      vehicleGroup {
        ...VehicleGroupFragment
      }
    }
  }
`;

export const ADD_VEHICLEGROUP = gql`
  ${VEHICLEGROUP_FRAGMENT}
  mutation VehicleGroupCreate($name: String!, $default: Boolean!) {
    vehicleGroupCreate(input: { params: { name: $name, default: $default } }) {
      vehicleGroup {
        ...VehicleGroupFragment
      }
    }
  }
`;

export const EDIT_VEHICLEGROUP = gql`
  ${VEHICLEGROUP_FRAGMENT}
  mutation VehicleGroupUpdate($id: ID!, $name: String!, $default: Boolean!) {
    vehicleGroupUpdate(
      input: { id: $id, params: { name: $name, default: $default } }
    ) {
      vehicleGroup {
        ...VehicleGroupFragment
      }
    }
  }
`;

export type VehicleGroup = {
  id: string;
  name: string;
  default: boolean;
  createdAt: string;
  updatedAt: string;
};
