import { gql } from "@apollo/client";

export const VEHICLE_EXPENSETYPE_FRAGMENT = gql`
  fragment VehicleExpenseTypeFragment on VehicleExpense {
    id
    name
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLE_EXPENSETYPES = gql`
  ${VEHICLE_EXPENSETYPE_FRAGMENT}
  query FetchVehicleExpenseTypes {
    fetchVehicleExpenseTypes {
      ...VehicleExpenseTypeFragment
    }
  }
`;

// export const GET_VEHICLE_EXPENSETYPE_BY_ID = gql`
//   ${VEHICLE_EXPENSETYPE_FRAGMENT}
//   query FetchVehicleExpense($id: ID!) {
//     fetchVehicleExpense(id: $id) {
//       ...VehicleExpenseTypeFragment
//     }
//   }
// `;

// export const REMOVE_VEHICLE_EXPENSETYPE = gql`
//   ${VEHICLE_EXPENSETYPE_FRAGMENT}
//   mutation VehicleExpenseDelete($id: ID!) {
//     vehicleExpenseTypeDelete(input: { id: $id }) {
//       vehicleExpenseType {
//         ...VehicleExpenseTypeFragment
//       }
//     }
//   }
// `;

export const ADD_VEHICLE_EXPENSETYPE = gql`
  ${VEHICLE_EXPENSETYPE_FRAGMENT}
  mutation VehicleExpenseCreate($name: String!) {
    vehicleExpenseTypeCreate(input: { params: { name: $name } }) {
      vehicleExpenseType {
        ...VehicleExpenseTypeFragment
      }
    }
  }
`;

// export const EDIT_VEHICLE_EXPENSETYPE = gql`
//   ${VEHICLE_EXPENSETYPE_FRAGMENT}
//   mutation VehicleExpenseUpdate($id: ID!, $name: String!) {
//     vehicleExpenseTypeUpdate(input: { id: $id, params: { name: $name } }) {
//       vehicleExpenseType {
//         ...VehicleExpenseTypeFragment
//       }
//     }
//   }
// `;

export type VehicleExpenseType = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};
