import { useQuery } from "@apollo/client";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SpinnerInline } from "../../animations";
import avatar from "../../assets/placeholder.svg";
import { Driver, GET_DRIVERS } from "../../graphql/fleets/settings/drivers";
import { classNames } from "../../utils";

type Option = Pick<Driver, "name" | "profileImageUrl"> & { id: string | null };
const defaultOption: Option = {
  id: null,
  name: "Unassigned",
  profileImageUrl: avatar,
};
type ListboxButtonProps = React.ComponentProps<typeof Listbox>;
type OptionalProps = Omit<ListboxButtonProps, "onChange" | "value">;
type FieldDriverImageProps = OptionalProps & {
  title: string;
  value: number | null;
  onChange: (newValue: number | null) => void;
  isLabel?: boolean;
  placeholder?: string;
};
export function FieldDriverImage({
  id,
  name,
  title,
  value,
  onChange,
  className = "",
  disabled = false,
  isLabel = true,
  placeholder,
  ...props
}: FieldDriverImageProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Option | null>(null);

  const { data, loading, refetch } = useQuery<{
    fetchDrivers: Driver[];
  }>(GET_DRIVERS);

  const options = useMemo<Option[]>(() => {
    const updatedDrivers = data?.fetchDrivers?.length ? data.fetchDrivers : [];
    const updatedOptions = updatedDrivers.map((p: Driver) => {
      return {
        id: p.id,
        name: p.name,
        profileImageUrl: p.profileImageUrl,
      };
    });
    return updatedOptions;
  }, [data]);

  useEffect(() => {
    if (!value) return setSelected(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.id === value.toString()) ?? null;
      setSelected(option);
      return;
    }
    setSelected(null);
  }, [options, value]);

  const onChangeHandler = (newValue: Option) => {
    onChange(newValue ? (newValue.id ? Number(newValue.id) : null) : null);
  };

  return (
    <Listbox
      value={selected}
      onChange={onChangeHandler}
      disabled={disabled}
      {...props}
    >
      {({ open }) => (
        <>
          <Listbox.Label
            htmlFor={id ? id : name}
            className={`block text-sm font-medium text-gray-900 ${
              isLabel ? "mb-1" : "sr-only"
            }`}
          >
            {title}
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button
              id={id ? id : name}
              className={classNames(
                "relative w-full rounded-md border border-gray-200 bg-white py-1.5 pl-1.5 pr-10 text-left text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-700 sm:text-sm",
                className,
                disabled ? "cursor-default opacity-50" : "cursor-pointer"
              )}
            >
              {loading ? (
                <span className="flex items-center">
                  <SpinnerInline className="text-primary-700" />
                  <span className="ml-3 block truncate">
                    {t("text_loading")}
                  </span>
                </span>
              ) : selected ? (
                <span className="flex items-center">
                  <span className="relative h-8 w-8 overflow-hidden rounded-md">
                    <img
                      src={selected.profileImageUrl || avatar}
                      alt={selected.name}
                      className="h-full w-full object-cover"
                    />
                  </span>
                  <span className="ml-3 block truncate">{selected.name}</span>
                </span>
              ) : (
                <span className="flex items-center">
                  <span className="relative h-8 w-8 overflow-hidden rounded-md">
                    <img
                      src={defaultOption.profileImageUrl}
                      alt={defaultOption.name}
                      className="h-full w-full object-cover"
                    />
                  </span>
                  <span className="ml-3 block truncate">
                    {placeholder ?? defaultOption.name}
                  </span>
                </span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={() => {
                refetch();
              }}
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-primary-700 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={option.profileImageUrl || avatar}
                            alt={option.name}
                            className="h-8 w-8 flex-shrink-0 rounded-full"
                          />
                          <span className={classNames("ml-3 block truncate")}>
                            {option.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-primary-700",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-6 w-6" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
