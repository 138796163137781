import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, FieldTableVehicles } from "../../../../components/form";
import { Vehicle } from "../../../../graphql/fleets/vehicles";

export default function FormDock({
  heading,
  initialValues,
  vehicles: initialVehicles = [],
  onSubmit,
  onCancel,
}: {
  heading: string;
  initialValues: {
    vehicleIds: number[];
  };
  vehicles: Vehicle[];
  onSubmit: (
    values: {
      vehicleIds: number[];
    },
    actions: any
  ) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  useEffect(() => {
    if (initialVehicles.length > 0) {
      setVehicles(initialVehicles);
    }
  }, [initialVehicles]);

  const ResourceSchema = Yup.object().shape({
    vehicleIds: Yup.array().of(Yup.string()).nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ResourceSchema,
    onSubmit: onSubmit,
  });

  return (
    <form
      className="flex h-full flex-col divide-y divide-gray-200"
      onSubmit={formik.handleSubmit}
    >
      <div className="h-0 flex-1">
        <div className="px-4 py-8 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-black">
              {heading}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                onClick={onCancel}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-500">
              Add or remove vehicles from this dock.
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-12">
                  <FieldTableVehicles
                    title={t("text_vehicles")}
                    data={vehicles}
                    value={formik.values.vehicleIds}
                    onChange={(vehicles) => {
                      formik.setFieldValue(
                        "vehicleIds",
                        vehicles.map((vehicle) => parseInt(vehicle.id))
                      );
                      setVehicles(vehicles);
                    }}
                  />
                  {formik.touched.vehicleIds && formik.errors.vehicleIds ? (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="vehicleIds-errors"
                    >
                      {formik.errors.vehicleIds}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
        <Button
          variant="secondary"
          className="w-full justify-center"
          onClick={onCancel}
        >
          {t("text_cancel")}
        </Button>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <Fragment>
              <Spinner />
              {t("text_processing")}
            </Fragment>
          ) : (
            t("text_update")
          )}
        </Button>
      </div>
    </form>
  );
}
