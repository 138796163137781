import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  Dock,
  DockForm,
  EDIT_DOCK,
  GET_DOCK_BY_ID,
  GET_DOCKS,
} from "../../../../graphql/fleets/settings/docks";
import Form from "./components/Form";

const DockUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { dockId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery(GET_DOCK_BY_ID, {
    variables: {
      id: dockId,
    },
  });
  const dock = useMemo<Dock>(() => data?.fetchDock, [data]);

  const [updateDock] = useMutation(EDIT_DOCK, {
    refetchQueries: [
      {
        query: GET_DOCKS,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: DockForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateDock({
      variables: {
        id: dockId,
        name: values.name,
        vehicleIds: values.vehicleIds,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.dockUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Dock updated successfully",
          });
          return navigate(`/fleets/settings/docks`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Dock update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Dock update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={DockUpdateResource.name}
        heading={DockUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Docks",
            href: "/fleets/settings/docks",
          },
          {
            name: DockUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_dock")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_dock")}
              </p>
            </div>
          </div>

          {loading || !dock ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                name: dock.name ?? "",
                vehicleIds: dock.vehicles
                  ? dock.vehicles.map((item) => parseInt(item.id))
                  : [],
                status: dock.status ?? true,
              }}
              vehicles={dock.vehicles}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DockUpdate;
export const DockUpdateResource: ResourceProps = {
  name: "Edit Dock",
  description: "Update an existing location",
  access: ["update-docks"],
  path: "docks/:dockId",
};
