import { gql } from "@apollo/client";

export const GET_PRICINGMETHODS = gql`
  query FetchPricingMethods($status: Boolean) {
    fetchPricingMethods(status: $status) {
      id
      name
      handle
      editable
      sellPriceFormula
      pricingMethodFields {
        fieldType
        fieldKey
        fieldName
        fieldOptions
      }
      createdAt
      status
    }
  }
`;

export const ADD_PRICINGMETHOD = gql`
  mutation PricingMethodCreate(
    $name: String!
    $handle: String!
    $sellPriceFormula: [String!]!
    $pricingMethodFields: [PricingmethodfieldInput!]
    $status: Boolean
  ) {
    pricingMethodCreate(
      input: {
        params: {
          name: $name
          handle: $handle
          sellPriceFormula: $sellPriceFormula
          pricingMethodFields: $pricingMethodFields
          status: $status
        }
      }
    ) {
      pricingMethod {
        id
        name
        handle
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
    }
  }
`;

export const EDIT_PRICINGMETHOD = gql`
  mutation PricingMethodUpdate(
    $id: ID!
    $name: String!
    $handle: String!
    $sellPriceFormula: [String!]!
    $pricingMethodFields: [PricingmethodfieldInput!]
    $status: Boolean
  ) {
    pricingMethodUpdate(
      input: {
        id: $id
        params: {
          name: $name
          handle: $handle
          sellPriceFormula: $sellPriceFormula
          pricingMethodFields: $pricingMethodFields
          status: $status
        }
      }
    ) {
      pricingMethod {
        id
        name
        handle
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
    }
  }
`;

export const REMOVE_PRICINGMETHOD = gql`
  mutation PricingMethodDelete($id: ID!) {
    pricingMethodDelete(input: { id: $id }) {
      pricingMethod {
        id
        name
      }
    }
  }
`;

export interface PricingMethodField {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
}

export interface PricingMethod {
  id: string;
  name: string;
  handle: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[] | null;
  status: boolean;
}
