import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import { RejectedProps } from "../core/types";

export default function Rejected({ file, errors }: RejectedProps) {
  return (
    <li className="relative flex items-center py-2 text-sm md:py-4">
      <span className="relative inline-flex h-10 w-12 items-center justify-center overflow-hidden rounded-md border border-gray-200 bg-white text-gray-600">
        <span className="bi bi-file-earmark-image-fill text-lg"></span>
      </span>
      <span className="ml-2 mr-4 font-medium text-gray-800">{file.path}</span>
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>

      <div className="ml-auto inline-flex items-center">
        <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6" />
      </div>
    </li>
  );
}
