import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import { VehicleIssueStatus } from "../../../../../graphql/fleets/vehicles/pages/issues";
import {
  EDIT_SERVICEENTRY,
  GET_SERVICEENTRIES,
  GET_SERVICEENTRY_BY_ID,
  VehicleServiceEntry,
  VehicleServiceEntryForm,
} from "../../../../../graphql/fleets/vehicles/pages/service-entry";
import { renderTimeIntervalUnit } from "../../../../../graphql/fleets/vehicles/pages/service-reminder";
import Form from "../../components/ServiceEntryForm";

const ServiceEntryUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  let { serviceEntryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchServiceEntry: VehicleServiceEntry;
  }>(GET_SERVICEENTRY_BY_ID, { variables: { id: serviceEntryId } });

  const serviceEntry = useMemo<VehicleServiceEntry | undefined>(
    () => data?.fetchServiceEntry,
    [data]
  );

  const [updateVehicleServiceEntry] = useMutation(EDIT_SERVICEENTRY, {
    refetchQueries: [{ query: GET_SERVICEENTRIES }],
    awaitRefetchQueries: true,
  });
  const handleUpdate = (
    values: VehicleServiceEntryForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!serviceEntryId) return;
    updateVehicleServiceEntry({
      variables: {
        id: serviceEntryId,
        vehicleId: values.vehicleId,
        repairPriority: values.repairPriority,
        odometer: values.odometer,
        completionDate: values.completionDate,
        startDate: values.startDate,
        referenceNo: values.referenceNo,
        fleetVendorId: values.fleetVendorId,
        totalLaborPrice: values.totalLaborPrice,
        totalPartsPrice: values.totalPartsPrice,
        subtotal: values.subtotal,
        total: values.total,
        discount: values.discount,
        discountType: values.discountType,
        tax: values.tax,
        generalNotes: values.generalNotes,
        comments: values.comments,
        issues: values.issues,
        lineItems: values.lineItems,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleServiceEntryUpdate) {
          toast.success(`Vehicle service entry updated successfully`);
          return navigate("/fleets/vehicles/service-entries");
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={ServiceEntryUpdateResource.name}
        heading={ServiceEntryUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ServiceEntryUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_service_entry_update")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_service_entry_update")}
              </p>
            </div>
          </div>

          {loading || !serviceEntry ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                vehicleId: serviceEntry.vehicle
                  ? parseInt(serviceEntry.vehicle.id)
                  : null,
                repairPriority: serviceEntry?.repairPriority ?? null,
                odometer: serviceEntry?.odometer ?? null,
                completionDate: serviceEntry?.completionDate ?? null,
                startDate: serviceEntry?.startDate ?? null,
                referenceNo: serviceEntry?.referenceNo ?? "",
                fleetVendorId: serviceEntry?.fleetVendor
                  ? parseInt(serviceEntry?.fleetVendor.id)
                  : null,
                totalLaborPrice: serviceEntry?.totalLaborPrice ?? null,
                totalPartsPrice: serviceEntry?.totalPartsPrice ?? null,
                subtotal: serviceEntry?.subtotal ?? null,
                total: serviceEntry?.total ?? null,
                discount: serviceEntry?.discount ?? null,
                discountType: serviceEntry?.discountType ?? 0,
                tax: serviceEntry?.tax ?? null,
                generalNotes: serviceEntry?.generalNotes ?? "",
                comments: serviceEntry?.comments ?? "",
                issues: serviceEntry?.serviceIssues?.length
                  ? serviceEntry?.serviceIssues
                      .filter((item) =>
                        item.status === VehicleIssueStatus.OPEN ? item.id : null
                      )
                      .flatMap((item) => parseInt(item.id))
                  : [],
                lineItems:
                  serviceEntry?.lineItems.map((item) => ({
                    vehicleServiceRemainderId: parseInt(
                      item.serviceRemainder.id
                    ),
                    remainderTimeInterval: item.serviceRemainder.timeInterval,
                    remainderTimeIntervalUnit: renderTimeIntervalUnit(
                      item.serviceRemainder.timeIntervalUnit,
                      true,
                      true
                    ),
                    laborPrice: item.laborPrice,
                    partsPrice: item.partsPrice,
                    subtotal: item.subtotal,
                    notes: item.notes,
                  })) ?? [],
              }}
              issues={[]}
              serviceRemainders={[]}
              onSubmit={handleUpdate}
              submitLabel={t("text_update")}
              onCancel={() => {
                navigate(`/fleets/vehicles/service-entries/${serviceEntryId}`);
              }}
              vehicleBasic={null}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceEntryUpdate;
export const ServiceEntryUpdateResource: ResourceProps = {
  name: "Edit Service Entry",
  description: "Update an existing service entry",
  access: ["update-vehicles"],
  path: "/service-entries/:serviceEntryId/edit",
};
