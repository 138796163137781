import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  CREATE_CUSTOMERGROUP,
  GET_CUSTOMERGROUPS,
} from "../../../../graphql/sales/customer-groups";
import Form from "./components/Form";

const CustomerGroupCreate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createCustomerGroup] = useMutation(CREATE_CUSTOMERGROUP, {
    refetchQueries: [
      {
        query: GET_CUSTOMERGROUPS,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCustomerGroup({
      variables: {
        name: values.name,
        description: values.description,
        customerIds: values.customers ? values.customers : [],
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerGroupCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer Group created successfully",
          });
          return navigate(`/sales/settings/customer-groups`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer Group creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer Group creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Customer Group"
        heading="Customer Groups"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customer Groups",
            href: "/sales/settings/customer-groups",
          },
          {
            name: CustomerGroupCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_customer_group")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_customer_group")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              name: "",
              description: "",
              customers: [],
              status: true,
            }}
            initialCustomers={[]}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerGroupCreate;
export const CustomerGroupCreateResource: ResourceProps = {
  name: "Create CustomerGroup",
  description: "Create a new customer group",
  access: ["create-customergroups"],
  path: "customer-groups/add",
};
