import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import { LimitBy, TableCursor } from "../../../../components/table";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import { formatDate } from "../../../../utils";
import { AuthShield } from "../../../auth/core";
import Form from "./components/Form";

type CustomerContactType = {
  id?: string;
  name: string;
  createdAt: string;
  status: boolean;
};

const FETCH_CUSTOMERCONTACTTYPES = gql`
  query FetchCustomerContactTypes {
    fetchCustomerContactTypes {
      id
      name
      createdAt
      status
    }
  }
`;

const CustomerContactTypeList = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery<{
    fetchCustomerContactTypes: CustomerContactType[];
  }>(FETCH_CUSTOMERCONTACTTYPES);

  const customerContactTypes = useMemo(
    () =>
      data?.fetchCustomerContactTypes.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data]
  );

  const [activeCustomerContactType, setActiveCustomerContactType] = useState<
    CustomerContactType | undefined
  >(undefined);
  const [newCustomerContactType, setNewCustomerContactType] = useState<
    boolean | undefined
  >(undefined);

  const UPDATE_CUSTOMERCONTACTTYPE = gql`
    mutation CustomerContactTypeUpdate(
      $id: ID!
      $name: String!
      $status: Boolean!
    ) {
      customerContactTypeUpdate(
        input: { id: $id, params: { name: $name, status: $status } }
      ) {
        contactType {
          id
          name
          createdAt
          status
        }
      }
    }
  `;
  const [updateCustomerContactType] = useMutation(UPDATE_CUSTOMERCONTACTTYPE);
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateCustomerContactType({
      variables: {
        id: activeCustomerContactType?.id,
        name: values.name,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerContactTypeUpdate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer contact type updated",
            message: "Customer contact type updated successfully",
          });
          setActiveCustomerContactType(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer contact type update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer contact type update failed",
          message: error.message,
        });
      });
  };

  const CREATE_CUSTOMERCONTACTTYPE = gql`
    mutation CustomerContactTypeCreate($name: String!, $status: Boolean!) {
      customerContactTypeCreate(
        input: { params: { name: $name, status: $status } }
      ) {
        contactType {
          id
          name
          createdAt
          status
        }
      }
    }
  `;
  const [createCustomerContactType] = useMutation(CREATE_CUSTOMERCONTACTTYPE, {
    refetchQueries: [{ query: FETCH_CUSTOMERCONTACTTYPES }],
  });
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCustomerContactType({
      variables: {
        name: values.name,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerContactTypeCreate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer contact type created successfully",
          });
          setNewCustomerContactType(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer contact type create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer contact type create failed",
          message: error.message,
        });
      });
  };

  const DELETE_CUSTOMERCONTACTTYPE = gql`
    mutation CustomerContactTypeDelete($id: ID!) {
      customerContactTypeDelete(input: { id: $id }) {
        contactType {
          id
          name
        }
      }
    }
  `;
  const [deleteCustomerContactType] = useMutation(DELETE_CUSTOMERCONTACTTYPE, {
    refetchQueries: [{ query: FETCH_CUSTOMERCONTACTTYPES }],
  });
  const handleDelete = async (id: string) => {
    deleteCustomerContactType({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.customerContactTypeDelete) {
          const deletedCustomerContactType =
            data.customerContactTypeDelete?.contactType;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Customer contact type ${deletedCustomerContactType.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer contact type deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer contact type deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<CustomerContactType, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-customers"]}>
              <Button
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
                onClick={() => setActiveCustomerContactType(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-customers"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Customer Contact Types"
        heading="Customer Contact Types"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: CustomerContactTypeListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {CustomerContactTypeListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {CustomerContactTypeListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-customers"]}>
              <Button onClick={() => setNewCustomerContactType(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add customer contact type
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={customerContactTypes}
          columns={columns}
          loading={loading}
          totalRows={customerContactTypes.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No customer contact types found"
          fallbackText="You can add a new customer contact type by clicking on add customer contact type button."
        />
      </div>

      <Transition.Root
        show={activeCustomerContactType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveCustomerContactType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Customer contact type - "
                  initialValues={{
                    id: activeCustomerContactType?.id,
                    name: activeCustomerContactType?.name ?? "",
                    status: activeCustomerContactType?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveCustomerContactType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newCustomerContactType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewCustomerContactType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Customer contact type - "
                  initialValues={{
                    name: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewCustomerContactType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CustomerContactTypeList;
export const CustomerContactTypeListResource: ResourceProps = {
  name: "Customer Contact Types",
  description: "A list of customerContactTypes",
  access: ["read-customers"],
  path: "customer-contact-types",
};
