import { Menu, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  Table,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useVirtual } from "react-virtual";

import { IconFilterClear } from "../../svgs";
import { classNames } from "../../utils";
import { Button } from "../form";
import { Filter } from ".";

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type TableCursorProps<T> = TableOptions<T> & {
  data: T[];
  columns: ColumnDef<T>[];
  loading?: boolean;
  filtering?: boolean;
  pageLimits?: number[];
  pageLimit?: number;
  setPageLimit?: React.Dispatch<React.SetStateAction<number>>;
  totalRows?: number;
  enableAction?: boolean;
  renderAction?: (table: Table<T>) => JSX.Element;
  enableNavigation?: boolean;
  renderNavigation?: (table: Table<T>) => JSX.Element;
  renderSelection?: (table: Table<T>) => JSX.Element;
  renderSubComponent?: (row: Row<T>) => JSX.Element;
  enableHeader?: boolean;
  fallbackTitle?: string;
  fallbackText?: string;
  className?: string;
};

export function TableCursor<T>(props: TableCursorProps<T>) {
  const {
    data,
    columns,
    loading = true,
    filtering = false,
    pageLimits = [10, 20, 30, 50],
    pageLimit = 20,
    setPageLimit = () => {},
    totalRows = 0,
    enableAction = false,
    renderAction = () => <></>,
    enableNavigation = false,
    renderNavigation = () => <></>,
    enableGlobalFilter = false,
    enableRowSelection = false,
    renderSelection = () => <></>,
    renderSubComponent = () => <></>,
    enableHeader = true,
    fallbackTitle,
    fallbackText,
    state,
    className,
    enableHiding,
    ...rest
  } = props;

  const pageSize = useMemo(
    () => Math.ceil(totalRows / pageLimit),
    [pageLimit, totalRows]
  );

  const { columnVisibility: _columnVisibility, ..._state } = state ?? {};
  const [globalFilter, setGlobalFilter] = useState("");

  const [columnVisibility, setColumnVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const flatData = useMemo<T[]>(
    () => (loading ? Array(pageLimit).fill({}) : Array.from(data)),
    [loading, pageLimit, data]
  );

  const tableColumns = useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            enableColumnFilter: false,
            ...column,
            cell: () => <Skeleton />,
          }))
        : columns.map((column) => ({
            enableColumnFilter: false,
            ...column,
          })),
    [loading, columns]
  );

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data: flatData,
    columns: tableColumns,
    state: {
      sorting,
      globalFilter,
      columnVisibility: {
        id: false,
        ..._columnVisibility,
        ...columnVisibility,
      },
      ..._state,
    },
    enableHiding,
    enableRowSelection,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    ...rest,
  });

  useEffect(() => {
    table.setPageSize(pageLimit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLimit]);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 30,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  // const paddingBottom =
  //   virtualRows.length > 0
  //     ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
  //     : 0;

  const paddingBottom =
    (virtualRows.length < pageLimit ? pageLimit - virtualRows.length : 0) * 10;

  return (
    <Fragment>
      {enableGlobalFilter && (
        <div className="mb-2 mr-10 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
          <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
            <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
            <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
            <DebounceInput
              type="search"
              className={classNames(
                "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                "shadow-none outline-none focus:ring-0",
                "placeholder-black/50 placeholder-opacity-100"
              )}
              placeholder="Search keywords or phrases"
              minLength={2}
              debounceTimeout={300}
              value={globalFilter || ""}
              onChange={(e) => {
                setGlobalFilter(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              variant="icon"
              className="relative flex h-11 w-11 items-center justify-center rounded-md text-gray-600 transition-all hover:bg-primary-200 hover:text-primary-900"
              onClick={() => {
                table.resetGlobalFilter(true);
                setGlobalFilter("");
              }}
              disabled={loading}
            >
              <IconFilterClear className="h-5 w-5" />
              <span className="sr-only">Clear Filters</span>
            </Button>
          </div>
        </div>
      )}
      {enableAction && renderAction(table)}
      <div
        className={classNames(
          "relative",
          className ??
            "overflow-hidden rounded-lg border border-gray-200 bg-white"
        )}
      >
        {enableHiding && (
          <Menu
            as="div"
            className="absolute right-0 top-1.5 z-10 inline-block text-left normal-case"
          >
            <div>
              <Menu.Button className="-my-2 flex items-center rounded-lg px-2  py-4 text-gray-700 hover:text-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-700">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-1 z-10 mt-4 origin-top-right md:mt-2">
                <div className="w-screen max-w-[200px] flex-auto rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white pb-1 text-sm leading-6 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-gray-900/5">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="16px"
                    height="16px"
                    viewBox="0 0 110.9 96"
                    enableBackground="new 0 0 110.9 96"
                    className="absolute -top-3 right-0 -z-0"
                    fill="#ffffff"
                    style={{
                      filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                    }}
                  >
                    <polygon points="110.9,0 0,96 110.9,96 " />
                  </svg>
                  <div className="flex flex-col p-4">
                    <div className="mb-1 block text-sm font-medium text-gray-900">
                      Column Visibility
                    </div>
                    {table.getAllLeafColumns().map((column) => {
                      if (!column.getCanHide()) return null;
                      return (
                        <Menu.Item key={column.id}>
                          <label className="font-normal">
                            <input
                              {...{
                                type: "checkbox",
                                checked: column.getIsVisible(),
                                onChange: column.getToggleVisibilityHandler(),
                              }}
                              className={classNames(
                                "relative h-4 w-4 rounded border border-gray-400 text-primary-600 shadow-sm sm:text-sm",
                                "cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:ring-primary-50",
                                "checked:border-primary-500 checked:ring-primary-500"
                              )}
                            />
                            <span className="ml-2">
                              {column.columnDef.header as string}
                            </span>
                          </label>
                        </Menu.Item>
                      );
                    })}
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
        <SkeletonTheme baseColor="#f5f5f5" borderRadius="0.25rem" duration={1}>
          <div
            className="min-w-full max-w-full overflow-x-auto"
            ref={tableContainerRef}
          >
            <table className="min-w-full table-fixed overflow-x-auto">
              {enableHeader && (
                <thead className="sticky top-0 border-b border-gray-200 bg-greyish">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{
                              width:
                                header.getSize() !== 100
                                  ? header.getSize()
                                  : "100%",
                            }}
                            className={classNames(
                              "aling-top whitespace-nowrap px-2 py-2 text-left align-top text-xs font-medium uppercase tracking-wider",
                              index === 0 ? "pl-6" : "",
                              index === headerGroup.headers.length - 1
                                ? "pr-6"
                                : ""
                            )}
                          >
                            {header.isPlaceholder ? null : (
                              <Fragment>
                                <div
                                  {...{
                                    className: classNames(
                                      header.column.getCanSort()
                                        ? "cursor-pointer select-none"
                                        : "",
                                      header.column.getIsSorted()
                                        ? "text-primary-800"
                                        : "text-gray-700",
                                      "group flex w-full items-center rounded-md px-1.5 py-1.5 transition ease-in-out md:px-2 md:py-2"
                                    ),
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                  title={
                                    header.column.getCanSort()
                                      ? `Sort from ${table.getPageCount()} of ${pageSize} pages`
                                      : ""
                                  }
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {header.column.getCanSort()
                                    ? {
                                        asc: (
                                          <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 4.5 8"
                                            enableBackground="new 0 0 4.5 8"
                                            className="ml-1 h-3 w-3"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M2,7.9L0.1,5.8c-0.2-0.3,0-0.6,0.3-0.6h3.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3L2.6,7.9
                         C2.4,8.1,2.2,8.1,2,7.9C2,7.9,2,7.9,2,7.9L2,7.9z"
                                            />
                                            <path
                                              fill="currentColor"
                                              d="M2.6,0.1l1.9,2.1c0.2,0.3,0,0.6-0.3,0.6H0.4C0.2,2.9,0,2.7,0,2.5c0-0.1,0-0.2,0.1-0.3L2,0.1
                         C2.1,0,2.4,0,2.6,0.1C2.5,0.1,2.5,0.1,2.6,0.1L2.6,0.1z"
                                              opacity={0.25}
                                            />
                                          </svg>
                                        ),
                                        desc: (
                                          <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 4.5 8"
                                            enableBackground="new 0 0 4.5 8"
                                            className="ml-1 h-3 w-3"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M2,7.9L0.1,5.8c-0.2-0.3,0-0.6,0.3-0.6h3.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3L2.6,7.9
                     C2.4,8.1,2.2,8.1,2,7.9C2,7.9,2,7.9,2,7.9L2,7.9z"
                                              opacity={0.25}
                                            />
                                            <path
                                              fill="currentColor"
                                              d="M2.6,0.1l1.9,2.1c0.2,0.3,0,0.6-0.3,0.6H0.4C0.2,2.9,0,2.7,0,2.5c0-0.1,0-0.2,0.1-0.3L2,0.1
                     C2.1,0,2.4,0,2.6,0.1C2.5,0.1,2.5,0.1,2.6,0.1L2.6,0.1z"
                                            />
                                          </svg>
                                        ),
                                      }[
                                        header.column.getIsSorted() as string
                                      ] ?? (
                                        <svg
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 4.5 8"
                                          enableBackground="new 0 0 4.5 8"
                                          className="ml-1 h-3 w-3"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M2,7.9L0.1,5.8c-0.2-0.3,0-0.6,0.3-0.6h3.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3L2.6,7.9
                           C2.4,8.1,2.2,8.1,2,7.9C2,7.9,2,7.9,2,7.9L2,7.9z"
                                          />
                                          <path
                                            fill="currentColor"
                                            d="M2.6,0.1l1.9,2.1c0.2,0.3,0,0.6-0.3,0.6H0.4C0.2,2.9,0,2.7,0,2.5c0-0.1,0-0.2,0.1-0.3L2,0.1
                           C2.1,0,2.4,0,2.6,0.1C2.5,0.1,2.5,0.1,2.6,0.1L2.6,0.1z"
                                          />
                                        </svg>
                                      )
                                    : null}
                                </div>
                                {header.column.getCanFilter() ? (
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  />
                                ) : null}
                              </Fragment>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody>
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}
                {(!loading && filtering && virtualRows.length === 0) ||
                (!loading && globalFilter && virtualRows.length === 0) ? (
                  <Fragment>
                    <tr>
                      <td
                        colSpan={table.getVisibleFlatColumns().length}
                        className={classNames(
                          "px-0 py-10 text-center md:py-16 xl:py-20"
                        )}
                      >
                        <ExclamationCircleIcon
                          type="outline"
                          name="exclamation-circle"
                          className="mx-auto h-6 w-6 text-gray-400"
                        />
                        <p className="mt-4 font-medium text-gray-900">
                          No results found
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          No results found for this search term. Please try
                          different keywords.
                        </p>
                      </td>
                    </tr>
                  </Fragment>
                ) : !loading && virtualRows.length === 0 ? (
                  <Fragment>
                    <tr>
                      <td
                        colSpan={table.getVisibleFlatColumns().length}
                        className={classNames(
                          "px-0 py-10 text-center md:py-16 xl:py-20"
                        )}
                      >
                        <ExclamationCircleIcon
                          type="outline"
                          name="exclamation-circle"
                          className="mx-auto h-6 w-6 text-gray-400"
                        />
                        <p className="mt-4 font-medium text-gray-900">
                          {fallbackTitle ?? "No data available"}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          {fallbackText ??
                            "There is no data available for this table."}
                        </p>
                      </td>
                    </tr>
                  </Fragment>
                ) : (
                  <Fragment>
                    {virtualRows.map((virtualRow) => {
                      const row = rows[virtualRow.index] as Row<T>;
                      return (
                        <Fragment key={row.id}>
                          <tr
                            className={classNames(
                              "group shadow-transparent transition hover:bg-primary-50 hover:shadow-base",
                              // row.columnDef.meta?.className ?? "",
                              table.getIsAllRowsExpanded() && row.subRows.length
                                ? "bg-greyish"
                                : ""
                            )}
                          >
                            {row.getVisibleCells().map((cell, index) => {
                              return (
                                <td
                                  key={cell.id}
                                  className={classNames(
                                    "py-3 pl-4 pr-4 text-sm",
                                    index === 0 ? "pl-8" : "",
                                    index === row.getVisibleCells().length - 1
                                      ? "pr-8"
                                      : ""
                                  )}
                                  style={
                                    enableHeader
                                      ? {}
                                      : {
                                          width:
                                            cell.column.columnDef.size !== 100
                                              ? cell.column.columnDef.size
                                              : "100%",
                                        }
                                  }
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                          {row.getIsExpanded() && (
                            <tr>
                              <td
                                colSpan={row.getVisibleCells().length}
                                className="p-0"
                              >
                                {renderSubComponent(row)}
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                )}
                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </SkeletonTheme>
        <nav
          className="flex flex-wrap items-center border-t border-gray-200 px-4 py-3 sm:px-2.5 md:justify-between"
          aria-label="Pagination"
        >
          {enableRowSelection && (
            <div className="flex flex-wrap items-center space-x-4">
              {/* <div className="inline-flex items-center px-2.5 py-2 text-sm text-gray-700">
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllPageRowsSelected(),
                    indeterminate: table.getIsSomePageRowsSelected(),
                    onChange: table.getToggleAllPageRowsSelectedHandler(),
                  }}
                />
                <span className="ml-4">
                  Page Rows ({table.getRowModel().rows.length})
                </span>
              </div> */}
              {renderSelection(table)}
            </div>
          )}
          <div
            className={classNames(
              "flex flex-1 flex-wrap items-center justify-between space-x-4 pr-4 sm:justify-end md:flex-nowrap"
            )}
          >
            <div className="inline-flex items-center">
              <Button
                variant="icon"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                className="mr-2 flex h-8 w-8 items-center justify-center  rounded-md bg-primary-300 p-1.5 text-black disabled:bg-gray-200"
              >
                <span className="sr-only">Go to previous page</span>
                <span className="bi bi-chevron-bar-left"></span>
              </Button>
              {enableNavigation ? (
                !table.getCanNextPage() ? (
                  renderNavigation(table)
                ) : (
                  <Button
                    variant="icon"
                    onClick={() => table.nextPage()}
                    className="flex h-8 w-8 items-center justify-center rounded-md bg-primary-300 text-black disabled:bg-gray-200"
                  >
                    <span className="sr-only"> Go to next page</span>
                    <span className="bi bi-chevron-bar-right"></span>
                  </Button>
                )
              ) : (
                <Button
                  variant="icon"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className="flex h-8 w-8 items-center justify-center rounded-md bg-primary-300 text-black disabled:bg-gray-200"
                >
                  <span className="sr-only"> Go to next page</span>
                  <span className="bi bi-chevron-bar-right"></span>
                </Button>
              )}
            </div>
            <p className="hidden text-sm text-gray-700 md:inline-block">
              <span className="mr-1">Showing</span>
              <span className="mr-1 font-medium">
                {table.getState().pagination.pageIndex + 1}
              </span>
              <span className="mr-1">of</span>
              <span className="font-medium">{pageSize}</span> pages
            </p>

            <select
              className="relative inline-flex appearance-none rounded-md border-0 pl-4 pr-7 text-sm leading-5 text-gray-800  focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
                setPageLimit(Number(e.target.value));
                table.setPageIndex(0);
              }}
              disabled={loading || pageLimits.length < 2}
            >
              {pageLimits.map((limit) => (
                <option key={`limit-${limit}`} value={limit}>
                  Show {limit}
                </option>
              ))}
            </select>
          </div>
        </nav>
      </div>
    </Fragment>
  );
}
