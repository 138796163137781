import { useMutation, useQuery } from "@apollo/client/react";
import {
  ArrowPathIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import placeholder from "../../../assets/placeholder.svg";
import {
  ButtonDots,
  itemClasses,
  VehicleThumb,
  Watchers,
  WatchersLayout,
  WatchersType,
} from "../../../components/appearance";
import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  GET_VEHICLES,
  REMOVE_VEHICLE,
  type Vehicle,
} from "../../../graphql/fleets/vehicles";
import { renderPrimaryMeter } from "../../../graphql/fleets/vehicles/pages/settings";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { classNames, formatDate } from "../../../utils";
import { AuthShield } from "../../auth/core";

const VehicleList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);

  const { data, loading, error, refetch } = useQuery(GET_VEHICLES);
  const vehicles: Vehicle[] = useMemo<Vehicle[]>(() => {
    if (data?.fetchVehicles) {
      const fetchedVehicles: Vehicle[] = data.fetchVehicles.flatMap(
        (item: Vehicle) => ({
          ...item,
          createdAt: formatDate(item.createdAt),
        })
      );
      return fetchedVehicles;
    }
    return [];
  }, [data]);

  const [deleteVehicle] = useMutation(REMOVE_VEHICLE, {
    refetchQueries: [
      {
        query: GET_VEHICLES,
      },
    ],
  });
  const handleDelete = async (id: string) => {
    return await deleteVehicle({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.vehicleDelete) {
          const deletedVehicle = data.vehicleDelete?.vehicle;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Vehicle ${deletedVehicle.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vehicle delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Vehicle delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<Vehicle, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "vehicle.name",
        header: "Name",
        cell: (props) => (
          <VehicleThumb
            name={props.row.original.name}
            status={props.row.original.status}
            vehicleImageUrl={props.row.original.vehicleImageUrl}
            make={props.row.original.make}
            model={props.row.original.model}
            year={props.row.original.year}
            operator={props.row.original.operator}
            id={parseInt(props.row.original.id)}
            fuelUnit={null}
            measurementUnit={null}
            primaryMeter={null}
          />
        ),
      },
      {
        accessorKey: "year",
        header: "Year",
      },
      {
        accessorKey: "make",
        header: "Make",
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.make?.name}
          </span>
        ),
      },
      {
        accessorKey: "model",
        header: "Model",
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.model?.name}
          </span>
        ),
      },
      {
        accessorKey: "vinSn",
        header: "VIN/SN",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.row.original.vinSn}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (props) => (
          <span className="flex items-center whitespace-nowrap">
            <span
              className={classNames(
                "mr-2 inline-block h-3 w-3 rounded-full align-middle"
              )}
              style={{
                backgroundColor: props.row.original.status.color,
              }}
            />
            {props.row.original.status.name}
          </span>
        ),
      },
      {
        accessorKey: "vehicleType",
        header: "Type",
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.vehicleType.name}
          </span>
        ),
      },
      {
        id: "currentMeter",
        header: "Current Meter",
        cell: (props) =>
          props.row.original.financial.length ? (
            <span className="whitespace-nowrap">
              {props.row.original.financial[0].odometer}{" "}
              {renderPrimaryMeter(
                props.row.original.settings?.length
                  ? props.row.original.settings[0]?.primaryMeter
                  : null
              )}
            </span>
          ) : null,
      },
      {
        accessorKey: "licensePlate",
        header: "License Plate",
      },
      {
        id: "watchers",
        header: "Watchers",
        cell: (props) => (
          <Watchers
            layout={WatchersLayout.BUTTON}
            type={WatchersType.VEHICLE}
            id={props.row.original.id}
            refetch={refetch}
            data={props.row.original.watchers}
          />
        ),
      },
      {
        accessorKey: "operator",
        header: "Operator",
        size: 300,
        cell: (props) =>
          props.row.original.operator ? (
            <div className="flex w-full items-center">
              <Link
                to={`/fleets/settings/drivers/${props.row.original.operator.id}`}
                className="relative mr-2 block h-10 w-10 cursor-pointer appearance-none overflow-hidden rounded-md ring-2 ring-white filter transition hover:hue-rotate-15"
              >
                <img
                  className={classNames("h-full w-full object-cover")}
                  src={
                    props.row.original.operator.profileImageUrl
                      ? props.row.original.operator.profileImageUrl
                      : placeholder
                  }
                  alt={props.row.original.operator.name}
                />
              </Link>
              <span className="max-w-[12rem] truncate lg:max-w-[14rem] 2xl:max-w-[18rem]">
                {props.row.original.operator.name}
              </span>
            </div>
          ) : null,
      },
      {
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <ButtonDots>
            <AuthShield access={["read-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/${props.row.original.id}`}
              >
                <div className="flex items-center">
                  <EyeIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    View
                    <span className="sr-only">, {props.row.original.name}</span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["update-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/${props.row.original.id}/details`}
              >
                <div className="flex items-center">
                  <PencilIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Edit
                    <span className="sr-only">, {props.row.original.name}</span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <div className="flex items-center">
                  <TrashIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Delete
                    <span className="sr-only">, {props.row.original.name}</span>
                  </span>
                </div>
              </Button>
            </AuthShield>
          </ButtonDots>
        ),
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
      },
    ],
    []
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head title="Vehicles" heading="Vehicles" breadcrumbs={breadcrumbs} />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {VehicleListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {VehicleListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-vehicles"]}>
              <Button type="link" href="/fleets/vehicles/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add vehicle
              </Button>
            </AuthShield>
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh vehicle list</span>
            </Button>
          </div>
        </div>

        <TableCursor
          state={{
            columnVisibility: {
              id: false,
              vinSn: false,
              operator: false,
              createdAt: false,
              vehicleType: false,
              currentMeter: false,
            },
          }}
          data={vehicles}
          columns={columns}
          loading={loading}
          totalRows={vehicles.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No vehicles found"
          fallbackText="You can add a new vehicle by clicking on add vehicle button."
          enableHiding
        />
      </div>
    </>
  );
};

export default VehicleList;
export const VehicleListResource: ResourceProps = {
  name: "Vehicles",
  description: "A list of vehicles in the system",
  access: ["read-vehicles"],
  path: "/",
};
