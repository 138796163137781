import { gql } from "@apollo/client";

export const VEHICLESTATUS_FRAGMENT = gql`
  fragment VehicleStatusFragment on VehicleStatus {
    id
    name
    color
    default
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLESTATUSES = gql`
  ${VEHICLESTATUS_FRAGMENT}
  query FetchVehicleStatuses {
    fetchVehicleStatuses {
      ...VehicleStatusFragment
    }
  }
`;

export const GET_VEHICLESTATUS_BY_ID = gql`
  ${VEHICLESTATUS_FRAGMENT}
  query FetchVehicleStatus($id: ID!) {
    fetchVehicleStatus(id: $id) {
      ...VehicleStatusFragment
    }
  }
`;

export const REMOVE_VEHICLESTATUS = gql`
  ${VEHICLESTATUS_FRAGMENT}
  mutation VehicleStatusDelete($id: ID!) {
    vehicleStatusDelete(input: { id: $id }) {
      vehicleStatus {
        ...VehicleStatusFragment
      }
    }
  }
`;

export const ADD_VEHICLESTATUS = gql`
  ${VEHICLESTATUS_FRAGMENT}
  mutation VehicleStatusCreate(
    $name: String!
    $color: String!
    $default: Boolean!
  ) {
    vehicleStatusCreate(
      input: { params: { name: $name, color: $color, default: $default } }
    ) {
      vehicleStatus {
        ...VehicleStatusFragment
      }
    }
  }
`;

export const EDIT_VEHICLESTATUS = gql`
  ${VEHICLESTATUS_FRAGMENT}
  mutation VehicleStatusUpdate(
    $id: ID!
    $name: String!
    $color: String!
    $default: Boolean!
  ) {
    vehicleStatusUpdate(
      input: {
        id: $id
        params: { name: $name, color: $color, default: $default }
      }
    ) {
      vehicleStatus {
        ...VehicleStatusFragment
      }
    }
  }
`;

export type VehicleStatus = {
  id: string;
  name: string;
  color: string;
  default: boolean;
  createdAt: string;
  updatedAt: string;
};
