import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { ADD_VENDOR, GET_VENDORS } from "../../../graphql/purchases/vendors";
import Form from "./components/Form";

const VendorCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createVendor] = useMutation(ADD_VENDOR, {
    refetchQueries: [{ query: GET_VENDORS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createVendor({
      variables: {
        companyName: values.companyName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        webAddress: values.webAddress,
        vendorType: values.vendorType
          ? parseInt(values.vendorType.value)
          : null,
        address: values.address,
        comments: values.comments,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.vendorCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vendor created successfully",
          });
          return navigate(`/purchases/vendors`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vendor creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Vendor creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Vendor"
        heading="Vendors"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Vendors",
            href: "/purchases/vendors",
          },
          {
            name: VendorCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_vendor")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_vendor")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              companyName: "",
              email: "",
              phoneNumber: "",
              vendorCode: "",
              webAddress: "",
              vendorType: null,
              address: "",
              comments: "",
              status: true,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default VendorCreate;
export const VendorCreateResource: ResourceProps = {
  name: "Create Vendor",
  description: "Create a new vendor",
  access: ["create-vendors"],
  path: "vendors/add",
};
