import { gql } from "@apollo/client";

export const GET_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID = gql`
  query FetchPricingStructure(
    $id: ID!
    $pricingCategoryId: Int!
    $structureId: Int!
  ) {
    fetchPricingStructure(id: $id) {
      id
      name
      pricingMethod {
        id
        name
        handle
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
      products(pricingCategoryId: $pricingCategoryId) {
        id
        name
        featureImageUrl
        hasVariants
        variants {
          id
          stockCode
          productId
          basePrice
          comparePrice
          cost
          marketCost
          structureDetails(structureId: $structureId) {
            id
            priceFields
            sellPrice
            costUpdated
          }
          variantTitle {
            id
            name
            option {
              id
              name
            }
          }
        }
        status
      }
      customers {
        id
        customerName
      }
      createdAt
      status
    }
  }
`;

export const ADD_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID = gql`
  mutation PricingStructurePricingCreate(
    $pricingCategoryId: Int!
    $pricingStructureId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    pricingStructurePricingCreate(
      input: {
        params: {
          pricingCategoryId: $pricingCategoryId
          pricingStructureId: $pricingStructureId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export const EDIT_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID = gql`
  mutation PricingStructurePricingUpdate(
    $id: ID!
    $pricingCategoryId: Int!
    $pricingStructureId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    pricingStructurePricingUpdate(
      input: {
        id: $id
        params: {
          pricingCategoryId: $pricingCategoryId
          pricingStructureId: $pricingStructureId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

export type PricingMethod = {
  id: string;
  name: string;
  handle: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

export type StructureDetails = {
  id: string;
  priceFields: any[];
  sellPrice: number;
  costUpdated: boolean;
};
type Title = {
  id: string;
  name: string;
  option: {
    id: string;
    name: string;
  };
};
export type Variant = {
  id: string;
  stockCode: string;
  productId: number;
  basePrice: number;
  comparePrice: number;
  cost: number;
  marketCost: number;
  structureDetails?: StructureDetails[];
  variantTitle: Title[];
};

export type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
  status: number;
};

export type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  products: Product[];
  customers: {
    id: string;
    customerName: string;
  }[];
  createdAt: string;
  status: boolean;
};

export enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

export type ProductPricing = {
  id: string;
  pricingId?: string;
  pricingStructureId: number;
  productId: number;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};
