import { useMutation } from "@apollo/client/react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Head } from "../../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../../contexts/NotifyContext";
import { VehicleBasic } from "../../../../../graphql/fleets/vehicles";
import {
  ADD_METERENTRY,
  GET_METERENTRIES,
  GET_METERENTRY_BY_VEHICLE_ID,
  VehicleMeterEntryForm,
} from "../../../../../graphql/fleets/vehicles/pages/meter-entry";
import Form from "../../components/MeterEntryForm";

const MeterEntryCreate = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  const { vehicleId } = useParams();
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createVehicleMeterEntry] = useMutation(ADD_METERENTRY, {
    refetchQueries: [
      { query: GET_METERENTRY_BY_VEHICLE_ID, variables: { vehicleId } },
    ],
  });
  const handleCreate = (
    values: VehicleMeterEntryForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!vehicleId) return;
    createVehicleMeterEntry({
      variables: {
        vehicleId: parseInt(vehicleId),
        meterDate: values.meterDate,
        primaryMeter: values.primaryMeter,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleMeterEntryCreate) {
          return navigate(
            `/fleets/vehicles/${vehicleId}/meter-entry/${data.vehicleMeterEntryCreate.vehicleMeterEntry.id}`
          );
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Meter entry create failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={MeterEntryCreateResource.name}
        heading={MeterEntryCreateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: MeterEntryCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_meter_entry_create")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_meter_entry_create")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              vehicleId: Number(vehicleId),
              meterDate: null,
              primaryMeter: null,
            }}
            onSubmit={handleCreate}
            submitLabel={t("text_create")}
            onCancel={() => {
              navigate(`/fleets/vehicles/${vehicleId}/meter-entry`);
            }}
            vehicleBasic={vehicleBasic}
          />
        </div>
      </div>
    </>
  );
};

export default MeterEntryCreate;
export const MeterEntryCreateResource: ResourceProps = {
  name: "Create Meter Entry",
  description: "Create a new meter entry",
  access: ["create-drivers"],
  path: "/meter-entry/add",
};
