import { gql } from "@apollo/client";

import { Product as RawProduct, Status } from "../../inventory/products";

export const GET_ORDER_APPROVAL_PENDING = gql`
  query FetchOrderApprovalPending {
    fetchOrderApprovalPending {
      id
      orderNumber
      purchaseNumber
      customer {
        id
        customerName
        email
        tradingAddresses {
          address
          suburb
          state
          postcode
          primaryAddress
        }
      }
      items {
        id
        price
        quantity
        productApprove
        variant {
          product {
            name
            handle
            description
            featureImageUrl
          }
          stockCode
          variantImageUrl
          variantTitle {
            name
            option {
              name
            }
          }
        }
      }
      deliveryDate
      notes
      shippingAddress {
        address
        suburb
        state
        postcode
      }
      status
      subTotal
      total
      createdAt
    }
  }
`;

export const GET_ORDERS = gql`
  query FetchOrders(
    $searchQuery: String!
    $searchFilter: [String!]
    $customerId: ID
    $deliveryrunId: ID
    $deliveryDate: String
    $status: Int
  ) {
    fetchOrders(
      searchQuery: $searchQuery
      searchFilter: $searchFilter
      customerId: $customerId
      deliveryrunId: $deliveryrunId
      deliveryDate: $deliveryDate
      status: $status
    ) {
      id
      orderNumber
      purchaseNumber
      customer {
        id
        customerName
        email
        tradingAddresses {
          address
          suburb
          state
          postcode
          primaryAddress
        }
      }
      items {
        id
        price
        quantity
        productApprove
        orderComments
        variant {
          product {
            name
            handle
            description
            featureImageUrl
          }
          stockCode
          variantImageUrl
          variantTitle {
            name
            option {
              name
            }
          }
        }
      }
      deliveryDate
      notes
      shippingAddress {
        address
        suburb
        state
        postcode
      }
      status
      subTotal
      total
      createdAt
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query FetchOrder($id: ID!) {
    fetchOrder(id: $id) {
      id
      orderNumber
      purchaseNumber
      customer {
        id
        customerName
        email
        tradingAddresses {
          id
          address
          suburb
          state
          postcode
        }
      }
      items {
        id
        price
        quantity
        productApprove
        orderComments
        variant {
          product {
            id
            name
            handle
            description
            featureImageUrl
            status
          }
          id
          plu
          stockCode
          variantImageUrl
          variantTitle {
            name
            option {
              name
            }
          }
          minimumQuantity
          inventory
        }
      }
      deliveryDate
      notes
      shippingAddress {
        id
        address
        suburb
        state
        postcode
      }
      status
      subTotal
      total
      timelines {
        id
        info
        message
        order {
          id
          orderNumber
          purchaseNumber
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;

export const ADD_ORDER = gql`
  mutation OrderCreate(
    $customerId: Int!
    $purchaseNumber: String
    $customerTradingAddressId: Int
    $subTotal: Float
    $total: Float
    $deliveryDate: ISO8601DateTime
    $notes: String
    $status: Int!
    $products: [OrderproductInput!]
  ) {
    orderCreate(
      input: {
        params: {
          customerId: $customerId
          purchaseNumber: $purchaseNumber
          customerTradingAddressId: $customerTradingAddressId
          subTotal: $subTotal
          total: $total
          deliveryDate: $deliveryDate
          notes: $notes
          status: $status
          products: $products
        }
      }
    ) {
      order {
        id
      }
    }
  }
`;

export const EDIT_ORDER = gql`
  mutation OrderUpdate(
    $id: ID!
    $customerId: Int!
    $purchaseNumber: String
    $customerTradingAddressId: Int
    $subTotal: Float
    $total: Float
    $deliveryDate: ISO8601DateTime
    $notes: String
    $status: Int!
    $products: [OrderproductInput!]
  ) {
    orderUpdate(
      input: {
        id: $id
        params: {
          customerId: $customerId
          purchaseNumber: $purchaseNumber
          customerTradingAddressId: $customerTradingAddressId
          subTotal: $subTotal
          total: $total
          deliveryDate: $deliveryDate
          notes: $notes
          status: $status
          products: $products
        }
      }
    ) {
      order {
        id
        orderNumber
        purchaseNumber
        customer {
          id
          customerName
          email
          tradingAddresses {
            id
            address
            suburb
            state
            postcode
          }
        }
        items {
          id
          price
          quantity
          orderComments
          variant {
            product {
              id
              name
              handle
              description
              featureImageUrl
            }
            id
            plu
            stockCode
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
            minimumQuantity
            inventory
          }
        }
        deliveryDate
        notes
        shippingAddress {
          id
          address
          suburb
          state
          postcode
        }
        status
        subTotal
        total
        updatedAt
        createdAt
      }
    }
  }
`;

export const EDIT_BULK_ORDER_STATUS = gql`
  mutation BulkOrderStatusUpdate($clientMutationId: String, $ids: [ID!]!) {
    bulkOrderStatusUpdate(
      input: { clientMutationId: $clientMutationId, ids: $ids }
    ) {
      message
    }
  }
`;

export const DELETE_ORDER_PRODUCT = gql`
  mutation OrderProductDelete($id: ID!) {
    orderProductDelete(input: { id: $id }) {
      message
    }
  }
`;

export type ShippingAddress = {
  id: string;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
};
export enum OrderStatus {
  ARCHIVE = 0,
  DRAFT = 1,
  OPEN = 2,
  PICKING_PENDING = 3,
  PICKED = 4,
  PACKING = 5,
  READY_SHIP = 6,
  SHIPPED = 7,
  COMPLETED = 8,
}

type Variant = {
  id: string;
  product: {
    id: string;
    name: string;
    handle: string;
    description: string;
    featureImageUrl: string;
    status: Status;
  };
  plu?: number | null;
  stockCode: string;
  minimumQuantity: number;
  inventory: number;
  variantImageUrl: string;
  variantTitle: {
    id: string;
    name: string;
    option: {
      id: string;
      name: string;
    };
  }[];
  customerBasePrice: number;
  customerSpecialPrice: number;
};
export type TradingAddress = {
  id: string;
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  primaryAddress: boolean;
};

export type Customer = {
  id: string;
  customerName: string;
  email: string;
  tradingAddresses: TradingAddress[];
  orders: Order[];
};

export type Timeline = {
  id: string;
  info: string;
  message: string;
  order: Order;
  updatedAt: string;
  createdAt: string;
};

export enum ProductApprove {
  APPROVED,
  REQUESTED,
  REJECTED,
}
export type Order = {
  id: string;
  orderNumber: string;
  purchaseNumber: string;
  customer: Customer;
  items: {
    id: string;
    price: number;
    quantity: number;
    productApprove: ProductApprove;
    orderComments: string;
    variant: Variant;
  }[];
  deliveryDate: string;
  notes: string;
  shippingAddress: ShippingAddress;
  total: number;
  subTotal: number;
  timelines: Timeline[];
  createdAt: string;
  updatedAt: string;
  status: OrderStatus;
};

export type Product = Pick<
  RawProduct,
  "id" | "name" | "featureImageUrl" | "variants" | "status"
> & {
  inventory: number;
  minimumQuantity: number;
  quantity: number;
  price: number;
  customerBasePrice: number;
  customerSpecialPrice: number;
};

export type RowProduct = {
  id: string;
  oid: string;
  index: number;
  name: string;
  featureImageUrl?: string;
  productId: number;
  plu?: number | null;
  stockCode: string;
  inventory: number;
  minimumQuantity: number;
  quantity: number;
  price: number;
  productApprove: ProductApprove;
  variantTitle: {
    id: string;
    name: string;
    option: {
      id: string;
      name: string;
    };
  }[];
  customerBasePrice?: number;
  customerSpecialPrice?: number;
  status: Status;
};

export const renderStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.ARCHIVE:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
          Archived
        </span>
      );
    case OrderStatus.DRAFT:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
          Draft
        </span>
      );
    case OrderStatus.OPEN:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-gray-200 px-2 text-xs font-medium leading-5 text-gray-700">
          Open
        </span>
      );
    case OrderStatus.PICKING_PENDING:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
          Picking Pending
        </span>
      );
    case OrderStatus.PICKED:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
          Picked
        </span>
      );
    case OrderStatus.PACKING:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-green-50 px-2 text-xs font-medium leading-5 text-green-500">
          Packing
        </span>
      );
    case OrderStatus.READY_SHIP:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-green-50 px-2 text-xs font-medium leading-5 text-green-500">
          Ready To Ship
        </span>
      );
    case OrderStatus.SHIPPED:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-violet-50 px-2 text-xs font-medium leading-5 text-violet-700">
          Ready To Ship
        </span>
      );
    case OrderStatus.COMPLETED:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
          Completed
        </span>
      );
  }
};

export const renderStatusText = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.ARCHIVE:
      return "Archived";
    case OrderStatus.DRAFT:
      return "Draft";
    case OrderStatus.OPEN:
      return "Open";
    case OrderStatus.PICKING_PENDING:
      return "Picking Pending";
    case OrderStatus.PICKED:
      return "Picked";
    case OrderStatus.PACKING:
      return "Packing";
    case OrderStatus.READY_SHIP:
      return "Ready To Ship";
    case OrderStatus.SHIPPED:
      return "Ready To Ship";
    case OrderStatus.COMPLETED:
      return "Completed";
  }
};
