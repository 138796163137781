import { animated, useSpring } from "@react-spring/web";
import { useEffect, useState } from "react";

export const Reveal = ({ props }: { props: any }) => {
  const { component: Component, ...rest } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setOpen(true);
    }, 200);

    return () => {
      clearTimeout(showTimeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expand = useSpring({
    config: { friction: 10 },
    opacity: open ? 1 : 0,
    transform: open ? "translateY(0px)" : "translateY(20px)",
    reset: true,
  });

  return (
    <div>
      <animated.div style={expand}>
        <Component {...rest} />
      </animated.div>
    </div>
  );
};
