import { DeliveryRunsResource } from "./delivery-runs";
import { DocksResource } from "./docks";
import { FleetVendorsResource } from "./vendors";

export const SettingsResource = {
  name: "Settings",
  description: "Fleet Settings management module",
  path: "fleets/settings",
  icon: "bi bi-bag",
  access: ["read-fleets"],
  children: [DocksResource, DeliveryRunsResource, FleetVendorsResource],
};
