import { Dialog, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { Dispatch, Fragment, SetStateAction } from "react";
import { Accept, FileWithPath, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, Field } from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import { getToken } from "../../../auth";

const { REACT_APP_GRAPHQL_URL } = process.env;

export default function FormImport({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();

  const formik = useFormik({
    initialValues: {
      file: [],
      acceptOverwrite: false,
    },
    validationSchema: Yup.object().shape({
      file: Yup.mixed().required("Required"),
      acceptOverwrite: Yup.boolean(),
    }),
    onSubmit: async (
      values: {
        file: FileWithPath[];
        acceptOverwrite: boolean;
      },
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      const { file } = values;
      const csv = file[0];

      try {
        let formData = new FormData();
        formData.append("doc", csv);
        formData.append("accept_overwrite", values.acceptOverwrite ? "1" : "0");
        const token = await getToken();
        const response = await fetch(
          `${REACT_APP_GRAPHQL_URL}/customer/import`,
          {
            method: "POST",
            headers: {
              authorization: `Bearer ${token}`,
            },
            body: formData,
          }
        );
        const data = await response.json();

        if (!data.status) throw new Error("Could not upload file to server!");
        setOpen(false);
        addNotify({
          type: NotifyType.SUCCESS,
          title:
            "We are importing customers to the store. It will take some time. We will send you email after the process is done.",
        });
      } catch (e: any) {
        addNotify({
          type: NotifyType.ERROR,
          title: e.message,
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Transition.Root show={open} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1">
                    <div className="px-4 py-8 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          Import Customers
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Import customers from a CSV file.
                        </p>
                      </div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pb-5">
                            <p className="text-sm text-gray-500">
                              <Link
                                to="/customers_template-58fssf24352dfd728bnf8234.csv"
                                target="_top"
                                download
                                className="text-primary-600 hover:text-primary-700"
                              >
                                Download a sample CSV
                              </Link>{" "}
                              to see how the file should be formatted.
                            </p>
                            <FieldImport
                              id="file"
                              onChange={(value) => {
                                formik.setFieldValue("file", value);
                              }}
                              value={formik.values.file}
                            />
                            {formik.errors.file ? (
                              <p
                                className="mt-2 text-sm text-red-600"
                                id="file-errors"
                              >
                                {formik.errors.file.toString()}
                              </p>
                            ) : null}

                            <Field
                              title={t("text_accept_overwrite_customers")}
                              id="acceptOverwrite"
                              name="acceptOverwrite"
                              type="checkbox"
                              aria-describedby="acceptOverwrite-description"
                              checked={formik.values.acceptOverwrite}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setOpen(false);
                            formik.resetForm();
                          }}
                        >
                          {t("text_cancel")}
                        </Button>
                        <Button type="submit" disabled={formik.isSubmitting}>
                          {formik.isSubmitting ? (
                            <>
                              <Spinner />
                              {t("text_processing")}
                            </>
                          ) : (
                            t("text_import")
                          )}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function FieldImport(props: {
  id: string;
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  accept?: Accept;
  onChange: (value: any) => void;
  value?: File[] | undefined;
  touched?: boolean | undefined;
  errors?: string | undefined;
}) {
  const {
    id,
    minSize = 0,
    maxSize = 5,
    maxFiles = 1,
    accept = {
      "text/csv": [".csv"],
    },
    onChange,
    value,
    touched,
    errors,
  } = props;

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxFiles,
      minSize,
      maxSize: 1024 * 1024 * maxSize,
      accept,
      onDrop: onChange,
    });

  const extensions = Object.values(accept).flat().join(", ");

  return (
    <div>
      <div
        {...getRootProps({
          className:
            "w-full flex justify-center items-center px-6 h-42 md:h-44 bg-white border border-gray-300 border-dashed rounded-md relative overflow-hidden hover:border-gray-400 hover:bg-gray-50 cursor-pointer",
        })}
      >
        <input id={id} {...getInputProps()} />
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            {isDragActive ? (
              <p className="pl-1">Drop the files here ...</p>
            ) : (
              <p className="pl-1">
                Drag 'n' drop some files here, or click to{" "}
                <span className="relative cursor-pointer rounded-md font-medium text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:text-primary-500">
                  select files
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
      <p className="mt-2 space-x-4 text-sm text-gray-500">
        <span>Accepted File Types: {extensions} only.</span>
        <span>Max File Size: {maxSize}MB.</span>
        {maxFiles > 1 && <span>Max Files: {maxFiles}.</span>}
      </p>

      {touched && errors ? (
        <p className="mt-2 text-sm text-red-600" id="image-errors">
          {errors}
        </p>
      ) : null}

      {fileRejections && (
        <ul className="divide-y divide-gray-200 text-red-600">
          {fileRejections.map(
            ({
              file,
              errors,
            }: {
              file: FileWithPath;
              errors: {
                code: string;
                message: string;
              }[];
            }) => (
              <li
                key={file.path}
                className="relative flex items-center py-2 text-sm md:py-4"
              >
                <span className="relative inline-flex h-10 w-12 items-center justify-center overflow-hidden rounded-md border border-gray-200 bg-white text-gray-600">
                  <span className="bi bi-file-earmark-image-fill text-lg"></span>
                </span>
                <span className="ml-2 mr-4 font-medium text-gray-800">
                  {file.path}
                </span>
                <ul>
                  {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                  ))}
                </ul>

                <div className="ml-auto inline-flex items-center">
                  <ExclamationTriangleIcon
                    aria-hidden="true"
                    className="h-6 w-6"
                  />
                </div>
              </li>
            )
          )}
        </ul>
      )}

      {value && value.length ? (
        <ul className="divide-y divide-gray-200 text-primary-600">
          {value.map(
            (
              file: {
                name: string;
                size: number;
              },
              index: number
            ) => (
              <li
                key={file.name + index}
                className="relative flex items-center py-2 text-sm md:py-4"
              >
                <span className="relative inline-flex h-10 w-12 items-center justify-center overflow-hidden rounded-md border border-gray-200 bg-white text-gray-600">
                  <span className="bi bi-filetype-csv text-lg"></span>
                </span>
                <span className="ml-2 mr-4 font-medium text-gray-800">
                  {file.name}
                </span>
                <p className="pointer-events-none mb-0 mr-3 block text-gray-500">
                  {(file.size / (1024 * 1024)).toFixed(2)}MB
                </p>

                <div className="ml-auto inline-flex items-center">
                  <CheckCircleIcon aria-hidden="true" className="h-6 w-6" />
                </div>
              </li>
            )
          )}
        </ul>
      ) : null}
    </div>
  );
}
