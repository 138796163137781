import { useLazyQuery, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldDriver,
  FieldImage,
  FieldUser,
  FieldVehicleStatus,
  FieldVehicleType,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { VehicleDetailsForm } from "../../../../graphql/fleets/vehicles/pages/details";
import {
  ADD_VEHICLEBODYSUBTYPE,
  GET_VEHICLEBODYSUBTYPES,
  VehicleBodySubtype,
} from "../../../../graphql/fleets/vehicles/sections/body-subtypes";
import {
  ADD_VEHICLEBODYTYPE,
  GET_VEHICLEBODYTYPES,
  VehicleBodyType,
} from "../../../../graphql/fleets/vehicles/sections/body-types";
import {
  ADD_VEHICLEFUELTYPE,
  GET_VEHICLEFUELTYPES,
  VehicleFuelType,
} from "../../../../graphql/fleets/vehicles/sections/fuel-type";
import {
  ADD_VEHICLEMAKE,
  GET_VEHICLEMAKES,
  VehicleMake,
} from "../../../../graphql/fleets/vehicles/sections/make";
import {
  ADD_VEHICLEMODEL,
  GET_VEHICLEMODELS,
  VehicleModel,
} from "../../../../graphql/fleets/vehicles/sections/model";
import { classNames, useFormikErrors } from "../../../../utils";

export default function Form({
  initialValues,
  onChange = () => {},
  onClear = () => {},
  onSubmit,
  actionLabel,
}: {
  initialValues: VehicleDetailsForm;
  onChange?: (values: VehicleDetailsForm) => void;
  onClear?: () => void;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const VehicleSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    color: Yup.string().nullable(),
    licensePlate: Yup.string().required("Required"),
    msrp: Yup.number().nullable(),
    trim: Yup.string().nullable(),
    vehicleImageUrl: Yup.string().nullable(),
    vinSn: Yup.string().required("Required"),
    year: Yup.number().nullable(),
    fleetManagerId: Yup.number().nullable(),
    operatorId: Yup.number().nullable(),
    vehicleBodySubtypeId: Yup.number().nullable(),
    vehicleBodyTypeId: Yup.number().nullable(),
    vehicleTypeId: Yup.number().typeError("Required").required("Required"),
    vehicleStatusId: Yup.number().typeError("Required").required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: VehicleSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  useEffect(() => {
    onChange(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={formik.touched.name}
            errors={formik.errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_license_plate")}
            name="licensePlate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.licensePlate}
            touched={formik.touched.licensePlate}
            errors={formik.errors.licensePlate}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_msrp")}
            name="msrp"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.msrp || ""}
            touched={formik.touched.msrp}
            errors={formik.errors.msrp}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_trim")}
            name="trim"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.trim}
            touched={formik.touched.trim}
            errors={formik.errors.trim}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_color")}
            name="color"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.color}
            touched={formik.touched.color}
            errors={formik.errors.color}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <FieldImage
            title={t("text_image")}
            onChange={(value) => {
              formik.setFieldValue("vehicleImageUrl", value);
            }}
            value={formik.values.vehicleImageUrl}
            touched={formik.touched.vehicleImageUrl}
            errors={formik.errors.vehicleImageUrl}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_vin_sn")}
            name="vinSn"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vinSn}
            touched={formik.touched.vinSn}
            errors={formik.errors.vinSn}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_year")}
            name="year"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.year || ""}
            touched={formik.touched.year}
            errors={formik.errors.year}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_fleet_manager")}
          </label>
          <FieldUser
            title="Fleet Manager"
            value={formik.values.fleetManagerId}
            onChange={(value) => {
              formik.setFieldValue("fleetManagerId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.fleetManagerId && formik.errors.fleetManagerId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.fleetManagerId && formik.errors.fleetManagerId ? (
            <p className="mt-2 text-sm text-red-600" id="fleetManagerId-errors">
              {formik.errors.fleetManagerId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_operator")}
          </label>
          <FieldDriver
            title={t("text_operator")}
            value={formik.values.operatorId}
            onChange={(value) => {
              formik.setFieldValue("operatorId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.operatorId && formik.errors.operatorId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.operatorId && formik.errors.operatorId ? (
            <p className="mt-2 text-sm text-red-600" id="operatorId-errors">
              {formik.errors.operatorId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_vehicle_body_subtype")}
          </label>
          <FieldVehicleBodySubtypes
            value={formik.values.vehicleBodySubtypeId}
            onChange={(value) => {
              formik.setFieldValue("vehicleBodySubtypeId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.vehicleBodySubtypeId &&
                formik.errors.vehicleBodySubtypeId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          <p className="mt-1 text-xs text-gray-500">
            Type to create new vehicle body subtype
          </p>
          {formik.touched.vehicleBodySubtypeId &&
          formik.errors.vehicleBodySubtypeId ? (
            <p
              className="mt-2 text-sm text-red-600"
              id="vehicleBodySubtypeId-errors"
            >
              {formik.errors.vehicleBodySubtypeId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_vehicle_body_type")}
          </label>
          <FieldVehicleBodyTypes
            value={formik.values.vehicleBodyTypeId}
            onChange={(value) => {
              formik.setFieldValue("vehicleBodyTypeId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.vehicleBodyTypeId &&
                formik.errors.vehicleBodyTypeId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          <p className="mt-1 text-xs text-gray-500">
            Type to create new vehicle body type
          </p>
          {formik.touched.vehicleBodyTypeId &&
          formik.errors.vehicleBodyTypeId ? (
            <p
              className="mt-2 text-sm text-red-600"
              id="vehicleBodyTypeId-errors"
            >
              {formik.errors.vehicleBodyTypeId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_vehicle_fuel_type")}
          </label>
          <FieldVehicleFuelTypes
            value={formik.values.vehicleFuelTypeId}
            onChange={(value) => {
              formik.setFieldValue("vehicleFuelTypeId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.vehicleFuelTypeId &&
                formik.errors.vehicleFuelTypeId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          <p className="mt-1 text-xs text-gray-500">
            Type to create new vehicle flue type
          </p>
          {formik.touched.vehicleFuelTypeId &&
          formik.errors.vehicleFuelTypeId ? (
            <p
              className="mt-2 text-sm text-red-600"
              id="vehicleFuelTypeId-errors"
            >
              {formik.errors.vehicleFuelTypeId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_vehicle_make")}
          </label>
          <FieldVehicleMakes
            value={formik.values.vehicleMakeId}
            onChange={(value) => {
              formik.setFieldValue("vehicleMakeId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.vehicleMakeId && formik.errors.vehicleMakeId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          <p className="mt-1 text-xs text-gray-500">
            Type to create new vehicle flue type
          </p>
          {formik.touched.vehicleMakeId && formik.errors.vehicleMakeId ? (
            <p className="mt-2 text-sm text-red-600" id="vehicleMakeId-errors">
              {formik.errors.vehicleMakeId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_vehicle_model")}
          </label>
          <FieldVehicleModels
            value={formik.values.vehicleModelId}
            onChange={(value) => {
              formik.setFieldValue("vehicleModelId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.vehicleModelId && formik.errors.vehicleModelId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          <p className="mt-1 text-xs text-gray-500">
            Type to create new vehicle flue type
          </p>
          {formik.touched.vehicleModelId && formik.errors.vehicleModelId ? (
            <p className="mt-2 text-sm text-red-600" id="vehicleModelId-errors">
              {formik.errors.vehicleModelId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_vehicle_group")}
          </label>
          <FieldVehicleType
            value={formik.values.vehicleTypeId}
            onChange={(value) => {
              formik.setFieldValue("vehicleTypeId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.vehicleTypeId && formik.errors.vehicleTypeId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.vehicleTypeId && formik.errors.vehicleTypeId ? (
            <p className="mt-2 text-sm text-red-600" id="vehicleTypeId-errors">
              {formik.errors.vehicleTypeId.toString()}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_vehicle_status")}
          </label>
          <FieldVehicleStatus
            value={formik.values.vehicleStatusId}
            onChange={(value) => {
              formik.setFieldValue("vehicleStatusId", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.vehicleStatusId && formik.errors.vehicleStatusId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.vehicleStatusId && formik.errors.vehicleStatusId ? (
            <p
              className="mt-2 text-sm text-red-600"
              id="vehicleStatusId-errors"
            >
              {formik.errors.vehicleStatusId.toString()}
            </p>
          ) : null}
        </div>
      </div>
      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/fleets/vehicles" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button
          variant="secondary"
          className="w-full justify-center"
          onClick={onClear}
        >
          {t("text_clear")}
        </Button>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}

export function FieldVehicleBodySubtypes({
  value: initialValue,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchVehicleBodySubtypes] = useLazyQuery(GET_VEHICLEBODYSUBTYPES);
  const [createVehicleBodySubtype] = useMutation(ADD_VEHICLEBODYSUBTYPE);

  const fetchingVehicleBodySubtypes = useCallback(() => {
    fetchVehicleBodySubtypes()
      .then(({ data }) => {
        if (data?.fetchVehicleBodySubtypes) {
          const updatedOptions: OptionProps[] =
            data.fetchVehicleBodySubtypes.map((p: VehicleBodySubtype) => {
              return {
                label: p.name,
                value: p.id,
              };
            });
          setOptions(updatedOptions);
        } else {
          toast.error("Error fetching vehicle body subtypes");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [fetchVehicleBodySubtypes]);

  useEffect(() => {
    fetchingVehicleBodySubtypes();
  }, [fetchingVehicleBodySubtypes]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === String(initialValue)) ?? null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [initialValue, options]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    createVehicleBodySubtype({
      variables: {
        name: inputValue,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.vehicleBodySubtypeCreate) {
          const newValue = data.vehicleBodySubtypeCreate.vehicleBodySubtype;
          const newOption = {
            label: newValue.name,
            value: newValue.id,
          };
          setOptions([...options, newOption]);
          onChangeHandler(newOption);
        } else {
          toast.error("Error fetching vehicle body subtypes");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChangeHandler}
        onCreateOption={handleCreate}
        options={options}
        value={value}
      />
    </SelectWrapper>
  );
}

export function FieldVehicleBodyTypes({
  value: initialValue,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchVehicleBodyTypes] = useLazyQuery(GET_VEHICLEBODYTYPES);
  const [createVehicleBodyType] = useMutation(ADD_VEHICLEBODYTYPE);

  const fetchingVehicleBodyTypes = useCallback(() => {
    fetchVehicleBodyTypes()
      .then(({ data }) => {
        if (data?.fetchVehicleBodyTypes) {
          const updatedOptions: OptionProps[] = data.fetchVehicleBodyTypes.map(
            (p: VehicleBodyType) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          toast.error("Error fetching vehicle body types");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [fetchVehicleBodyTypes]);

  useEffect(() => {
    fetchingVehicleBodyTypes();
  }, [fetchingVehicleBodyTypes]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === String(initialValue)) ?? null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [initialValue, options]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    createVehicleBodyType({
      variables: {
        name: inputValue,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.vehicleBodyTypeCreate) {
          const newValue = data.vehicleBodyTypeCreate.vehicleBodyType;
          const newOption = {
            label: newValue.name,
            value: newValue.id,
          };
          setOptions([...options, newOption]);
          onChangeHandler(newOption);
        } else {
          toast.error("Error fetching vehicle body types");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChangeHandler}
        onCreateOption={handleCreate}
        options={options}
        value={value}
      />
    </SelectWrapper>
  );
}

export function FieldVehicleFuelTypes({
  value: initialValue,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchVehicleFuelTypes] = useLazyQuery(GET_VEHICLEFUELTYPES);
  const [createVehicleFuelType] = useMutation(ADD_VEHICLEFUELTYPE);

  const fetchingVehicleFuelTypes = useCallback(() => {
    fetchVehicleFuelTypes()
      .then(({ data }) => {
        if (data?.fetchVehicleFuelTypes) {
          const updatedOptions: OptionProps[] = data.fetchVehicleFuelTypes.map(
            (p: VehicleFuelType) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          toast.error("Error fetching vehicle fuel types");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [fetchVehicleFuelTypes]);

  useEffect(() => {
    fetchingVehicleFuelTypes();
  }, [fetchingVehicleFuelTypes]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === String(initialValue)) ?? null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [initialValue, options]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    createVehicleFuelType({
      variables: {
        name: inputValue,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.vehicleFuelTypeCreate) {
          const newValue = data.vehicleFuelTypeCreate.vehicleFuelType;
          const newOption = {
            label: newValue.name,
            value: newValue.id,
          };
          setOptions([...options, newOption]);
          onChangeHandler(newOption);
        } else {
          toast.error("Error fetching vehicle fuel types");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChangeHandler}
        onCreateOption={handleCreate}
        options={options}
        value={value}
      />
    </SelectWrapper>
  );
}

export function FieldVehicleMakes({
  value: initialValue,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchVehicleMakes] = useLazyQuery(GET_VEHICLEMAKES);
  const [createVehicleMake] = useMutation(ADD_VEHICLEMAKE);

  const fetchingVehicleMakes = useCallback(() => {
    fetchVehicleMakes()
      .then(({ data }) => {
        if (data?.fetchVehicleMakes) {
          const updatedOptions: OptionProps[] = data.fetchVehicleMakes.map(
            (p: VehicleMake) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          toast.error("Error fetching vehicle make");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [fetchVehicleMakes]);

  useEffect(() => {
    fetchingVehicleMakes();
  }, [fetchingVehicleMakes]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === String(initialValue)) ?? null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [initialValue, options]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    createVehicleMake({
      variables: {
        name: inputValue,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.vehicleMakeCreate) {
          const newValue = data.vehicleMakeCreate.vehicleMake;
          const newOption = {
            label: newValue.name,
            value: newValue.id,
          };
          setOptions([...options, newOption]);
          onChangeHandler(newOption);
        } else {
          toast.error("Error fetching vehicle make");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChangeHandler}
        onCreateOption={handleCreate}
        options={options}
        value={value}
      />
    </SelectWrapper>
  );
}

export function FieldVehicleModels({
  value: initialValue,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);
  const [fetchVehicleModels] = useLazyQuery(GET_VEHICLEMODELS);
  const [createVehicleModel] = useMutation(ADD_VEHICLEMODEL);

  const fetchingVehicleModels = useCallback(() => {
    fetchVehicleModels()
      .then(({ data }) => {
        if (data?.fetchVehicleModels) {
          const updatedOptions: OptionProps[] = data.fetchVehicleModels.map(
            (p: VehicleModel) => {
              return {
                label: p.name,
                value: p.id,
              };
            }
          );
          setOptions(updatedOptions);
        } else {
          toast.error("Error fetching vehicle model");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [fetchVehicleModels]);

  useEffect(() => {
    fetchingVehicleModels();
  }, [fetchingVehicleModels]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === String(initialValue)) ?? null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [initialValue, options]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    createVehicleModel({
      variables: {
        name: inputValue,
      },
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.vehicleModelCreate) {
          const newValue = data.vehicleModelCreate.vehicleModel;
          const newOption = {
            label: newValue.name,
            value: newValue.id,
          };
          setOptions([...options, newOption]);
          onChangeHandler(newOption);
        } else {
          toast.error("Error fetching vehicle model");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={onChangeHandler}
        onCreateOption={handleCreate}
        options={options}
        value={value}
      />
    </SelectWrapper>
  );
}
