import { useQuery } from "@apollo/client";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";

import { Driver, GET_DRIVERS } from "../../graphql/fleets/settings/drivers";
import { Avatar, ButtonPopover, PopoverPosition } from "../appearance";
import { selectStyles, SelectWrapper } from ".";

type FieldDriverProps = {
  title: string;
  value: number | null;
  onChange?: (newValue: number | null) => void;
  className?: string;
  isPreview?: boolean;
  isString?: boolean;
  position?: PopoverPosition;
};
export function FieldDriver({
  title,
  value: initialValue,
  onChange,
  className = "",
  isPreview = false,
  isString = false,
  position = PopoverPosition.RIGHT,
}: FieldDriverProps) {
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery<{
    fetchDrivers: Driver[];
  }>(GET_DRIVERS);

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchDrivers) {
      return data.fetchDrivers?.map((p: Driver) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === initialValue.toString()) ??
        null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [options, initialValue]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    typeof onChange === "function" &&
      onChange(newValue ? Number(newValue.value) : null);
  };

  if (isPreview) {
    if (value) {
      const driver = data?.fetchDrivers?.find(
        (p: Driver) => p.id === value?.value
      );
      if (driver) {
        return (
          <ButtonPopover
            title={driver.name}
            child={
              isString ? (
                driver.name
              ) : (
                <div className="flex items-center">
                  {driver.profileImageUrl ? (
                    <span className="relative inline-block h-10 w-10 overflow-hidden rounded-md">
                      <img
                        className="h-full w-full object-cover"
                        src={driver.profileImageUrl}
                        alt={driver.name}
                      />
                    </span>
                  ) : (
                    <Avatar firstName={driver.name} />
                  )}
                  <span className="ml-2">{driver.name}</span>
                </div>
              )
            }
            position={position}
          >
            <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
              <h3 className="mb-1 font-medium">{driver.name}</h3>
              <p>#{driver.driverCode}</p>
              <p>{driver.email}</p>
            </div>
            <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
              <Link
                to={`/fleets/settings/drivers/${driver.id}`}
                target="_blank"
                className="block text-center text-sm text-gray-500"
              >
                View {title}
              </Link>
            </div>
          </ButtonPopover>
        );
      }
    }
    return <Fragment>-</Fragment>;
  }

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={value}
        options={options}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
