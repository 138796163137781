import { useQuery } from "@apollo/client/react";
import { Dialog, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../../../animations";
import {
  Button,
  Field,
  selectStyles,
  SelectWrapper,
} from "../../../../../../components/form";
import {
  GET_VEHICLESERVICETASKS,
  ServiceTaskForm,
} from "../../../../../../graphql/fleets/vehicles/sections/service-tasks";
import {
  classNames,
  toCleanString,
  toNestedOptions,
} from "../../../../../../utils";

export default function Form({
  heading,
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: ServiceTaskForm;
  onSubmit: (values: ServiceTaskForm, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();

  const ServiceTaskSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    description: Yup.string().nullable(),
    parent: Yup.object().nullable(),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ServiceTaskSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 py-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.name ? formik.values.name : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Get started by filling in the information below to create your
                new service task.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <Field
                      title={t("text_name")}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      touched={touched.name}
                      errors={errors.name}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label className="block text-sm font-medium text-gray-900">
                      {t("text_service_task_parent")}
                    </label>
                    <FieldServiceTask
                      value={formik.values.parentId ?? null}
                      serviceTaskId={
                        formik.values.id
                          ? parseInt(formik.values.id)
                          : undefined
                      }
                      onChange={(value) => {
                        formik.setFieldValue("parentId", value);
                      }}
                      className={classNames(
                        "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                        formik.touched.parentId && formik.errors.parentId
                          ? "border-red-600 text-red-900"
                          : ""
                      )}
                    />
                    {formik.touched.parentId && formik.errors.parentId ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="parentId-errors"
                      >
                        {formik.errors.parentId}
                      </p>
                    ) : null}
                  </div>

                  <div className="sm:col-span-6">
                    <Field
                      title={t("text_description")}
                      name="description"
                      type="textarea"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      touched={touched.description}
                      errors={errors.description}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <legend className="text-sm font-medium text-gray-900">
                      {t("text_status")}
                    </legend>
                    <Switch.Group
                      as="div"
                      className="mt-1.5 inline-flex items-center"
                    >
                      <Switch
                        checked={formik.values.status}
                        onChange={() => {
                          formik.setFieldValue("status", !formik.values.status);
                        }}
                        id="status"
                        className={classNames(
                          formik.values.status
                            ? "bg-primary-600"
                            : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            formik.values.status
                              ? "translate-x-5"
                              : "translate-x-0",
                            "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                      <Switch.Label
                        passive
                        htmlFor="status"
                        className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                      >
                        {formik.values.status
                          ? t("text_active")
                          : t("text_inactive")}
                      </Switch.Label>
                    </Switch.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export function FieldServiceTask({
  value: initialValue,
  onChange,
  className,
  serviceTaskId,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
  serviceTaskId?: number;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery(GET_VEHICLESERVICETASKS);

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchServiceTasks) {
      const fetchedServiceTasks: OptionsData[] = data.fetchServiceTasks;
      const optionsWithDepth: Array<OptionsData & { depth?: number }> =
        toNestedOptions(fetchedServiceTasks, String(serviceTaskId));
      const updatedOptions = optionsWithDepth?.map((p) => {
        return {
          label: `${Array.from(
            Array(typeof p.depth === "number" ? p.depth : 0).keys()
          )
            .map((_) => "-")
            .join("")} ${p.name}`,
          value: p.id,
        };
      });

      return updatedOptions;
    }
    return [];
  }, [data, serviceTaskId]);

  useEffect(() => {
    if (!initialValue) return setValues(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === String(initialValue)) ?? null;
      if (option) {
        const updatedValues = {
          label: toCleanString(option.label),
          value: option.value,
        };
        setValues(updatedValues);
        return;
      }
    }
    setValues(null);
  }, [initialValue, options]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
