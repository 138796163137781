import { gql } from "@apollo/client/core";

import { Variant } from "../../inventory/products";
import { BASIC_CUSTOMER_FRAGMENT } from "../../sales/customers";
import { OrderStatus } from "../../sales/orders";
import { Picker } from "../../user-management/assign-zone-pickers";

export const PICKING_PRODUCT_FRAGMENT = gql`
  fragment PickingProductFragment on Pickingproduct {
    id
    price
    quantity
    productApprove
    pickingStatus
    pickerId
    picker {
      id
      firstName
      lastName
      fullName
    }
    orderComments
    packingComments
    variant {
      ...VariantFragment
    }
  }
`;
export const PICKING_FRAGMENT = gql`
  ${BASIC_CUSTOMER_FRAGMENT}
  ${PICKING_PRODUCT_FRAGMENT}
  fragment PickingFragment on Picking {
    id
    orderNumber
    createdAt
    deliveryRun
    status
    customer {
      ...BasicCustomerFragment
    }
    orderDetails {
      ...PickingProductFragment
    }
  }
`;

export const GET_PICKING = gql`
  ${PICKING_FRAGMENT}
  query FetchPickings(
    $first: Int
    $cursor: String
    $searchQuery: String!
    $searchFilter: [String!]
    $binLocationId: ID
    $deliveryrunId: ID
    $departmentId: ID
    $fromDate: String
    $toDate: String
  ) {
    fetchPickings(
      first: $first
      after: $cursor
      searchQuery: $searchQuery
      searchFilter: $searchFilter
      binLocationId: $binLocationId
      deliveryrunId: $deliveryrunId
      departmentId: $departmentId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      edges {
        node {
          ...PickingFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export enum PickingStatus {
  PENDING,
  IN_PROGRESS,
  PICKED,
}
export const renderPickingStatusText = (status: PickingStatus) => {
  switch (status) {
    case PickingStatus.PENDING:
      return "Not Picked";
    case PickingStatus.IN_PROGRESS:
      return "In Progress";
    case PickingStatus.PICKED:
      return "Picked";
    default:
      return "Unknown";
  }
};
export const renderPickingStatus = (status: PickingStatus) => {
  switch (status) {
    case PickingStatus.PENDING:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-red-50 px-2 text-xs font-medium leading-5 text-red-500">
          Not Picked
        </span>
      );
    case PickingStatus.IN_PROGRESS:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-violet-50 px-2 text-xs font-medium leading-5 text-violet-700">
          In Progress
        </span>
      );
    case PickingStatus.PICKED:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-green-50 px-2 text-xs font-medium leading-5 text-green-500">
          Picked
        </span>
      );
    default:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-gray-50 px-2 text-xs font-medium leading-5 text-gray-500">
          Unknown
        </span>
      );
  }
};
export enum ProductApprove {
  APPROVED,
  SENT_APPROVAL,
  REJECTED,
}
export const renderProductApproveText = (status: ProductApprove) => {
  switch (status) {
    case ProductApprove.APPROVED:
      return "Approved";
    case ProductApprove.SENT_APPROVAL:
      return "Sent Approval";
    case ProductApprove.REJECTED:
      return "Rejected";
    default:
      return "Unknown";
  }
};
export const renderProductApprove = (status: ProductApprove) => {
  switch (status) {
    case ProductApprove.APPROVED:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-red-50 px-2 text-xs font-medium leading-5 text-red-500">
          Approved
        </span>
      );
    case ProductApprove.SENT_APPROVAL:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-violet-50 px-2 text-xs font-medium leading-5 text-violet-700">
          Sent Approval
        </span>
      );
    case ProductApprove.REJECTED:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-green-50 px-2 text-xs font-medium leading-5 text-green-500">
          Rejected
        </span>
      );
    default:
      return (
        <span className="inline-flex whitespace-nowrap rounded-full bg-gray-50 px-2 text-xs font-medium leading-5 text-gray-500">
          Unknown
        </span>
      );
  }
};

export type PickingProduct = {
  id: string;
  orderComments: string;
  packingComments: string;
  pickingStatus: PickingStatus;
  pickerId: number | null;
  picker: Picker | null;
  price: number;
  productApprove: ProductApprove;
  quantity: number;
  variant: Variant;
};
export type Picking = {
  id: string;
  orderNumber: string;
  customerId: string;
  customer: {
    id: string;
    customerName: string;
    customerPlu: string;
  };
  deliveryRun: string;
  status: OrderStatus;
  orderDetails: PickingProduct[];
};

export const EDIT_PICKING_STATUS = gql`
  mutation PickingStatusUpdate($orderIds: [ID!]!, $pickingStatus: Int!) {
    pickingStatusUpdate(
      input: { orderIds: $orderIds, pickingStatus: $pickingStatus }
    ) {
      message
    }
  }
`;
