import { useMutation, useQuery } from "@apollo/client/react";
import {
  ArrowPathIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SingleValue } from "react-select";

import { ProgressBar } from "../../../animations";
import placeholder from "../../../assets/placeholder.svg";
import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  EDIT_PICKING_PRODUCTSTATUS,
  GET_PICKER_WORKLIST,
} from "../../../graphql/pickpack/picker-worklist";
import {
  type Picking,
  PickingStatus,
  renderPickingStatus,
} from "../../../graphql/pickpack/picking";
import { renderStatus } from "../../../graphql/sales/orders";
import {
  classNames,
  formatCurrency,
  formatDate,
  formatFloat,
  formatVariantTitle,
} from "../../../utils";
import { isAdministrator, isDeveloper, useAuth } from "../../auth";
import { FieldStatus } from "./components/FieldStatus";

const PickerWorkerlistList = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const { currentRole } = useAuth();

  const [refetching, setRefetching] = useState<boolean>(false);

  const [isMorefetching, setIsMorefetching] = useState(false);
  const [pageLimit] = useState<LimitBy>(LimitBy.L4);

  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_PICKER_WORKLIST,
    {
      variables: {
        first: pageLimit,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    }
  );

  const totalProducts = useMemo(
    () => data?.fetchPickerWorklist?.totalCount || 0,
    [data?.fetchPickerWorklist?.totalCount]
  );

  const pickings: Picking[] = useMemo(
    () =>
      data?.fetchPickerWorklist.edges?.flatMap((edge: { node: Picking }) => ({
        ...edge.node,
      })) ?? [],
    [data?.fetchPickerWorklist.edges]
  );

  const fetchNextPage = useCallback(
    (table: any) => {
      setIsMorefetching(true);
      fetchMore({
        variables: {
          cursor: data?.fetchPickerWorklist?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setTimeout(() => {
          setIsMorefetching(false);
          table.setPageIndex(table.getPageCount() - 1);
        }, 500);
      });
    },
    [data?.fetchPickerWorklist?.pageInfo?.endCursor, fetchMore]
  );

  const columns = useMemo<ColumnDef<Picking, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Order Number",
      },
      {
        accessorKey: "customer",
        header: "To",
        enableHiding: false,
        cell: ({ row }) => (
          <span className="whitespace-nowrap">
            {row.original.customer?.customerName}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        header: () => <span className="whitespace-nowrap">Order Date</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {formatDate(props.getValue<string>())}
          </span>
        ),
      },
      {
        accessorKey: "deliveryRun",
        header: "Delivery Run",
      },
      {
        accessorKey: "status",
        header: () => <span className="whitespace-nowrap">Progress</span>,
        cell: (props) => {
          const progress = formatFloat(
            (props.row.original.orderDetails.filter(
              (od) => od.pickingStatus === PickingStatus.PICKED
            ).length /
              props.row.original.orderDetails.length) *
              100
          ).toFixed(0);
          return (
            <div className="flex items-center space-x-2">
              <ProgressBar progress={parseInt(progress)} className="text-xs" />
              {renderStatus(props.getValue<number>())}
            </div>
          );
        },
      },
    ],
    []
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  const [pickingProductStatusUpdate, { loading: loadingPickingProductStatus }] =
    useMutation(EDIT_PICKING_PRODUCTSTATUS, {
      refetchQueries: [
        {
          query: GET_PICKER_WORKLIST,
          variables: {
            first: pageLimit,
          },
        },
      ],
    });

  const handleProductStatus = useCallback(
    ({ id, pickingStatus }: { id: string; pickingStatus: number }) => {
      pickingProductStatusUpdate({
        variables: {
          id,
          pickingStatus,
        },
      })
        .then(async ({ data }) => {
          if (data?.pickingProductStatusUpdate) {
            await refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Picking status updated",
              message: data.pickingProductStatusUpdate.message,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Picking status update failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Picking status update failed",
            message: error.message,
          });
        });
    },
    [addNotify, pickingProductStatusUpdate, refetch]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Picker Worker List"
        heading="Picker Worker List"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: PickerWorkerlistListResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PickerWorkerlistListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PickerWorkerlistListResource.description}
            </p>
          </div>
        </div>

        {isDeveloper(currentRole) ? (
          <div className="py-10 text-center md:py-16 xl:py-20">
            <ExclamationCircleIcon
              type="outline"
              name="exclamation-circle"
              className="mx-auto h-6 w-6 text-gray-400"
            />
            <p className="mt-4 font-medium text-gray-900">
              You are in developer mode
            </p>
            <p className="mt-2 text-sm text-gray-500">
              This page is only available for administrative users.
            </p>
          </div>
        ) : (
          <TableCursor
            data={pickings}
            columns={columns}
            loading={loading}
            totalRows={totalProducts}
            pageLimit={pageLimit}
            pageLimits={[pageLimit]}
            getRowCanExpand={() => true}
            getCoreRowModel={getCoreRowModel()}
            getExpandedRowModel={getExpandedRowModel()}
            renderSubComponent={(row) => {
              return (
                <div className="bg-primary-700/20 px-4 md:px-6 xl:px-16">
                  <table className="min-w-full divide-y divide-gray-900/60">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell lg:pl-0"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell lg:w-44"
                        >
                          Product Name
                        </th>
                        <th
                          scope="col"
                          className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                        >
                          Stock Code
                        </th>
                        <th
                          scope="col"
                          className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                        >
                          Variant
                        </th>
                        <th
                          scope="col"
                          className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                        >
                          Department
                        </th>
                        <th
                          scope="col"
                          className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                        >
                          Bin Location
                        </th>
                        <th
                          scope="col"
                          className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium sm:table-cell lg:text-center"
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium lg:text-center"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium lg:text-center"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium lg:text-left"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-900/10">
                      {row.original.orderDetails?.length
                        ? row.original.orderDetails.map((item, index) => (
                            <Fragment
                              key={`picking-products-${row.original.id}-${item.id}`}
                            >
                              <tr key={item.id}>
                                <td className="px-3 py-4 text-sm lg:pl-0">
                                  {index + 1}
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm sm:w-auto sm:max-w-none">
                                  <div className="flex items-center">
                                    <div className="h-11 w-11 flex-shrink-0 overflow-hidden rounded-md">
                                      {item.variant.variantImageUrl ? (
                                        <img
                                          className={classNames(
                                            "h-full w-full object-cover"
                                          )}
                                          src={item.variant.variantImageUrl}
                                          alt={item.variant?.product?.name}
                                        />
                                      ) : (
                                        <img
                                          className={classNames(
                                            "h-full w-full object-cover"
                                          )}
                                          src={
                                            item.variant?.product
                                              ?.featureImageUrl
                                              ? item.variant.product
                                                  .featureImageUrl
                                              : placeholder
                                          }
                                          alt={item.variant?.product?.name}
                                        />
                                      )}
                                    </div>
                                    <div className="ml-4">
                                      <div className="font-normal text-gray-900">
                                        {item.variant?.product?.name}
                                      </div>
                                    </div>
                                  </div>

                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">Stock Code</dt>
                                    <dd className="mt-1 truncate">
                                      {item.variant.stockCode}
                                    </dd>
                                    <dt className="sr-only">Variant</dt>
                                    <dd className="mt-1 truncate">
                                      {formatVariantTitle(
                                        item.variant.variantTitle
                                      )}
                                    </dd>
                                    <dt className="sr-only">Department</dt>
                                    <dd className="mt-1 truncate">
                                      {item.variant.product?.department?.name}
                                    </dd>
                                    <dt className="sr-only">Bin Location</dt>
                                    <dd className="mt-1 truncate">
                                      {item.variant.product?.binLocation?.name}
                                    </dd>
                                    <dt className="sr-only sm:hidden">
                                      Quantity
                                    </dt>
                                    <dd className="mt-1 truncate sm:hidden">
                                      {item.quantity}
                                    </dd>
                                  </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                  {item.variant.stockCode}
                                </td>
                                <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                  {formatVariantTitle(
                                    item.variant.variantTitle
                                  )}
                                </td>
                                <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                  {item.variant.product?.department?.name}
                                </td>
                                <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                  {item.variant.product?.binLocation?.name}
                                </td>
                                <td className="hidden px-3 py-4 text-center text-sm sm:table-cell">
                                  <span className="inline-block rounded-md border border-gray-900/30 px-4 py-2">
                                    {item.quantity}
                                  </span>
                                </td>
                                <td className="px-3 py-4 text-center text-sm">
                                  {formatCurrency(item.price)}
                                </td>
                                <td className="px-3 py-4 text-center text-sm">
                                  {renderPickingStatus(item.pickingStatus)}
                                </td>
                                <td className="px-3 py-4 text-left text-sm">
                                  <FieldStatus
                                    value={item.pickingStatus}
                                    onChange={(
                                      value: SingleValue<OptionProps>
                                    ) => {
                                      if (!value) return;
                                      if (
                                        parseInt(value.value) ===
                                        item.pickingStatus
                                      )
                                        return;
                                      handleProductStatus({
                                        id: item.id,
                                        pickingStatus: parseInt(value.value),
                                      });
                                    }}
                                    className={classNames(
                                      "rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                                    )}
                                    disabled={
                                      loadingPickingProductStatus ||
                                      item.pickingStatus ===
                                        PickingStatus.PICKED ||
                                      !item.pickerId
                                    }
                                  />
                                  {isAdministrator(currentRole) &&
                                  item.picker ? (
                                    <Link
                                      to={`/user-management/users/${item.picker.id}`}
                                      target="_blank"
                                      className="mt-2 inline-flex items-center rounded-full bg-primary-700 pl-1.5 pr-2 text-xs font-normal leading-6 text-white transition hover:bg-primary-800 hover:text-white"
                                    >
                                      <span className="mr-1 h-3 w-3 rounded-full bg-white"></span>
                                      {item.picker.fullName}
                                    </Link>
                                  ) : null}
                                </td>
                              </tr>
                              {item.packingComments ? (
                                <tr>
                                  <td
                                    colSpan={6}
                                    className="w-full px-4 py-4 text-sm sm:px-0"
                                  >
                                    <div className="flex bg-white/50">
                                      <div className="bg-black px-4 py-2 text-white md:px-6 md:py-3 xl:px-8 xl:py-4">
                                        Comments
                                      </div>
                                      <div className="px-4 py-2 md:px-6 md:py-3 xl:px-8 xl:py-4">
                                        {item.packingComments ??
                                          "No comments found for item"}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ) : null}
                            </Fragment>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              );
            }}
            enableAction
            renderAction={(table) => (
              <div className="flex justify-end">
                <Button
                  variant="icon"
                  onClick={handleRefetch}
                  disabled={loading}
                  className="flex h-11 items-center justify-center px-2 text-blue-700"
                >
                  <ArrowPathIcon
                    aria-hidden="true"
                    className={classNames(
                      "h-5 w-5",
                      refetching ? "animate-spin" : ""
                    )}
                  />
                  <span className="sr-only">Refresh picking list</span>
                </Button>
              </div>
            )}
            enableNavigation
            renderNavigation={(table) => (
              <Button
                variant="icon"
                onClick={() => {
                  fetchNextPage(table);
                }}
                disabled={!data?.fetchPickerWorklist?.pageInfo?.hasNextPage}
                loading={isMorefetching}
                className="flex h-8 w-8 items-center justify-center rounded-md bg-primary-300 text-black disabled:bg-gray-200"
              >
                <span className="sr-only"> Go to next page</span>
                <span className="bi bi-chevron-bar-right"></span>
              </Button>
            )}
            fallbackTitle="No picking products found"
            fallbackText="Please create a order and move it to packing."
          />
        )}
      </div>
    </>
  );
};
export default PickerWorkerlistList;
export const PickerWorkerlistListResource: ResourceProps = {
  name: "Picker Worker List",
  description: "A list of products that are ready to be picked",
  access: ["read-pickerworkerlists"],
  path: "picker-workerlist",
};
