import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  ADD_RESOURCE,
  EDIT_RESOURCE,
  GET_RESOURCES,
  REMOVE_RESOURCE,
} from "../../../graphql/user-management/resources";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { formatDate } from "../../../utils";
import { AuthShield } from "../../auth/core";
import Form from "./components/Form";

type Resource = {
  id: string;
  resourceName: string;
  resourceDescription: string;
  createdAt: string;
  status: boolean;
};

const ResourceList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery<{
    fetchResources: Resource[];
  }>(GET_RESOURCES);
  const resources = useMemo(
    () =>
      data?.fetchResources.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data?.fetchResources]
  );

  const [activeResource, setActiveResource] = useState<Resource | undefined>(
    undefined
  );
  const [newResource, setNewResource] = useState<boolean | undefined>(
    undefined
  );

  const [updateResource] = useMutation(EDIT_RESOURCE, {
    refetchQueries: [{ query: GET_RESOURCES }],
  });
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateResource({
      variables: {
        id: activeResource?.id,
        resourceName: values.resourceName,
        resourceDescription: values.resourceDescription,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.resourceUpdate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Resource updated successfully",
          });
          setActiveResource(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Resource update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Resource update failed",
          message: error.message,
        });
      });
  };

  const [createResource] = useMutation(ADD_RESOURCE, {
    refetchQueries: [{ query: GET_RESOURCES }],
  });
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createResource({
      variables: {
        resourceName: values.resourceName,
        resourceDescription: values.resourceDescription,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.resourceCreate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Resource created successfully",
          });
          setNewResource(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Resource create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Resource create failed",
          message: error.message,
        });
      });
  };

  const [deleteResource] = useMutation(REMOVE_RESOURCE, {
    refetchQueries: [{ query: GET_RESOURCES }],
  });
  const handleDelete = useCallback(
    async (id: string) => {
      deleteResource({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data?.resourceDelete) {
            const deletedResource = data?.resourceDelete?.resource;
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: `Resource ${deletedResource.resourceName} deleted`,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Resource delete failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Resource delete failed",
            message: error.message,
          });
        });
    },
    [addNotify, deleteResource, refetch]
  );

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<Resource, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "resourceName",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.resourceName,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-resources"]}>
              <Button
                onClick={() => setActiveResource(props.row.original)}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.resourceName}
                </span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-resources"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.resourceName,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.resourceName}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Resources"
        heading="Resources"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ResourceListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {ResourceListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {ResourceListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-resources"]}>
              <Button onClick={() => setNewResource(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add resource
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={resources}
          columns={columns}
          loading={loading}
          totalRows={resources.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No resources found"
          fallbackText="You can add a new resource by clicking on add resource button."
        />
      </div>

      <Transition.Root
        show={activeResource ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveResource(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Resource - "
                  initialValues={{
                    resourceName: activeResource?.resourceName ?? "",
                    resourceDescription:
                      activeResource?.resourceDescription ?? "",
                    status: activeResource?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveResource(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newResource ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewResource(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Resource - "
                  initialValues={{
                    resourceName: "",
                    resourceDescription: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewResource(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* <Transition.Root show={activeResource ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveResource(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="Edit Resource - "
                      initialValues={{
                        resourceName: activeResource?.resourceName ?? "",
                        resourceDescription:
                          activeResource?.resourceDescription ?? "",
                        status: activeResource?.status ?? true,
                      }}
                      onSubmit={handleUpdate}
                      submitLabel={t("text_update")}
                      onCancel={() => setActiveResource(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}

      {/* <Transition.Root show={newResource ? true : false} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewResource(undefined);
          }}
        >
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                    <Form
                      heading="New Resource - "
                      initialValues={{
                        resourceName: "",
                        resourceDescription: "",
                        status: true,
                      }}
                      onSubmit={handleCreate}
                      submitLabel={t("text_create")}
                      onCancel={() => setNewResource(undefined)}
                      cancelLabel={t("text_cancel")}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
};

export default ResourceList;
export const ResourceListResource: ResourceProps = {
  name: "Resources",
  description: "A list of resources",
  access: ["create-resources", "read-resources"],
  path: "resources",
};
