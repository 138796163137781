import { useMutation, useQuery } from "@apollo/client/react";
import {
  ArrowPathIcon,
  InboxIcon,
  MapPinIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  GET_LOCATIONS,
  type Location,
  REMOVE_LOCATION,
} from "../../../graphql/inventory/locations";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { classNames, formatDate, toNestedTable } from "../../../utils";
import { AuthShield } from "../../auth/core";

type RowLocation = Location & {
  subRows: RowLocation[];
};

const LocationList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { data, loading, error, refetch } = useQuery(GET_LOCATIONS);
  const locations: RowLocation[] = useMemo<RowLocation[]>(() => {
    if (data?.fetchBinLocations) {
      const fetchedBinLocations: Location[] = data.fetchBinLocations.flatMap(
        (item: Location) => ({
          ...item,
          createdAt: formatDate(item.createdAt),
        })
      );
      return toNestedTable(fetchedBinLocations) as RowLocation[];
    }
    return [];
  }, [data]);

  const [deleteLocation] = useMutation(REMOVE_LOCATION, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
      },
    ],
  });
  const handleDelete = async (id: string) => {
    return await deleteLocation({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.binLocationDelete) {
          const deletedLocation = data.binLocationDelete?.location;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Location ${deletedLocation.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Location delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<RowLocation, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "branch",
        header: "Branch",
      },
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-binlocations"]}>
              <Button
                type="link"
                variant="icon"
                href={`/inventory/locations/${props.row.original.id}`}
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-binlocations"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
      },
    ],
    []
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Locations"
        heading="Locations"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Locations",
            href: "/inventory/locations",
          },
          {
            name: LocationListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {LocationListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {LocationListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-categories"]}>
              <Button type="link" href="/inventory/locations/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add location
              </Button>
            </AuthShield>
            <AuthShield access={["read-binlocationtypes"]}>
              <Button
                type="link"
                variant="info"
                border
                href="/inventory/location-types"
              >
                <MapPinIcon className="mr-2 h-5 w-5" />
                Location types
              </Button>
            </AuthShield>
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh location list</span>
            </Button>
          </div>
        </div>

        <TableCursor
          data={locations}
          columns={columns}
          loading={loading}
          totalRows={locations.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          state={{
            expanded,
          }}
          onExpandedChange={setExpanded}
          getExpandedRowModel={getExpandedRowModel()}
          enableRowSelection={false}
          getSubRows={(row) => row.subRows}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No locations found"
          fallbackText="You can add a new location by clicking on add location button."
        />
      </div>
    </>
  );
};

export default LocationList;
export const LocationListResource: ResourceProps = {
  name: "Locations",
  description: "A list of locations in the system",
  access: ["read-binlocations"],
  path: "locations",
};
