import { gql, useMutation } from "@apollo/client";
import { Dialog, Switch, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  InformationCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { evaluate } from "mathjs";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";

import { Spinner, SpinnerInline } from "../../animations";
import placeholder from "../../assets/placeholder.svg";
import {
  type Details,
  type PricingMethod,
  type PricingMethodField,
  type Product,
  type ProductPricing,
  ProductPricingStatus,
  type Variant,
} from "../../graphql/inventory/pricing/special-prices/pricing";
import {
  classNames,
  formatAmount,
  formatFloat,
  formatVariantTitle,
} from "../../utils";
import { Button, Field } from "./";

type FieldWithValue = PricingMethodField & {
  fieldValue: string;
};

type IFormatedFormula = {
  key: string;
  value: string;
};

export function FieldSpecialPriceCalculator({
  product,
  removeProduct,
  pricingMethod,
  className,
  productPricing,
  setProductPricing,
}: {
  product: Product;
  removeProduct: (id: string) => void;
  pricingMethod: PricingMethod;
  className?: string;
  productPricing: ProductPricing[];
  setProductPricing: Dispatch<SetStateAction<ProductPricing[]>>;
}) {
  const { variants } = product;
  const [showDecimal, setShowDecimal] = useState<boolean>(true);

  return (
    <div
      className={classNames(
        "flex divide-x rounded-md border border-gray-100 bg-white p-4",
        className ?? ""
      )}
    >
      <div className="flex w-48 max-w-full flex-col pr-4">
        <div className="mt-2">
          <div className="h-16 w-16">
            <img
              className="h-16 w-16 rounded-md"
              src={
                product.featureImageUrl ? product.featureImageUrl : placeholder
              }
              alt={product.name}
            />
          </div>
          <div className="mt-4">
            <Link
              to={`/inventory/products/${product.id}`}
              target="_blank"
              className="overflow-ellipsis text-sm font-normal text-gray-900"
            >
              {product.name}
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full pl-6">
        <div className="mb-4 flex justify-end space-x-4">
          <Button
            variant="icon"
            onClick={() => {
              removeProduct(product.id);
            }}
            className="rounded-md border-0 bg-transparent p-2 text-sm text-gray-700 transition-all hover:bg-white hover:text-gray-800"
          >
            <TrashIcon aria-hidden="true" className="h-5 w-5" />
            <span className="sr-only">Remove product, {product.name}</span>
          </Button>
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={showDecimal}
              onChange={setShowDecimal}
              className={classNames(
                showDecimal ? "bg-blue-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showDecimal ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 text-sm">
              <span className="font-medium text-gray-900">Formatted Price</span>
            </Switch.Label>
          </Switch.Group>
        </div>
        <div className="divide-y divide-gray-100">
          {variants.map((variant) => (
            <ProductVariant
              key={variant.id}
              variant={variant}
              pricingMethod={pricingMethod}
              productPricing={productPricing}
              setProductPricing={setProductPricing}
              showDecimal={showDecimal}
              product={product}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const ProductVariant = ({
  variant,
  pricingMethod,
  productPricing,
  setProductPricing,
  showDecimal,
  product,
}: {
  variant: Variant;
  pricingMethod: PricingMethod;
  productPricing: ProductPricing[];
  setProductPricing: Dispatch<SetStateAction<ProductPricing[]>>;
  showDecimal: boolean;
  product: Product;
}) => {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { specialPriceId } = useParams();
  const [isRefetchCost, setIsRefetchCost] = useState<boolean>(false);

  const [priceFields, setPriceFields] = useState<FieldWithValue[]>([]);
  const [formatedFormula, setFormatedFormula] = useState<IFormatedFormula[]>(
    []
  );
  const [sellPrice, setSellPrice] = useState<number>(0);
  const [pricingId, setPricingId] = useState<string | undefined>(undefined);

  const didInitialLoad = useRef(false);
  const initialLoading = useCallback(() => {
    if (!specialPriceId) return;

    if (pricingMethod) {
      let specialPriceDetails: Details;
      if (
        variant.specialPriceDetails &&
        variant.specialPriceDetails.length > 0
      ) {
        specialPriceDetails = variant.specialPriceDetails[0];
        if (specialPriceDetails) {
          setPricingId(specialPriceDetails.id);
          setSellPrice(formatFloat(specialPriceDetails.sellPrice));
        } else {
          setSellPrice(0);
          setPricingId(undefined);
        }
      } else {
        setSellPrice(0);
        setPricingId(undefined);
      }

      if (pricingMethod?.pricingMethodFields) {
        const updatedPriceFields = pricingMethod.pricingMethodFields?.map(
          (field: PricingMethodField) => {
            let updatedValue =
              field.fieldKey.toLowerCase() === "$cost"
                ? `${variant.cost ? variant.cost : ""}`
                : "";
            if (specialPriceDetails) {
              let findValue = specialPriceDetails.priceFields.find(
                (pf: any) => pf.fieldKey === field.fieldKey
              );
              if (findValue) {
                updatedValue = findValue.fieldValue;
              }
            }

            return {
              ...field,
              fieldValue: updatedValue,
            };
          }
        );
        setPriceFields(updatedPriceFields);
      } else {
        setPriceFields([]);
      }

      const updatedFormatedFormula = pricingMethod?.sellPriceFormula?.map(
        (formula: string) => {
          let updatedValue = "";
          if (/^\$[a-zA-Z0-9]+/.test(formula)) {
            if (specialPriceDetails) {
              let findValue = specialPriceDetails.priceFields.find(
                (pf: any) => pf.fieldKey === formula
              );
              if (findValue) {
                updatedValue = findValue.fieldValue;
              }
            }
          } else {
            updatedValue = formula;
          }

          return {
            key: formula,
            value: updatedValue,
          };
        }
      );
      setFormatedFormula(updatedFormatedFormula);
    } else {
      setSellPrice(0);
      setPricingId(undefined);
      setPriceFields([]);
      setFormatedFormula([]);
    }
  }, [
    pricingMethod,
    specialPriceId,
    variant.cost,
    variant.specialPriceDetails,
  ]);

  useEffect(() => {
    if (!didInitialLoad.current) {
      initialLoading();
      didInitialLoad.current = true;
    }
  }, [initialLoading]);

  const handleCalculate = useCallback(
    (formatedFormula: IFormatedFormula[], priceFields: any[]) => {
      if (!specialPriceId) return;
      if (formatedFormula.length > 0) {
        if (formatedFormula.flatMap((f) => f.value).some((v) => v === ""))
          return setSellPrice(0);

        let updatedPrice: number = evaluate(
          formatedFormula.flatMap((ff) => ff.value).join(" ")
        );

        const isMinimumMarkup = pricingMethod?.pricingMethodFields?.some(
          (field) => field.fieldKey === "$minimumMarkup"
        );
        const isMaximumMarkup = pricingMethod?.pricingMethodFields?.some(
          (field) => field.fieldKey === "$maximumMarkup"
        );
        const cost = priceFields.find(
          (pf: any) => pf.fieldKey.toLowerCase() === "$cost"
        );

        if (isMinimumMarkup && cost) {
          const minimumMarkup = priceFields.find(
            (pf: any) => pf.fieldKey === "$minimumMarkup"
          );
          const costValue = parseFloat(cost.fieldValue);

          if (minimumMarkup) {
            if (
              updatedPrice - costValue <
              parseFloat(minimumMarkup.fieldValue)
            ) {
              updatedPrice = costValue + parseFloat(minimumMarkup.fieldValue);
            }
          }
        }

        if (isMaximumMarkup && cost) {
          const maximumMarkup = priceFields.find(
            (pf: any) => pf.fieldKey === "$maximumMarkup"
          );
          const costValue = parseFloat(cost.fieldValue);

          if (maximumMarkup) {
            if (
              updatedPrice - costValue >
              parseFloat(maximumMarkup.fieldValue)
            ) {
              updatedPrice = costValue + parseFloat(maximumMarkup.fieldValue);
            }
          }
        }

        let newPrice = formatAmount(updatedPrice);
        setSellPrice(updatedPrice);

        const updatedProductPrice: ProductPricing = {
          id: `${variant.productId}-${variant.id}`,
          pricingId,
          specialPriceId: parseInt(specialPriceId),
          productId: variant.productId,
          productSkuId: parseInt(variant.id),
          priceFields: priceFields.map((pf) => ({
            fieldType: pf.fieldType,
            fieldKey: pf.fieldKey,
            fieldValue: pf.fieldValue,
          })),
          sellPrice: newPrice,
          status: ProductPricingStatus.pending,
        };

        const updatedProductPricing = [...productPricing];
        const index = updatedProductPricing.findIndex(
          (pp) => pp.id === updatedProductPrice.id
        );
        if (index > -1) {
          updatedProductPricing[index] = updatedProductPrice;
        } else {
          updatedProductPricing.push(updatedProductPrice);
        }
        setProductPricing(updatedProductPricing);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      specialPriceId,
      pricingMethod?.pricingMethodFields,
      variant.productId,
      variant.id,
      pricingId,
      productPricing,
      setProductPricing,
    ]
  );

  const renderStatus = useCallback(() => {
    const findStatus = productPricing.find(
      (pp) => pp.id === `${variant.productId}-${variant.id}`
    );

    switch (findStatus?.status) {
      case ProductPricingStatus.pending:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full border border-gray-200 bg-white">
            <ExclamationTriangleIcon
              className="h-5 w-5 -rotate-45 text-yellow-400"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.success:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full border border-gray-200 bg-white">
            <CheckIcon
              className="h-5 w-5 -rotate-45 text-green-500"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.error:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full border border-gray-200 bg-white">
            <XMarkIcon
              className="h-5 w-5 -rotate-45 text-red-500"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.loading:
        return (
          <div className="flex h-8 w-8 items-center justify-center rounded-full border border-gray-200 bg-white">
            <SpinnerInline className="text-green-500" />
          </div>
        );

      default:
        return null;
    }
  }, [productPricing, variant]);

  const handleRefetchCost = useCallback(() => {
    setIsRefetchCost(true);
    try {
      if (!variant.cost) return;
      const costKey = "$cost";
      const costField = priceFields.find(
        (field) => field.fieldKey.toLowerCase() === costKey
      );
      if (!costField) return;
      if (costField.fieldValue === String(variant.cost)) return;

      const updatedPriceFields = priceFields.map((field) => {
        if (field.fieldKey.toLowerCase() === costKey) {
          return {
            ...field,
            fieldValue: `${variant.cost ? variant.cost : field.fieldValue}`,
          };
        }
        return field;
      });
      setPriceFields(updatedPriceFields);

      const updatedFormatedFormula = formatedFormula.map((f) => {
        if (f.key.toLowerCase() === "$cost") {
          return {
            ...f,
            value: `${variant.cost ? variant.cost : f.value}`,
          };
        }
        return f;
      });
      setFormatedFormula(updatedFormatedFormula);
      handleCalculate(updatedFormatedFormula, updatedPriceFields);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setIsRefetchCost(false);
      }, 250);
    }
  }, [formatedFormula, handleCalculate, priceFields, variant.cost]);

  useEffect(() => {
    if (variant.specialPriceDetails?.some((spd) => spd.costUpdated))
      handleRefetchCost();
  }, [handleRefetchCost, variant.specialPriceDetails]);

  const handleFieldChange = useCallback(
    (key: string, value: string) => {
      const updatedPriceFields = priceFields.map((field) => {
        if (field.fieldKey === key) {
          return {
            ...field,
            fieldValue: value,
          };
        } else if (field.fieldKey.toLowerCase() === "$cost") {
          return {
            ...field,
            fieldValue: `${field.fieldValue ? field.fieldValue : variant.cost}`,
          };
        }
        return field;
      });
      setPriceFields(updatedPriceFields);

      const updatedFormatedFormula = formatedFormula.map((f) => {
        if (f.key === key) {
          return {
            ...f,
            value,
          };
        } else if (f.key.toLowerCase() === "$cost") {
          return {
            ...f,
            value: `${f.value ? f.value : variant.cost}`,
          };
        }
        return f;
      });

      setFormatedFormula(updatedFormatedFormula);
      handleCalculate(updatedFormatedFormula, updatedPriceFields);
    },
    [formatedFormula, handleCalculate, priceFields, variant.cost]
  );

  const renderVariantTitle = useCallback(() => {
    const variantTypes: {
      [key: string]: string;
    } = {};
    variant.variantTitle.forEach((title) => {
      variantTypes[title.option.name] = title.name;
    });

    if (!Object.keys(variantTypes).length) return null;

    return (
      <div className="px-2 py-1">
        <span className="mb-1 block text-sm font-medium text-gray-900">
          {t("text_variant")}
        </span>
        {Object.keys(variantTypes).map((key) => {
          return (
            <div key={key} className="text-sm text-gray-500">
              {key}: {variantTypes[key]}
            </div>
          );
        })}
      </div>
    );
  }, [t, variant.variantTitle]);

  const renderProfit: () => JSX.Element | null = useCallback(() => {
    const method = pricingMethod.handle;
    console.log("method", method);

    if (
      (method !== "gross-profit-method" && method !== "fixed-markup-method") ||
      !sellPrice
    )
      return null;

    let profit = 0;
    if (method === "gross-profit-method") {
      let cost = variant.cost;
      const costField = priceFields.find(
        (pf) => pf.fieldKey.toLowerCase() === "$cost"
      );

      if (costField) cost = parseFloat(costField.fieldValue);
      if (!cost) return null;

      profit = sellPrice - cost;
    }

    if (method === "fixed-markup-method") {
      let markup = 0;
      const markupField = priceFields.find(
        (pf) => pf.fieldKey.toLowerCase() === "$markup"
      );
      if (markupField) markup = parseFloat(markupField.fieldValue);
      if (!markup) return null;

      profit = (markup / sellPrice) * 100;
    }

    return (
      <div className="ml-2 text-right">
        <Field
          title={t("text_profit")}
          value={showDecimal ? formatAmount(profit) : formatFloat(profit, 3)}
          className="max-w-[5rem] border-0 text-right"
          disabled
        />
      </div>
    );
  }, [
    priceFields,
    pricingMethod.handle,
    sellPrice,
    showDecimal,
    t,
    variant.cost,
  ]);

  const renderPrice: () => JSX.Element | null = useCallback(() => {
    return (
      <div>
        <Field
          title={t("text_sell_price")}
          value={
            showDecimal ? formatAmount(sellPrice) : formatFloat(sellPrice, 3)
          }
          disabled
          className="max-w-[6rem] border-0 text-right"
        />
      </div>
    );
  }, [sellPrice, showDecimal, t]);

  const VariantSchema = Yup.object().shape({
    cost: Yup.number().nullable().min(0),
  });

  const UPDATE_VARIANT = gql`
    mutation VariantUpdate($id: ID!, $cost: Float!) {
      variantUpdate(input: { id: $id, cost: $cost }) {
        variant {
          id
        }
      }
    }
  `;

  const [response, setResponse] = useState<{
    type: "success" | "error" | null;
    title: string;
    message: string;
  } | null>(null);
  const [updateVariant] = useMutation(UPDATE_VARIANT);

  const formik = useFormik({
    initialValues: {
      cost: variant.cost ?? 0,
    },
    enableReinitialize: true,
    validationSchema: VariantSchema,
    onSubmit: (values: { cost: number }, actions) => {
      updateVariant({
        variables: {
          id: variant.id,
          cost: values.cost,
        },
      })
        .then(({ data }) => {
          if (data?.variantUpdate?.variant) {
            setResponse({
              type: "success",
              title: "Cost update is in progress!",
              message:
                "It will take some time to update the cost on the pricing page.",
            });
          } else {
            setResponse({
              type: "error",
              title: "Error!",
              message: "Failed to update the cost.",
            });
          }
        })
        .catch((error) => {
          setResponse({
            type: "error",
            title: "Error!",
            message: error.message,
          });
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const variantTitle = useMemo(
    () => formatVariantTitle(variant.variantTitle),
    [variant.variantTitle]
  );

  const isCost = priceFields.find(
    (field) => field.fieldKey.toLowerCase() === "$cost"
  );
  const isCostValid = isCost?.fieldValue && parseFloat(isCost.fieldValue) > 0;

  return priceFields?.length ? (
    <Fragment>
      <div className="relative flex w-full flex-wrap items-start py-2 pr-6 xl:flex-nowrap">
        <div className="px-2 py-1">
          <Button
            type="button"
            border
            onClick={() => {
              setModalOpen(true);
            }}
            className="px-2"
          >
            <PencilIcon aria-hidden="true" className="h-5 w-5" />
            <span className="sr-only">Edit variant, {variant.stockCode}</span>
          </Button>
        </div>
        {renderVariantTitle()}
        <div className="px-2 py-1">
          <Field
            title={t("text_stock_code")}
            value={variant.stockCode}
            disabled
            className="max-w-[7rem] border-0"
          />
        </div>
        <div className="flex flex-wrap items-start">
          {priceFields.map((f) => {
            const isFieldCost = f.fieldKey.toLowerCase() === "$cost";
            return (
              <div
                key={`${variant.id}-${f.fieldKey}`}
                className="relative mb-2 px-2 py-1"
              >
                <Field
                  title={t(f.fieldName)}
                  name={f.fieldKey}
                  type={f.fieldType === "percentage" ? "text" : f.fieldType}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { name, value } = e.target;
                    if (value === "") return handleFieldChange(name, value);
                    if (f.fieldType === "number") {
                      if (!isNaN(parseFloat(value))) {
                        handleFieldChange(name, value);
                      } else {
                        handleFieldChange(name, "");
                      }
                    } else if (f.fieldType === "percentage") {
                      if (!isNaN(parseFloat(value))) {
                        const updateValue = value.replace(/[^0-9.]/g, "");
                        handleFieldChange(name, `${updateValue}%`);
                      } else {
                        handleFieldChange(name, "");
                      }
                    } else {
                      if (!isNaN(parseFloat(value))) {
                        handleFieldChange(name, value);
                      } else {
                        handleFieldChange(name, "");
                      }
                    }
                  }}
                  value={
                    f.fieldType === "percentage"
                      ? f.fieldValue.replace(/[^0-9.]/g, "")
                      : f.fieldValue
                  }
                  className={classNames("max-w-[7rem]")}
                  disabled={isCost && isFieldCost && !isCostValid}
                />
              </div>
            );
          })}
        </div>

        <div className="ml-auto flex items-center py-1">
          {renderPrice()}
          {renderProfit()}
        </div>
        <div className="absolute inset-y-0 -left-10 m-auto h-5 w-5">
          {renderStatus()}
        </div>
      </div>

      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <Dialog.Title className="sr-only">
                    {product.name} - {variant.stockCode}
                  </Dialog.Title>
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      onClick={() => setModalOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="flex items-center">
                      <div className="relative mr-4 h-16 w-16 flex-shrink-0 overflow-hidden rounded-md border border-gray-300 md:h-24 md:w-24 xl:h-32 xl:w-32">
                        <img
                          className="absolute h-full w-full object-cover"
                          src={
                            product.featureImageUrl
                              ? product.featureImageUrl
                              : placeholder
                          }
                          alt={product.name}
                        />
                      </div>
                      <div className="flex flex-1 flex-col space-y-4">
                        <div>
                          <strong className="block text-sm text-primary-900">
                            Name
                          </strong>
                          <Link
                            to={`/inventory/products/${product.id}`}
                            target="_blank"
                            className="overflow-ellipsis text-sm font-normal text-gray-900"
                          >
                            {product.name}
                          </Link>
                        </div>
                        {variantTitle ? (
                          <div>
                            <strong className="block text-sm text-primary-900">
                              Variant
                            </strong>
                            <span className="overflow-ellipsis text-sm font-normal text-gray-900">
                              {formatVariantTitle(variant.variantTitle)}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        <strong className="mb-1 block text-sm text-primary-900">
                          Cost
                        </strong>
                        <Field
                          title={t("text_cost")}
                          name="cost"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.cost}
                          touched={formik.touched.cost}
                          errors={formik.errors.cost}
                          isLabel={false}
                          placeholder={t("text_cost")}
                        />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <Button
                        border
                        onClick={() => setModalOpen(false)}
                        ref={cancelButtonRef}
                      >
                        {response ? "Close" : "Cancel"}
                      </Button>
                      <Button type="submit" disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? (
                          <Fragment>
                            <Spinner />
                            {t("text_processing")}
                          </Fragment>
                        ) : (
                          "Update"
                        )}
                      </Button>
                    </div>
                  </form>
                  {response && (
                    <div
                      className={classNames(
                        "mt-4 rounded-md p-4 md:mt-6",
                        response.type === "success" ? "bg-blue-50" : "bg-red-50"
                      )}
                    >
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            className={classNames(
                              "h-5 w-5",
                              response.type === "success"
                                ? "text-blue-400"
                                : "text-red-400"
                            )}
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3
                            className={classNames(
                              "text-sm font-medium",
                              response.type === "success"
                                ? "text-blue-800"
                                : "text-red-800"
                            )}
                          >
                            {response.title}
                          </h3>
                          <div
                            className={classNames(
                              "mt-2 text-sm",
                              response.type === "success"
                                ? "text-blue-700"
                                : "text-red-700"
                            )}
                          >
                            <p>{response.message}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  ) : (
    <p className="font-medium text-gray-900">No price fields found</p>
  );
};
