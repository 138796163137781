import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldVehicle,
} from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleMeterEntryForm } from "../../../../graphql/fleets/vehicles/pages/meter-entry";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { classNames, useFormikErrors } from "../../../../utils";

export default function Form({
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  vehicleBasic: vehicleBasicProp,
}: {
  initialValues: VehicleMeterEntryForm;
  onSubmit: (values: VehicleMeterEntryForm, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  vehicleBasic: VehicleBasic | null;
}) {
  const { vehicleId } = useParams();
  const { t } = useTranslation();

  const [vehicleBasic, setVehicleBasic] = useState<VehicleBasic | null>(null);
  useEffect(() => {
    if (vehicleId === undefined) return;
    setVehicleBasic(vehicleBasicProp);
  }, [vehicleBasicProp, vehicleId]);

  const ValidationSchema = Yup.object().shape({
    vehicleId: Yup.number().when(".", {
      is: (val: unknown) => vehicleId === undefined,
      then: Yup.number().typeError("Must be a number").required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    meterDate: Yup.string().required("Required"),
    primaryMeter: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          {vehicleId === undefined ? (
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_vehicle")}
              </label>
              <FieldVehicle
                value={formik.values.vehicleId}
                onChange={(value) => {
                  formik.setFieldValue("vehicleId", value ? value.id : null);
                  setVehicleBasic(value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.vehicleId && formik.errors.vehicleId
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.vehicleId && formik.errors.vehicleId ? (
                <p className="mt-2 text-sm text-red-600" id="vehicleId-errors">
                  {formik.errors.vehicleId}
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="sm:col-span-3">
            <FieldDatepicker
              title={t("text_meter_date")}
              name="meterDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "meterDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.meterDate
                  ? new Date(formik.values.meterDate)
                  : null
              }
              touched={formik.touched.meterDate}
              errors={formik.errors.meterDate}
            />
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t("text_primary_meter")}
              name="primaryMeter"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.primaryMeter ?? ""}
              touched={formik.touched.primaryMeter}
              errors={formik.errors.primaryMeter}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
        </div>
        <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={onCancel}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <Fragment>
                <Spinner />
                {t("text_processing")}
              </Fragment>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
