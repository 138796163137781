import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

import {
  DeliveryRun,
  GET_DELIVERYRUNS,
} from "../../graphql/fleets/settings/delivery-runs";
import { selectStyles, SelectWrapper } from ".";

type SelectProps = React.ComponentProps<typeof Select>;
type SelectOptionalProps = Omit<SelectProps, "onChange" | "value">;
type FieldVehicleProps = SelectOptionalProps & {
  value: number | null;
  onChange: (newValue: DeliveryRun | null) => void;
};

export function FieldDeliveryRun({
  value,
  onChange,
  className,
  ...props
}: FieldVehicleProps) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const [deliveryRuns, setDeliveryRuns] = useState<DeliveryRun[]>([]);

  const { data, loading, refetch } = useQuery<{
    fetchDeliveryRuns: DeliveryRun[];
  }>(GET_DELIVERYRUNS);

  const options: MultiValue<OptionProps> = useMemo(() => {
    const updatedDeliveryRuns = data?.fetchDeliveryRuns?.length
      ? data.fetchDeliveryRuns
      : [];
    setDeliveryRuns(updatedDeliveryRuns);
    const updatedValues = updatedDeliveryRuns.map((p) => {
      return {
        label: p.name,
        value: p.id.toString(),
      };
    });
    return updatedValues;
  }, [data?.fetchDeliveryRuns]);

  useEffect(() => {
    if (!value) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps> | unknown) => {
    onChange(
      newValue
        ? deliveryRuns.find((p) => p.id === (newValue as OptionProps).value) ??
            null
        : null
    );
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
        {...props}
      />
    </SelectWrapper>
  );
}
