import { gql } from "@apollo/client";
import { Layout } from "react-grid-layout";

import { UserModel } from "../../modules/auth/@types/auth";

export interface Layouts {
  [P: string]: Layout[];
}
export interface LayoutSlot {
  [P: string]: Layout;
}

export const GET_DASHBOARD = gql`
  query FetchDashboard {
    fetchDashboard {
      createdAt
      handle
      id
      name
      position
      slot
      status
      updatedAt
      blocks {
        id
        name
        handle
        position
        status
      }
    }
  }
`;

// export const GET_WIDGETS = gql`
//   query FetchWidgets {
//     fetchWidgets {
//       createdAt
//       handle
//       id
//       name
//       position
//       slot
//       status
//       updatedAt
//     }
//   }
// `;

export const EDIT_USER_WIDGET = gql`
  mutation UserWidgetUpdate($params: [WidgetInput!]!) {
    userWidgetUpdate(input: { params: $params }) {
      message
    }
  }
`;

export type WidgetBlock = {
  id: string;
  name: string;
  handle: string;
  position: number;
  status: boolean;
};

export type Widget = {
  id: string;
  blocks: WidgetBlock[];
  handle: string;
  name: string;
  position: number;
  slot: LayoutSlot;
  createdAt: string;
  updatedAt: string;
  status: boolean;
};

export interface InitialWidget extends Omit<Widget, "slot"> {
  slot: Layouts;
}

export const GET_SALES_ACTIVITY_WIDGET = gql`
  query fetchSalesActivityWidget($id: ID!) {
    fetchSalesActivityWidget(id: $id) {
      id
      blockData
      unit
      handle
      name
      position
      status
    }
  }
`;

export type SalesActivityWidget = {
  id: string;
  blockData: string;
  unit: "quantity" | "currency";
  handle: string;
  name: string;
  position: number;
  status: boolean;
};

export const GET_RECENT_ACTIVITY_WIDGET = gql`
  query fetchRecentActivityWidget {
    fetchRecentActivityWidget {
      id
      createdAt
      event
      eventDescription
      updatedAt
      user {
        id
        fullName
        firstName
        lastName
      }
    }
  }
`;

export type RecentActivityWidget = {
  id: string;
  createdAt: string;
  event: string;
  eventDescription: string;
  updatedAt: string;
  user: UserModel;
};

export const GET_SALES_REVENUE_WIDGET = gql`
  query FetchSalesRevenueWidget($year: Int) {
    fetchSalesRevenueWidget(year: $year) {
      month
      totalSales
      year
    }
  }
`;

export type SalesRevenueWidget = {
  month: number;
  totalSales: number;
  year: number;
};

export const GET_STOCK_ITEMS_WIDGET = gql`
  query FetchStockItemsWidget($id: ID!) {
    fetchStockItemsWidget(id: $id) {
      id
      count
      handle
      name
      position
      status
    }
  }
`;

export type StockItemsWidget = {
  id: string;
  count: string;
  handle: string;
  name: string;
  position: number;
  status: boolean;
};

export const GET_TOP_SELLING_PRODUCTS_WIDGET = gql`
  query FetchTopSellingProductsWidget($year: Int, $month: Int) {
    fetchTopSellingProductsWidget(year: $year, month: $month) {
      name
      featureImageUrl
      stockCode
      totalPrice
      totalPurchase
    }
  }
`;

export type TopSellingProductsWidget = {
  featureImageUrl: string;
  name: string;
  stockCode: string;
  totalPrice: number;
  totalPurchase: number;
};
