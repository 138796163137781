import "react-lazy-load-image-component/src/effects/black-and-white.css";

import { EyeIcon, LinkIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Spinner } from "../../../animations";
import { Button } from "../../../components/form";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { deleteMedia } from "../core/MediaHelper";
import { MediaProps } from "../core/types";

export default function TabeRow({
  file,
  onDelete,
  onClick,
}: {
  file: MediaProps;
  onDelete: (url: string) => void;
  onClick: (file: MediaProps) => void;
}) {
  const { addNotify } = useNotifyContext();
  const [loading, setLoading] = useState(false);

  const handleRemove = useCallback(() => {
    if (!file)
      return addNotify({
        type: NotifyType.ERROR,
        message: "Invalid file path",
      });
    const path = file.url.split(".com/alphafresh/")[1];
    if (!path)
      return addNotify({
        type: NotifyType.ERROR,
        message: "Invalid file path",
      });
    setLoading(true);
    deleteMedia(path)
      .then((data: { status: boolean; msg: string }) => {
        if (data.status === true) {
          addNotify({
            type: NotifyType.SUCCESS,
            message: data.msg,
          });
          onDelete(file.url);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            message: data.msg || "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          message: error.message,
        });
      })
      .finally(() => setLoading(false));
  }, [addNotify, file, onDelete]);

  const handleCopy = useCallback(() => {
    addNotify({
      type: NotifyType.SUCCESS,
      message: "Copied to clipboard",
    });
  }, [addNotify]);

  return (
    <tr key={file.name}>
      <td className="max-w-sm whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-0">
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => {
              onClick(file);
            }}
            className="relative h-10 w-10 flex-shrink-0"
            aria-label="View image"
            title={file.title}
          >
            <LazyLoadImage
              src={file.url}
              alt={file.name}
              effect="black-and-white"
              className="h-10 w-10 rounded-full"
            />
            {loading && (
              <div className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2">
                <Spinner className="mx-0 text-primary-700" />
              </div>
            )}
          </button>
          <div className="ml-4">
            <div className="max-w-sm truncate font-medium text-gray-900">
              {file.title}
            </div>
            <div className="uppercase text-gray-500">{file.extension}</div>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {file.date}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {file.size}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <CopyToClipboard text={file.url} onCopy={handleCopy}>
          <Button
            variant="icon"
            className="rounded-md border border-gray-200 p-3 text-gray-400 transition hover:border-primary-500 hover:text-primary-700"
          >
            <LinkIcon className="h-5 w-5" />
            <span className="sr-only">Copy, {file.title} link</span>
          </Button>
        </CopyToClipboard>
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
        <div className="flex justify-end space-x-4">
          <Button
            variant="icon"
            onClick={() => {
              onClick(file);
            }}
            className="text-primary-700 transition hover:text-primary-900"
          >
            <EyeIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
          <Button
            variant="icon"
            onClick={handleRemove}
            className="text-red-700 transition hover:text-red-900"
          >
            <TrashIcon className="h-4 w-4" aria-hidden="true" />
          </Button>
        </div>
      </td>
    </tr>
  );
}
