import { useQuery } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Fragment, useMemo } from "react";

import { Spinner } from "../../../animations";
import { ErrorFallback } from "../../../components/core";
import {
  GET_RECENT_ACTIVITY_WIDGET,
  RecentActivityWidget,
  Widget,
} from "../../../graphql/dashboard";
import { classNames, formatTime } from "../../../utils";

export function RecentActivity({ item }: { item: Widget }) {
  const { data, error, loading, refetch } = useQuery<{
    fetchRecentActivityWidget: RecentActivityWidget[];
  }>(GET_RECENT_ACTIVITY_WIDGET, {
    variables: {
      id: item.id,
    },
  });

  const timeline = useMemo<RecentActivityWidget[]>(
    () => data?.fetchRecentActivityWidget ?? [],
    [data?.fetchRecentActivityWidget]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <Fragment>
      {loading ? (
        <div className="flex justify-center py-10 text-primary md:py-16 xl:py-20">
          <Spinner />
        </div>
      ) : timeline.length ? (
        <div className="flow-root rounded-lg bg-white p-4 shadow-sm">
          <ul className="-mb-8">
            {timeline.map((event, i) => (
              <li key={event.id}>
                <div className="relative pb-6">
                  {i !== timeline.length - 1 ? (
                    <span
                      className="absolute left-4 top-4 -ml-px h-full w-px bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span className="-m-1 inline-block rounded-full bg-white p-1.5">
                        <span
                          className={classNames(
                            i === 0
                              ? "border-primary-700 bg-primary-700 text-white"
                              : "border-current bg-transparent text-gray-400",
                            "flex h-6 w-6 items-center justify-center rounded-full border"
                          )}
                        >
                          <CheckIcon
                            className="h-3 w-3 text-current"
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5 font-light text-black">
                      <div>
                        <p className="text-xs capitalize-first">
                          {`${event.user.fullName} ${
                            event.event === "delete"
                              ? "deleted"
                              : event.event === "create"
                              ? "created"
                              : event.event === "update"
                              ? "updated"
                              : event.event
                          } ${event.eventDescription}`}
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-xs">
                        <time dateTime={event.updatedAt}>
                          {formatTime(event.updatedAt)}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          className={classNames(
            "px-0 py-10 text-center font-light md:py-16 xl:py-20"
          )}
        >
          <ExclamationCircleIcon
            type="outline"
            name="exclamation-circle"
            className="mx-auto h-6 w-6 text-gray-400"
          />
          <p className="mt-4 font-normal text-gray-900">No data available</p>
          <p className="mt-2 text-sm text-gray-500">
            There is no data available for this widget.
          </p>
        </div>
      )}
    </Fragment>
  );
}
