import { FormikProps, getIn } from "formik";
import { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldDatepicker } from "../../../../components/form";
import { VehicleFinanceType } from "../../../../graphql/fleets/vehicles/pages/financial";

export default memo(function FinancialLoanFields({
  formik,
}: {
  formik: FormikProps<any>;
}) {
  const { t } = useTranslation();

  if (formik.values.financeType !== VehicleFinanceType.LOAN) return null;

  return (
    <Fragment>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_lender")}
          name="loanDetails.lender"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              loanDetails: {
                ...formik.values.loanDetails,
                lender: e.target.value,
              },
            });
          }}
          value={formik.values.loanDetails?.lender ?? ""}
          touched={getIn(formik.touched, "loanDetails.lender")}
          errors={getIn(formik.errors, "loanDetails.lender")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <FieldDatepicker
          title={t("text_date_of_loan")}
          name="loanDetails.dateOfLoan"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              formik.setValues({
                ...formik.values,
                loanDetails: {
                  ...formik.values.loanDetails,
                  dateOfLoan: value ? value.toISOString() : null,
                },
              });
            }
          }}
          selected={
            formik.values.loanDetails?.dateOfLoan
              ? new Date(formik.values.loanDetails.dateOfLoan)
              : null
          }
          touched={getIn(formik.touched, "loanDetails.dateOfLoan")}
          errors={getIn(formik.errors, "loanDetails.dateOfLoan")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_amount_of_loan")}
          name="loanDetails.amountOfLoan"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              loanDetails: {
                ...formik.values.loanDetails,
                amountOfLoan: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.loanDetails?.amountOfLoan ?? ""}
          touched={getIn(formik.touched, "loanDetails.amountOfLoan")}
          errors={getIn(formik.errors, "loanDetails.amountOfLoan")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_annual_percentage_rate")}
          name="loanDetails.annualPercentageRate"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              loanDetails: {
                ...formik.values.loanDetails,
                annualPercentageRate: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.loanDetails?.annualPercentageRate ?? ""}
          touched={getIn(formik.touched, "loanDetails.annualPercentageRate")}
          errors={getIn(formik.errors, "loanDetails.annualPercentageRate")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_down_payment")}
          name="loanDetails.downPayment"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              loanDetails: {
                ...formik.values.loanDetails,
                downPayment: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.loanDetails?.downPayment ?? ""}
          touched={getIn(formik.touched, "loanDetails.downPayment")}
          errors={getIn(formik.errors, "loanDetails.downPayment")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <FieldDatepicker
          title={t("text_first_payment_date")}
          name="loanDetails.firstPaymentDate"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              formik.setValues({
                ...formik.values,
                loanDetails: {
                  ...formik.values.loanDetails,
                  firstPaymentDate: value ? value.toISOString() : null,
                },
              });
            }
          }}
          selected={
            formik.values.loanDetails?.firstPaymentDate
              ? new Date(formik.values.loanDetails.firstPaymentDate)
              : null
          }
          touched={getIn(formik.touched, "loanDetails.firstPaymentDate")}
          errors={getIn(formik.errors, "loanDetails.firstPaymentDate")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_monthly_payment")}
          name="loanDetails.monthlyPayment"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              loanDetails: {
                ...formik.values.loanDetails,
                monthlyPayment: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.loanDetails?.monthlyPayment ?? ""}
          touched={getIn(formik.touched, "loanDetails.monthlyPayment")}
          errors={getIn(formik.errors, "loanDetails.monthlyPayment")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_no_of_payments")}
          name="loanDetails.noOfPayments"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              loanDetails: {
                ...formik.values.loanDetails,
                noOfPayments: parseInt(e.target.value),
              },
            });
          }}
          value={formik.values.loanDetails?.noOfPayments ?? ""}
          touched={getIn(formik.touched, "loanDetails.noOfPayments")}
          errors={getIn(formik.errors, "loanDetails.noOfPayments")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <FieldDatepicker
          title={t("text_loan_end_date")}
          name="loanDetails.loanEndDate"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              formik.setValues({
                ...formik.values,
                loanDetails: {
                  ...formik.values.loanDetails,
                  loanEndDate: value ? value.toISOString() : null,
                },
              });
            }
          }}
          selected={
            formik.values.loanDetails?.loanEndDate
              ? new Date(formik.values.loanDetails.loanEndDate)
              : null
          }
          touched={getIn(formik.touched, "loanDetails.loanEndDate")}
          errors={getIn(formik.errors, "loanDetails.loanEndDate")}
        />
      </div>
      <div className="col-span-12 sm:col-span-12">
        <Field
          title={t("text_notes")}
          name="loanDetails.notes"
          type="textarea"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              loanDetails: {
                ...formik.values.loanDetails,
                notes: e.target.value,
              },
            });
          }}
          value={formik.values.loanDetails?.notes ?? ""}
          touched={getIn(formik.touched, "loanDetails.notes")}
          errors={getIn(formik.errors, "loanDetails.notes")}
        />
      </div>
    </Fragment>
  );
});
