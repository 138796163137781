import { gql } from "@apollo/client";
import { useLazyQuery, useMutation } from "@apollo/client/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "../../../animations";
import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import Form from "./components/Form";
import { TreeItems } from "./components/types";

type IStoreMenu = {
  id: string;
  name: string;
  handle: string;
  menuItems: {
    id: string;
    linkType: number;
    linkValue: {
      id: string;
      name: string;
      handle: string;
    };
    menuName: string;
    subMenu: {
      id: string;
      linkType: number;
      linkValue: {
        id: string;
        name: string;
        handle: string;
      };
      menuName: string;
    }[];
  }[];
  status: boolean;
};

const FETCH_STOREMENU = gql`
  query FetchStoreNavigation($id: ID!) {
    fetchStoreNavigation(id: $id) {
      id
      name
      handle
      menuItems {
        id
        linkType
        linkValue {
          id
          name
          handle
        }
        menuName
        subMenu {
          id
          linkType
          linkValue {
            id
            name
            handle
          }
          menuName
        }
      }
      createdAt
      status
    }
  }
`;

const UPDATE_STOREMENU = gql`
  mutation StoreMenuUpdate(
    $id: ID!
    $name: String!
    $handle: String!
    $menuItems: [StorenavigationmenuitemInput!]
    $status: Boolean!
  ) {
    storeMenuUpdate(
      input: {
        id: $id
        params: {
          name: $name
          handle: $handle
          menuItems: $menuItems
          status: $status
        }
      }
    ) {
      menu {
        id
        name
      }
    }
  }
`;

export default function MenuUpdate({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { menuId } = useParams();

  const [storeMenu, setStoreMenu] = useState<IStoreMenu>();

  const [fetchStoreNavigation] = useLazyQuery(FETCH_STOREMENU);
  const [updateStoreMenu] = useMutation(UPDATE_STOREMENU);

  useEffect(() => {
    fetchStoreNavigation({
      variables: {
        id: menuId,
      },
    })
      .then(({ data, error }) => {
        if (data?.fetchStoreNavigation) {
          setStoreMenu(data.fetchStoreNavigation);
        } else {
          return navigate("/error/401", {
            state: {
              message: error
                ? error.message
                : "StoreNavigation read permission(s) is required to access this page.",
            },
          });
        }
      })
      .catch((error) => {
        return navigate("/error/500", {
          state: {
            error,
            message: error.message,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStoreNavigation, menuId]);

  const handleSubmit = (
    values: {
      name: string;
      handle: string;
      menuItems: TreeItems;
      status: boolean;
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const menuItems = values.menuItems.map((item) => ({
      menuName: item.title,
      parentId: null,
      linkType: item.type,
      linkId: item.link,
    }));

    updateStoreMenu({
      variables: {
        id: menuId,
        name: values.name,
        handle: values.handle,
        menuItems: menuItems,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.storeMenuUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Menu updated successfully",
          });
          return navigate("/store/menu", { replace: true });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Menu update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Menu update failed",
          message: error.message,
        });
      });
  };
  return (
    <>
      <Head
        title="Update Menu"
        heading="Update Menu"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: MenuUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          {storeMenu ? (
            <Form
              initialValues={{
                name: storeMenu.name || "",
                handle: storeMenu.handle || "",
                menuItems: storeMenu.menuItems
                  ? storeMenu.menuItems.map((item) => ({
                      id: item.id,
                      title: item.menuName,
                      link: item.linkValue ? item.linkValue.id : "",
                      type: item.linkType,
                      children: item.subMenu
                        ? item.subMenu.map((subItem) => ({
                            id: subItem.id,
                            title: subItem.menuName,
                            link: subItem.linkValue ? subItem.linkValue.id : "",
                            type: subItem.linkType,
                            children: [],
                          }))
                        : [],
                    }))
                  : [],
                status: storeMenu.status,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
}

export const MenuUpdateResource: ResourceProps = {
  name: "Update Menu",
  description: "A update of menu items",
  access: ["update-menus"],
  path: "menu/:menuId",
};
