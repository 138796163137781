import { RadioGroup } from "@headlessui/react";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { VehicleSettingForm } from "../../../../graphql/fleets/vehicles/pages/settings";
import { classNames, useFormikErrors } from "../../../../utils";

export default function Form({
  initialValues,
  onChange = () => {},
  onClear = () => {},
  onSubmit,
  actionLabel,
}: {
  initialValues: VehicleSettingForm;
  onChange?: (values: VehicleSettingForm) => void;
  onClear?: () => void;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const VehicleSettingsSchema = Yup.object().shape({
    fuelUnit: Yup.number().nullable(),
    measurementUnit: Yup.number().nullable(),
    primaryMeter: Yup.number().typeError("Must be a number").nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: VehicleSettingsSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  useEffect(() => {
    onChange(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const primaryMeters = [
    {
      label: "Miles",
      value: 0,
    },
    {
      label: "Kilometers",
      value: 1,
    },
    {
      label: "Hours",
      value: 2,
    },
  ];
  const measurementUnits = [
    {
      label: "Imperial",
      value: 0,
      description: "Inches, pounds, gallons, miles",
    },
    {
      label: "Metric",
      value: 1,
      description: "Centimeters, kilograms, liters, kilometers",
    },
  ];
  const fuelUnits = [
    {
      label: "Gallons",
      value: 0,
    },
    {
      label: "Liters",
      value: 1,
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-2 text-base font-medium text-gray-900">
          Primary Meter
        </div>
        <p className="mb-4 text-sm">
          Select how you measure utilization for this vehicle
        </p>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-12">
            <div className="mb-1 block text-sm font-medium text-gray-900">
              {t("text_primary_meter")}
            </div>
            <div className="space-x-4">
              {primaryMeters.map((type, typeIdx) => (
                <Field
                  key={`primaryMeter-${type.value}`}
                  title={type.label}
                  id={`primaryMeter-${type.value}`}
                  name="primaryMeter"
                  type="radio"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "primaryMeter",
                      parseInt(e.target.value)
                    );
                  }}
                  value={String(type.value)}
                  checked={type.value === formik.values.primaryMeter}
                />
              ))}
            </div>
            {formik.touched.primaryMeter && formik.errors.primaryMeter ? (
              <p className="mt-2 text-sm text-red-600" id="primaryMeter-errors">
                {formik.errors.primaryMeter}
              </p>
            ) : null}
          </div>
          <div className="col-span-12 sm:col-span-12">
            <RadioGroup
              value={formik.values.measurementUnit}
              onChange={(value: number) => {
                formik.setFieldValue("measurementUnit", value);
              }}
            >
              <RadioGroup.Label className="text-sm font-medium text-gray-900">
                {t("text_measurement_unit")}
              </RadioGroup.Label>

              <div className="isolate mt-1 -space-y-px rounded-md bg-white">
                {measurementUnits.map((type, typeIdx) => (
                  <RadioGroup.Option
                    key={type.label}
                    value={type.value}
                    className={({ checked }) =>
                      classNames(
                        typeIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                        typeIdx === measurementUnits.length - 1
                          ? "rounded-bl-md rounded-br-md"
                          : "",
                        checked
                          ? "z-10 border-primary-200 bg-primary-50"
                          : "border-gray-200",
                        "relative flex cursor-pointer border p-4 focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "border-transparent bg-primary-600"
                              : "border-gray-300 bg-white",
                            active
                              ? "ring-2 ring-primary-500 ring-offset-2"
                              : "",
                            "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                          )}
                          aria-hidden="true"
                        >
                          <span className="h-1.5 w-1.5 rounded-full bg-white" />
                        </span>
                        <span className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-primary-900" : "text-gray-900",
                              "block text-sm font-medium"
                            )}
                          >
                            {type.label}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? "text-primary-700" : "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {type.description}
                          </RadioGroup.Description>
                        </span>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
            {formik.touched.measurementUnit && formik.errors.measurementUnit ? (
              <p
                className="mt-2 text-sm text-red-600"
                id="measurementUnit-errors"
              >
                {formik.errors.measurementUnit}
              </p>
            ) : null}
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-4 text-base font-medium text-gray-900">Fuel</div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-12">
            <div className="mb-1 block text-sm font-medium text-gray-900">
              {t("text_fuel_unit")}
            </div>
            <div className="space-x-4">
              {fuelUnits.map((type, typeIdx) => (
                <Field
                  key={`fuelUnit-${type.value}`}
                  title={type.label}
                  id={`fuelUnit-${type.value}`}
                  name="fuelUnit"
                  type="radio"
                  onChange={(e) => {
                    formik.setFieldValue("fuelUnit", parseInt(e.target.value));
                  }}
                  value={String(type.value)}
                  checked={type.value === formik.values.fuelUnit}
                />
              ))}
            </div>
            {formik.touched.fuelUnit && formik.errors.fuelUnit ? (
              <p className="mt-2 text-sm text-red-600" id="fuelUnit-errors">
                {formik.errors.fuelUnit}
              </p>
            ) : null}
          </div>
        </div>
      </fieldset>
      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/fleets/vehicles" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
