import { gql } from "@apollo/client";

export const GET_RESOURCES = gql`
  query FetchResources {
    fetchResources {
      id
      resourceName
      resourceDescription
      createdAt
      status
    }
  }
`;

export const EDIT_RESOURCE = gql`
  mutation ResourceUpdate(
    $id: ID!
    $resourceName: String!
    $resourceDescription: String!
    $status: Boolean!
  ) {
    resourceUpdate(
      input: {
        id: $id
        params: {
          resourceName: $resourceName
          resourceDescription: $resourceDescription
          status: $status
        }
      }
    ) {
      resource {
        id
        resourceName
        resourceDescription
        createdAt
        status
      }
    }
  }
`;

export const ADD_RESOURCE = gql`
  mutation ResourceCreate(
    $resourceName: String!
    $resourceDescription: String!
    $status: Boolean!
  ) {
    resourceCreate(
      input: {
        params: {
          resourceName: $resourceName
          resourceDescription: $resourceDescription
          status: $status
        }
      }
    ) {
      resource {
        id
        resourceName
        resourceDescription
        createdAt
        status
      }
    }
  }
`;

export const REMOVE_RESOURCE = gql`
  mutation ResourceDelete($id: ID!) {
    resourceDelete(input: { id: $id }) {
      resource {
        id
        resourceName
      }
    }
  }
`;
