import {
  ArrowPathIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { Fragment, useMemo, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { InView } from "react-intersection-observer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useVirtual } from "react-virtual";

import { IconFilterClear } from "../../svgs";
import { classNames } from "../../utils";
import { Button } from "../form";
import { Filter } from ".";

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type TableCursorInfiniteProps<T> = TableOptions<T> & {
  data: T[];
  columns: ColumnDef<T>[];
  loading?: boolean;
  fetching: boolean;
  filtering?: boolean;
  pageLimit?: number;
  totalRows?: number;
  enableHeader?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  className?: string;
};

export function TableCursorInfinite<T>(props: TableCursorInfiniteProps<T>) {
  const {
    data,
    columns,
    loading = true,
    fetching = false,
    filtering = false,
    pageLimit = 20,
    totalRows = 0,
    enableGlobalFilter = false,
    enableRowSelection = false,
    enableHeader = true,
    state,
    hasNextPage,
    fetchNextPage = () => {},
    className,
    ...rest
  } = props;

  const pageSize = useMemo(
    () => Math.ceil(totalRows / pageLimit),
    [pageLimit, totalRows]
  );

  const { columnVisibility: _columnVisibility, ..._state } = state ?? {};
  const [globalFilter, setGlobalFilter] = useState("");

  const [columnVisibility, setColumnVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const flatData = useMemo(
    () => (loading ? Array(pageLimit).fill({}) : Array.from(data)),
    [loading, pageLimit, data]
  );

  const tableColumns = useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            enableColumnFilter: false,
            ...column,
            cell: () => <Skeleton />,
          }))
        : columns.map((column) => ({
            enableColumnFilter: false,
            ...column,
          })),
    [loading, columns]
  );

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data: flatData,
    columns: tableColumns,
    state: {
      sorting,
      globalFilter,
      columnVisibility: {
        id: false,
        ..._columnVisibility,
        ...columnVisibility,
      },
      ..._state,
    },
    enableRowSelection,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    ...rest,
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <Fragment>
      {enableGlobalFilter && (
        <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
          <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
            <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
            <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
            <DebounceInput
              type="search"
              className={classNames(
                "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                "shadow-none outline-none focus:ring-0",
                "placeholder-black/50 placeholder-opacity-100"
              )}
              placeholder="Search keywords or phrases"
              minLength={2}
              debounceTimeout={300}
              value={globalFilter || ""}
              onChange={(e) => {
                setGlobalFilter(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              variant="icon"
              className="relative flex h-11 w-11 items-center justify-center rounded-md text-gray-600 transition-all hover:bg-primary-200 hover:text-primary-900"
              onClick={() => {
                table.resetGlobalFilter(true);
                setGlobalFilter("");
              }}
              disabled={loading}
            >
              <IconFilterClear className="h-5 w-5" />
              <span className="sr-only">Clear Filters</span>
            </Button>
          </div>
        </div>
      )}
      <div
        className={classNames(
          "relative",
          className ??
            "overflow-hidden rounded-lg border border-gray-200 bg-white"
        )}
      >
        <SkeletonTheme baseColor="#f5f5f5" borderRadius="0.25rem" duration={1}>
          <div
            className="h- h-screen-3/4 min-w-full max-w-full overflow-x-auto"
            ref={tableContainerRef}
          >
            <table className="relative z-0 min-w-full  table-fixed pt-20">
              {enableHeader && (
                <thead
                  className={classNames(
                    "sticky top-0 border-b border-gray-200 bg-greyish"
                  )}
                >
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{
                              width:
                                header.getSize() !== 100
                                  ? header.getSize()
                                  : "100%",
                            }}
                            className={classNames(
                              "aling-top whitespace-nowrap px-2 py-2 text-left align-top text-xs font-medium uppercase tracking-wider",
                              index === 0 ? "pl-6" : "",
                              index === headerGroup.headers.length - 1
                                ? "pr-6"
                                : ""
                            )}
                          >
                            {header.isPlaceholder ? null : (
                              <Fragment>
                                <div
                                  {...{
                                    className: classNames(
                                      header.column.getCanSort()
                                        ? "cursor-pointer select-none"
                                        : "",
                                      header.column.getIsSorted()
                                        ? "text-primary-800"
                                        : "text-gray-700",
                                      "group flex w-full items-center rounded-md px-1.5 py-1.5 transition ease-in-out md:px-2 md:py-2"
                                    ),
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                  title={
                                    header.column.getCanSort()
                                      ? `Sort from ${table.getPageCount()} of ${pageSize} pages`
                                      : ""
                                  }
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {header.column.getCanSort()
                                    ? {
                                        asc: (
                                          <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 4.5 8"
                                            enableBackground="new 0 0 4.5 8"
                                            className="ml-1 h-3 w-3"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M2,7.9L0.1,5.8c-0.2-0.3,0-0.6,0.3-0.6h3.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3L2.6,7.9
                         C2.4,8.1,2.2,8.1,2,7.9C2,7.9,2,7.9,2,7.9L2,7.9z"
                                            />
                                            <path
                                              fill="currentColor"
                                              d="M2.6,0.1l1.9,2.1c0.2,0.3,0,0.6-0.3,0.6H0.4C0.2,2.9,0,2.7,0,2.5c0-0.1,0-0.2,0.1-0.3L2,0.1
                         C2.1,0,2.4,0,2.6,0.1C2.5,0.1,2.5,0.1,2.6,0.1L2.6,0.1z"
                                              opacity={0.25}
                                            />
                                          </svg>
                                        ),
                                        desc: (
                                          <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 4.5 8"
                                            enableBackground="new 0 0 4.5 8"
                                            className="ml-1 h-3 w-3"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M2,7.9L0.1,5.8c-0.2-0.3,0-0.6,0.3-0.6h3.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3L2.6,7.9
                     C2.4,8.1,2.2,8.1,2,7.9C2,7.9,2,7.9,2,7.9L2,7.9z"
                                              opacity={0.25}
                                            />
                                            <path
                                              fill="currentColor"
                                              d="M2.6,0.1l1.9,2.1c0.2,0.3,0,0.6-0.3,0.6H0.4C0.2,2.9,0,2.7,0,2.5c0-0.1,0-0.2,0.1-0.3L2,0.1
                     C2.1,0,2.4,0,2.6,0.1C2.5,0.1,2.5,0.1,2.6,0.1L2.6,0.1z"
                                            />
                                          </svg>
                                        ),
                                      }[
                                        header.column.getIsSorted() as string
                                      ] ?? (
                                        <svg
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          x="0px"
                                          y="0px"
                                          viewBox="0 0 4.5 8"
                                          enableBackground="new 0 0 4.5 8"
                                          className="ml-1 h-3 w-3"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M2,7.9L0.1,5.8c-0.2-0.3,0-0.6,0.3-0.6h3.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3L2.6,7.9
                           C2.4,8.1,2.2,8.1,2,7.9C2,7.9,2,7.9,2,7.9L2,7.9z"
                                          />
                                          <path
                                            fill="currentColor"
                                            d="M2.6,0.1l1.9,2.1c0.2,0.3,0,0.6-0.3,0.6H0.4C0.2,2.9,0,2.7,0,2.5c0-0.1,0-0.2,0.1-0.3L2,0.1
                           C2.1,0,2.4,0,2.6,0.1C2.5,0.1,2.5,0.1,2.6,0.1L2.6,0.1z"
                                          />
                                        </svg>
                                      )
                                    : null}
                                </div>
                                {header.column.getCanFilter() ? (
                                  <Filter
                                    column={header.column}
                                    table={table}
                                  />
                                ) : null}
                              </Fragment>
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody className="relative z-[-1]">
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}
                {(!loading && filtering && virtualRows.length === 0) ||
                (!loading && globalFilter && virtualRows.length === 0) ? (
                  <Fragment>
                    <tr>
                      <td
                        colSpan={table.getVisibleFlatColumns().length}
                        className="py-10 text-center md:py-16 xl:py-20"
                      >
                        <ExclamationCircleIcon
                          type="outline"
                          name="exclamation-circle"
                          className="mx-auto h-6 w-6 text-gray-400"
                        />
                        <p className="mt-4 font-medium text-gray-900">
                          No results found
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          No results found for this search term. Please try
                          different keywords.
                        </p>
                      </td>
                    </tr>
                  </Fragment>
                ) : (
                  <Fragment>
                    {virtualRows.map((virtualRow) => {
                      const row = rows[virtualRow.index] as Row<T>;
                      return (
                        <tr
                          key={row.id}
                          className={classNames(
                            "group shadow-transparent transition hover:bg-primary-50 hover:shadow-base",
                            table.getIsAllRowsExpanded() && row.subRows.length
                              ? "bg-greyish"
                              : ""
                          )}
                        >
                          {row.getVisibleCells().map((cell, index) => {
                            return (
                              <td
                                key={cell.id}
                                className={classNames(
                                  "py-3 pl-4 pr-4 text-sm",
                                  index === 0 ? "pl-8" : "",
                                  index === row.getVisibleCells().length - 1
                                    ? "pr-8"
                                    : ""
                                )}
                                style={
                                  enableHeader
                                    ? {}
                                    : {
                                        width:
                                          cell.column.columnDef.size !== 100
                                            ? cell.column.columnDef.size
                                            : "100%",
                                      }
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </Fragment>
                )}
                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
                <tr>
                  <td colSpan={table.getVisibleFlatColumns().length}>
                    {hasNextPage && (
                      <div className="flex justify-center py-5 text-sm">
                        <Button
                          variant="icon"
                          disabled={loading || fetching}
                          onClick={fetchNextPage}
                        >
                          <ArrowPathIcon
                            className={classNames(
                              "h-5 w-5",
                              loading
                                ? "animate-spin"
                                : fetching
                                ? "animate-spin"
                                : "animate-pulse"
                            )}
                          />
                          <span className="ml-2">
                            {loading ? "Loading" : "Load More"}
                          </span>
                        </Button>
                      </div>
                    )}
                    <InView
                      root={tableContainerRef.current}
                      disabled={!hasNextPage}
                      onChange={async (inView) => {
                        if (inView && !fetching) {
                          fetchNextPage();
                        }
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </SkeletonTheme>
      </div>
    </Fragment>
  );
}
