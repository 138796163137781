import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";

import {
  GET_SERVICEREMAINDER_BY_VEHICLE_ID,
  renderStatus as renderServiceReminderStatus,
  VehicleServiceReminder,
} from "../../graphql/fleets/vehicles/pages/service-reminder";
import { classNames } from "../../utils";
import { ErrorMessage } from "../core";
import { TableStaticInfinite } from "../table";
import { Button } from ".";

export function FieldInsertServiceReminder({
  title,
  type = "button",
  vehicleId,
  value,
  onChange,
  excludeIds = [],
  excludeNote = "",
  disabled = false,
  className = "",
  children,
}: {
  title: string;
  type?: "button" | "search";
  vehicleId: number | null;
  value: number | null;
  onChange: (newValue: VehicleServiceReminder) => void;
  excludeIds?: number[];
  excludeNote?: string;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}) {
  const [pageLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const { data, loading, error } = useQuery<{
    fetchServiceRemaindersByVehicle: VehicleServiceReminder[];
  }>(GET_SERVICEREMAINDER_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });

  const serviceRemainders: VehicleServiceReminder[] = useMemo(() => {
    const updatedServiceRemainders: VehicleServiceReminder[] =
      data?.fetchServiceRemaindersByVehicle || [];

    const flatData = updatedServiceRemainders.flat();

    if (value) {
      const updatedRowSelection: Record<string, boolean> = {};
      flatData.forEach((p, i) => {
        updatedRowSelection[String(i)] = value === Number(p.id);
      });
      setRowSelection(updatedRowSelection);
    } else {
      setRowSelection({});
    }

    return flatData;
  }, [data?.fetchServiceRemaindersByVehicle, value]);

  const columns = useMemo<ColumnDef<VehicleServiceReminder>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "vehicleServiceTask.name",
        header: "Task",
        size: 100,
        enableHiding: false,
        cell: (props) => (
          <span className="flex items-center whitespace-nowrap">
            <span className="mr-2">
              {props.row.original.vehicleServiceTask.name}
            </span>
            {excludeNote &&
            excludeIds.includes(Number(props.row.original.id)) ? (
              <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                {excludeNote}
              </span>
            ) : null}
          </span>
        ),
        accessorFn: (row) => row.vehicleServiceTask.name,
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (props) => renderServiceReminderStatus(props.row.original.status),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        cell: ({ row }) => (
          <div className="flex space-x-2 md:space-x-4">
            <Button
              variant="info"
              border
              onClick={() => {
                onChange(row.original);
                setSearchQuery(null);
              }}
              disabled={excludeIds.includes(Number(row.original.id))}
            >
              Insert
              <span className="sr-only">
                , {row.original.vehicleServiceTask.name}
              </span>
            </Button>
          </div>
        ),
      },
    ],
    [excludeNote, excludeIds, onChange]
  );

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      {type === "button" ? (
        <Button
          variant="info"
          border
          onClick={() => {
            setSearchQuery("");
          }}
          disabled={disabled}
          className={classNames("px-2", className)}
        >
          {children}
        </Button>
      ) : (
        <Fragment>
          <div className="mb-4 flex justify-end">
            <Button
              variant="text"
              className="text-primary-700 underline decoration-dotted underline-offset-2 hover:no-underline"
              onClick={() => {
                setSearchQuery("");
              }}
            >
              View Service Reminders
              <span className="ml-2 h-[1.25rem] min-w-[1.25rem] rounded-full bg-primary-700 px-1 text-xs leading-[1.25rem] text-white">
                {serviceRemainders.length}
              </span>
            </Button>
          </div>
          <header className="grid grid-cols-1 gap-4 sm:grid-cols-4">
            <div className="relative col-span-3">
              <label htmlFor="search" className="sr-only">
                Search service reminders
              </label>
              <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className={classNames(
                  "relative block w-full appearance-none rounded-md border border-gray-300 py-2.5 pl-10 pr-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                  "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
                )}
                placeholder="Search service reminders"
                value={searchQuery || ""}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>
            <div className="col-span-1 flex">
              <Button
                variant="secondary"
                className="w-full justify-center"
                onClick={() => {
                  setSearchQuery("");
                }}
              >
                Browse
              </Button>
            </div>
          </header>
        </Fragment>
      )}
      <Transition.Root
        show={searchQuery === null ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setSearchQuery(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-4xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-greyish p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="mb-2 flex space-x-2">
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for name"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery ?? ""}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string | null> };
                      }) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    variant="icon"
                    onClick={() => {
                      setSearchQuery(null);
                    }}
                  >
                    <XMarkIcon className="mr-2 h-5 w-5" />
                  </Button>
                </div>
                <TableStaticInfinite
                  data={serviceRemainders}
                  columns={columns}
                  loading={loading}
                  totalRows={serviceRemainders.length}
                  pageLimit={pageLimit}
                  state={{
                    rowSelection,
                  }}
                  enableRowSelection={(row) =>
                    !excludeIds.includes(Number(row.original.id))
                  }
                  onRowSelectionChange={setRowSelection}
                  getCoreRowModel={getCoreRowModel()}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
