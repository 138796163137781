import { gql } from "@apollo/client";

import { Vehicle, VEHICLE_BASIC_FRAGMENT } from "../../vehicles";

export const DOCK_FRAGMENT = gql`
  ${VEHICLE_BASIC_FRAGMENT}
  fragment DockFragment on Dock {
    id
    name
    status
    createdAt
    vehicles {
      ...VehicleBasicFragment
    }
  }
`;

export const GET_DOCKS = gql`
  ${DOCK_FRAGMENT}
  query FetchDocks($status: Boolean) {
    fetchDocks(status: $status) {
      ...DockFragment
    }
  }
`;

export const GET_DOCK_BY_ID = gql`
  ${DOCK_FRAGMENT}
  query FetchDock($id: ID!) {
    fetchDock(id: $id) {
      ...DockFragment
    }
  }
`;

export const EDIT_DOCK = gql`
  ${DOCK_FRAGMENT}
  mutation DockUpdate(
    $id: ID!
    $name: String!
    $status: Boolean!
    $vehicleIds: [Int!]
  ) {
    dockUpdate(
      input: {
        id: $id
        params: { name: $name, status: $status, vehicleIds: $vehicleIds }
      }
    ) {
      dock {
        ...DockFragment
      }
    }
  }
`;

export const ADD_DOCK = gql`
  ${DOCK_FRAGMENT}
  mutation DockCreate($name: String!, $status: Boolean!, $vehicleIds: [Int!]) {
    dockCreate(
      input: {
        params: { name: $name, status: $status, vehicleIds: $vehicleIds }
      }
    ) {
      dock {
        ...DockFragment
      }
    }
  }
`;

export const REMOVE_DOCK = gql`
  ${DOCK_FRAGMENT}
  mutation DockDelete($id: ID!) {
    dockDelete(input: { id: $id }) {
      dock {
        ...DockFragment
      }
    }
  }
`;

export interface Dock {
  id: string;
  name: string;
  status: boolean;
  vehicles: Vehicle[];
  createdAt: string;
}
export interface DockForm extends Omit<Dock, "id" | "createdAt" | "vehicles"> {
  id?: string;
  vehicleIds: number[];
}
