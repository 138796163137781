import { useFormik } from "formik";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldDriver,
  FieldUser,
  FieldVehicle,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleIssueForm } from "../../../../graphql/fleets/vehicles/pages/issues";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { classNames, useFormikErrors } from "../../../../utils";
export default function Form({
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
}: {
  initialValues: VehicleIssueForm;
  onSubmit: (values: VehicleIssueForm, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
}) {
  const { vehicleId } = useParams();
  const { t } = useTranslation();

  const [vehicleBasic, setVehicleBasic] = useState<VehicleBasic | null>(null);

  const ValidationSchema = Yup.object().shape({
    vehicleId: Yup.number().when(".", {
      is: (val: unknown) => vehicleId === undefined,
      then: Yup.number().typeError("Must be a number").required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    reportedDate: Yup.string().required("Required"),
    summary: Yup.string().required("Required"),
    issueType: Yup.string().nullable(),
    reportedById: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    assignedToId: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    priority: Yup.number().nullable(),
    overDueDate: Yup.string().nullable(),
    description: Yup.string().nullable(),
    status: Yup.number().typeError("Must be a number").required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          {vehicleId === undefined ? (
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_vehicle")}
              </label>
              <FieldVehicle
                value={formik.values.vehicleId}
                onChange={(value) => {
                  formik.setFieldValue("vehicleId", value ? value.id : null);
                  setVehicleBasic(value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.vehicleId && formik.errors.vehicleId
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.vehicleId && formik.errors.vehicleId ? (
                <p className="mt-2 text-sm text-red-600" id="vehicleId-errors">
                  {formik.errors.vehicleId}
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_priority")}
            </label>
            <FieldPriority
              value={formik.values.priority}
              onChange={(value) => {
                formik.setFieldValue("priority", value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.priority && formik.errors.priority
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.priority && formik.errors.priority ? (
              <p className="mt-2 text-sm text-red-600" id="priority-errors">
                {formik.errors.priority}
              </p>
            ) : null}
          </div>
          <div className="sm:col-span-3">
            <FieldDatepicker
              title={t("text_reported_date")}
              name="reportedDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "reportedDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.reportedDate
                  ? new Date(formik.values.reportedDate)
                  : null
              }
              touched={formik.touched.reportedDate}
              errors={formik.errors.reportedDate}
            />
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t("text_summary")}
              name="summary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.summary}
              touched={formik.touched.summary}
              errors={formik.errors.summary}
            />
          </div>
          <div className="sm:col-span-6">
            <Field
              title={t("text_description")}
              name="description"
              type="textarea"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              touched={formik.touched.description}
              errors={formik.errors.description}
            />
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t("text_issue_type")}
              name="issueType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.issueType}
              touched={formik.touched.issueType}
              errors={formik.errors.issueType}
            />
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t("text_primary_meter")}
              name="primaryMeter"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.primaryMeter ?? ""}
              touched={formik.touched.primaryMeter}
              errors={formik.errors.primaryMeter}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_reported_by")}
            </label>
            <FieldDriver
              title={t("text_driver")}
              value={formik.values.reportedById}
              onChange={(value) => {
                formik.setFieldValue("reportedById", value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.reportedById && formik.errors.reportedById
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.reportedById && formik.errors.reportedById ? (
              <p className="mt-2 text-sm text-red-600" id="reportedById-errors">
                {formik.errors.reportedById.toString()}
              </p>
            ) : null}
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_assigned_to")}
            </label>
            <FieldUser
              title="User"
              value={formik.values.assignedToId}
              onChange={(value) => {
                formik.setFieldValue("assignedToId", value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.assignedToId && formik.errors.assignedToId
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.assignedToId && formik.errors.assignedToId ? (
              <p className="mt-2 text-sm text-red-600" id="assignedToId-errors">
                {formik.errors.assignedToId.toString()}
              </p>
            ) : null}
          </div>

          <div className="sm:col-span-3">
            <FieldDatepicker
              title={t("text_overdue_date")}
              name="overDueDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "overDueDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.overDueDate
                  ? new Date(formik.values.overDueDate)
                  : null
              }
              touched={formik.touched.overDueDate}
              errors={formik.errors.overDueDate}
            />
          </div>
        </div>
        <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={onCancel}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <Fragment>
                <Spinner />
                {t("text_processing")}
              </Fragment>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export function FieldStatus({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Open", value: "0" },
      { label: "Resolved", value: "1" },
      { label: "Closed", value: "2" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}

export function FieldPriority({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "No Priority", value: "0" },
      { label: "Low", value: "1" },
      { label: "Medium", value: "2" },
      { label: "High", value: "3" },
      { label: "Critical", value: "4" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}
