import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  gql,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  split,
} from "@apollo/client";
import { createFragmentRegistry } from "@apollo/client/cache";
// import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import {
  getMainDefinition,
  relayStylePagination,
} from "@apollo/client/utilities";
import ActionCable from "actioncable";
import { GraphQLError } from "graphql";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";

import * as authHelper from "./AuthHelper";

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
});

const cable = ActionCable.createConsumer(
  `${process.env.REACT_APP_GRAPHQL_WS_URL}`
);

const wsLink = new ActionCableLink({
  cable,
});

// const retryLink = new RetryLink({
//   delay: {
//     initial: 300,
//     max: Infinity,
//     jitter: true,
//   },
//   attempts: {
//     max: 5,
//     retryIf: (error, _operation) => {
//       console.log("RetryLink", error);
//       console.log(error.message);
//       if (error.graphQLErrors.length) {
//         const graphQLError = error.graphQLErrors[0];
//         if (graphQLError.extensions.code === "UNAUTHENTICATED") {
//           return true;
//         }
//       }
//       return false;
//     },
//   },
// });

const authLink = new ApolloLink((operation, forward) => {
  const oldHeaders = operation.getContext().headers;
  // console.log(operation.operationName);
  if (operation.operationName === "GenerateAccessToken") {
    operation.setContext({
      headers: {
        ...oldHeaders,
        platform: "web",
        // authorization: refresh ? `Bearer ${refresh}` : "",
      },
    });
  } else {
    const _access = authHelper.getAccess();
    operation.setContext({
      headers: {
        ...oldHeaders,
        platform: "web",
        authorization: _access ? `Bearer ${_access}` : "",
      },
    });
  }

  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // console.log("GraphQL error", graphQLErrors);

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // console.error(err?.extensions?.code, 678);

        switch (err?.extensions?.code) {
          case "UNAUTHENTICATED":
            // console.log(99, operation.operationName);
            if (operation.operationName === "GenerateAccessToken") return;

            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                // used an annonymous function for using an async function
                (async () => {
                  try {
                    const accessToken = await getToken();
                    if (!accessToken) {
                      throw new GraphQLError("Access token not found");
                    }

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              }
            );
            return observable;

          case "FORBIDDEN":
            // console.log("this should redirect to 403 page");
            // window.location.href = "/error/403";
            break;
          case "INTERNAL_SERVER_ERROR":
            // console.log("this should redirect to 500 page");
            // window.location.href = "/error/500";
            break;
          case "GRAPHQL_VALIDATION_FAILED":
            // console.log("this should redirect to 400 page");
            // window.location.href = "/error/400";
            break;
        }
      }
    }

    // console.log(`[Network error]: ${networkError}`);

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError && networkError.name === "ServerError") {
      // remove cached token on 401 from the server
      authHelper.removeAccess();
    }
  }
);

const wssLink = authLink.concat(authLink).concat(wsLink);
const nonWssLink = errorLink.concat(authLink).concat(httpLink);
const link = split(
  ({ query }) => {
    const def = getMainDefinition(query);
    return (
      def.kind === "OperationDefinition" && def.operation === "subscription"
    );
  },
  wssLink,
  nonWssLink
);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(gql`
      fragment OptionValueFragment on ProductOptionValue {
        name
        option {
          name
        }
      }
      fragment VariantFragment on ProductSku {
        id
        stockCode
        barCode
        plu
        basePrice
        comparePrice
        variantTitle {
          ...OptionValueFragment
        }
        cost
        marketCost
        minimumQuantity
        inventory
        variantImageUrl
        productId
        product {
          id
          name
          handle
          featureImageUrl
          department {
            id
            name
            handle
          }
          binLocation {
            id
            name
          }
        }
      }
    `),
    typePolicies: {
      Query: {
        fields: {
          fetchProducts: relayStylePagination(),
          fetchPickings: relayStylePagination(),
        },
      },
      Picking: {
        fields: {
          orderDetails: {
            merge: false,
          },
        },
      },
    },
  }),
  link,
});

export const getToken = async (): Promise<string | any> => {
  const refresh = authHelper.getRefresh();
  if (!refresh) return authHelper.sessionExpired();

  try {
    const { data } = await client.mutate({
      mutation: gql`
        mutation GenerateAccessToken($refresh: String!) {
          generateAccessToken(input: { params: { token: $refresh } }) {
            accessToken
          }
        }
      `,
      variables: {
        refresh: refresh,
      },
      fetchPolicy: "network-only",
    });

    authHelper.setAccess(data.generateAccessToken.accessToken);
    return data.generateAccessToken.accessToken;
  } catch (error) {
    console.log(error);
    return authHelper.sessionExpired();
  }
};

export const getUser = async (): Promise<any> => {
  const { data } = await client.query({
    query: gql`
      query Me {
        me {
          id
          fullName
          firstName
          lastName
          userName
          email
          enableMfa
          phoneNumber
          roles {
            id
            permissions {
              id
              permissionDescription
              permissionDisplayName
              permissionName
              resource {
                id
                permission {
                  id
                  permissionDescription
                  permissionDisplayName
                  permissionName
                  status
                  updatedAt
                }
                resourceDescription
                resourceName
                status
                updatedAt
              }
              status
              updatedAt
            }
            roleDescription
            roleName
            status
            updatedAt
          }
          status
          updatedAt
        }
      }
    `,
    fetchPolicy: "cache-first",
  });
  return data?.me;
};
