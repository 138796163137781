import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import { LimitBy, TableCursor } from "../../../../components/table";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_DELIVERYRUN,
  type DeliveryRun,
  EDIT_DELIVERYRUN,
  GET_DELIVERYRUNS,
  REMOVE_DELIVERYRUN,
} from "../../../../graphql/fleets/settings/delivery-runs";
import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import { formatDate } from "../../../../utils";
import { AuthShield } from "../../../auth/core";
import Form from "./components/Form";

const DeliveryRunList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery<{
    fetchDeliveryRuns: DeliveryRun[];
  }>(GET_DELIVERYRUNS);
  const deliveryRuns = useMemo<DeliveryRun[]>(
    () =>
      data?.fetchDeliveryRuns.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data?.fetchDeliveryRuns]
  );

  const [activeDeliveryRun, setActiveDeliveryRun] = useState<
    DeliveryRun | undefined
  >(undefined);
  const [newDeliveryRun, setNewDeliveryRun] = useState<boolean | undefined>(
    undefined
  );

  const [updateDeliveryRun] = useMutation(EDIT_DELIVERYRUN, {
    refetchQueries: [{ query: GET_DELIVERYRUNS }],
  });
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateDeliveryRun({
      variables: {
        id: activeDeliveryRun?.id,
        name: values.name,
        dockId: values.dock ? parseInt(values.dock.value) : null,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.deliveryRunUpdate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Delivery Run updated successfully",
          });
          setActiveDeliveryRun(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Delivery Run update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Delivery Run update failed",
          message: error.message,
        });
      });
  };

  const [createDeliveryRun] = useMutation(ADD_DELIVERYRUN, {
    refetchQueries: [{ query: GET_DELIVERYRUNS }],
  });
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createDeliveryRun({
      variables: {
        name: values.name,
        dockId: values.dock ? parseInt(values.dock.value) : null,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.deliveryRunCreate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Delivery Run created successfully",
          });
          setNewDeliveryRun(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Delivery Run create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Delivery Run create failed",
          message: error.message,
        });
      });
  };

  const [deleteDeliveryRun] = useMutation(REMOVE_DELIVERYRUN, {
    refetchQueries: [{ query: GET_DELIVERYRUNS }],
  });
  const handleDelete = async (id: string) => {
    deleteDeliveryRun({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.deliveryRunDelete) {
          const deletedDeliveryRun = data.deliveryRunDelete?.deliveryRun;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Delivery Run ${deletedDeliveryRun.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Delivery Run deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Delivery Run deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<DeliveryRun, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-deliveryRuns"]}>
              <button
                type="button"
                className="mr-4 appearance-none py-2 text-primary-700 hover:text-primary-900"
                onClick={() => setActiveDeliveryRun(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </button>
            </AuthShield>
            <AuthShield access={["destroy-deliveryRuns"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={DeliveryRunListResource.name}
        heading={DeliveryRunListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: DeliveryRunListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {DeliveryRunListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {DeliveryRunListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-deliveryRuns"]}>
              <Button onClick={() => setNewDeliveryRun(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add delivery run
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={deliveryRuns}
          columns={columns}
          loading={loading}
          totalRows={deliveryRuns.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No delivery runs found"
          fallbackText="You can add a new delivery run by clicking on add delivery run button."
        />
      </div>

      <Transition.Root
        show={activeDeliveryRun ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveDeliveryRun(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Delivery Run - "
                  initialValues={{
                    id: activeDeliveryRun?.id,
                    name: activeDeliveryRun?.name ?? "",
                    dock: activeDeliveryRun?.dock
                      ? {
                          label: activeDeliveryRun?.dock.name,
                          value: activeDeliveryRun?.dock.id,
                        }
                      : null,
                    status: activeDeliveryRun?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveDeliveryRun(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newDeliveryRun ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewDeliveryRun(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Delivery Run - "
                  initialValues={{
                    name: "",
                    dock: null,
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewDeliveryRun(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DeliveryRunList;
export const DeliveryRunListResource: ResourceProps = {
  name: "Delivery Runs",
  description: "A list of delivery runs",
  access: ["read-deliveryRuns"],
  path: "delivery-runs",
};
