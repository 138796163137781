import "simplebar/dist/simplebar.css";

import { Outlet } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { createGlobalStyle } from "styled-components";

import NavLayout from "./NavLayout";

const GlobalStyle = createGlobalStyle`
html {
  background-color: #000000;
}
`;
export function MasterLayout() {
  return (
    <>
      <GlobalStyle />
      <div className="h-full">
        <NavLayout />
        <div className="flex h-full flex-1 flex-col py-4 lg:pl-64">
          <main className="mx-2 h-full lg:ml-0 lg:mr-4">
            <SimpleBar className="h-full overflow-y-auto rounded-2xl bg-white lg:mx-auto">
              <Outlet />
            </SimpleBar>
          </main>
        </div>
      </div>
    </>
  );
}
