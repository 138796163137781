import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";
import { createGlobalStyle } from "styled-components";
import { TinyMCE } from "tinymce";

type EditorOptions = Parameters<TinyMCE["init"]>[0];

const GlobalStyle = createGlobalStyle`
html .tox-tinymce {
  z-index: 0;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
`;
export function FieldEditor({
  title,
  id,
  name,
  onChange,
  className,
  limit,
  label = true,
  value,
  touched,
  errors,
  ...props
}: {
  title: string;
  name: string;
  id?: string;
  value: string;
  onChange: any;
  className?: string;
  init?: EditorOptions & {
    selector?: undefined;
    target?: undefined;
  };
  limit?: number;
  touched?: boolean | undefined;
  errors?: string | undefined;
  label?: boolean;
}) {
  const editorRef = useRef(null);

  const sizeLimit = limit ?? 0;
  const [length, setLength] = React.useState(0);

  const handleInit = (evt: any, editor: any) => {
    setLength(editor.getContent({ format: "text" }).length); //A method for retrieving the character count in the textarea
    // @ts-ignore
    return (editorRef.current = editor);
  };

  const handleUpdate = (value: any, editor: any) => {
    onChange(value, editor);
    const length = editor.getContent({ format: "text" }).length;
    if (length <= sizeLimit) {
      setLength(length);
    }
  };

  const handleBeforeAddUndo = (evt: any, editor: any) => {
    const length = editor.getContent({ format: "text" }).length;
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > sizeLimit) {
      evt.preventDefault();
    }
  };

  return (
    <>
      <label
        htmlFor={id ? id : name}
        className={`block text-sm font-medium text-gray-900 ${
          label ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </label>
      <div className={className} {...props}>
        <GlobalStyle />
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          value={value}
          init={{
            height: 400,
            menubar: false,
            toolbar:
              "formatselect | " +
              "bold italic backcolor forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            ...props.init,
          }}
          onInit={handleInit}
          onEditorChange={handleUpdate}
          onBeforeAddUndo={handleBeforeAddUndo}
        />
        {sizeLimit !== 0 && (
          <p className="mt-3 text-xs">Remaining: {sizeLimit - length}</p>
        )}
      </div>
      {touched && errors ? (
        <p className="mt-2 text-sm text-red-600" id="editor-errors">
          {errors}
        </p>
      ) : null}
    </>
  );
}
