import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import { DebounceInput } from "react-debounce-input";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { useReactToPrint } from "react-to-print";

import { ProgressBar } from "../../../animations";
import placeholder from "../../../assets/placeholder.svg";
import { ButtonDropdown } from "../../../components/appearance";
import { ErrorFallback, Head } from "../../../components/core";
import {
  Button,
  FieldDatepicker,
  FieldDepartmentPicker,
  selectStyles,
  SelectWrapper,
} from "../../../components/form";
import {
  IndeterminateCheckbox,
  LimitBy,
  TableCursor,
} from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  DeliveryRun,
  GET_DELIVERYRUNS,
} from "../../../graphql/fleets/settings/delivery-runs";
import {
  Department,
  GET_DEPARTMENTS,
} from "../../../graphql/inventory/departments";
import { GET_LOCATIONS } from "../../../graphql/inventory/locations";
import { BinLocation } from "../../../graphql/inventory/products";
import {
  EDIT_PICKING_STATUS,
  GET_PICKING,
  type Picking,
  PickingStatus,
  renderPickingStatus,
  renderPickingStatusText,
} from "../../../graphql/pickpack/picking";
import { OrderStatus, renderStatus } from "../../../graphql/sales/orders";
import {
  FETCH_GENERAL_SETTINGS,
  General,
  ZonePicking,
} from "../../../graphql/settings/general";
import { EDIT_ASSIGN_PICKING } from "../../../graphql/user-management/assign-zone-pickers";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { IconFilterClear } from "../../../svgs";
import {
  classNames,
  formatCurrency,
  formatDate,
  formatFloat,
  formatVariantTitle,
} from "../../../utils";
import FormManual from "./components/FormManual";
import { PrintTemplate } from "./components/PrintTemplate";

type ExportMethod = "current" | "all" | "filtered" | "selected";
type ExportFormat = "csv" | "pdf";

const PickingList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();

  const [refetching, setRefetching] = useState<boolean>(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string | null>(
    searchParams.get("query") || ""
  );
  const [binLocation, setBinLocation] =
    useState<SingleValue<OptionProps>>(null);
  const [deliveryRun, setDeliveryRun] =
    useState<SingleValue<OptionProps>>(null);
  const [department, setDepartment] = useState<SingleValue<OptionProps>>(null);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [dateBy, setDateBy] = useState<"orderdate" | "deliverydate" | null>(
    null
  );

  const [isMorefetching, setIsMorefetching] = useState(false);
  const [pageLimit] = useState<LimitBy>(LimitBy.L4);

  const [rowSelection, setRowSelection] = useState({});

  const [zonePicking, setZonePicking] = useState<ZonePicking>(0);

  const { loading: loadingGeneralSettings } = useQuery<{
    fetchGeneralSettings: General;
  }>(FETCH_GENERAL_SETTINGS, {
    onCompleted(data) {
      setZonePicking(data.fetchGeneralSettings.zonePicking);
    },
  });

  useEffect(() => {
    setSearchQuery(searchParams.get("query") || "");
  }, [searchParams]);

  const handleClearFilters = useCallback(() => {
    setSearchParams({ query: "" });
    setBinLocation(null);
    setDeliveryRun(null);
    setDepartment(null);
    setDateBy(null);
  }, [setSearchParams]);

  const {
    data: binLocationsData,
    loading: binLocationsLoading,
    refetch: binLocationsRefetch,
  } = useQuery(GET_LOCATIONS);

  const {
    data: deliveryRunsData,
    loading: deliveryRunsLoading,
    refetch: deliveryRunsRefetch,
  } = useQuery(GET_DELIVERYRUNS);

  const {
    data: departmentsData,
    loading: departmentsLoading,
    refetch: departmentsRefetch,
  } = useQuery(GET_DEPARTMENTS);

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");
  if (binLocation) searchFilter.push("binlocation");
  if (deliveryRun) searchFilter.push("deliveryrun");
  if (department) searchFilter.push("department");
  if (dateBy && fromDate && toDate) searchFilter.push(dateBy);

  const { data, loading, error, refetch, fetchMore } = useQuery(GET_PICKING, {
    variables: {
      first: pageLimit,
      searchQuery,
      searchFilter,
      binLocationId: binLocation ? binLocation.value : null,
      deliveryrunId: deliveryRun ? deliveryRun.value : null,
      departmentId: department ? department.value : null,
      fromDate,
      toDate,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const totalProducts = useMemo(
    () => data?.fetchPickings?.totalCount || 0,
    [data?.fetchPickings?.totalCount]
  );

  const pickings: Picking[] = useMemo(
    () =>
      data?.fetchPickings.edges?.flatMap((edge: { node: Picking }) => ({
        ...edge.node,
      })) ?? [],
    [data?.fetchPickings.edges]
  );

  const filtering: boolean = useMemo(
    () =>
      searchFilter.length > 0 ||
      fromDate !== "" ||
      toDate !== "" ||
      deliveryRun ||
      binLocation ||
      !!searchQuery
        ? true
        : false,

    [
      searchFilter.length,
      fromDate,
      toDate,
      deliveryRun,
      binLocation,
      searchQuery,
    ]
  );

  const fetchNextPage = useCallback(
    (table: any) => {
      setIsMorefetching(true);
      fetchMore({
        variables: {
          cursor: data?.fetchPickings?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setTimeout(() => {
          setIsMorefetching(false);
          table.setPageIndex(table.getPageCount() - 1);
        }, 500);
      });
    },
    [data?.fetchPickings?.pageInfo?.endCursor, fetchMore]
  );

  const [exportNotify, setExportNotify] = useState(false);
  const ExportAlert = useAlert({
    open: exportNotify,
    title: "Picking products export started",
    message: (
      <p className="text-sm text-gray-500">
        Products will be exported and send to your email shortly. Please check
        your email for the exported file. This may take a few minutes.
      </p>
    ),
    type: AlertType.SUCCESS,
    modal: AlertModal.CENTERED_SINGLE_ACTION,
    delay: 5000,
    onDismiss() {
      setExportNotify(false);
    },
  });

  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [showExport, setShowExport] = useState<boolean>(false);
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const [currentPickings, setCurrentPickings] = useState<Picking[]>([]);
  const [selectedExportMethod, setSelectedExportMethod] =
    useState<ExportMethod>("current");
  const [selectedExportFormat, setSelectedExportFormat] =
    useState<ExportFormat>("csv");
  const exportMethodOptions = useMemo<
    {
      id: ExportMethod;
      title: string;
      disabled: boolean;
    }[]
  >(
    () => [
      { id: "current", title: "Current Page", disabled: false },
      { id: "all", title: "All Products", disabled: false },
      { id: "filtered", title: "Filtered Products", disabled: !filtering },
      {
        id: "selected",
        title: "Selected Products",
        disabled: Object.keys(rowSelection).length === 0,
      },
    ],
    [filtering, rowSelection]
  );
  const exportFormatOptions = useMemo<
    {
      id: ExportFormat;
      title: string;
      disabled: boolean;
    }[]
  >(() => [{ id: "csv", title: "CSV", disabled: false }], []);

  const UPDATE_PICKING_EXPORT = gql`
    mutation PickingExport($ids: [ID!], $fromDate: String, $toDate: String) {
      pickingExport(
        input: { ids: $ids, fromDate: $fromDate, toDate: $toDate }
      ) {
        message
      }
    }
  `;
  const [exportPickingProduct] = useMutation(UPDATE_PICKING_EXPORT);

  const handleExportEmail = useCallback(async () => {
    setLoadingExport(true);
    switch (selectedExportMethod) {
      case "all":
        return await exportPickingProduct({
          variables: {
            ids: "",
            fromDate: "",
            toDate: "",
          },
        })
          .then(({ data }) => {
            if (data?.pickingExport?.message) {
              setShowExport(false);
              setExportNotify(true);
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Picking products export failed",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            addNotify({
              type: NotifyType.ERROR,
              title: "Picking products export failed",
              message: error.message,
            });
          })
          .finally(() => {
            setLoadingExport(false);
          });
      case "filtered":
        return await exportPickingProduct({
          variables: {
            ids: "",
            fromDate,
            toDate,
          },
        })
          .then(({ data }) => {
            if (data?.pickingExport?.message) {
              setShowExport(false);
              setExportNotify(true);
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Picking products export failed",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            addNotify({
              type: NotifyType.ERROR,
              title: "Picking products export failed",
              message: error.message,
            });
          })
          .finally(() => {
            setLoadingExport(false);
          });
    }
  }, [addNotify, exportPickingProduct, fromDate, selectedExportMethod, toDate]);

  const columns = useMemo<ColumnDef<Picking, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Order Number",
      },
      {
        accessorKey: "customer",
        header: "To",
        enableHiding: false,
        cell: ({ row }) => (
          <span className="whitespace-nowrap">
            {row.original.customer?.customerName}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        header: () => <span className="whitespace-nowrap">Order Date</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {formatDate(props.getValue<string>())}
          </span>
        ),
      },
      {
        accessorKey: "deliveryRun",
        header: "Delivery Run",
      },
      {
        accessorKey: "status",
        header: () => <span className="whitespace-nowrap">Progress</span>,
        cell: (props) => {
          const progress = formatFloat(
            (props.row.original.orderDetails.filter(
              (od) => od.pickingStatus === PickingStatus.PICKED
            ).length /
              props.row.original.orderDetails.length) *
              100
          ).toFixed(0);
          return (
            <div className="flex items-center space-x-2">
              <ProgressBar progress={parseInt(progress)} className="text-xs" />
              {renderStatus(props.getValue<number>())}
            </div>
          );
        },
      },
    ],
    []
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  const {
    selected: selectedPickings,
    picked: pickedPickings,
    unpicked: unpickedPickings,
  } = useMemo(() => {
    const selected = pickings.filter((_, idx) =>
      Object.keys(rowSelection).includes(idx.toString())
    );
    const picked = selected.filter((p) => p.status === OrderStatus.PICKED);
    const unpicked = selected.filter((p) => p.status !== OrderStatus.PICKED);
    return { selected, picked, unpicked };
  }, [pickings, rowSelection]);

  const formatedExport = useCallback(() => {
    let data: Picking[] = [];

    switch (selectedExportMethod) {
      case "all":
      case "filtered":
        data = pickings;
        break;
      case "current":
        data = currentPickings;
        break;
      case "selected":
        const selectedIdx = Object.keys(rowSelection);
        data = pickings.filter((_, idx) =>
          selectedIdx.includes(idx.toString())
        );
        break;
    }

    const headers = [
      { label: "Name", key: "name" },
      { label: "Stock Code", key: "stockCode" },
      { label: "Variant", key: "variantTitle" },
      { label: "Quantity", key: "quantity" },
      { label: "Bin Location", key: "binLocation" },
      { label: "Status", key: "orderStatus" },
    ];

    return {
      data,
      headers,
    };
  }, [currentPickings, pickings, rowSelection, selectedExportMethod]);

  const orderComponentRef = useRef(null);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const orderPrintContent = useCallback(() => {
    return orderComponentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: orderPrintContent,
    documentTitle: `Alpha Fresh - Picking Products - ${new Date().toISOString()}`,
    onBeforeGetContent: () => {
      setLoadingPrint(true);
    },
    onAfterPrint: () => {
      setLoadingPrint(false);
    },
    removeAfterPrint: true,
  });

  const [assignPicking, { loading: loadingAssignPicking }] = useMutation(
    EDIT_ASSIGN_PICKING,
    {
      refetchQueries: [
        {
          query: GET_PICKING,
          variables: {
            first: pageLimit,
            searchQuery,
            searchFilter,
            binLocationId: null,
            deliveryrunId: null,
            departmentId: null,
            fromDate,
            toDate,
          },
        },
      ],
    }
  );
  const handleAssign = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    assignPicking({
      variables: {
        orders: values.orders ? values.orders : [],
        orderIds: values.orderIds ? values.orderIds : [],
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.assignPicking) {
          await refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pickers assigned successfully",
            message: data.assignPicking.message,
          });
          setShowAssign(false);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pickers assign failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pickers assign failed",
          message: error.message,
        });
      });
  };

  const handleStartZonePicking = useCallback(() => {
    assignPicking({
      variables: {
        orderIds: unpickedPickings.map((p) => parseInt(p.id)),
      },
    })
      .then(async ({ data }) => {
        if (data?.assignPicking) {
          await refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Picker assigned successfully",
            message: data.assignPicking.message,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Picker assign failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Pickers assign failed",
          message: error.message,
        });
      });
  }, [addNotify, assignPicking, refetch, unpickedPickings]);

  type AssignSingleParams = {
    order?: {
      id: string;
      orderProducts: {
        pickerId: number | null;
        productSkuId: string;
      }[];
    };
    orderId?: string;
  };
  const handleAssignSingle = useCallback(
    ({ order, orderId }: AssignSingleParams) => {
      assignPicking({
        variables: {
          orders: order ? [order] : [],
          orderIds: orderId ? [orderId] : [],
        },
      })
        .then(async ({ data }) => {
          if (data?.assignPicking) {
            await refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Picker assigned successfully",
              message: data.assignPicking.message,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Picker assign failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pickers assign failed",
            message: error.message,
          });
        });
    },
    [addNotify, assignPicking, refetch]
  );

  const [pickingStatusUpdate] = useMutation(EDIT_PICKING_STATUS, {
    refetchQueries: [
      {
        query: GET_PICKING,
        variables: {
          first: pageLimit,
          searchQuery,
          searchFilter,
          binLocationId: null,
          deliveryrunId: null,
          departmentId: null,
          fromDate,
          toDate,
        },
      },
    ],
  });

  const handleMoveToPacking = useCallback(
    async () =>
      pickingStatusUpdate({
        variables: {
          orderIds: pickedPickings.map((p) => parseInt(p.id)),
          pickingStatus: OrderStatus.PACKING,
        },
      })
        .then(({ data }) => {
          if (data?.pickingStatusUpdate) {
            refetch();
            toast.success("Orders are moved to packing section");
          } else {
            toast.error("Something went wrong, please try again later");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        }),
    [pickedPickings, pickingStatusUpdate, refetch]
  );

  const handleReadyToShipping = useCallback(
    async () =>
      pickingStatusUpdate({
        variables: {
          orderIds: pickedPickings.map((p) => parseInt(p.id)),
          pickingStatus: OrderStatus.READY_SHIP,
        },
      })
        .then(({ data }) => {
          if (data?.pickingStatusUpdate) {
            refetch();
            toast.success("Orders are moved to shipping section");
          } else {
            toast.error("Something went wrong, please try again later");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        }),
    [pickedPickings, pickingStatusUpdate, refetch]
  );

  const [alert, setAlert] = useState<boolean>(false);
  const ShippingAlert = useAlert({
    open: alert ? true : false,
    title: "You are trying to move the order directly to shipping section.",
    message: (
      <p className="text-sm text-gray-500">
        Please ensure that items are packed before marking as Ready to Ship.
      </p>
    ),
    type: AlertType.WARNING,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleReadyToShipping();
      setAlert(false);
    },
    onCancel: () => {
      setAlert(false);
    },
  });

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Picking Products"
        heading="Picking"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: PickingListResource.name,
            href: null,
          },
        ]}
      />
      <ExportAlert />
      <ShippingAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PickingListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PickingListResource.description}
            </p>
          </div>
        </div>

        <TableCursor
          data={pickings}
          columns={columns}
          loading={loading || loadingGeneralSettings}
          totalRows={totalProducts}
          pageLimit={pageLimit}
          pageLimits={[pageLimit]}
          filtering={filtering}
          state={{
            rowSelection,
          }}
          enableRowSelection={true}
          onRowSelectionChange={setRowSelection}
          getRowCanExpand={() => true}
          getCoreRowModel={getCoreRowModel()}
          getExpandedRowModel={getExpandedRowModel()}
          renderSubComponent={(row) => {
            return (
              <div className="bg-primary-700/20 px-4 md:px-6 xl:px-16">
                <table className="min-w-full divide-y divide-gray-900/60">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell lg:pl-0"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell lg:w-44"
                      >
                        Product Name
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        Stock Code
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        Variant
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        Department
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium lg:table-cell"
                      >
                        Bin Location
                      </th>
                      <th
                        scope="col"
                        className="text-gray-900/700 hidden px-3 py-3.5 text-left text-sm font-medium sm:table-cell lg:text-center"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium lg:text-center"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium lg:text-center"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium lg:text-left"
                      >
                        Picker
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-900/10">
                    {row.original.orderDetails?.length
                      ? row.original.orderDetails.map((item, index) => (
                          <Fragment
                            key={`picking-products-${row.original.id}-${item.id}`}
                          >
                            <tr key={item.id}>
                              <td className="px-3 py-4 text-sm lg:pl-0">
                                {index + 1}
                              </td>
                              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm sm:w-auto sm:max-w-none">
                                <div>
                                  <div className="flex items-center">
                                    <div className="h-11 w-11 flex-shrink-0 overflow-hidden rounded-md">
                                      {item.variant.variantImageUrl ? (
                                        <img
                                          className={classNames(
                                            "h-full w-full object-cover"
                                          )}
                                          src={item.variant.variantImageUrl}
                                          alt={item.variant?.product?.name}
                                        />
                                      ) : (
                                        <img
                                          className={classNames(
                                            "h-full w-full object-cover"
                                          )}
                                          src={
                                            item.variant?.product
                                              ?.featureImageUrl
                                              ? item.variant.product
                                                  .featureImageUrl
                                              : placeholder
                                          }
                                          alt={item.variant?.product?.name}
                                        />
                                      )}
                                    </div>
                                    <div className="ml-4">
                                      <div className="font-normal text-gray-900">
                                        {item.variant?.product?.name}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <dl className="font-normal lg:hidden">
                                  <dt className="sr-only">Stock Code</dt>
                                  <dd className="mt-1 truncate">
                                    {item.variant.stockCode}
                                  </dd>
                                  <dt className="sr-only">Variant</dt>
                                  <dd className="mt-1 truncate">
                                    {formatVariantTitle(
                                      item.variant.variantTitle
                                    )}
                                  </dd>
                                  <dt className="sr-only">Department</dt>
                                  <dd className="mt-1 truncate">
                                    {item.variant.product?.department?.name}
                                  </dd>
                                  <dt className="sr-only">Bin Location</dt>
                                  <dd className="mt-1 truncate">
                                    {item.variant.product?.binLocation?.name}
                                  </dd>
                                  <dt className="sr-only sm:hidden">
                                    Quantity
                                  </dt>
                                  <dd className="mt-1 truncate sm:hidden">
                                    {item.quantity}
                                  </dd>
                                </dl>
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {item.variant.stockCode}
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {formatVariantTitle(item.variant.variantTitle)}
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {item.variant.product?.department?.name}
                              </td>
                              <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                {item.variant.product?.binLocation?.name}
                              </td>
                              <td className="hidden px-3 py-4 text-center text-sm sm:table-cell">
                                <span className="inline-block rounded-md border border-gray-900/30 px-4 py-2">
                                  {item.quantity}
                                </span>
                              </td>
                              <td className="px-3 py-4 text-center text-sm">
                                {formatCurrency(item.price)}
                              </td>
                              <td className="px-3 py-4 text-center text-sm">
                                {renderPickingStatus(item.pickingStatus)}
                              </td>
                              <td className="px-3 py-4 text-left text-sm">
                                {item.variant.product?.department ? (
                                  <FieldDepartmentPicker
                                    title="Picker"
                                    departmentId={
                                      item.variant.product.department.id
                                    }
                                    value={item.pickerId}
                                    onChange={(value) => {
                                      if (value === item.pickerId) return;
                                      handleAssignSingle({
                                        order: {
                                          id: row.original.id,
                                          orderProducts: [
                                            {
                                              pickerId: value,
                                              productSkuId: item.variant.id,
                                            },
                                          ],
                                        },
                                      });
                                    }}
                                    className={classNames(
                                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                                    )}
                                    isPreview={
                                      zonePicking === ZonePicking.AUTOMATIC
                                    }
                                    loading={loadingAssignPicking}
                                    disabled={
                                      loadingAssignPicking ||
                                      row.original.status === OrderStatus.PICKED
                                    }
                                  />
                                ) : null}
                              </td>
                            </tr>
                            {item.packingComments ? (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="w-full px-4 py-4 text-sm sm:px-0"
                                >
                                  <div className="flex bg-white/50">
                                    <div className="bg-black px-4 py-2 text-white md:px-6 md:py-3 xl:px-8 xl:py-4">
                                      Comments
                                    </div>
                                    <div className="px-4 py-2 md:px-6 md:py-3 xl:px-8 xl:py-4">
                                      {item.packingComments ??
                                        "No comments found for item"}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : null}
                          </Fragment>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            );
          }}
          enableAction
          renderAction={(table) => (
            <Fragment>
              <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap xl:items-stretch">
                <div className="flex flex-1 items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                  <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <DebounceInput
                    type="search"
                    className={classNames(
                      "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                      "shadow-none outline-none focus:ring-0",
                      "placeholder-black/50 placeholder-opacity-100"
                    )}
                    placeholder="Search order number, customer name, email, and customer code"
                    minLength={2}
                    debounceTimeout={300}
                    value={searchQuery ?? ""}
                    onChange={(e) => {
                      setSearchParams({
                        query: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                    <Select
                      className="h-11 w-full xl:whitespace-nowrap"
                      closeMenuOnSelect={true}
                      styles={selectStyles}
                      value={binLocation}
                      options={binLocationsData?.fetchBinLocations.map(
                        (d: BinLocation) => ({
                          value: d.id,
                          label: d.name,
                        })
                      )}
                      onChange={setBinLocation}
                      isLoading={binLocationsLoading}
                      placeholder="Filter by bin location"
                      isClearable
                      onMenuOpen={() => {
                        binLocationsRefetch();
                      }}
                    />
                  </SelectWrapper>
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                    <Select
                      className="h-11 w-full xl:whitespace-nowrap"
                      closeMenuOnSelect={true}
                      styles={selectStyles}
                      value={deliveryRun}
                      options={deliveryRunsData?.fetchDeliveryRuns.map(
                        (d: DeliveryRun) => ({
                          value: d.id,
                          label: d.name,
                        })
                      )}
                      onChange={setDeliveryRun}
                      isLoading={deliveryRunsLoading}
                      placeholder="Filter by delivery run"
                      isClearable
                      onMenuOpen={() => {
                        deliveryRunsRefetch();
                      }}
                    />
                  </SelectWrapper>
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                    <Select
                      className="h-11 w-full xl:whitespace-nowrap"
                      closeMenuOnSelect={true}
                      styles={selectStyles}
                      value={department}
                      options={departmentsData?.fetchDepartments.map(
                        (d: Department) => ({
                          value: d.id,
                          label: d.name,
                        })
                      )}
                      onChange={setDepartment}
                      isLoading={departmentsLoading}
                      placeholder="Filter by department"
                      isClearable
                      onMenuOpen={() => {
                        departmentsRefetch();
                      }}
                    />
                  </SelectWrapper>
                </div>
              </div>
              <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap xl:items-stretch xl:justify-end">
                <RadioGroup
                  value={dateBy}
                  onChange={setDateBy}
                  className="flex flex-1 flex-wrap items-center space-x-2 rounded-lg border border-gray-200 bg-white p-1 pl-4 xl:flex-nowrap"
                >
                  <RadioGroup.Label className="flex flex-1 items-center text-sm text-black/60">
                    <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-2.5 block h-4 w-[1px] bg-gray-400"></span>
                    <span className="ml-2.5">Select date by</span>
                  </RadioGroup.Label>
                  <RadioGroup.Option
                    value="orderdate"
                    className={({ active, checked }) =>
                      classNames(
                        checked
                          ? "border-primary-700 bg-primary-700 text-white hover:bg-primary-600"
                          : "border-gray-200 bg-gray-50 text-gray-900 ring-0 hover:bg-gray-100",
                        "flex cursor-pointer items-center justify-center rounded-md border px-3 py-1.5 text-sm md:px-5 xl:px-8"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">Order Date</RadioGroup.Label>
                  </RadioGroup.Option>

                  <RadioGroup.Option
                    value="deliverydate"
                    className={({ active, checked }) =>
                      classNames(
                        checked
                          ? "border-primary-700 bg-primary-700 text-white hover:bg-primary-600"
                          : "border-gray-200 bg-gray-50 text-gray-900 ring-0 hover:bg-gray-100",
                        "flex cursor-pointer items-center justify-center rounded-md border px-3 py-1.5 text-sm md:px-5 xl:px-8"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">Delivery Date</RadioGroup.Label>
                  </RadioGroup.Option>
                </RadioGroup>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <CalendarDaysIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <FieldDatepicker
                    title={t("text_start_date")}
                    name="startDate"
                    onChange={(value) => {
                      if (!Array.isArray(value)) {
                        setFromDate(value ? new Date(value).toISOString() : "");
                      }
                    }}
                    selected={fromDate ? new Date(fromDate) : null}
                    isClearable
                    isLabel={false}
                    placeholderText="Start date"
                    className="border-none focus-visible:ring-0"
                    disableIcon
                  />
                </div>
                <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                  <CalendarDaysIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                  <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                  <FieldDatepicker
                    title={t("text_end_date")}
                    name="endDate"
                    onChange={(value) => {
                      if (!Array.isArray(value)) {
                        setToDate(value ? new Date(value).toISOString() : "");
                      }
                    }}
                    selected={toDate ? new Date(toDate) : null}
                    minDate={fromDate ? new Date(fromDate) : null}
                    isClearable
                    isLabel={false}
                    placeholderText="End date"
                    className="border-none focus-visible:ring-0"
                    disableIcon
                  />
                </div>
                <Button
                  variant="icon"
                  className="relative flex h-11 w-11 items-center justify-center rounded-md text-gray-600 transition-all hover:bg-primary-200 hover:text-primary-900"
                  onClick={handleClearFilters}
                  disabled={loading}
                >
                  <IconFilterClear className="h-5 w-5" />
                  <span className="sr-only">Clear Filters</span>
                </Button>
              </div>
              <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap xl:items-stretch xl:justify-end">
                {selectedPickings.length > 0 && (
                  <Fragment>
                    {unpickedPickings.length ? (
                      zonePicking === ZonePicking.MANUAL ? (
                        <Button
                          variant="tertiary"
                          onClick={() => {
                            setShowAssign(true);
                          }}
                          className="mr-2"
                          disabled={loading || loadingAssignPicking}
                        >
                          <span className="bi bi-people mr-2 h-5 w-5 text-center text-xl leading-5" />
                          {t("text_assign_batch_picking")}
                        </Button>
                      ) : (
                        <Button
                          variant="tertiary"
                          onClick={handleStartZonePicking}
                          className="mr-2"
                          disabled={loading || loadingAssignPicking}
                        >
                          <span className="bi bi-people mr-2 h-5 w-5 text-center text-xl leading-5" />
                          {t("text_start_zone_picking")}
                        </Button>
                      )
                    ) : null}
                    {pickedPickings.length ? (
                      <Fragment>
                        <ButtonDropdown
                          childrens={[
                            {
                              label: t("text_move_to_packing"),
                              icon: () => (
                                <span
                                  className="bi bi-box-seam mr-2 h-5 w-5 text-current"
                                  aria-hidden="true"
                                />
                              ),
                              onClick: handleMoveToPacking,
                            },
                            {
                              label: t("text_ready_to_shiping"),
                              icon: () => (
                                <TruckIcon
                                  className="mr-2 h-5 w-5 text-current"
                                  aria-hidden="true"
                                />
                              ),
                              onClick: () => {
                                setAlert(true);
                              },
                            },
                          ]}
                          disabled={loading || loadingAssignPicking}
                        >
                          {t("text_move_options")}
                          <ChevronDownIcon
                            className="ml-1 h-5 w-5"
                            aria-hidden="true"
                          />
                        </ButtonDropdown>
                      </Fragment>
                    ) : null}
                  </Fragment>
                )}
                <Button
                  onClick={() => {
                    setShowExport(true);
                    setCurrentPickings(
                      table
                        .getPaginationRowModel()
                        .rows.map((row) => row.original)
                    );
                  }}
                  disabled={loading || pickings.length === 0}
                  className="mr-2"
                >
                  <span className="bi bi-filetype-csv mr-2 h-5 w-5 text-center text-xl leading-5" />
                  {t("text_export")}
                </Button>
                <Button
                  variant="icon"
                  onClick={handleRefetch}
                  disabled={loading}
                  className="flex h-11 items-center justify-center px-2 text-blue-700"
                >
                  <ArrowPathIcon
                    aria-hidden="true"
                    className={classNames(
                      "h-5 w-5",
                      refetching ? "animate-spin" : ""
                    )}
                  />
                  <span className="sr-only">Refresh picking list</span>
                </Button>
              </div>
            </Fragment>
          )}
          enableNavigation
          renderNavigation={(table) => (
            <Button
              variant="icon"
              onClick={() => {
                fetchNextPage(table);
              }}
              disabled={!data?.fetchPickings?.pageInfo?.hasNextPage}
              loading={isMorefetching}
              className="flex h-8 w-8 items-center justify-center rounded-md bg-primary-300 text-black disabled:bg-gray-200"
            >
              <span className="sr-only"> Go to next page</span>
              <span className="bi bi-chevron-bar-right"></span>
            </Button>
          )}
          fallbackTitle="No picking products found"
          fallbackText="Please create a order and move it to packing."
        />
      </div>
      <div className="hidden">
        <div ref={orderComponentRef}>
          <PrintTemplate pickings={formatedExport().data} />
        </div>
      </div>
      <Transition.Root show={showExport} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setShowExport(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1">
                    <div className="px-4 py-8 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          Export Picking
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setShowExport(false);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Export picking products
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pb-5">
                          <div>
                            <label className="text-base font-medium text-gray-900">
                              Export Products
                            </label>
                            <p className="text-sm text-gray-500">
                              Please select the export method.
                            </p>
                            <fieldset className="mt-4">
                              <legend className="sr-only">
                                Export Products
                              </legend>
                              <div className="space-y-2">
                                {exportMethodOptions.map((option) => (
                                  <div
                                    key={option.id}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={option.id}
                                      name="export-method"
                                      type="radio"
                                      checked={
                                        selectedExportMethod === option.id
                                      }
                                      disabled={option.disabled}
                                      onChange={() => {
                                        if (
                                          option.id === "all" ||
                                          option.id === "filtered"
                                        ) {
                                          setSelectedExportFormat("csv");
                                        }
                                        setSelectedExportMethod(option.id);
                                      }}
                                      className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600 disabled:border-gray-200 disabled:text-gray-600"
                                    />
                                    <label
                                      htmlFor={option.id}
                                      className={classNames(
                                        "ml-3 block text-sm font-normal leading-6",
                                        option.disabled
                                          ? "text-gray-500"
                                          : "text-gray-900"
                                      )}
                                    >
                                      {option.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                          </div>

                          <div>
                            <label className="text-base font-medium text-gray-900">
                              Export Format
                            </label>
                            <p className="text-sm text-gray-500">
                              Please select the export file format.
                            </p>
                            <fieldset className="mt-4">
                              <legend className="sr-only">Export Format</legend>
                              <div className="space-y-2">
                                {exportFormatOptions.map((option) => (
                                  <div
                                    key={option.id}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={option.id}
                                      name="export-format"
                                      type="radio"
                                      checked={
                                        selectedExportFormat === option.id
                                      }
                                      disabled={option.disabled}
                                      onChange={() => {
                                        setSelectedExportFormat(option.id);
                                      }}
                                      className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600 disabled:border-gray-200 disabled:text-gray-600"
                                    />
                                    <label
                                      htmlFor={option.id}
                                      className={classNames(
                                        "ml-3 block text-sm font-normal leading-6",
                                        option.disabled
                                          ? "text-gray-500"
                                          : "text-gray-900"
                                      )}
                                    >
                                      {option.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex px-4 py-6 sm:px-6">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShowExport(false);
                        }}
                        className="mr-2 flex-1 justify-center"
                      >
                        {t("text_cancel")}
                      </Button>
                      {(selectedExportMethod === "all" &&
                        totalProducts <= 50) ||
                      selectedExportMethod === "current" ||
                      (selectedExportMethod === "filtered" &&
                        totalProducts <= 50) ||
                      selectedExportMethod === "selected" ? (
                        <Fragment>
                          <Button
                            variant="info"
                            border
                            className="mr-2 flex-1 justify-center"
                            onClick={handlePrint}
                            disabled={loadingPrint}
                            loading={loadingPrint}
                          >
                            <span className="bi bi-printer mr-2 h-5 w-5 text-center text-xl leading-5" />
                            {t("text_print")}
                          </Button>
                          <CSVLink
                            data={formatedExport().data.flatMap((p) =>
                              p.orderDetails.map((product) => ({
                                name: product.variant?.product?.name,
                                stockCode: product.variant.stockCode,
                                variantTitle: product.variant.variantTitle,
                                quantity: product.quantity,
                                binLocation:
                                  product.variant.product?.binLocation?.name,
                                orderStatus: renderPickingStatusText(
                                  product.pickingStatus
                                ),
                              }))
                            )}
                            headers={formatedExport().headers}
                            filename={`picking-products-${new Date().toISOString()}.csv`}
                            className="inline-flex flex-1 items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-primary-700 px-3 py-2 text-base font-normal text-white transition hover:bg-primary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40 md:px-4"
                          >
                            <span className="bi bi-download mr-2 h-5 w-5 text-center text-xl leading-5" />
                            {t("text_export")}
                          </CSVLink>
                        </Fragment>
                      ) : (
                        <Button
                          className="flex-1 justify-center"
                          onClick={handleExportEmail}
                          loading={loadingExport}
                          disabled={loadingExport}
                        >
                          <span className="bi bi-download mr-2 h-5 w-5 text-center text-xl leading-5" />
                          {t("text_export")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={showAssign} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setShowAssign(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <FormManual
                  heading={`Assign Zone Pickers`}
                  initialValues={{
                    orders: selectedPickings.map((p) => ({
                      id: p.id,
                      orderProducts: p.orderDetails.map((op) => ({
                        pickerId: op.pickerId,
                        productSkuId: op.variant.id,
                      })),
                    })),
                  }}
                  pickings={selectedPickings}
                  department={department}
                  onSubmit={handleAssign}
                  submitLabel={t("text_assign")}
                  onCancel={() => setShowAssign(false)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default PickingList;
export const PickingListResource: ResourceProps = {
  name: "Picking",
  description: "A list of products that are ready to be picked",
  access: ["read-pickings"],
  path: "picking",
};
