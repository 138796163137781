import { useLazyQuery, useQuery } from "@apollo/client/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import { Button } from "../../../../../components/form";
import {
  GET_VEHICLE_BY_ID,
  Vehicle,
} from "../../../../../graphql/fleets/vehicles";
import {
  GET_SERVICEREMAINDER_BY_ID,
  VehicleServiceReminder,
} from "../../../../../graphql/fleets/vehicles/pages/service-reminder";
import { AuthShield } from "../../../../auth/core";
import { ServiceReminderDetail } from "../../components/ServiceReminderDetail";

const ServiceReminderView = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  let { serviceReminderId } = useParams();
  const { t } = useTranslation();

  const [fetchVehicle, { data: vehicleData }] = useLazyQuery<{
    fetchVehicle: Vehicle;
  }>(GET_VEHICLE_BY_ID);

  const { data, loading, error } = useQuery<{
    fetchServiceRemainder: VehicleServiceReminder;
  }>(GET_SERVICEREMAINDER_BY_ID, {
    variables: { id: serviceReminderId },
    onCompleted(data) {
      fetchVehicle({
        variables: { id: data?.fetchServiceRemainder?.vehicle.id },
      });
    },
  });

  const serviceReminder = useMemo<VehicleServiceReminder | undefined>(
    () => data?.fetchServiceRemainder,
    [data]
  );

  const vehicle = useMemo<Vehicle | undefined>(
    () => vehicleData?.fetchVehicle,
    [vehicleData]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={ServiceReminderViewResource.name}
        heading={ServiceReminderViewResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ServiceReminderViewResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_service_reminder")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_service_reminder")}
              </p>
            </div>
            <div className="ml-auto flex items-center space-x-2 pr-3">
              <AuthShield access={["read-vehicles"]}>
                <Button
                  type="link"
                  href={`/fleets/vehicles/service-reminders/${serviceReminderId}/edit`}
                >
                  <PencilIcon className="mr-2 h-5 w-5" />
                  {t("text_edit")}
                </Button>
              </AuthShield>
            </div>
          </div>

          {loading || !serviceReminder ? (
            <Waiting />
          ) : (
            <ServiceReminderDetail
              serviceReminder={serviceReminder}
              vehicleBasic={
                vehicle
                  ? {
                      id: parseInt(vehicle.id),
                      name: vehicle.name,
                      vehicleImageUrl: vehicle.vehicleImageUrl,
                      make: vehicle.make,
                      model: vehicle.model,
                      year: vehicle.year,
                      operator: vehicle.operator,
                      status: vehicle.status,
                      fuelUnit: vehicle?.settings?.length
                        ? vehicle?.settings[0].fuelUnit
                        : null,
                      measurementUnit: vehicle?.settings?.length
                        ? vehicle?.settings[0].measurementUnit
                        : null,
                      primaryMeter: vehicle?.settings?.length
                        ? vehicle?.settings[0].primaryMeter
                        : null,
                    }
                  : null
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceReminderView;
export const ServiceReminderViewResource: ResourceProps = {
  name: "Service Reminder",
  description: "View an existing service reminder",
  access: ["read-vehicles"],
  path: "/service-reminders/:serviceReminderId",
};
