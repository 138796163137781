import { gql } from "@apollo/client";

import { FleetVendor, FLEETVENDOR_FRAGMENT } from "../../../settings/vendors";

const LEASEDETAILS_FRAGMENT = gql`
  fragment VehicleFinancialLeaseFragment on VehicleFinancialLease {
    contractMileageCap
    cost
    dateOfLease
    downPayment
    excessMileageCap
    firstPaymentDate
    id
    leaseEndDate
    leaseNumber
    monthlyPayment
    noOfPayments
    notes
    residualValue
    vendor
  }
`;
const LOANDETAILS_FRAGMENT = gql`
  fragment VehicleFinancialLoanFragment on VehicleFinancialLoan {
    amountOfLoan
    annualPercentageRate
    dateOfLoan
    downPayment
    firstPaymentDate
    id
    lender
    loanEndDate
    monthlyPayment
    noOfPayments
    notes
  }
`;

export const VEHICLEFINANCIAL_FRAGMENT = gql`
  ${LEASEDETAILS_FRAGMENT}
  ${LOANDETAILS_FRAGMENT}
  ${FLEETVENDOR_FRAGMENT}
  fragment VehicleFinancialFragment on VehicleFinancial {
    id
    financeType
    leaseDetails {
      ...VehicleFinancialLeaseFragment
    }
    loanDetails {
      ...VehicleFinancialLoanFragment
    }
    maxOdometerValue
    notes
    odometer
    purchaseDate
    purchasePrice
    vendor {
      ...FleetVendorFragment
    }
    warrantyExpiryDate
  }
`;

export const GET_VEHICLEFINANCIAL_BY_VEHICLE_ID = gql`
  ${VEHICLEFINANCIAL_FRAGMENT}
  query FetchFinancialByVehicle($vehicleId: ID!) {
    fetchFinancialByVehicle(vehicleId: $vehicleId) {
      ...VehicleFinancialFragment
    }
  }
`;

export const ADD_VEHICLEFINANCIAL = gql`
  ${VEHICLEFINANCIAL_FRAGMENT}
  mutation VehicleFinancialCreate(
    $vehicleId: Int!
    $fleetVendorId: Int
    $purchaseDate: ISO8601DateTime
    $purchasePrice: Float
    $odometer: Int
    $notes: String
    $warrantyExpiryDate: ISO8601DateTime
    $maxOdometerValue: Int
    $financeType: Int
    $loanDetails: VehiclefinancialloanInput
    $leaseDetails: VehiclefinancialleaseInput
  ) {
    vehicleFinancialCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          fleetVendorId: $fleetVendorId
          purchaseDate: $purchaseDate
          purchasePrice: $purchasePrice
          odometer: $odometer
          notes: $notes
          warrantyExpiryDate: $warrantyExpiryDate
          maxOdometerValue: $maxOdometerValue
          financeType: $financeType
          loanDetails: $loanDetails
          leaseDetails: $leaseDetails
        }
      }
    ) {
      vehicleFinancial {
        ...VehicleFinancialFragment
      }
    }
  }
`;

export const EDIT_VEHICLEFINANCIAL = gql`
  ${VEHICLEFINANCIAL_FRAGMENT}
  mutation VehicleFinancialUpdate(
    $id: ID!
    $vehicleId: Int!
    $fleetVendorId: Int
    $purchaseDate: ISO8601DateTime
    $purchasePrice: Float
    $odometer: Int
    $notes: String
    $warrantyExpiryDate: ISO8601DateTime
    $maxOdometerValue: Int
    $financeType: Int
    $loanDetails: VehiclefinancialloanInput
    $leaseDetails: VehiclefinancialleaseInput
  ) {
    vehicleFinancialUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          fleetVendorId: $fleetVendorId
          purchaseDate: $purchaseDate
          purchasePrice: $purchasePrice
          odometer: $odometer
          notes: $notes
          warrantyExpiryDate: $warrantyExpiryDate
          maxOdometerValue: $maxOdometerValue
          financeType: $financeType
          loanDetails: $loanDetails
          leaseDetails: $leaseDetails
        }
      }
    ) {
      vehicleFinancial {
        ...VehicleFinancialFragment
      }
    }
  }
`;

export type VehicleFinancialLease = {
  id: string;
  contractMileageCap: string;
  cost: number;
  dateOfLease: string;
  downPayment: number;
  excessMileageCap: number;
  firstPaymentDate: string;
  leaseEndDate: string;
  leaseNumber: string;
  monthlyPayment: number;
  noOfPayments: number;
  notes: string;
  residualValue: string;
  vendor: string;
};
export type VehicleFinancialLoan = {
  id: string;
  amountOfLoan: number;
  annualPercentageRate: number;
  dateOfLoan: string;
  downPayment: number;
  firstPaymentDate: string;
  lender: string;
  loanEndDate: string;
  monthlyPayment: number;
  noOfPayments: number;
  notes: string;
};
export enum VehicleFinanceType {
  LOAN,
  LEASE,
  NO_FINANCIING,
}
export type VehicleFinancial = {
  id: string;
  financeType: VehicleFinanceType | null;
  leaseDetails: VehicleFinancialLease | null;
  loanDetails: VehicleFinancialLoan | null;
  maxOdometerValue: number | null;
  notes: string;
  odometer: number | null;
  purchaseDate: string | null;
  purchasePrice: number | null;
  vendor: FleetVendor;
  warrantyExpiryDate: string | null;
};

export type VehicleFinancialForm = Omit<
  VehicleFinancial,
  "id" | "vendor" | "leaseDetails" | "loanDetails"
> & {
  id?: string;
  fleetVendorId: number | null;
  leaseDetails: Omit<VehicleFinancialLease, "id"> | null;
  loanDetails: Omit<VehicleFinancialLoan, "id"> | null;
};
