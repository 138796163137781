import { gql } from "@apollo/client";

import { User, USER_FRAGMENT } from "../users";

export interface Picker extends User {}

export const GET_ASSIGN_ZONEPICKERS = gql`
  ${USER_FRAGMENT}
  query FetchAssignZonePickers($status: Boolean) {
    fetchDepartments(status: $status) {
      id
      name
      parent {
        id
        name
      }
      pickers {
        ...UserFragment
      }
    }
  }
`;

export const EDIT_ASSIGN_ZONEPICKERS = gql`
  mutation AssignZonePickers($departmentId: ID!, $zonePickerIds: [ID!]!) {
    assignZonePickers(
      input: { departmentId: $departmentId, zonePickerIds: $zonePickerIds }
    ) {
      message
    }
  }
`;

export const EDIT_ASSIGN_PICKING = gql`
  mutation AssignPicking($orders: [PickingInput!], $orderIds: [ID!]) {
    assignPicking(input: { orders: $orders, orderIds: $orderIds }) {
      message
    }
  }
`;

export type AssignZonePicker = {
  id: string;
  name: string;
  parent: AssignZonePicker | null;
  pickers: Picker[];
};

export const GET_WAREHOUSE_PICKERS = gql`
  ${USER_FRAGMENT}
  query FetchWarehousePickers {
    fetchWarehousePickers {
      ...UserFragment
    }
  }
`;

export type DepartmentZonepicker = {
  id: string;
  pickers: Picker[];
};
export const GET_DEPARTMENT_ZONEPICKERS = gql`
  ${USER_FRAGMENT}
  query FetchAssignZonePickers($id: ID!) {
    fetchDepartment(id: $id) {
      id
      pickers {
        ...UserFragment
      }
    }
  }
`;
