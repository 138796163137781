/**
 * @param title - The title of the notification
 * @optional @param message - message to display
 * @param onDismiss - function to dismiss the notification
 * @optional @param closeText - text to display in close button
 * @optional @param onCancel - async callback function to cancel the notification
 * @optional @param cancelText - text to display in cancel button
 * @optional @param onConfirm - async callback function to confirm the notification
 * @optional @param confirmText - text to display in confirm button
 * @param open - boolean to open or close the notification
 * @optional @param type - type of notification (success, danger, warning, info)
 * @optional @param modal - modal of notification (simple, condensed, actions-below, split-buttons)
 * @optional @param delay - auto dismiss notification after x seconds
 * @returns {JSX.Element}
 * @example useAlert({ open: true, title: "Success", message: "Successfully created user", type: AlertType.SUCCESS, delay: 3000 });
 */

import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  LifebuoyIcon,
  StarIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as ReactIs from "react-is";

import { classNames } from "../utils";

export enum AlertType {
  SUCCESS,
  DANGER,
  WARNING,
  INFO,
}

export enum AlertModal {
  CENTERED_SINGLE_ACTION,
  CENTERED_DOUBLE_ACTION,
  SIMPLE_WITH_DISMISS,
  SIMPLE_WITH_FOOTER,
}

interface AlertProps {
  closeText?: string;
  onConfirm?: () => void | Promise<void>;
  confirmText?: string;
  onCancel?: () => void | Promise<void>;
  cancelText?: string;
  type: AlertType;
  modal?: AlertModal;
  title: string;
  message?: string | React.ReactElement;
}

interface NotificationProps extends AlertProps {
  isOpen: boolean;
  onClose: () => void;
  dismissible: boolean;
}

interface RenderModalProps extends AlertProps {
  onClose: () => void;
  dismissible: boolean;
  focusRef: React.RefObject<HTMLButtonElement>;
}

interface UseAlertProps extends AlertProps {
  open: boolean;
  onDismiss?: () => void;
  delay?: number;
}

function renderIcon(type: number) {
  switch (type) {
    case AlertType.SUCCESS:
      return (
        <CheckIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
      );
    case AlertType.DANGER:
      return <TrashIcon className="h-6 w-6 text-red-400" aria-hidden="true" />;
    case AlertType.WARNING:
      return (
        <ExclamationTriangleIcon
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
        />
      );
    case AlertType.INFO:
      return (
        <LifebuoyIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />
      );
    default:
      return <StarIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />;
  }
}

function RenderModal({
  title,
  message,
  onClose,
  closeText,
  dismissible,
  onCancel,
  cancelText,
  onConfirm,
  confirmText,
  modal,
  type,
  focusRef,
}: RenderModalProps): JSX.Element {
  const confirmHandler = useCallback(async () => {
    if (onConfirm) {
      return await onConfirm();
    }
  }, [onConfirm]);

  const cancelHandler = useCallback(async () => {
    if (onCancel) {
      return await onCancel();
    }
  }, [onCancel]);

  switch (modal) {
    case AlertModal.CENTERED_DOUBLE_ACTION:
      return (
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div>
            <div
              className={classNames(
                "mx-auto flex h-12 w-12 items-center justify-center rounded-full",
                type === AlertType.SUCCESS
                  ? "bg-primary-100"
                  : type === AlertType.DANGER
                  ? "bg-red-100"
                  : type === AlertType.WARNING
                  ? "bg-yellow-100"
                  : type === AlertType.INFO
                  ? "bg-blue-100"
                  : "bg-gray-100"
              )}
            >
              {renderIcon(type)}
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="mt-2">
                {ReactIs.isElement(message) ? (
                  message
                ) : (
                  <p className="text-sm text-gray-500">{message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            {onConfirm && (
              <button
                type="button"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2  sm:col-start-2 sm:text-sm",
                  type === AlertType.SUCCESS
                    ? "bg-primary-600 hover:bg-primary-700 focus-visible:ring-primary-500"
                    : type === AlertType.DANGER
                    ? "bg-red-600 hover:bg-red-700 focus-visible:ring-red-500"
                    : type === AlertType.WARNING
                    ? "bg-yellow-600 hover:bg-yellow-700 focus-visible:ring-yellow-500"
                    : type === AlertType.INFO
                    ? "bg-blue-600 hover:bg-blue-700 focus-visible:ring-blue-500"
                    : "bg-gray-600 hover:bg-gray-700 focus-visible:ring-gray-500"
                )}
                onClick={confirmHandler}
              >
                {confirmText}
              </button>
            )}
            {onCancel && (
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                onClick={cancelHandler}
                ref={focusRef}
              >
                {cancelText}
              </button>
            )}
          </div>
        </Dialog.Panel>
      );
    case AlertModal.SIMPLE_WITH_DISMISS:
      return (
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          {dismissible && (
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={onClose}
              >
                <span className="sr-only">{closeText}</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          )}
          <div className="sm:flex sm:items-start">
            <div
              className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                type === AlertType.SUCCESS
                  ? "bg-primary-100"
                  : type === AlertType.DANGER
                  ? "bg-red-100"
                  : type === AlertType.WARNING
                  ? "bg-yellow-100"
                  : type === AlertType.INFO
                  ? "bg-blue-100"
                  : "bg-gray-100"
              } sm:mx-0 sm:h-10 sm:w-10`}
            >
              {renderIcon(type)}
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="mt-2">
                {ReactIs.isElement(message) ? (
                  message
                ) : (
                  <p className="text-sm text-gray-500">{message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            {onConfirm && (
              <button
                type="button"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md border border-transparent  px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
                  type === AlertType.SUCCESS
                    ? "bg-primary-600 hover:bg-primary-700 focus-visible:ring-primary-500"
                    : type === AlertType.DANGER
                    ? "bg-red-600 hover:bg-red-700 focus-visible:ring-red-500"
                    : type === AlertType.WARNING
                    ? "bg-yellow-600 hover:bg-yellow-700 focus-visible:ring-yellow-500"
                    : type === AlertType.INFO
                    ? "bg-blue-600 hover:bg-blue-700 focus-visible:ring-blue-500"
                    : "bg-gray-600 hover:bg-gray-700 focus-visible:ring-gray-500"
                )}
                onClick={confirmHandler}
              >
                {confirmText}
              </button>
            )}
            {onCancel && (
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={cancelHandler}
                ref={focusRef}
              >
                {cancelText}
              </button>
            )}
          </div>
        </Dialog.Panel>
      );
    case AlertModal.SIMPLE_WITH_FOOTER:
      return (
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div
                className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                  type === AlertType.SUCCESS
                    ? "bg-primary-100"
                    : type === AlertType.DANGER
                    ? "bg-red-100"
                    : type === AlertType.WARNING
                    ? "bg-yellow-100"
                    : type === AlertType.INFO
                    ? "bg-blue-100"
                    : "bg-gray-100"
                } sm:mx-0 sm:h-10 sm:w-10`}
              >
                {renderIcon(type)}
              </div>
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  {ReactIs.isElement(message) ? (
                    message
                  ) : (
                    <p className="text-sm text-gray-500">{message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            {onConfirm && (
              <button
                type="button"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
                  type === AlertType.SUCCESS
                    ? "bg-primary-600 hover:bg-primary-700 focus-visible:ring-primary-500"
                    : type === AlertType.DANGER
                    ? "bg-red-600 hover:bg-red-700 focus-visible:ring-red-500"
                    : type === AlertType.WARNING
                    ? "bg-yellow-600 hover:bg-yellow-700 focus-visible:ring-yellow-500"
                    : type === AlertType.INFO
                    ? "bg-blue-600 hover:bg-blue-700 focus-visible:ring-blue-500"
                    : "bg-gray-600 hover:bg-gray-700 focus-visible:ring-gray-500"
                )}
                onClick={confirmHandler}
              >
                {confirmText}
              </button>
            )}
            {onCancel && (
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={cancelHandler}
                ref={focusRef}
              >
                {cancelText}
              </button>
            )}
          </div>
        </Dialog.Panel>
      );
    default:
      return (
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div
              className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${
                type === AlertType.SUCCESS
                  ? "bg-primary-100"
                  : type === AlertType.DANGER
                  ? "bg-red-100"
                  : type === AlertType.WARNING
                  ? "bg-yellow-100"
                  : type === AlertType.INFO
                  ? "bg-blue-100"
                  : "bg-gray-100"
              }`}
            >
              {renderIcon(type)}
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {title}
              </Dialog.Title>
              <div className="mt-2">
                {ReactIs.isElement(message) ? (
                  message
                ) : (
                  <p className="text-sm text-gray-500">{message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
              ref={focusRef}
            >
              {closeText}
            </button>
          </div>
        </Dialog.Panel>
      );
  }
}
function AlertWidget({
  isOpen,
  onClose,
  closeText,
  dismissible,
  onCancel,
  cancelText,
  onConfirm,
  confirmText,
  modal,
  type,
  title,
  message,
}: NotificationProps) {
  const focusRef = useRef<HTMLButtonElement>(null);

  return isOpen ? (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={focusRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {RenderModal({
                modal,
                type,
                title,
                message,
                onClose,
                closeText,
                dismissible,
                onCancel,
                cancelText,
                onConfirm,
                confirmText,
                focusRef,
              })}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  ) : null;
}

export function useAlert({
  open,
  title,
  message,
  onDismiss,
  closeText = "Close",
  onCancel,
  cancelText = "Cancel",
  onConfirm,
  confirmText = "Confirm",
  modal = AlertModal.CENTERED_SINGLE_ACTION,
  type = AlertType.SUCCESS,
  delay = 5000,
}: UseAlertProps) {
  const [isOpen, setIsOpen] = useState(open);

  const clearAlertAlert = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
  }, [onDismiss]);

  useEffect(() => {
    setIsOpen(open);
    if (open) {
      if (onDismiss) {
        const timeout = setTimeout(() => {
          clearAlertAlert();
        }, delay);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, message, onDismiss, delay, open]);

  const rootModal = document.getElementById("root-modal") as HTMLElement;

  return () =>
    ReactDOM.createPortal(
      <AlertWidget
        isOpen={isOpen}
        title={title}
        message={message}
        type={type}
        modal={modal}
        dismissible={typeof onDismiss === "function"}
        onCancel={onCancel}
        cancelText={cancelText}
        onConfirm={onConfirm}
        confirmText={confirmText}
        onClose={() => {
          clearAlertAlert();
        }}
        closeText={closeText}
      />,
      rootModal
    );
}
