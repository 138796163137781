import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  FieldTableWarehousePickers,
} from "../../../../components/form";
import { Picker } from "../../../../graphql/user-management/assign-zone-pickers";

export default function Form({
  heading,
  initialValues,
  zonePickers,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: {
    departmentId: string;
    zonePickerIds: number[];
  };
  zonePickers: Picker[];
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();
  const [warehousePickers, setWarehousePickers] = useState<Picker[]>([]);

  useEffect(() => {
    setWarehousePickers(zonePickers);
  }, [zonePickers]);

  const ValidationSchema = Yup.object().shape({
    departmentId: Yup.string(),
    zonePickerIds: Yup.array().of(Yup.string()).min(1, "Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 py-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Get started by filling in the information below to create your
                new department.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <FieldTableWarehousePickers
                      title={t("text_warehouse_pickers")}
                      data={warehousePickers}
                      value={formik.values.zonePickerIds}
                      onChange={(warehousePickers) => {
                        formik.setFieldValue(
                          "zonePickerIds",
                          warehousePickers.map((warehousePicker) =>
                            parseInt(warehousePicker.id)
                          )
                        );
                        setWarehousePickers(warehousePickers);
                      }}
                    />
                    {formik.touched.zonePickerIds &&
                    formik.errors.zonePickerIds ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="roles-errors"
                      >
                        {formik.errors.zonePickerIds.toString()}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
