import { useQuery } from "@apollo/client/react";
import { Popover, Transition } from "@headlessui/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import {
  IndeterminateCheckbox,
  LimitBy,
  TableCursor,
} from "../../../components/table";
import {
  Customer,
  GET_ORDER_APPROVAL_PENDING,
  Order,
  ProductApprove,
  renderStatus,
} from "../../../graphql/sales/orders";
import { classNames, formatDate, renderAddress } from "../../../utils";
import { AuthShield } from "../../auth/core";

const OrderList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);

  const { data, loading, error } = useQuery(GET_ORDER_APPROVAL_PENDING);

  const orders = useMemo<Order[]>(
    () =>
      data?.fetchOrderApprovalPending.map((item: Order) => {
        return {
          ...item,
          createdAt: formatDate(item.createdAt),
          deliveryDate: formatDate(item.deliveryDate),
        };
      }) ?? [],
    [data?.fetchOrderApprovalPending]
  );

  const columns = useMemo<ColumnDef<Order, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Order Number",
        cell: ({ row }) => (
          <Link
            to={`/sales/orders/${row.original.id}`}
            className="text-gray-700 transition hover:text-primary-700"
          >
            #{row.original.orderNumber}
          </Link>
        ),
        accessorFn: (row) => row.orderNumber,
      },
      {
        accessorKey: "customer.email",
        header: "Customer",
        cell: ({ row }) => <CustomerPopover customer={row.original.customer} />,
        accessorFn: (row) => row.customer.email,
      },
      {
        accessorKey: "items",
        header: "Item(s) Status",
        cell: ({ row }) => {
          const pendingItems = row.original.items.reduce((acc, item) => {
            const isApproved = item.productApprove === ProductApprove.APPROVED;
            if (isApproved) {
              return acc;
            }
            return acc + 1;
          }, 0);
          return pendingItems ? (
            <span className="inline-flex items-center whitespace-nowrap rounded-full bg-red-50 px-2 text-xs font-medium leading-5 text-red-700">
              <span>Pending</span>
              <span className="ml-1 h-[14px] min-w-[14px] rounded-full border border-red-700 text-center text-[10px] leading-[13px]">
                {pendingItems}
              </span>
            </span>
          ) : null;
        },
      },
      {
        accessorKey: "total",
        header: "Order Total",
        cell: ({ row }) => {
          const total = row.original.items.reduce((acc, item) => {
            const isApproved = item.productApprove === ProductApprove.APPROVED;
            if (isApproved) {
              return acc + item.quantity * item.price;
            }
            return acc;
          }, 0);
          return <span className="font-medium text-gray-900">${total}</span>;
        },
      },
      {
        accessorKey: "createdAt",
        header: "Order Date",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "deliveryDate",
        header: "Delivery Date",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) => renderStatus(info.row.original.status),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-salesorders"]}>
              <Button
                type="link"
                href={`/sales/orders/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.orderNumber}
                </span>
              </Button>
            </AuthShield>
            {/* <AuthShield access={["destroy-salesorders"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.orderNumber,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.orderNumber}
                </span>
              </Button>
            </AuthShield> */}
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Orders"
        heading="Orders"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Orders",
            href: "/sales/orders",
          },
          {
            name: OrderApprovalResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {OrderApprovalResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {OrderApprovalResource.description}
            </p>
          </div>
        </div>

        <Fragment>
          <TableCursor
            data={orders}
            columns={columns}
            loading={loading}
            totalRows={orders.length}
            enableGlobalFilter={true}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            getCoreRowModel={getCoreRowModel()}
            fallbackTitle="No approval orders found"
            fallbackText="There are no approval orders to display at the moment."
          />
        </Fragment>
      </div>
    </>
  );
};

export default OrderList;
export const OrderApprovalResource: ResourceProps = {
  name: "Approval Orders",
  description: "A list of orders that need product approval",
  access: ["read-salesorders"],
  path: "orders/approval",
};

function CustomerPopover({ customer }: { customer: Customer }) {
  const address = customer.tradingAddresses.find(
    (address) => address.primaryAddress
  );
  return (
    <Popover className="relative flex justify-end">
      {({ open }) => (
        <div>
          <Popover.Button className="group inline-flex items-center whitespace-nowrap px-3 py-2 text-gray-700 transition hover:text-primary-700 focus-visible:outline-none">
            <span>{customer.customerName}</span>
            <ChevronDownIcon
              className={classNames(
                open ? "" : "text-opacity-70",
                "ml-2 h-4 w-4 text-primary-700 transition duration-150 ease-in-out group-hover:text-opacity-80"
              )}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-2 z-40 mt-4 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-2 md:w-44">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 110.9 96"
                enableBackground="new 0 0 110.9 96"
                className="absolute -top-3 right-0 -z-0"
                fill="#ffffff"
                style={{
                  filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                }}
              >
                <polygon points="110.9,0 0,96 110.9,96 " />
              </svg>
              <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
                <h3 className="mb-1 font-medium">{customer.customerName}</h3>
                {renderAddress(address)}
              </div>
              <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
                <Link
                  to={`/sales/customers/${customer.id}`}
                  target="_blank"
                  className="block text-center text-sm text-gray-500"
                >
                  View Customer
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
}
