import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

import {
  GET_PRICINGMETHODS,
  PricingMethod,
} from "../../graphql/inventory/settings/pricing-methods";
import { selectStyles, SelectWrapper } from ".";

export function FieldPricingMethod({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery(GET_PRICINGMETHODS, {
    variables: {
      status: true,
    },
  });

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchPricingMethods) {
      return data.fetchPricingMethods?.map((p: PricingMethod) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
