import React from "react";

import { classNames } from "../../utils";

export function renderStatusBoolean(status: boolean) {
  return (
    <div className="flex items-center">
      <span
        className={classNames(
          "mr-1.5 h-3 w-3 rounded-full",
          status ? "bg-green-500" : "bg-red-500"
        )}
        aria-hidden="true"
      />
      {status ? "Active" : "Inactive"}
    </div>
  );
}
