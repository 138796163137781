import { lazy, Suspense } from "react";
import type { LinkProps } from "react-router-dom";
import {
  Link,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
  useResolvedPath,
  useRoutes,
} from "react-router-dom";

import {
  LayoutSplashScreen,
  NotAuthorized,
  NotFound,
} from "../../components/core";
import HeaderLayout from "../../layouts/HeaderLayout";
import { classNames } from "../../utils";
import { isAuthorizedForResource, useAuth } from "../auth";
import { AccountResource } from "./pages/Account";
import { GeneralResource } from "./pages/General";
import { MultifactorAuthenticationResource } from "./pages/MultifactorAuthentication";
import { NotificationResource } from "./pages/Notification";
import { SecurityResource } from "./pages/Security";

const Account = lazy(() => import("./pages/Account"));
const Security = lazy(() => import("./pages/Security"));
const Notification = lazy(() => import("./pages/Notification"));
const General = lazy(() => import("./pages/General"));
const MultifactorAuthentication = lazy(
  () => import("./pages/MultifactorAuthentication")
);

function CustomLink({
  children,
  to,
  name,
  icon,
  child,
  ...props
}: LinkProps & {
  name: string;
  icon: any;
  child?: { name: string; href: string }[];
}) {
  let location = useLocation();
  let resolved = useResolvedPath(to);
  let match = location.pathname.match(resolved.pathname);
  let active = match ? true : false;

  return (
    <Link
      to={to}
      className={classNames(
        active ? "bg-primary-700/10 text-primary-700" : "",
        "group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-700 transition-colors focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
      )}
      aria-current={active ? "page" : undefined}
      {...props}
    >
      <span
        className={classNames(
          "mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
          icon
        )}
        aria-hidden="true"
      />
      <span className="flex-1 truncate">{name}</span>
    </Link>
  );
}

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
  {
    name: "Settings",
    href: "/settings",
  },
];

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentRole } = useAuth();

  const navigation = [
    {
      name: "General",
      path: "/settings/general",
      icon: "bi bi-gear",
      allow: isAuthorizedForResource(currentRole, GeneralResource.access),
    },
    {
      name: "Notification",
      path: "/settings/notification",
      icon: "bi bi-bell",
      allow: isAuthorizedForResource(currentRole, NotificationResource.access),
    },
    {
      name: "Security",
      path: "/settings/security",
      icon: "bi bi-key",
      allow: true,
    },
    {
      name: "Account",
      path: "/settings/account",
      icon: "bi bi-person",
      allow: true,
    },
  ];

  return (
    <div className="pb-6 lg:mx-auto xl:max-w-6xl xl:px-8 xl:pb-8">
      <HeaderLayout />
      <div className="rounded-xl bg-greyish p-5">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="pb-6 md:px-6 lg:col-span-3 lg:px-0 lg:pb-0">
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                className="mt-1 block w-full rounded-md border-gray-200 py-2 pl-3 pr-10 text-base shadow-sm focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 sm:text-sm"
                defaultValue={location.pathname}
                onChange={(e) => {
                  const to = e.target.value;
                  navigate(to);
                }}
              >
                {navigation.map((item) =>
                  item.allow ? (
                    <option key={item.name} value={item.path}>
                      {item.name}
                    </option>
                  ) : null
                )}
              </select>
            </div>
            <div className="hidden lg:block">
              <nav className="space-y-1">
                {navigation.map((item) =>
                  item.allow ? (
                    <CustomLink
                      key={item.name}
                      name={item.name}
                      icon={item.icon}
                      to={item.path}
                    />
                  ) : null
                )}
              </nav>
            </div>
          </aside>
          <div className="lg:col-span-9">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
export function SettingsPage() {
  const { currentUser, currentRole } = useAuth();

  let elements = !currentUser?.enableMfa
    ? [
        {
          path: MultifactorAuthenticationResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <MultifactorAuthentication breadcrumbs={breadcrumbs} />
            </Suspense>
          ),
        },
      ]
    : [
        {
          path: MultifactorAuthenticationResource.path,
          element: <Navigate to={AccountResource.path} />,
        },
      ];

  let routes: RouteObject[] = [
    {
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Navigate to={GeneralResource.path} replace={true} />,
        },
        {
          path: AccountResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Account breadcrumbs={breadcrumbs} />
            </Suspense>
          ),
        },
        {
          path: SecurityResource.path,
          element: (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Security breadcrumbs={breadcrumbs} />
            </Suspense>
          ),
        },
        {
          path: NotificationResource.path,
          element: isAuthorizedForResource(
            currentRole,
            NotificationResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Notification breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(NotificationResource.access.join(", "))}
            />
          ),
        },
        {
          path: GeneralResource.path,
          element: isAuthorizedForResource(
            currentRole,
            GeneralResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <General breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(GeneralResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    ...elements,
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
