import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { animated as a, useTrail } from "@react-spring/web";
import { lazy, Suspense } from "react";
import {
  Link,
  LinkProps,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
  useResolvedPath,
  useRoutes,
} from "react-router-dom";

import { LayoutSplashScreen, NotAuthorized } from "../../../components/core";
import PageLayout from "../../../layouts/PageLayout";
import { classNames } from "../../../utils";
import { isAuthorizedForResource, useAuth } from "../../auth";
import { VehicleCreateResource } from "./Create";
import { VehicleEditPage } from "./Edit";
import { VehicleListResource } from "./List";
import { DetailsResource } from "./pages/Details";
import { FinancialResource } from "./pages/Financial";
import { LifecycleResource } from "./pages/Lifecycle";
import { SettingsResource } from "./pages/Settings";
import { SpecificationsResource } from "./pages/Specifications";
import { ExpenseEntryCreateResource } from "./sections/expense-entries/Create";
import { ExpenseEntryListResource } from "./sections/expense-entries/List";
import { ExpenseEntryUpdateResource } from "./sections/expense-entries/Update";
import { ExpenseEntryViewResource } from "./sections/expense-entries/View";
import { FuelEntryCreateResource } from "./sections/fuel-entries/Create";
import { FuelEntryListResource } from "./sections/fuel-entries/List";
import { FuelEntryUpdateResource } from "./sections/fuel-entries/Update";
import { FuelEntryViewResource } from "./sections/fuel-entries/View";
import { IssuesCreateResource } from "./sections/issues/Create";
import { IssuesListResource } from "./sections/issues/List";
import { IssuesUpdateResource } from "./sections/issues/Update";
import { IssuesViewResource } from "./sections/issues/View";
import { MeterEntryCreateResource } from "./sections/meter-entries/Create";
import { MeterEntryListResource } from "./sections/meter-entries/List";
import { MeterEntryUpdateResource } from "./sections/meter-entries/Update";
import { MeterEntryViewResource } from "./sections/meter-entries/View";
import { ServiceEntryCreateResource } from "./sections/service-entries/Create";
import { ServiceEntryListResource } from "./sections/service-entries/List";
import { ServiceEntryUpdateResource } from "./sections/service-entries/Update";
import { ServiceEntryViewResource } from "./sections/service-entries/View";
import { ServiceReminderCreateResource } from "./sections/service-reminders/Create";
import { ServiceReminderListResource } from "./sections/service-reminders/List";
import { ServiceReminderUpdateResource } from "./sections/service-reminders/Update";
import { ServiceReminderViewResource } from "./sections/service-reminders/View";
import { ServiceTaskListResource } from "./sections/service-tasks/List";
import { VehicleStatusListResource } from "./sections/status/List";
import { VehicleGroupListResource } from "./sections/types/List";

const VehicleList = lazy(() => import("./List"));
const VehicleCreate = lazy(() => import("./Create"));
const VehicleStatusList = lazy(() => import("./sections/status/List"));
const VehicleGroupList = lazy(() => import("./sections/types/List"));
const ServiceTaskList = lazy(() => import("./sections/service-tasks/List"));
const ServiceReminderList = lazy(
  () => import("./sections/service-reminders/List")
);
const ServiceReminderCreate = lazy(
  () => import("./sections/service-reminders/Create")
);
const ServiceReminderUpdate = lazy(
  () => import("./sections/service-reminders/Update")
);
const ServiceReminderView = lazy(
  () => import("./sections/service-reminders/View")
);
const ServiceEntryList = lazy(() => import("./sections/service-entries/List"));
const ServiceEntryCreate = lazy(
  () => import("./sections/service-entries/Create")
);
const ServiceEntryUpdate = lazy(
  () => import("./sections/service-entries/Update")
);
const ServiceEntryView = lazy(() => import("./sections/service-entries/View"));
const FuelEntryList = lazy(() => import("./sections/fuel-entries/List"));
const FuelEntryCreate = lazy(() => import("./sections/fuel-entries/Create"));
const FuelEntryUpdate = lazy(() => import("./sections/fuel-entries/Update"));
const FuelEntryView = lazy(() => import("./sections/fuel-entries/View"));
const MeterEntryList = lazy(() => import("./sections/meter-entries/List"));
const MeterEntryCreate = lazy(() => import("./sections/meter-entries/Create"));
const MeterEntryUpdate = lazy(() => import("./sections/meter-entries/Update"));
const MeterEntryView = lazy(() => import("./sections/meter-entries/View"));
const IssuesList = lazy(() => import("./sections/issues/List"));
const IssuesCreate = lazy(() => import("./sections/issues/Create"));
const IssuesUpdate = lazy(() => import("./sections/issues/Update"));
const IssuesView = lazy(() => import("./sections/issues/View"));
const ExpenseEntryList = lazy(() => import("./sections/expense-entries/List"));
const ExpenseEntryCreate = lazy(
  () => import("./sections/expense-entries/Create")
);
const ExpenseEntryUpdate = lazy(
  () => import("./sections/expense-entries/Update")
);
const ExpenseEntryView = lazy(() => import("./sections/expense-entries/View"));

function MenuSubItem({
  open,
  name,
  icon,
  child,
  active,
}: {
  open: boolean;
  name: string;
  icon: any;
  child: { name: string; path: string }[];
  active: boolean;
}) {
  let location = useLocation();

  const trailConfig = {
    mass: 3,
    tension: active ? 0 : 1000,
    friction: 100,
  };

  const childTrail = useTrail(child ? child.length : 0, {
    config: trailConfig,
    opacity: open ? 1 : 0,
    x: open ? 0 : 10,
    height: open ? 36 : 0,
    from: { opacity: 0, x: 10, height: 0 },
  });

  return (
    <>
      <Disclosure.Button
        className={classNames(
          active
            ? " bg-primary-700/20 text-primary-700"
            : "text-gray-700 hover:text-primary-800",
          "group flex w-full items-center rounded-md px-2 py-2 text-left text-sm font-medium leading-6 transition-colors"
        )}
      >
        <span
          className={classNames(
            active
              ? " text-primary-700"
              : "text-gray-700 group-hover:text-gray-800",
            "mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 transition-colors",
            icon
          )}
          aria-hidden="true"
        />
        <span className="flex-1 truncate">{name}</span>
        <ChevronUpIcon
          className={classNames(
            open ? "text-gray-400" : "rotate-90 text-gray-300",
            "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
          )}
          aria-hidden="true"
        />
      </Disclosure.Button>
      <Disclosure.Panel className="space-y-1">
        {childTrail.map(({ x, height, ...rest }, index) => {
          let match = location.pathname === child[index].path;
          let active = match ? true : false;

          return (
            <a.div
              key={child[index].name}
              style={{
                ...rest,
                transform: x.to((x) => `translate3d(0,${x}px,0)`),
              }}
            >
              <a.div style={{ height }}>
                <Link
                  to={child[index].path}
                  className={classNames(
                    active
                      ? "text-primary-700 hover:text-primary-800"
                      : "text-gray-700 hover:text-primary-800",
                    "group flex w-full items-center rounded-md py-2 pl-12 pr-2 text-sm font-normal transition-colors"
                  )}
                >
                  <span className="truncate">{child[index].name}</span>
                </Link>
              </a.div>
            </a.div>
          );
        })}
      </Disclosure.Panel>
    </>
  );
}

function CustomLink({
  children,
  to,
  name,
  icon,
  child = [],
  ...props
}: LinkProps & {
  name: string;
  icon: any;
  child?: { name: string; path: string }[];
}) {
  let location = useLocation();
  let resolved = useResolvedPath(to);
  let match = location.pathname === resolved.pathname;
  let active = match ? true : false;

  return child.length ? (
    <Disclosure as="div" defaultOpen={active} className="space-y-1">
      {(props) => (
        <MenuSubItem
          {...props}
          name={name}
          icon={icon}
          child={child}
          active={active}
        />
      )}
    </Disclosure>
  ) : (
    <div>
      <Link
        to={to}
        className={classNames(
          active
            ? "bg-primary-700/10 text-primary-700"
            : "text-gray-700 hover:text-primary-800",
          "group flex w-full items-center rounded-md px-2 py-2 text-left text-sm font-medium leading-6 transition-colors"
        )}
        aria-current={active ? "page" : undefined}
        {...props}
      >
        <span
          className={classNames(
            active
              ? " text-primary-700"
              : "text-gray-700 group-hover:text-gray-800",
            "mr-4 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 transition-colors",
            icon
          )}
          aria-hidden="true"
        />
        <span className="flex-1 truncate">{name}</span>
      </Link>
    </div>
  );
}

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentRole } = useAuth();

  const navigation = [
    {
      name: "Vehicles",
      path: "/fleets/vehicles",
      icon: "bi bi-file-earmark-text",
      allow: isAuthorizedForResource(currentRole, DetailsResource.access),
      child: [
        {
          name: "Vehicle List",
          path: "/fleets/vehicles",
        },
        {
          name: "Meter History",
          path: "/fleets/vehicles/meter-entries",
        },
        {
          name: "Expense History",
          path: "/fleets/vehicles/expense-entries",
        },
      ],
    },
    {
      name: "Issues",
      path: "/fleets/vehicles/issues",
      icon: "bi bi-recycle",
      allow: isAuthorizedForResource(currentRole, LifecycleResource.access),
    },
    {
      name: "Reminders",
      path: "/fleets/vehicles/service-remiders",
      icon: "bi bi-bar-chart-line",
      allow: isAuthorizedForResource(currentRole, FinancialResource.access),
      child: [
        {
          name: "Service Reminders",
          path: "/fleets/vehicles/service-reminders",
        },
      ],
    },
    {
      name: "Services",
      path: "/fleets/vehicles/service-entries",
      icon: "bi bi-border-style",
      allow: isAuthorizedForResource(
        currentRole,
        SpecificationsResource.access
      ),
      child: [
        {
          name: "Service History",
          path: "/fleets/vehicles/service-entries",
        },
        {
          name: "Service Tasks",
          path: "/fleets/vehicles/service-tasks",
        },
      ],
    },
    {
      name: "Fuel History",
      path: "/fleets/vehicles/fuel-entries",
      icon: "bi bi-recycle",
      allow: isAuthorizedForResource(currentRole, LifecycleResource.access),
    },
    {
      name: "Vehicle Settings",
      path: "/fleets/vehicles/settings",
      icon: "bi bi-gear",
      allow: isAuthorizedForResource(currentRole, SettingsResource.access),
      child: [
        {
          name: "Vehicle Types",
          path: "/fleets/vehicles/types",
        },
        {
          name: "Vehicle Status",
          path: "/fleets/vehicles/status",
        },
      ],
    },
  ];

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="md:px-6 lg:col-span-3 lg:px-0">
        <div className="lg:hidden">
          <label htmlFor="selected-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="selected-tab"
            name="selected-tab"
            className="mt-1 block w-full rounded-md border-gray-200 py-2 pl-3 pr-10 text-base shadow-sm focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 sm:text-sm"
            defaultValue={location.pathname}
            onChange={(e) => {
              const to = e.target.value;
              navigate(to);
            }}
          >
            {navigation.map((item) =>
              item.allow ? (
                <option key={item.name} value={item.path}>
                  {item.name}
                </option>
              ) : null
            )}
          </select>
        </div>
        <div className="hidden rounded-xl bg-greyish p-5 lg:block">
          <nav className="space-y-1">
            {navigation.map((item) =>
              item.allow ? (
                <CustomLink
                  key={item.name}
                  name={item.name}
                  icon={item.icon}
                  to={item.path}
                  child={item.child}
                />
              ) : null
            )}
          </nav>
        </div>
      </aside>
      <div className="lg:col-span-9">
        <Outlet />
      </div>
    </div>
  );
}

export default function VehiclesPage({
  breadcrumbs: initialBreadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { currentRole } = useAuth();
  const breadcrumbs = [
    ...initialBreadcrumbs,
    {
      name: VehiclesResource.name,
      href: "/fleets/vehicles",
    },
  ];

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          element: <Layout />,
          children: [
            {
              index: true,
              element: isAuthorizedForResource(
                currentRole,
                VehicleListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <VehicleList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(VehicleListResource.access.join(", "))}
                />
              ),
            },
            {
              path: VehicleStatusListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                VehicleStatusListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <VehicleStatusList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(VehicleStatusListResource.access.join(", "))}
                />
              ),
            },
            {
              path: VehicleGroupListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                VehicleGroupListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <VehicleGroupList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(VehicleGroupListResource.access.join(", "))}
                />
              ),
            },
            {
              path: ServiceTaskListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceTaskListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceTaskList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(ServiceTaskListResource.access.join(", "))}
                />
              ),
            },
            {
              path: ServiceReminderListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceReminderListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceReminderList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ServiceReminderListResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ServiceReminderCreateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceReminderCreateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceReminderCreate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ServiceReminderCreateResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ServiceReminderUpdateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceReminderUpdateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceReminderUpdate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ServiceReminderUpdateResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ServiceReminderViewResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceReminderViewResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceReminderView breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ServiceReminderViewResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ServiceEntryListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceEntryListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceEntryList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(ServiceEntryListResource.access.join(", "))}
                />
              ),
            },
            {
              path: ServiceEntryCreateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceEntryCreateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceEntryCreate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ServiceEntryCreateResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ServiceEntryUpdateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceEntryUpdateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceEntryUpdate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ServiceEntryUpdateResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ServiceEntryViewResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ServiceEntryViewResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ServiceEntryView breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(ServiceEntryViewResource.access.join(", "))}
                />
              ),
            },
            {
              path: FuelEntryListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                FuelEntryListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <FuelEntryList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(FuelEntryListResource.access.join(", "))}
                />
              ),
            },
            {
              path: FuelEntryCreateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                FuelEntryCreateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <FuelEntryCreate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(FuelEntryCreateResource.access.join(", "))}
                />
              ),
            },
            {
              path: FuelEntryUpdateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                FuelEntryUpdateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <FuelEntryUpdate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(FuelEntryUpdateResource.access.join(", "))}
                />
              ),
            },
            {
              path: FuelEntryViewResource.path,
              element: isAuthorizedForResource(
                currentRole,
                FuelEntryViewResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <FuelEntryView breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(FuelEntryViewResource.access.join(", "))}
                />
              ),
            },
            {
              path: MeterEntryListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                MeterEntryListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <MeterEntryList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(MeterEntryListResource.access.join(", "))}
                />
              ),
            },
            {
              path: MeterEntryCreateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                MeterEntryCreateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <MeterEntryCreate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(MeterEntryCreateResource.access.join(", "))}
                />
              ),
            },
            {
              path: MeterEntryUpdateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                MeterEntryUpdateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <MeterEntryUpdate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(MeterEntryUpdateResource.access.join(", "))}
                />
              ),
            },
            {
              path: MeterEntryViewResource.path,
              element: isAuthorizedForResource(
                currentRole,
                MeterEntryViewResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <MeterEntryView breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(MeterEntryViewResource.access.join(", "))}
                />
              ),
            },
            {
              path: ExpenseEntryListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ExpenseEntryListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ExpenseEntryList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(ExpenseEntryListResource.access.join(", "))}
                />
              ),
            },
            {
              path: ExpenseEntryCreateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ExpenseEntryCreateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ExpenseEntryCreate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ExpenseEntryCreateResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ExpenseEntryUpdateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ExpenseEntryUpdateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ExpenseEntryUpdate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={
                    new Error(ExpenseEntryUpdateResource.access.join(", "))
                  }
                />
              ),
            },
            {
              path: ExpenseEntryViewResource.path,
              element: isAuthorizedForResource(
                currentRole,
                ExpenseEntryViewResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <ExpenseEntryView breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(ExpenseEntryViewResource.access.join(", "))}
                />
              ),
            },
            {
              path: IssuesListResource.path,
              element: isAuthorizedForResource(
                currentRole,
                IssuesListResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <IssuesList breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(IssuesListResource.access.join(", "))}
                />
              ),
            },
            {
              path: IssuesCreateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                IssuesCreateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <IssuesCreate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(IssuesCreateResource.access.join(", "))}
                />
              ),
            },
            {
              path: IssuesUpdateResource.path,
              element: isAuthorizedForResource(
                currentRole,
                IssuesUpdateResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <IssuesUpdate breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(IssuesUpdateResource.access.join(", "))}
                />
              ),
            },
            {
              path: IssuesViewResource.path,
              element: isAuthorizedForResource(
                currentRole,
                IssuesViewResource.access
              ) ? (
                <Suspense fallback={<LayoutSplashScreen />}>
                  <IssuesView breadcrumbs={breadcrumbs} />
                </Suspense>
              ) : (
                <NotAuthorized
                  error={new Error(IssuesViewResource.access.join(", "))}
                />
              ),
            },
          ],
        },
        {
          path: VehicleCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            VehicleCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <VehicleCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(VehicleCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: "/:vehicleId/*",
          element: <VehicleEditPage breadcrumbs={breadcrumbs} />,
        },
      ],
    },
  ];

  return useRoutes(routes);
}

export const VehiclesResource: ResourceProps = {
  name: "Vehicles",
  description: "Vehicles",
  access: ["read-vehicles"],
  path: "vehicles/*",
};
