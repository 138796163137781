import { useQuery } from "@apollo/client";
import { lazy, Suspense, useEffect, useMemo } from "react";
import type { LinkProps } from "react-router-dom";
import {
  Link,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
  useParams,
  useResolvedPath,
  useRoutes,
} from "react-router-dom";

import {
  LayoutSplashScreen,
  NotAuthorized,
  NotFound,
} from "../../../components/core";
import {
  GET_VEHICLE_BASIC_BY_ID,
  Vehicle,
  VehicleBasic,
} from "../../../graphql/fleets/vehicles";
import { classNames } from "../../../utils";
import { isAuthorizedForResource, useAuth } from "../../auth";
import { DetailsResource } from "./pages/Details";
import { ExpenseEntryCreateResource } from "./pages/expense-entry/Create";
import { ExpenseEntryListResource } from "./pages/expense-entry/List";
import { ExpenseEntryUpdateResource } from "./pages/expense-entry/Update";
import { ExpenseEntryViewResource } from "./pages/expense-entry/View";
import { FinancialResource } from "./pages/Financial";
import { FuelEntryCreateResource } from "./pages/fuel-entry/Create";
import { FuelEntryListResource } from "./pages/fuel-entry/List";
import { FuelEntryUpdateResource } from "./pages/fuel-entry/Update";
import { FuelEntryViewResource } from "./pages/fuel-entry/View";
import { IssuesCreateResource } from "./pages/issue/Create";
import { IssuesListResource } from "./pages/issue/List";
import { IssuesUpdateResource } from "./pages/issue/Update";
import { IssuesViewResource } from "./pages/issue/View";
import { LifecycleResource } from "./pages/Lifecycle";
import { MeterEntryCreateResource } from "./pages/meter-entry/Create";
import { MeterEntryListResource } from "./pages/meter-entry/List";
import { MeterEntryUpdateResource } from "./pages/meter-entry/Update";
import { MeterEntryViewResource } from "./pages/meter-entry/View";
import { ServiceEntryCreateResource } from "./pages/service-entry/Create";
import { ServiceEntryListResource } from "./pages/service-entry/List";
import { ServiceEntryUpdateResource } from "./pages/service-entry/Update";
import { ServiceEntryViewResource } from "./pages/service-entry/View";
import { ServiceReminderCreateResource } from "./pages/service-reminder/Create";
import { ServiceReminderListResource } from "./pages/service-reminder/List";
import { ServiceReminderUpdateResource } from "./pages/service-reminder/Update";
import { ServiceReminderViewResource } from "./pages/service-reminder/View";
import { SettingsResource } from "./pages/Settings";
import { SpecificationsResource } from "./pages/Specifications";
import { VehicleViewResource } from "./View";

const Details = lazy(() => import("./pages/Details"));
const Specs = lazy(() => import("./pages/Specifications"));
const ExpenseEntryList = lazy(() => import("./pages/expense-entry/List"));
const ExpenseEntryCreate = lazy(() => import("./pages/expense-entry/Create"));
const ExpenseEntryUpdate = lazy(() => import("./pages/expense-entry/Update"));
const ExpenseEntryView = lazy(() => import("./pages/expense-entry/View"));
const IssuesList = lazy(() => import("./pages/issue/List"));
const IssuesCreate = lazy(() => import("./pages/issue/Create"));
const IssuesUpdate = lazy(() => import("./pages/issue/Update"));
const IssuesView = lazy(() => import("./pages/issue/View"));
const ServiceReminderList = lazy(() => import("./pages/service-reminder/List"));
const ServiceReminderCreate = lazy(
  () => import("./pages/service-reminder/Create")
);
const ServiceReminderUpdate = lazy(
  () => import("./pages/service-reminder/Update")
);
const ServiceReminderView = lazy(() => import("./pages/service-reminder/View"));
const ServiceEntryList = lazy(() => import("./pages/service-entry/List"));
const ServiceEntryCreate = lazy(() => import("./pages/service-entry/Create"));
const ServiceEntryUpdate = lazy(() => import("./pages/service-entry/Update"));
const ServiceEntryView = lazy(() => import("./pages/service-entry/View"));
const FuelEntryList = lazy(() => import("./pages/fuel-entry/List"));
const FuelEntryCreate = lazy(() => import("./pages/fuel-entry/Create"));
const FuelEntryUpdate = lazy(() => import("./pages/fuel-entry/Update"));
const FuelEntryView = lazy(() => import("./pages/fuel-entry/View"));
const MeterEntryList = lazy(() => import("./pages/meter-entry/List"));
const MeterEntryCreate = lazy(() => import("./pages/meter-entry/Create"));
const MeterEntryUpdate = lazy(() => import("./pages/meter-entry/Update"));
const MeterEntryView = lazy(() => import("./pages/meter-entry/View"));
const Settings = lazy(() => import("./pages/Settings"));
const Financial = lazy(() => import("./pages/Financial"));
const Lifecycle = lazy(() => import("./pages/Lifecycle"));
const VehicleView = lazy(() => import("./View"));

function CustomLink({
  children,
  to,
  name,
  icon,
  child,
  ...props
}: LinkProps & {
  name: string;
  icon: any;
  child?: { name: string; href: string }[];
}) {
  let location = useLocation();
  let resolved = useResolvedPath(to);
  let active = location.pathname === resolved.pathname;

  return (
    <Link
      to={to}
      className={classNames(
        active ? "bg-primary-700/10 text-primary-700" : "",
        "group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-700 transition-colors focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
      )}
      aria-current={active ? "page" : undefined}
      {...props}
    >
      <span
        className={classNames(
          "mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
          icon
        )}
        aria-hidden="true"
      />
      <span className="flex-1 truncate">{name}</span>
    </Link>
  );
}

function Layout() {
  const location = useLocation();
  let { vehicleId } = useParams();
  const navigate = useNavigate();
  const { currentRole } = useAuth();
  useEffect(() => {
    const isInteger = vehicleId && !isNaN(+vehicleId);
    if (!isInteger) {
      navigate(-1);
    }
  }, [vehicleId, navigate]);

  const navigation = [
    {
      name: "Dashboard",
      path: `/fleets/vehicles/${vehicleId}/`,
      icon: "bi bi-speedometer2",
      allow: isAuthorizedForResource(currentRole, DetailsResource.access),
    },
    {
      name: "Details",
      path: `/fleets/vehicles/${vehicleId}/details`,
      icon: "bi bi-file-earmark-text",
      allow: isAuthorizedForResource(currentRole, DetailsResource.access),
    },
    {
      name: "Lifecycle",
      path: `/fleets/vehicles/${vehicleId}/lifecycle`,
      icon: "bi bi-recycle",
      allow: isAuthorizedForResource(currentRole, LifecycleResource.access),
    },
    {
      name: "Financial",
      path: `/fleets/vehicles/${vehicleId}/financial`,
      icon: "bi bi-bar-chart-line",
      allow: isAuthorizedForResource(currentRole, FinancialResource.access),
    },
    {
      name: "Specifications",
      path: `/fleets/vehicles/${vehicleId}/specifications`,
      icon: "bi bi-border-style",
      allow: isAuthorizedForResource(
        currentRole,
        SpecificationsResource.access
      ),
    },
    {
      name: "Settings",
      path: `/fleets/vehicles/${vehicleId}/settings`,
      icon: "bi bi-gear",
      allow: isAuthorizedForResource(currentRole, SettingsResource.access),
    },
  ];

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 sm:py-8 md:px-6 lg:col-span-3 lg:px-0">
        <div className="lg:hidden">
          <label htmlFor="selected-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="selected-tab"
            name="selected-tab"
            className="mt-1 block w-full rounded-md border-gray-200 py-2 pl-3 pr-10 text-base shadow-sm focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 sm:text-sm"
            defaultValue={location.pathname}
            onChange={(e) => {
              const to = e.target.value;
              navigate(to);
            }}
          >
            {navigation.map((item) =>
              item.allow ? (
                <option key={item.name} value={item.path}>
                  {item.name}
                </option>
              ) : null
            )}
          </select>
        </div>
        <div className="hidden rounded-xl bg-greyish p-5 lg:block">
          <nav className="space-y-1">
            {navigation.map((item) =>
              item.allow ? (
                <CustomLink
                  key={item.name}
                  name={item.name}
                  icon={item.icon}
                  to={item.path}
                />
              ) : null
            )}
          </nav>
        </div>
      </aside>
      <div className="lg:col-span-9">
        <Outlet />
      </div>
    </div>
  );
}
export function VehicleEditPage({
  breadcrumbs: initialBreadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { vehicleId } = useParams();
  const { currentRole } = useAuth();

  const { data } = useQuery<{ fetchVehicle: Vehicle }>(
    GET_VEHICLE_BASIC_BY_ID,
    {
      variables: {
        id: vehicleId,
      },
    }
  );

  const vehicleBasic = useMemo<VehicleBasic | null>(() => {
    if (!data?.fetchVehicle) return null;
    const vehicle = data?.fetchVehicle;
    return {
      id: parseInt(vehicle.id),
      name: vehicle.name || "Vehicle",
      vehicleImageUrl: vehicle.vehicleImageUrl || "",
      make: vehicle.make,
      model: vehicle.model,
      year: vehicle.year,
      operator: vehicle.operator,
      status: vehicle.status,
      fuelUnit: vehicle.settings?.length ? vehicle.settings[0]?.fuelUnit : 0,
      measurementUnit: vehicle.settings?.length
        ? vehicle.settings[0]?.measurementUnit
        : 0,
      primaryMeter: vehicle.settings?.length
        ? vehicle.settings[0]?.primaryMeter
        : 0,
    };
  }, [data?.fetchVehicle]);

  const breadcrumbs = useMemo<Breadcrumb[]>(() => {
    return [
      ...initialBreadcrumbs,
      {
        name: data?.fetchVehicle?.name || "Vehicle",
        href: `/fleets/vehicles/${vehicleId}/`,
      },
    ];
  }, [data?.fetchVehicle, initialBreadcrumbs, vehicleId]);

  let routes: RouteObject[] = [
    {
      index: true,
      element: isAuthorizedForResource(
        currentRole,
        VehicleViewResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <VehicleView breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(VehicleViewResource.access.join(", "))}
        />
      ),
    },
    {
      element: <Layout />,
      children: [
        {
          path: DetailsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DetailsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Details breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DetailsResource.access.join(", "))}
            />
          ),
        },
        {
          path: SpecificationsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SpecificationsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Specs breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SpecificationsResource.access.join(", "))}
            />
          ),
        },
        {
          path: FinancialResource.path,
          element: isAuthorizedForResource(
            currentRole,
            FinancialResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Financial
                breadcrumbs={breadcrumbs}
                vehicleBasic={vehicleBasic}
              />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(FinancialResource.access.join(", "))}
            />
          ),
        },
        {
          path: LifecycleResource.path,
          element: isAuthorizedForResource(
            currentRole,
            LifecycleResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Lifecycle
                breadcrumbs={breadcrumbs}
                vehicleBasic={vehicleBasic}
              />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(LifecycleResource.access.join(", "))}
            />
          ),
        },
        {
          path: SettingsResource.path,
          element: isAuthorizedForResource(
            currentRole,
            SettingsResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <Settings breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(SettingsResource.access.join(", "))}
            />
          ),
        },
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
    {
      path: IssuesListResource.path,
      element: isAuthorizedForResource(
        currentRole,
        IssuesListResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <IssuesList breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(IssuesListResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceEntryListResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceEntryListResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceEntryList
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceEntryListResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceEntryCreateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceEntryCreateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceEntryCreate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceEntryCreateResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceEntryUpdateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceEntryUpdateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceEntryUpdate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceEntryUpdateResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceEntryViewResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceEntryViewResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceEntryView
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceEntryViewResource.access.join(", "))}
        />
      ),
    },
    {
      path: FuelEntryListResource.path,
      element: isAuthorizedForResource(
        currentRole,
        FuelEntryListResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <FuelEntryList
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(FuelEntryListResource.access.join(", "))}
        />
      ),
    },
    {
      path: FuelEntryCreateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        FuelEntryCreateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <FuelEntryCreate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(FuelEntryCreateResource.access.join(", "))}
        />
      ),
    },
    {
      path: FuelEntryUpdateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        FuelEntryUpdateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <FuelEntryUpdate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(FuelEntryUpdateResource.access.join(", "))}
        />
      ),
    },
    {
      path: FuelEntryViewResource.path,
      element: isAuthorizedForResource(
        currentRole,
        FuelEntryViewResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <FuelEntryView
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(FuelEntryViewResource.access.join(", "))}
        />
      ),
    },
    {
      path: MeterEntryListResource.path,
      element: isAuthorizedForResource(
        currentRole,
        MeterEntryListResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <MeterEntryList
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(MeterEntryListResource.access.join(", "))}
        />
      ),
    },
    {
      path: MeterEntryCreateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        MeterEntryCreateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <MeterEntryCreate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(MeterEntryCreateResource.access.join(", "))}
        />
      ),
    },
    {
      path: MeterEntryUpdateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        MeterEntryUpdateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <MeterEntryUpdate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(MeterEntryUpdateResource.access.join(", "))}
        />
      ),
    },
    {
      path: MeterEntryViewResource.path,
      element: isAuthorizedForResource(
        currentRole,
        MeterEntryViewResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <MeterEntryView
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(MeterEntryViewResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceReminderListResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceReminderListResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceReminderList
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceReminderListResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceReminderCreateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceReminderCreateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceReminderCreate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceReminderCreateResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceReminderUpdateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceReminderUpdateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceReminderUpdate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceReminderUpdateResource.access.join(", "))}
        />
      ),
    },
    {
      path: ServiceReminderViewResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ServiceReminderViewResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ServiceReminderView
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ServiceReminderViewResource.access.join(", "))}
        />
      ),
    },
    {
      path: ExpenseEntryListResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ExpenseEntryListResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ExpenseEntryList
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ExpenseEntryListResource.access.join(", "))}
        />
      ),
    },
    {
      path: ExpenseEntryCreateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ExpenseEntryCreateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ExpenseEntryCreate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ExpenseEntryCreateResource.access.join(", "))}
        />
      ),
    },
    {
      path: ExpenseEntryUpdateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ExpenseEntryUpdateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ExpenseEntryUpdate
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ExpenseEntryUpdateResource.access.join(", "))}
        />
      ),
    },
    {
      path: ExpenseEntryViewResource.path,
      element: isAuthorizedForResource(
        currentRole,
        ExpenseEntryViewResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <ExpenseEntryView
            breadcrumbs={breadcrumbs}
            vehicleBasic={vehicleBasic}
          />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(ExpenseEntryViewResource.access.join(", "))}
        />
      ),
    },
    {
      path: IssuesListResource.path,
      element: isAuthorizedForResource(
        currentRole,
        IssuesListResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <IssuesList breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(IssuesListResource.access.join(", "))}
        />
      ),
    },
    {
      path: IssuesCreateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        IssuesCreateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <IssuesCreate breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(IssuesCreateResource.access.join(", "))}
        />
      ),
    },
    {
      path: IssuesUpdateResource.path,
      element: isAuthorizedForResource(
        currentRole,
        IssuesUpdateResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <IssuesUpdate breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(IssuesUpdateResource.access.join(", "))}
        />
      ),
    },
    {
      path: IssuesViewResource.path,
      element: isAuthorizedForResource(
        currentRole,
        IssuesViewResource.access
      ) ? (
        <Suspense fallback={<LayoutSplashScreen />}>
          <IssuesView breadcrumbs={breadcrumbs} vehicleBasic={vehicleBasic} />
        </Suspense>
      ) : (
        <NotAuthorized
          error={new Error(IssuesViewResource.access.join(", "))}
        />
      ),
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
