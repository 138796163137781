import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import { LimitBy, TableCursor } from "../../../../components/table";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_PRICINGMETHOD,
  EDIT_PRICINGMETHOD,
  GET_PRICINGMETHODS,
  REMOVE_PRICINGMETHOD,
} from "../../../../graphql/inventory/settings/pricing-methods";
import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import { formatDate } from "../../../../utils";
import { AuthShield } from "../../../auth/core";
import Form from "./components/Form";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id?: string;
  name: string;
  handle: string;
  editable: boolean;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

const PricingMethodList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);

  const { data, loading, error, refetch } = useQuery(GET_PRICINGMETHODS);
  const pricingMethods = useMemo<PricingMethod[]>(
    () =>
      data?.fetchPricingMethods
        ? data.fetchPricingMethods.flatMap((item: PricingMethod) => ({
            ...item,
            createdAt: formatDate(item.createdAt),
          }))
        : [],
    [data]
  );

  const [activePricingMethod, setActivePricingMethod] = useState<
    PricingMethod | undefined
  >(undefined);
  const [newPricingMethod, setNewPricingMethod] = useState<boolean | undefined>(
    undefined
  );

  const [updatePricingMethod] = useMutation(EDIT_PRICINGMETHOD, {
    refetchQueries: [
      {
        query: GET_PRICINGMETHODS,
      },
    ],
  });
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updatePricingMethod({
      variables: {
        id: activePricingMethod?.id,
        name: values.name,
        handle: values.handle,
        sellPriceFormula: values.sellPriceFormula,
        pricingMethodFields: values.pricingMethodFields
          ? values.pricingMethodFields.map((item: PricingMethodField) => ({
              fieldType: item.fieldType,
              fieldKey: item.fieldKey,
              fieldName: item.fieldName,
              fieldOptions: item.fieldOptions,
            }))
          : [],
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingMethodUpdate) {
          refetch();
          setActivePricingMethod(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing method update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing method update failed",
          message: error.message,
        });
      });
  };

  const [createPricingMethod] = useMutation(ADD_PRICINGMETHOD, {
    refetchQueries: [
      {
        query: GET_PRICINGMETHODS,
      },
    ],
  });
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createPricingMethod({
      variables: {
        name: values.name,
        handle: values.handle,
        sellPriceFormula: values.sellPriceFormula,
        pricingMethodFields: values.pricingMethodFields
          ? values.pricingMethodFields.map((item: PricingMethodField) => ({
              fieldType: item.fieldType,
              fieldKey: item.fieldKey,
              fieldName: item.fieldName,
              fieldOptions: item.fieldOptions,
            }))
          : [],
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingMethodCreate) {
          refetch();
          setNewPricingMethod(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing method created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing method create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing method create failed",
          message: error.message,
        });
      });
  };

  const [deletePricingMethod] = useMutation(REMOVE_PRICINGMETHOD, {
    refetchQueries: [
      {
        query: GET_PRICINGMETHODS,
      },
    ],
  });
  const handleDelete = async (id: string) => {
    deletePricingMethod({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.pricingMethodDelete) {
          refetch();
          const deletedPricingMethod = data.pricingMethodDelete?.pricingMethod;
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Pricing method ${deletedPricingMethod.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing method deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing method deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<PricingMethod, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) =>
          props.row.original.editable ? (
            <div className="flex space-x-2 md:space-x-4">
              <AuthShield access={["update-pricings"]}>
                <Button
                  variant="icon"
                  className="text-blue-500 hover:text-blue-600"
                  onClick={() => setActivePricingMethod(props.row.original)}
                >
                  <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                  <span className="sr-only">
                    Edit, {props.row.original.name}
                  </span>
                </Button>
              </AuthShield>
              <AuthShield access={["destroy-pricings"]}>
                <Button
                  variant="icon"
                  className="text-red-500 hover:text-red-600"
                  onClick={() => {
                    setAlert({
                      id: props.row.original.id!,
                      name: props.row.original.name,
                    });
                  }}
                >
                  <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                  <span className="sr-only">
                    Delete, {props.row.original.name}
                  </span>
                </Button>
              </AuthShield>
            </div>
          ) : null,
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={PricingMethodListResource.name}
        heading={PricingMethodListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: PricingMethodListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PricingMethodListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PricingMethodListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-pricings"]}>
              <Button onClick={() => setNewPricingMethod(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add pricing method
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={pricingMethods}
          columns={columns}
          loading={loading}
          totalRows={pricingMethods.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No pricing methods found"
          fallbackText="You can add a new pricing method by clicking on add pricing method button."
        />
      </div>

      <Transition.Root
        show={activePricingMethod ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePricingMethod(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Pricing Method - "
                  initialValues={{
                    id: activePricingMethod?.id,
                    name: activePricingMethod?.name ?? "",
                    handle: activePricingMethod?.handle ?? "",
                    pricingMethodFields:
                      activePricingMethod?.pricingMethodFields ?? [],
                    sellPriceFormula:
                      activePricingMethod?.sellPriceFormula ?? [],
                    status: activePricingMethod?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActivePricingMethod(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newPricingMethod ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPricingMethod(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Pricing Method - "
                  initialValues={{
                    name: "",
                    handle: "",
                    pricingMethodFields: [],
                    sellPriceFormula: [],
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewPricingMethod(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default PricingMethodList;
export const PricingMethodListResource: ResourceProps = {
  name: "Pricing Methods",
  description: "A list of pricing methods",
  access: ["read-pricings"],
  path: "pricing-methods",
};
