import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { Listbox, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel, Row } from "@tanstack/react-table";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import toast from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";

import { ErrorFallback, Head } from "../../../components/core";
import {
  Button,
  FieldDatepicker,
  selectStyles,
  SelectWrapper,
} from "../../../components/form";
import {
  IndeterminateCheckbox,
  LimitBy,
  TableCursor,
} from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  DeliveryRun,
  GET_DELIVERYRUNS,
} from "../../../graphql/fleets/settings/delivery-runs";
import { GET_CUSTOMERS } from "../../../graphql/sales/customers";
import {
  Customer,
  EDIT_BULK_ORDER_STATUS,
  GET_ORDER_APPROVAL_PENDING,
  GET_ORDERS,
  Order,
  OrderStatus,
  ProductApprove,
  renderStatus,
} from "../../../graphql/sales/orders";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { IconFilterClear } from "../../../svgs";
import { classNames, formatDate, renderAddress } from "../../../utils";
import { useAuth } from "../../auth";
import { AuthShield } from "../../auth/core";

const OrderList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { currentUser } = useAuth();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const [rowSelection, setRowSelection] = useState({});

  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string | null>(
    searchParams.get("query") || ""
  );
  const [customer, setCustomer] = useState<SingleValue<OptionProps>>(null);
  const [deliveryRun, setDeliveryRun] =
    useState<SingleValue<OptionProps>>(null);
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [status, setStatus] = useState<SingleValue<OptionProps>>(null);

  useEffect(() => {
    setSearchQuery(searchParams.get("query") || "");
  }, [searchParams]);

  const handleClearFilters = useCallback(() => {
    setSearchParams({ query: "" });
    setCustomer(null);
    setDeliveryRun(null);
    setDeliveryDate("");
    setStatus(null);
  }, [setSearchParams]);

  const {
    data: customersData,
    loading: customersLoading,
    refetch: customersRefetch,
  } = useQuery(GET_CUSTOMERS);

  const {
    data: deliveryRunsData,
    loading: deliveryRunsLoading,
    refetch: deliveryRunsRefetch,
  } = useQuery(GET_DELIVERYRUNS);

  const {
    data: orderApprovalPendingData,
    loading: orderApprovalPendingLoading,
  } = useQuery(GET_ORDER_APPROVAL_PENDING);

  const statusData = [
    { value: String(OrderStatus.ARCHIVE), label: "Archive" },
    { value: String(OrderStatus.DRAFT), label: "Draft" },
    { value: String(OrderStatus.OPEN), label: "Open" },
    { value: String(OrderStatus.PACKING), label: "Packing" },
    { value: String(OrderStatus.SHIPPED), label: "Ready To Ship" },
    { value: String(OrderStatus.COMPLETED), label: "Completed" },
  ];

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");
  if (customer) searchFilter.push("customer");
  if (deliveryRun) searchFilter.push("deliveryrun");
  if (deliveryDate) searchFilter.push("deliverydate");
  if (status) searchFilter.push("status");

  const { data, loading, error, refetch } = useQuery(GET_ORDERS, {
    variables: {
      searchQuery,
      searchFilter,
      customerId: customer ? customer.value : null,
      deliveryrunId: deliveryRun ? deliveryRun.value : null,
      deliveryDate,
      status: status ? Number(status.value) : null,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const orders = useMemo<Order[]>(
    () =>
      data?.fetchOrders?.flatMap((item: Order) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
        deliveryDate: formatDate(item.deliveryDate),
      })) ?? [],
    [data?.fetchOrders]
  );

  const filtering: boolean = useMemo(
    () =>
      searchFilter.length > 0 ||
      deliveryRun ||
      customer ||
      typeof status === "number" ||
      !!deliveryDate ||
      !!searchQuery
        ? true
        : false,
    [
      searchFilter.length,
      deliveryRun,
      customer,
      status,
      deliveryDate,
      searchQuery,
    ]
  );

  const DELETE_ORDER = gql`
    mutation OrderDelete($id: ID!) {
      orderDelete(input: { id: $id }) {
        order {
          id
          orderName
        }
      }
    }
  `;
  const [deleteOrder] = useMutation(DELETE_ORDER, {
    refetchQueries: [
      {
        query: GET_ORDERS,
        variables: {
          searchQuery: "",
          searchFilter: [],
          customerId: null,
          deliveryrunId: null,
          deliveryDate: "",
          status: null,
        },
      },
    ],
  });
  const handleDelete = useCallback(
    async (id: string) => {
      return await deleteOrder({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data?.orderDelete) {
            const deletedOrder = data?.orderDelete?.order;
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: `Order ${deletedOrder.orderName} deleted`,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Order delete failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Order delete failed",
            message: error.message,
          });
        });
    },
    [addNotify, deleteOrder, refetch]
  );

  const [updateBulkOrderStatusUpdate] = useMutation(EDIT_BULK_ORDER_STATUS);

  const handleBulkOrderStatus = useCallback(
    (orders: Row<Order>[]) =>
      updateBulkOrderStatusUpdate({
        variables: {
          clientMutationId: currentUser?.id,
          ids: orders.map((order) => order.original.id),
        },
      })
        .then(({ data }) => {
          if (data?.bulkOrderStatusUpdate) {
            refetch();
            toast.success("Orders are moved to picking section");
          } else {
            toast.error("Something went wrong, please try again later");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        }),
    [currentUser?.id, refetch, updateBulkOrderStatusUpdate]
  );

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<Order, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Order Number",
        cell: ({ row }) => (
          <Link
            to={`/sales/orders/${row.original.id}`}
            className="text-gray-700 transition hover:text-primary-700"
          >
            #{row.original.orderNumber}
          </Link>
        ),
        accessorFn: (row) => row.orderNumber,
      },
      {
        accessorKey: "customer.email",
        header: "Customer",
        cell: ({ row }) => <CustomerPopover customer={row.original.customer} />,
        accessorFn: (row) => row.customer.email,
      },
      {
        accessorKey: "items",
        header: "Item(s) Status",
        cell: ({ row }) => {
          const pendingItems = row.original.items.reduce((acc, item) => {
            const isApproved = item.productApprove === ProductApprove.APPROVED;
            if (isApproved) {
              return acc;
            }
            return acc + 1;
          }, 0);
          return pendingItems ? (
            <span className="inline-flex items-center whitespace-nowrap rounded-full bg-red-50 px-2 text-xs font-medium leading-5 text-red-700">
              <span>Pending</span>
              <span className="ml-1 h-[14px] min-w-[14px] rounded-full border border-red-700 text-center text-[10px] leading-[13px]">
                {pendingItems}
              </span>
            </span>
          ) : null;
        },
      },
      {
        accessorKey: "total",
        header: "Order Total",
        cell: ({ row }) => {
          const total = row.original.items.reduce((acc, item) => {
            const isApproved = item.productApprove === ProductApprove.APPROVED;
            if (isApproved) {
              return acc + item.quantity * item.price;
            }
            return acc;
          }, 0);
          return <span className="font-medium text-gray-900">${total}</span>;
        },
      },
      {
        accessorKey: "createdAt",
        header: "Order Date",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "deliveryDate",
        header: "Delivery Date",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) => renderStatus(info.row.original.status),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-salesorders"]}>
              <Button
                type="link"
                href={`/sales/orders/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit, {props.row.original.orderNumber}
                </span>
              </Button>
            </AuthShield>
            {/* <AuthShield access={["destroy-salesorders"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.orderNumber,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.orderNumber}
                </span>
              </Button>
            </AuthShield> */}
          </div>
        ),
      },
    ],
    []
  );

  const ordersPending = useMemo(
    () =>
      orderApprovalPendingData?.fetchOrderApprovalPending.map((item: Order) => {
        return {
          ...item,
          createdAt: formatDate(item.createdAt),
          deliveryDate: formatDate(item.deliveryDate),
        };
      }) || [],
    [orderApprovalPendingData]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Orders"
        heading="Orders"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Orders",
            href: "/sales/orders",
          },
          {
            name: OrderListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {OrderListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {OrderListResource.description}
            </p>
          </div>
        </div>
        {ordersPending.length ? (
          <div className="mb-4 rounded-md border border-red-100 bg-red-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <div className="text-sm font-medium text-red-700">
                  There are some products that are waiting for approval in{" "}
                  <span className="mr-1 inline-block h-[16px] min-w-[16px] rounded-full border border-current bg-current text-center text-[12px] leading-[15px]">
                    <span className="text-white">{ordersPending.length}</span>
                  </span>{" "}
                  orders. Click{" "}
                  <Button
                    type="link"
                    href="/sales/orders/approval"
                    variant="text"
                    loading={orderApprovalPendingLoading}
                    className="underline transition-all hover:no-underline"
                  >
                    here
                  </Button>{" "}
                  to view the details.
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <Fragment>
          <TableCursor
            data={orders}
            columns={columns}
            loading={loading}
            totalRows={orders.length}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            filtering={filtering}
            state={{
              rowSelection,
            }}
            enableRowSelection={(row) =>
              row.original.status === OrderStatus.ARCHIVE ||
              row.original.status === OrderStatus.DRAFT ||
              row.original.status === OrderStatus.OPEN
            }
            onRowSelectionChange={setRowSelection}
            getCoreRowModel={getCoreRowModel()}
            enableAction
            renderAction={(table) => (
              <Fragment>
                <div className="mb-4 flex items-start justify-end space-x-2">
                  <Button
                    onClick={async () => {
                      setRefetching(true);
                      await handleBulkOrderStatus(
                        table.getSelectedRowModel().rows
                      );
                      table.resetRowSelection();
                      setRefetching(false);
                    }}
                    disabled={
                      loading ||
                      table.getSelectedRowModel().rows.length === 0 ||
                      refetching
                    }
                    variant="info"
                  >
                    <span className="bi bi-box-seam mr-2 h-5 w-5 text-center text-xl leading-5" />
                    Move To Picking
                  </Button>
                  <AuthShield access={["create-salesorders"]}>
                    <Button type="link" href="/sales/orders/add">
                      <PlusCircleIcon className="mr-2 h-5 w-5" />
                      Add order
                    </Button>
                  </AuthShield>
                </div>
                <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for name"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery ?? ""}
                      onChange={(e) => {
                        setSearchParams({
                          query: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                      <Select
                        className="h-11 w-full xl:whitespace-nowrap"
                        closeMenuOnSelect={true}
                        styles={selectStyles}
                        value={customer}
                        options={customersData?.fetchCustomers.map(
                          (d: Customer) => ({
                            value: d.id,
                            label: d.customerName,
                          })
                        )}
                        onChange={setCustomer}
                        isLoading={customersLoading}
                        placeholder="Filter by customer"
                        isClearable
                        onMenuOpen={() => {
                          customersRefetch();
                        }}
                      />
                    </SelectWrapper>
                  </div>
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                      <Select
                        className="h-11 w-full xl:whitespace-nowrap"
                        closeMenuOnSelect={true}
                        styles={selectStyles}
                        value={deliveryRun}
                        options={deliveryRunsData?.fetchDeliveryRuns.map(
                          (d: DeliveryRun) => ({
                            value: d.id,
                            label: d.name,
                          })
                        )}
                        onChange={setDeliveryRun}
                        isLoading={deliveryRunsLoading}
                        placeholder="Filter by delivery run"
                        isClearable
                        onMenuOpen={() => {
                          deliveryRunsRefetch();
                        }}
                      />
                    </SelectWrapper>
                  </div>
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <CalendarDaysIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <FieldDatepicker
                      title="Delivery Date"
                      name="deliveryDate"
                      onChange={(value) => {
                        if (!Array.isArray(value)) {
                          setDeliveryDate(
                            value ? new Date(value).toISOString() : ""
                          );
                        }
                      }}
                      selected={deliveryDate ? new Date(deliveryDate) : null}
                      isClearable
                      isLabel={false}
                      placeholderText="Delivery Date"
                      className="border-none focus-visible:ring-0"
                      disableIcon
                    />
                  </div>
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <Listbox value={status} onChange={setStatus}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="sr-only">
                            Status
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="relative h-11 w-full cursor-default pl-3 pr-10 text-left text-sm xl:whitespace-nowrap">
                              <span className="flex items-center">
                                <span
                                  aria-label={status?.value}
                                  className={classNames(
                                    // Number(status?.value) === OrderStatus.ARCHIVE
                                    //   ? "bg-red-400"
                                    //   : Number(status?.value) === Status.DRAFT
                                    //   ? "bg-yellow-400"
                                    //   : Number(status?.value) === Status.ACTIVE
                                    //   ? "bg-primary-400"
                                    //   : "bg-gray-200",
                                    "inline-block h-2 w-2 flex-shrink-0 rounded-full bg-gray-200"
                                  )}
                                />
                                <span
                                  className={classNames(
                                    "ml-3 block truncate",
                                    status ? "text-black" : "text-black/50"
                                  )}
                                >
                                  {status ? status.label : "Filter by status"}
                                </span>
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute right-0 z-20 mt-5 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-3 md:w-44 md:px-2.5 md:py-3">
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="16px"
                                  height="16px"
                                  viewBox="0 0 110.9 96"
                                  enableBackground="new 0 0 110.9 96"
                                  className="absolute -top-3 right-0 -z-0"
                                  fill="#ffffff"
                                  style={{
                                    filter:
                                      "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                                  }}
                                >
                                  <polygon points="110.9,0 0,96 110.9,96 " />
                                </svg>
                                {statusData.map((_status) => (
                                  <Listbox.Option
                                    key={`status-${_status.value}`}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-primary-700/10 text-primary-700"
                                          : "",
                                        "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2"
                                      )
                                    }
                                    value={_status}
                                  >
                                    {({ selected, active }) => (
                                      <div className="flex items-center">
                                        <span
                                          className={classNames(
                                            // Number(_status?.value) === OrderStatus.
                                            //   ? "bg-red-400"
                                            //   : Number(_status?.value) ===
                                            //     Status.DRAFT
                                            //   ? "bg-yellow-400"
                                            //   : Number(_status?.value) ===
                                            //     Status.ACTIVE
                                            //   ? "bg-primary-400"
                                            //   : "bg-gray-200",
                                            "inline-block h-2 w-2 flex-shrink-0 rounded-full bg-gray-200"
                                          )}
                                          aria-hidden="true"
                                        />
                                        <span
                                          className={classNames(
                                            selected ? "text-gray-900" : "",
                                            "ml-3 block truncate"
                                          )}
                                        >
                                          {_status.label}
                                          <span className="sr-only">
                                            {" "}
                                            is {status?.label}
                                          </span>
                                        </span>
                                      </div>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div>
                    <Button
                      variant="icon"
                      className="relative flex h-11 w-11 items-center justify-center rounded-md text-gray-600 transition-all hover:bg-primary-200 hover:text-primary-900"
                      onClick={handleClearFilters}
                      disabled={loading}
                    >
                      <IconFilterClear className="h-5 w-5" />
                      <span className="sr-only">Clear Filters</span>
                    </Button>
                  </div>
                  <div className="py-1">
                    <Button
                      variant="icon"
                      onClick={handleRefetch}
                      disabled={loading}
                      className="flex h-9 items-center justify-center px-2 text-blue-700"
                    >
                      <ArrowPathIcon
                        aria-hidden="true"
                        className={classNames(
                          "h-5 w-5",
                          refetching ? "animate-spin" : ""
                        )}
                      />
                      <span className="sr-only">Refresh order list</span>
                    </Button>
                  </div>
                </div>
              </Fragment>
            )}
            fallbackTitle="No orders found"
            fallbackText="There are no orders to display at the moment."
          />
        </Fragment>
      </div>
    </>
  );
};

export default OrderList;
export const OrderListResource: ResourceProps = {
  name: "Orders",
  description: "A list of Orders",
  access: ["read-salesorders"],
  path: "orders",
};

function CustomerPopover({ customer }: { customer: Customer }) {
  const address = customer.tradingAddresses.find(
    (address) => address.primaryAddress
  );
  return (
    <Popover className="relative flex justify-end">
      {({ open }) => (
        <div>
          <Popover.Button className="group inline-flex items-center whitespace-nowrap px-3 py-2 text-gray-700 transition hover:text-primary-700 focus-visible:outline-none">
            <span>{customer.customerName}</span>
            <ChevronDownIcon
              className={classNames(
                open ? "" : "text-opacity-70",
                "ml-2 h-4 w-4 text-primary-700 transition duration-150 ease-in-out group-hover:text-opacity-80"
              )}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-2 z-40 mt-4 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-2 md:w-44">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="16px"
                height="16px"
                viewBox="0 0 110.9 96"
                enableBackground="new 0 0 110.9 96"
                className="absolute -top-3 right-0 -z-0"
                fill="#ffffff"
                style={{
                  filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                }}
              >
                <polygon points="110.9,0 0,96 110.9,96 " />
              </svg>
              <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
                <h3 className="mb-1 font-medium">{customer.customerName}</h3>
                {renderAddress(address)}
              </div>
              <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
                <Link
                  to={`/sales/customers/${customer.id}`}
                  target="_blank"
                  className="block text-center text-sm text-gray-500"
                >
                  View Customer
                </Link>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
}
