import { gql, useMutation } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useCallback, useState } from "react";
import * as Yup from "yup";

import { Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import { classNames } from "../../../../utils";
import { useAuth } from "../..";

// const AutoSubmitToken = () => {
//   const { values, submitForm }: { values: any; submitForm: any } =
//     useFormikContext();
//   useEffect(() => {
//     if (values.code.length === 6) {
//       submitForm();
//     }
//   }, [values, submitForm]);
//   return null;
// };

const SEND_OTP = gql`
  mutation SendOtp($email: String!) {
    sendOtp(input: { params: { email: $email } }) {
      user {
        id
      }
    }
  }
`;

const CODE_LENGTH = 6;

export default function Email({
  title,
  description,
  challengeHandler,
  verifyHandler,
}: {
  title: string;
  description: string;
  challengeHandler: () => void;
  verifyHandler: ({
    code,
    type,
  }: {
    code: string;
    type: string;
  }) => Promise<any>;
}) {
  /* useEffect(() => {
    if ("OTPCredential" in window) {
      console.log("OTPCredential is available");
    }
    const onDOMContentLoaded = (e: any) =>
      console.info("DOMContentLoaded e:", e);
    window.addEventListener("DOMContentLoaded", onDOMContentLoaded);
    return () => {
      window.removeEventListener("DOMContentLoaded", onDOMContentLoaded);
    };
  }, []); */

  const TotpSchema = Yup.object().shape({
    code: Yup.string()
      .matches(/^[0-9]{6}$/, `Code must be ${CODE_LENGTH} digits long`)
      .required("Please enter one-time password"),
  });
  const { currentIdentifier } = useAuth();
  const { addNotify } = useNotifyContext();
  const [sendOtp, { loading }] = useMutation(SEND_OTP);
  const [isRequested, setRequested] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const handleRequestOtp = useCallback(async () => {
    try {
      await sendOtp({
        variables: {
          email: currentIdentifier,
        },
      });
      setRequested(true);
      setCountdown(60);
      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        setRequested(false);
      }, 60000);
    } catch (e: any) {
      addNotify({
        type: NotifyType.ERROR,
        message: e.message,
      });
    }
  }, [currentIdentifier, sendOtp, addNotify]);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: TotpSchema,
    onSubmit: (values, actions) => {
      return verifyHandler({ code: values.code, type: "emailotp" });
    },
  });

  const { errors, touched, isSubmitting, setFieldValue } = formik;

  const handleOnPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .replace(/[^0-9]/g, "")
        .slice(0, CODE_LENGTH);
      if (pastedData) {
        setFieldValue("code", pastedData);
      }
    },
    [setFieldValue]
  );

  return (
    <>
      <Head title={title} heading={title} />
      <div className="mt-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        <p className="text-sm">{description}</p>

        <form className="mt-2 space-y-6" onSubmit={formik.handleSubmit}>
          <div>
            <label htmlFor="code" className="sr-only">
              Enter your OTP
            </label>
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-3 mt-1">
                <div className="relative">
                  <input
                    type="text"
                    name="code"
                    className={classNames(
                      "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 shadow-sm focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                      "read-only:cursor-not-allowed disabled:border-primary-200 disabled:bg-primary-50 disabled:text-primary-500",
                      touched.code && errors.code
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.code}
                    autoComplete="off"
                    inputMode="numeric"
                    required
                    placeholder="000-000"
                    onPaste={handleOnPaste}
                  />
                  {touched.code && errors.code ? (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-span-2 mt-1 flex">
                <Button
                  type="button"
                  variant="secondary"
                  className="w-full justify-center pl-1 pr-1"
                  onClick={handleRequestOtp}
                  disabled={(isRequested && countdown > 0) || loading}
                  loading={loading}
                >
                  {isRequested ? `Request OTP (${countdown})` : "Request OTP"}
                </Button>
              </div>
            </div>
            {touched.code && errors.code ? (
              <p className="mt-2 text-sm text-red-600" id="code-errors">
                {errors.code}
              </p>
            ) : null}
            {/* <AutoSubmitToken /> */}
          </div>

          <div className="grid grid-cols-5 items-center gap-4">
            <div className="col-span-3">
              <Button
                variant="text"
                className="justify-start border-0 pl-0 pr-0 text-primary-700"
                type="button"
                onClick={challengeHandler}
              >
                Try another way
              </Button>
            </div>
            <Button
              type="submit"
              variant="primary"
              className="group relative col-span-2 flex justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2.5 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
              disabled={isSubmitting}
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
