import { useLazyQuery, useMutation } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Waiting } from "../../../animations";
import { Head } from "../../../components/core";
import { Button, FieldDeliveryRun } from "../../../components/form";
import {
  DeliveryRun,
  GET_DELIVERYRUNS,
} from "../../../graphql/fleets/settings/delivery-runs";
import {
  Dock,
  EDIT_DOCK,
  GET_DOCKS,
} from "../../../graphql/fleets/settings/docks";
import {
  GET_VEHICLES_BY_DOCK,
  Vehicle,
  VehicleBasic,
} from "../../../graphql/fleets/vehicles";
import { classNames } from "../../../utils";
import { AuthShield } from "../../auth/core";
import CalendarView from "./Calendar";
import FormDock from "./components/FormDock";

const VehicleAssignments = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { t } = useTranslation();

  const [openAddVehicle, setOpenAddVehicle] = useState(false);

  const [deliveryRunId, setDeliveryRunId] = useState<number | null>(null);
  const [dockId, setDockId] = useState<number | null>(null);
  const [dock, setDock] = useState<Dock | null>(null);
  const [vehicles, setVehicles] = useState<VehicleBasic[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredVehicles = useMemo(
    () =>
      searchQuery
        ? vehicles.filter((vehicle) =>
            vehicle.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
        : vehicles,
    [searchQuery, vehicles]
  );

  const [fetchingDeliveryRuns, { loading: loadingDeliveryRuns }] =
    useLazyQuery<{
      fetchDeliveryRuns: DeliveryRun[];
    }>(GET_DELIVERYRUNS);

  const [fetchingVehiclesByDock, { loading: loadingDocks }] = useLazyQuery<{
    fetchVehiclesByDock: Vehicle[];
  }>(GET_VEHICLES_BY_DOCK, {
    variables: {
      dockId,
    },
  });

  const fetchingEvents = useCallback(
    async (dockId: number | null) =>
      fetchingVehiclesByDock({
        variables: {
          dockId,
        },
      })
        .then(({ data }) => {
          const updatedResources =
            data?.fetchVehiclesByDock?.map((vehicle) => {
              return {
                id: parseInt(vehicle.id),
                name: vehicle.name,
                vehicleImageUrl: vehicle.vehicleImageUrl,
                fuelUnit: vehicle.settings?.length
                  ? vehicle.settings[0].fuelUnit
                  : 0,
                measurementUnit: vehicle?.settings?.length
                  ? vehicle.settings[0].measurementUnit
                  : 0,
                primaryMeter: vehicle?.settings?.length
                  ? vehicle.settings[0]?.primaryMeter
                  : 0,
                status: vehicle?.status,
                operator: vehicle?.operator ? vehicle.operator : null,
                make: vehicle?.make ? vehicle.make : null,
                model: vehicle?.model ? vehicle.model : null,
                year: vehicle?.year ? vehicle.year : null,
              };
            }) ?? [];
          setVehicles(updatedResources);
        })
        .catch((error) => {
          toast.error(error.message);
        }),
    [fetchingVehiclesByDock]
  );

  const fetchingCalendar = useCallback(() => {
    fetchingDeliveryRuns()
      .then(({ data }) => {
        const firstDeliveryRun = data?.fetchDeliveryRuns?.length
          ? data?.fetchDeliveryRuns[0]
          : null;
        setDeliveryRunId(firstDeliveryRun ? Number(firstDeliveryRun.id) : null);
        setDockId(firstDeliveryRun ? Number(firstDeliveryRun.dock.id) : null);
        setDock(firstDeliveryRun?.dock ?? null);
        fetchingEvents(
          firstDeliveryRun ? Number(firstDeliveryRun.dock.id) : null
        );
      })
      .catch((error) => {
        toast.error((error as Error).message);
      });
  }, [fetchingDeliveryRuns, fetchingEvents]);

  useEffect(() => {
    fetchingCalendar();
  }, [fetchingCalendar]);

  useEffect(() => {
    if (!deliveryRunId) return setVehicles([]);
    fetchingEvents(deliveryRunId);
  }, [deliveryRunId, fetchingEvents]);

  const [updateDock] = useMutation(EDIT_DOCK, {
    refetchQueries: [
      {
        query: GET_DOCKS,
      },
      {
        query: GET_VEHICLES_BY_DOCK,
        variables: {
          dockId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: {
      vehicleIds: number[];
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!dock) return;
    updateDock({
      variables: {
        id: dockId,
        name: dock.name,
        vehicleIds: values.vehicleIds,
        status: dock.status,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.dockUpdate) {
          await fetchingEvents(data.dockUpdate.dock.id);
          setOpenAddVehicle(false);
          toast.success(`Dock ${data.dockUpdate.dock.name} updated`);
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  return (
    <>
      <Head
        title={VehicleAssignmentsResource.name}
        heading={VehicleAssignmentsResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: VehicleAssignmentsResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {VehicleAssignmentsResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {VehicleAssignmentsResource.description}
            </p>
          </div>
        </div>
        <div className="rounded-lg bg-white p-4 md:p-5 xl:p-6">
          {loadingDeliveryRuns || loadingDocks ? (
            <Waiting />
          ) : (
            <Fragment>
              <div className="relative top-0.5 z-10 float-left flex items-center space-x-4 lg:-mb-12">
                <div className="flex w-56 max-w-full">
                  <label className="sr-only mb-1 block text-sm font-medium text-gray-900">
                    {t("text_delivery_run")}
                  </label>
                  <FieldDeliveryRun
                    value={deliveryRunId}
                    onChange={(value) => {
                      setDeliveryRunId(value ? Number(value.id) : null);
                      setDockId(value ? Number(value.dock.id) : null);
                      setDock(value?.dock ?? null);
                    }}
                    className={classNames(
                      "w-full rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
                    )}
                    isLoading={loadingDeliveryRuns}
                    placeholder={t("text_select_delivery_run")}
                  />
                </div>
                {dockId ? (
                  <div className="flex w-56 max-w-full items-center rounded-md border border-gray-300 bg-white pl-3 text-black/50">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-10 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for vehicle"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery}
                      onChange={(e: any) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              {deliveryRunId ? (
                <Fragment>
                  <CalendarView
                    deliveryRunId={deliveryRunId}
                    vehicles={filteredVehicles}
                  />
                  <AuthShield access={["update-docks"]}>
                    <div className="grid grid-cols-1 border-b border-l border-r border-gray-200 sm:grid-cols-4">
                      <div className="p-4">
                        <Button
                          onClick={() => {
                            setOpenAddVehicle(true);
                          }}
                          border
                          className="w-full py-4"
                        >
                          <PlusCircleIcon className="mr-2 h-5 w-5" />
                          Add Vehicle
                        </Button>
                      </div>
                    </div>
                  </AuthShield>
                </Fragment>
              ) : null}
              {(!loadingDeliveryRuns || !loadingDocks) &&
              searchQuery.length > 0 &&
              filteredVehicles.length === 0 ? (
                <div className="py-10 text-center text-base md:py-16 xl:py-20">
                  <ExclamationCircleIcon
                    type="outline"
                    name="exclamation-circle"
                    className="mx-auto h-10 w-10 text-gray-400"
                  />
                  <h4 className="mt-4 text-lg font-medium">
                    No vehicles found
                  </h4>
                  <p className="text-gray-900">
                    Please change the filter to view vehicle assignments.
                  </p>
                </div>
              ) : (!loadingDeliveryRuns || !loadingDocks) &&
                searchQuery.length === 0 &&
                deliveryRunId &&
                filteredVehicles.length === 0 ? (
                <div className="py-10 text-center text-base md:py-16 xl:py-20">
                  <ExclamationCircleIcon
                    type="outline"
                    name="exclamation-circle"
                    className="mx-auto h-10 w-10 text-gray-400"
                  />
                  <h4 className="mt-4 text-lg font-medium">
                    No vehicles found
                  </h4>
                  <p className="text-gray-900">
                    Please add vehicles to the delivery run to view vehicle
                    assignments.
                  </p>
                </div>
              ) : (!loadingDeliveryRuns || !loadingDocks) && !deliveryRunId ? (
                <div className="py-10 text-center text-base md:py-16 xl:py-20">
                  <ExclamationCircleIcon
                    type="outline"
                    name="exclamation-circle"
                    className="mx-auto h-10 w-10 text-gray-400"
                  />
                  <h4 className="mt-4 text-lg font-medium">
                    No delivery run selected
                  </h4>
                  <p className="text-gray-900">
                    Please select a delivery run to view vehicle assignments.
                  </p>
                </div>
              ) : null}
            </Fragment>
          )}
        </div>
      </div>

      <Transition.Root show={openAddVehicle} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpenAddVehicle(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <FormDock
                  heading={`Add Vehicle to ${dock?.name}`}
                  initialValues={{
                    vehicleIds: dock?.vehicles?.length
                      ? dock.vehicles.map((item) => parseInt(item.id))
                      : [],
                  }}
                  vehicles={dock?.vehicles ?? []}
                  onSubmit={handleSubmit}
                  onCancel={() => setOpenAddVehicle(false)}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default VehicleAssignments;
export const VehicleAssignmentsResource: ResourceProps = {
  name: "Vehicle Assignment",
  description: "Manage your vehicle assignments here.",
  access: ["read-vehicleAssignments"],
  path: "vehicle-assignments",
};
