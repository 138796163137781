import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_DRIVER,
  Driver,
  GET_DRIVERS,
} from "../../../../graphql/fleets/settings/drivers";
import Form from "./components/Form";

const DriverCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createDriver] = useMutation(ADD_DRIVER, {
    refetchQueries: [
      {
        query: GET_DRIVERS,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: Driver,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createDriver({
      variables: {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        address: values.address,
        profileImageUrl: values.profileImageUrl,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.driverCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Driver created successfully",
          });
          return navigate(`/fleets/settings/drivers`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Driver creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Driver creation failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_create_driver")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_create_driver")}
          </p>
        </div>
        <div className="mt-4 flex sm:ml-16 sm:mt-0">
          <Link to="/fleets/settings/drivers" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_create")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title={DriverCreateResource.name}
        heading="Drivers"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Drivers",
            href: "/fleets/settings/drivers",
          },
          {
            name: DriverCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_driver")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_driver")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              name: "",
              email: "",
              mobile: "",
              address: "",
              profileImageUrl: "",
              status: 0,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default DriverCreate;
export const DriverCreateResource: ResourceProps = {
  name: "Create Driver",
  description: "Create a new driver",
  access: ["create-drivers"],
  path: "drivers/add",
};
