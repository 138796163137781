import { ErrorDebug } from "./";

const { REACT_APP_DEBUG } = process.env;

export function ErrorMessage({ error }: { error: Error }) {
  return (
    <div>
      <div className="text-sm text-red-700">{error.message}</div>
      {error && REACT_APP_DEBUG === "true" && (
        <ErrorDebug
          error={error}
          className="mx-auto mt-10 w-full max-w-3xl md:mt-16"
        />
      )}
    </div>
  );
}
