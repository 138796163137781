import { useMutation, useQuery } from "@apollo/client/react";
import { customAlphabet } from "nanoid";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import placeholder from "../../../assets/placeholder.svg";
import { ErrorFallback, Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { GET_PACKING_BY_ID } from "../../../graphql/pickpack/packing";
import {
  EDIT_ORDER,
  GET_ORDER_BY_ID,
  Order,
} from "../../../graphql/sales/orders";
import { formatVariantTitle } from "../../../utils";
import Form from "./components/FormUpdate";

const nanoidCustom = customAlphabet("1234567890", 10);

const OrderUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { orderId } = useParams<{ orderId: string }>();

  const { data, loading, error, refetch } = useQuery(GET_ORDER_BY_ID, {
    variables: {
      id: orderId,
    },
  });
  const order: Order = useMemo(() => {
    if (data?.fetchOrder) {
      return data.fetchOrder;
    }
    return {};
  }, [data]);

  const [updateOrder] = useMutation(EDIT_ORDER, {
    refetchQueries: [
      {
        query: GET_PACKING_BY_ID,
        variables: {
          id: orderId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateOrder({
      variables: {
        id: orderId,
        customerId: values.customerId,
        purchaseNumber: values.purchaseNumber,
        customerTradingAddressId: values.customerTradingAddressId,
        subTotal: parseInt(values.subTotal),
        total: parseInt(values.total),
        deliveryDate: values.deliveryDate,
        notes: values.notes,
        status: values.status,
        products: values.products,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.orderUpdate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Order updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Order update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Order update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  let address = "";
  if (order.shippingAddress) {
    address =
      order.shippingAddress.address +
      " " +
      order.shippingAddress.suburb +
      " " +
      order.shippingAddress.state +
      " " +
      order.shippingAddress.postcode;
  }

  return (
    <>
      <Head
        title={OrderUpdateResource.name}
        heading="Orders"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Orders",
            href: "/sales/orders",
          },
          {
            name: OrderUpdateResource.name,
            href: null,
          },
        ]}
      />

      {loading || !order ? (
        <Waiting />
      ) : (
        <Form
          initialValues={{
            customerId: parseInt(order.customer.id) ?? null,
            products:
              order.items.map((item) => ({
                productId: isNaN(parseInt(item.variant?.id))
                  ? Number(nanoidCustom())
                  : parseInt(item.variant?.id),
                quantity: item.quantity,
                price: item.price,
                orderComments: item.orderComments,
              })) ?? [],
            purchaseNumber: order.purchaseNumber ?? "",
            customerTradingAddressId: order.shippingAddress
              ? parseInt(order.shippingAddress.id)
              : null,
            deliveryDate: order.deliveryDate ?? null,
            notes: order.notes ?? "",
            subTotal: order.subTotal ?? 0,
            total: order.total ?? 0,
            status: order.status ?? 1,
          }}
          orderNumber={order.orderNumber}
          orderAddress={address}
          orderUpdatedAt={order.updatedAt}
          orderDate={order.createdAt}
          products={order.items.flatMap((item, index) => ({
            index,
            id: isNaN(parseInt(item.variant?.id))
              ? nanoidCustom()
              : item.variant?.id,
            oid: item.id,
            productId: isNaN(parseInt(item.variant?.product?.id))
              ? Number(nanoidCustom())
              : Number(item.variant.product.id),
            name:
              `${item.variant?.product?.name} - ${formatVariantTitle(
                item.variant?.variantTitle
              )}` ?? "",
            price: item.price,
            quantity: item.quantity,
            productApprove: item.productApprove ?? 0,
            featureImageUrl:
              item.variant?.variantImageUrl ??
              item.variant?.product?.featureImageUrl ??
              placeholder,
            plu: item.variant?.plu ?? null,
            stockCode: item.variant?.stockCode ?? "",
            minimumQuantity: item.variant?.minimumQuantity ?? 0,
            variantTitle: item.variant?.variantTitle ?? [],
            inventory: item.variant?.inventory ?? 0,
            status: item.variant?.product.status ?? 0,
            orderComments: item.orderComments ?? "",
          }))}
          customer={order.customer}
          timelines={order.timelines}
          onSubmit={handleSubmit}
          onRefetch={refetch}
        />
      )}
    </>
  );
};

export default OrderUpdate;
export const OrderUpdateResource: ResourceProps = {
  name: "Edit Order",
  description: "Update an existing order",
  access: ["update-salesorders"],
  path: "orders/:orderId",
};
