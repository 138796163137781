import { Link } from "react-router-dom";

import { VehicleBasic } from "../../graphql/fleets/vehicles";
import { classNames } from "../../utils";
import { ButtonPopover, PopoverPosition } from "../appearance";
import { ImageStatus } from "./ImageStatus";

type VehicleThumbProps = VehicleBasic & {
  position?: PopoverPosition;
  children?: React.ReactNode;
};
export function VehicleThumb({
  id,
  name,
  vehicleImageUrl,
  make,
  model,
  year,
  status,
  position = PopoverPosition.LEFT,
  children,
}: VehicleThumbProps) {
  return (
    <div className="group flex items-center text-sm">
      <ButtonPopover
        title={name}
        child={
          <ImageStatus
            title={name}
            url={vehicleImageUrl}
            color={status.color}
            className="h-10 w-10"
          />
        }
        isArrow={false}
        position={position}
        className="px-0"
      >
        <div className="px-2.5 py-2 leading-6 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
          <h3 className="mb-1 whitespace-nowrap font-medium">{name}</h3>
          <p>
            <span className="mr-1 text-gray-500">Status:</span>
            <span
              className={classNames(
                "mr-1 inline-block h-2.5 w-2.5 rounded-full"
              )}
              style={{
                backgroundColor: status.color,
              }}
            />
            {status.name}
          </p>
          {make ? (
            <p>
              <span className="text-gray-500">Make:</span> {make.name}
            </p>
          ) : null}
          {model ? (
            <p>
              <span className="text-gray-500">Model:</span> {model.name}
            </p>
          ) : null}
          {year ? (
            <p>
              <span className="text-gray-500">Year:</span> {year}
            </p>
          ) : null}
        </div>
        <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
          <Link
            to={`/fleets/vehicles/${id}`}
            target="_blank"
            className="block text-center text-sm text-gray-500"
          >
            View Vehicle
          </Link>
        </div>
      </ButtonPopover>
      <div>
        <Link
          to={`/fleets/vehicles/${id}`}
          className="text-primary-700 underline decoration-dotted underline-offset-2 transition-all group-hover:text-primary-900"
        >
          {name}
        </Link>
        {children}
      </div>
    </div>
  );
}
