import {
  ExclamationCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { DebounceInput } from "react-debounce-input";

import { classNames } from "../../utils";

type DebounceInputProps = React.ComponentProps<typeof DebounceInput>;
interface FieldDebounceProps
  extends Omit<DebounceInputProps, "inputRef" | "onChange"> {
  title: string;
  name: string;
  readOnly?: boolean;
  id?: string;
  className?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean | undefined;
  errors?: string | undefined;
  isLabel?: boolean;
  editMode?: boolean;
  suffix?: React.ReactNode;
  isSuffix?: boolean;
  caption?: string;
}
export function FieldDebounce(props: FieldDebounceProps) {
  const {
    title,
    caption,
    name,
    type,
    value,
    onChange,
    onBlur,
    readOnly = false,
    disabled = false,
    touched,
    errors,
    isLabel = true,
    id,
    className = "",
    placeholder,
    editMode = false,
    suffix = <Fragment />,
    isSuffix = false,
    ...rest
  } = props;
  const [isEdit, setEdit] = useState(false);

  return (
    <Fragment>
      <label
        htmlFor={id ? id : name}
        className={`block text-sm font-medium text-gray-900 ${
          isLabel ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </label>
      {caption ? (
        <p className="text-sm font-light text-gray-700">{caption}</p>
      ) : null}
      <div className="relative flex">
        <DebounceInput
          debounceTimeout={500}
          name={name}
          id={id ? id : name}
          type={type ?? "text"}
          className={classNames(
            "relative block w-full flex-1 appearance-none border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
            "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
            "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
            touched && errors ? "border-red-600 text-red-900" : "",
            className ? className : "",
            isSuffix ? "rounded-l-md border-r-0" : "rounded-md"
          )}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          readOnly={readOnly || (editMode && !isEdit)}
          placeholder={placeholder ? placeholder : ""}
          disabled={disabled}
          {...rest}
        />
        {editMode ? (
          <button
            type="button"
            className="absolute inset-y-0 right-0 z-10 flex items-center pl-3 pr-3 text-gray-700 transition hover:text-gray-900 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
            title={isEdit ? "Edit Mode" : "Display Mode"}
            onClick={() => {
              setEdit(!isEdit);
            }}
          >
            {isEdit ? (
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
        ) : touched && errors ? (
          <div
            className={classNames(
              "pointer-events-none absolute inset-y-0 flex items-center pr-3",
              isSuffix ? "right-14" : "right-0"
            )}
          >
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
        {isSuffix ? (
          <div
            className={classNames(
              "-ml-px rounded-r-md border  bg-primary-50 px-3 py-2.5 text-primary-900 sm:text-sm",
              touched && errors ? "border-red-600" : "border-primary-200"
            )}
          >
            {suffix}
          </div>
        ) : null}
      </div>
      {touched && errors ? (
        <p
          className="mt-2 text-sm text-red-600"
          id={`${id ? id : name}-errors`}
        >
          {errors}
        </p>
      ) : null}
    </Fragment>
  );
}
