import { useMutation, useQuery } from "@apollo/client/react";
import { useContext, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import { GenContext } from "../../../../contexts/GenContext";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  ADD_VEHICLEFINANCIAL,
  EDIT_VEHICLEFINANCIAL,
  GET_VEHICLEFINANCIAL_BY_VEHICLE_ID,
  VehicleFinancial,
  VehicleFinancialForm,
} from "../../../../graphql/fleets/vehicles/pages/financial";
import Form from "../components/FinancialForm";

const Financial = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const { vehicleForm, storeVehicleForm } = useContext(GenContext);

  const { data, loading, error, refetch } = useQuery<{
    fetchFinancialByVehicle: VehicleFinancial;
  }>(GET_VEHICLEFINANCIAL_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });
  const financial = useMemo<VehicleFinancial | null>(() => {
    let updatedFinancial = data?.fetchFinancialByVehicle as VehicleFinancial;
    if (!updatedFinancial) return null;
    updatedFinancial = {
      ...updatedFinancial,
      leaseDetails: Array.isArray(updatedFinancial.leaseDetails)
        ? updatedFinancial.leaseDetails.length
          ? updatedFinancial.leaseDetails[0]
          : null
        : updatedFinancial.leaseDetails,
      loanDetails: Array.isArray(updatedFinancial.loanDetails)
        ? updatedFinancial.loanDetails.length
          ? updatedFinancial.loanDetails[0]
          : null
        : updatedFinancial.loanDetails,
    };
    return updatedFinancial;
  }, [data]);

  const [createVehicleFinancial] = useMutation(ADD_VEHICLEFINANCIAL);
  const [updateVehicleFinancial] = useMutation(EDIT_VEHICLEFINANCIAL);

  const handleSubmit = (
    values: VehicleFinancialForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!vehicleId) return;
    if (financial?.id) {
      updateVehicleFinancial({
        variables: {
          id: financial.id,
          vehicleId: parseInt(vehicleId),
          fleetVendorId: values.fleetVendorId,
          purchaseDate: values.purchaseDate,
          purchasePrice: values.purchasePrice,
          odometer: values.odometer,
          notes: values.notes,
          warrantyExpiryDate: values.warrantyExpiryDate,
          maxOdometerValue: values.maxOdometerValue,
          financeType: values.financeType,
          loanDetails: values.loanDetails,
          leaseDetails: values.leaseDetails,
        },
      })
        .then(async ({ data }) => {
          if (data?.vehicleFinancialUpdate) {
            await refetch();
            storeVehicleForm({
              ...vehicleForm,
              financial: null,
            });
            toast.success("Vehicle financial updated successfully");
          } else {
            toast.error("Something went wrong, please try again later");
          }
          actions.setSubmitting(false);
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
      return;
    }

    createVehicleFinancial({
      variables: {
        vehicleId: parseInt(vehicleId),
        fleetVendorId: values.fleetVendorId,
        purchaseDate: values.purchaseDate,
        purchasePrice: values.purchasePrice,
        odometer: values.odometer,
        notes: values.notes,
        warrantyExpiryDate: values.warrantyExpiryDate,
        maxOdometerValue: values.maxOdometerValue,
        financeType: values.financeType,
        loanDetails: values.loanDetails,
        leaseDetails: values.leaseDetails,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleFinancialCreate) {
          await refetch();
          toast.success("Vehicle financial created successfully");
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={FinancialResource.name}
        heading={FinancialResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: FinancialResource.name,
            href: null,
          },
        ]}
      />
      <div className="py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_financial")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_financial")}
              </p>
            </div>
          </div>

          {loading ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                fleetVendorId: vehicleForm.id === vehicleId && vehicleForm?.financial ? vehicleForm?.financial?.vendor?.id : financial?.vendor?.id ?? null, // prettier-ignore
                purchaseDate: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.purchaseDate : financial?.purchaseDate ?? null, // prettier-ignore
                purchasePrice: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.purchasePrice : financial?.purchasePrice ?? null, // prettier-ignore
                odometer: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.odometer : financial?.odometer ?? null, // prettier-ignore
                notes: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.notes : financial?.notes ?? "", // prettier-ignore
                warrantyExpiryDate: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.warrantyExpiryDate : financial?.warrantyExpiryDate ?? null, // prettier-ignore
                maxOdometerValue: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.maxOdometerValue : financial?.maxOdometerValue ?? null, // prettier-ignore
                financeType: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.financeType : financial?.financeType ?? null, // prettier-ignore
                loanDetails: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.loanDetails : financial?.loanDetails ?? null, // prettier-ignore
                leaseDetails: (vehicleForm.id === vehicleId && vehicleForm?.financial) ? vehicleForm?.financial?.leaseDetails : financial?.leaseDetails ?? null, // prettier-ignore
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_save")}
              vehicleBasic={vehicleBasic}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Financial;
export const FinancialResource: ResourceProps = {
  name: "Financial",
  description: "Update an existing vehicle financial",
  access: ["update-vehicles"],
  path: "financial",
  icon: "bi bi-person",
};
