import { gql } from "@apollo/client";

export const METERENTRY_FRAGMENT = gql`
  fragment VehicleMeterEntryFragment on VehicleMeterEntry {
    id
    meterDate
    primaryMeter
    vehicleId
    createdAt
    updatedAt
  }
`;

export const GET_METERENTRIES = gql`
  ${METERENTRY_FRAGMENT}
  query FetchMeterEntries {
    fetchMeterEntries {
      ...VehicleMeterEntryFragment
    }
  }
`;

export const GET_METERENTRY_BY_ID = gql`
  ${METERENTRY_FRAGMENT}
  query FetchMeterEntry($id: ID!) {
    fetchMeterEntry(id: $id) {
      ...VehicleMeterEntryFragment
    }
  }
`;

export const GET_METERENTRY_BY_VEHICLE_ID = gql`
  ${METERENTRY_FRAGMENT}
  query FetchMeterEntriesByVehicle($vehicleId: ID!) {
    fetchMeterEntriesByVehicle(vehicleId: $vehicleId) {
      ...VehicleMeterEntryFragment
    }
  }
`;

export const ADD_METERENTRY = gql`
  ${METERENTRY_FRAGMENT}
  mutation VehicleMeterEntryCreate(
    $vehicleId: Int!
    $meterDate: ISO8601DateTime!
    $primaryMeter: Float!
  ) {
    vehicleMeterEntryCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          meterDate: $meterDate
          primaryMeter: $primaryMeter
        }
      }
    ) {
      vehicleMeterEntry {
        ...VehicleMeterEntryFragment
      }
    }
  }
`;

export const EDIT_METERENTRY = gql`
  ${METERENTRY_FRAGMENT}
  mutation VehicleMeterEntryUpdate(
    $id: ID!
    $vehicleId: Int!
    $meterDate: ISO8601DateTime!
    $primaryMeter: Float!
  ) {
    vehicleMeterEntryUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          meterDate: $meterDate
          primaryMeter: $primaryMeter
        }
      }
    ) {
      vehicleMeterEntry {
        ...VehicleMeterEntryFragment
      }
    }
  }
`;

export const REMOVE_METERENTRY = gql`
  ${METERENTRY_FRAGMENT}
  mutation VehicleMeterEntryDelete($id: ID!) {
    vehicleMeterEntryDelete(input: { id: $id }) {
      vehicleMeterEntry {
        ...VehicleMeterEntryFragment
      }
    }
  }
`;

export type VehicleMeterEntry = {
  id: string;
  meterDate: string | null;
  primaryMeter: number | null;
  vehicleId: number;
  createdAt: string;
  updatedAt: string;
};

export type VehicleMeterEntryForm = Omit<
  VehicleMeterEntry,
  "id" | "vehicleId" | "createdAt" | "updatedAt"
> & {
  id?: string;
  vehicleId: number | null;
};
