import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_PRICINGCATEGORY,
  GET_PRICINGCATEGORIES,
} from "../../../../graphql/inventory/pricing/pricing-categories";
import FormCreate from "./components/FormCreate";

const PricingCategoryCreate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createPricingCategory] = useMutation(ADD_PRICINGCATEGORY, {
    refetchQueries: [
      {
        query: GET_PRICINGCATEGORIES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createPricingCategory({
      variables: {
        name: values.name,
        description: values.description,
        productType: values.productType,
        productIds: values.productIds,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingCategoryCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing category created successfully",
          });
          return navigate(
            `/inventory/pricing/pricing-categories/${data?.pricingCategoryCreate?.pricingCategory?.id}`
          );
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing category creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing category creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Pricing Category"
        heading="Pricing Categories"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Pricing Categories",
            href: "/inventory/pricing/pricing-categories",
          },
          {
            name: PricingCategoryCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {PricingCategoryCreateResource.name}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {PricingCategoryCreateResource.description}
              </p>
            </div>
          </div>

          <FormCreate
            initialValues={{
              name: "",
              description: "",
              productType: 0,
              productIds: [],
              status: true,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default PricingCategoryCreate;
export const PricingCategoryCreateResource: ResourceProps = {
  name: "Create Pricing Category",
  description: "Create a new pricing category",
  access: ["create-pricings"],
  path: "pricing-categories/add",
};
