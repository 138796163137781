import { useLazyQuery, useQuery } from "@apollo/client/react";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import { Button } from "../../../../../components/form";
import {
  GET_VEHICLE_BY_ID,
  Vehicle,
} from "../../../../../graphql/fleets/vehicles";
import {
  GET_FUELENTRIES_BY_VEHICLE_ID,
  GET_FUELENTRY_BY_ID,
  VehicleFuelEntry,
} from "../../../../../graphql/fleets/vehicles/pages/fuel-entry";
import { AuthShield } from "../../../../auth/core";
import { FuelEntryDetail } from "../../components/FuelEntryDetail";

const FuelEntryView = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  let { fuelEntryId } = useParams();
  const { t } = useTranslation();

  const [fetchVehicle, { data: vehicleData }] = useLazyQuery<{
    fetchVehicle: Vehicle;
  }>(GET_VEHICLE_BY_ID);

  const [fetchFuelEntries, { data: fuelEntriesData }] = useLazyQuery<{
    fetchFuelEntriesByVehicle: VehicleFuelEntry[];
  }>(GET_FUELENTRIES_BY_VEHICLE_ID);

  const { data, loading, error } = useQuery<{
    fetchFuelEntry: VehicleFuelEntry;
  }>(GET_FUELENTRY_BY_ID, {
    variables: { id: fuelEntryId },
    onCompleted(data) {
      fetchVehicle({ variables: { id: data?.fetchFuelEntry?.vehicle.id } });
      fetchFuelEntries({
        variables: { vehicleId: data?.fetchFuelEntry?.vehicle.id },
      });
    },
  });

  const fuelEntry = useMemo<VehicleFuelEntry | undefined>(
    () => data?.fetchFuelEntry,
    [data]
  );

  const vehicle = useMemo<Vehicle | undefined>(
    () => vehicleData?.fetchVehicle,
    [vehicleData]
  );

  const fuelEntries = useMemo<VehicleFuelEntry[]>(
    () => fuelEntriesData?.fetchFuelEntriesByVehicle ?? [],
    [fuelEntriesData]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={FuelEntryViewResource.name}
        heading={FuelEntryViewResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: FuelEntryViewResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_fuel_entry")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_fuel_entry")}
              </p>
            </div>
            <div className="ml-auto flex items-center space-x-2 pr-3">
              <AuthShield access={["read-vehicles"]}>
                <Button
                  type="link"
                  href={`/fleets/vehicles/fuel-entries/${fuelEntryId}/edit`}
                >
                  <PencilIcon className="mr-2 h-5 w-5" />
                  {t("text_edit")}
                </Button>
              </AuthShield>
            </div>
          </div>

          {loading || !fuelEntry ? (
            <Waiting />
          ) : (
            <FuelEntryDetail
              fuelEntry={fuelEntry}
              fuelEntries={fuelEntries}
              vehicleBasic={
                vehicle
                  ? {
                      id: parseInt(vehicle.id),
                      name: vehicle.name,
                      vehicleImageUrl: vehicle.vehicleImageUrl,
                      make: vehicle.make,
                      model: vehicle.model,
                      year: vehicle.year,
                      operator: vehicle.operator,
                      status: vehicle.status,
                      fuelUnit: vehicle?.settings?.length
                        ? vehicle?.settings[0].fuelUnit
                        : null,
                      measurementUnit: vehicle?.settings?.length
                        ? vehicle?.settings[0].measurementUnit
                        : null,
                      primaryMeter: vehicle?.settings?.length
                        ? vehicle?.settings[0].primaryMeter
                        : null,
                    }
                  : null
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FuelEntryView;
export const FuelEntryViewResource: ResourceProps = {
  name: "Fuel Entry",
  description: "View an existing fuel entry",
  access: ["read-vehicles"],
  path: "/fuel-entries/:fuelEntryId",
};
