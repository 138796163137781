import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { FormikProps, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import placeholder from "../../../../assets/placeholder.svg";
import {
  Button,
  Field,
  FieldCustomerShippingAddress,
  FieldDatepicker,
  FieldOrderProducts,
} from "../../../../components/form";
import {
  Customer,
  Order,
  RowProduct,
  TradingAddress,
} from "../../../../graphql/sales/orders";
import { classNames } from "../../../../utils";

const orderSchema = Yup.object().shape({
  customerId: Yup.number().nullable().required("Required"),
  purchaseNumber: Yup.string().nullable(),
  products: Yup.array().of(
    Yup.object()
      .shape({
        productId: Yup.number().nullable(),
        price: Yup.number().nullable(),
        quantity: Yup.number().nullable(),
        orderComments: Yup.string().nullable(),
      })
      .nullable()
  ),
  customerTradingAddressId: Yup.number().nullable(),
  deliveryDate: Yup.string().nullable(),
  notes: Yup.string(),
  subTotal: Yup.number(),
  total: Yup.number(),
  status: Yup.number(),
});

type InitialValues = Pick<Order, "status" | "total" | "subTotal" | "notes"> & {
  purchaseNumber: string;
  customerTradingAddressId: number | null;
  deliveryDate: string | null;
  products: {
    productId: number;
    price: number;
    quantity: number;
    orderComments: string;
  }[];
  customerId: number | null;
};

export default function Form({
  initialValues,
  customer,
  onSubmit,
  onCancel,
}: {
  initialValues: InitialValues;
  customer: Omit<Customer, "orders">;
  onSubmit: (values: any, actions: any) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();
  const [paymentEdit, setPaymentEdit] = useState<boolean>(false);

  const [selectedProducts, setSelectedProducts] = useState<
    (RowProduct & {
      orderComments: string;
    })[]
  >([]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: orderSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched } = formik;

  const handlePriceChange = useCallback(() => {
    const total = formik.values.products.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
    formik.setFieldValue("subTotal", total.toFixed(2));
    formik.setFieldValue("total", total.toFixed(2));
  }, [formik.values.products]);

  useEffect(() => {
    handlePriceChange();
  }, [selectedProducts, handlePriceChange]);

  const handleRemoveProduct = useCallback(
    (product: RowProduct) => {
      const newSelectedProducts = selectedProducts.filter(
        (selectedProduct) => selectedProduct.id !== product.id
      );
      setSelectedProducts(newSelectedProducts);

      if (newSelectedProducts.length === 0) {
        formik.setFieldValue("products", []);
        return;
      }

      const updatedProducts = formik.values.products.filter(
        (p) => parseInt(product.id) !== p.productId
      );
      formik.setFieldValue("products", updatedProducts);
    },
    [formik, selectedProducts]
  );

  const handleUpdateProduct = useCallback(
    (
      variant: RowProduct,
      quantity: number,
      price: number,
      orderComments: string
    ) => {
      const updatedSelectedProducts = selectedProducts.map((p) => {
        if (p.id === variant.id) {
          return {
            ...p,
            quantity,
            price,
            orderComments,
          };
        }
        return p;
      });
      const updatedProducts = formik.values.products.map((p) => {
        if (p.productId === parseInt(variant.id)) {
          return {
            ...p,
            quantity,
            price,
            orderComments,
          };
        }
        return p;
      });
      setSelectedProducts(updatedSelectedProducts);
      formik.setFieldValue("products", updatedProducts);
    },
    [formik, selectedProducts]
  );

  return (
    <>
      <div className="mx-auto max-w-6xl">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_order")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_order")}
              </p>
            </div>
            <div className="mt-4 flex sm:ml-16 sm:mt-0">
              <Button
                variant="secondary"
                onClick={onCancel}
                className="mr-2 flex"
              >
                {t("text_cancel")}
              </Button>
              <Button type="submit" disabled={formik.isSubmitting}>
                {formik.isSubmitting ? (
                  <>
                    <Spinner />
                    {t("text_processing")}
                  </>
                ) : (
                  t("text_save")
                )}
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
            <div className="space-y-4 md:col-span-8">
              <div className="rounded-xl bg-greyish p-5">
                <label
                  htmlFor="description"
                  className="mb-1 block text-sm font-medium text-gray-900"
                >
                  {t("text_products")}
                </label>

                {formik.values.customerId ? (
                  <FieldOrderProducts
                    customerId={formik.values.customerId}
                    data={selectedProducts}
                    value={selectedProducts.flatMap((p) => Number(p.id))}
                    onChange={(products) => {
                      const updatedProducts = products.map((p) => {
                        const existingProduct = formik.values.products.find(
                          (product) => product.productId === Number(p.id)
                        );
                        return {
                          productId: Number(p.id),
                          quantity: existingProduct?.quantity || p.quantity,
                          price: existingProduct?.price || p.price,
                          orderComments: existingProduct?.orderComments || "",
                        };
                      });
                      formik.setFieldValue("products", updatedProducts);
                      setSelectedProducts(
                        products.map((p) => ({
                          ...p,
                          orderComments: "",
                        }))
                      );
                    }}
                  />
                ) : null}

                <div className="mt-2">
                  {selectedProducts.length > 0 ? (
                    <div className="divide-y">
                      {selectedProducts.map((variant, index) => (
                        <OrderProductItem
                          key={variant.id}
                          variant={variant}
                          index={index}
                          formik={formik}
                          handleRemoveProduct={handleRemoveProduct}
                          handleUpdateProduct={handleUpdateProduct}
                        />
                      ))}
                    </div>
                  ) : formik.values.customerId === null ? (
                    <div className="py-6 text-center text-sm md:py-12">
                      <ExclamationCircleIcon
                        type="outline"
                        name="exclamation-circle"
                        className="mx-auto h-6 w-6 text-gray-400"
                      />
                      <p className="mt-4 font-medium text-gray-900">
                        No customer selected
                      </p>
                      <p className="mt-2 text-gray-500">
                        Please select a customer to add products to the order.
                      </p>
                    </div>
                  ) : null}
                  {formik.touched.products && formik.errors.products ? (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="customer-errors"
                    >
                      {formik.errors.products.toString()}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <h4 className="text-md mb-2 font-medium text-gray-700">
                    Payment
                  </h4>
                  <Button
                    variant="text"
                    onClick={() => {
                      setPaymentEdit((prev) => !prev);
                    }}
                    className="text-sm text-primary-700 hover:text-primary-800"
                  >
                    {paymentEdit ? t("text_cancel") : t("text_edit")}
                  </Button>
                </div>
                <div className="space-y-4 rounded-xl bg-greyish p-5">
                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-900"
                    >
                      {t("text_sub_total")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="flex justify-end text-right">
                      {paymentEdit ? (
                        <Field
                          title={t("text_sub_total")}
                          name="subTotal"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subTotal}
                          touched={touched.subTotal}
                          errors={errors.subTotal}
                          isLabel={false}
                          placeholder={t("text_sub_total")}
                          className="w-24"
                        />
                      ) : (
                        <p className="text-sm font-medium text-gray-900">
                          $ {formik.values.subTotal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-700"
                    >
                      {t("text_add_shipping")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-900">
                        $ 0.00
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-700"
                    >
                      {t("text_estimated_tax")}
                    </label>
                    <span className="text-xs">Not calculated</span>
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-900">
                        $ 0.00
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="total"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-900"
                    >
                      {t("text_total")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="flex justify-end text-right">
                      {paymentEdit ? (
                        <Field
                          name="total"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.total}
                          touched={touched.total}
                          errors={errors.total}
                          isLabel={false}
                          className="w-24"
                        />
                      ) : (
                        <p className="text-sm font-medium text-gray-900">
                          $ {formik.values.total}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4 space-y-4">
              <div className="divide-y divide-gray-300 rounded-xl bg-greyish px-4 py-5 sm:p-6">
                <fieldset className="pb-4">
                  <h4 className="text-md block font-medium text-gray-900">
                    {t("text_customer")}
                  </h4>
                  <span className="block text-sm font-normal text-gray-500">
                    <Link
                      to={`/sales/customers/${customer.id}`}
                      className="text-gray-700 transition hover:text-gray-900"
                    >
                      {customer.customerName}
                    </Link>
                  </span>
                </fieldset>
                <fieldset className="py-4">
                  <Field
                    title={t("text_purchase_number")}
                    name="purchaseNumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.purchaseNumber}
                    touched={touched.purchaseNumber}
                    errors={errors.purchaseNumber}
                  />
                </fieldset>
                <fieldset className="py-4">
                  <h4 className="block text-sm font-medium text-gray-900">
                    Contact Information
                  </h4>
                  <span className="block text-sm font-normal text-gray-500">
                    <a href={`mailto:${customer.email}`}>{customer.email}</a>
                  </span>
                </fieldset>
                <fieldset className="py-4">
                  <h4 className="text-md block font-medium text-gray-900">
                    Delivery Information
                  </h4>
                  <FieldCustomerShippingAddress
                    title={t("text_trading_address")}
                    data={customer.tradingAddresses}
                    onChange={(value: TradingAddress | null) => {
                      if (!value) {
                        formik.setFieldValue("customerTradingAddressId", null);
                        return;
                      }
                      formik.setFieldValue(
                        "customerTradingAddressId",
                        parseInt(value.id)
                      );
                    }}
                    value={formik.values.customerTradingAddressId}
                    className={classNames(
                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      formik.touched.customerTradingAddressId &&
                        formik.errors.customerTradingAddressId
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                  />
                  <fieldset className="mt-4">
                    <FieldDatepicker
                      title={t("text_delivery_date")}
                      name="deliveryDate"
                      onChange={(value) => {
                        if (!Array.isArray(value)) {
                          formik.setFieldValue(
                            "deliveryDate",
                            value ? new Date(value).toISOString() : ""
                          );
                        }
                      }}
                      minDate={new Date()}
                      selected={
                        formik.values.deliveryDate
                          ? new Date(formik.values.deliveryDate)
                          : null
                      }
                      touched={formik.touched.deliveryDate}
                      errors={formik.errors.deliveryDate}
                    />
                  </fieldset>
                  <div className="mt-3">
                    <Field
                      title={t("text_delivery_note")}
                      name="notes"
                      type="textarea"
                      className="h-24"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.notes}
                      touched={formik.touched.notes}
                      errors={formik.errors.notes}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

function OrderProductItem({
  variant,
  index,
  formik,
  handleRemoveProduct,
  handleUpdateProduct,
}: {
  variant: RowProduct & {
    orderComments: string;
  };
  index: number;
  formik: FormikProps<InitialValues>;
  handleRemoveProduct: (product: RowProduct) => void;
  handleUpdateProduct: (
    variant: RowProduct,
    quantity: number,
    price: number,
    orderComments: string
  ) => void;
}) {
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState<boolean>(false);
  useEffect(() => {
    setShowComments(variant.orderComments !== "");
  }, [variant.orderComments]);

  return (
    <div className="relative py-2 md:py-4">
      <Button
        variant="text"
        onClick={() => {
          handleRemoveProduct(variant);
        }}
        className="absolute right-0 top-4 mt-5"
      >
        <span className="sr-only">Remove {variant.name}</span>
        <span
          aria-hidden="true"
          className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
        />
      </Button>
      <div className="mr-6 flex items-center">
        <span className="mr-4 text-sm text-gray-700">{index + 1}.</span>
        <div className="h-10 w-10 flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={
              variant.featureImageUrl ? variant.featureImageUrl : placeholder
            }
            alt={variant.name}
          />
        </div>
        <div className="ml-4 mr-4">
          <Link
            to={`/inventory/products/${variant.productId}`}
            target="_blank"
            className="text-sm font-medium text-gray-900"
          >
            {variant.name}
          </Link>
        </div>
        <div className="ml-auto flex items-center">
          <div className="mr-4">
            <Field
              title={t("text_quantity")}
              name={`products[${index}].quantity`}
              type="number"
              onChange={(e) => {
                const quantity = parseInt(e.target.value);
                const minimum = variant.minimumQuantity || 1;
                if (quantity >= minimum && quantity <= variant.inventory) {
                  formik.setFieldValue(`products[${index}].quantity`, quantity);
                }
                if (!isNaN(quantity)) {
                  handleUpdateProduct(
                    variant,
                    quantity,
                    variant.price,
                    variant.orderComments
                  );
                }
              }}
              value={variant.quantity}
              minLength={variant.minimumQuantity || 1}
              className="w-24"
            />
          </div>
          <div className="mr-4">
            <Field
              title={t("text_price")}
              name={`products[${index}].price`}
              type="number"
              onChange={(e) => {
                const price = parseFloat(e.target.value);
                if (!isNaN(price)) {
                  handleUpdateProduct(
                    variant,
                    variant.quantity,
                    price,
                    variant.orderComments
                  );
                }
              }}
              value={variant.price}
              className="w-24"
            />
          </div>
        </div>
      </div>
      {showComments && (
        <div className="ml-20 mr-6">
          <Field
            title={t("text_comments")}
            type="textarea"
            onChange={(e) => {
              handleUpdateProduct(
                variant,
                variant.quantity,
                variant.price,
                e.target.value
              );
            }}
            value={variant.orderComments}
            className="h-14"
          />
        </div>
      )}
      <div className="mr-10 mt-2 flex justify-end">
        {showComments ? (
          <Button
            variant="text"
            className="inline-flex text-xs font-medium text-blue-900 hover:underline"
            onClick={() => setShowComments(false)}
          >
            Hide Comment
          </Button>
        ) : (
          <Button
            variant="text"
            className="inline-flex text-xs font-medium text-blue-900 hover:underline"
            onClick={() => setShowComments(true)}
          >
            Add Comment
          </Button>
        )}
      </div>
    </div>
  );
}
