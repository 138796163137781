import { gql } from "@apollo/client";

export const GET_PRICINGCATEGORIES = gql`
  query FetchPricingCategories {
    fetchPricingCategories {
      id
      name
      description
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
      }
      createdAt
      status
    }
  }
`;

export const ADD_PRICINGCATEGORY = gql`
  mutation PricingCategoryCreate(
    $name: String!
    $description: String
    $productType: Int
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingCategoryCreate(
      input: {
        params: {
          name: $name
          description: $description
          productType: $productType
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingCategory {
        id
        name
      }
    }
  }
`;

export const EDIT_PRICINGCATEGORY = gql`
  mutation PricingCategoryUpdate(
    $id: ID!
    $name: String!
    $description: String
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingCategoryUpdate(
      input: {
        id: $id
        params: {
          name: $name
          description: $description
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingCategory {
        id
        name
      }
    }
  }
`;

export const REMOVE_PRICINGCATEGORY = gql`
  mutation PricingCategoryDelete($id: ID!) {
    pricingCategoryDelete(input: { id: $id }) {
      pricingCategory {
        id
        name
      }
    }
  }
`;

export const EDIT_PRICINGSTRUCTURE_BY_CATEGORY_ID = gql`
  mutation PricingStructureUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $pricingCategoryId: Int!
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          pricingCategoryId: $pricingCategoryId
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

export const CHECK_STRUCTURERELATION = gql`
  query CheckStructureRelation(
    $productIds: [Int!]
    $customerIds: [Int!]
    $pricingStructureIds: [Int!]
  ) {
    checkStructureRelation(
      productIds: $productIds
      customerIds: $customerIds
      pricingStructureIds: $pricingStructureIds
    ) {
      customerId
      customerName
      productId
      productName
    }
  }
`;

export const CHECK_STRUCTURERELATION_CUSTOMERS = gql`
  query CheckStructureRelationCustomers(
    $productIds: [Int!]
    $customerIds: [Int!]
    $pricingStructureIds: [Int!]
  ) {
    checkStructureRelation(
      productIds: $productIds
      customerIds: $customerIds
      pricingStructureIds: $pricingStructureIds
    ) {
      customerId
      customerName
      productId
      productName
    }
  }
`;

export const ADD_PRICINGSTRUCTURE_BY_CATEGORY_ID = gql`
  mutation PricingStructureCreate(
    $name: String!
    $pricingMethodId: Int!
    $pricingCategoryId: Int!
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureCreate(
      input: {
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          pricingCategoryId: $pricingCategoryId
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

export const REMOVE_PRICINGSTRUCTURECUSTOMER_BY_ID_CUSTOMER_ID = gql`
  mutation PricingStructureCustomerDelete(
    $customerId: Int!
    $pricingStructureId: Int!
  ) {
    pricingStructureCustomerDelete(
      input: {
        customerId: $customerId
        pricingStructureId: $pricingStructureId
      }
    ) {
      message
    }
  }
`;

export const REMOVE_PRICINGCATEGORYPRODUCT_BY_ID_CATEGORY_ID = gql`
  mutation PricingCategoryProductDelete(
    $productId: Int!
    $pricingCategoryId: Int!
  ) {
    pricingCategoryProductDelete(
      input: { productId: $productId, pricingCategoryId: $pricingCategoryId }
    ) {
      message
    }
  }
`;

export const GET_PRICINGCATEGORY_BY_CATEGORY_ID = gql`
  query FetchPricingCategory($id: ID!) {
    fetchPricingCategory(id: $id) {
      id
      name
      description
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
        customers {
          id
          customerName
        }
        status
      }
      products {
        id
        name
        featureImageUrl
        status
      }
      createdAt
      status
    }
  }
`;

export const REMOVE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureDelete($id: ID!) {
    pricingStructureDelete(input: { id: $id }) {
      pricingStructure {
        id
        name
      }
    }
  }
`;
