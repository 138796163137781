import { Dialog, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import Sketch from "react-color/lib/components/sketch/Sketch";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../../../animations";
import { Button, Field } from "../../../../../../components/form";
import { VehicleStatus } from "../../../../../../graphql/fleets/vehicles/sections/status";
import { classNames } from "../../../../../../utils";

type InitialValues = Pick<VehicleStatus, "name" | "color" | "default"> & {
  id?: string;
};
export default function Form({
  heading,
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: InitialValues;
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();

  const ResourceSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    color: Yup.string().required("Required"),
    default: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ResourceSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 py-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.name ? formik.values.name : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Get started by filling in the information below to create your
                new vehicle status.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <Field
                      title={t("text_name")}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      touched={touched.name}
                      errors={errors.name}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <div className="text-sm font-medium text-gray-900">
                      {t("text_default")}
                    </div>
                    <Switch.Group
                      as="div"
                      className="mt-1.5 inline-flex items-center"
                    >
                      <Switch
                        checked={formik.values.default}
                        onChange={() => {
                          formik.setFieldValue(
                            "default",
                            !formik.values.default
                          );
                        }}
                        id="default"
                        className={classNames(
                          formik.values.default
                            ? "bg-primary-600"
                            : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            formik.values.default
                              ? "translate-x-5"
                              : "translate-x-0",
                            "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                      <Switch.Label
                        passive
                        htmlFor="default"
                        className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                      >
                        {formik.values.default
                          ? t("text_active")
                          : t("text_inactive")}
                      </Switch.Label>
                    </Switch.Group>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="color"
                      className="mb-4 block text-sm font-medium text-gray-900"
                    >
                      {t("text_color")}
                    </label>
                    <Sketch
                      className="rounded-md border border-gray-300 shadow-none"
                      styles={{
                        default: {
                          picker: {
                            boxShadow: "none",
                            borderRadius: "0.375rem",
                            borderWidth: "1px",
                            borderColor:
                              "rgb(209 213 219 / var(--tw-border-opacity))",
                            boxSizing: "border-box",
                          },
                        },
                      }}
                      width="100%"
                      color={formik.values.color}
                      onChangeComplete={(color) => {
                        formik.setFieldValue("color", color.hex);
                      }}
                    />
                    {formik.touched.color && formik.errors.color ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="color-errors"
                      >
                        {formik.errors.color}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
