import { formatBytes, getFileNameExt } from "../../../utils";
import { getToken } from "../../auth";
import { MediaApiProps, MediaProps } from "./types";

const { REACT_APP_GRAPHQL_URL } = process.env;
const MEDIA_PREFIX = "https://storage.googleapis.com/alphafresh/";

export async function fetchMedia() {
  const token = await getToken();
  return new Promise<any>((resolve, reject) => {
    fetch(`${REACT_APP_GRAPHQL_URL}/media/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.status) reject(new Error("Error fetching media"));
        const results: MediaProps[] =
          data.files.map((file: MediaApiProps) => {
            const { title, extension } = getFileNameExt(file.name || "");
            return {
              title: title || file.name,
              name: file.name,
              url: `${MEDIA_PREFIX}${file.name}`,
              size: formatBytes(file.size || 0),
              type: file.content_type,
              date: file.created_at,
              extension: extension || "",
            };
          }) || [];
        resolve(results);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function uploadMedia(file: string) {
  const token = await getToken();
  return new Promise<any>((resolve, reject) => {
    if (!file) throw new Error("File not found");
    let formData = new FormData();
    formData.append("path", "media");
    formData.append("image", file);
    fetch(`${REACT_APP_GRAPHQL_URL}/image/upload`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function deleteMedia(file: string) {
  const token = await getToken();
  return new Promise<any>((resolve, reject) => {
    if (!file) throw new Error("File not found");
    let formData = new FormData();
    formData.append("path", file);
    fetch(`${REACT_APP_GRAPHQL_URL}/image/delete`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
