import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowsUpDownIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Link } from "react-router-dom";

import { Spinner } from "../../animations";
import placeholder from "../../assets/placeholder.svg";
import { GET_VEHICLES, Vehicle } from "../../graphql/fleets/vehicles";
import { classNames } from "../../utils";
import { VehicleThumb, Watchers } from "../appearance";
import { ErrorFallback } from "../core";
import {
  IndeterminateCheckbox,
  TableCursor,
  TableStaticInfinite,
} from "../table";
import { Button } from ".";

interface RowVehicle extends Vehicle {
  index: number;
}

export function FieldTableVehicles({
  title,
  data: previews,
  value = [],
  onChange,
  excludeIds = [],
  excludeNote = "",
}: {
  title: string;
  data: Vehicle[];
  value: number[];
  onChange: (newValue: Vehicle[]) => void;
  excludeIds?: number[];
  excludeNote?: string;
}) {
  const [query, setQuery] = useState<string>("");

  const [pageLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

  const { data, loading, error } = useQuery(GET_VEHICLES);

  const vehicles: RowVehicle[] = useMemo(() => {
    const updatedVehicles: RowVehicle[] =
      data?.fetchVehicles?.flatMap((vehicle: Vehicle, index: number) => ({
        ...vehicle,
        index,
      })) ?? [];

    if (value.length > 0) {
      const updatedRowSelection: Record<string, boolean> = {};
      updatedVehicles.forEach((p, i) => {
        updatedRowSelection[String(i)] = value.includes(Number(p.id));
      });
      setRowSelection(updatedRowSelection);
    } else {
      setRowSelection({});
    }

    return updatedVehicles;
  }, [data?.fetchVehicles, value]);

  const columns = useMemo<ColumnDef<RowVehicle, any>[]>(
    () => [
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect() || row.depth > 0,
                  indeterminate: row.getIsSomeSelected(),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target;
                    row.getToggleSelectedHandler()(e);
                    if (checked) {
                      onChange([...previews, row.original]);
                      return;
                    }
                    onChange(previews.filter((p) => p.id !== row.original.id));
                  },
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        cell: (props) => (
          <Fragment>
            <VehicleThumb
              name={props.row.original.name}
              status={props.row.original.status}
              vehicleImageUrl={props.row.original.vehicleImageUrl}
              make={props.row.original.make}
              model={props.row.original.model}
              year={props.row.original.year}
              operator={props.row.original.operator}
              id={parseInt(props.row.original.id)}
              fuelUnit={null}
              measurementUnit={null}
              primaryMeter={null}
            />
            {excludeNote &&
            excludeIds.includes(Number(props.row.original.id)) ? (
              <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                {excludeNote}
              </span>
            ) : null}
          </Fragment>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (props) => (
          <span className="flex items-center whitespace-nowrap font-light">
            <span
              className={classNames(
                "mr-2 inline-block h-3 w-3 rounded-full align-middle"
              )}
              style={{
                backgroundColor: props.row.original.status.color,
              }}
            />
            {props.row.original.status.name}
          </span>
        ),
      },
      {
        accessorKey: "vehicleType",
        header: "Type",
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.vehicleType.name}
          </span>
        ),
      },
      {
        accessorKey: "licensePlate",
        header: "License Plate",
      },
      {
        id: "watchers",
        header: "Watchers",
        cell: (props) => (
          <Watchers
            caption={`List of watchers of ${props.row.original.name}`}
            data={props.row.original.watchers}
          />
        ),
      },
      {
        accessorKey: "operator",
        header: "Operator",
        size: 300,
        cell: (props) =>
          props.row.original.operator ? (
            <div className="flex w-full items-center">
              <Link
                to={`/fleets/settings/drivers/${props.row.original.operator.id}`}
                className="relative mr-2 block h-10 w-10 cursor-pointer appearance-none overflow-hidden rounded-md ring-2 ring-white filter transition hover:hue-rotate-15"
              >
                <img
                  className={classNames("h-full w-full object-cover")}
                  src={
                    props.row.original.operator.profileImageUrl
                      ? props.row.original.operator.profileImageUrl
                      : placeholder
                  }
                  alt={props.row.original.operator.name}
                />
              </Link>
              <span className="max-w-[12rem] truncate lg:max-w-[14rem] 2xl:max-w-[18rem]">
                {props.row.original.operator.name}
              </span>
            </div>
          ) : null,
      },
    ],
    [excludeIds, excludeNote, onChange, previews]
  );

  const previewColumns = useMemo<ColumnDef<Vehicle>[]>(
    () => [
      {
        id: "index",
        header: "No.",
        size: 30,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.row.index + 1}.</span>
        ),
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 100,
        cell: (props) => (
          <Fragment>
            <VehicleThumb
              name={props.row.original.name}
              status={props.row.original.status}
              vehicleImageUrl={props.row.original.vehicleImageUrl}
              make={props.row.original.make}
              model={props.row.original.model}
              year={props.row.original.year}
              operator={props.row.original.operator}
              id={parseInt(props.row.original.id)}
              fuelUnit={null}
              measurementUnit={null}
              primaryMeter={null}
            />
            {excludeNote &&
            excludeIds.includes(Number(props.row.original.id)) ? (
              <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                {excludeNote}
              </span>
            ) : null}
          </Fragment>
        ),
      },
      {
        id: "remove",
        header: "Remove",
        cell: (props) => (
          <div>
            <Button
              variant="text"
              onClick={() => {
                const newVehicles = previews.filter(
                  (p) => p.id !== props.row.original.id
                );
                onChange(newVehicles);
              }}
            >
              <span className="sr-only">Remove {props.row.original.name}</span>
              <span
                aria-hidden="true"
                className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
              />
            </Button>
          </div>
        ),
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    [excludeIds, excludeNote, onChange, previews]
  );

  const filteredSelectedVehicles = useMemo(
    () =>
      query === ""
        ? previews
        : previews.filter((p) =>
            p.name?.toLowerCase().includes(query?.toLowerCase())
          ),
    [previews, query]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <label className="mb-1 block text-sm font-medium text-gray-900">
        {title}
      </label>
      <header className="grid grid-cols-1 gap-4 sm:grid-cols-4">
        <div className="relative col-span-3">
          <label htmlFor="search" className="sr-only">
            Search vehicles
          </label>
          <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className={classNames(
              "relative block w-full appearance-none rounded-md border border-gray-300 py-2.5 pl-10 pr-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
              "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
            )}
            placeholder="Search vehicles"
            value={query || ""}
            onChange={(e) => {
              previews.length > 0
                ? setQuery(e.target.value)
                : setSearchQuery(e.target.value);
            }}
          />
        </div>
        <div className="col-span-1 flex">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={() => {
              setSearchQuery("");
            }}
          >
            Browse
          </Button>
        </div>
      </header>
      <div className="mt-2">
        {loading ? (
          <div className="flex justify-center py-6 md:py-12">
            <Spinner className="h-8 w-8 text-primary-700" />
          </div>
        ) : previews.length > 0 ? (
          <div className="rounded-lg bg-greyish pt-2">
            <TableStaticInfinite
              data={filteredSelectedVehicles}
              columns={previewColumns}
              loading={loading}
              filtering={query.length > 0}
              totalRows={previews.length}
              pageLimit={pageLimit}
              getCoreRowModel={getCoreRowModel()}
              className=""
            />
          </div>
        ) : (
          <div className="py-6 text-center text-sm md:py-12">
            <ExclamationCircleIcon
              type="outline"
              name="exclamation-circle"
              className="mx-auto h-6 w-6 text-gray-400"
            />
            <p className="mt-4 font-medium text-gray-900">
              No vehicles selected
            </p>
            <p className="mt-2 text-gray-500">
              Please{" "}
              <Button
                variant="text"
                onClick={() => {
                  setSearchQuery("");
                }}
              >
                browse
              </Button>{" "}
              vehicles to add to the category.
            </p>
          </div>
        )}
      </div>
      <Transition.Root
        show={searchQuery === null ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setSearchQuery(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-4xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-greyish p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for name"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery ?? ""}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string | null> };
                      }) => {
                        setSearchQuery(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setSearchQuery(null);
                    }}
                  >
                    <ArrowsUpDownIcon className="mr-2 h-5 w-5" />
                    Done
                  </Button>
                </div>
                <TableCursor
                  data={vehicles}
                  columns={columns}
                  loading={loading}
                  totalRows={vehicles.length || 0}
                  state={{
                    rowSelection,
                  }}
                  enableRowSelection={(row) =>
                    !excludeIds.includes(Number(row.original.id))
                  }
                  enableSubRowSelection={false}
                  onRowSelectionChange={setRowSelection}
                  getCoreRowModel={getCoreRowModel()}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
