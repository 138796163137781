import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  ExclamationCircleIcon,
  LockClosedIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../animations";
import logo from "../../../assets/logo.svg";
import { Head } from "../../../components/core";
import { Field, Response } from "../../../components/form/";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { useAuth } from "..";

const { REACT_APP_NAME } = process.env;

const FAILED_ATTEMPT_LIMIT = 10;

export function Login({ onSubmit }: { onSubmit?: (values: any) => void }) {
  const { t } = useTranslation();

  const { currentIdentifier } = useAuth();

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    <>
      <Head title="Login" heading="Login" />
      {/* {loading && <LayoutSplashScreen />} */}
      <header>
        {/* <div className="flex items-center justify-center">
          <button onClick={() => changeLanguage("en")}>US</button> |
          <button onClick={() => changeLanguage("fr")}>FR</button>
        </div> */}
        <img
          className="mx-auto h-9 w-auto sm:h-10 md:h-11 xl:h-12"
          src={logo}
          alt={REACT_APP_NAME}
        />
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
          {t("heading_login")}
        </h2>
      </header>
      {!currentIdentifier ? (
        <UserIdentifier onSubmit={onSubmit} />
      ) : (
        <UserLogin onSubmit={onSubmit} />
      )}
    </>
  );
}

const UserIdentifier = ({ onSubmit }: { onSubmit?: (values: any) => void }) => {
  const { t } = useTranslation();
  const attempt = useRef(0);
  const { setCurrentIdentifier } = useAuth();

  const [response, setResponse] = useState<FormResponse | null>(null);

  const VERIFY_USER = gql`
    query VerifyUser($email: String!) {
      verifyUser(email: $email) {
        fullName
      }
    }
  `;

  const [verifyUser] = useLazyQuery(VERIFY_USER);

  const IdentifierSchema = Yup.object().shape({
    identifier: Yup.string().required("Please enter your email"),
  });

  const handleSubmit = (
    values: { identifier: string },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    setResponse(null);

    if (attempt.current >= FAILED_ATTEMPT_LIMIT) {
      actions.setSubmitting(false);
      return;
    }

    onSubmit && onSubmit(values);

    verifyUser({
      variables: {
        email: values.identifier,
      },
    })
      .then(({ data, error }) => {
        actions.setSubmitting(false);
        if (data?.verifyUser) {
          setCurrentIdentifier(values.identifier);
        } else {
          attempt.current++;
          setResponse({
            type: "error",
            message: error
              ? error.message
              : "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        attempt.current++;
        setResponse({
          type: "error",
          message: error.message,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      identifier: "",
    },
    validationSchema: IdentifierSchema,
    onSubmit: handleSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form className="mt-6 space-y-4" onSubmit={formik.handleSubmit}>
      <Attempt count={attempt.current} />

      <div>
        <Field
          title={t("text_identifier")}
          type="text"
          name="identifier"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.identifier}
          touched={touched.identifier}
          errors={errors.identifier}
          autoCapitalize="sentences"
          autoComplete="username"
          autoCorrect="on"
          spellCheck="true"
          dir="auto"
        />
      </div>

      <div>
        <button
          type="submit"
          data-testid="next-submit"
          className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2.5 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          disabled={isSubmitting}
        >
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
              aria-hidden="true"
            />
          </span>

          {isSubmitting ? (
            <>
              <Spinner />
              Processing...
            </>
          ) : (
            "Next"
          )}
        </button>
      </div>

      <Response
        response={response}
        onDismiss={() => {
          setResponse(null);
        }}
        className="mt-5"
      />
    </form>
  );
};

const UserLogin = ({ onSubmit }: { onSubmit?: (values: any) => void }) => {
  const { t } = useTranslation();
  const attempt = useRef(0);
  const navigate = useNavigate();
  const [response, setResponse] = useState<FormResponse | null>(null);
  const { addNotify } = useNotifyContext();
  let location: any = useLocation();
  let from =
    location.state?.from && !location.state?.from.includes("error")
      ? location.state.from
      : "/";

  const {
    currentIdentifier,
    setCurrentIdentifier,
    setCurrentUser,
    setCurrentRole,
    setMfaEnabled,
    // setLocked,
    saveAccess,
    saveRefresh,
  } = useAuth();

  const LOGIN = gql`
    mutation Login($email: String!, $password: String!, $rememberMe: Boolean!) {
      login(
        input: {
          params: {
            email: $email
            password: $password
            rememberMe: $rememberMe
            platform: "web"
          }
        }
      ) {
        accessToken
        refreshToken
        user {
          id
          firstName
          lastName
          fullName
          userName
          email
          phoneNumber
          enableMfa
          roles {
            id
            roleName
            permissions {
              id
              permissionName
              resource {
                id
                resourceName
              }
            }
          }
        }
      }
    }
  `;

  const [login] = useMutation(LOGIN);

  const LoginSchema = Yup.object().shape({
    identifier: Yup.string().required("Please enter your email"),
    password: Yup.string().required("Please enter your password"),
    rememberMe: Yup.boolean(),
  });

  const handleSubmit = (
    values: {
      identifier: string | undefined;
      password: string;
      rememberMe: boolean;
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    setResponse(null);

    if (attempt.current >= FAILED_ATTEMPT_LIMIT) {
      actions.setSubmitting(false);
      return;
    }

    onSubmit && onSubmit(values);

    login({
      variables: {
        email: values.identifier,
        password: values.password,
        rememberMe: values.rememberMe,
      },
    })
      .then(({ data }) => {
        if (data?.login) {
          actions.setSubmitting(false);

          const { accessToken, refreshToken, user } = data.login;
          saveAccess(accessToken);
          saveRefresh(refreshToken);
          setCurrentUser(user);
          setCurrentRole(user.roles[0]);
          setMfaEnabled(user?.enableMfa ?? false);

          if (!user?.enableMfa) {
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Welcome to the Dashboard",
              message: "You are now logged in",
            });
          }

          return navigate(from, { replace: true });
        } else {
          actions.setSubmitting(false);
          attempt.current++;
          setResponse({
            type: "error",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        attempt.current++;
        setResponse({
          type: "error",
          message: error.message,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      identifier: currentIdentifier,
      password: "",
      rememberMe: false,
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form className="mt-6 space-y-4" onSubmit={formik.handleSubmit}>
      <Attempt count={attempt.current} />
      <div className="relative mt-1">
        <Field
          title={t("text_identifier")}
          type="text"
          name="identifier"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.identifier}
          touched={touched.identifier}
          errors={errors.identifier}
          autoCapitalize="sentences"
          autoComplete="username"
          autoCorrect="on"
          spellCheck="true"
          dir="auto"
          disabled
          readOnly
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 z-10 mt-5 flex items-center pl-3 pr-3 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
          title="Different user?"
          onClick={() => {
            setCurrentIdentifier(undefined);
          }}
        >
          <span
            className="bi bi-arrow-repeat h-5 w-5 text-center text-xl leading-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
      <div>
        <Field
          title={t("text_password")}
          type="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          touched={touched.password}
          errors={errors.password}
        />
      </div>

      <div className="flex items-center justify-between">
        <Field
          title={t("text_remember_me")}
          id="rememberMe"
          name="rememberMe"
          type="checkbox"
          aria-describedby="rememberMe-description"
          checked={formik.values.rememberMe}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <div className="text-sm">
          <Link
            to="/auth/forgot-password"
            className="rounded-sm font-medium text-primary-700 hover:text-primary-700 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
          >
            Forgot your password?
          </Link>
        </div>
      </div>

      <div>
        <button
          type="submit"
          data-testid="login-submit"
          className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2.5 text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          disabled={isSubmitting}
        >
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <LockClosedIcon
              className="h-5 w-5 text-primary-300 transition-colors group-hover:text-primary-500"
              aria-hidden="true"
            />
          </span>

          {isSubmitting ? (
            <>
              <Spinner />
              Processing...
            </>
          ) : (
            "Sign in"
          )}
        </button>
      </div>

      <Response
        response={response}
        onDismiss={() => {
          setResponse(null);
        }}
        className="mt-5"
      />
    </form>
  );
};

export function Attempt({ count }: { count: number }) {
  if (count <= 5) return null;
  if (count >= FAILED_ATTEMPT_LIMIT) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">
              You have exceeded the maximum failed attempts. Please try after 30
              minutes.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-yellow-800">
            You have {FAILED_ATTEMPT_LIMIT - count} failed login attempts
            remains until blocked.
          </p>
        </div>
      </div>
    </div>
  );
}
