import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useMemo } from "react";

import { VehicleThumb } from "../../../../components/appearance";
import { FieldDriver, FieldUser } from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  renderPriority,
  renderStatus,
  VehicleIssue,
  VehicleIssueStatus,
} from "../../../../graphql/fleets/vehicles/pages/issues";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import {
  classNames,
  formatDate,
  formatDateDays,
  formatTime,
} from "../../../../utils";

type IssuesDetailProps = {
  issue: VehicleIssue;
  vehicleBasic: VehicleBasic | null;
};
export function IssuesDetail({ issue, vehicleBasic }: IssuesDetailProps) {
  const timeToResolve = useMemo(() => {
    if (!issue) return null;
    const diff = formatDateDays(issue?.reportedDate, issue?.resolvedDate);
    console.log(diff);
    return diff;
  }, [issue]);

  const distanceToResolve = useMemo(() => {
    if (!issue || !issue?.resolvedMeterEntry || !issue?.primaryMeter)
      return null;
    const diff = issue?.resolvedMeterEntry - issue?.primaryMeter;
    console.log(diff);
    return diff;
  }, [issue]);

  return (
    <section className="space-y-4">
      <article>
        <h3 className="text-md mb-3 font-medium text-gray-900">Details</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="space-y-4 md:space-y-6 xl:space-y-8">
            {vehicleBasic ? (
              <li className="grid grid-cols-2 gap-4">
                <span>Vehicle:</span>
                <VehicleThumb {...vehicleBasic} />
              </li>
            ) : null}
            <li className="grid grid-cols-2 gap-4">
              <span>Assigned To:</span>
              <FieldUser title="User" value={issue.assignedToId} isPreview />
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Status:</span>
              <span className="text-gray-500">
                {renderStatus(issue.status)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Issue Type:</span>
              <span className="text-gray-500">{issue.issueType}</span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Summary:</span>
              <span className="text-gray-500">{issue.summary}</span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Description:</span>
              <span className="text-gray-500">{issue.description}</span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Priority:</span>
              <span className="text-gray-500">
                {renderPriority(issue?.priority)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Reported Date:</span>
              <span className="text-gray-500">
                {issue.reportedDate ? formatDate(issue.reportedDate) : "-"}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Reported By:</span>
              <div>
                <FieldDriver
                  title="Driver"
                  value={issue.reportedById}
                  isPreview
                />
              </div>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Odometer:</span>
              <span className="text-gray-500">
                {issue.primaryMeter}{" "}
                {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Overdue Date:</span>
              <span className="text-gray-500">
                {formatDate(issue.overDueDate)}
              </span>
            </li>
          </ul>
        </div>
      </article>
      {issue?.status === VehicleIssueStatus.RESOLVED ? (
        <article>
          <h3 className="text-md mb-3 font-medium text-gray-900">
            Resolution Details
          </h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="space-y-4 md:space-y-6 xl:space-y-8">
              <li className="grid grid-cols-2 gap-4">
                <span>Resolved Date:</span>
                <span className="text-gray-500">
                  {formatDate(issue.resolvedDate)}
                </span>
              </li>
              <li className="grid grid-cols-2 gap-4">
                <span>Note:</span>
                <span className="text-gray-500">{issue.statusNotes}</span>
              </li>
              {issue.resolvedMeterEntry ? (
                <li className="grid grid-cols-2 gap-4">
                  <span>Resolved Meter Entry:</span>
                  <span className="text-gray-500">
                    {issue.resolvedMeterEntry}{" "}
                    {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                  </span>
                </li>
              ) : null}
              <li className="grid grid-cols-2 gap-4">
                <span>Time to resolve:</span>
                <span className="text-gray-500">{timeToResolve}</span>
              </li>
              {issue.resolvedMeterEntry ? (
                <li className="grid grid-cols-2 gap-4">
                  <span>
                    {renderPrimaryMeter(vehicleBasic?.primaryMeter)} to Resolve:
                  </span>
                  <span className="text-gray-500">
                    {distanceToResolve}{" "}
                    {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                  </span>
                </li>
              ) : null}
            </ul>
          </div>
        </article>
      ) : null}
      <article>
        <h3 className="text-md mb-3 font-medium text-gray-900">Timeline</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="space-y-6 font-light">
            {issue.timelines.map((timeline, index) => (
              <li key={timeline.id} className="relative flex gap-x-4">
                <div
                  className={classNames(
                    index === issue.timelines.length - 1 ? "h-6" : "-bottom-6",
                    "absolute left-0 top-0 flex w-6 justify-center"
                  )}
                >
                  <div className="w-px bg-gray-200" />
                </div>

                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  {index === 0 ? (
                    <CheckCircleIcon
                      className="h-6 w-6 text-primary-600"
                      aria-hidden="true"
                    />
                  ) : (
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  )}
                </div>
                <div
                  className="flex-auto py-0.5 leading-5 text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: timeline.info,
                  }}
                />
                <time
                  dateTime={timeline.updatedAt}
                  className="flex-none py-0.5 leading-5 text-gray-500"
                >
                  {formatTime(timeline.updatedAt)}
                </time>
              </li>
            ))}
          </ul>
        </div>
      </article>
    </section>
  );
}
