import { gql } from "@apollo/client";

export const VEHICLEMODEL_FRAGMENT = gql`
  fragment VehicleModelFragment on VehicleModel {
    id
    name
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLEMODELS = gql`
  ${VEHICLEMODEL_FRAGMENT}
  query FetchVehicleModels {
    fetchVehicleModels {
      ...VehicleModelFragment
    }
  }
`;

// export const GET_VEHICLEMODEL_BY_ID = gql`
//   ${VEHICLEMODEL_FRAGMENT}
//   query FetchVehicleModel($id: ID!) {
//     fetchVehicleModel(id: $id) {
//       ...VehicleModelFragment
//     }
//   }
// `;

// export const REMOVE_VEHICLEMODEL = gql`
//   ${VEHICLEMODEL_FRAGMENT}
//   mutation VehicleModelDelete($id: ID!) {
//     vehicleModelDelete(input: { id: $id }) {
//       vehicleModel {
//         ...VehicleModelFragment
//       }
//     }
//   }
// `;

export const ADD_VEHICLEMODEL = gql`
  ${VEHICLEMODEL_FRAGMENT}
  mutation VehicleModelCreate($name: String!) {
    vehicleModelCreate(input: { params: { name: $name } }) {
      vehicleModel {
        ...VehicleModelFragment
      }
    }
  }
`;

// export const EDIT_VEHICLEMODEL = gql`
//   ${VEHICLEMODEL_FRAGMENT}
//   mutation VehicleModelUpdate($id: ID!, $name: String!) {
//     vehicleModelUpdate(input: { id: $id, params: { name: $name } }) {
//       vehicleModel {
//         ...VehicleModelFragment
//       }
//     }
//   }
// `;

export type VehicleModel = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};
