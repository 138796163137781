import { useMutation, useQuery } from "@apollo/client/react";
import { RadioGroup } from "@headlessui/react";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import {
  Button,
  Field,
  FieldAddress,
  FieldState,
  Response,
} from "../../../components/form";
import {
  CREATE_GENERAL_SETTING,
  FETCH_GENERAL_SETTINGS,
  General,
  UPDATE_GENERAL_SETTING,
} from "../../../graphql/settings/general";
import { classNames } from "../../../utils";

const GeneralSchema = Yup.object().shape({
  senderEmail: Yup.string().email("Invalid email address").required(),
  address: Yup.string().nullable(),
  suburb: Yup.string().nullable(),
  state: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  zonePicking: Yup.number(),
});

const GeneralSettings = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<FormResponse | null>(null);

  const { data, loading, error, refetch } = useQuery(FETCH_GENERAL_SETTINGS);
  const general = useMemo<General>(() => data?.fetchGeneralSettings, [data]);

  const [generalSettingCreate, { loading: loadingCreate }] = useMutation(
    CREATE_GENERAL_SETTING,
    {
      refetchQueries: [{ query: FETCH_GENERAL_SETTINGS }],
      awaitRefetchQueries: true,
    }
  );
  const [generalSettingUpdate, { loading: loadingUpdate }] = useMutation(
    UPDATE_GENERAL_SETTING,
    {
      refetchQueries: [{ query: FETCH_GENERAL_SETTINGS }],
      awaitRefetchQueries: true,
    }
  );

  const formik = useFormik({
    initialValues: {
      id: general?.id ?? null,
      senderEmail: general?.senderEmail ?? "",
      address: general?.address ?? "",
      suburb: general?.suburb ?? "",
      state: general?.state ?? "",
      postcode: general?.postcode ?? "",
      zonePicking: general?.zonePicking ?? 0,
    },
    enableReinitialize: true,
    validationSchema: GeneralSchema,
    onSubmit: (values, actions) => {
      if (!values.id) {
        generalSettingCreate({
          variables: {
            senderEmail: values.senderEmail,
            address: values.address,
            suburb: values.suburb,
            state: values.state,
            postcode: values.postcode,
            zonePicking: values.zonePicking,
          },
        })
          .then(({ data }) => {
            setResponse(null);
            if (data?.generalSettingCreate?.general) {
              refetch();
              setResponse({
                type: "success",
                message: "General settings created successfully",
              });
            } else {
              setResponse({
                type: "error",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            actions.setSubmitting(false);
            setResponse({
              type: "error",
              message: error.message,
            });
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
        return;
      }

      generalSettingUpdate({
        variables: {
          id: values.id,
          senderEmail: values.senderEmail,
          address: values.address,
          suburb: values.suburb,
          state: values.state,
          postcode: values.postcode,
          zonePicking: values.zonePicking,
        },
      })
        .then(({ data }) => {
          setResponse(null);

          if (data?.generalSettingUpdate?.general) {
            refetch();
            setResponse({
              type: "success",
              message: "General settings updated successfully",
            });
          } else {
            setResponse({
              type: "error",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  if (error) return <ErrorFallback error={error} />;

  const zonePickingTypes = [
    {
      label: "Manual",
      value: 0,
      description: "Products must be manually added to zone picking.",
    },
    {
      label: "Automated",
      value: 1,
      description:
        "Products will be automatically added to zone picking based on their department.",
    },
  ];

  return (
    <>
      <Head
        title="General Settings"
        heading="Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "General Settings",
            href: "/settings/general",
          },
        ]}
      />
      <div className="space-y-6 sm:px-6 lg:px-0">
        {loading ? (
          <Waiting />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="shadow-sm sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    General Settings
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Configure general settings.
                  </p>
                </div>

                <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
                  <div className="col-span-12 sm:col-span-6 md:col-span-3">
                    <Field
                      title={t("text_sender_email")}
                      autoComplete="email"
                      type="text"
                      name="senderEmail"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.senderEmail}
                      touched={formik.touched.senderEmail}
                      errors={formik.errors.senderEmail}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-3">
                    <FieldAddress
                      title={t("text_address")}
                      name="address"
                      type="street"
                      onChange={(value) => {
                        const { street, state, suburb, postcode } = value;

                        formik.setValues({
                          ...formik.values,
                          address: street,
                          suburb: suburb || formik.values.suburb,
                          state: state || formik.values.state,
                          postcode: postcode || formik.values.postcode,
                        });
                      }}
                      value={formik.values.address}
                      touched={formik.touched.address}
                      errors={formik.errors.address}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-3">
                    <Field
                      title={t("text_suburb")}
                      name="suburb"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.suburb}
                      touched={formik.touched.suburb}
                      errors={formik.errors.suburb}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-3">
                    <FieldState
                      title={t("text_state")}
                      name="state"
                      onChange={(value) => {
                        formik.setFieldValue("state", value);
                      }}
                      value={formik.values.state}
                      touched={formik.touched.state}
                      errors={formik.errors.state}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-3">
                    <FieldAddress
                      title={t("text_postcode")}
                      name="postcode"
                      type="postcode"
                      onChange={(value) => {
                        const { street, state, suburb, postcode } = value;

                        formik.setValues({
                          ...formik.values,
                          address: street || formik.values.address,
                          suburb: suburb || formik.values.suburb,
                          state: state || formik.values.state,
                          postcode: postcode,
                        });
                      }}
                      value={formik.values.postcode}
                      touched={formik.touched.postcode}
                      errors={formik.errors.postcode}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6 md:col-span-6">
                    <RadioGroup
                      value={formik.values.zonePicking}
                      onChange={(value: number) => {
                        formik.setFieldValue("zonePicking", value);
                      }}
                    >
                      <RadioGroup.Label className="text-sm font-medium text-gray-900">
                        Zone Picking Mode
                      </RadioGroup.Label>

                      <div className="isolate mt-1 -space-y-px rounded-md bg-white">
                        {zonePickingTypes.map((type, typeIdx) => (
                          <RadioGroup.Option
                            key={type.label}
                            value={type.value}
                            className={({ checked }) =>
                              classNames(
                                typeIdx === 0
                                  ? "rounded-tl-md rounded-tr-md"
                                  : "",
                                typeIdx === zonePickingTypes.length - 1
                                  ? "rounded-bl-md rounded-br-md"
                                  : "",
                                checked
                                  ? "z-10 border-primary-200 bg-primary-50"
                                  : "border-gray-200",
                                "relative flex cursor-pointer border p-4 focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    checked
                                      ? "border-transparent bg-primary-600"
                                      : "border-gray-300 bg-white",
                                    active
                                      ? "ring-2 ring-primary-500 ring-offset-2"
                                      : "",
                                    "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                </span>
                                <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-primary-900"
                                        : "text-gray-900",
                                      "block text-sm font-medium"
                                    )}
                                  >
                                    {type.label}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-primary-700"
                                        : "text-gray-500",
                                      "block text-sm"
                                    )}
                                  >
                                    {type.description}
                                  </RadioGroup.Description>
                                </span>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </div>

                <Response
                  response={response}
                  onDismiss={() => {
                    setResponse(null);
                  }}
                  className="mt-5"
                />
              </div>
              <div className="border-t border-gray-200 bg-white px-4 py-4 text-right md:px-6 md:py-6">
                <Button
                  variant="primary"
                  type="submit"
                  className="justify-center md:w-36"
                  loading={loadingCreate || loadingUpdate}
                  disabled={
                    formik.isSubmitting || loadingCreate || loadingUpdate
                  }
                >
                  {formik.isSubmitting
                    ? t("text_processing")
                    : formik.values.id
                    ? t("text_update")
                    : t("text_create")}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default GeneralSettings;
export const GeneralResource: ResourceProps = {
  name: "General",
  description: "General page",
  access: ["read-settings"],
  path: "general",
  icon: "bi bi-person",
};
