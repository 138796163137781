import { useTranslation } from "react-i18next";

import { Head } from "../../../../components/core";
import FormUpdate from "./components/FormUpdate";

const PricingCategoryUpdate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={PricingCategoryUpdateResource.name}
        heading="Pricing Categories"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Pricing Categories",
            href: "/inventory/pricing/pricing-categories",
          },
          {
            name: PricingCategoryUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {PricingCategoryUpdateResource.name}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {PricingCategoryUpdateResource.description}
              </p>
            </div>
          </div>

          <FormUpdate actionLabel={t("text_update")} />
        </div>
      </div>
    </>
  );
};

export default PricingCategoryUpdate;
export const PricingCategoryUpdateResource: ResourceProps = {
  name: "Edit Pricing Category",
  description: "Update an existing pricing category",
  access: ["update-pricings"],
  path: "pricing-categories/:pricingCategoryId",
};
