import { useMutation, useQuery } from "@apollo/client/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { GET_WAREHOUSE_PICKERS } from "../../../graphql/user-management/assign-zone-pickers";
import {
  GET_USERS,
  REMOVE_USER,
  renderStatus,
  User,
} from "../../../graphql/user-management/users";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { formatDate } from "../../../utils";
import { isAdmin } from "../../auth";
import { AuthShield } from "../../auth/core";

// type Role = {
//   roleName: string;
// };
// type User = {
//   id: string;
//   fullName: string;
//   email: string;
//   roles: Role[];
//   createdAt: string;
//   status: boolean;
// };

const UserList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();

  const { data, loading, error, refetch } = useQuery<{
    fetchUsers: User[];
  }>(GET_USERS);
  const users = useMemo(
    () =>
      data?.fetchUsers.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data?.fetchUsers]
  );

  const [deleteUser] = useMutation(REMOVE_USER, {
    refetchQueries: [{ query: GET_USERS }, { query: GET_WAREHOUSE_PICKERS }],
  });
  const handleDelete = async (id: string) => {
    return await deleteUser({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.userDelete) {
          const deletedUser = data?.userDelete?.user;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `User ${deletedUser.fullName} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "User delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "User delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<User, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "fullName",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.fullName,
      },
      {
        accessorKey: "email",
        header: "Email",
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.email,
      },
      {
        accessorKey: "roles",
        header: "Role",
        cell: (props) =>
          props.row.original.roles.map((r) => r.roleName).join(", "),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (props) => renderStatus(props.getValue()),
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) =>
          props.row.original.roles.find((r) => !isAdmin(r)) ? (
            <div className="flex space-x-2 md:space-x-4">
              <AuthShield access={["update-users"]}>
                <Button
                  type="link"
                  href={`/user-management/users/${props.row.original.id}`}
                  variant="icon"
                  className="text-blue-500 hover:text-blue-600"
                >
                  <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                  <span className="sr-only">
                    Edit, {props.row.original.fullName}
                  </span>
                </Button>
              </AuthShield>
              <AuthShield access={["destroy-users"]}>
                <Button
                  variant="icon"
                  className="text-red-500 hover:text-red-600"
                  onClick={() => {
                    setAlert({
                      id: props.row.original.id,
                      name: props.row.original.fullName,
                    });
                  }}
                >
                  <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                  <span className="sr-only">
                    Delete, {props.row.original.fullName}
                  </span>
                </Button>
              </AuthShield>
            </div>
          ) : null,
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={UserListResource.name}
        heading={UserListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Users",
            href: "/user-management/users",
          },
          {
            name: UserListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {UserListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {UserListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-users"]}>
              <Button type="link" href="/user-management/users/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add user
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={users}
          columns={columns}
          loading={loading}
          totalRows={users.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No users found"
          fallbackText="You can add a new user by clicking on add user button."
        />
      </div>
    </>
  );
};

export default UserList;
export const UserListResource: ResourceProps = {
  name: "Users",
  description: "A list of users",
  access: ["read-users"],
  path: "users",
};
