import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import {
  EDIT_EXPENSEENTRY,
  GET_EXPENSEENTRIES,
  GET_EXPENSEENTRY_BY_ID,
  VehicleExpenseEntry,
  VehicleExpenseEntryForm,
} from "../../../../../graphql/fleets/vehicles/pages/expense-entry";
import Form from "../../components/ExpenseEntryForm";

const ExpenseEntryUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  let { expenseEntryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchExpenseEntry: VehicleExpenseEntry;
  }>(GET_EXPENSEENTRY_BY_ID, { variables: { id: expenseEntryId } });

  const expenseEntry = useMemo<VehicleExpenseEntry | undefined>(
    () => data?.fetchExpenseEntry,
    [data]
  );

  const [updateVehicleExpenseEntry] = useMutation(EDIT_EXPENSEENTRY, {
    refetchQueries: [{ query: GET_EXPENSEENTRIES }],
    awaitRefetchQueries: true,
  });
  const handleUpdate = (
    values: VehicleExpenseEntryForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!expenseEntryId) return;
    updateVehicleExpenseEntry({
      variables: {
        id: expenseEntryId,
        vehicleId: values.vehicleId,
        vehicleExpenseTypeId: values.vehicleExpenseTypeId,
        fleetVendorId: values.fleetVendorId,
        amount: values.amount,
        expenseDate: values.expenseDate,
        notes: values.notes,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleExpenseEntryUpdate) {
          toast.success(`Vehicle expense entry updated successfully`);
          return navigate("/fleets/vehicles/expense-entries");
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={ExpenseEntryUpdateResource.name}
        heading={ExpenseEntryUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ExpenseEntryUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_expense_entry_update")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_expense_entry_update")}
              </p>
            </div>
          </div>

          {loading || !expenseEntry ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                vehicleId: expenseEntry.vehicleId ?? null,
                vehicleExpenseTypeId:
                  expenseEntry?.vehicleExpenseTypeId ?? null,
                fleetVendorId: expenseEntry?.fleetVendorId ?? null,
                amount: expenseEntry?.amount ?? null,
                expenseDate: expenseEntry?.expenseDate ?? "",
                notes: expenseEntry?.notes ?? "",
              }}
              onSubmit={handleUpdate}
              submitLabel={t("text_update")}
              onCancel={() => {
                navigate(`/fleets/vehicles/expense-entries/${expenseEntryId}`);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ExpenseEntryUpdate;
export const ExpenseEntryUpdateResource: ResourceProps = {
  name: "Edit Expense Entry",
  description: "Update an existing expense entry",
  access: ["update-vehicles"],
  path: "/expense-entries/:expenseEntryId/edit",
};
