import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  GET_CUSTOMERGROUPS,
  UPDATE_CUSTOMERGROUP,
} from "../../../../graphql/sales/customer-groups";
import { PreviewCustomer } from "../../../../graphql/sales/customers";
import Form from "./components/Form";

type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: PreviewCustomer[];
  status: boolean;
};

const FETCH_CUSTOMERGROUP = gql`
  query FetchCustomerGroup($id: ID!) {
    fetchCustomerGroup(id: $id) {
      id
      name
      description
      customers {
        id
        customerName
        profileImageUrl
        status
      }
      status
    }
  }
`;

const CustomerGroupUpdate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  let { customerGroupId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchCustomerGroup: CustomerGroup;
  }>(FETCH_CUSTOMERGROUP, {
    variables: {
      id: customerGroupId,
    },
  });

  const customerGroup = useMemo<CustomerGroup | undefined>(
    () => data?.fetchCustomerGroup,
    [data?.fetchCustomerGroup]
  );

  const [updateCustomerGroup] = useMutation(UPDATE_CUSTOMERGROUP, {
    refetchQueries: [
      {
        query: GET_CUSTOMERGROUPS,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateCustomerGroup({
      variables: {
        id: customerGroup?.id,
        name: values.name,
        description: values.description,
        customerIds: values.customers ? values.customers : [],
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerGroupUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer Group updated successfully",
          });
          return navigate(`/sales/settings/customer-groups`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer Group update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer Group update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={CustomerGroupUpdateResource.name}
        heading="Customer Groups"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customer Groups",
            href: "/sales/settings/customer-groups",
          },
          {
            name: CustomerGroupUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_customer_group")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_customer_group")}
              </p>
            </div>
          </div>

          {loading || !customerGroup ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                name: customerGroup.name ?? "",
                description: customerGroup.description ?? "",
                customers: customerGroup.customers
                  ? customerGroup.customers.map((p) => parseInt(p.id))
                  : [],
                status: customerGroup.status ? true : false,
              }}
              initialCustomers={customerGroup.customers}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerGroupUpdate;
export const CustomerGroupUpdateResource: ResourceProps = {
  name: "Edit Customer Group",
  description: "Update an existing customer group",
  access: ["update-customergroups"],
  path: "customer-groups/:customerGroupId",
};
