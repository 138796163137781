import placeholder from "../../assets/placeholder.svg";
import { classNames } from "../../utils";

interface ImageStatusProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  title: string;
  url: string | null;
  color: string;
}
export function ImageStatus({
  title,
  url,
  color,
  className = "",
  ...props
}: ImageStatusProps) {
  return (
    <span
      className={classNames(
        "relative mr-3 block h-12 w-12 cursor-pointer",
        className
      )}
      {...props}
    >
      <span className="w-ful block h-full appearance-none overflow-hidden rounded-md ring-2 ring-white filter transition hover:hue-rotate-15">
        <img
          className={classNames("h-full w-full object-cover")}
          src={url ? url : placeholder}
          alt={title}
        />
      </span>
      <span
        className={classNames(
          "absolute -bottom-0.5 -right-0.5 inline-block h-2.5 w-2.5 rounded-full"
        )}
        style={{
          backgroundColor: color,
        }}
      />
    </span>
  );
}
