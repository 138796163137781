import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldPlaces,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { classNames } from "../../../../utils";

type Vendor = {
  id?: number;
  companyName: string;
  email: string;
  phoneNumber: string;
  vendorCode: string;
  webAddress: string;
  vendorType: SingleValue<OptionProps>;
  address: string;
  comments: string;
  status: boolean;
};

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: Vendor;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();
  const { vendorId } = useParams<{ vendorId: string }>();

  const VendorSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .nullable(),
    vendorCode: Yup.string().nullable(),
    webAddress: Yup.string().nullable(),
    vendorType: Yup.object().nullable().required("Required"),
    address: Yup.string().nullable(),
    comments: Yup.string().nullable(),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: VendorSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_company_name")}
            name="companyName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            touched={touched.companyName}
            errors={errors.companyName}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_email")}
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            touched={touched.email}
            errors={errors.email}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_phone")}
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            touched={touched.phoneNumber}
            errors={errors.phoneNumber}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_website")}
            name="webAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.webAddress}
            touched={touched.webAddress}
            errors={errors.webAddress}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <Field
            title={t("text_comments")}
            name="comments"
            type="textarea"
            className="h-24"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comments}
            touched={touched.comments}
            errors={errors.comments}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <FieldPlaces
            title={t("text_address")}
            name="address"
            onUpdate={(value) => {
              formik.setFieldValue("address", value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            touched={touched.address}
            errors={errors.address}
          />
        </div>
        {typeof vendorId !== "undefined" ? (
          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <Field
              title={t("text_vendor_code")}
              name="vendorCode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vendorCode}
              touched={touched.vendorCode}
              errors={errors.vendorCode}
              disabled
            />
          </div>
        ) : null}
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <label className="block text-sm font-medium text-gray-900">
              {t("text_vendor_type")}
            </label>
            <FieldVendorType
              value={formik.values.vendorType}
              onChange={(value: SingleValue<OptionProps>) => {
                formik.setFieldValue("vendorType", value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.vendorType && formik.errors.vendorType
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.vendorType && formik.errors.vendorType ? (
              <p className="mt-2 text-sm text-red-600" id="roles-errors">
                {formik.errors.vendorType.toString()}
              </p>
            ) : null}
          </fieldset>
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              {t("text_status")}
            </legend>
            <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
              <Switch
                checked={formik.values.status}
                onChange={() => {
                  formik.setFieldValue("status", !formik.values.status);
                }}
                id="status"
                className={classNames(
                  formik.values.status ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formik.values.status ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor="status"
                className="mb-0 ml-2 block text-sm font-normal text-gray-700"
              >
                {formik.values.status ? t("text_active") : t("text_inactive")}
              </Switch.Label>
            </Switch.Group>
          </fieldset>
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/purchases/vendors" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}

export function FieldVendorType({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const options = useMemo(
    () => [
      { label: "Company", value: "1" },
      { label: "Individual", value: "0" },
    ],
    []
  );

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}
