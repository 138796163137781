import { useQuery } from "@apollo/client";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";

import { GET_USERS, User } from "../../graphql/user-management/users";
import { Avatar, ButtonPopover, PopoverPosition } from "../appearance";
import { selectStyles, SelectWrapper } from ".";

type FieldUserProps = {
  title: string;
  value: number | null;
  onChange?: (newValue: number | null, newObject: User | null) => void;
  excludeIds?: number[];
  className?: string;
  isPreview?: boolean;
  position?: PopoverPosition;
};
export function FieldUser({
  title,
  value: initialValue,
  onChange,
  excludeIds = [],
  className = "",
  isPreview = false,
  position = PopoverPosition.RIGHT,
}: FieldUserProps) {
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery<{
    fetchUsers: User[];
  }>(GET_USERS);

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchUsers) {
      return data.fetchUsers.map((p: User) => {
        return {
          label: p.fullName,
          value: p.id,
        };
      });
    }
    return [];
  }, [data?.fetchUsers]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === initialValue.toString()) ??
        null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [options, initialValue]);

  const onChangeHandler = useCallback(
    (newValue: SingleValue<OptionProps>) => {
      if (typeof onChange !== "function") return;
      if (!newValue) return onChange(null, null);
      const selectedUser =
        data?.fetchUsers?.find((p: User) => p.id === newValue.value) ?? null;
      onChange(Number(newValue.value), selectedUser);
    },
    [data?.fetchUsers, onChange]
  );

  if (isPreview) {
    if (value) {
      const user = data?.fetchUsers?.find((p: User) => p.id === value?.value);
      if (user) {
        return (
          <ButtonPopover
            title={user.fullName}
            child={
              <div className="flex items-center">
                <Avatar firstName={user.firstName} lastName={user.lastName} />
                <span className="ml-2">{user.fullName}</span>
              </div>
            }
            position={position}
          >
            <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
              <h3 className="mb-1 font-medium">{user.fullName}</h3>
              <p>{user.email}</p>
              <p>{user.phoneNumber}</p>
            </div>
            <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
              <Link
                to={`/user-management/users/${user.id}`}
                target="_blank"
                className="block text-center text-sm text-gray-500"
              >
                View {title}
              </Link>
            </div>
          </ButtonPopover>
        );
      }
    }
    return <Fragment>-</Fragment>;
  }

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={value}
        options={options.filter(
          (option) => !excludeIds.includes(parseInt(option.value))
        )}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
