import { useMutation, useQuery } from "@apollo/client/react";
import {
  ArrowPathIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import {
  ButtonDots,
  itemClasses,
  Watchers,
} from "../../../../components/appearance";
import { ErrorFallback } from "../../../../components/core";
import { Button } from "../../../../components/form";
import { LimitBy, TableCursor } from "../../../../components/table";
import {
  GET_SERVICEREMAINDER_BY_VEHICLE_ID,
  REMOVE_SERVICEREMAINDER,
  renderStatus,
  VehicleServiceReminder,
} from "../../../../graphql/fleets/vehicles/pages/service-reminder";
import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import { classNames, formatDate } from "../../../../utils";
import { AuthShield } from "../../../auth/core";

export function ServiceReminders({
  vehicleId,
}: {
  vehicleId: string | undefined;
}) {
  const { t } = useTranslation();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);

  const { data, loading, error, refetch } = useQuery<{
    fetchServiceRemaindersByVehicle: VehicleServiceReminder[] | null;
  }>(GET_SERVICEREMAINDER_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });
  const serviceReminders = useMemo<VehicleServiceReminder[]>(() => {
    const updatedServiceRemainder = data?.fetchServiceRemaindersByVehicle
      ?.length
      ? data?.fetchServiceRemaindersByVehicle.map((item) => {
          return {
            ...item,
            lastCompletionDate: formatDate(item.lastCompletionDate),
            nextDueDate: formatDate(item.nextDueDate),
            createdAt: formatDate(item.createdAt),
          };
        })
      : [];
    return updatedServiceRemainder;
  }, [data]);

  const [deleteVehicleServiceReminder] = useMutation(REMOVE_SERVICEREMAINDER);
  const handleDelete = async (id: string) => {
    deleteVehicleServiceReminder({
      variables: {
        id: Number(id),
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleServiceRemainderDelete) {
          await refetch();
          toast.success(`Vehicle service reminder deleted successfully`);
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<VehicleServiceReminder, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "vehicleServiceTask.name",
        header: "Task Name",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (props) => renderStatus(props.row.original.status),
      },
      {
        accessorKey: "nextDueDate",
        header: "Next Due",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "lastCompletionDate",
        header: "Last Completed",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "watchers",
        header: "Watchers",
        cell: (props) => (
          <Watchers
            caption={`Watchers of ${props.row.original.vehicle.name} service reminder - ${props.row.original.vehicleServiceTask.name}`}
            data={props.row.original.watchers}
          />
        ),
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <ButtonDots>
            <AuthShield access={["read-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/${vehicleId}/service-reminder/${props.row.original.id}`}
              >
                <div className="flex items-center">
                  <EyeIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    View
                    <span className="sr-only">
                      , {props.row.original.vehicleServiceTask.name}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["update-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                type="link"
                href={`/fleets/vehicles/${vehicleId}/service-reminder/${props.row.original.id}/edit`}
              >
                <div className="flex items-center">
                  <PencilIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Edit
                    <span className="sr-only">
                      , {props.row.original.vehicleServiceTask.name}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-vehicles"]}>
              <Button
                variant="text"
                className={itemClasses}
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.vehicleServiceTask.name,
                  });
                }}
              >
                <div className="flex items-center">
                  <TrashIcon
                    aria-hidden="true"
                    className="mr-2 h-5 w-5 text-current"
                  />
                  <span className="block truncate">
                    Delete
                    <span className="sr-only">
                      , {props.row.original.vehicleServiceTask.name}
                    </span>
                  </span>
                </div>
              </Button>
            </AuthShield>
          </ButtonDots>
        ),
      },
    ],
    [vehicleId]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;
  return (
    <Fragment>
      <ResponseAlert />
      <div className="relative">
        <TableCursor
          data={serviceReminders}
          columns={columns}
          loading={loading}
          totalRows={serviceReminders.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          enableRowSelection={false}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No service reminders found"
          fallbackText="You can add a new service reminder by clicking on add service reminder button."
          enableAction
          renderAction={() => (
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="absolute right-0 top-1 flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh service reminders list</span>
            </Button>
          )}
        />
      </div>
    </Fragment>
  );
}
