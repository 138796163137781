import { Column, Table } from "@tanstack/react-table";
import { useEffect, useRef } from "react";

import { classNames } from "../../utils";

export * from "./TableCursor";
export * from "./TableCursorInfinite";
export * from "./TableStaticInfinite";

export enum LimitBy {
  L1 = 10,
  L2 = 20,
  L3 = 30,
  L4 = 50,
}

export function Filter<T>({
  column,
  table,
}: {
  column: Column<T, any>;
  table: Table<T>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  switch (column.id) {
    case "createdAt":
      return (
        <input
          type="date"
          value={(column.getFilterValue() ?? "") as string}
          onChange={(e) => column.setFilterValue(e.target.value)}
          placeholder={`Search for ${column.columnDef.header}`}
          className="w-full rounded-lg border-gray-200 text-sm font-normal"
        />
      );
  }

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={((column.getFilterValue() as any)?.[0] ?? "") as string}
        onChange={(e) =>
          column.setFilterValue((old: any) => [e.target.value, old?.[1]])
        }
        placeholder={`Min ${column.columnDef.header}`}
        className="w-24 rounded-lg border-gray-200"
      />
      <input
        type="number"
        value={((column.getFilterValue() as any)?.[1] ?? "") as string}
        onChange={(e) =>
          column.setFilterValue((old: any) => [old?.[0], e.target.value])
        }
        placeholder={`Max ${column.columnDef.header}`}
        className="w-24 rounded-lg border-gray-200"
      />
    </div>
  ) : (
    <input
      type="text"
      value={(column.getFilterValue() ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search for ${column.columnDef.header}`}
      className="w-full rounded-lg border-gray-200 text-sm font-normal"
    />
  );
}

export function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={classNames(
        "relative h-4 w-4 rounded border border-gray-400 text-primary-600 shadow-sm sm:text-sm",
        "cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2",
        "disabled:cursor-not-allowed disabled:border-gray-200 disabled:ring-primary-50",
        "checked:border-primary-500 checked:ring-primary-500",
        className ?? ""
      )}
      {...rest}
    />
  );
}
