import { useMutation, useQuery } from "@apollo/client/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useCallback, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  Button,
  FieldInsertProducts,
  FieldPriceLevelCalculator,
} from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import { EDIT_PRICINGLEVEL } from "../../../../graphql/inventory/pricing/pricing-levels";
import {
  CREATE_PRODUCTPRICING,
  DELETE_PRODUCTPRICING,
  FETCH_PRICINGLEVEL_DETAILS,
  type PricingLevel,
  type ProductPricing,
  ProductPricingStatus,
  UPDATE_PRODUCTPRICING,
} from "../../../../graphql/inventory/pricing/pricing-levels/pricing";
import { PreviewProduct } from "../../../../graphql/inventory/products";
import { classNames } from "../../../../utils";

const PricingLevelPricing = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { pricingLevelId } = useParams();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { data, loading, error, refetch } = useQuery(
    FETCH_PRICINGLEVEL_DETAILS,
    {
      variables: {
        id: pricingLevelId,
        pricingLevelId: Number(pricingLevelId),
      },
    }
  );

  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);

  const [deleteProductPricing] = useMutation(DELETE_PRODUCTPRICING, {
    refetchQueries: [
      {
        query: FETCH_PRICINGLEVEL_DETAILS,
        variables: {
          id: pricingLevelId,
          specialPriceId: Number(pricingLevelId),
        },
      },
    ],
  });

  const [updatePricingLevel] = useMutation(EDIT_PRICINGLEVEL, {
    refetchQueries: [
      {
        query: FETCH_PRICINGLEVEL_DETAILS,
        variables: {
          id: pricingLevelId,
          specialPriceId: Number(pricingLevelId),
        },
      },
    ],
  });

  const [createProductPricing, { loading: loadingCreate }] = useMutation(
    CREATE_PRODUCTPRICING
  );
  const [updateProductPricing, { loading: loadingUpdate }] = useMutation(
    UPDATE_PRODUCTPRICING
  );

  const pricingLevel = useMemo<PricingLevel | undefined>(() => {
    if (data?.fetchPricingLevel) {
      const fetchedPricingLevel: PricingLevel = data.fetchPricingLevel;
      if (globalFilter === "") return fetchedPricingLevel;
      const updatedPricingLevel = {
        ...fetchedPricingLevel,
        products: fetchedPricingLevel.products.filter((product) => {
          const isMatchName = product.name
            .toLowerCase()
            .includes(globalFilter.toLowerCase());
          const isMatchSku = product.variants.some((variant) =>
            variant.stockCode.toLowerCase().includes(globalFilter.toLowerCase())
          );
          return isMatchName || isMatchSku;
        }),
      };
      return updatedPricingLevel;
    }
  }, [data, globalFilter]);

  const handleSave = useCallback(async () => {
    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const {
        pricingId,
        pricingLevelId,
        productId,
        productSkuId,
        priceFields,
        sellPrice,
      } = product;

      if (pricingId) {
        await updateProductPricing({
          variables: {
            id: pricingId,
            pricingLevelId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.productPricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createProductPricing({
          variables: {
            pricingLevelId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.productPricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    setProductPricing(newProductPricing);
    refetch();
  }, [createProductPricing, productPricing, refetch, updateProductPricing]);

  const handleCancel = () => {
    return navigate(`/inventory/pricing/pricing-levels/${pricingLevelId}`);
  };

  const handleRemoveProduct = useCallback(
    (productId: string) => {
      if (!pricingLevelId || !pricingLevel) return;

      deleteProductPricing({
        variables: {
          productId: parseInt(productId),
          pricingLevelId: parseInt(pricingLevelId),
        },
      })
        .then(({ data }) => {
          if (data?.productPricingDelete) {
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Product removed successfully",
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Prodcut remove failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcut remove failed",
            message: error.message,
          });
        });
    },
    [addNotify, deleteProductPricing, pricingLevel, pricingLevelId, refetch]
  );

  const handleAddProduct = useCallback(
    (products: PreviewProduct[]) => {
      if (!pricingLevel) return;

      updatePricingLevel({
        variables: {
          id: pricingLevel.id,
          name: pricingLevel.name,
          pricingMethodId: parseInt(pricingLevel.pricingMethod.id),
          productIds: products.map((item) => parseInt(item.id)),
          status: pricingLevel.status,
        },
      })
        .then(({ data }) => {
          if (data?.pricingLevelUpdate) {
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Products updated successfully",
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Prodcuts update failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcuts update failed",
            message: error.message,
          });
        });
    },
    [addNotify, pricingLevel, refetch, updatePricingLevel]
  );

  const handleRefetch = useCallback(() => {
    window.location.reload();
  }, []);

  const isOutdated = useMemo<boolean>(() => {
    return (
      pricingLevel?.products.some((sp) =>
        sp.variants.some((v) =>
          v.pricingLevelDetails?.some((spd) => spd.costUpdated)
        )
      ) ?? false
    );
  }, [pricingLevel]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Manage Pricing"
        heading="Manage Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Manage Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Pricing Levels",
            href: "/inventory/pricing/pricing-levels",
          },
          {
            name: pricingLevel?.name || "Pricing Level",
            href: `/inventory/pricing/pricing-levels/${pricingLevelId}`,
          },
          {
            name: PricingLevelPricingResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PricingLevelPricingResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PricingLevelPricingResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <div className="flex w-80 max-w-full items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
              <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <DebounceInput
                type="search"
                className={classNames(
                  "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                  "shadow-none outline-none focus:ring-0",
                  "placeholder-black/50 placeholder-opacity-100"
                )}
                placeholder="Search product name or stock code"
                minLength={2}
                debounceTimeout={300}
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
              />
            </div>
            <FieldInsertProducts
              title="Add Products"
              onChange={handleAddProduct}
              excludeIds={
                pricingLevel?.products?.map((item) => parseInt(item.id)) ?? []
              }
              excludeNote="Already added"
            />
            <Button variant="secondary" onClick={handleCancel}>
              {t("text_back")}
            </Button>
            {productPricing.filter(
              (pp) =>
                pp.status === ProductPricingStatus.pending ||
                pp.status === ProductPricingStatus.error
            ).length ? (
              <Button
                onClick={handleSave}
                loading={loadingCreate || loadingUpdate}
              >
                {t("text_update")}
              </Button>
            ) : null}
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh pricing levels</span>
            </Button>
          </div>
        </div>

        {isOutdated && (
          <div className="mt-4 rounded-md border border-red-100 bg-red-50 p-4 md:mt-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Warning: Outdated cost price
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>
                    The sell price of some products are outdated. Please click
                    the update button to save the new sell prices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading || !pricingLevel ? (
          <Waiting />
        ) : (
          <div className="mt-4 space-y-2">
            {pricingLevel?.products.map((product) => (
              <FieldPriceLevelCalculator
                key={product.id}
                product={product}
                removeProduct={handleRemoveProduct}
                pricingMethod={pricingLevel.pricingMethod}
                productPricing={productPricing}
                setProductPricing={setProductPricing}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PricingLevelPricing;
export const PricingLevelPricingResource: ResourceProps = {
  name: "Manage Pricing",
  description: "Manage pricing list for pricing level",
  access: ["read-pricings"],
  path: "pricing-levels/:pricingLevelId/pricing",
};
