import { gql } from "@apollo/client";

export const VEHICLELIFECYCLE_FRAGMENT = gql`
  fragment VehicleLifecycleFragment on VehicleLifecycle {
    id
    estimatedResaleValue
    estimatedServiceLifeInMeter
    estimatedServiceLifeInMonths
    inServiceDate
    inServiceOdometer
    outOfServiceDate
    outOfServiceOdometer
  }
`;

export const GET_VEHICLELIFECYCLE_BY_VEHICLE_ID = gql`
  ${VEHICLELIFECYCLE_FRAGMENT}
  query FetchLifecycleByVehicle($vehicleId: ID!) {
    fetchLifecycleByVehicle(vehicleId: $vehicleId) {
      ...VehicleLifecycleFragment
    }
  }
`;

export const ADD_VEHICLELIFECYCLE = gql`
  ${VEHICLELIFECYCLE_FRAGMENT}
  mutation VehicleLifecycleCreate(
    $vehicleId: Int!
    $inServiceDate: ISO8601DateTime
    $inServiceOdometer: Int
    $estimatedServiceLifeInMonths: Int
    $estimatedServiceLifeInMeter: Int
    $estimatedResaleValue: Float
    $outOfServiceDate: ISO8601DateTime
    $outOfServiceOdometer: Int
  ) {
    vehicleLifecycleCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          inServiceDate: $inServiceDate
          inServiceOdometer: $inServiceOdometer
          estimatedServiceLifeInMonths: $estimatedServiceLifeInMonths
          estimatedServiceLifeInMeter: $estimatedServiceLifeInMeter
          estimatedResaleValue: $estimatedResaleValue
          outOfServiceDate: $outOfServiceDate
          outOfServiceOdometer: $outOfServiceOdometer
        }
      }
    ) {
      vehicleLifecycle {
        ...VehicleLifecycleFragment
      }
    }
  }
`;

export const EDIT_VEHICLELIFECYCLE = gql`
  ${VEHICLELIFECYCLE_FRAGMENT}
  mutation VehicleLifecycleUpdate(
    $id: ID!
    $vehicleId: Int!
    $inServiceDate: ISO8601DateTime
    $inServiceOdometer: Int
    $estimatedServiceLifeInMonths: Int
    $estimatedServiceLifeInMeter: Int
    $estimatedResaleValue: Float
    $outOfServiceDate: ISO8601DateTime
    $outOfServiceOdometer: Int
  ) {
    vehicleLifecycleUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          inServiceDate: $inServiceDate
          inServiceOdometer: $inServiceOdometer
          estimatedServiceLifeInMonths: $estimatedServiceLifeInMonths
          estimatedServiceLifeInMeter: $estimatedServiceLifeInMeter
          estimatedResaleValue: $estimatedResaleValue
          outOfServiceDate: $outOfServiceDate
          outOfServiceOdometer: $outOfServiceOdometer
        }
      }
    ) {
      vehicleLifecycle {
        ...VehicleLifecycleFragment
      }
    }
  }
`;

export type VehicleLifecycle = {
  id: string;
  estimatedResaleValue: number | null;
  estimatedServiceLifeInMeter: number | null;
  estimatedServiceLifeInMonths: number | null;
  inServiceDate: string | null;
  inServiceOdometer: number | null;
  outOfServiceDate: string | null;
  outOfServiceOdometer: number | null;
  vehicleId: number;
};

export type VehicleLifecycleForm = Omit<
  VehicleLifecycle,
  "id" | "vehicleId"
> & {
  id?: string;
};
