import { useMutation, useQuery } from "@apollo/client/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useCallback, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner, Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  Button,
  FieldInsertProducts,
  FieldSpecialPriceCalculator,
} from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  CREATE_SPECIALPRICEPRICING,
  DELETE_PRODUCTPRICING,
  FETCH_SPECIALPRICE,
  type ProductPricing,
  ProductPricingStatus,
  type SpecialPrice,
  UPDATE_SPECIALPRICE,
  UPDATE_SPECIALPRICEPRICING,
} from "../../../../graphql/inventory/pricing/special-prices/pricing";
import { PreviewProduct } from "../../../../graphql/inventory/products";
import { classNames } from "../../../../utils";

const SpecialPricePricing = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { specialPriceId } = useParams();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { data, loading, error, refetch } = useQuery(FETCH_SPECIALPRICE, {
    variables: {
      id: specialPriceId,
      specialPriceId: Number(specialPriceId),
    },
  });

  const specialPrice = useMemo<SpecialPrice | undefined>(() => {
    if (data?.fetchSpecialPrice) {
      const fetchedSpecialPrice: SpecialPrice = data.fetchSpecialPrice;
      if (globalFilter === "") return fetchedSpecialPrice;
      const updatedSpecialPrice = {
        ...fetchedSpecialPrice,
        products: fetchedSpecialPrice.products.filter((product) => {
          const isMatchName = product.name
            .toLowerCase()
            .includes(globalFilter.toLowerCase());
          const isMatchSku = product.variants.some((variant) =>
            variant.stockCode.toLowerCase().includes(globalFilter.toLowerCase())
          );
          return isMatchName || isMatchSku;
        }),
      };
      return updatedSpecialPrice;
    }
  }, [data, globalFilter]);

  const [deleteProductPricing] = useMutation(DELETE_PRODUCTPRICING, {
    refetchQueries: [
      {
        query: FETCH_SPECIALPRICE,
        variables: {
          id: specialPriceId,
          specialPriceId: Number(specialPriceId),
        },
      },
    ],
  });

  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);
  const [createSpecialPricePricing, { loading: loadingCreate }] = useMutation(
    CREATE_SPECIALPRICEPRICING,
    {
      refetchQueries: [
        {
          query: FETCH_SPECIALPRICE,
          variables: {
            id: specialPriceId,
            specialPriceId: Number(specialPriceId),
          },
        },
      ],
    }
  );
  const [updateSpecialPricePricing, { loading: loadingUpdate }] = useMutation(
    UPDATE_SPECIALPRICEPRICING,
    {
      refetchQueries: [
        {
          query: FETCH_SPECIALPRICE,
          variables: {
            id: specialPriceId,
            specialPriceId: Number(specialPriceId),
          },
        },
      ],
    }
  );

  const [updateSpecialPrice] = useMutation(UPDATE_SPECIALPRICE, {
    refetchQueries: [
      {
        query: FETCH_SPECIALPRICE,
        variables: {
          id: specialPriceId,
          specialPriceId: Number(specialPriceId),
        },
      },
    ],
  });

  const handleSave = useCallback(async () => {
    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const {
        pricingId,
        specialPriceId,
        productId,
        productSkuId,
        priceFields,
        sellPrice,
      } = product;

      if (pricingId) {
        await updateSpecialPricePricing({
          variables: {
            id: pricingId,
            specialPriceId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.specialPricePricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createSpecialPricePricing({
          variables: {
            specialPriceId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.specialPricePricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    setProductPricing(newProductPricing);
    refetch();
  }, [
    createSpecialPricePricing,
    productPricing,
    refetch,
    updateSpecialPricePricing,
  ]);

  const handleCancel = () => {
    return navigate(`/inventory/pricing/special-prices/${specialPriceId}`);
  };

  const handleRemoveProduct = useCallback(
    (productId: string) => {
      if (!specialPriceId || !specialPrice) return;

      deleteProductPricing({
        variables: {
          productId: parseInt(productId),
          specialPriceId: parseInt(specialPriceId),
        },
      })
        .then(({ data }) => {
          if (data?.specialPricePricingDelete) {
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Product removed successfully",
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Prodcut remove failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcut remove failed",
            message: error.message,
          });
        });
    },
    [addNotify, deleteProductPricing, refetch, specialPrice, specialPriceId]
  );

  const handleAddProduct = useCallback(
    (products: PreviewProduct[]) => {
      if (!specialPrice || !products.length) return;

      updateSpecialPrice({
        variables: {
          id: specialPrice.id,
          name: specialPrice.name,
          pricingMethodId: parseInt(specialPrice.pricingMethod.id),
          productIds: products.map((item) => parseInt(item.id)),
          status: specialPrice.status,
        },
      })
        .then(({ data }) => {
          if (data?.specialPriceUpdate) {
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Products updated successfully",
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Prodcuts update failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Prodcuts update failed",
            message: error.message,
          });
        });
    },
    [addNotify, refetch, specialPrice, updateSpecialPrice]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  const isOutdated = useMemo<boolean>(() => {
    return (
      specialPrice?.products.some((sp) =>
        sp.variants.some((v) =>
          v.specialPriceDetails?.some((spd) => spd.costUpdated)
        )
      ) ?? false
    );
  }, [specialPrice]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Manage Pricing"
        heading="Manage Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Manage Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Special Prices",
            href: "/inventory/pricing/special-prices",
          },
          {
            name: specialPrice?.name || "Special Price",
            href: `/inventory/pricing/special-prices/${specialPriceId}`,
          },
          {
            name: SpecialPricePricingResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {SpecialPricePricingResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {SpecialPricePricingResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <div className="flex w-80 max-w-full items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
              <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <DebounceInput
                type="search"
                className={classNames(
                  "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                  "shadow-none outline-none focus:ring-0",
                  "placeholder-black/50 placeholder-opacity-100"
                )}
                placeholder="Search product name or stock code"
                minLength={2}
                debounceTimeout={300}
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
              />
            </div>
            <FieldInsertProducts
              title="Add Products"
              onChange={handleAddProduct}
              excludeIds={
                specialPrice?.products?.map((item) => parseInt(item.id)) ?? []
              }
              excludeNote="Already added"
            />
            <Button variant="secondary" onClick={handleCancel}>
              {t("text_back")}
            </Button>
            {productPricing.filter(
              (pp) =>
                pp.status === ProductPricingStatus.pending ||
                pp.status === ProductPricingStatus.error
            ).length ? (
              <Button onClick={handleSave}>
                {loadingCreate || loadingUpdate ? (
                  <Fragment>
                    <Spinner />
                    {t("text_update")}
                  </Fragment>
                ) : (
                  t("text_update")
                )}
              </Button>
            ) : null}
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh special prices</span>
            </Button>
          </div>
        </div>

        {isOutdated && (
          <div className="mt-4 rounded-md border border-red-100 bg-red-50 p-4 md:mt-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Warning: Outdated cost price
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>
                    The sell price of some products are outdated. Please click
                    the update button to save the new sell prices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading || !specialPrice ? (
          <Waiting />
        ) : (
          <div className="mt-4 space-y-2">
            {specialPrice.products.map((product) => (
              <FieldSpecialPriceCalculator
                key={product.variants.flatMap((v) => v.stockCode).join("-")}
                product={product}
                removeProduct={handleRemoveProduct}
                pricingMethod={specialPrice.pricingMethod}
                productPricing={productPricing}
                setProductPricing={setProductPricing}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SpecialPricePricing;
export const SpecialPricePricingResource: ResourceProps = {
  name: "Manage Pricing",
  description: "Manage pricing list for special price",
  access: ["read-pricings"],
  path: "special-prices/:specialPriceId/pricing",
};
