import { gql } from "@apollo/client";

import { Vehicle } from "../..";

export const VEHICLESETTINGS_FRAGMENT = gql`
  fragment VehicleSettingFragment on VehicleSetting {
    id
    fuelUnit
    measurementUnit
    primaryMeter
    vehicle {
      id
      name
    }
  }
`;

export const GET_VEHICLESETTINGS = gql`
  ${VEHICLESETTINGS_FRAGMENT}
  query FetchSettingsByVehicle($vehicleId: ID!) {
    fetchSettingsByVehicle(vehicleId: $vehicleId) {
      ...VehicleSettingFragment
    }
  }
`;

export const GET_VEHICLESETTINGS_BASIC_BY_VEHICLE_ID = gql`
  query FetchVehicleSettingBasic($vehicleId: ID!) {
    fetchSettingsByVehicle(vehicleId: $vehicleId) {
      id
      fuelUnit
      measurementUnit
      primaryMeter
    }
  }
`;

export const ADD_VEHICLESETTINGS = gql`
  ${VEHICLESETTINGS_FRAGMENT}
  mutation VehicleSettingCreate(
    $vehicleId: Int!
    $fuelUnit: Int
    $measurementUnit: Int
    $primaryMeter: Int
  ) {
    vehicleSettingCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          fuelUnit: $fuelUnit
          measurementUnit: $measurementUnit
          primaryMeter: $primaryMeter
        }
      }
    ) {
      vehicleSetting {
        ...VehicleSettingFragment
      }
    }
  }
`;

export const EDIT_VEHICLESETTINGS = gql`
  ${VEHICLESETTINGS_FRAGMENT}
  mutation VehicleSettingUpdate(
    $id: ID!
    $vehicleId: Int!
    $fuelUnit: Int
    $measurementUnit: Int
    $primaryMeter: Int
  ) {
    vehicleSettingUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          fuelUnit: $fuelUnit
          measurementUnit: $measurementUnit
          primaryMeter: $primaryMeter
        }
      }
    ) {
      vehicleSetting {
        ...VehicleSettingFragment
      }
    }
  }
`;

export enum VehicleFuelUnit {
  GALLONS,
  LITRES,
}
export enum VehicleMeasurementUnit {
  IMPERIAL,
  METRIC,
}
export enum VehiclePrimaryMeter {
  MILES,
  KILOMETER,
  HOURS,
}
export type VehicleSetting = {
  id: string;
  fuelUnit: VehicleFuelUnit | null;
  measurementUnit: VehicleMeasurementUnit | null;
  primaryMeter: VehiclePrimaryMeter | null;
  vehicle: Vehicle;
};

export type VehicleSettingForm = Omit<VehicleSetting, "id" | "vehicle"> & {
  id?: string;
};

export const renderPrimaryMeter = (
  status: VehiclePrimaryMeter | null | undefined
) => {
  switch (status) {
    case VehiclePrimaryMeter.MILES:
      return "Miles";
    case VehiclePrimaryMeter.KILOMETER:
      return "Kilometers";
    case VehiclePrimaryMeter.HOURS:
      return "Hours";
    default:
      return "Miles";
  }
};

export const renderFuelUnit = (status: VehicleFuelUnit | null | undefined) => {
  switch (status) {
    case VehicleFuelUnit.GALLONS:
      return "Gallons";
    case VehicleFuelUnit.LITRES:
      return "Litres";
    default:
      return "Gallons";
  }
};

export const renderMeasurementUnit = (
  status: VehicleMeasurementUnit | null | undefined
) => {
  switch (status) {
    case VehicleMeasurementUnit.IMPERIAL:
      return "Imperial";
    case VehicleMeasurementUnit.METRIC:
      return "Metric";
    default:
      return "Imperial";
  }
};
