import { useLazyQuery } from "@apollo/client/react";
import { lazy, Suspense, useEffect, useState } from "react";
import { Link, RouteObject, useRoutes } from "react-router-dom";

import {
  Head,
  LayoutSplashScreen,
  NotAuthorized,
  NotFound,
} from "../../../components/core";
import { GET_DELIVERYRUNS } from "../../../graphql/fleets/settings/delivery-runs";
import { GET_DOCKS } from "../../../graphql/fleets/settings/docks";
import { GET_DRIVERS } from "../../../graphql/fleets/settings/drivers";
import { GET_FLEETVENDORS } from "../../../graphql/fleets/settings/vendors";
import { GET_VEHICLES } from "../../../graphql/fleets/vehicles";
import PageLayout from "../../../layouts/PageLayout";
import { classNames } from "../../../utils";
import { isAuthorizedForResource, useAuth } from "../../auth";
import { DeliveryRunListResource } from "./delivery-runs/List";
import { DockCreateResource } from "./docks/Create";
import { DockListResource } from "./docks/List";
import { DockUpdateResource } from "./docks/Update";
import { DriverCreateResource } from "./drivers/Create";
import { DriverListResource } from "./drivers/List";
import { DriverUpdateResource } from "./drivers/Update";
import { FleetVendorCreateResource } from "./vendors/Create";
import { FleetVendorListResource } from "./vendors/List";
import { FleetVendorUpdateResource } from "./vendors/Update";

const DockList = lazy(() => import("./docks/List"));
const DockCreate = lazy(() => import("./docks/Create"));
const DockUpdate = lazy(() => import("./docks/Update"));
const DeliveryRunList = lazy(() => import("./delivery-runs/List"));
const FleetVendorList = lazy(() => import("./vendors/List"));
const FleetVendorCreate = lazy(() => import("./vendors/Create"));
const FleetVendorUpdate = lazy(() => import("./vendors/Update"));

const DriverList = lazy(() => import("./drivers/List"));
const DriverCreate = lazy(() => import("./drivers/Create"));
const DriverUpdate = lazy(() => import("./drivers/Update"));

export default function Settings({
  breadcrumbs: initialBreadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { currentRole } = useAuth();
  const breadcrumbs = [
    ...initialBreadcrumbs,
    {
      name: SettingsResource.name,
      href: `/fleets/settings`,
    },
  ];

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: (
            <>
              <Head
                title="Fleets"
                heading="Fleet Settings"
                breadcrumbs={breadcrumbs}
              />
              <Layout />
            </>
          ),
        },
        {
          path: DockListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DockListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DockList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DockListResource.access.join(", "))}
            />
          ),
        },
        {
          path: DockCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DockCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DockCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DockCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: DockUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DockUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DockUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DockUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: DeliveryRunListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DeliveryRunListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DeliveryRunList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DeliveryRunListResource.access.join(", "))}
            />
          ),
        },
        {
          path: FleetVendorListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            FleetVendorListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <FleetVendorList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(FleetVendorListResource.access.join(", "))}
            />
          ),
        },
        {
          path: FleetVendorCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            FleetVendorCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <FleetVendorCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(FleetVendorCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: FleetVendorUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            FleetVendorUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <FleetVendorUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(FleetVendorUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: DriverListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DriverListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DriverList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DriverListResource.access.join(", "))}
            />
          ),
        },
        {
          path: DriverCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DriverCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DriverCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DriverCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: DriverUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DriverUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <DriverUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(DriverUpdateResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}

function Layout() {
  const [navigation, setNavigation] = useState([
    {
      id: "drivers",
      name: "Drivers",
      path: "/fleets/settings/drivers",
      icon: "bi bi-person-lines-fill",
      count: 0,
    },
    {
      id: "vendors",
      name: "Vendors",
      path: "/fleets/settings/vendors",
      icon: "bi bi-boxes",
      count: 0,
    },
    {
      id: "docks",
      name: "Docks",
      path: "/fleets/settings/docks",
      icon: "bi bi-hdd-stack",
      count: 0,
    },
    {
      id: "delivery-runs",
      name: "Delivery Runs",
      path: "/fleets/settings/delivery-runs",
      icon: "bi bi-truck-front",
      count: 0,
    },
  ]);

  const [fetchDocks] = useLazyQuery(GET_DOCKS);
  const [fetchDeliveryRuns] = useLazyQuery(GET_DELIVERYRUNS);
  const [fetchFleetVendors] = useLazyQuery(GET_FLEETVENDORS);
  const [fetchDrivers] = useLazyQuery(GET_DRIVERS);
  const [fetchVehicles] = useLazyQuery(GET_VEHICLES);

  useEffect(() => {
    fetchDocks({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "docks") {
              return {
                ...item,
                count: data.fetchDocks.length,
              };
            }

            return item;
          })
        );
      },
    });

    fetchDeliveryRuns({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "delivery-runs") {
              return {
                ...item,
                count: data.fetchDeliveryRuns.length,
              };
            }

            return item;
          })
        );
      },
    });

    fetchFleetVendors({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "vendors") {
              return {
                ...item,
                count: data.fetchFleetVendors.length,
              };
            }

            return item;
          })
        );
      },
    });

    fetchDrivers({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "drivers") {
              return {
                ...item,
                count: data.fetchDrivers.length,
              };
            }

            return item;
          })
        );
      },
    });
    fetchVehicles({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "vehicles") {
              return {
                ...item,
                count: data.fetchVehicles.length,
              };
            }

            return item;
          })
        );
      },
    });
  }, [
    fetchDeliveryRuns,
    fetchDocks,
    fetchDrivers,
    fetchFleetVendors,
    fetchVehicles,
  ]);

  return (
    <>
      <div>
        <h2 className="text-sm font-medium text-gray-500">Settings</h2>
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 2xl:grid-cols-4">
          {navigation.map((nav) => (
            <Link
              key={nav.name}
              to={nav.path}
              className="overflow-hidden rounded-lg border border-gray-300 bg-white hover:border-primary-600"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex h-10 w-10 items-center justify-center rounded-md bg-primary-300">
                      <span
                        className={classNames(
                          "h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                          nav.icon
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="ml-4 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {nav.name}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {nav.count ? nav.count : "-"}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <span className="font-medium text-primary-700 hover:text-primary-900">
                    View all
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}

export const SettingsResource: ResourceProps = {
  name: "Fleet Settings",
  description: "Fleet Settings",
  access: ["read-fleets"],
  path: "settings/*",
};
