import { useQuery } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { Fragment, useMemo, useState } from "react";

import { Spinner } from "../../../animations";
import placeholder from "../../../assets/placeholder.svg";
import { ErrorFallback } from "../../../components/core";
import {
  GET_TOP_SELLING_PRODUCTS_WIDGET,
  TopSellingProductsWidget,
  Widget,
} from "../../../graphql/dashboard";
import { classNames, formatCurrency } from "../../../utils";

export function TopSellingProducts({ item }: { item: Widget }) {
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const { data, error, loading, refetch } = useQuery<{
    fetchTopSellingProductsWidget: TopSellingProductsWidget[];
  }>(GET_TOP_SELLING_PRODUCTS_WIDGET, {
    variables: {
      month,
      year,
    },
  });

  const orders = useMemo<TopSellingProductsWidget[]>(
    () => data?.fetchTopSellingProductsWidget ?? [],
    [data?.fetchTopSellingProductsWidget]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <Fragment>
      <nav className="z-10 flex items-center justify-end space-x-2 md:absolute md:right-14 md:top-3">
        <select
          className="relative inline-flex appearance-none rounded-md border-0 bg-transparent pl-4 pr-7 text-sm leading-5 text-gray-800 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
          value={month}
          onChange={(e) => {
            setMonth(parseInt(e.target.value));
          }}
        >
          {Array.from({ length: 12 }, (_, i) => {
            const month = i + 1;
            const isFutureMonth =
              month > new Date().getMonth() + 1 &&
              year === new Date().getFullYear();
            return (
              <option key={month} value={month} disabled={isFutureMonth}>
                {new Date(0, month - 1).toLocaleString("default", {
                  month: "long",
                })}
              </option>
            );
          })}
        </select>
        <select
          className="relative inline-flex appearance-none rounded-md border-0 bg-transparent pl-4 pr-7 text-sm leading-5  text-gray-800 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
          value={year}
          onChange={(e) => {
            setYear(parseInt(e.target.value));
          }}
        >
          {Array.from({ length: 5 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </nav>
      {loading ? (
        <div className="flex justify-center py-10 text-primary md:py-16 xl:py-20">
          <Spinner />
        </div>
      ) : orders.length ? (
        <div className="rounded-lg bg-white p-4 shadow-sm">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 lg:table-cell"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 sm:table-cell"
                >
                  StockCode
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 sm:table-cell"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 pr-4 text-right text-sm font-normal text-gray-900 sm:pr-0"
                >
                  Total Price
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {orders.map((order) => (
                <tr key={order.stockCode}>
                  <td className="w-full max-w-0 py-1 pl-4 pr-3 text-sm font-light text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                    <div className="relative z-10 mr-3 block h-12 w-12 appearance-none overflow-hidden rounded-md ring-2 ring-white filter transition hover:hue-rotate-15">
                      <img
                        className={classNames("h-full w-full object-cover")}
                        src={
                          order.featureImageUrl
                            ? order.featureImageUrl
                            : placeholder
                        }
                        alt={order.name}
                      />
                    </div>

                    <dl className="font-light lg:hidden">
                      <dt className="sr-only">Stock Code</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {order.stockCode}
                      </dd>
                      <dt className="sr-only sm:hidden">Quantity</dt>
                      <dd className="mt-1 truncate text-gray-700 sm:hidden">
                        {order.totalPurchase}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm font-light text-gray-700 lg:table-cell">
                    {order.name}
                  </td>
                  <td className="hidden px-3 py-4 text-sm font-light text-gray-700 sm:table-cell">
                    {order.stockCode}
                  </td>
                  <td className="hidden px-3 py-4 text-sm font-light text-gray-700 sm:table-cell">
                    {order.totalPurchase}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-normal sm:pr-0">
                    {formatCurrency(order.totalPrice)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className={classNames(
            "px-0 py-10 text-center font-light md:py-16 xl:py-20"
          )}
        >
          <ExclamationCircleIcon
            type="outline"
            name="exclamation-circle"
            className="mx-auto h-6 w-6 text-gray-400"
          />
          <p className="mt-4 font-normal text-gray-900">No data available</p>
          <p className="mt-2 text-sm text-gray-500">
            There is no data available for this widget.
          </p>
        </div>
      )}
    </Fragment>
  );
}
