import { faker } from "@faker-js/faker";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
  },
  elements: {
    line: {
      borderWidth: 1,
      fill: false,
    },
    point: {
      radius: 2,
      hitRadius: 10,
      hoverRadius: 4,
    },
  },
};

const labels = [
  "Jan 2022",
  "Feb 2020",
  "Mar 2020",
  "Apr 2020",
  "May 2020",
  "Jun 2020",
  "Jul 2020",
];

export const datas = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(75, 168, 79)",
      backgroundColor: "rgba(75, 16, 79, 0.5)",
      pointStyle: "cross",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(229, 229, 229)",
      backgroundColor: "rgba(229, 229, 229, 0.5)",
      pointStyle: "cross",
    },
  ],
};

export function LineChart({ className }: { className?: string }) {
  return <Line options={options} data={datas} className={className} />;
}
