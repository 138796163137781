import { gql } from "@apollo/client/core";

export const GET_CUSTOMERGROUPS = gql`
  query FetchCustomerGroups($status: Boolean) {
    fetchCustomerGroups(status: $status) {
      id
      name
      description
      customers {
        id
        customerName
      }
      createdAt
      status
    }
  }
`;

export const UPDATE_CUSTOMERGROUP = gql`
  mutation CustomerGroupUpdate(
    $id: ID!
    $name: String!
    $description: String
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    customerGroupUpdate(
      input: {
        id: $id
        params: {
          name: $name
          description: $description
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      customerGroup {
        id
        name
        description
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

export const CREATE_CUSTOMERGROUP = gql`
  mutation CustomerGroupCreate(
    $name: String!
    $description: String
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    customerGroupCreate(
      input: {
        params: {
          name: $name
          description: $description
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      customerGroup {
        id
        name
        description
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

export type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: {
    id: string;
    customerName: string;
  }[];
  createdAt: string;
  status: boolean;
};
