import { useQuery } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Fragment, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";

import { Spinner } from "../../../animations";
import { ErrorFallback } from "../../../components/core";
import {
  GET_SALES_REVENUE_WIDGET,
  SalesRevenueWidget,
  Widget,
} from "../../../graphql/dashboard";
import { classNames, formatFloat } from "../../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function SalesRevenue({ item }: { item: Widget }) {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const { data, error, loading, refetch } = useQuery<{
    fetchSalesRevenueWidget: SalesRevenueWidget[];
  }>(GET_SALES_REVENUE_WIDGET, {
    variables: {
      year,
    },
  });

  const blocks = useMemo<SalesRevenueWidget[]>(
    () => data?.fetchSalesRevenueWidget ?? [],
    [data?.fetchSalesRevenueWidget]
  );

  const values = useMemo<number[]>(
    () => blocks.map((block) => block.totalSales),
    [blocks]
  );

  const datas = {
    labels: blocks.map(
      (block) =>
        `${new Date(block.year, block.month - 1, 1).toLocaleString("default", {
          month: "short",
        })} ${block.year}`
    ),
    datasets: [
      {
        label: "Total Sales",
        data: values,
        borderColor: "rgb(75, 168, 79)",
        backgroundColor: "rgba(75, 168, 79, 0.5)",
        pointStyle: "cross",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,

        external: function (context: any) {
          // Tooltip Element
          let tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.classList.add("pl-4");
            tooltipEl.innerHTML =
              "<div class='bg-primary-700 text-white text-left shadow-lg rounded-tr-md rounded-br-md rounded-bl-md p-2 relative'><span class='icon-angle-tl'></span><table></table></div>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const dataPoints = tooltipModel.dataPoints;

            let innerHtml = "<thead>";

            titleLines.forEach(function (title: string) {
              innerHtml +=
                "<tr><th col-span='2' class='text-xs font-light p-1'>" +
                title +
                "</th></tr>";
            });
            innerHtml += "</thead><tbody>";

            dataPoints.forEach(function (point: { raw: number }) {
              innerHtml +=
                "<tr><td col-span='2' class='text-lg font-medium px-1'> $" +
                point.raw +
                "</td></tr>";
            });

            const curValue = dataPoints.length ? dataPoints[0].raw : 0;
            const currIndex = values.indexOf(curValue);
            let prevValue = 0;
            if (currIndex > 0) {
              prevValue = values[currIndex - 1];
            }
            const diff = ((curValue - prevValue) / prevValue) * 100;
            const diffSign = diff > 0 ? "+" : "";
            const diffString = prevValue === 0 ? 0 : formatFloat(diff, 2);

            innerHtml +=
              "<tr><td class='text-xs font-light p-1'>Total Sales</td><td class='text-xs font-light pl-3 py-1 pr-1'>" +
              diffSign +
              "" +
              diffString +
              "%</td></tr>";

            innerHtml += "</tbody>";

            let tableRoot = tooltipEl.querySelector("table");
            if (tableRoot) {
              tableRoot.innerHTML = innerHtml;
            }
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.scrollY + tooltipModel.caretX + "px";
          tooltipEl.style.top =
            position.top + window.scrollY + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
    },
    elements: {
      line: {
        borderWidth: 1,
        fill: false,
      },
      point: {
        radius: 2,
        hitRadius: 10,
        hoverRadius: 4,
      },
    },
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <Fragment>
      <nav className="z-10 flex items-center justify-end space-x-2 md:absolute md:right-14 md:top-3">
        <select
          className="relative inline-flex appearance-none rounded-md border-0 bg-transparent pl-4 pr-7 text-sm leading-5  text-gray-800 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
          value={year}
          onChange={(e) => {
            setYear(parseInt(e.target.value));
          }}
        >
          {Array.from({ length: 5 }, (_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </nav>

      {loading ? (
        <div className="flex justify-center py-10 text-primary md:py-16 xl:py-20">
          <Spinner />
        </div>
      ) : blocks.length ? (
        <div className="rounded-lg bg-white p-4 shadow-sm">
          <Line options={options} data={datas} />
        </div>
      ) : (
        <div
          className={classNames(
            "px-0 py-10 text-center font-light md:py-16 xl:py-20"
          )}
        >
          <ExclamationCircleIcon
            type="outline"
            name="exclamation-circle"
            className="mx-auto h-6 w-6 text-gray-400"
          />
          <p className="mt-4 font-normal text-gray-900">No data available</p>
          <p className="mt-2 text-sm text-gray-500">
            There is no data available for this widget.
          </p>
        </div>
      )}
    </Fragment>
  );
}
