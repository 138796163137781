import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

import { Spinner } from "../../animations";
import { bottonClassess } from "../../utils";

type ButtonButtonProps = Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "disabled" | "className" | "children" | "style"
>;
type ButtonLinkProps = Pick<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  "rel" | "href" | "target" | "tabIndex"
>;
type ButtonAndLinkProps = ButtonButtonProps & ButtonLinkProps;
type ButtonProps = ButtonAndLinkProps & {
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "danger"
    | "warning"
    | "info"
    | "text"
    | "icon";
  type?: "button" | "submit" | "reset" | "link";
  border?: boolean;
  name?: string;
  loading?: boolean;
};

export const Button = forwardRef((props: ButtonProps, ref: any) => {
  const {
    className = "",
    children,
    type = "button",
    border = false,
    variant = "primary",
    loading = false,
    rel = "",
    href = "",
    disabled = false,
    onClick,
    ...rest
  } = props;

  const render = loading ? <Spinner className="mx-0" /> : children;

  const classes = bottonClassess(className, variant, border, loading);

  switch (type) {
    case "link":
      return (
        <Link to={href} rel={rel} className={classes} {...rest}>
          {render}
        </Link>
      );
    default:
      return (
        <button
          ref={ref}
          type={type}
          onClick={onClick}
          disabled={disabled}
          className={classes}
          {...rest}
        >
          {render}
        </button>
      );
  }
});
