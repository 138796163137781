import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

import {
  GET_VEHICLEGROUPS,
  VehicleGroup,
} from "../../graphql/fleets/vehicles/sections/types";
import { selectStyles, SelectWrapper } from ".";

export function FieldVehicleType({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery<{
    fetchVehicleGroups: VehicleGroup[];
  }>(GET_VEHICLEGROUPS);

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchVehicleGroups) {
      return data.fetchVehicleGroups?.map((p: VehicleGroup) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (!value) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
