import { ApolloSandbox } from "@apollo/sandbox/react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { Loader } from "../animations";
import App from "../App";
import { NetworkStatus, NotMatch } from "../components/core";
import { MasterLayout } from "../layouts/MasterLayout";
import { AuthPage, Logout, useAuth } from "../modules/auth";
import DashboardPage from "../modules/dashboard";
import { ErrorsPage } from "../modules/errors";
import { FleetsPage } from "../modules/fleets";
import { InventoryPage } from "../modules/inventory";
import MediaPage from "../modules/media";
import { PickpackPage } from "../modules/pickpack";
import { PurchasePage } from "../modules/purchases";
import { SalePage } from "../modules/sales";
import { SettingsPage } from "../modules/settings";
import { StorePage } from "../modules/store";
import { UserManagementPage } from "../modules/user-management";

const { REACT_APP_URL } = process.env;

export default function AppRoutes() {
  const { isLocked, refresh } = useAuth();

  let elements =
    !isLocked && refresh
      ? [
          {
            element: <MasterLayout />,
            children: [
              {
                path: "auth/*",
                children: [
                  {
                    path: "login",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "register",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "forgot-password",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "reset-password",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "create-password",
                    element: <Navigate to="/" />,
                  },
                  {
                    path: "challenge/*",
                    element: <Navigate to="/" />,
                  },
                ],
              },
              {
                path: "dashboard",
                element: <DashboardPage />,
              },
              {
                path: "media",
                element: <MediaPage />,
              },
              {
                path: "user-management/*",
                element: <UserManagementPage />,
              },
              {
                path: "inventory/*",
                element: <InventoryPage />,
              },
              {
                path: "purchases/*",
                element: <PurchasePage />,
              },
              {
                path: "fleets/*",
                element: <FleetsPage />,
              },
              {
                path: "sales/*",
                element: <SalePage />,
              },
              {
                path: "pickpack/*",
                element: <PickpackPage />,
              },
              {
                path: "store/*",
                element: <StorePage />,
              },
              {
                path: "settings/*",
                element: <SettingsPage />,
              },
              {
                index: true,
                element: <Navigate to="/dashboard" replace={true} />,
              },
            ],
          },
          {
            index: true,
            element: <Navigate to="/dashboard" replace={true} />,
          },
        ]
      : [
          {
            path: "auth/*",
            element: <AuthPage />,
          },
          {
            path: "*",
            element: <Navigate to="/auth/login" replace={true} />,
          },
          {
            index: true,
            element: <Navigate to="/auth/login" replace={true} />,
          },
        ];

  let router = createBrowserRouter(
    [
      {
        path: "/",
        element: <App />,
        errorElement: <NotMatch />,
        children: [
          {
            path: "login",
            element: <Navigate to="/auth/login" />,
          },
          {
            path: "register",
            element: <Navigate to="/auth/register" />,
          },
          {
            path: "logout",
            element: <Logout />,
          },
          {
            path: "error/*",
            element: <ErrorsPage />,
          },
          ...elements,
        ],
      },
      {
        path: "graphql",
        element: (
          <div className="h-screen [&>*]:h-full">
            <ApolloSandbox initialEndpoint="https://api-app.alphafresh.planetmedia.dev/graphql" />
          </div>
        ),
      },
    ],
    {
      basename: REACT_APP_URL,
    }
  );

  return (
    <>
      <NetworkStatus />
      <RouterProvider router={router} fallbackElement={<Loader loading />} />
    </>
  );
}
