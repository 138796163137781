import { gql, useLazyQuery } from "@apollo/client";
import { lazy, Suspense, useEffect, useState } from "react";
import { Link, RouteObject, useRoutes } from "react-router-dom";

import {
  Head,
  LayoutSplashScreen,
  NotAuthorized,
  NotFound,
} from "../../../components/core";
import PageLayout from "../../../layouts/PageLayout";
import { classNames } from "../../../utils";
import { isAuthorizedForResource, useAuth } from "../../auth";
import { CustomerContactTypeListResource } from "./customer-contact-types/List";
import { CustomerContactCreateResource } from "./customer-contacts/Create";
import { CustomerContactListResource } from "./customer-contacts/List";
import { CustomerContactUpdateResource } from "./customer-contacts/Update";
import { CustomerGroupCreateResource } from "./customer-groups/Create";
import { CustomerGroupListResource } from "./customer-groups/List";
import { CustomerGroupUpdateResource } from "./customer-groups/Update";

const CustomerGroupList = lazy(() => import("./customer-groups/List"));
const CustomerGroupCreate = lazy(() => import("./customer-groups/Create"));
const CustomerGroupUpdate = lazy(() => import("./customer-groups/Update"));

const CustomerContactTypeList = lazy(
  () => import("./customer-contact-types/List")
);
const CustomerContactList = lazy(() => import("./customer-contacts/List"));
const CustomerContactCreate = lazy(() => import("./customer-contacts/Create"));
const CustomerContactUpdate = lazy(() => import("./customer-contacts/Update"));

export default function Settings({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: (
            <>
              <Head
                title="Sales"
                heading="Sales Settings"
                breadcrumbs={[
                  ...breadcrumbs,
                  {
                    name: "Sales",
                    href: "/sales",
                  },
                  {
                    name: "Sales Settings",
                    href: null,
                  },
                ]}
              />
              <Layout />
            </>
          ),
        },
        {
          path: CustomerGroupListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerGroupListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerGroupList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerGroupListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerGroupCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerGroupCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerGroupCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerGroupCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerGroupUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerGroupUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerGroupUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerGroupUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerContactTypeListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactTypeListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactTypeList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={
                new Error(CustomerContactTypeListResource.access.join(", "))
              }
            />
          ),
        },
        {
          path: CustomerContactListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerContactListResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerContactCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerContactCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: CustomerContactUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            CustomerContactUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <CustomerContactUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(CustomerContactUpdateResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}

const FETCH_CUSTOMERCONTACTTYPES = gql`
  query FetchCustomerContactTypes {
    fetchCustomerContactTypes {
      id
    }
  }
`;
const FETCH_CUSTOMERGROUPS = gql`
  query FetchCustomerGroups {
    fetchCustomerGroups {
      id
    }
  }
`;

const GET_CUSTOMERCONTACTS = gql`
  query FetchCustomerContacts {
    fetchCustomerContacts {
      id
    }
  }
`;

function Layout() {
  const [navigation, setNavigation] = useState([
    {
      id: "contact-types",
      name: "Contact Types",
      path: "/sales/settings/customer-contact-types",
      icon: "bi bi-hdd-stack",
      count: 0,
    },
    {
      id: "contact-groups",
      name: "Contact Groups",
      path: "/sales/settings/customer-groups",
      icon: "bi bi-truck-front",
      count: 0,
    },
    {
      id: "customer-contacts",
      name: "Customer Contacts",
      path: "/sales/settings/customer-contacts",
      icon: "bi bi-truck-front",
      count: 0,
    },
  ]);

  const [fetchContactTypes] = useLazyQuery<{
    fetchCustomerContactTypes: {
      id: string;
    }[];
  }>(FETCH_CUSTOMERCONTACTTYPES);

  const [fetchContactGroups] = useLazyQuery<{
    fetchCustomerGroups: {
      id: string;
    }[];
  }>(FETCH_CUSTOMERGROUPS);

  const [fetchCustomerContacts] = useLazyQuery<{
    fetchCustomerContacts: {
      id: string;
    }[];
  }>(GET_CUSTOMERCONTACTS);

  useEffect(() => {
    fetchContactTypes({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "contact-types") {
              return {
                ...item,
                count: data.fetchCustomerContactTypes.length,
              };
            }
            return item;
          })
        );
      },
    });
    fetchContactGroups({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "contact-groups") {
              return {
                ...item,
                count: data.fetchCustomerGroups.length,
              };
            }
            return item;
          })
        );
      },
    });
    fetchCustomerContacts({
      onCompleted: (data) => {
        setNavigation((navigation) =>
          navigation.map((item) => {
            if (item.id === "customer-contacts") {
              return {
                ...item,
                count: data.fetchCustomerContacts.length,
              };
            }
            return item;
          })
        );
      },
    });
  }, [fetchContactGroups, fetchContactTypes, fetchCustomerContacts]);

  return (
    <>
      <div>
        <h2 className="text-sm font-medium text-gray-500">Settings</h2>
        <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 2xl:grid-cols-4">
          {navigation.map((nav) => (
            <Link
              key={nav.name}
              to={nav.path}
              className="overflow-hidden rounded-lg border border-gray-300 bg-white hover:border-primary-600"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex h-10 w-10 items-center justify-center rounded-md bg-primary-300">
                      <span
                        className={classNames(
                          "h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                          nav.icon
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="ml-4 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {nav.name}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {nav.count}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 py-3">
                <div className="text-sm">
                  <span className="font-medium text-primary-700 hover:text-primary-900">
                    View all
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
}

export const SettingsResource: ResourceProps = {
  name: "Sales Settings",
  description: "Sales Settings",
  access: ["read-customers"],
  path: "settings/*",
};
