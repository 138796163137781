import { useMutation } from "@apollo/client/react";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { ADD_PRODUCT, GET_PRODUCTS } from "../../../graphql/inventory/products";
import Form from "./components/FormCreate";

type VariantItem = {
  id: number;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  basePrice: number;
  comparePrice: number;
  cost: number;
  marketCost: number;
  minimumQuantity: number;
  inventory: number;
  variantImageUrl: string;
  optionValues: {
    option: string;
    optionValue: string;
  }[];
};

const ProductCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();

  const [createProduct] = useMutation(ADD_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          first: 50,
          searchQuery: "",
          searchFilter: [],
          status: null,
          stockCode: "",
          vendorId: null,
          categoryId: null,
          departmentId: null,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createProduct({
      variables: {
        name: values.name,
        handle: values.handle,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        departmentId: values.department
          ? parseInt(values.department.value)
          : null,
        categoryIds: values.categories
          ? values.categories.map((item: any) => parseInt(item.value))
          : [],
        vendorId: values.vendor ? parseInt(values.vendor.value) : null,
        binLocationId: values.binLocation
          ? parseInt(values.binLocation.value)
          : null,
        featureImageUrl: values.featureImageUrl,
        options: values.options,
        hasVariants: values.hasVariants,
        variants: values.hasVariants
          ? values.variants.map((v: VariantItem) => {
              return {
                stockCode: v.stockCode,
                barCode: v.barCode ? v.barCode : null,
                plu: v.plu ? v.plu : null,
                basePrice: v.basePrice,
                comparePrice: v.comparePrice ? v.comparePrice : 0,
                optionValues: v.optionValues,
                cost: v.cost,
                marketCost: v.marketCost,
                minimumQuantity: v.minimumQuantity,
                inventory: v.inventory,
                variantImageUrl: v.variantImageUrl,
              };
            })
          : [
              {
                stockCode: values.stockCode,
                barCode: values.barCode ? values.barCode : null,
                plu: values.plu ? values.plu : null,
                basePrice: values.basePrice,
                comparePrice: values.comparePrice ? values.comparePrice : 0,
                optionValues: [],
                cost: values.cost,
                marketCost: values.marketCost,
                minimumQuantity: values.minimumQuantity,
                inventory: values.inventory,
                variantImageUrl: "",
              },
            ],
        returnableItem: values.returnableItem,
        relatedIds: values.relatedIds
          ? values.relatedIds.map((item: any) => parseInt(item.value))
          : [],
        galleryImageUrls: values.galleryImageUrls
          ? values.galleryImageUrls
          : [],
        status: values.status ? parseInt(values.status.value) : null,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.productCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Product created successfully",
          });
          return navigate(
            `/inventory/products/${data.productCreate.product.id}`
          );
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Product creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Product creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Product"
        heading="Products"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Products",
            href: "/inventory/products",
          },
          {
            name: ProductCreateResource.name,
            href: null,
          },
        ]}
      />

      <div className="mx-auto max-w-6xl px-5 py-6 sm:py-8">
        <Form
          initialValues={{
            name: "",
            handle: "",
            description: "",
            basePrice: 0,
            binLocation: null,
            categories: [],
            comparePrice: 0,
            department: null,
            featureImageUrl: "",
            pricingLevel: null,
            variants: [],
            cost: 0,
            marketCost: 0,
            minimumQuantity: 0,
            inventory: 0,
            options: [],
            hasVariants: false,
            returnableItem: false,
            stockCode: "",
            barCode: "",
            plu: null,
            vendor: null,
            relatedIds: [],
            galleryImageUrls: [],
            status: null,
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default ProductCreate;
export const ProductCreateResource: ResourceProps = {
  name: "Create Product",
  description: "Create a new product",
  access: ["create-products"],
  path: "products/add",
};
