import { Pick } from "@react-spring/web";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../../animations";
import {
  Button,
  Field,
  FieldImage,
  FieldPlaces,
  selectStyles,
  SelectWrapper,
} from "../../../../../components/form";
import { Driver } from "../../../../../graphql/fleets/settings/drivers";
import { classNames } from "../../../../../utils";

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: Pick<
    Driver,
    "name" | "email" | "mobile" | "address" | "profileImageUrl" | "status"
  >;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const DriverSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    email: Yup.string().required("Required"),
    mobile: Yup.string().nullable(),
    address: Yup.string().nullable(),
    profileImageUrl: Yup.string().nullable(),
    status: Yup.number(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: DriverSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={touched.name}
            errors={errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_email")}
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            touched={touched.email}
            errors={errors.email}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_mobile")}
            name="mobile"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mobile}
            touched={touched.mobile}
            errors={errors.mobile}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <FieldPlaces
            title={t("text_address")}
            name="address"
            onUpdate={(value) => {
              formik.setFieldValue("address", value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            touched={touched.address}
            errors={errors.address}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <FieldImage
            title={t("text_image")}
            onChange={(value) => {
              formik.setFieldValue("profileImageUrl", value);
            }}
            value={formik.values.profileImageUrl}
            touched={touched.profileImageUrl}
            errors={errors.profileImageUrl}
          />
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_status")}
          </label>
          <FieldStatus
            value={formik.values.status}
            onChange={(value) => {
              formik.setFieldValue("status", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.status && formik.errors.status
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.status && formik.errors.status ? (
            <p className="mt-2 text-sm text-red-600" id="roles-errors">
              {formik.errors.status.toString()}
            </p>
          ) : null}
        </div>
      </div>
      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/fleets/settings/drivers" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}

export function FieldStatus({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Inactive", value: "0" },
      { label: "Send Request", value: "1" },
      { label: "Approve/Active", value: "2" },
      { label: "Banned", value: "3" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}
