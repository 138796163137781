import { PackingResource } from "./packing";
import { PickerWorkerlistResource } from "./picker-worklist";
import { PickingResource } from "./picking";
export * from "./Layout";

export const PickpackResource = {
  name: "Pick & Pack",
  description: "Pick & Pack orders",
  path: "/pickpack",
  icon: (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
    >
      <path
        d="M13.9 11.4116C12.2468 9.74016 11.3363 7.51771 11.3363 5.15372V4.65347C11.3363 4.56523 11.303 4.47698 11.2364 4.40978L10.7038 3.8713C9.26534 2.41671 7.35252 1.6158 5.31812 1.6158H4.37906C4.21723 0.698856 3.42467 0 2.47185 0C1.40259 0 0.532761 0.879549 0.532761 1.96047C0.532761 3.04152 1.40259 3.92094 2.47185 3.92094C3.42457 3.92094 4.21724 3.22209 4.37906 2.30515H4.79453V4.52279C4.79453 5.90428 4.2625 7.20305 3.29623 8.18004C3.17839 8.29906 3.16531 8.48348 3.25592 8.61766H0.340914C0.152676 8.61766 0 8.77202 0 8.96233V11.6553C0 11.8456 0.152682 12 0.340914 12H4.60271C4.79095 12 4.94362 11.8456 4.94362 11.6553V8.96233C4.94362 8.77201 4.79094 8.61766 4.60271 8.61766H3.82527C4.89002 7.5163 5.47635 6.06543 5.47635 4.52276V2.30908C7.26929 2.34948 8.94941 3.07239 10.2219 4.35866L10.5193 4.65933C8.88733 6.42985 7.99095 8.72222 7.99095 11.1548V11.6551C7.99095 11.8454 8.14363 11.9998 8.33186 11.9998C8.52009 11.9998 8.67277 11.8454 8.67277 11.6551V11.1548C8.67277 9.08016 9.37448 7.11473 10.6637 5.53463C10.7566 7.93959 11.7251 10.1872 13.418 11.8987C13.4846 11.9661 13.5718 11.9997 13.6591 11.9997C13.7464 11.9997 13.8335 11.966 13.9001 11.8987C14.0333 11.7642 14.0333 11.5459 13.9001 11.4113L13.9 11.4116ZM2.47173 3.23169C1.77849 3.23169 1.21447 2.66148 1.21447 1.96056C1.21447 1.25965 1.77846 0.689438 2.47173 0.689438C3.16501 0.689438 3.72899 1.25965 3.72899 1.96056C3.72899 2.66148 3.16501 3.23169 2.47173 3.23169ZM4.26172 11.3107H0.681809V9.30703H4.26172V11.3107Z"
        fill="currentColor"
      />
    </svg>
  ),
  access: ["read-pickpackmodules"],
  children: [PickingResource, PackingResource, PickerWorkerlistResource],
};
