import { PricingMethodsResource } from "./pricing-methods";

export const SettingsResource = {
  name: "Settings",
  description: "Inventory Settings management module",
  path: "inventory/settings",
  icon: "bi bi-bag",
  access: ["read-pricings"],
  children: [PricingMethodsResource],
};
