import { CheckIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { VehicleThumb } from "../../../../components/appearance";
import { FieldFleetVendor } from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  renderRepairPriorityText,
  VehicleServiceEntry,
} from "../../../../graphql/fleets/vehicles/pages/service-entry";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { formatCurrency, formatDate } from "../../../../utils";

type ServiceEntryDetailProps = {
  serviceEntry: VehicleServiceEntry;
  vehicleBasic: VehicleBasic | null;
};
export function ServiceEntryDetail({
  serviceEntry,
  vehicleBasic,
}: ServiceEntryDetailProps) {
  const { t } = useTranslation();
  const { vehicleId } = useParams<{ vehicleId: string }>();

  return (
    <section className="space-y-4">
      <div className="grid gap-4 md:grid-cols-5">
        <article className="md:col-span-3">
          <h3 className="text-md mb-3 font-medium text-gray-900">Details</h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="space-y-4 md:space-y-6 xl:space-y-8">
              {vehicleBasic ? (
                <li className="grid grid-cols-2 gap-4">
                  <span>Vehicle:</span>
                  <VehicleThumb {...vehicleBasic} />
                </li>
              ) : null}
              <li className="grid grid-cols-2 gap-4">
                <span>Repair Priority Class:</span>
                <span className="text-gray-500">
                  {renderRepairPriorityText(serviceEntry.repairPriority)}
                </span>
              </li>
              <li className="grid grid-cols-2 gap-4">
                <span>Start Date:</span>
                <span className="text-gray-500">
                  {formatDate(serviceEntry.startDate)}
                </span>
              </li>
              <li className="grid grid-cols-2 gap-4">
                <span>Completion Date:</span>
                <span className="text-gray-500">
                  {formatDate(serviceEntry.completionDate)}
                </span>
              </li>
              <li className="grid grid-cols-2 gap-4">
                <span>Odometer:</span>
                <span className="text-gray-500">
                  {serviceEntry.odometer}{" "}
                  {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                </span>
              </li>
              <li className="grid grid-cols-2 gap-4">
                <span>Vendor:</span>
                <FieldFleetVendor
                  title={t("text_fleet_vendor")}
                  value={
                    serviceEntry.fleetVendor
                      ? parseInt(serviceEntry.fleetVendor.id)
                      : null
                  }
                  isPreview
                />
              </li>
              <li className="grid grid-cols-2 gap-4">
                <span>Reference No:</span>
                <span className="text-gray-500">
                  {serviceEntry.referenceNo}
                </span>
              </li>
              <li className="grid grid-cols-2 gap-4">
                <span>Notes:</span>
                <span className="text-gray-500">
                  {serviceEntry.generalNotes}
                </span>
              </li>
            </ul>
          </div>
        </article>
        <article className="md:col-span-2">
          <h3 className="text-md mb-3 font-medium text-gray-900">
            Resolved Issues
          </h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            {serviceEntry.serviceIssues.length ? (
              <ul>
                {serviceEntry.serviceIssues.map((item) => (
                  <li key={item.id} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-primary-700"
                      aria-hidden="true"
                    />
                    <div>
                      <Link
                        to={
                          vehicleId === undefined
                            ? `/fleets/vehicles/issues/${item.id}`
                            : `/fleets/vehicles/${vehicleId}/issue/${item.id}`
                        }
                        className="mr-2 text-primary-700 transition-all hover:text-primary-900"
                      >
                        #{item.id}
                      </Link>
                      {item.summary}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="py-10 text-center md:py-16 xl:py-20">
                No Resolved Issues for this Service Entry
              </div>
            )}
          </div>
        </article>
      </div>
      <article>
        <h3 className="text-md mb-3 font-medium text-gray-900">Line Items</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <div className="grid grid-cols-3 rounded-lg border border-gray-300 text-base font-medium">
            <div className="border-r border-gray-300 px-4 py-3">
              <div>Labor</div>
              <div className="mt-1">
                {formatCurrency(serviceEntry.totalLaborPrice ?? 0)}
              </div>
            </div>
            <div className="border-r border-gray-300 px-4 py-3">
              <div>Parts</div>
              <div className="mt-1">
                {formatCurrency(serviceEntry.totalPartsPrice ?? 0)}
              </div>
            </div>
            <div className="px-4 py-3">
              <div>Total</div>
              <div className="mt-1">
                {formatCurrency(serviceEntry.total ?? 0)}
              </div>
            </div>
          </div>

          <table className="mt-4 min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                >
                  {t("text_item")}
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-900 sm:table-cell"
                >
                  {t("text_labor")}
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                >
                  {t("text_parts")}
                </th>
                <th
                  scope="col"
                  className="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium text-gray-900 sm:pr-0"
                >
                  {t("text_sub_total")}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {serviceEntry.lineItems.map((item, index) => {
                return (
                  <Fragment key={item.id}>
                    <tr className="border-t border-gray-200">
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 align-top text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                        <div className="font-normal text-gray-900">
                          {item?.serviceRemainder.vehicleServiceTask.name}
                        </div>
                      </td>
                      <td className="px-3 py-4 align-top text-sm">
                        {formatCurrency(item.laborPrice ?? 0)}
                      </td>
                      <td className="px-3 py-4 align-top text-sm">
                        {formatCurrency(item.partsPrice ?? 0)}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right align-top text-sm font-normal sm:pr-0">
                        <span className="text-gray-900">
                          {formatCurrency(item.subtotal ?? 0)}
                        </span>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
          <div className="mt-4 flex justify-end gap-4">
            <div className="w-96 max-w-full space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="text-sm text-gray-700">
                  {t("text_total_labor_price")}
                </div>
                <div className="text-right text-sm text-gray-700">
                  {formatCurrency(serviceEntry.totalLaborPrice ?? 0)}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="text-sm text-gray-700">
                  {t("text_total_parts_price")}
                </div>
                <div className="text-right text-sm text-gray-700">
                  {formatCurrency(serviceEntry.totalPartsPrice ?? 0)}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="text-sm text-gray-700">
                  {t("text_subtotal")}
                </div>
                <div className="text-right text-sm text-gray-700">
                  {formatCurrency(serviceEntry.subtotal ?? 0)}
                </div>
              </div>
              <hr className="border-t border-gray-300" />
              <div className="grid grid-cols-2 gap-4 text-lg font-medium text-gray-900">
                <div>{t("text_total")}</div>
                <div className="text-right">
                  {formatCurrency(serviceEntry.total ?? 0)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}
