import { useMutation, useQuery } from "@apollo/client/react";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  Customer,
  CustomerType,
  EDIT_CUSTOMER,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMERS,
  TradingAddress,
} from "../../../graphql/sales/customers";
import Form from "./components/Form";

const CustomerUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { customerId } = useParams<{ customerId: string }>();
  const navigate = useNavigate();

  const [newOrder, setNewOrder] = useState(false);

  const { data, loading, error } = useQuery<{
    fetchCustomer: Customer;
  }>(GET_CUSTOMER_BY_ID, {
    variables: {
      id: customerId,
    },
  });
  const customer = useMemo<Customer | undefined>(
    () => data?.fetchCustomer,
    [data]
  );

  const [updateCustomer] = useMutation(EDIT_CUSTOMER, {
    refetchQueries: [
      { query: GET_CUSTOMER_BY_ID, variables: { id: customerId } },
      { query: GET_CUSTOMERS },
    ],
    awaitRefetchQueries: true,
  });

  const handleNewOrder = useCallback((status: boolean) => {
    setNewOrder(status);
  }, []);

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateCustomer({
      variables: {
        id: customerId,
        customerName:
          values.customerType === CustomerType.COMPANY
            ? values.companyName
            : values.customerName,
        companyName:
          values.customerType === CustomerType.COMPANY
            ? values.companyName
            : values.customerName,
        profileImageUrl: values.profileImageUrl,
        customerType: values.customerType,
        email: values.email,
        storeContact: values.storeContact,
        storeEmail: values.storeEmail,
        storePhoneNumber: values.storePhoneNumber,
        storeFax: values.storeFax,
        abnNo: values.abnNo,
        customerPlu: values.customerPlu,
        packingInstruction: values.packingInstruction,
        deliveryInstruction: values.deliveryInstruction,
        salesRepId: values.salesRep ? parseInt(values.salesRep.value) : null,
        deliveryRunId: values.deliveryRun
          ? parseInt(values.deliveryRun.value)
          : null,
        creditApplicationStatus: values.creditApplicationStatus,
        creditLimit: values.creditLimit,
        customerGroupIds: values.customerGroups
          ? values.customerGroups.map((item: any) => parseInt(item.value))
          : [],
        tradingAddresses:
          values.tradingAddresses.map((a: TradingAddress) => ({
            address: a.address,
            suburb: a.suburb,
            state: a.state,
            postcode: a.postcode,
            primaryAddress: a.primaryAddress,
          })) ?? [],
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={CustomerUpdateResource.name}
        heading="Customers"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: CustomerUpdateResource.name,
            href: null,
          },
        ]}
      />
      {loading || !customer ? (
        <Waiting />
      ) : (
        <Form
          initialValues={{
            customerCode: customer.customerCode ?? "",
            customerName: customer.customerName ?? "",
            companyName: customer.companyName ?? "",
            profileImageUrl: customer.profileImageUrl ?? "",
            customerType: customer.customerType ?? 1,
            email: customer.email ?? "",
            storeContact: customer.storeContact ?? "",
            storeEmail: customer.storeEmail ?? "",
            storePhoneNumber: customer.storePhoneNumber ?? "",
            storeFax: customer.storeFax ?? "",
            abnNo: customer.abnNo ?? "",
            customerPlu: customer.customerPlu ?? "",
            packingInstruction: customer.packingInstruction ?? "",
            deliveryInstruction: customer.deliveryInstruction ?? "",
            salesRep: customer.salesRep
              ? {
                  label: customer.salesRep?.fullName,
                  value: customer.salesRep?.id,
                }
              : null,
            deliveryRun: customer.deliveryRuns
              ? {
                  label: customer.deliveryRuns.name,
                  value: customer.deliveryRuns.id,
                }
              : null,
            creditApplicationStatus: customer.creditApplicationStatus ?? false,
            creditLimit: customer.creditLimit ?? 0,
            customerGroups: customer.customerGroups
              ? customer.customerGroups.map((item: any) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })
              : [],
            tradingAddresses: customer.tradingAddresses
              ? customer.tradingAddresses.map((item: any) => {
                  return {
                    id: item.id ?? "",
                    address: item.address ?? "",
                    suburb: item.suburb ?? "",
                    state: item.state ?? "",
                    postcode: item.postcode ?? "",
                    primaryAddress: item.primaryAddress ?? false,
                  };
                })
              : [],
          }}
          customerStatus={customer.status}
          customerContactId={
            customer.customerContact.length
              ? customer.customerContact[0].id
              : null
          }
          onSubmit={handleSubmit}
          newOrder={newOrder}
          handleNewOrder={handleNewOrder}
        />
      )}
    </>
  );
};

export default CustomerUpdate;
export const CustomerUpdateResource: ResourceProps = {
  name: "Edit Customer",
  description: "Update an existing customer",
  access: ["update-customers"],
  path: "customers/:customerId",
};
