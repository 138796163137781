import { useTranslation } from "react-i18next";

import { VehicleThumb, Watchers } from "../../../../components/appearance";
import { FieldDriver } from "../../../../components/form";
import { renderStatusBoolean } from "../../../../components/render";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  getDateByTimeInterval,
  renderStatus,
  renderTimeIntervalUnit,
  VehicleServiceReminder,
} from "../../../../graphql/fleets/vehicles/pages/service-reminder";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { formatDate } from "../../../../utils";

type ServiceReminderDetailProps = {
  serviceReminder: VehicleServiceReminder;
  vehicleBasic: VehicleBasic | null;
};
export function ServiceReminderDetail({
  serviceReminder,
  vehicleBasic,
}: ServiceReminderDetailProps) {
  const { t } = useTranslation();

  return (
    <section className="space-y-4">
      <article>
        <h3 className="text-md mb-3 font-medium text-gray-900">Details</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="space-y-4 md:space-y-6 xl:space-y-8">
            {vehicleBasic ? (
              <li className="grid grid-cols-2 gap-4">
                <span>Vehicle:</span>
                <VehicleThumb {...vehicleBasic}>
                  <div className="mt-0.5 text-sm text-gray-700">
                    {serviceReminder.vehicle.currentMeter}{" "}
                    {renderPrimaryMeter(vehicleBasic.primaryMeter)}
                  </div>
                </VehicleThumb>
              </li>
            ) : null}
            <li className="grid grid-cols-2 gap-4">
              <span>Operator:</span>
              {serviceReminder.vehicle.operator?.id ? (
                <div>
                  <FieldDriver
                    title="Driver"
                    value={parseInt(serviceReminder.vehicle.operator.id)}
                    isPreview
                  />
                </div>
              ) : (
                <span>-</span>
              )}
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Status:</span>
              <span className="text-gray-500">
                {renderStatus(serviceReminder?.status)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Service Task:</span>
              <span className="text-gray-500">
                {serviceReminder.vehicleServiceTask.name}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Next Due:</span>
              <span className="text-gray-500">
                {formatDate(serviceReminder.nextDueDate)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Last Completed:</span>
              <span className="text-gray-500">
                {formatDate(serviceReminder.lastCompletionDate)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Time Interval:</span>
              <span className="text-gray-500">
                {serviceReminder.timeInterval}{" "}
                {renderTimeIntervalUnit(serviceReminder?.timeIntervalUnit)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>{t("text_remainder_type")}:</span>
              <span className="text-gray-500">
                {serviceReminder.remainderType ? "Yes" : "No"}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Time Due Soon Threshold:</span>
              <span className="text-gray-500">
                {serviceReminder.timeDueSoonThreshold}{" "}
                {renderTimeIntervalUnit(
                  serviceReminder?.timeDueSoonThresholdUnit
                )}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Notification:</span>
              <span className="text-gray-500">
                {renderStatusBoolean(serviceReminder.notification)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Watchers:</span>
              <div className="flex flex-wrap items-center">
                <Watchers
                  caption={`Watchers of ${vehicleBasic?.name} service reminder - ${serviceReminder.vehicleServiceTask.name}`}
                  data={serviceReminder.watchers}
                />
              </div>
            </li>
          </ul>
        </div>
      </article>
      <article>
        <h3 className="text-md mb-3 font-medium text-gray-900">Next Due</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="space-y-4 md:space-y-6 xl:space-y-8">
            <li className="grid grid-cols-2 gap-4">
              <span>Scheduled Date:</span>
              <span className="text-gray-500">
                {formatDate(serviceReminder.nextDueDate)}
              </span>
            </li>
            <li className="grid grid-cols-2 gap-4">
              <span>Upcoming:</span>
              <span className="text-gray-500 first-letter:uppercase">
                {serviceReminder.timeInterval
                  ? getDateByTimeInterval(
                      serviceReminder.timeInterval,
                      serviceReminder.timeIntervalUnit
                    ).toRelative()
                  : null}{" "}
                from now
              </span>
            </li>
          </ul>
        </div>
      </article>
    </section>
  );
}
