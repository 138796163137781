import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Dialog, Menu, Switch, Tab, Transition } from "@headlessui/react";
import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  PencilIcon,
  TicketIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { FormikProps, useFormik } from "formik";
import { nanoid } from "nanoid";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";

import { Logo } from "../../../../animations";
import {
  Button,
  Field,
  FieldAddress,
  FieldImage,
  FieldState,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import { GET_DELIVERYRUNS } from "../../../../graphql/fleets/settings/delivery-runs";
import { PreviewProduct } from "../../../../graphql/inventory/products";
import { GET_CUSTOMERGROUPS } from "../../../../graphql/sales/customer-groups";
import {
  CustomerType,
  FETCH_ABN_DETAILS,
  formatAbnDetails,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_PRICINGSTRUCTURES,
  GET_CUSTOMER_TRADINGADDRESSES,
  GET_CUSTOMERS,
  IAbnDetails,
  IFetchAbnDetails,
  PreviewCustomer,
  REMOVE_CUSTOMER,
  SEND_ACCOUNT_INVITATION,
  UPDATE_CUSTOMER_STATUS,
} from "../../../../graphql/sales/customers";
import { GET_USERS } from "../../../../graphql/user-management/users";
import { AlertType, useAlert } from "../../../../hooks/useAlert";
import { arrayMove, classNames, orderOptions, toSlug } from "../../../../utils";
import { isAdmin } from "../../../auth";
import FormStructure from "./FormStructure";
import Orders from "./Orders";

type User = {
  id: string;
  fullName: string;
  email: string;
  roles: {
    roleName: string;
  }[];
  createdAt: string;
  status: boolean;
};

type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: Customer[];
  createdAt: string;
  status: boolean;
};

type DeliveryRun = {
  id: string;
  name: string;
  dock: {
    id: string;
    name: string;
  };
  createdAt: string;
  status: boolean;
};

type TradingAddresses = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  primaryAddress: boolean;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  handle: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: PreviewProduct[];
  customers: PreviewCustomer[];
  status: boolean;
};

type Customer = {
  id?: string;
  customerCode: string;
  customerName: string;
  companyName: string;
  profileImageUrl: string;
  customerType: number;
  email: string;
  storeContact: string;
  storeEmail: string;
  storePhoneNumber: string;
  storeFax: string;
  abnNo: string;
  customerPlu: string;
  packingInstruction: string;
  deliveryInstruction: string;
  salesRep: SingleValue<OptionProps>;
  deliveryRun: SingleValue<OptionProps>;
  creditApplicationStatus: boolean;
  creditLimit: number;
  customerGroups: MultiValue<OptionProps>;
  tradingAddresses: TradingAddresses[];
};

type AddressOption = TradingAddresses & {
  id: string;
};

const CREATE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureCreate(
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureCreate(
      input: {
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        productType
        structureProducts {
          id
          name
          featureImageUrl
          status
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

const UPDATE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $productIds: [Int!]
    $customerIds: [Int!]
    $status: Boolean!
  ) {
    pricingStructureUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          productIds: $productIds
          customerIds: $customerIds
          status: $status
        }
      }
    ) {
      pricingStructure {
        id
        name
        pricingMethod {
          id
          name
        }
        productType
        structureProducts {
          id
          name
          featureImageUrl
          status
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;
const DELETE_PRICINGSTRUCTURE = gql`
  mutation PricingStructureDelete($id: ID!) {
    pricingStructureDelete(input: { id: $id }) {
      pricingStructure {
        id
        name
      }
    }
  }
`;

const DELETE_PRODUCTPRICING = gql`
  mutation PricingStructurePricingDelete(
    $productId: Int!
    $pricingStructureId: Int!
  ) {
    pricingStructurePricingDelete(
      input: { productId: $productId, pricingStructureId: $pricingStructureId }
    ) {
      message
    }
  }
`;

const CHECK_STRUCTURERELATION_CUSTOMERS = gql`
  query CheckStructureRelationCustomers(
    $productIds: [Int!]
    $customerIds: [Int!]
    $pricingStructureIds: [Int!]
  ) {
    checkStructureRelation(
      productIds: $productIds
      customerIds: $customerIds
      pricingStructureIds: $pricingStructureIds
    ) {
      customerId
      customerName
      productId
      productName
    }
  }
`;

type Occupied = {
  customerId: number;
  customerName: string;
  productId: number;
  productName: string;
};

export default function Form({
  initialValues,
  onSubmit,
  newOrder,
  handleNewOrder,
  customerStatus,
  customerContactId,
}: {
  initialValues: Customer;
  customerStatus: number;
  onSubmit: (values: any, actions: any) => void;
  newOrder?: boolean;
  handleNewOrder?: (status: boolean) => void;
  customerContactId?: string | null;
}) {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const refTab = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const { addNotify } = useNotifyContext();

  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);

  const [activePricingStructure, setActivePricingStructure] = useState<
    PricingStructure | undefined
  >(undefined);
  const [newPricingStructure, setNewPricingStructure] = useState<
    boolean | undefined
  >(undefined);

  const [loadingAbn, setLoadingAbn] = useState(false);
  const [abnDetails, setAbnDetails] = useState<IAbnDetails | null>(null);

  const [fetchAbnDetails] = useLazyQuery<{
    fetchAbnDetails: IFetchAbnDetails;
  }>(FETCH_ABN_DETAILS);

  const [customerStatusUpdate] = useMutation(UPDATE_CUSTOMER_STATUS, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_BY_ID,
        variables: {
          id: customerId,
        },
      },
      { query: GET_CUSTOMERS },
    ],
    awaitRefetchQueries: true,
  });

  const [sendAccountInvitation] = useMutation(SEND_ACCOUNT_INVITATION, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_BY_ID,
        variables: {
          id: customerId,
        },
      },
      { query: GET_CUSTOMERS },
    ],
    awaitRefetchQueries: true,
  });

  const updateCustomerStatus = useCallback(
    (status: number) => {
      if (!customerId) return;
      customerStatusUpdate({
        variables: {
          id: customerId,
          status,
        },
      })
        .then(({ data }) => {
          if (data?.customerStatusUpdate) {
            addNotify({
              type: NotifyType.SUCCESS,
              message: data?.customerStatusUpdate?.message,
            });
            return navigate(0);
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            message: error.message,
          });
        });
    },
    [customerStatusUpdate, addNotify, navigate, customerId]
  );

  const inviteCustomer = useCallback(() => {
    if (!customerId) return;

    sendAccountInvitation({
      variables: {
        id: customerId,
      },
    })
      .then(({ data }) => {
        if (data?.sendAccountInvitation) {
          addNotify({
            type: NotifyType.SUCCESS,
            message: data?.sendAccountInvitation?.message,
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          message: error.message,
        });
      });
  }, [customerId, sendAccountInvitation, addNotify]);

  const [deleteCustomer] = useMutation(REMOVE_CUSTOMER, {
    refetchQueries: [{ query: GET_CUSTOMERS }],
    awaitRefetchQueries: true,
  });
  const handleCustomerDelete = useCallback(() => {
    if (!customerId) return;
    deleteCustomer({
      variables: {
        id: customerId,
      },
    })
      .then(({ data }) => {
        if (data?.customerDelete) {
          const deletedCustomer = data?.customerDelete?.customer;
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Customer ${deletedCustomer.customerName} deleted`,
          });
          return navigate("/sales/customers");
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer delete failed",
          message: error.message,
        });
      });
  }, [deleteCustomer, customerId, addNotify, navigate]);

  const [occupiedProductIds, setOccupiedProductIds] = useState<number[]>([]);

  const [checkStructureRelationCustomers] = useLazyQuery(
    CHECK_STRUCTURERELATION_CUSTOMERS
  );
  const didFetchStructures = useRef(false);

  const [fetchTradingAddresses] = useLazyQuery<{
    fetchCustomer: {
      tradingAddresses: AddressOption[];
    };
  }>(GET_CUSTOMER_TRADINGADDRESSES);

  const {
    data: dataCustomerPricingStructures,
    refetch: refetchPricingStructures,
  } = useQuery<{
    fetchCustomer: {
      pricingStructures: (Omit<PricingStructure, "products"> & {
        structureProducts: PricingStructure["products"];
      })[];
    };
  }>(GET_CUSTOMER_PRICINGSTRUCTURES, {
    variables: {
      id: customerId,
    },
    onCompleted(data) {
      if (data?.fetchCustomer?.pricingStructures) {
        const pricingStructureIds = pricingStructures.map((structure) =>
          Number(structure.id)
        );

        checkStructureRelationCustomers({
          variables: {
            productIds: [],
            customerIds: [Number(customerId)],
            pricingStructureIds,
          },
        }).then(({ data }) => {
          if (data?.checkStructureRelation) {
            const updatedOccupiedProductIds =
              data?.checkStructureRelation?.flatMap(
                (csr: Occupied) => csr.productId
              ) || [];
            setOccupiedProductIds(updatedOccupiedProductIds);
          }
        });
      }
    },
  });

  const pricingStructures: PricingStructure[] =
    dataCustomerPricingStructures?.fetchCustomer?.pricingStructures?.map(
      (s) => ({
        ...s,
        products: s.structureProducts,
      })
    ) || [];

  const [createPricingStructure] = useMutation(CREATE_PRICINGSTRUCTURE);
  const [deletePricingStructure] = useMutation(DELETE_PRICINGSTRUCTURE);
  const [updatePricingStructure] = useMutation(UPDATE_PRICINGSTRUCTURE);
  const [deleteProductPricing] = useMutation(DELETE_PRODUCTPRICING);

  const fetchStructures = useCallback(async () => {
    if (!customerId) return;

    const { data: dataCustomerAddresses } = await fetchTradingAddresses({
      variables: {
        id: parseInt(customerId),
      },
    });

    const updatedAddressOptions = dataCustomerAddresses?.fetchCustomer
      ?.tradingAddresses || [
      {
        id: nanoid(11),
        address: "",
        suburb: "",
        state: "",
        postcode: "",
        primaryAddress: false,
      },
    ];
    setAddressOptions(updatedAddressOptions);
  }, [customerId, fetchTradingAddresses]);

  useEffect(() => {
    if (!didFetchStructures.current) {
      fetchStructures();
      didFetchStructures.current = true;
    }
  }, [fetchStructures]);

  const customerSchema = Yup.object().shape({
    customerType: Yup.number().required("Required"),
    customerCode: Yup.string().nullable(),
    customerName: Yup.string().when("customerType", {
      is: (val: number) => val === CustomerType.INDIVIDUAL,
      then: (schema) =>
        schema.min(2, "Too Short!").max(80, "Too Long!").required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    companyName: Yup.string().when("customerType", {
      is: (val: number) => val === CustomerType.COMPANY,
      then: (schema) =>
        schema.min(2, "Too Short!").max(80, "Too Long!").required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    profileImageUrl: Yup.string(),
    email: Yup.string().email("Invalid email").required("Required"),
    storeContact: Yup.string(),
    storeEmail: Yup.string().email("Invalid email"),
    storePhoneNumber: Yup.string(),
    storeFax: Yup.string(),
    abnNo: Yup.string().nullable(),
    customerPlu: Yup.string(),
    packingInstruction: Yup.string().nullable(),
    deliveryInstruction: Yup.string().nullable(),
    salesRep: Yup.object().nullable().required("Required"),
    deliveryRun: Yup.object().nullable().required("Required"),
    creditApplicationStatus: Yup.boolean().required("Required"),
    creditLimit: Yup.number().required("Required"),
    customerGroups: Yup.array().required("Required"),
    tradingAddresses: Yup.array(
      Yup.object().shape({
        address: Yup.string().required("Required"),
        suburb: Yup.string().required("Required"),
        state: Yup.string().required("Required"),
        postcode: Yup.string().required("Required"),
        primaryAddress: Yup.boolean().required("Required"),
      })
    ).notRequired(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: customerSchema,
    onSubmit: onSubmit,
  });

  const fetchingAbnDetails = useCallback(
    (abnNo: string) => {
      setLoadingAbn(true);
      fetchAbnDetails({
        variables: {
          abnNo,
        },
      })
        .then(({ data }) => {
          if (data?.fetchAbnDetails?.details) {
            if (data?.fetchAbnDetails?.details?.businessEntity201205) {
              const formated = formatAbnDetails(data?.fetchAbnDetails);
              setAbnDetails(formated);
            } else {
              setAbnDetails(null);
            }
          } else {
            setAbnDetails(null);
          }
        })
        .catch((e) => {
          setAbnDetails(null);
        })
        .finally(() => {
          setLoadingAbn(false);
        });
    },
    [fetchAbnDetails]
  );

  useEffect(() => {
    if (formik.values.abnNo.length < 11) {
      setAbnDetails(null);
      return;
    }
    fetchingAbnDetails(formik.values.abnNo);
  }, [fetchingAbnDetails, formik.values.abnNo]);

  const { errors, touched } = formik;

  const handleCreateStructure = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!customerId) return;

    createPricingStructure({
      variables: {
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        productType: values.productType,
        productIds: values.productIds,
        customerIds: [parseInt(customerId)],
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingStructureCreate) {
          // const { pricingStructure } = data.pricingStructureCreate;
          // const newStructure = {
          //   id: pricingStructure.id,
          //   name: pricingStructure.name,
          //   pricingMethod: pricingStructure.pricingMethod,
          //   productType: pricingStructure.productType,
          //   products: pricingStructure.structureProducts,
          //   customers: pricingStructure.customers,
          //   status: pricingStructure.status,
          // };
          // const newStructures = [...pricingStructures, newStructure];
          // setPricingStructures(newStructures);
          await refetchPricingStructures();
          setNewPricingStructure(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Structure created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure create failed",
          message: error.message,
        });
      });
  };

  const handleUpdateStructure = async (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!customerId || !activePricingStructure) return;

    const oldProductIds = activePricingStructure.products.flatMap((p) =>
      Number(p.id)
    );
    const newProductIds = values.productIds;
    const addedIds = newProductIds.filter(
      (productId: number) => !oldProductIds.includes(productId)
    );
    const removedIds = oldProductIds.filter(
      (productId: number) => !newProductIds.includes(productId)
    );

    for (const productId of removedIds) {
      await deleteProductPricing({
        variables: {
          productId: productId,
          pricingStructureId: Number(activePricingStructure.id),
        },
      });
    }

    updatePricingStructure({
      variables: {
        id: activePricingStructure.id,
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        productType: values.productType,
        productIds: addedIds,
        customerIds: [parseInt(customerId)],
        status: values.status,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingStructureUpdate) {
          // const { pricingStructure } = data.pricingStructureUpdate;
          // const newStructures = [...pricingStructures];
          // const index = newStructures.findIndex(
          //   (structure) => structure.id === activePricingStructure.id
          // );
          // const newStructure = {
          //   id: pricingStructure.id,
          //   name: pricingStructure.name,
          //   pricingMethod: pricingStructure.pricingMethod,
          //   productType: pricingStructure.productType,
          //   products: pricingStructure.structureProducts,
          //   customers: pricingStructure.customers,
          //   status: pricingStructure.status,
          // };

          // newStructures[index] = newStructure;
          // setPricingStructures(newStructures);
          await refetchPricingStructures();
          setActivePricingStructure(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Structure updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure update failed",
          message: error.message,
        });
      });
  };

  const handleDeleteStructure = async (pricingStructureId: string) => {
    deletePricingStructure({
      variables: {
        id: pricingStructureId,
      },
    })
      .then(async ({ data }) => {
        if (data?.pricingStructureDelete) {
          const { pricingStructure } = data.pricingStructureDelete;
          // const newStructures = [...pricingStructures];
          // const index = newStructures.findIndex(
          //   (structure) => structure.id === pricingStructureId
          // );
          // newStructures.splice(index, 1);
          // setPricingStructures(newStructures);
          await refetchPricingStructures();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Structure ${pricingStructure.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Structure deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Structure deletion failed",
          message: error.message,
        });
      });
  };

  const [warning, setWarning] = useState(false);

  const Message = useAlert({
    open: warning,
    title: "Under Development",
    message: "This area is under development and will be available soon.",
    type: AlertType.SUCCESS,
    delay: 3000,
    onDismiss: () => {
      setWarning(false);
    },
  });

  return (
    <>
      <Message />
      <form onSubmit={formik.handleSubmit}>
        {customerId !== undefined ? (
          <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_customer")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_customer")}
              </p>
            </div>
            <div className="mt-4 flex flex-wrap sm:ml-16 sm:mt-0">
              <Link to="/sales/customers" className="mr-2 flex">
                <Button variant="secondary">{t("text_cancel")}</Button>
              </Link>
              <Button type="submit" className="mr-2">
                {t("text_update")}
              </Button>
              <div className="inline-flex rounded-md">
                <Menu as="div" className="relative -ml-px flex">
                  <Menu.Button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500">
                    <span>{t("text_more_options")}</span>
                    <ChevronDownIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 -mr-1 mt-12 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {customerContactId ? (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href={`/sales/settings/customer-contacts/${customerContactId}`}
                                target="_blank"
                                className={classNames(
                                  active
                                    ? "bg-primary-700 text-white"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                                rel="noreferrer"
                              >
                                {t("text_view_contact_details")}
                              </a>
                            )}
                          </Menu.Item>
                        ) : null}

                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={`/sales/customers/${customerId}/products`}
                              target="_blank"
                              className={classNames(
                                active
                                  ? "bg-primary-700 text-white"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                              rel="noreferrer"
                            >
                              {t("text_view_product_pricing")}
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Button
                          className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-primary-700 hover:text-white"
                          onClick={() => {
                            setSelectedTab(2);
                            refTab.current &&
                              refTab.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            setTimeout(() => {
                              handleNewOrder && handleNewOrder(true);
                            }, 1000);
                          }}
                        >
                          {t("text_create_order")}
                        </Menu.Button>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_customer")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_customer")}
              </p>
            </div>
            <div className="mt-4 flex sm:ml-16 sm:mt-0">
              <Link to="/sales/customers" className="mr-2 flex">
                <Button variant="secondary">{t("text_cancel")}</Button>
              </Link>
              <Button type="submit">{t("text_create")}</Button>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
          <div className="space-y-4 md:col-span-8">
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              <div className="grid grid-cols-12 gap-6">
                {formik.values.customerType === CustomerType.COMPANY ? (
                  <Fragment>
                    <div className="col-span-12 sm:col-span-12">
                      <Field
                        title={t("text_company_name")}
                        name="companyName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyName}
                        touched={touched.companyName}
                        errors={errors.companyName}
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <Field
                        title={t("text_abn_no")}
                        name="abnNo"
                        onChange={(e) => {
                          const { value } = e.target;
                          const updatedValue = value.replace(/[^0-9]/g, "");
                          formik.setFieldValue("abnNo", updatedValue);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.abnNo}
                        touched={touched.abnNo}
                        errors={errors.abnNo}
                      />
                      {loadingAbn ? (
                        <div className="mt-4 text-center">
                          <Logo />
                        </div>
                      ) : abnDetails ? (
                        <div className="mt-4 rounded-lg bg-white p-2 text-sm text-primary-900 md:p-4">
                          <ul className="space-y-2">
                            <li>
                              <b>{abnDetails.name}</b>
                            </li>
                            <li>
                              ABN status: Active from {abnDetails.activeFrom}
                            </li>
                            <li>Entity type: {abnDetails.type}</li>
                            <li>
                              Goods & Services Tax (GST): Registered from{" "}
                              {abnDetails.registeredFrom}
                            </li>
                            <li>
                              Main business location: {abnDetails.address}
                            </li>

                            <li className="flex flex-wrap">
                              <span className="mr-2">Buiness names:</span>
                              <Fragment>
                                {abnDetails.business.map((b) => (
                                  <span
                                    key={`b-${toSlug(b)}`}
                                    className="mb-1 mr-1 inline-flex items-center rounded-full bg-primary-700 pl-2 pr-3 text-xs font-normal leading-6 text-white"
                                  >
                                    <span className="mr-1 h-3 w-3 rounded-full bg-white"></span>
                                    {b}
                                  </span>
                                ))}
                              </Fragment>
                            </li>
                          </ul>
                        </div>
                      ) : formik.values.abnNo.length === 11 ? (
                        <div className="text-center">
                          <span className="mt-4 inline-block text-sm text-red-500">
                            <sup>*</sup> {t("text_abn_no_info")}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </Fragment>
                ) : (
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_customer_name")}
                      name="customerName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.customerName}
                      touched={touched.customerName}
                      errors={errors.customerName}
                    />
                  </div>
                )}
                {typeof customerId !== "undefined" ? (
                  <div className="col-span-12 sm:col-span-6">
                    <Field
                      title={t("text_customer_code")}
                      name="customerCode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.customerCode}
                      touched={touched.customerCode}
                      errors={errors.customerCode}
                      disabled
                    />
                  </div>
                ) : null}
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_email")}
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    touched={touched.email}
                    errors={errors.email}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_contact")}
                    name="storeContact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeContact}
                    touched={touched.storeContact}
                    errors={errors.storeContact}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_email")}
                    name="storeEmail"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeEmail}
                    touched={touched.storeEmail}
                    errors={errors.storeEmail}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_phone_number")}
                    name="storePhoneNumber"
                    type="tel"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storePhoneNumber}
                    touched={touched.storePhoneNumber}
                    errors={errors.storePhoneNumber}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_store_fax")}
                    name="storeFax"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeFax}
                    touched={touched.storeFax}
                    errors={errors.storeFax}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_customer_plu")}
                    name="customerPlu"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerPlu}
                    touched={touched.customerPlu}
                    errors={errors.customerPlu}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_credit_limit")}
                    name="creditLimit"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.creditLimit}
                    touched={touched.creditLimit}
                    errors={errors.creditLimit}
                  />
                </div>
                <div className="col-span-12 sm:col-span-12">
                  <Switch.Group as="div" className="inline-flex items-center">
                    <Switch
                      checked={formik.values.creditApplicationStatus}
                      onChange={() => {
                        formik.setFieldValue(
                          "creditApplicationStatus",
                          !formik.values.creditApplicationStatus
                        );
                      }}
                      id="creditApplicationStatus"
                      className={classNames(
                        formik.values.creditApplicationStatus
                          ? "bg-primary-600"
                          : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          formik.values.creditApplicationStatus
                            ? "translate-x-5"
                            : "translate-x-0",
                          "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <Switch.Label
                      passive
                      htmlFor="creditApplicationStatus"
                      className="mb-0 ml-2 block text-sm font-normal text-gray-900"
                    >
                      {t("text_credit_application_status")}
                    </Switch.Label>
                  </Switch.Group>
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_packing_instruction")}
                    name="packingInstruction"
                    type="textarea"
                    className="h-24"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.packingInstruction}
                    touched={touched.packingInstruction}
                    errors={errors.packingInstruction}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_delivery_instruction")}
                    name="deliveryInstruction"
                    type="textarea"
                    className="h-24"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.deliveryInstruction}
                    touched={touched.deliveryInstruction}
                    errors={errors.deliveryInstruction}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 space-y-4">
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              {customerId && customerStatus === 4 ? (
                <fieldset className="mb-4">
                  <label className="block text-sm font-medium text-gray-900">
                    {t("text_send_invitation")}
                  </label>
                  <div className="mt-1">
                    <button
                      onClick={() => {
                        inviteCustomer();
                      }}
                      className="w-full whitespace-nowrap rounded-md border border-blue-500 bg-transparent px-4 py-2.5 text-center text-sm font-medium text-blue-500 transition hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-40"
                    >
                      {t("text_send_invitation")}
                    </button>
                  </div>
                </fieldset>
              ) : null}
              {customerId && customerStatus === 1 ? (
                <fieldset className="mb-4">
                  <label className="block text-sm font-medium text-gray-900">
                    {t("text_approve_reject")}
                  </label>
                  <div className="mt-1 grid grid-cols-2 gap-4">
                    <Button
                      variant="info"
                      onClick={() => {
                        updateCustomerStatus(2);
                      }}
                    >
                      {t("text_approve")}
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        updateCustomerStatus(3);
                      }}
                    >
                      {t("text_reject")}
                    </Button>
                  </div>
                </fieldset>
              ) : null}

              <fieldset>
                <FieldImage
                  title={t("text_photo")}
                  onChange={(value) => {
                    formik.setFieldValue("profileImageUrl", value);
                  }}
                  value={formik.values.profileImageUrl}
                  touched={touched.profileImageUrl}
                  errors={errors.profileImageUrl}
                />
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_customer_type")}
                </label>
                <FieldCustomerType
                  value={formik.values.customerType}
                  onChange={(value) => {
                    formik.setFieldValue("customerType", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.customerType && formik.errors.customerType
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.customerType && formik.errors.customerType ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.customerType.toString()}
                  </p>
                ) : null}
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_sales_rep")}
                </label>
                <FieldSalesRep
                  value={formik.values.salesRep}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("salesRep", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.salesRep && formik.errors.salesRep
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.salesRep && formik.errors.salesRep ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.salesRep.toString()}
                  </p>
                ) : null}
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_delivery_run")}
                </label>
                <FieldDeliveryRuns
                  value={formik.values.deliveryRun}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue("deliveryRun", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.deliveryRun && formik.errors.deliveryRun
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {formik.touched.deliveryRun && formik.errors.deliveryRun ? (
                  <p className="mt-2 text-sm text-red-600" id="roles-errors">
                    {formik.errors.deliveryRun.toString()}
                  </p>
                ) : null}
              </fieldset>

              <fieldset className="mt-4">
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_customer_groups")}
                </label>
                <FieldCustomerGroups
                  value={formik.values.customerGroups}
                  // fixedValue={formik.values.autoCategories?.flatMap(
                  //   (ac) => ac.id
                  // )}
                  onChange={(value: MultiValue<OptionProps>) => {
                    formik.setFieldValue("customerGroups", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.customerGroups &&
                      formik.errors.customerGroups
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {/* {formik.values.autoCategories?.length ? (
                <p className="mt-2 space-x-4 text-sm text-gray-500">
                  Default:{" "}
                  {formik.values.autoCategories
                    ?.flatMap((ac) => ac.name)
                    .map((name) => (
                      <span className="rounded-md border border-gray-300 bg-white py-0.5 text-xs px-1">
                        {name}
                      </span>
                    ))}
                </p>
              ) : null} */}
                {formik.touched.customerGroups &&
                formik.errors.customerGroups ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="customerGroups-errors"
                  >
                    {formik.errors.customerGroups.toString()}
                  </p>
                ) : null}
              </fieldset>
            </div>
          </div>
          <div className="space-y-4 md:col-span-12" ref={refTab}>
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
              <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex space-x-8">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-primary-600 text-primary-600"
                            : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                          "whitespace-nowrap border-b-2 py-6 text-sm font-medium focus:outline-none"
                        )
                      }
                    >
                      Trading Address
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-primary-600 text-primary-600"
                            : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                          "whitespace-nowrap border-b-2 py-6 text-sm font-medium focus:outline-none"
                        )
                      }
                    >
                      Pricing Structure
                    </Tab>
                    {customerId !== undefined ? (
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "border-primary-600 text-primary-600"
                              : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                            "whitespace-nowrap border-b-2 py-6 text-sm font-medium focus:outline-none"
                          )
                        }
                      >
                        Orders
                      </Tab>
                    ) : null}
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  <Tab.Panel className="pt-6">
                    <TradingAddressOptions
                      formik={formik}
                      addressOptions={addressOptions}
                      setAddressOptions={setAddressOptions}
                    />
                  </Tab.Panel>
                  <Tab.Panel className="pt-6">
                    <div className="sr-only mb-1 text-sm font-medium text-gray-900">
                      {t("text_pricing_structures")}
                    </div>
                    <p className="mb-4 text-sm text-gray-500">
                      This will override the base price and pricing level for
                      this customer.
                    </p>

                    {pricingStructures.length > 0 ? (
                      <>
                        <div className="flex flex-col space-y-4">
                          {pricingStructures.map((structure) => (
                            <div
                              key={structure.id}
                              className="space-y-4 rounded-md bg-white p-5"
                            >
                              <div className="flex items-center justify-between">
                                <div>
                                  <div className="text-sm">
                                    {structure.name}
                                  </div>
                                </div>
                                {customerId &&
                                Array.from(
                                  new Set(
                                    structure.customers.flatMap((c) =>
                                      Number(c.id)
                                    )
                                  )
                                ).length === 1 &&
                                structure.customers
                                  .flatMap((c) => Number(c.id))
                                  .includes(parseInt(customerId)) ? (
                                  <div className="flex items-center space-x-4">
                                    <Button
                                      variant="icon"
                                      onClick={() => {
                                        setActivePricingStructure(structure);
                                      }}
                                      className="border-0 bg-transparent"
                                    >
                                      <span className="sr-only">
                                        Edit {structure.name}
                                      </span>
                                      <PencilIcon
                                        aria-hidden="true"
                                        className="h-5 w-5 text-gray-700"
                                      />
                                    </Button>
                                    <Button
                                      variant="icon"
                                      onClick={() => {
                                        handleDeleteStructure(structure.id);
                                      }}
                                      className="border-0 bg-transparent"
                                    >
                                      <span className="sr-only">
                                        Delete {structure.name}
                                      </span>
                                      <TrashIcon
                                        aria-hidden="true"
                                        className="h-5 w-5 text-gray-700"
                                      />
                                    </Button>
                                    <Menu
                                      as="div"
                                      className="relative inline-block text-left"
                                    >
                                      <div>
                                        <Menu.Button className="inline-flex items-center whitespace-nowrap rounded-md border border-gray-700 bg-white px-3 py-2.5 text-sm font-normal text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">
                                          <>{t("text_adjust_prices")}</>
                                          <ChevronDownIcon
                                            className="-mr-1 ml-2 h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                      </div>

                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <Link
                                                  to={`structure/${structure.id}/pricing`}
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-100 text-gray-900"
                                                      : "text-gray-700",
                                                    "group flex items-center px-4 py-2 text-sm"
                                                  )}
                                                >
                                                  <TicketIcon
                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                  />
                                                  Set prices manually
                                                </Link>
                                              )}
                                            </Menu.Item>
                                          </div>
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-100 text-gray-900"
                                                      : "text-gray-700",
                                                    "group flex items-center px-4 py-2 text-sm"
                                                  )}
                                                  onClick={() =>
                                                    setWarning(true)
                                                  }
                                                >
                                                  <ArrowUpOnSquareIcon
                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                  />
                                                  Import from CSV
                                                </span>
                                              )}
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-100 text-gray-900"
                                                      : "text-gray-700",
                                                    "group flex items-center px-4 py-2 text-sm"
                                                  )}
                                                  onClick={() =>
                                                    setWarning(true)
                                                  }
                                                >
                                                  <ArrowDownOnSquareIcon
                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                  />
                                                  Export to CSV
                                                </span>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </div>
                                ) : (
                                  <Button
                                    variant="secondary"
                                    className="text-sm text-gray-500"
                                    disabled
                                  >
                                    Multiple customers included
                                  </Button>
                                )}
                              </div>
                            </div>
                          ))}
                          <div className="text-center">
                            <Button
                              className="w-full justify-center bg-gray-800 hover:bg-gray-900"
                              onClick={() => setNewPricingStructure(true)}
                            >
                              Create Pricing Structure
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="py-6 text-center text-sm md:py-12">
                        <ExclamationCircleIcon
                          type="outline"
                          name="exclamation-circle"
                          className="mx-auto h-6 w-6 text-gray-400"
                        />
                        <p className="mt-4 font-medium text-gray-900">
                          No pricing pricing structures found
                        </p>
                        <p className="mb-2 mt-2 text-gray-500">
                          Please <Button variant="text">create</Button> a
                          pricing pricing structure to continue.
                        </p>
                        <Button
                          variant="secondary"
                          onClick={() => setNewPricingStructure(true)}
                        >
                          Add Pricing Structure
                        </Button>
                      </div>
                    )}
                  </Tab.Panel>
                  {customerId !== undefined ? (
                    <Tab.Panel className="pt-6">
                      <div className="sr-only mb-1 text-sm font-medium text-gray-900">
                        {t("text_orders")}
                      </div>
                      <p className="mb-4 text-sm text-gray-500">
                        Customer orders are listed below. You can view the order
                        details by clicking on the order number.
                      </p>
                      <Orders
                        newOrder={newOrder ?? false}
                        handleNewOrder={handleNewOrder}
                      />
                    </Tab.Panel>
                  ) : null}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>

        {customerId !== undefined && customerStatus !== 1 ? (
          <div className="mt-4 bg-greyish sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg font-medium text-gray-900">
                Manage Account
              </h3>
              <div className="mt-2">
                <div className="max-w-xl text-sm text-gray-500">
                  <p>
                    Deactivating a customer account will prevent them from being
                    able to log in to the system.
                  </p>
                </div>
                <div className="mt-5 flex flex-wrap space-x-4">
                  {customerStatus === 0 ? (
                    <Button
                      variant="info"
                      onClick={() => {
                        updateCustomerStatus(2);
                      }}
                    >
                      {t("text_activate")}
                    </Button>
                  ) : (
                    <Button
                      variant="warning"
                      onClick={() => {
                        updateCustomerStatus(0);
                      }}
                    >
                      Deactivate
                    </Button>
                  )}
                  <Button variant="danger" onClick={handleCustomerDelete}>
                    Terminate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </form>

      <Transition.Root
        show={activePricingStructure ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePricingStructure(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                {activePricingStructure && activePricingStructure?.id ? (
                  <FormStructure
                    heading="Edit Pricing Structure - "
                    initialValues={{
                      id: activePricingStructure.id,
                      name: activePricingStructure.name,
                      pricingMethod: activePricingStructure.pricingMethod
                        ? {
                            label: activePricingStructure.pricingMethod.name,
                            value: activePricingStructure.pricingMethod.id,
                          }
                        : null,
                      productType: activePricingStructure.productType,
                      productIds: activePricingStructure.products?.flatMap(
                        (p) => Number(p.id)
                      ),
                      status: activePricingStructure.status,
                    }}
                    products={activePricingStructure.products}
                    occupiedProductIds={occupiedProductIds}
                    pricingStructures={pricingStructures}
                    onSubmit={handleUpdateStructure}
                    submitLabel={t("text_update")}
                    onCancel={() => setActivePricingStructure(undefined)}
                    cancelLabel={t("text_cancel")}
                    actionType="update"
                  />
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newPricingStructure ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPricingStructure(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <FormStructure
                  heading="New Pricing Structure - "
                  initialValues={{
                    id: "",
                    name: "",
                    pricingMethod: null,
                    productType: 1,
                    productIds: [],
                    status: true,
                  }}
                  products={[]}
                  occupiedProductIds={occupiedProductIds}
                  pricingStructures={pricingStructures}
                  onSubmit={handleCreateStructure}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewPricingStructure(undefined)}
                  cancelLabel={t("text_cancel")}
                  actionType="create"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function FieldCustomerType({
  value: initialValue,
  onChange,
  className,
}: {
  value: number;
  onChange: (newValue: number) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Individual", value: "0" },
      { label: "Company", value: "1" },
    ],
    []
  );

  useEffect(() => {
    const value =
      options.find((o) => o.value === initialValue.toString()) ?? null;
    setValues(value);
  }, [initialValue, options]);

  const handleChange = (newValue: SingleValue<OptionProps>) => {
    if (newValue === null) return;
    onChange(Number(newValue.value));
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={handleChange}
      />
    </SelectWrapper>
  );
}

function FieldSalesRep({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery<{
    fetchUsers: User[];
  }>(GET_USERS);
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchUsers) {
      const filteredOptions = data.fetchUsers.filter((u: User) =>
        u.roles.some((r) => !isAdmin(r))
      );
      return filteredOptions.map((p: User) => {
        return {
          label: p.fullName,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

function FieldDeliveryRuns({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery<{
    fetchDeliveryRuns: DeliveryRun[];
  }>(GET_DELIVERYRUNS, {
    variables: {
      status: true,
    },
  });
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchDeliveryRuns) {
      return data.fetchDeliveryRuns?.map((p: DeliveryRun) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

function FieldCustomerGroups({
  value,
  fixedValue,
  onChange,
  className,
}: {
  value: MultiValue<OptionProps>;
  fixedValue?: string[];
  onChange: (newValue: MultiValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<MultiValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery<{
    fetchCustomerGroups: CustomerGroup[];
  }>(GET_CUSTOMERGROUPS, {
    variables: {
      status: true,
    },
  });
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchCustomerGroups) {
      const filteredOptions = data.fetchCustomerGroups?.filter(
        (p: CustomerGroup) => !fixedValue?.includes(p.id)
      );
      return filteredOptions?.map((p: CustomerGroup) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data, fixedValue]);

  useEffect(() => {
    const activeOptionsIds: string[] = value.flatMap((v) => v.value);
    const activeOptions = value.filter((v) =>
      activeOptionsIds.includes(v.value)
    );
    const updatedValues = activeOptions.map((v) => {
      return {
        label: v.label,
        value: v.value,
      };
    });
    setValues(updatedValues);
  }, [value]);

  const handleChange = (
    newValue: MultiValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(orderOptions(newValue));
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={handleChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

function TradingAddressOptions(props: {
  formik: FormikProps<any>;
  addressOptions: AddressOption[];
  setAddressOptions: Dispatch<SetStateAction<AddressOption[]>>;
}) {
  const { t } = useTranslation();
  const { formik, addressOptions, setAddressOptions } = props;

  const [defaultAddressId, setDefaultAddressId] = useState("");
  useEffect(() => {
    const currentAddress = addressOptions.find(
      (v: AddressOption) => v.primaryAddress
    );
    setDefaultAddressId(currentAddress ? currentAddress.id : "");
  }, [addressOptions]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addAddress = () => {
    const newAddresses = [
      ...addressOptions,
      {
        id: nanoid(11),
        address: "",
        suburb: "",
        state: "",
        postcode: "",
        primaryAddress: false,
      },
    ];
    formik.setFieldValue(
      "tradingAddresses",
      newAddresses.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setAddressOptions(newAddresses);
  };

  const removeAddress = (id: string) => {
    const deletedAddresses = addressOptions.filter(
      (v: AddressOption) => v.id !== id
    );
    formik.setFieldValue(
      "tradingAddresses",
      deletedAddresses.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setAddressOptions(deletedAddresses);
  };

  const updateAddress = (id: string, address: AddressOption) => {
    const updatedAddresses = [
      ...addressOptions.map((v) => ({
        ...v,
        primaryAddress: address.primaryAddress ? false : v.primaryAddress,
      })),
    ];
    const index = updatedAddresses.findIndex((v) => v.id === id);
    updatedAddresses[index] = address;
    formik.setFieldValue(
      "tradingAddresses",
      updatedAddresses.map((t) => {
        const { id, ...rest } = t;
        return rest;
      })
    );
    setAddressOptions(updatedAddresses);
  };

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = addressOptions.findIndex((v) => v.id === active.id);
      const newIndex = addressOptions.findIndex((v) => v.id === over.id);
      const updatedArray = arrayMove(addressOptions, oldIndex, newIndex);
      formik.setFieldValue(
        "tradingAddresses",
        updatedArray.map((t) => {
          const { id, ...rest } = t;
          return rest;
        })
      );
      setAddressOptions(updatedArray);
    }
  }

  return (
    <fieldset>
      <legend className="sr-only text-sm font-medium text-gray-900">
        {t("text_trading_addresses")}
      </legend>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={addressOptions.map((v) => v.id!)}
            strategy={verticalListSortingStrategy}
          >
            {addressOptions.map((v) => (
              <FieldsetAddress
                key={`address-${v.id}`}
                id={v.id!}
                address={v}
                removeAddress={removeAddress}
                updateAddress={updateAddress}
                addressOptions={addressOptions}
                defaultAddressId={defaultAddressId}
                setDefaultAddressId={setDefaultAddressId}
              />
            ))}
          </SortableContext>
        </DndContext>
        <div className="mt-2 flex justify-end">
          <Button variant="secondary" onClick={addAddress}>
            {t("text_add_address")}
          </Button>
        </div>
      </div>
    </fieldset>
  );
}

function FieldsetAddress(props: {
  id: string;
  address: AddressOption;
  updateAddress?: any;
  removeAddress?: any;
  addressOptions: AddressOption[];
  defaultAddressId: string;
  setDefaultAddressId: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { t } = useTranslation();
  const {
    address,
    updateAddress,
    removeAddress,
    addressOptions,
    defaultAddressId,
    setDefaultAddressId,
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "default",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      {props.id === "0" ? null : <hr className="my-4" />}
      <div className="flex w-full items-start">
        <div className="mr-1 mt-3.5 space-y-5">
          <Button
            variant="text"
            style={{
              display: "flex",
            }}
            className="transition-all hover:bg-white"
            {...listeners}
          >
            <span
              aria-hidden="true"
              className="bi bi-grip-vertical px-2 text-lg"
            ></span>
          </Button>
          {addressOptions.length > 1 ? (
            <Button
              variant="text"
              style={{
                display: "flex",
              }}
              onClick={() => {
                removeAddress(address.id);
              }}
              className="transition-all hover:bg-white"
            >
              <TrashIcon
                aria-hidden="true"
                className="mx-2 h-4 w-4 text-gray-700"
              />
            </Button>
          ) : null}
        </div>

        <div className="grid w-full grid-cols-2 gap-4 py-4">
          <div>
            <FieldAddress
              title={t("text_address")}
              name={`trading-address-${address.id}`}
              type="street"
              onChange={(value) => {
                const { street, state, suburb, postcode } = value;

                const newAddress = { ...address };
                newAddress.address = street;
                newAddress.suburb = suburb || address.suburb;
                newAddress.state = state || address.state;
                newAddress.postcode = postcode || address.postcode;
                updateAddress(address.id, newAddress);
              }}
              value={address.address}
              isLabel={false}
              placeholder={t("text_address")}
            />
          </div>
          <div>
            <Field
              title={t("text_suburb")}
              name={`trading-suburb-${address.id}`}
              onChange={(e) => {
                const newAddress = { ...address };
                newAddress.suburb = e.target.value;
                updateAddress(address.id, newAddress);
              }}
              value={address.suburb}
              isLabel={false}
              placeholder={t("text_suburb")}
            />
          </div>
          <div>
            <FieldState
              title={t("text_state")}
              name={`trading-state-${address.id}`}
              onChange={(value) => {
                const newAddress = { ...address };
                newAddress.state = value;
                updateAddress(address.id, newAddress);
              }}
              value={address.state}
              isLabel={false}
              placeholder={t("text_state")}
            />
          </div>
          <div>
            <FieldAddress
              title={t("text_postcode")}
              name={`trading-postcode-${address.id}`}
              type="postcode"
              onChange={(value) => {
                const { street, state, suburb, postcode } = value;

                const newAddress = { ...address };
                newAddress.address = street || address.address;
                newAddress.suburb = suburb || address.suburb;
                newAddress.state = state || address.state;
                newAddress.postcode = postcode;
                updateAddress(address.id, newAddress);
              }}
              value={address.postcode}
              isLabel={false}
              placeholder={t("text_postcode")}
            />
          </div>
          <div>
            <Field
              title={t("text_primary_address")}
              id={`trading-primary-${address.id}`}
              name={`trading-primary`}
              type="radio"
              onChange={(e) => {
                const newAddress = { ...address };
                newAddress.primaryAddress =
                  defaultAddressId !== e.currentTarget.value;
                setDefaultAddressId(address.id);
                updateAddress(address.id, newAddress);
              }}
              value={address.id}
              checked={defaultAddressId === address.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
