import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_SPECIALPRICE,
  GET_SPECIALPRICES,
} from "../../../../graphql/inventory/pricing/special-prices";
import Form from "./components/FormCreate";

const SpecialPriceCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createSpecialPrice] = useMutation(ADD_SPECIALPRICE, {
    refetchQueries: [
      {
        query: GET_SPECIALPRICES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createSpecialPrice({
      variables: {
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? Number(values.pricingMethod.value)
          : null,
        productIds: values.productIds,
        startDate: values.startDate
          ? new Date(values.startDate).toISOString()
          : null,
        endDate: values.endDate ? new Date(values.endDate).toISOString() : null,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.specialPriceCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Special price created successfully",
          });
          return navigate(
            `/inventory/pricing/special-prices/${data?.specialPriceCreate?.specialPrice?.id}`
          );
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Special price creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Special price creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Special Price"
        heading="Special Prices"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Special Prices",
            href: "/inventory/pricing/special-prices",
          },
          {
            name: SpecialPriceCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_special_price")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_special_price")}
              </p>
            </div>
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link
                  to="/inventory/pricing/special-prices/add"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
                >
                  Add special price
                </Link>
              </div> */}
          </div>

          <Form
            initialValues={{
              name: "",
              pricingMethod: null,
              productIds: [],
              startDate: "",
              endDate: "",
              status: true,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default SpecialPriceCreate;
export const SpecialPriceCreateResource: ResourceProps = {
  name: "Create Special Price",
  description: "Create a new special price",
  access: ["create-pricings"],
  path: "special-prices/add",
};
