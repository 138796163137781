import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { User } from "../../../../graphql/user-management/users";
import { classNames } from "../../../../utils";
import { isAdmin } from "../../../auth";
import { Role } from "../../../auth/@types/auth";

type RoleProps = {
  readonly label: string;
  readonly value: string;
};

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: Pick<
    User,
    "firstName" | "lastName" | "userName" | "email" | "phoneNumber" | "status"
  > & {
    roles: RoleProps[];
  };
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const UserSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string().required("Required"),
    userName: Yup.string().required("Required"),
    roles: Yup.array()
      .of(Yup.object().nullable().required("Required"))
      .min(1, "Required"),
    status: Yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="rounded-md shadow-sm">
        <div className="bg-white px-4 py-5 sm:p-6">
          <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_firstname")}
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                touched={touched.firstName}
                errors={errors.firstName}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_lastname")}
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                touched={touched.lastName}
                errors={errors.lastName}
              />
            </div>

            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_email")}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                touched={touched.email}
                errors={errors.email}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_phone")}
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                touched={touched.phoneNumber}
                errors={errors.phoneNumber}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <Field
                title={t("text_username")}
                name="userName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                touched={touched.userName}
                errors={errors.userName}
              />
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_roles")}
              </label>
              <FieldRoles
                value={formik.values.roles}
                onChange={(value: MultiValue<RoleProps>) => {
                  formik.setFieldValue("roles", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.roles && formik.errors.roles
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.roles && formik.errors.roles ? (
                <p className="mt-2 text-sm text-red-600" id="roles-errors">
                  {formik.errors.roles.toString()}
                </p>
              ) : null}
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3">
              <fieldset>
                <legend className="text-sm font-medium text-gray-900">
                  {t("text_status")}
                </legend>
                <FieldStatus
                  value={formik.values.status}
                  onChange={(value: SingleValue<OptionProps>) => {
                    formik.setFieldValue(
                      "status",
                      value ? parseInt(value.value) : null
                    );
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.status && formik.errors.status
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
              </fieldset>
            </div>
          </div>
        </div>

        <div className="flex justify-end border-t border-gray-300 bg-white px-4 py-4 text-right md:px-6 md:py-6">
          <Link to="/user-management/users" className="mr-4 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              actionLabel
            )}
          </Button>
        </div>
      </div>
    </form>
  );
}

const FETCH_ROLES = gql`
  query FetchRoles($status: Boolean) {
    fetchRoles(status: $status) {
      id
      roleName
    }
  }
`;

export function FieldRoles({
  value,
  onChange,
  className,
}: {
  value: MultiValue<RoleProps>;
  onChange: (newValue: MultiValue<RoleProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<MultiValue<RoleProps>>(value);

  const { data, loading, refetch } = useQuery(FETCH_ROLES, {
    variables: {
      status: true,
    },
  });

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchRoles) {
      const newOptions = data.fetchRoles.filter((r: Role) => {
        if (isAdmin(r)) return false;
        return r;
      });
      return newOptions?.map((r: Role) => {
        return {
          label: r.roleName,
          value: r.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={(newValue: MultiValue<RoleProps>) => {
          if (newValue.length > 1) {
            onChange([newValue[newValue.length - 1]]);
          } else {
            onChange(newValue);
          }
        }}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

export function FieldStatus({
  value: initialValue,
  onChange,
  className,
}: {
  value: number;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Inactive", value: "0" },
      { label: "Invitation Sent", value: "1" },
      { label: "Active", value: "2" },
    ],
    []
  );

  useEffect(() => {
    const value: SingleValue<OptionProps> =
      options.find((o) => o.value === initialValue.toString()) ?? null;
    setValues(value);
  }, [options, initialValue]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}
