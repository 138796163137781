import { useMutation } from "@apollo/client/react";
import { Tab } from "@headlessui/react";
import { Fragment, useContext, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { GenContext } from "../../../contexts/GenContext";
import { GET_VEHICLES } from "../../../graphql/fleets/vehicles";
import {
  ADD_VEHICLEDETAILS,
  VehicleDetailsForm,
} from "../../../graphql/fleets/vehicles/pages/details";
import { classNames } from "../../../utils";
import FormDetails from "./components/DetailsForm";
import FinancialForm from "./components/FinancialForm";
import FormLifecycle from "./components/LifecycleForm";
import SettingsForm from "./components/SettingsForm";
import SpecificationsForm from "./components/SpecificationsForm";

const VehicleCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { vehicleForm, storeVehicleForm } = useContext(GenContext);

  const [createVehicle] = useMutation(ADD_VEHICLEDETAILS, {
    refetchQueries: [
      {
        query: GET_VEHICLES,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: VehicleDetailsForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createVehicle({
      variables: {
        name: values.name,
        color: values.color,
        licensePlate: values.licensePlate,
        msrp: values.msrp,
        trim: values.trim,
        vehicleImageUrl: values.vehicleImageUrl,
        vinSn: values.vinSn,
        year: values.year,
        fleetManagerId: values.fleetManagerId,
        operatorId: values.operatorId,
        vehicleBodySubtypeId: values.vehicleBodySubtypeId,
        vehicleBodyTypeId: values.vehicleBodyTypeId,
        vehicleFuelTypeId: values.vehicleFuelTypeId,
        vehicleMakeId: values.vehicleMakeId,
        vehicleModelId: values.vehicleModelId,
        vehicleTypeId: values.vehicleTypeId,
        vehicleStatusId: values.vehicleStatusId,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleCreate) {
          storeVehicleForm({
            ...vehicleForm,
            details: null,
            id: data.vehicleCreate.vehicle.id,
          });
          return navigate(`/fleets/vehicles/${data.vehicleCreate.vehicle.id}`);
        } else {
          toast.error("Vehicle creation failed");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  const navigation = useMemo(
    () => [
      {
        name: "Details",
        index: 0,
        icon: "bi bi-file-earmark-text",
      },
      {
        name: "Lifecycle",
        path: 1,
        icon: "bi bi-recycle",
      },
      {
        name: "Financial",
        path: 2,
        icon: "bi bi-bar-chart-line",
      },
      {
        name: "Specifications",
        path: 3,
        icon: "bi bi-border-style",
      },
      {
        name: "Settings",
        path: 4,
        icon: "bi bi-gear",
      },
    ],
    []
  );

  return (
    <>
      <Head
        title={VehicleCreateResource.name}
        heading="Vehicles"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: VehicleCreateResource.name,
            href: null,
          },
        ]}
      />
      <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="md:px-6 lg:col-span-3 lg:px-0">
            <div className="lg:hidden">
              <label htmlFor="selected-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="selected-tab"
                name="selected-tab"
                className="mt-1 block w-full rounded-md border-gray-200 py-2 pl-3 pr-10 text-base shadow-sm focus:outline-none focus-visible:border-primary-500 focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 sm:text-sm"
                defaultValue={activeTab}
                onChange={(e) => {
                  const to = e.target.value;
                  setActiveTab(parseInt(to));
                }}
              >
                {navigation.map(({ name, index, icon }) => (
                  <option key={`option-${name}`} value={index}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden rounded-xl bg-greyish p-5 lg:block">
              <Tab.List className="space-y-1">
                {navigation.map(({ name, index, icon }) => {
                  return (
                    <Tab
                      key={`tab-${name}`}
                      className={({ selected }: { selected: boolean }) =>
                        classNames(
                          selected ? "bg-primary-700/10 text-primary-700" : "",
                          "group flex w-full items-center rounded-md px-2 py-2 text-left text-sm text-gray-700 transition-colors focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
                        )
                      }
                    >
                      <span
                        className={classNames(
                          "mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current",
                          icon
                        )}
                        aria-hidden="true"
                      />
                      <span className="flex-1 truncate">{name}</span>
                    </Tab>
                  );
                })}
              </Tab.List>
            </div>
          </aside>
          <div className="lg:col-span-9">
            <div className="max-w-medium mx-auto">
              <div className="rounded-xl bg-greyish p-5">
                <Tab.Panels as={Fragment}>
                  <Tab.Panel>
                    <div className="mb-6 sm:flex sm:items-center md:mb-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-medium text-gray-900">
                          {t("heading_create_vehicle")}
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                          {t("description_create_vehicle")}
                        </p>
                      </div>
                    </div>

                    <FormDetails
                      initialValues={{
                        name: vehicleForm?.details?.name ?? "",
                        color: vehicleForm?.details?.color ?? "",
                        licensePlate: vehicleForm?.details?.licensePlate ?? "",
                        msrp: vehicleForm?.details?.msrp ?? null,
                        trim: vehicleForm?.details?.trim ?? "",
                        vehicleImageUrl:
                          vehicleForm?.details?.vehicleImageUrl ?? "",
                        vinSn: vehicleForm?.details?.vinSn ?? "",
                        year: vehicleForm?.details?.year ?? null,
                        fleetManagerId:
                          vehicleForm?.details?.fleetManagerId ?? null,
                        operatorId: vehicleForm?.details?.operatorId ?? null,
                        vehicleBodySubtypeId:
                          vehicleForm?.details?.vehicleBodySubtypeId ?? null,
                        vehicleBodyTypeId:
                          vehicleForm?.details?.vehicleBodyTypeId ?? null,
                        vehicleFuelTypeId:
                          vehicleForm?.details?.vehicleFuelTypeId ?? null,
                        vehicleMakeId:
                          vehicleForm?.details?.vehicleMakeId ?? null,
                        vehicleModelId:
                          vehicleForm?.details?.vehicleModelId ?? null,
                        vehicleTypeId:
                          vehicleForm?.details?.vehicleTypeId ?? null,
                        vehicleStatusId:
                          vehicleForm?.details?.vehicleStatusId ?? null,
                      }}
                      onChange={(values) => {
                        storeVehicleForm({
                          ...vehicleForm,
                          details: values,
                        });
                      }}
                      onClear={() => {
                        storeVehicleForm({
                          ...vehicleForm,
                          details: null,
                        });
                      }}
                      onSubmit={handleSubmit}
                      actionLabel={t("text_create")}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="mb-6 sm:flex sm:items-center md:mb-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-medium text-gray-900">
                          {t("heading_vehicle_lifecycle")}
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                          {t("description_vehicle_lifecycle")}
                        </p>
                      </div>
                    </div>
                    <FormLifecycle
                      initialValues={{
                        inServiceDate:
                          vehicleForm?.lifecycle?.inServiceDate ?? null,
                        inServiceOdometer:
                          vehicleForm?.lifecycle?.inServiceOdometer ?? null,
                        estimatedServiceLifeInMonths:
                          vehicleForm?.lifecycle
                            ?.estimatedServiceLifeInMonths ?? null,
                        estimatedServiceLifeInMeter:
                          vehicleForm?.lifecycle?.estimatedServiceLifeInMeter ??
                          null,
                        estimatedResaleValue:
                          vehicleForm?.lifecycle?.estimatedResaleValue ?? null,
                        outOfServiceDate:
                          vehicleForm?.lifecycle?.outOfServiceDate ?? null,
                        outOfServiceOdometer:
                          vehicleForm?.lifecycle?.outOfServiceOdometer ?? null,
                      }}
                      onChange={(values) => {
                        storeVehicleForm({
                          ...vehicleForm,
                          lifecycle: values,
                        });
                      }}
                      onClear={() => {
                        storeVehicleForm({
                          ...vehicleForm,
                          lifecycle: null,
                        });
                      }}
                      onSubmit={(values, actions) => {
                        toast.error("Please save the vehicle details first.");
                        actions.setSubmitting(false);
                      }}
                      actionLabel={t("text_save")}
                      vehicleBasic={null}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="mb-6 sm:flex sm:items-center md:mb-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-medium text-gray-900">
                          {t("heading_vehicle_financial")}
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                          {t("description_vehicle_financial")}
                        </p>
                      </div>
                    </div>
                    <FinancialForm
                      initialValues={{
                        fleetVendorId: vehicleForm?.financial?.vendor
                          ? parseInt(vehicleForm.financial.vendor.id)
                          : null,
                        purchaseDate: vehicleForm?.financial?.purchaseDate
                          ? vehicleForm.financial.purchaseDate
                          : null,
                        purchasePrice: vehicleForm?.financial?.purchasePrice
                          ? vehicleForm.financial.purchasePrice
                          : null,
                        odometer: vehicleForm?.financial?.odometer
                          ? vehicleForm.financial.odometer
                          : null,
                        notes: vehicleForm?.financial?.notes
                          ? vehicleForm.financial.notes
                          : "",
                        warrantyExpiryDate: vehicleForm?.financial
                          ?.warrantyExpiryDate
                          ? vehicleForm.financial.warrantyExpiryDate
                          : null,
                        maxOdometerValue: vehicleForm?.financial
                          ?.maxOdometerValue
                          ? vehicleForm.financial.maxOdometerValue
                          : null,
                        financeType: vehicleForm?.financial?.financeType
                          ? vehicleForm.financial.financeType
                          : null,
                        loanDetails: vehicleForm?.financial?.loanDetails
                          ? vehicleForm.financial.loanDetails
                          : null,
                        leaseDetails: vehicleForm?.financial?.leaseDetails
                          ? vehicleForm.financial.leaseDetails
                          : null,
                      }}
                      onChange={(values) => {
                        storeVehicleForm({
                          ...vehicleForm,
                          financial: values,
                        });
                      }}
                      onClear={() => {
                        storeVehicleForm({
                          ...vehicleForm,
                          financial: null,
                        });
                      }}
                      onSubmit={(values, actions) => {
                        toast.error("Please save the vehicle details first.");
                        actions.setSubmitting(false);
                      }}
                      actionLabel={t("text_save")}
                      vehicleBasic={null}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="mb-6 sm:flex sm:items-center md:mb-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-medium text-gray-900">
                          {t("heading_vehicle_specs")}
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                          {t("description_vehicle_specs")}
                        </p>
                      </div>
                    </div>
                    <SpecificationsForm
                      initialValues={{
                        bedLength:
                          vehicleForm?.specifications?.bedLength ?? null,
                        compression:
                          vehicleForm?.specifications?.compression ?? null,
                        curbWeight:
                          vehicleForm?.specifications?.curbWeight ?? null,
                        cylinders:
                          vehicleForm?.specifications?.cylinders ?? null,
                        displacement:
                          vehicleForm?.specifications?.displacement ?? null,
                        engineBore:
                          vehicleForm?.specifications?.engineBore ?? null,
                        engineBrand:
                          vehicleForm?.specifications?.engineBrand ?? "",
                        engineSummary:
                          vehicleForm?.specifications?.engineSummary ?? "",
                        epaCity: vehicleForm?.specifications?.epaCity ?? "",
                        epaCombined:
                          vehicleForm?.specifications?.epaCombined ?? "",
                        epaHighway:
                          vehicleForm?.specifications?.epaHighway ?? "",
                        fuelQuality:
                          vehicleForm?.specifications?.fuelQuality ?? null,
                        fuelTankOneCapacity:
                          vehicleForm?.specifications?.fuelTankOneCapacity ??
                          null,
                        fuelTankTwoCapacity:
                          vehicleForm?.specifications?.fuelTankTwoCapacity ??
                          null,
                        grossVehicleWeightRating:
                          vehicleForm?.specifications
                            ?.grossVehicleWeightRating ?? null,
                        groundClearance:
                          vehicleForm?.specifications?.groundClearance ?? null,
                        height: vehicleForm?.specifications?.height ?? null,
                        interiorVolume:
                          vehicleForm?.specifications?.interiorVolume ?? null,
                        length: vehicleForm?.specifications?.length ?? null,
                        maxPayload:
                          vehicleForm?.specifications?.maxPayload ?? null,
                        maxTorque:
                          vehicleForm?.specifications?.maxTorque ?? null,
                        oilCapacity:
                          vehicleForm?.specifications?.oilCapacity ?? null,
                        passengerVolume:
                          vehicleForm?.specifications?.passengerVolume ?? null,
                        stroke: vehicleForm?.specifications?.stroke ?? null,
                        towingCapacity:
                          vehicleForm?.specifications?.towingCapacity ?? null,
                        valves: vehicleForm?.specifications?.valves ?? null,
                        width: vehicleForm?.specifications?.width ?? null,
                      }}
                      onChange={(values) => {
                        storeVehicleForm({
                          ...vehicleForm,
                          specifications: values,
                        });
                      }}
                      onClear={() => {
                        storeVehicleForm({
                          ...vehicleForm,
                          specifications: null,
                        });
                      }}
                      onSubmit={(values, actions) => {
                        toast.error("Please save the vehicle details first.");
                        actions.setSubmitting(false);
                      }}
                      actionLabel={t("text_save")}
                      vehicleBasic={null}
                    />
                  </Tab.Panel>
                  <Tab.Panel>
                    <div className="mb-6 sm:flex sm:items-center md:mb-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-medium text-gray-900">
                          {t("heading_vehicle_settings")}
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                          {t("description_vehicle_settings")}
                        </p>
                      </div>
                    </div>
                    <SettingsForm
                      initialValues={{
                        fuelUnit: vehicleForm?.settings?.fuelUnit ?? null,
                        measurementUnit:
                          vehicleForm?.settings?.measurementUnit ?? null,
                        primaryMeter:
                          vehicleForm?.settings?.primaryMeter ?? null,
                      }}
                      onChange={(values) => {
                        storeVehicleForm({
                          ...vehicleForm,
                          settings: values,
                        });
                      }}
                      onClear={() => {
                        storeVehicleForm({
                          ...vehicleForm,
                          settings: null,
                        });
                      }}
                      onSubmit={(values, actions) => {
                        toast.error("Please save the vehicle details first.");
                        actions.setSubmitting(false);
                      }}
                      actionLabel={t("text_save")}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </div>
            </div>
          </div>
        </div>
      </Tab.Group>
    </>
  );
};

export default VehicleCreate;
export const VehicleCreateResource: ResourceProps = {
  name: "Create Vehicle",
  description: "Create a new vehicle",
  access: ["create-vehicles"],
  path: "/add",
};
