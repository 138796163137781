import { gql } from "@apollo/client";

export const GET_ROLES = gql`
  query FetchRoles {
    fetchRoles {
      id
      roleName
      roleDescription
      createdAt
      status
      permissions {
        id
        permissionName
        permissionDisplayName
        permissionDescription
        createdAt
        status
      }
    }
  }
`;

export const REMOVE_ROLE = gql`
  mutation RoleDelete($id: ID!) {
    roleDelete(input: { id: $id }) {
      role {
        id
        roleName
      }
    }
  }
`;

export const GET_ROLE_BY_ID = gql`
  query FetchRole($id: ID!) {
    fetchRole(id: $id) {
      id
      roleName
      roleDescription
      permissions {
        id
        permissionName
        permissionDisplayName
        permissionDescription
        createdAt
        status
      }
      createdAt
      status
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation RoleUpdate(
    $id: ID!
    $roleName: String!
    $roleDescription: String!
    $permissions: [Int!]!
    $status: Boolean!
  ) {
    roleUpdate(
      input: {
        id: $id
        params: {
          roleName: $roleName
          roleDescription: $roleDescription
          permissions: $permissions
          status: $status
        }
      }
    ) {
      role {
        id
        roleName
      }
    }
  }
`;

export const ADD_ROLE = gql`
  mutation RoleCreate(
    $roleName: String!
    $roleDescription: String!
    $permissions: [Int!]!
    $status: Boolean!
  ) {
    roleCreate(
      input: {
        params: {
          roleName: $roleName
          roleDescription: $roleDescription
          permissions: $permissions
          status: $status
        }
      }
    ) {
      role {
        id
        roleName
      }
    }
  }
`;
