import { useMutation } from "@apollo/client/react";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { ADD_ORDER, GET_ORDERS } from "../../../graphql/sales/orders";
import Form from "./components/FormCreate";

const OrderCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();

  const [createOrder] = useMutation(ADD_ORDER, {
    refetchQueries: [
      {
        query: GET_ORDERS,
        variables: {
          searchQuery: "",
          searchFilter: [],
          customerId: null,
          deliveryrunId: null,
          deliveryDate: "",
          status: null,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createOrder({
      variables: {
        customerId: values.customerId,
        purchaseNumber: values.purchaseNumber,
        customerTradingAddressId: values.customerTradingAddressId,
        subTotal: parseInt(values.subTotal),
        total: parseInt(values.total),
        deliveryDate: values.deliveryDate,
        notes: values.notes,
        status: values.status,
        products: values.products,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.orderCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Order created successfully",
          });
          return navigate(`/sales/orders/${data.orderCreate.order.id}`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Order creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Order creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Order"
        heading="Orders"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Orders",
            href: "/sales/orders",
          },
          {
            name: OrderCreateResource.name,
            href: null,
          },
        ]}
      />
      <Form
        initialValues={{
          customerId: null,
          products: [],
          purchaseNumber: "",
          customerTradingAddressId: null,
          deliveryDate: null,
          notes: "",
          subTotal: 0,
          total: 0,
          status: 1,
        }}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default OrderCreate;
export const OrderCreateResource: ResourceProps = {
  name: "Create Order",
  description: "Create a new order",
  access: ["create-salesorders"],
  path: "orders/add",
};
