import { gql } from "@apollo/client";

export const VEHICLEMAKE_FRAGMENT = gql`
  fragment VehicleMakeFragment on VehicleMake {
    id
    name
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLEMAKES = gql`
  ${VEHICLEMAKE_FRAGMENT}
  query FetchVehicleMakes {
    fetchVehicleMakes {
      ...VehicleMakeFragment
    }
  }
`;

// export const GET_VEHICLEMAKE_BY_ID = gql`
//   ${VEHICLEMAKE_FRAGMENT}
//   query FetchVehicleMake($id: ID!) {
//     fetchVehicleMake(id: $id) {
//       ...VehicleMakeFragment
//     }
//   }
// `;

// export const REMOVE_VEHICLEMAKE = gql`
//   ${VEHICLEMAKE_FRAGMENT}
//   mutation VehicleMakeDelete($id: ID!) {
//     vehicleMakeDelete(input: { id: $id }) {
//       vehicleMake {
//         ...VehicleMakeFragment
//       }
//     }
//   }
// `;

export const ADD_VEHICLEMAKE = gql`
  ${VEHICLEMAKE_FRAGMENT}
  mutation VehicleMakeCreate($name: String!) {
    vehicleMakeCreate(input: { params: { name: $name } }) {
      vehicleMake {
        ...VehicleMakeFragment
      }
    }
  }
`;

// export const EDIT_VEHICLEMAKE = gql`
//   ${VEHICLEMAKE_FRAGMENT}
//   mutation VehicleMakeUpdate($id: ID!, $name: String!) {
//     vehicleMakeUpdate(input: { id: $id, params: { name: $name } }) {
//       vehicleMake {
//         ...VehicleMakeFragment
//       }
//     }
//   }
// `;

export type VehicleMake = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};
