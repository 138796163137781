import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import {
  EDIT_FUELENTRY,
  GET_FUELENTRIES,
  GET_FUELENTRY_BY_ID,
  VehicleFuelEntry,
  VehicleFuelEntryForm,
} from "../../../../../graphql/fleets/vehicles/pages/fuel-entry";
import Form from "../../components/FuelEntryForm";

const FuelEntryUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  let { fuelEntryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchFuelEntry: VehicleFuelEntry;
  }>(GET_FUELENTRY_BY_ID, { variables: { id: fuelEntryId } });

  const fuelEntry = useMemo<VehicleFuelEntry | undefined>(
    () => data?.fetchFuelEntry,
    [data]
  );

  const [updateVehicleFuelEntry] = useMutation(EDIT_FUELENTRY, {
    refetchQueries: [{ query: GET_FUELENTRIES }],
    awaitRefetchQueries: true,
  });
  const handleUpdate = (
    values: VehicleFuelEntryForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!fuelEntryId) return;
    updateVehicleFuelEntry({
      variables: {
        id: fuelEntryId,
        vehicleId: values.vehicleId,
        vehicleFuelTypeId: values.vehicleFuelTypeId,
        referenceNo: values.referenceNo,
        pricePerUnit: values.pricePerUnit,
        odometer: values.odometer,
        fuelFlags: values.fuelFlags,
        fuelEntryDate: values.fuelEntryDate,
        fuelAmount: values.fuelAmount,
        fleetVendorId: values.fleetVendorId,
        files: values.files,
        comments: values.comments,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleFuelEntryUpdate) {
          toast.success(`Vehicle fuel entry updated successfully`);
          return navigate("/fleets/vehicles/fuel-entries");
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={FuelEntryUpdateResource.name}
        heading={FuelEntryUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: FuelEntryUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_fuel_entry_update")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_fuel_entry_update")}
              </p>
            </div>
          </div>

          {loading || !fuelEntry ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                vehicleId: fuelEntry.vehicle
                  ? parseInt(fuelEntry.vehicle.id)
                  : null,
                vehicleFuelTypeId: fuelEntry?.fuelType
                  ? parseInt(fuelEntry.fuelType.id)
                  : null,
                referenceNo: fuelEntry?.referenceNo ?? "",
                pricePerUnit: fuelEntry?.pricePerUnit ?? null,
                odometer: fuelEntry?.odometer ?? null,
                fuelFlags: fuelEntry?.fuelFlags ?? null,
                fuelEntryDate: fuelEntry?.fuelEntryDate ?? null,
                fuelAmount: fuelEntry?.fuelAmount ?? null,
                fleetVendorId: fuelEntry?.vendor
                  ? parseInt(fuelEntry.vendor.id)
                  : null,
                files: fuelEntry?.files.length
                  ? fuelEntry?.files.map((item) => ({
                      fileType: item.fileType,
                      fileUrl: item.fileUrl,
                    }))
                  : [],
                comments: fuelEntry?.comments ?? "",
              }}
              onSubmit={handleUpdate}
              submitLabel={t("text_update")}
              onCancel={() => {
                navigate(`/fleets/vehicles/fuel-entries/${fuelEntryId}`);
              }}
              vehicleBasic={null}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FuelEntryUpdate;
export const FuelEntryUpdateResource: ResourceProps = {
  name: "Edit Fuel Entry",
  description: "Update an existing fuel entry",
  access: ["update-vehicles"],
  path: "/fuel-entries/:fuelEntryId/edit",
};
