import { Dialog, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import { Spinner } from "../../../../../animations";
import {
  Button,
  Field,
  selectStyles,
  SelectWrapper,
} from "../../../../../components/form";
import {
  FieldFormulaBuilder,
  IFieldValues,
} from "../../../../../components/form/FieldFormulaBuilder";
import { classNames, toSlug } from "../../../../../utils";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id?: string;
  name: string;
  handle: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

export default function Form({
  heading,
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: PricingMethod;
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();

  const ResourceSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    handle: Yup.string().required("Required"),
    sellPriceFormula: Yup.array().of(Yup.string()),
    pricingMethodFields: Yup.array().of(
      Yup.object().shape({
        fieldType: Yup.string().required("Required"),
        fieldKey: Yup.string().required("Required"),
        fieldName: Yup.string().required("Required"),
        fieldOptions: Yup.array().of(Yup.string()),
      })
    ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ResourceSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 py-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.name ? formik.values.name : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Get started by filling in the information below to create your
                new pricing methods.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-6 md:col-span-3">
                    <Field
                      title={t("text_name")}
                      name="name"
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue("name", value);
                        formik.setFieldValue("handle", toSlug(value));
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      touched={touched.name}
                      errors={errors.name}
                    />
                  </div>
                  <div className="sm:col-span-6 md:col-span-3">
                    <Field
                      title={t("text_handle")}
                      name="handle"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.handle}
                      touched={touched.handle}
                      errors={errors.handle}
                    />
                  </div>
                  {/* <div className="sm:col-span-6">
                    <hr />
                    <div className="py-4 md:py-6">
                      <FieldBuilder
                        values={formik.values.pricingMethodFields}
                        onChange={(values) => {
                          formik.setFieldValue("pricingMethodFields", values);
                        }}
                      />
                    </div>
                    <hr />
                  </div>
                  <div className="sm:col-span-6 md:col-span-6">
                    <FieldFormula
                      values={formik.values.sellPriceFormula}
                      variants={formik.values.pricingMethodFields.map((f) =>
                        toVariableName(f.fieldName)
                      )}
                      onChange={(value) => {
                        formik.setFieldValue("sellPriceFormula", value);
                      }}
                    />
                  </div> */}
                  <div className="sm:col-span-6 md:col-span-6">
                    <FieldFormulaBuilder
                      values={{
                        priceFields: formik.values.pricingMethodFields,
                        priceFormula: formik.values.sellPriceFormula,
                      }}
                      onChange={(
                        priceFields: IFieldValues[],
                        priceFormula: string[]
                      ) => {
                        console.log(1, priceFields);
                        console.log(2, priceFormula);
                        formik.setValues({
                          ...formik.values,
                          pricingMethodFields: priceFields,
                          sellPriceFormula: priceFormula,
                        });
                      }}
                    />
                  </div>
                  <div className="sm:col-span-6 md:col-span-3">
                    <fieldset>
                      <legend className="text-sm font-medium text-gray-900">
                        {t("text_status")}
                      </legend>
                      <Switch.Group
                        as="div"
                        className="mt-1.5 inline-flex items-center"
                      >
                        <Switch
                          checked={formik.values.status}
                          onChange={() => {
                            formik.setFieldValue(
                              "status",
                              !formik.values.status
                            );
                          }}
                          id="status"
                          className={classNames(
                            formik.values.status
                              ? "bg-primary-600"
                              : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              formik.values.status
                                ? "translate-x-5"
                                : "translate-x-0",
                              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                        <Switch.Label
                          passive
                          htmlFor="status"
                          className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                        >
                          {formik.values.status
                            ? t("text_active")
                            : t("text_inactive")}
                        </Switch.Label>
                      </Switch.Group>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export function FieldPricingFormula({
  value,
  onChange,
  className,
}: {
  value: string[];
  onChange: (newValue: string[]) => void;
  className?: string;
}) {
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([
    {
      label: "+",
      value: "$+",
    },
    {
      label: "-",
      value: "$-",
    },
    {
      label: "*",
      value: "$*",
    },
    {
      label: "/",
      value: "$/",
    },
    {
      label: "(",
      value: "$(",
    },
    {
      label: ")",
      value: "$)",
    },
    {
      label: "%",
      value: "$%",
    },
  ]);

  const [values, setValues] = useState<MultiValue<OptionProps>>([]);

  useEffect(() => {
    if (!value.length) return;
    setValues(
      value?.map((v) => ({
        label: v.replace(/\$.*?\$/g, ""),
        value: v.match(/\$.*?\$/g) ? v : `$${v}`,
      }))
    );
  }, [value]);

  const handleCreate = (inputValue: string) => {
    setLoading(true);
    const newOption = {
      label: inputValue.replace(/\$.*?\$/g, ""),
      value: inputValue.match(/\$.*?\$/g) ? inputValue : `$${inputValue}`,
    };
    setOptions([...options, newOption]);
    setLoading(false);
  };

  const handleChange = (newValue: MultiValue<OptionProps>) => {
    onChange(newValue.map((item) => item.value));
  };

  return (
    <SelectWrapper className={className ?? ""}>
      <CreatableSelect
        isClearable
        styles={selectStyles}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={handleChange}
        onCreateOption={handleCreate}
        options={options}
        value={values}
        isMulti
      />
    </SelectWrapper>
  );
}
