import { Fragment, useEffect, useMemo, useState } from "react";
import Select, { SingleValue } from "react-select";

import { classNames } from "../../utils";
import { selectStyles, SelectWrapper } from ".";

interface FieldProps {
  title: string;
  name: string;
  id?: string;
  className?: string;
  placeholder?: string;
  touched?: boolean | undefined;
  errors?: string | undefined;
  isLabel?: boolean;
  onChange: (value: string) => void;
  value: string;
  readOnly?: boolean;
  disabled?: boolean;
}

export function FieldState(props: FieldProps) {
  const {
    title,
    name,
    value,
    onChange,
    readOnly = false,
    disabled = false,
    touched,
    errors,
    isLabel = true,
    id,
    className,
    placeholder,
    ...rest
  } = props;

  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      {
        label: "New South Wales",
        value: "NSW",
      },
      {
        label: "Victoria",
        value: "VIC",
      },
      {
        label: "Queensland",
        value: "QLD",
      },
      {
        label: "Tasmania",
        value: "TAS",
      },
      {
        label: "South Australia",
        value: "SA",
      },
      {
        label: "Western Australia",
        value: "WA",
      },
      {
        label: "Northern Territory",
        value: "NT",
      },
      {
        label: "Australian Capital Territory",
        value: "ACT",
      },
    ],
    []
  );

  useEffect(() => {
    if (value) {
      const option = options.find((option) => option.value === value) || null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  return (
    <Fragment>
      <label
        htmlFor={id ? id : name}
        className={`block text-sm font-medium text-gray-900 ${
          isLabel ? "mb-1" : "sr-only"
        }`}
      >
        {title}
      </label>
      <SelectWrapper
        className={classNames(
          "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
          className ?? ""
        )}
      >
        <Select
          id={id ? id : name}
          closeMenuOnSelect={true}
          styles={selectStyles}
          value={values}
          options={options}
          onChange={(value) => onChange(value?.value || "")}
          isDisabled={readOnly || disabled}
          {...rest}
        />
      </SelectWrapper>
    </Fragment>
  );
}
