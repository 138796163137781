import { gql } from "@apollo/client";

import { PICKING_FRAGMENT } from "../picking";

export const GET_PICKER_WORKLIST = gql`
  ${PICKING_FRAGMENT}
  query FetchPickerWorklist($first: Int, $cursor: String) {
    fetchPickerWorklist(first: $first, after: $cursor) {
      edges {
        node {
          ...PickingFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const EDIT_PICKING_PRODUCTSTATUS = gql`
  mutation PickingProductStatusUpdate($id: ID!, $pickingStatus: Int!) {
    pickingProductStatusUpdate(
      input: { id: $id, pickingStatus: $pickingStatus }
    ) {
      message
    }
  }
`;
