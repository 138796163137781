import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { GET_WAREHOUSE_PICKERS } from "../../../graphql/user-management/assign-zone-pickers";
import {
  EDIT_USER,
  GET_USER_BY_ID,
  GET_USERS,
  User,
} from "../../../graphql/user-management/users";
import Form from "./components/Form";

type RoleProps = {
  readonly label: string;
  readonly value: string;
};

const UserUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchUser: User;
  }>(GET_USER_BY_ID, {
    variables: {
      id: userId,
    },
  });
  const user = useMemo<User | undefined>(
    () => data?.fetchUser,
    [data?.fetchUser]
  );

  const [updateUser] = useMutation(EDIT_USER, {
    refetchQueries: [{ query: GET_USERS }, { query: GET_WAREHOUSE_PICKERS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateUser({
      variables: {
        id: userId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        userName: values.userName,
        roles: values.roles.map((role: RoleProps) => parseInt(role.value)),
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.userUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "User updated successfully",
          });
          return navigate(`/user-management/users`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "User update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "User update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={UserUpdateResource.name}
        heading={UserUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Users",
            href: "/user-management/users",
          },
          {
            name: UserUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-6 sm:flex sm:items-center md:mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {t("heading_update_user")}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {t("description_update_user")}
            </p>
          </div>
        </div>

        {loading || !user ? (
          <Waiting />
        ) : (
          <Form
            initialValues={{
              firstName: user.firstName ?? "",
              lastName: user.lastName ?? "",
              userName: user.userName ?? "",
              email: user.email ?? "",
              phoneNumber: user.phoneNumber ?? "",
              roles:
                user.roles.map((r) => {
                  return {
                    label: r.roleName,
                    value: r.id,
                  };
                }) ?? [],
              status: user.status ?? 0,
            }}
            onSubmit={handleSubmit}
            actionLabel={t("text_update")}
          />
        )}
      </div>
    </>
  );
};

export default UserUpdate;
export const UserUpdateResource: ResourceProps = {
  name: "Edit User",
  description: "Update an existing user",
  access: ["update-users"],
  path: "users/:userId",
};
