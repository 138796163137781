import "react-lazy-load-image-component/src/effects/blur.css";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";
import { Fragment, useCallback, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Logo } from "../../../animations";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { formatDate, getImageDimensions } from "../../../utils";
import { deleteMedia } from "../core/MediaHelper";
import { MediaProps } from "../core/types";

type ModalProps = {
  file: MediaProps | null;
  open: boolean;
  onClose: () => void;
  onDelete: (url: string) => void;
};

export default function Modal({ file, open, onClose, onDelete }: ModalProps) {
  const { addNotify } = useNotifyContext();
  const [loading, setLoading] = useState(false);
  const [dimenstions, setDimensions] = useState({ width: 0, height: 0 });

  const handleRemove = useCallback(() => {
    if (!file)
      return addNotify({
        type: NotifyType.ERROR,
        message: "Invalid file path",
      });
    const path = file.url.split(".com/alphafresh/")[1];
    if (!path)
      return addNotify({
        type: NotifyType.ERROR,
        message: "Invalid file path",
      });
    setLoading(true);
    deleteMedia(path)
      .then((data: { status: boolean; msg: string }) => {
        if (data.status === true) {
          addNotify({
            type: NotifyType.SUCCESS,
            message: data.msg,
          });
          onDelete(file.url);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            message: data.msg || "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          message: error.message,
        });
      })
      .finally(() => setLoading(false));
  }, [addNotify, file, onDelete]);

  useEffect(() => {
    if (!file) return;
    getImageDimensions(file.url).then((dimensions) => {
      setDimensions(dimensions);
    });
  }, [file]);

  if (!file) return null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {loading || !file ? (
                    <div className="flex h-full w-full flex-col items-center justify-center bg-white p-8">
                      <Logo />
                    </div>
                  ) : file ? (
                    <div className="h-full overflow-y-auto bg-white p-8">
                      <div className="space-y-6 pb-16">
                        <div>
                          <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
                            <LazyLoadImage
                              src={file.url}
                              alt={file.title}
                              className="object-cover"
                              effect="blur"
                            />
                          </div>
                          <div className="mt-4 flex items-start justify-between">
                            <div>
                              <h2 className="text-base font-semibold leading-6 text-gray-900">
                                <span className="sr-only">Details for </span>
                                <span className="first-letter:uppercase">
                                  {file.title}
                                </span>
                              </h2>
                              <p className="text-sm font-medium text-gray-500">
                                {file.size}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-900">
                            Information
                          </h3>
                          <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                            <div className="flex justify-between py-3 text-sm font-medium">
                              <dt className="text-gray-500">Uploaded on</dt>
                              <dd className="text-gray-900">
                                {formatDate(
                                  DateTime.fromJSDate(
                                    new Date(`${file.date}`)
                                  ).toISODate()
                                )}
                              </dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                              <dt className="text-gray-500">File Type</dt>
                              <dd className="text-gray-900">{file.type}</dd>
                            </div>
                            {dimenstions ? (
                              <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Dimensions</dt>
                                <dd className="text-gray-900">
                                  {dimenstions.width}px x {dimenstions.height}px
                                </dd>
                              </div>
                            ) : null}
                            <div className="flex justify-between py-3 text-sm font-medium">
                              <dt className="text-gray-500">File Extension</dt>
                              <dd className="text-gray-900">
                                {file.extension}
                              </dd>
                            </div>
                          </dl>
                        </div>

                        <div className="flex">
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noreferrer"
                            download={file.title}
                            className="flex-1 rounded-md bg-secondary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-secondary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-600"
                          >
                            Download
                          </a>
                          <button
                            type="button"
                            className="ml-3 flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={handleRemove}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
