import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import Select, { MultiValue } from "react-select";

import { GET_USERS, User } from "../../graphql/user-management/users";
import { selectStyles, SelectWrapper } from ".";

export function FieldUsers({
  value = [],
  onChange,
  className,
}: {
  value: number[];
  onChange: (newValue: number[]) => void;
  className: string;
}) {
  const [values, setValues] = useState<MultiValue<OptionProps>>([]);

  const { data, loading, refetch } = useQuery<{
    fetchUsers: User[];
  }>(GET_USERS);

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchUsers) {
      return data.fetchUsers?.map((p: User) => {
        return {
          label: p.fullName,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (!value) return setValues([]);
    const updatedValues = options.filter((option) =>
      value.includes(Number(option.value))
    );
    setValues(updatedValues);
  }, [options, value]);

  const onChangeHandler = (newValue: MultiValue<OptionProps>) => {
    onChange(newValue ? newValue.map((option) => Number(option.value)) : []);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isMulti
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
