import { gql } from "@apollo/client";

import { UserModel } from "../../../modules/auth/@types/auth";
import { User, USER_FRAGMENT } from "../../user-management/users";
import { Driver, DRIVER_FRAGMENT } from "../settings/drivers";
import { VehicleFinancial, VEHICLEFINANCIAL_FRAGMENT } from "./pages/financial";
import { VehicleIssue, VEHICLEISSUE_FRAGMENT } from "./pages/issues";
import { VehicleLifecycle, VEHICLELIFECYCLE_FRAGMENT } from "./pages/lifecycle";
import { VehicleSetting, VEHICLESETTINGS_FRAGMENT } from "./pages/settings";
import { VehicleSpec, VEHICLESPECS_FRAGMENT } from "./pages/specifications";
import {
  VehicleBodySubtype,
  VEHICLEBODYSUBTYPE_FRAGMENT,
} from "./sections/body-subtypes";
import {
  VehicleBodyType,
  VEHICLEBODYTYPE_FRAGMENT,
} from "./sections/body-types";
import {
  VehicleFuelType,
  VEHICLEFUELTYPE_FRAGMENT,
} from "./sections/fuel-type";
import { VehicleMake, VEHICLEMAKE_FRAGMENT } from "./sections/make";
import { VehicleModel, VEHICLEMODEL_FRAGMENT } from "./sections/model";
import { VehicleStatus, VEHICLESTATUS_FRAGMENT } from "./sections/status";
import { VehicleGroup, VEHICLEGROUP_FRAGMENT } from "./sections/types";

export const VEHICLE_COSTSTATISTICS_FRAGMENT = gql`
  fragment VehicleCostStatisticsFragment on Vehiclecoststatistics {
    fuelCost
    serviceCost
    otherCost
    month
    year
  }
`;

export const VEHICLE_WATCHER_FRAGMENT = gql`
  fragment VehicleWatcherFragment on User {
    id
    fullName
    firstName
    lastName
    email
  }
`;

export const VEHICLE_FRAGMENT = gql`
  ${VEHICLE_COSTSTATISTICS_FRAGMENT}
  ${VEHICLEFINANCIAL_FRAGMENT}
  ${USER_FRAGMENT}
  ${VEHICLEFUELTYPE_FRAGMENT}
  ${VEHICLELIFECYCLE_FRAGMENT}
  ${VEHICLEMAKE_FRAGMENT}
  ${VEHICLEMODEL_FRAGMENT}
  ${DRIVER_FRAGMENT}
  ${VEHICLESTATUS_FRAGMENT}
  ${VEHICLEBODYSUBTYPE_FRAGMENT}
  ${VEHICLEBODYTYPE_FRAGMENT}
  ${VEHICLEGROUP_FRAGMENT}
  ${VEHICLESPECS_FRAGMENT}
  ${VEHICLEISSUE_FRAGMENT}
  ${VEHICLESETTINGS_FRAGMENT}
  ${VEHICLE_WATCHER_FRAGMENT}
  fragment VehicleFragment on Vehicle {
    id
    color
    name
    costStatistics {
      ...VehicleCostStatisticsFragment
    }
    financial {
      ...VehicleFinancialFragment
    }
    fleetManager {
      ...UserFragment
    }
    fuelType {
      ...VehicleFuelTypeFragment
    }
    licensePlate
    lifecycle {
      ...VehicleLifecycleFragment
    }
    make {
      ...VehicleMakeFragment
    }
    model {
      ...VehicleModelFragment
    }
    operator {
      ...DriverFragment
    }
    status {
      ...VehicleStatusFragment
    }
    trim
    vehicleBodySubtype {
      ...VehicleBodySubtypeFragment
    }
    vehicleBodyType {
      ...VehicleBodyTypeFragment
    }
    vehicleType {
      ...VehicleGroupFragment
    }
    vehicleImageUrl
    specification {
      ...VehicleSpecsFragment
    }
    openIssues {
      ...VehicleIssueFragment
    }
    settings {
      ...VehicleSettingFragment
    }
    openIssuesCount
    overDueIssuesCount
    watchers {
      ...VehicleWatcherFragment
    }
    msrp
    vinSn
    year
    createdAt
    updatedAt
  }
`;

export const VEHICLE_BASIC_FRAGMENT = gql`
  ${VEHICLEMAKE_FRAGMENT}
  ${VEHICLEMODEL_FRAGMENT}
  ${DRIVER_FRAGMENT}
  ${VEHICLESTATUS_FRAGMENT}
  ${VEHICLESETTINGS_FRAGMENT}
  fragment VehicleBasicFragment on Vehicle {
    id
    name
    vehicleImageUrl
    make {
      ...VehicleMakeFragment
    }
    model {
      ...VehicleModelFragment
    }
    operator {
      ...DriverFragment
    }
    status {
      ...VehicleStatusFragment
    }
    settings {
      ...VehicleSettingFragment
    }
  }
`;

export const GET_VEHICLES = gql`
  ${VEHICLE_FRAGMENT}
  query FetchVehicles($status: Boolean) {
    fetchVehicles(status: $status) {
      ...VehicleFragment
    }
  }
`;

export const GET_VEHICLES_BASIC = gql`
  ${VEHICLE_BASIC_FRAGMENT}
  query FetchVehiclesBasic($status: Boolean) {
    fetchVehicles(status: $status) {
      ...VehicleBasicFragment
    }
  }
`;

export const GET_VEHICLE_BY_ID = gql`
  ${VEHICLE_FRAGMENT}
  query FetchVehicle($id: ID!) {
    fetchVehicle(id: $id) {
      ...VehicleFragment
    }
  }
`;

export const GET_VEHICLE_BASIC_BY_ID = gql`
  ${VEHICLE_BASIC_FRAGMENT}
  query FetchVehicleBasic($id: ID!) {
    fetchVehicle(id: $id) {
      ...VehicleBasicFragment
    }
  }
`;

export const GET_VEHICLES_BY_DOCK = gql`
  query FetchVehiclesByDock($dockId: ID!) {
    fetchVehiclesByDock(dockId: $dockId) {
      id
      name
      vehicleImageUrl
      settings {
        fuelUnit
        measurementUnit
        primaryMeter
      }
      status {
        id
        name
        color
      }
      operator {
        id
        name
      }
      make {
        id
        name
      }
      model {
        id
        name
      }
      year
    }
  }
`;

export const REMOVE_VEHICLE = gql`
  ${VEHICLE_FRAGMENT}
  mutation VehicleDelete($id: ID!) {
    vehicleDelete(input: { id: $id }) {
      vehicle {
        ...VehicleFragment
      }
    }
  }
`;

export const GET_VEHICLE_WATCHERS = gql`
  ${VEHICLE_WATCHER_FRAGMENT}
  query VehicleWatchers($id: ID!) {
    fetchVehicle(id: $id) {
      watchers {
        ...VehicleWatcherFragment
      }
    }
  }
`;

export const SUBSCRIPTION_VEHICLE_WATCHERS = gql`
  subscription VehicleWatchers($id: ID!) {
    vehicleWatchers(id: $id) {
      id
      user {
        ...VehicleWatcherFragment
      }
      vehicleId
    }
  }
`;

export const CREATE_VEHICLE_WATCHER = gql`
  mutation VehicleWatcherCreate($vehicleId: ID!, $watcherId: ID!) {
    vehicleWatcherCreate(
      input: { vehicleId: $vehicleId, watcherId: $watcherId }
    ) {
      message
    }
  }
`;

export const DELETE_VEHICLE_WATCHER = gql`
  mutation VehicleWatcherDelete($vehicleId: ID!, $watcherId: ID!) {
    vehicleWatcherDelete(
      input: { vehicleId: $vehicleId, watcherId: $watcherId }
    ) {
      message
    }
  }
`;

export type Watcher = Pick<
  User,
  "id" | "fullName" | "firstName" | "lastName" | "email"
>;

export type VehicleWatcher = {
  id: string;
  user: Watcher;
  vehicleId: number;
};

export type VehicleCostStatistics = {
  fuelCost: number;
  serviceCost: number;
  otherCost: number;
  month: number;
  year: number;
};
export type Vehicle = {
  id: string;
  name: string;
  color: string;
  createdAt: string;
  costStatistics: VehicleCostStatistics[];
  financial: VehicleFinancial[];
  fleetManager: UserModel;
  fuelType: VehicleFuelType | null;
  licensePlate: string;
  lifecycle: VehicleLifecycle[];
  make: VehicleMake | null;
  model: VehicleModel | null;
  operator: Driver | null;
  status: VehicleStatus;
  trim: string;
  updatedAt: string;
  vehicleBodySubtype: VehicleBodySubtype;
  vehicleBodyType: VehicleBodyType;
  vehicleType: VehicleGroup;
  vehicleImageUrl: string;
  specification: VehicleSpec[];
  settings: VehicleSetting[];
  openIssues: VehicleIssue[];
  openIssuesCount: number;
  overDueIssuesCount: number;
  watchers: Watcher[];
  msrp: number | null;
  vinSn: string;
  year: number | null;
};

export type VehicleBase = Pick<
  Vehicle,
  "name" | "vehicleImageUrl" | "status" | "make" | "model" | "year" | "operator"
> & {
  id: number;
};

export type VehicleBasic = VehicleBase &
  Pick<VehicleSetting, "fuelUnit" | "measurementUnit" | "primaryMeter">;
