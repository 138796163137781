import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon, PencilIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  type AssignZonePicker as RawAssignZonePicker,
  EDIT_ASSIGN_ZONEPICKERS,
  GET_ASSIGN_ZONEPICKERS,
} from "../../../graphql/user-management/assign-zone-pickers";
import { classNames, toNestedTable } from "../../../utils";
import { AuthShield } from "../../auth/core";
import Form from "./components/Form";

type RowAssignZonePicker = RawAssignZonePicker & {
  subRows: RowAssignZonePicker[];
};

const ZonePickerList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { data, loading, error, refetch } = useQuery(GET_ASSIGN_ZONEPICKERS);
  const departments: RowAssignZonePicker[] = useMemo(() => {
    const items: RowAssignZonePicker[] = data?.fetchDepartments ?? [];
    return toNestedTable(items) as RowAssignZonePicker[];
  }, [data?.fetchDepartments]);

  const [activeAssignZonePicker, setActiveAssignZonePicker] = useState<
    RowAssignZonePicker | undefined
  >(undefined);

  const [updateAssignZonePicker] = useMutation(EDIT_ASSIGN_ZONEPICKERS, {
    refetchQueries: [{ query: GET_ASSIGN_ZONEPICKERS }],
  });
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateAssignZonePicker({
      variables: {
        departmentId: values.departmentId,
        zonePickerIds: values.zonePickerIds,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.assignZonePickers) {
          await refetch();
          setActiveAssignZonePicker(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Zone picker update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Zone picker update failed",
          message: error.message,
        });
      });
  };

  const columns = useMemo<ColumnDef<RowAssignZonePicker, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "pickers",
        header: "Zone Pickers",
        cell: (props) =>
          props.row.original.pickers.map((r) => r.fullName).join(", "),
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-departments"]}>
              <Button
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
                onClick={() => setActiveAssignZonePicker(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={ZonePickerListResource.name}
        heading={ZonePickerListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ZonePickerListResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {ZonePickerListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {ZonePickerListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh department list</span>
            </Button>
          </div>
        </div>

        <TableCursor
          data={departments}
          columns={columns}
          loading={loading}
          totalRows={departments.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          state={{
            expanded,
          }}
          enableRowSelection={false}
          onExpandedChange={setExpanded}
          getExpandedRowModel={getExpandedRowModel()}
          getSubRows={(row) => row.subRows}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No departments found"
          fallbackText="You can add a new department by clicking on add department button."
        />
      </div>

      <Transition.Root
        show={activeAssignZonePicker ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveAssignZonePicker(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                {activeAssignZonePicker ? (
                  <Form
                    heading={`Assign Zone Pickers for ${activeAssignZonePicker.name}`}
                    initialValues={{
                      departmentId: activeAssignZonePicker.id,
                      zonePickerIds: activeAssignZonePicker.pickers.length
                        ? activeAssignZonePicker.pickers.map((picker) =>
                            parseInt(picker.id)
                          )
                        : [],
                    }}
                    zonePickers={activeAssignZonePicker.pickers}
                    onSubmit={handleUpdate}
                    submitLabel={t("text_assign")}
                    onCancel={() => setActiveAssignZonePicker(undefined)}
                    cancelLabel={t("text_cancel")}
                  />
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ZonePickerList;
export const ZonePickerListResource: ResourceProps = {
  name: "Assign Zone Pickers List",
  description: "A list of zone pickers",
  access: ["create-assignZonePickers", "read-assignZonePickers"],
  path: "assign-zone-pickers",
};
