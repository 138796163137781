/**
 * @param title - The title of the notification
 * @optional @param message - message to display
 * @param onDismiss - function to dismiss the notification
 * @optional @param onCancel - async callback function to cancel the notification
 * @optional @param cancelText - text to display in cancel button
 * @optional @param onConfirm - async callback function to confirm the notification
 * @optional @param confirmText - text to display in confirm button
 * @optional @param type - type of notification (success, error, warning, info)
 * @optional @param modal - modal of notification (simple, condensed, actions-below, split-buttons)
 * @optional @param delay - auto dismiss notification after x seconds
 * @returns {JSX.Element}
 * @example dispatchNotify({
            type: NotifyAction.ADD,
            payload: {
              type: NotifyType.SUCCESS,
              modal: NotifyModal.SIMPLE,
              title: "Welcome to the Dashboard",
              message: "You are now logged in",
            },
          });
 */

import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  LifebuoyIcon,
  StarIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { a, useTrail } from "@react-spring/web";
import { nanoid } from "nanoid";
import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { createPortal } from "react-dom";
import * as ReactIs from "react-is";

import { classNames } from "../utils";

export enum NotifyAction {
  ADD,
  REMOVE,
  CLEAR,
}

export enum NotifyType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
}

export enum NotifyModal {
  SIMPLE,
  CONDENSED,
  ACTIONS_BELOW,
  SPLIT_BUTTONS,
}

export const NotifyContext = createContext<any>({});

const initialState: NotifyState[] = [];

export const notifyReducer = (
  state: NotifyState[],
  action: { type: NotifyAction; payload: any }
): NotifyState[] => {
  switch (action.type) {
    case NotifyAction.ADD:
      return [
        ...state,
        {
          id: nanoid(),
          ...action.payload,
        },
      ];
    case NotifyAction.REMOVE:
      return state.filter(
        (n: NotifyState): boolean => n.id !== action.payload.id
      );
    case NotifyAction.CLEAR:
      return [];
    default:
      return state;
  }
};

export default function NotifyProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [notify, dispatchNotify] = useReducer(notifyReducer, initialState);

  const addNotify = (payload: NotifyState) => {
    dispatchNotify({ type: NotifyAction.ADD, payload });
  };
  const removeNotify = (payload: number) => {
    dispatchNotify({ type: NotifyAction.REMOVE, payload });
  };

  const notifyData = { notify, dispatchNotify, addNotify, removeNotify };
  // const rootModal = document.getElementById("root-modal") as HTMLElement;
  // if (!rootModal) {
  //   throw new Error("root-modal not found");
  // }

  return (
    <NotifyContext.Provider value={notifyData}>
      {children}
      {createPortal(<NotifyRender notify={notify} />, document.body)}
    </NotifyContext.Provider>
  );
}

export const useNotifyContext = () => {
  return useContext(NotifyContext);
};

export function NotifyRender({ notify }: { notify: NotifyState[] }) {
  const trail = useTrail(notify.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    delay: 250,
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 20 },
  });

  const { dispatchNotify } = useNotifyContext();
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 z-50 flex items-start px-4 py-6 sm:p-6"
    >
      <div className="flex w-full flex-col items-end space-y-4">
        {notify.length > 1 && (
          <button
            type="button"
            className="pointer-events-auto mr-5"
            onClick={() => dispatchNotify({ type: NotifyAction.CLEAR })}
            arial-label="Close notifications"
          >
            <span
              className="bi bi-list-nested h-6 w-6 text-xl"
              aria-hidden="true"
            />
          </button>
        )}
        {trail.map(({ ...style }, index) => (
          <a.div
            key={notify[index].id}
            className="flex w-full flex-col items-end space-y-4"
            style={style}
          >
            <Notification {...notify[index]} />
          </a.div>
        ))}
      </div>
    </div>
  );
}

function useRenderModal({
  title,
  message,
  onClose,
  dismissible,
  onCancel,
  cancelText,
  onConfirm,
  confirmText,
  modal,
  type,
}: NotificationProps): JSX.Element {
  function renderIcon(type: NotifyType) {
    switch (type) {
      case NotifyType.SUCCESS:
        return (
          <CheckCircleIcon
            className="h-6 w-6 text-primary-600"
            aria-hidden="true"
          />
        );
      case NotifyType.ERROR:
        return (
          <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        );
      case NotifyType.WARNING:
        return (
          <ExclamationTriangleIcon
            className="h-6 w-6 text-yellow-600"
            aria-hidden="true"
          />
        );
      case NotifyType.INFO:
        return (
          <LifebuoyIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
        );
      default:
        return (
          <StarIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
        );
    }
  }

  const confirmHandler = useCallback(async () => {
    if (onConfirm) {
      return await onConfirm();
    }
  }, [onConfirm]);

  const cancelHandler = useCallback(async () => {
    if (onCancel) {
      return await onCancel();
    }
  }, [onCancel]);

  switch (modal) {
    case NotifyModal.CONDENSED:
      return (
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center">
              <div className="flex w-0 flex-1 justify-between">
                <p className="w-0 flex-1 text-sm font-medium text-gray-900">
                  {title}
                </p>
                {onConfirm && (
                  <button
                    type="button"
                    className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={confirmHandler}
                  >
                    {confirmText}
                  </button>
                )}
              </div>
              {(dismissible || (!onConfirm && !onCancel)) && (
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NotifyModal.ACTIONS_BELOW:
      return (
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">{renderIcon(type)}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                {title && (
                  <p className="text-sm font-medium text-gray-900">{title}</p>
                )}
                {ReactIs.isElement(message) ? (
                  message
                ) : (
                  <p
                    className={classNames(
                      title ? "mt-1" : "",
                      "text-sm text-gray-500"
                    )}
                  >
                    {message}
                  </p>
                )}
                <div className="flex space-x-7">
                  {onConfirm && (
                    <button
                      type="button"
                      className="mt-3 rounded-md bg-white text-sm font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      onClick={confirmHandler}
                    >
                      {confirmText}
                    </button>
                  )}
                  {onCancel && (
                    <button
                      type="button"
                      className="mt-3 rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      onClick={cancelHandler}
                    >
                      {cancelText}
                    </button>
                  )}
                </div>
              </div>
              {(dismissible || (!onConfirm && !onCancel)) && (
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NotifyModal.SPLIT_BUTTONS:
      return (
        <div className="pointer-events-auto flex w-full max-w-md divide-x divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="flex w-0 flex-1 items-center p-4">
            <div className="w-full">
              {title && (
                <p className="text-sm font-medium text-gray-900">{title}</p>
              )}
              {ReactIs.isElement(message) ? (
                message
              ) : (
                <p
                  className={classNames(
                    title ? "mt-1" : "",
                    "text-sm text-gray-500"
                  )}
                >
                  {message}
                </p>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col divide-y divide-gray-200">
              {onConfirm && (
                <div className="flex h-0 flex-1">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-primary-600 hover:text-primary-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500"
                    onClick={confirmHandler}
                  >
                    {confirmText}
                  </button>
                </div>
              )}
              {onCancel && (
                <div className="flex h-0 flex-1">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                    onClick={cancelHandler}
                  >
                    {cancelText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">{renderIcon(type)}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                {title && (
                  <p className="text-sm font-medium text-gray-900">{title}</p>
                )}
                {ReactIs.isElement(message) ? (
                  message
                ) : (
                  <p
                    className={classNames(
                      title ? "mt-1" : "",
                      "text-sm text-gray-500"
                    )}
                  >
                    {message}
                  </p>
                )}
              </div>
              {(dismissible || (!onConfirm && !onCancel)) && (
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
  }
}

function NotifyWidget({
  onClose,
  dismissible,
  onCancel,
  cancelText,
  onConfirm,
  confirmText,
  modal,
  type,
  title,
  message,
}: NotificationProps) {
  return (
    <>
      <Transition
        show={true}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {useRenderModal({
          modal,
          type,
          title,
          message,
          dismissible,
          onCancel,
          cancelText,
          onConfirm,
          confirmText,
          onClose,
        })}
      </Transition>
    </>
  );
}

export function Notification({
  id,
  title,
  message,
  dismissible = true,
  onCancel,
  cancelText = "Cancel",
  onConfirm,
  confirmText = "Confirm",
  modal,
  type,
  delay = 5000,
}: UseNotifyProps) {
  const { dispatchNotify } = useNotifyContext();

  const clearNotifyAlert = useCallback(() => {
    if ((!onConfirm && !onCancel) || dismissible) {
      dispatchNotify({ type: NotifyAction.REMOVE, payload: { id } });
    }
  }, [dismissible, id, dispatchNotify, onConfirm, onCancel]);

  useEffect(() => {
    if (dismissible) {
      const timeout = setTimeout(() => {
        clearNotifyAlert();
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [title, message, dismissible, delay, clearNotifyAlert]);

  return (
    <NotifyWidget
      title={title}
      message={message}
      type={type}
      modal={modal}
      dismissible={dismissible}
      onCancel={onCancel}
      cancelText={cancelText}
      onConfirm={onConfirm}
      confirmText={confirmText}
      onClose={() => {
        clearNotifyAlert();
      }}
    />
  );
}
