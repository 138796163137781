import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  GET_VEHICLESPECS,
  VehicleSpec,
} from "../../../../graphql/fleets/vehicles/pages/specifications";

export function Specifications({
  vehicleId,
}: {
  vehicleId: string | undefined;
}) {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery<{
    fetchSpecsByVehicle: VehicleSpec;
  }>(GET_VEHICLESPECS, {
    variables: {
      vehicleId,
    },
  });
  const specifications = useMemo<VehicleSpec | null>(
    () => data?.fetchSpecsByVehicle || null,
    [data]
  );

  return (
    <div className="grid gap-4 sm:grid-cols-12">
      <div className="space-y-5 sm:col-span-7">
        <div className="rounded-xl bg-greyish p-5">
          <h3 className="text-md mb-3 font-medium text-gray-900">Dimensions</h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Width</span>
                <span className="text-gray-500">
                  {specifications?.width ? specifications.width : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Height</span>
                <span className="text-gray-500">
                  {specifications?.height ? specifications.height : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Length</span>
                <span className="text-gray-500">
                  {specifications?.length ? specifications.length : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Interior Volume</span>
                <span className="text-gray-500">
                  {specifications?.interiorVolume
                    ? specifications.interiorVolume
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Passenger Volume</span>
                <span className="text-gray-500">
                  {specifications?.passengerVolume
                    ? specifications.passengerVolume
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Ground Clearance</span>
                <span className="text-gray-500">
                  {specifications?.groundClearance
                    ? specifications.groundClearance
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Bed Length</span>
                <span className="text-gray-500">
                  {specifications?.bedLength ? specifications.bedLength : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="rounded-xl bg-greyish p-5">
          <h3 className="text-md mb-3 font-medium text-gray-900">Weight</h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Curb Weight</span>
                <span className="text-gray-500">
                  {specifications?.curbWeight ? specifications.curbWeight : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Gross Vehicle Weight Rating</span>
                <span className="text-gray-500">
                  {specifications?.grossVehicleWeightRating
                    ? specifications.grossVehicleWeightRating
                    : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="rounded-xl bg-greyish p-5">
          <h3 className="text-md mb-3 font-medium text-gray-900">
            Performance
          </h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Towing Capacity</span>
                <span className="text-gray-500">
                  {specifications?.towingCapacity
                    ? specifications.towingCapacity
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Max Payload</span>
                <span className="text-gray-500">
                  {specifications?.maxPayload ? specifications.maxPayload : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="rounded-xl bg-greyish p-5">
          <h3 className="text-md mb-3 font-medium text-gray-900">
            Fuel Economy
          </h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>EPA City</span>
                <span className="text-gray-500">
                  {specifications?.epaCity ? specifications.epaCity : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>EPA Highway</span>
                <span className="text-gray-500">
                  {specifications?.epaHighway ? specifications.epaHighway : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>EPA Combined</span>
                <span className="text-gray-500">
                  {specifications?.epaCombined
                    ? specifications.epaCombined
                    : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="space-y-5 sm:col-span-5">
        <div className="rounded-xl bg-greyish p-5">
          <h3 className="text-md mb-3 font-medium text-gray-900">Engine</h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Engine Brand</span>
                <span className="text-gray-500">
                  {specifications?.engineBrand
                    ? specifications.engineBrand
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Engine Bore</span>
                <span className="text-gray-500">
                  {specifications?.engineBore ? specifications.engineBore : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Max Torque</span>
                <span className="text-gray-500">
                  {specifications?.maxTorque ? specifications.maxTorque : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Cylinders</span>
                <span className="text-gray-500">
                  {specifications?.cylinders ? specifications.cylinders : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Displacement</span>
                <span className="text-gray-500">
                  {specifications?.displacement
                    ? specifications.displacement
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Compression</span>
                <span className="text-gray-500">
                  {specifications?.compression
                    ? specifications.compression
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Stroke</span>
                <span className="text-gray-500">
                  {specifications?.stroke ? specifications.stroke : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Valves</span>
                <span className="text-gray-500">
                  {specifications?.valves ? specifications.valves : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between sm:col-span-2">
                <span>Engine Summary</span>
                <span className="text-gray-500">
                  {specifications?.engineSummary
                    ? specifications.engineSummary
                    : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="rounded-xl bg-greyish p-5">
          <h3 className="text-md mb-3 font-medium text-gray-900">Fuel</h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-1 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Fuel Quality</span>
                <span className="text-gray-500">
                  {specifications?.fuelQuality
                    ? specifications.fuelQuality
                    : "-"}
                </span>
              </li>

              <li className="flex items-center justify-between">
                <span>Fuel Tank One Capacity</span>
                <span className="text-gray-500">
                  {specifications?.fuelTankOneCapacity
                    ? specifications.fuelTankOneCapacity
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Fuel Tank Two Capacity</span>
                <span className="text-gray-500">
                  {specifications?.fuelTankTwoCapacity
                    ? specifications.fuelTankTwoCapacity
                    : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="rounded-xl bg-greyish p-5">
          <h3 className="text-md mb-3 font-medium text-gray-900">Oil</h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-1 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Oil Capacity</span>
                <span className="text-gray-500">
                  {specifications?.oilCapacity
                    ? specifications.oilCapacity
                    : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
