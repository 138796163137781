export type SvgProps = {
  className?: string;
};

export const IconFilterClear = (props: SvgProps) => {
  const { className = "", ...rest } = props;
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 388.2 347.7"
      enableBackground="new 0 0 388.2 347.7"
      className={className}
      {...rest}
    >
      <g>
        <path
          fill="currentColor"
          d="M225.9,312.8c-0.1-1.9-0.8-3.5-2.1-4.7c-1.5-1.4-3.7-2.1-6.2-1.9h0c-4.1,0.3-6.8,2.8-7.3,6.8c-0.2,1.7-0.2,3.4-0.2,5
       c0,0.4,0,0.9,0,1.3c0,3.4,0,6.8,0,10.3v0.4c0,0-0.1,0-0.1-0.1c-4.7-2.8-9.5-5.5-14.2-8.3c-11.3-6.6-23-13.5-34.6-20.1
       c-4.9-2.8-7-6.4-7-12.1c0.1-19.3,0.1-39,0.1-58c0-7.1,0-14.3,0-21.4v-1.8l15.9,0c5.3,0,10.6,0,15.8,0c8.8,0,10.2-5,10.2-8
       c0-2.3-0.6-4.1-2-5.6c-1.7-1.8-4.3-2.6-8-2.7c-0.6,0-1.2,0-1.9,0l-0.8,0c-2.7,0-5.3,0-8,0c-7.8,0-15.9,0-23.8-0.1
       c-1.1,0-2.7-0.7-3.4-1.4c-39.4-41-79.5-82.8-118.2-123.2l-2.8-2.9h309.6c0,0,0,0,0,0l-16.1,16.8c-12.9,13.5-25.9,27-38.8,40.5
       c-3.7,3.9-3.9,8.8-0.5,12.1c1.5,1.5,3.4,2.3,5.4,2.3c0,0,0.1,0,0.1,0c2.3,0,4.6-1.1,6.5-3.1c18.5-19.3,40.3-42,61.9-64.6
       c5.8-6.1,8.8-13.8,8.8-22.8c0-3.7,0-7.5,0-11.2c0-4.3,0-8.5,0-12.8c0-13.4-8.3-21.6-21.6-21.6c-107,0-214.1,0-321.1,0
       c-2.2,0-4.1,0.2-5.7,0.5C8.2,2.2,3.1,7.2,0.1,15.9L0,16.3v35.2l0.1,0.3c2.2,7.6,5.4,13.3,10,18.1c21.7,22.6,43.3,45.1,65,67.7
       c20.3,21.2,40.6,42.3,60.9,63.5c1.5,1.5,2,2.9,2,5.2c0,15.8,0,31.9,0,47.4c0,12,0,24,0,36.1c0,11.5,4.9,20.1,14.7,25.5
       c3.4,1.9,6.9,3.9,10.2,5.9c1.4,0.8,2.9,1.7,4.3,2.5c3.7,2.2,7.5,4.4,11.2,6.6c8.6,5,17.5,10.2,26.3,15.3c2.4,1.4,5.2,2.1,7.9,2.1
       c2.2,0,4.4-0.5,6.4-1.4c3.6-1.8,6-5,6.5-8.8c1-7.1,0.8-14.5,0.5-21.5C225.9,314.9,225.9,313.9,225.9,312.8z M18.6,48
       c-0.8,0-1.8,0-2.4,0c0-0.6,0-1.5-0.1-2.4c0-9.3,0-16.9,0-23.9c0-4.3,1.1-5.4,5.5-5.4c106.9,0,213.8,0,320.7,0
       c4.5,0,5.6,1.1,5.6,5.7c0,9.1,0,16.6,0,23.6c0,0.8-0.1,1.6-0.2,2.5L18.6,48z"
        />
        <path
          fill="currentColor"
          d="M361,170.8c-17.4-17.3-40.3-26.9-64.6-26.9c-0.1,0-0.2,0-0.4,0c-24.7,0.1-47.9,9.8-65.3,27.4
       c-17.3,17.5-26.8,40.6-26.6,65.1c0.3,50.7,41.6,91.8,92,91.8c0,0,0.1,0,0.1,0c24.6,0,47.7-9.7,65.1-27.2
       c17.4-17.5,27-40.7,26.9-65.3C388.2,211.3,378.5,188.3,361,170.8z M296.2,160.2c20.1,0,39.1,7.9,53.4,22.2
       C364,196.7,372,215.8,372,236c0.1,41.8-33.8,76-75.6,76.1c-0.1,0-0.2,0-0.3,0c-20.1,0-39.1-7.9-53.5-22.2
       c-14.4-14.4-22.4-33.5-22.4-53.8c0-41.8,34-75.8,75.8-75.9C296.1,160.2,296.2,160.2,296.2,160.2z"
        />
        <path
          fill="currentColor"
          d="M267.3,253.5c-1.1,1.1-2.3,2.2-3.4,3.4c-3.1,3.1-6.3,6.2-9.3,9.4c-3.3,3.5-3.4,8.3-0.2,11.5c3.2,3.3,7.7,3.3,11.4,0
       c0.9-0.8,1.7-1.6,2.5-2.4c0.3-0.3,0.6-0.6,0.9-0.9l27-27c3.1,3.1,6.2,6.2,9.3,9.3c6.7,6.8,13.5,13.5,20.3,20.2
       c2.1,2.1,4.4,3.1,6.6,3.1c2,0,3.9-0.8,5.5-2.4c3.5-3.5,3.2-8.1-0.6-12.1c-1.9-2-3.9-3.9-5.8-5.8c-0.8-0.8-1.6-1.6-2.4-2.4
       l-21.4-21.4c0.4-0.5,0.9-0.9,1.3-1.3c3.2-3.2,6.4-6.4,9.5-9.5c6.2-6.2,12.3-12.3,18.5-18.5c3.2-3.2,5.1-8.2,0.9-12.4
       c-3.5-3.5-8.3-3.1-12.4,0.9c-8.4,8.3-16.6,16.6-24.3,24.3c-1.5,1.5-3,3-4.5,4.6c-0.1,0.1-0.3,0.3-0.4,0.4c-0.5-0.5-1-1-1.5-1.4
       l-5.9-5.9c-7.3-7.2-14.8-14.7-22.1-22.1c-4.2-4.3-8.1-3.4-10.7-2c-2.2,1.2-3.5,3.2-3.9,5.5c-0.4,2.7,0.7,5.6,3,7.9
       c6.1,6.1,12.2,12.3,18.3,18.4c3.3,3.3,6.6,6.6,10,10c0.4,0.4,0.8,0.8,1.1,1.2l-4.9,4.9C275.6,245.2,271.4,249.4,267.3,253.5z"
        />
      </g>
    </svg>
  );
};
