import { useQuery } from "@apollo/client";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";

import {
  FleetVendor,
  FleetVendorClassification,
  GET_FLEETVENDORS,
  renderClassification,
} from "../../graphql/fleets/settings/vendors";
import { Avatar, ButtonPopover, PopoverPosition } from "../appearance";
import { selectStyles, SelectWrapper } from ".";

type FieldFleetVendorProps = {
  title: string;
  value: number | null;
  onChange?: (newValue: number | null) => void;
  classification?: FleetVendorClassification | null;
  className?: string;
  isPreview?: boolean;
  position?: PopoverPosition;
};
export function FieldFleetVendor({
  title,
  value: initialValue,
  onChange,
  classification = null,
  className = "",
  isPreview = false,
  position = PopoverPosition.RIGHT,
}: FieldFleetVendorProps) {
  const [value, setValue] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery<{
    fetchFleetVendors: FleetVendor[];
  }>(GET_FLEETVENDORS);

  const options: MultiValue<OptionProps> = useMemo(() => {
    const fetchedOptions = data?.fetchFleetVendors?.length
      ? data?.fetchFleetVendors
      : [];
    const filteredOptions =
      classification !== null
        ? fetchedOptions.filter((p: FleetVendor) =>
            p.classification.includes(classification)
          )
        : fetchedOptions;
    return filteredOptions.map((p: FleetVendor) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
  }, [classification, data?.fetchFleetVendors]);

  useEffect(() => {
    if (!initialValue) return setValue(null);
    const isInteger = Number.isInteger(initialValue);
    if (isInteger) {
      const option =
        options.find((option) => option.value === initialValue.toString()) ??
        null;
      setValue(option);
      return;
    }
    setValue(null);
  }, [options, initialValue]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    typeof onChange === "function" &&
      onChange(newValue ? Number(newValue.value) : null);
  };

  if (isPreview) {
    if (value) {
      const vendor = data?.fetchFleetVendors?.find(
        (p: FleetVendor) => p.id === value?.value
      );
      if (vendor) {
        return (
          <ButtonPopover
            title={vendor.name}
            child={
              <div className="flex items-center">
                {vendor.profileImageUrl ? (
                  <span className="relative inline-block h-10 w-10 overflow-hidden rounded-full">
                    <img
                      className="h-full w-full object-cover"
                      src={vendor.profileImageUrl}
                      alt={vendor.name}
                    />
                  </span>
                ) : (
                  <Avatar firstName={vendor.name} />
                )}
                <span className="ml-2">{vendor.name}</span>
              </div>
            }
            position={position}
          >
            <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
              <h3 className="mb-2 font-medium">{vendor.name}</h3>
              <p>
                {vendor.classification.map((c) => (
                  <span
                    key={c}
                    className="mb-2 mr-2 rounded-full bg-greyish px-2 py-1 text-gray-700"
                  >
                    {renderClassification(c)}
                  </span>
                ))}
              </p>
              <p className="mt-2">{vendor.email}</p>
            </div>
            <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
              <Link
                to={`/fleets/settings/drivers/${vendor.id}`}
                target="_blank"
                className="block text-center text-sm text-gray-500"
              >
                View {title}
              </Link>
            </div>
          </ButtonPopover>
        );
      }
    }
    return <Fragment>-</Fragment>;
  }

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={value}
        options={options}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
