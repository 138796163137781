import { gql } from "@apollo/client";

export const VEHICLESERVICETASK_FRAGMENT = gql`
  fragment VehicleServiceTaskFragment on VehicleServiceTask {
    id
    name
    description
    parent {
      id
      name
    }
    status
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLESERVICETASKS = gql`
  ${VEHICLESERVICETASK_FRAGMENT}
  query FetchServiceTasks {
    fetchServiceTasks {
      ...VehicleServiceTaskFragment
    }
  }
`;

export const ADD_VEHICLESERVICETASK = gql`
  ${VEHICLESERVICETASK_FRAGMENT}
  mutation VehicleServiceTaskCreate(
    $name: String!
    $description: String
    $parentId: Int
    $status: Boolean!
  ) {
    vehicleServiceTaskCreate(
      input: {
        params: {
          name: $name
          description: $description
          parentId: $parentId
          status: $status
        }
      }
    ) {
      vehicleServiceTask {
        ...VehicleServiceTaskFragment
      }
    }
  }
`;

export const EDIT_VEHICLESERVICETASK = gql`
  ${VEHICLESERVICETASK_FRAGMENT}
  mutation VehicleServiceTaskUpdate(
    $id: ID!
    $name: String!
    $description: String
    $parentId: Int
    $status: Boolean!
  ) {
    vehicleServiceTaskUpdate(
      input: {
        id: $id
        params: {
          name: $name
          description: $description
          parentId: $parentId
          status: $status
        }
      }
    ) {
      vehicleServiceTask {
        ...VehicleServiceTaskFragment
      }
    }
  }
`;

export const REMOVE_VEHICLESERVICETASK = gql`
  ${VEHICLESERVICETASK_FRAGMENT}
  mutation VehicleServiceTaskDelete($id: ID!) {
    vehicleServiceTaskDelete(input: { id: $id }) {
      vehicleServiceTask {
        ...VehicleServiceTaskFragment
      }
    }
  }
`;

export type ServiceTask = {
  id: string;
  name: string;
  description: string;
  parent: ServiceTask | null;
  createdAt: string;
  updatedAt: string;
  status: boolean;
};

export type ServiceTaskForm = Pick<
  ServiceTask,
  "name" | "description" | "status"
> & {
  id?: string;
  parentId?: number | null;
};
