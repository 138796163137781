import { VehicleThumb } from "../../../../components/appearance";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleMeterEntry } from "../../../../graphql/fleets/vehicles/pages/meter-entry";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { formatDate } from "../../../../utils";

type MeterEntryDetailProps = {
  meterEntry: VehicleMeterEntry;
  vehicleBasic: VehicleBasic | null;
};
export function MeterEntryDetail({
  meterEntry,
  vehicleBasic,
}: MeterEntryDetailProps) {
  return (
    <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
      <ul className="space-y-4 md:space-y-6 xl:space-y-8">
        {vehicleBasic ? (
          <li className="grid grid-cols-2 gap-4">
            <span>Vehicle:</span>
            <VehicleThumb {...vehicleBasic} />
          </li>
        ) : null}
        <li className="grid grid-cols-2 gap-4">
          <span>Meter Date:</span>
          <span className="text-gray-500">
            {formatDate(meterEntry.meterDate)}
          </span>
        </li>
        <li className="grid grid-cols-2 gap-4">
          <span>Primary Meter:</span>
          <span className="text-gray-500">
            {meterEntry.primaryMeter}{" "}
            {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
          </span>
        </li>
      </ul>
    </div>
  );
}
