import { useLazyQuery, useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import {
  InboxArrowDownIcon,
  NewspaperIcon,
  PencilIcon,
  ReceiptRefundIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ButtonDropdown } from "../../../../../components/appearance";
import { ErrorFallback, Head } from "../../../../../components/core";
import { Button } from "../../../../../components/form";
import {
  GET_VEHICLE_BY_ID,
  Vehicle,
} from "../../../../../graphql/fleets/vehicles";
import {
  GET_VEHICLEISSUE_BY_ID,
  UPDATE_VEHICLEISSUE_STATUS,
  VehicleIssue,
  VehicleIssueStatus,
  VehicleIssueStatusForm,
} from "../../../../../graphql/fleets/vehicles/pages/issues";
import { AuthShield } from "../../../../auth/core";
import { IssuesDetail } from "../../components/IssuesDetail";
import IssueStatusForm from "../../components/IssuesStatusForm";

const IssuesView = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  let { issueId } = useParams();
  const { t } = useTranslation();

  const [openStatus, setOpenStatus] = useState(false);

  const [fetchVehicle, { data: vehicleData }] = useLazyQuery<{
    fetchVehicle: Vehicle;
  }>(GET_VEHICLE_BY_ID);

  const { data, loading, error, refetch } = useQuery<{
    fetchVehicleIssue: VehicleIssue;
  }>(GET_VEHICLEISSUE_BY_ID, {
    variables: { id: issueId },
    onCompleted(data) {
      fetchVehicle({ variables: { id: data?.fetchVehicleIssue?.vehicleId } });
    },
  });

  const issue = useMemo<VehicleIssue | undefined>(
    () => data?.fetchVehicleIssue,
    [data]
  );

  const vehicle = useMemo<Vehicle | undefined>(
    () => vehicleData?.fetchVehicle,
    [vehicleData]
  );

  const [updateVehicleIssueStatus] = useMutation(UPDATE_VEHICLEISSUE_STATUS, {
    refetchQueries: [
      { query: GET_VEHICLEISSUE_BY_ID, variables: { id: issueId } },
    ],
  });
  const handleSubmitStatus = useCallback(
    (
      values: VehicleIssueStatusForm,
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      if (!issueId || !issue) return;
      updateVehicleIssueStatus({
        variables: {
          id: issueId,
          status:
            issue.status === VehicleIssueStatus.OPEN
              ? VehicleIssueStatus.RESOLVED
              : VehicleIssueStatus.OPEN,
          statusNote: values.statusNotes,
        },
      })
        .then(async ({ data }) => {
          if (data?.vehicleIssueStatusUpdate) {
            await refetch();
            actions.setSubmitting(false);
            handleCancelStatus();
            toast.success(`Vehicle issue updated successfully`);
          } else {
            actions.setSubmitting(false);
            toast.error("Something went wrong, please try again later");
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
    },
    [issue, issueId, refetch, updateVehicleIssueStatus]
  );

  const handleCancelStatus = () => {
    setOpenStatus(false);
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={IssuesViewResource.name}
        heading={IssuesViewResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: IssuesViewResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_issue")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_issue")}
              </p>
            </div>
            <div className="ml-auto flex items-center space-x-2 pr-3">
              {issue && issue?.status !== VehicleIssueStatus.RESOLVED ? (
                <Fragment>
                  <AuthShield access={["update-vehicles"]}>
                    <Button
                      type="link"
                      href={`/fleets/vehicles/issues/${issueId}/edit`}
                    >
                      <PencilIcon className="mr-2 h-5 w-5" />
                      {t("text_edit")}
                    </Button>
                  </AuthShield>

                  <ButtonDropdown
                    variant="tertiary"
                    childrens={[
                      {
                        label: "Add to Service Entry",
                        href: `/fleets/vehicles/service-entries/add?vehicleId=${vehicle?.id}&issueId=${issueId}`,
                        target: "_blank",
                        icon: () => (
                          <WrenchIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Resolve with Note",
                        onClick: () => {
                          setOpenStatus(true);
                        },
                        icon: () => (
                          <NewspaperIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                    ]}
                  >
                    <InboxArrowDownIcon className="mr-2 h-5 w-5" />
                    {t("text_resolve_issue")}
                    <ChevronDownIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </ButtonDropdown>
                </Fragment>
              ) : (
                <AuthShield access={["update-vehicles"]}>
                  <Button
                    onClick={() => {
                      setOpenStatus(true);
                    }}
                  >
                    <ReceiptRefundIcon className="mr-2 h-5 w-5" />
                    {t("text_reopen_issue")}
                  </Button>
                </AuthShield>
              )}
            </div>
          </div>

          {loading || !issue ? (
            <Waiting />
          ) : (
            <IssuesDetail
              issue={issue}
              vehicleBasic={
                vehicle
                  ? {
                      id: parseInt(vehicle.id),
                      name: vehicle.name,
                      vehicleImageUrl: vehicle.vehicleImageUrl,
                      make: vehicle.make,
                      model: vehicle.model,
                      year: vehicle.year,
                      operator: vehicle.operator,
                      status: vehicle.status,
                      fuelUnit: vehicle?.settings?.length
                        ? vehicle?.settings[0].fuelUnit
                        : null,
                      measurementUnit: vehicle?.settings?.length
                        ? vehicle?.settings[0].measurementUnit
                        : null,
                      primaryMeter: vehicle?.settings?.length
                        ? vehicle?.settings[0].primaryMeter
                        : null,
                    }
                  : null
              }
            />
          )}
        </div>
      </div>

      <Transition.Root show={openStatus} as={Fragment} appear>
        <Dialog as="div" className="relative z-10" onClose={handleCancelStatus}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                {issue ? (
                  <IssueStatusForm
                    heading={
                      issue.status === VehicleIssueStatus.OPEN
                        ? "Resolve Issue"
                        : "Reopen Issue"
                    }
                    caption={
                      issue.status === VehicleIssueStatus.OPEN
                        ? "Resolve this issue by adding a note."
                        : "Reopen this issue by adding a note."
                    }
                    button={
                      issue.status === VehicleIssueStatus.OPEN
                        ? "Resolve"
                        : "Reopen"
                    }
                    initialValues={{
                      vehicleId: issue.vehicleId,
                      statusNotes: issue?.statusNotes || "",
                    }}
                    onSubmit={handleSubmitStatus}
                    onCancel={handleCancelStatus}
                  />
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default IssuesView;
export const IssuesViewResource: ResourceProps = {
  name: "Issue Entry",
  description: "View an existing issue",
  access: ["read-vehicles"],
  path: "/issues/:issueId",
};
