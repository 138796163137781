import "./index.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./i18n";

import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";

import { AuthProvider, client } from "./modules/auth";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./routes/AppRoutes";

const appRoot = ReactDOM.createRoot(
  document.getElementById("root-app") as HTMLElement
);
appRoot.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", function () {
//     navigator.serviceWorker
//       .register("/firebase-messaging-sw.js")
//       .then(function (registration) {
//         console.log("Registration successful, scope is:", registration.scope);
//       })
//       .catch(function (err) {
//         console.log("Service worker registration failed, error:", err);
//       });
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
