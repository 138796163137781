import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { classNames } from "../../../utils";
import { Button } from "../";
import { IFieldType, IFormula } from "./types";

type SortableItemProps = IFormula & {
  deleteItem: (id: UniqueIdentifier | string) => void;
};
export function SortableItem({
  id,
  name,
  type,
  color,
  deleteItem,
  ...props
}: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...props}
      {...listeners}
      {...attributes}
    >
      <Button
        variant="icon"
        className={classNames(
          "group inline-flex items-center rounded-sm border-0 bg-transparent py-1 text-xl outline-dotted outline-1 outline-gray-50 transition-all hover:bg-white hover:outline-gray-400",
          type === IFieldType.variable ? "px-0 hover:text-gray-700" : "px-2",
          isDragging ? "font-normal opacity-100" : "font-light opacity-75"
        )}
        style={{ color }}
        onClick={() => {
          deleteItem(id);
        }}
      >
        <div
          className={classNames(type === IFieldType.variable ? "scale-75" : "")}
        >
          {name}
        </div>
      </Button>
    </div>
  );
}
