import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query FetchCategories($status: Boolean) {
    fetchCategories(status: $status) {
      id
      name
      description
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;
export const REMOVE_CATEGORY = gql`
  mutation CategoryDelete($id: ID!) {
    categoryDelete(input: { id: $id }) {
      category {
        id
        name
      }
    }
  }
`;
export const GET_CATEGORY_BY_ID = gql`
  query FetchCategory($id: ID!) {
    fetchCategory(id: $id) {
      id
      name
      handle
      description
      parent {
        id
        name
      }
      products {
        id
        name
        featureImageUrl
        status
      }
      imageUrl
      categoryType
      conditionType
      conditions {
        conditionTitle
        conditionSymbol
        conditionValue
      }
      createdAt
      status
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation CategoryCreate(
    $name: String!
    $handle: String!
    $description: String
    $parentId: Int
    $productsIds: [Int!]
    $imageUrl: String
    $categoryType: Int
    $conditionType: Int
    $conditions: [CategoryautoconditionsInput!]
    $status: Boolean!
  ) {
    categoryCreate(
      input: {
        params: {
          name: $name
          handle: $handle
          description: $description
          parentId: $parentId
          productsIds: $productsIds
          imageUrl: $imageUrl
          categoryType: $categoryType
          conditionType: $conditionType
          conditions: $conditions
          status: $status
        }
      }
    ) {
      category {
        id
        name
        handle
        description
        parent {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const EDIT_CATEGORY = gql`
  mutation CategoryUpdate(
    $id: ID!
    $name: String!
    $handle: String!
    $parentId: Int
    $description: String
    $productsIds: [Int!]
    $imageUrl: String
    $categoryType: Int
    $conditionType: Int
    $conditions: [CategoryautoconditionsInput!]
    $status: Boolean!
  ) {
    categoryUpdate(
      input: {
        id: $id
        params: {
          name: $name
          handle: $handle
          description: $description
          parentId: $parentId
          productsIds: $productsIds
          imageUrl: $imageUrl
          categoryType: $categoryType
          conditionType: $conditionType
          conditions: $conditions
          status: $status
        }
      }
    ) {
      category {
        id
        name
        handle
        description
        parent {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export type Condition = {
  conditionTitle: string;
  conditionSymbol: string;
  conditionValue: string;
};

export type ConditionTitle = {
  label: string;
  value: "vendor_id" | "department_id";
};

export type ConditionSymbol = {
  label: string;
  value: "=" | "!=" | ">" | ">=" | "<" | "<=";
};

export type Category = {
  id: string;
  name: string;
  handle: string;
  description: string;
  parent: Category | null;
  imageUrl: string;
  categoryType: number;
  conditionType: number;
  conditions: Condition[];
  createdAt: string;
  updatedAt: string;
  status: boolean;
};
