import { FormikProps, getIn } from "formik";
import { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldDatepicker } from "../../../../components/form";
import { VehicleFinanceType } from "../../../../graphql/fleets/vehicles/pages/financial";

export default memo(function FinancialLeaseFields({
  formik,
}: {
  formik: FormikProps<any>;
}) {
  const { t } = useTranslation();

  if (formik.values.financeType !== VehicleFinanceType.LEASE) return null;

  return (
    <Fragment>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_vendor")}
          name="leaseDetails.vendor"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                vendor: e.target.value,
              },
            });
          }}
          value={formik.values.leaseDetails?.vendor ?? ""}
          touched={getIn(formik.touched, "leaseDetails.vendor")}
          errors={getIn(formik.errors, "leaseDetails.vendor")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <FieldDatepicker
          title={t("text_date_of_lease")}
          name="leaseDetails.dateOfLease"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              formik.setValues({
                ...formik.values,
                leaseDetails: {
                  ...formik.values.leaseDetails,
                  dateOfLease: value ? value.toISOString() : null,
                },
              });
            }
          }}
          selected={
            formik.values.leaseDetails?.dateOfLease
              ? new Date(formik.values.leaseDetails.dateOfLease)
              : null
          }
          touched={getIn(formik.touched, "leaseDetails.dateOfLease")}
          errors={getIn(formik.errors, "leaseDetails.dateOfLease")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_cost")}
          name="leaseDetails.cost"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                cost: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.leaseDetails?.cost ?? ""}
          touched={getIn(formik.touched, "leaseDetails.cost")}
          errors={getIn(formik.errors, "leaseDetails.cost")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_down_payment")}
          name="leaseDetails.downPayment"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                downPayment: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.leaseDetails?.downPayment ?? ""}
          touched={getIn(formik.touched, "leaseDetails.downPayment")}
          errors={getIn(formik.errors, "leaseDetails.downPayment")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <FieldDatepicker
          title={t("text_first_payment_date")}
          name="leaseDetails.firstPaymentDate"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              formik.setValues({
                ...formik.values,
                leaseDetails: {
                  ...formik.values.leaseDetails,
                  firstPaymentDate: value ? value.toISOString() : null,
                },
              });
            }
          }}
          selected={
            formik.values.leaseDetails?.firstPaymentDate
              ? new Date(formik.values.leaseDetails.firstPaymentDate)
              : null
          }
          touched={getIn(formik.touched, "leaseDetails.firstPaymentDate")}
          errors={getIn(formik.errors, "leaseDetails.firstPaymentDate")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_monthly_payment")}
          name="leaseDetails.monthlyPayment"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                monthlyPayment: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.leaseDetails?.monthlyPayment ?? ""}
          touched={getIn(formik.touched, "leaseDetails.monthlyPayment")}
          errors={getIn(formik.errors, "leaseDetails.monthlyPayment")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_no_of_payments")}
          name="leaseDetails.noOfPayments"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                noOfPayments: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.leaseDetails?.noOfPayments ?? ""}
          touched={getIn(formik.touched, "leaseDetails.noOfPayments")}
          errors={getIn(formik.errors, "leaseDetails.noOfPayments")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <FieldDatepicker
          title={t("text_lease_end_date")}
          name="leaseDetails.leaseEndDate"
          onChange={(value) => {
            if (!Array.isArray(value)) {
              formik.setValues({
                ...formik.values,
                leaseDetails: {
                  ...formik.values.leaseDetails,
                  leaseEndDate: value ? value.toISOString() : null,
                },
              });
            }
          }}
          selected={
            formik.values.leaseDetails?.leaseEndDate
              ? new Date(formik.values.leaseDetails.leaseEndDate)
              : null
          }
          touched={getIn(formik.touched, "leaseDetails.leaseEndDate")}
          errors={getIn(formik.errors, "leaseDetails.leaseEndDate")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        {/* <p>(Value of the vehicle at the end of the lease)</p> */}
        <Field
          title={t("text_residual_value")}
          name="leaseDetails.residualValue"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                residualValue: e.target.value,
              },
            });
          }}
          value={formik.values.leaseDetails?.residualValue ?? ""}
          touched={getIn(formik.touched, "leaseDetails.residualValue")}
          errors={getIn(formik.errors, "leaseDetails.residualValue")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_contract_mileage_cap")}
          name="leaseDetails.contractMileageCap"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                contractMileageCap: e.target.value,
              },
            });
          }}
          value={formik.values.leaseDetails?.contractMileageCap ?? ""}
          touched={getIn(formik.touched, "leaseDetails.contractMileageCap")}
          errors={getIn(formik.errors, "leaseDetails.contractMileageCap")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_excess_mileage_cap")}
          name="leaseDetails.excessMileageCap"
          type="number"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                excessMileageCap: parseFloat(e.target.value),
              },
            });
          }}
          value={formik.values.leaseDetails?.excessMileageCap ?? ""}
          touched={getIn(formik.touched, "leaseDetails.excessMileageCap")}
          errors={getIn(formik.errors, "leaseDetails.excessMileageCap")}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Field
          title={t("text_lease_number")}
          name="leaseDetails.leaseNumber"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                leaseNumber: e.target.value,
              },
            });
          }}
          value={formik.values.leaseDetails?.leaseNumber ?? ""}
          touched={getIn(formik.touched, "leaseDetails.leaseNumber")}
          errors={getIn(formik.errors, "leaseDetails.leaseNumber")}
        />
      </div>
      <div className="col-span-12 sm:col-span-12">
        <Field
          title={t("text_notes")}
          name="leaseDetails.notes"
          type="textarea"
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              leaseDetails: {
                ...formik.values.leaseDetails,
                notes: e.target.value,
              },
            });
          }}
          value={formik.values.leaseDetails?.notes ?? ""}
          touched={getIn(formik.touched, "leaseDetails.notes")}
          errors={getIn(formik.errors, "leaseDetails.notes")}
        />
      </div>
    </Fragment>
  );
});
