import { gql } from "@apollo/client/core";

import { formatDate } from "../../../utils";
import { PreviewProduct } from "../../inventory/products";
import { Order } from "../orders";

export const BASIC_CUSTOMER_FRAGMENT = gql`
  fragment BasicCustomerFragment on Customer {
    id
    customerName
    customerPlu
  }
`;

export const GET_CUSTOMERS_BASIC = gql`
  query FetchCustomersBasic {
    fetchCustomers {
      id
      email
      customerCode
      customerName
      profileImageUrl
      customerType
      tradingAddresses {
        address
        suburb
        state
        postcode
        primaryAddress
      }
      status
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query FetchCustomers {
    fetchCustomers {
      id
      abnNo
      email
      createdAt
      creditApplicationStatus
      creditLimit
      customerCode
      customerGroups {
        id
        name
      }
      customerContact {
        id
      }
      customerName
      companyName
      profileImageUrl
      customerPlu
      customerType
      deliveryInstruction
      deliveryRuns {
        id
        name
      }
      packingInstruction
      salesRep {
        id
        fullName
      }
      storeContact
      storeEmail
      storeFax
      storePhoneNumber
      tradingAddresses {
        address
        suburb
        state
        postcode
        primaryAddress
      }
      status
    }
  }
`;

export const UPDATE_CUSTOMER_STATUS = gql`
  mutation CustomerStatusUpdate($id: ID!, $status: Int!) {
    customerStatusUpdate(input: { id: $id, status: $status }) {
      message
    }
  }
`;

export const SEND_ACCOUNT_INVITATION = gql`
  mutation SendAccountInvitation($id: ID!) {
    sendAccountInvitation(input: { id: $id }) {
      message
    }
  }
`;

export const SEND_BULK_ACCOUNT_INVITATION = gql`
  mutation SendBulkAccountInvitation($ids: [ID!]!) {
    sendBulkAccountInvitation(input: { ids: $ids }) {
      message
    }
  }
`;

export const REMOVE_CUSTOMER = gql`
  mutation CustomerDelete($id: ID!) {
    customerDelete(input: { id: $id }) {
      customer {
        id
        customerName
      }
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query FetchCustomer($id: ID!) {
    fetchCustomer(id: $id) {
      id
      abnNo
      email
      createdAt
      creditApplicationStatus
      creditLimit
      customerCode
      customerGroups {
        id
        name
      }
      customerName
      companyName
      customerContact {
        id
      }
      profileImageUrl
      customerPlu
      customerType
      deliveryInstruction
      deliveryRuns {
        id
        name
      }
      packingInstruction
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
        productType
        structureProducts {
          id
          name
          featureImageUrl
          status
        }
        customers {
          id
          customerName
        }
        status
      }
      salesRep {
        id
        fullName
      }
      storeContact
      storeEmail
      storeFax
      storePhoneNumber
      tradingAddresses {
        id
        address
        suburb
        state
        postcode
        primaryAddress
      }
      orders {
        id
        orderNumber
        customer {
          id
          customerName
          email
          tradingAddresses {
            id
            address
            suburb
            state
            postcode
          }
        }
        items {
          id
          price
          quantity
          variant {
            product {
              id
              name
              handle
              description
              featureImageUrl
            }
            id
            stockCode
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
            minimumQuantity
            inventory
          }
        }
        notes
        shippingAddress {
          id
          address
          suburb
          state
          postcode
        }
        status
        subTotal
        total
        updatedAt
        createdAt
      }
      status
    }
  }
`;

export const GET_CUSTOMER_PRICINGSTRUCTURES = gql`
  query FetchCustomerPricingStructures($id: ID!) {
    fetchCustomer(id: $id) {
      id
      pricingStructures {
        id
        name
        pricingMethod {
          id
          name
        }
        productType
        structureProducts {
          id
          name
          featureImageUrl
          status
        }
        customers {
          id
          customerName
        }
        status
      }
    }
  }
`;

export const GET_CUSTOMER_TRADINGADDRESSES = gql`
  query FetchCustomerTradingAddresses($id: ID!) {
    fetchCustomer(id: $id) {
      id
      tradingAddresses {
        id
        address
        suburb
        state
        postcode
        primaryAddress
      }
    }
  }
`;

export const GET_CUSTOMERS_PREVIEW = gql`
  query FetchCustomers {
    fetchCustomers {
      id
      customerName
      status
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation CustomerCreate(
    $customerName: String
    $companyName: String
    $profileImageUrl: String
    $customerType: Int!
    $email: String!
    $storeContact: String
    $storeEmail: String!
    $storePhoneNumber: String
    $storeFax: String
    $abnNo: String!
    $customerPlu: String!
    $packingInstruction: String
    $deliveryInstruction: String
    $salesRepId: Int
    $deliveryRunId: Int
    $creditApplicationStatus: Boolean
    $creditLimit: Float
    $customerGroupIds: [Int!]
    $tradingAddresses: [CustomertradingaddressInput!]
  ) {
    customerCreate(
      input: {
        params: {
          customerName: $customerName
          companyName: $companyName
          profileImageUrl: $profileImageUrl
          customerType: $customerType
          email: $email
          storeContact: $storeContact
          storeEmail: $storeEmail
          storePhoneNumber: $storePhoneNumber
          storeFax: $storeFax
          abnNo: $abnNo
          customerPlu: $customerPlu
          packingInstruction: $packingInstruction
          deliveryInstruction: $deliveryInstruction
          salesRepId: $salesRepId
          deliveryRunId: $deliveryRunId
          creditApplicationStatus: $creditApplicationStatus
          creditLimit: $creditLimit
          customerGroupIds: $customerGroupIds
          tradingAddresses: $tradingAddresses
        }
      }
    ) {
      customer {
        id
        abnNo
        email
        createdAt
        creditApplicationStatus
        creditLimit
        customerCode
        customerGroups {
          id
          name
        }
        customerName
        companyName
        profileImageUrl
        customerPlu
        customerType
        deliveryInstruction
        deliveryRuns {
          id
          name
        }
        packingInstruction
        salesRep {
          id
          fullName
        }
        storeContact
        storeEmail
        storeFax
        storePhoneNumber
        tradingAddresses {
          address
          suburb
          state
          postcode
          primaryAddress
        }
        status
      }
    }
  }
`;

export const EDIT_CUSTOMER = gql`
  mutation CustomerUpdate(
    $id: ID!
    $customerName: String
    $companyName: String
    $profileImageUrl: String
    $customerType: Int!
    $email: String!
    $storeContact: String
    $storeEmail: String!
    $storePhoneNumber: String
    $storeFax: String
    $abnNo: String!
    $customerPlu: String!
    $packingInstruction: String
    $deliveryInstruction: String
    $salesRepId: Int
    $deliveryRunId: Int
    $creditApplicationStatus: Boolean
    $creditLimit: Float
    $customerGroupIds: [Int!]
    $tradingAddresses: [CustomertradingaddressInput!]
  ) {
    customerUpdate(
      input: {
        id: $id
        params: {
          customerName: $customerName
          companyName: $companyName
          profileImageUrl: $profileImageUrl
          customerType: $customerType
          email: $email
          storeContact: $storeContact
          storeEmail: $storeEmail
          storePhoneNumber: $storePhoneNumber
          storeFax: $storeFax
          abnNo: $abnNo
          customerPlu: $customerPlu
          packingInstruction: $packingInstruction
          deliveryInstruction: $deliveryInstruction
          salesRepId: $salesRepId
          deliveryRunId: $deliveryRunId
          creditApplicationStatus: $creditApplicationStatus
          creditLimit: $creditLimit
          customerGroupIds: $customerGroupIds
          tradingAddresses: $tradingAddresses
        }
      }
    ) {
      customer {
        id
        abnNo
        email
        createdAt
        creditApplicationStatus
        creditLimit
        customerCode
        customerGroups {
          id
          name
        }
        customerName
        companyName
        profileImageUrl
        customerPlu
        customerType
        deliveryInstruction
        deliveryRuns {
          id
          name
        }
        packingInstruction
        salesRep {
          id
          fullName
        }
        storeContact
        storeEmail
        storeFax
        storePhoneNumber
        tradingAddresses {
          address
          suburb
          state
          postcode
          primaryAddress
        }
        status
      }
    }
  }
`;

export const GET_PRICINGSTRUCTURE_BY_ID = gql`
  query FetchPricingStructure($id: ID!, $structureId: Int!) {
    fetchPricingStructure(id: $id) {
      id
      name
      pricingMethod {
        id
        name
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
      structureProducts {
        id
        name
        featureImageUrl
        hasVariants
        variants {
          id
          stockCode
          productId
          basePrice
          comparePrice
          cost
          marketCost
          structureDetails(structureId: $structureId) {
            id
            priceFields
            sellPrice
            costUpdated
          }
          variantTitle {
            id
            name
            option {
              id
              name
            }
          }
        }
        status
      }
      customers {
        id
        customerName
      }
      createdAt
      status
    }
  }
`;

export const ADD_PRICINGSTRUCTUREPRICING = gql`
  mutation PricingStructurePricingCreate(
    $pricingStructureId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    pricingStructurePricingCreate(
      input: {
        params: {
          pricingStructureId: $pricingStructureId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export const EDIT_PRICINGSTRUCTUREPRICING = gql`
  mutation PricingStructurePricingUpdate(
    $id: ID!
    $pricingStructureId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    pricingStructurePricingUpdate(
      input: {
        id: $id
        params: {
          pricingStructureId: $pricingStructureId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export type PreviewCustomer = {
  id: string;
  customerName: string;
  profileImageUrl: string;
  status: number;
};

export const FETCH_ABN_DETAILS = gql`
  query FetchAbnDetails($abnNo: String!) {
    fetchAbnDetails(abnNo: $abnNo) {
      details
    }
  }
`;

export type IAbnDetails = {
  name: string;
  activeFrom: string;
  registeredFrom: string;
  type: string;
  address: string;
  business: string[];
};

export type IFetchAbnDetails = {
  details: {
    businessEntity201205: {
      ASICNumber: string;
      mainName: {
        effectiveFrom: string;
        organisationName: string;
      };
      entityStatus: {
        effectiveFrom: string;
        effectiveTo: string;
        entityStatusCode: string;
      };
      entityType: {
        entityDescription: string;
        entityTypeCode: string;
      };
      goodsAndServicesTax: {
        effectiveFrom: string;
        effectiveTo: string;
      };
      businessName:
        | {
            effectiveFrom: string;
            organisationName: string;
          }
        | {
            effectiveFrom: string;
            organisationName: string;
          }[];
      mainBusinessPhysicalAddress: {
        effectiveFrom: string;
        effectiveTo: string;
        postcode: string;
        stateCode: string;
      };
    };
  };
};

export const formatAbnDetails = (
  data: IFetchAbnDetails
): IAbnDetails | null => {
  const { businessEntity201205 } = data?.details;
  if (!businessEntity201205) return null;

  const {
    entityStatus,
    goodsAndServicesTax,
    mainName,
    mainBusinessPhysicalAddress,
  } = businessEntity201205;

  const business = [];
  if (Array.isArray(mainName)) {
    business.push(...mainName.flatMap((item) => item.organisationName));
  } else {
    business.push(mainName.organisationName);
  }

  const formattedAbnDetails = {
    name: mainName?.organisationName,
    activeFrom: formatDate(entityStatus.effectiveFrom),
    registeredFrom: formatDate(goodsAndServicesTax.effectiveFrom),
    type: businessEntity201205.entityType.entityDescription,
    address:
      mainBusinessPhysicalAddress?.stateCode +
      " " +
      mainBusinessPhysicalAddress?.postcode,
    business,
  };
  return formattedAbnDetails;
};

type User = {
  id: string;
  fullName: string;
  email: string;
  roles: {
    roleName: string;
  }[];
  createdAt: string;
  status: boolean;
};

type CustomerGroup = {
  id: string;
  name: string;
  description: string;
  customers: Customer[];
  createdAt: string;
  status: boolean;
};

type DeliveryRun = {
  id: string;
  name: string;
  dock: {
    id: string;
    name: string;
  };
  createdAt: string;
  status: boolean;
};

export type TradingAddress = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  primaryAddress: boolean;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  structureProducts: PreviewProduct[];
  customers: PreviewCustomer[];
  status: boolean;
};
export enum CustomerType {
  INDIVIDUAL,
  COMPANY,
}
export type Customer = {
  id: string;
  abnNo: string;
  email: string;
  createdAt: string;
  creditApplicationStatus: boolean;
  creditLimit: number;
  customerCode: string;
  customerGroups: CustomerGroup[];
  customerName: string;
  companyName: string;
  customerContact: {
    id: string;
  }[];
  profileImageUrl: string;
  orders: Order[];
  customerPlu: string;
  customerType: CustomerType;
  deliveryInstruction: string;
  deliveryRuns: DeliveryRun;
  packingInstruction: string;
  pricingStructures: PricingStructure[];
  salesRep: User;
  storeContact: string;
  storeEmail: string;
  storeFax: string;
  storePhoneNumber: string;
  tradingAddresses: TradingAddress[];
  status: number;
};

export type CustomerBasic = Pick<
  Customer,
  | "id"
  | "email"
  | "customerCode"
  | "customerName"
  | "profileImageUrl"
  | "customerType"
  | "tradingAddresses"
  | "status"
>;

export const renderCustomerType = (type: CustomerType) => {
  switch (type) {
    case CustomerType.INDIVIDUAL:
      return "Individual";
    case CustomerType.COMPANY:
      return "Company";
    default:
      return "";
  }
};
