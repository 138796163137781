import { isAuthorizedForResource, useAuth } from "..";

export function AuthShield({
  access,
  children,
}: {
  access: string[];
  children: JSX.Element;
}) {
  const { currentRole } = useAuth();

  if (isAuthorizedForResource(currentRole, access)) return children;

  return null;
}
