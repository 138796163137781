import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import { GET_CUSTOMERCONTACTS } from "../../../../graphql/sales/settings/customer-contacts";
import Form from "./components/Form";

type Customer = {
  id: string;
  customerName: string;
};
type ContactDetails = {
  name: string;
  contactType: any;
  email: string;
  phoneNumber: string;
  sendSms: boolean;
};
type CustomerContact = {
  id: string;
  contactDetails: ContactDetails[];
  customer: Customer;
  emailImportantUpdates: boolean;
  emailSpecials: boolean;
  firstName: string;
  mobileNumber: string;
  phoneNumber: string;
  postalAddress: string;
  postcode: string;
  state: string;
  suburb: string;
  surName: string;
};

const GET_CUSTOMERCONTACT_BY_ID = gql`
  query FetchCustomerContact($id: ID!) {
    fetchCustomerContact(id: $id) {
      id
      contactDetails {
        id
        name
        contactType {
          id
          name
        }
        phoneNumber
        email
        sendSms
      }
      createdAt
      customer {
        id
        customerName
      }
      emailImportantUpdates
      emailSpecials
      firstName
      mobileNumber
      phoneNumber
      postalAddress
      postcode
      state
      suburb
      surName
    }
  }
`;

const CustomerContactUpdate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  let { customerContactId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchCustomerContact: CustomerContact;
  }>(GET_CUSTOMERCONTACT_BY_ID, {
    variables: {
      id: customerContactId,
    },
  });
  const customerContact = useMemo<CustomerContact | undefined>(
    () => data?.fetchCustomerContact,
    [data]
  );

  const UPDATE_CUSTOMERCONTACT = gql`
    mutation CustomerContactUpdate(
      $id: ID!
      $customerId: Int!
      $firstName: String
      $surName: String
      $phoneNumber: String
      $mobileNumber: String
      $postalAddress: String
      $suburb: String
      $state: String
      $postcode: String
      $contactDetails: [ContactdetailInput!]
      $emailSpecials: Boolean
      $emailImportantUpdates: Boolean
    ) {
      customerContactUpdate(
        input: {
          id: $id
          params: {
            customerId: $customerId
            firstName: $firstName
            surName: $surName
            phoneNumber: $phoneNumber
            mobileNumber: $mobileNumber
            postalAddress: $postalAddress
            suburb: $suburb
            state: $state
            postcode: $postcode
            contactDetails: $contactDetails
            emailSpecials: $emailSpecials
            emailImportantUpdates: $emailImportantUpdates
          }
        }
      ) {
        customerContact {
          id
          contactDetails {
            id
            name
            contactType {
              id
              name
            }
            phoneNumber
            email
            sendSms
          }
          createdAt
          customer {
            id
            customerName
          }
          emailImportantUpdates
          emailSpecials
          firstName
          mobileNumber
          phoneNumber
          postalAddress
          postcode
          state
          suburb
          surName
        }
      }
    }
  `;
  const [updateCustomerContact] = useMutation(UPDATE_CUSTOMERCONTACT, {
    refetchQueries: [{ query: GET_CUSTOMERCONTACTS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateCustomerContact({
      variables: {
        id: customerContactId,
        customerId: values.customer ? parseInt(values.customer.value) : null,
        firstName: values.firstName,
        surName: values.surName,
        phoneNumber: values.phoneNumber,
        mobileNumber: values.mobileNumber,
        postalAddress: values.postalAddress,
        suburb: values.suburb,
        state: values.state,
        postcode: values.postcode,
        contactDetails: values.contactDetails
          ? values.contactDetails.map((item: ContactDetails) => ({
              name: item.name,
              contactTypeId: item.contactType
                ? parseInt(item.contactType.value)
                : null,
              email: item.email,
              phoneNumber: item.phoneNumber,
              sendSms: item.sendSms,
            }))
          : [],
        emailSpecials: values.emailSpecials,
        emailImportantUpdates: values.emailImportantUpdates,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerContactUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "CustomerContact updated successfully",
          });
          return navigate(`/sales/settings/customer-contacts`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "CustomerContact update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "CustomerContact update failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_update_customer_contact")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_update_customer_contact")}
          </p>
        </div>
        <div className="mt-4 flex sm:ml-16 sm:mt-0">
          <Link to="/sales/settings/customer-contacts" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_update")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title={CustomerContactUpdateResource.name}
        heading="Customer Contacts"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customer Contacts",
            href: "/sales/settings/customer-contacts",
          },
          {
            name: CustomerContactUpdateResource.name,
            href: null,
          },
        ]}
      />
      {loading || !customerContact ? (
        <Waiting />
      ) : (
        <Form
          initialValues={{
            customer: customerContact.customer
              ? {
                  value: customerContact.customer?.id,
                  label: customerContact.customer?.customerName,
                }
              : null,
            firstName: customerContact.firstName ?? "",
            surName: customerContact.surName ?? "",
            phoneNumber: customerContact.phoneNumber ?? "",
            mobileNumber: customerContact.mobileNumber ?? "",
            postalAddress: customerContact.postalAddress ?? "",
            suburb: customerContact.suburb ?? "",
            state: customerContact.state ?? "",
            postcode: customerContact.postcode ?? "",
            contactDetails: customerContact.contactDetails
              ? customerContact.contactDetails.map((item: ContactDetails) => {
                  return {
                    contactType: item?.contactType
                      ? {
                          value: item?.contactType?.id,
                          label: item?.contactType?.name,
                        }
                      : null,
                    name: item.name,
                    email: item.email,
                    phoneNumber: item.phoneNumber,
                    sendSms: item.sendSms,
                  };
                })
              : [],
            emailSpecials: customerContact.emailSpecials ?? false,
            emailImportantUpdates:
              customerContact.emailImportantUpdates ?? false,
          }}
          onSubmit={handleSubmit}
          header={<FormHeader />}
        />
      )}
    </>
  );
};

export default CustomerContactUpdate;
export const CustomerContactUpdateResource: ResourceProps = {
  name: "Edit CustomerContact",
  description: "Update an existing customer contact",
  access: ["update-customers"],
  path: "customer-contacts/:customerContactId",
};
