import { useTranslation } from "react-i18next";

import { VehicleThumb } from "../../../../components/appearance";
import {
  FieldFleetVendor,
  FieldVehicleExpenseType,
} from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleExpenseEntry } from "../../../../graphql/fleets/vehicles/pages/expense-entry";
import { formatCurrency, formatDate } from "../../../../utils";

type ExpenseEntryDetailProps = {
  expenseEntry: VehicleExpenseEntry;
  vehicleBasic: VehicleBasic | null;
};
export function ExpenseEntryDetail({
  expenseEntry,
  vehicleBasic,
}: ExpenseEntryDetailProps) {
  const { t } = useTranslation();

  return (
    <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
      <ul className="space-y-4 md:space-y-6 xl:space-y-8">
        {vehicleBasic ? (
          <li className="grid grid-cols-2 gap-4">
            <span>Vehicle:</span>
            <VehicleThumb {...vehicleBasic} />
          </li>
        ) : null}
        <li className="grid grid-cols-2 gap-4">
          <span>Expense Type:</span>
          <FieldVehicleExpenseType
            title={t("text_vehicle_expense_type")}
            value={expenseEntry.vehicleExpenseTypeId}
            isPreview
          />
        </li>
        <li className="grid grid-cols-2 gap-4">
          <span>Fleet Vendor:</span>
          <FieldFleetVendor
            title={t("text_fleet_vendor")}
            value={expenseEntry.fleetVendorId}
            isPreview
          />
        </li>
        <li className="grid grid-cols-2 gap-4">
          <span>Amount:</span>
          <span className="text-gray-500">
            {formatCurrency(expenseEntry.amount ?? 0)}
          </span>
        </li>
        <li className="grid grid-cols-2 gap-4">
          <span>Expense Date:</span>
          <span className="text-gray-500">
            {formatDate(expenseEntry.expenseDate)}
          </span>
        </li>
        <li className="grid grid-cols-2 gap-4">
          <span>Notes:</span>
          <span className="text-gray-500">{expenseEntry.notes}</span>
        </li>
      </ul>
    </div>
  );
}
