import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldFleetVendor,
  FieldVehicle,
  FieldVehicleExpenseType,
} from "../../../../components/form";
import { FleetVendorClassification } from "../../../../graphql/fleets/settings/vendors";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleExpenseEntryForm } from "../../../../graphql/fleets/vehicles/pages/expense-entry";
import { classNames, useFormikErrors } from "../../../../utils";

export default function Form({
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
}: {
  initialValues: VehicleExpenseEntryForm;
  onSubmit: (values: VehicleExpenseEntryForm, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
}) {
  const { vehicleId } = useParams();
  const { t } = useTranslation();

  const [vehicleBasic, setVehicleBasic] = useState<VehicleBasic | null>(null);

  const ValidationSchema = Yup.object().shape({
    vehicleId: Yup.number().when(".", {
      is: (val: unknown) => vehicleId === undefined,
      then: Yup.number().typeError("Must be a number").required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    vehicleExpenseTypeId: Yup.number()
      .typeError("Must be a number")
      .required("Required"),
    fleetVendorId: Yup.number().nullable(),
    amount: Yup.number().typeError("Must be a number").required("Required"),
    expenseDate: Yup.string().typeError("Required").required("Required"),
    notes: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          {vehicleId === undefined ? (
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_vehicle")}
              </label>
              <FieldVehicle
                value={formik.values.vehicleId}
                onChange={(value) => {
                  formik.setFieldValue("vehicleId", value ? value.id : null);
                  setVehicleBasic(value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.vehicleId && formik.errors.vehicleId
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.vehicleId && formik.errors.vehicleId ? (
                <p className="mt-2 text-sm text-red-600" id="vehicleId-errors">
                  {formik.errors.vehicleId}
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_vehicle_expense_type")}
            </label>
            <FieldVehicleExpenseType
              title={t("text_vehicle_expense_type")}
              value={formik.values.vehicleExpenseTypeId}
              onChange={(value) => {
                formik.setFieldValue("vehicleExpenseTypeId", value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.vehicleExpenseTypeId &&
                  formik.errors.vehicleExpenseTypeId
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            <p className="mt-1 text-xs text-gray-500">
              Type to create new vehicle expense type
            </p>
            {formik.touched.vehicleExpenseTypeId &&
            formik.errors.vehicleExpenseTypeId ? (
              <p
                className="mt-2 text-sm text-red-600"
                id="vehicleExpenseTypeId-errors"
              >
                {formik.errors.vehicleExpenseTypeId}
              </p>
            ) : null}
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_fleet_vendor")}
            </label>
            <FieldFleetVendor
              title={t("text_fleet_vendor")}
              value={formik.values.fleetVendorId}
              onChange={(value) => {
                formik.setFieldValue("fleetVendorId", value);
              }}
              classification={FleetVendorClassification.VEHICLE}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.fleetVendorId && formik.errors.fleetVendorId
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.fleetVendorId && formik.errors.fleetVendorId ? (
              <p
                className="mt-2 text-sm text-red-600"
                id="fleetVendorId-errors"
              >
                {formik.errors.fleetVendorId}
              </p>
            ) : null}
          </div>
          <div className="sm:col-span-3">
            <Field
              title={t("text_amount")}
              name="amount"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amount ?? ""}
              touched={formik.touched.amount}
              errors={formik.errors.amount}
            />
          </div>
          <div className="sm:col-span-3">
            <FieldDatepicker
              title={t("text_expense_date")}
              name="expenseDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "expenseDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.expenseDate
                  ? new Date(formik.values.expenseDate)
                  : null
              }
              touched={formik.touched.expenseDate}
              errors={formik.errors.expenseDate}
            />
          </div>
          <div className="sm:col-span-6">
            <Field
              title={t("text_notes")}
              name="notes"
              type="textarea"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notes}
              touched={formik.touched.notes}
              errors={formik.errors.notes}
            />
          </div>
        </div>
        <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={onCancel}
          >
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <Fragment>
                <Spinner />
                {t("text_processing")}
              </Fragment>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
