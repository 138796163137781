import { gql } from "@apollo/client";

import { classNames } from "../../../../../utils";
import { FleetVendor } from "../../../settings/vendors";
import { Vehicle } from "../..";
import { VehicleIssue, VEHICLEISSUE_FRAGMENT } from "../issues";
import {
  SERVICEREMAINDER_FRAGMENT,
  VehicleServiceReminder,
} from "../service-reminder";

export const SERVICEENTRY_LINEITEM_FRAGMENT = gql`
  ${SERVICEREMAINDER_FRAGMENT}
  fragment VehicleServiceEntryLineItemFragment on VehicleServiceEntryLineItem {
    id
    laborPrice
    notes
    partsPrice
    subtotal
    createdAt
    updatedAt
    serviceRemainder {
      ...VehicleServiceRemainderFragment
    }
  }
`;
export const SERVICEENTRY_FRAGMENT = gql`
  ${SERVICEENTRY_LINEITEM_FRAGMENT}
  ${VEHICLEISSUE_FRAGMENT}
  fragment VehicleServiceEntryFragment on VehicleServiceEntry {
    id
    comments
    completionDate
    createdAt
    discount
    discountType
    generalNotes
    odometer
    referenceNo
    repairPriority
    startDate
    subtotal
    tax
    total
    totalLaborPrice
    totalPartsPrice
    updatedAt
    fleetVendor {
      id
      name
    }
    vehicle {
      id
      name
    }
    lineItems {
      ...VehicleServiceEntryLineItemFragment
    }
    serviceIssues {
      ...VehicleIssueFragment
    }
  }
`;

export const GET_SERVICEENTRIES = gql`
  ${SERVICEENTRY_FRAGMENT}
  query FetchServiceEntries {
    fetchServiceEntries {
      ...VehicleServiceEntryFragment
    }
  }
`;

export const GET_SERVICEENTRY_BY_VEHICLE_ID = gql`
  ${SERVICEENTRY_FRAGMENT}
  query FetchServiceEntriesByVehicle($vehicleId: ID!) {
    fetchServiceEntriesByVehicle(vehicleId: $vehicleId) {
      ...VehicleServiceEntryFragment
    }
  }
`;

export const GET_SERVICEENTRY_BY_ID = gql`
  ${SERVICEENTRY_FRAGMENT}
  query FetchServiceEntry($id: ID!) {
    fetchServiceEntry(id: $id) {
      ...VehicleServiceEntryFragment
    }
  }
`;

export const ADD_SERVICEENTRY = gql`
  ${SERVICEENTRY_FRAGMENT}
  mutation VehicleServiceEntryCreate(
    $vehicleId: Int!
    $repairPriority: Int
    $odometer: Int!
    $completionDate: ISO8601DateTime!
    $startDate: ISO8601DateTime
    $referenceNo: String
    $fleetVendorId: Int
    $totalLaborPrice: Float
    $totalPartsPrice: Float
    $subtotal: Float
    $total: Float
    $discount: Float
    $discountType: Int
    $tax: Float
    $generalNotes: String
    $comments: String
    $issues: [Int!]
    $lineItems: [VehicleserviceentrylineitemInput!]
  ) {
    vehicleServiceEntryCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          repairPriority: $repairPriority
          odometer: $odometer
          completionDate: $completionDate
          startDate: $startDate
          referenceNo: $referenceNo
          fleetVendorId: $fleetVendorId
          totalLaborPrice: $totalLaborPrice
          totalPartsPrice: $totalPartsPrice
          subtotal: $subtotal
          total: $total
          discount: $discount
          discountType: $discountType
          tax: $tax
          generalNotes: $generalNotes
          comments: $comments
          issues: $issues
          lineItems: $lineItems
        }
      }
    ) {
      vehicleServiceEntry {
        ...VehicleServiceEntryFragment
      }
    }
  }
`;

export const EDIT_SERVICEENTRY = gql`
  ${SERVICEENTRY_FRAGMENT}
  mutation VehicleServiceEntryUpdate(
    $id: ID!
    $vehicleId: Int!
    $repairPriority: Int
    $odometer: Int!
    $completionDate: ISO8601DateTime!
    $startDate: ISO8601DateTime
    $referenceNo: String
    $fleetVendorId: Int
    $totalLaborPrice: Float
    $totalPartsPrice: Float
    $subtotal: Float
    $total: Float
    $discount: Float
    $discountType: Int
    $tax: Float
    $generalNotes: String
    $comments: String
    $issues: [Int!]
    $lineItems: [VehicleserviceentrylineitemInput!]
  ) {
    vehicleServiceEntryUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          repairPriority: $repairPriority
          odometer: $odometer
          completionDate: $completionDate
          startDate: $startDate
          referenceNo: $referenceNo
          fleetVendorId: $fleetVendorId
          totalLaborPrice: $totalLaborPrice
          totalPartsPrice: $totalPartsPrice
          subtotal: $subtotal
          total: $total
          discount: $discount
          discountType: $discountType
          tax: $tax
          generalNotes: $generalNotes
          comments: $comments
          issues: $issues
          lineItems: $lineItems
        }
      }
    ) {
      vehicleServiceEntry {
        ...VehicleServiceEntryFragment
      }
    }
  }
`;

export const REMOVE_SERVICEENTRY = gql`
  ${SERVICEENTRY_FRAGMENT}
  mutation VehicleServiceEntryDelete($id: ID!) {
    vehicleServiceEntryDelete(input: { id: $id }) {
      vehicleServiceEntry {
        ...VehicleServiceEntryFragment
      }
    }
  }
`;

export enum VehicleServiceRepairPriority {
  SHELDULED,
  NON_SHEDULED,
  EMERGENCY,
}
export type VehicleServiceEntryLineItem = {
  id: string;
  laborPrice: number | null;
  notes: string;
  partsPrice: number | null;
  subtotal: number | null;
  createdAt: string;
  updatedAt: string;
  serviceRemainder: VehicleServiceReminder;
};
export enum VehicleServiceEntryDiscountType {
  PERCENTAGE,
  FIXED,
}
export type VehicleServiceEntry = {
  id: string;
  comments: string;
  completionDate: string | null;
  discount: number | null;
  discountType: VehicleServiceEntryDiscountType | null;
  fleetVendor: FleetVendor | null;
  vehicle: Vehicle;
  generalNotes: string;
  odometer: number | null;
  referenceNo: string;
  repairPriority: VehicleServiceRepairPriority | null;
  startDate: string | null;
  subtotal: number | null;
  tax: number | null;
  total: number | null;
  totalLaborPrice: number | null;
  totalPartsPrice: number | null;
  createdAt: string;
  updatedAt: string;
  lineItems: VehicleServiceEntryLineItem[];
  serviceIssues: VehicleIssue[];
};

type VehicleServiceEntryLineItemForm = Omit<
  VehicleServiceEntryLineItem,
  "id" | "createdAt" | "updatedAt" | "serviceRemainder"
> & {
  vehicleServiceRemainderId: number;
  remainderTimeInterval: number | null;
  remainderTimeIntervalUnit: string;
};

export type VehicleServiceEntryForm = Omit<
  VehicleServiceEntry,
  | "id"
  | "fleetVendor"
  | "vehicle"
  | "createdAt"
  | "updatedAt"
  | "lineItems"
  | "serviceIssues"
> & {
  id?: string;
  vehicleId: number | null;
  fleetVendorId: number | null;
  issues: number[];
  lineItems: VehicleServiceEntryLineItemForm[];
};

export const renderRepairPriority = (
  status: VehicleServiceRepairPriority | null
) => {
  switch (status) {
    case VehicleServiceRepairPriority.SHELDULED:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-gray-200")}
            aria-hidden="true"
          />
          Scheduled
        </span>
      );
    case VehicleServiceRepairPriority.NON_SHEDULED:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-green-800")}
            aria-hidden="true"
          />
          Non-Scheduled
        </span>
      );
    case VehicleServiceRepairPriority.EMERGENCY:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-yellow-800")}
            aria-hidden="true"
          />
          Emergency
        </span>
      );
    default:
      return null;
  }
};

export const renderRepairPriorityText = (
  status: VehicleServiceRepairPriority | null
) => {
  switch (status) {
    case VehicleServiceRepairPriority.SHELDULED:
      return "Scheduled";
    case VehicleServiceRepairPriority.NON_SHEDULED:
      return "Non Scheduled";
    case VehicleServiceRepairPriority.EMERGENCY:
      return "Emergency";
    default:
      return null;
  }
};
