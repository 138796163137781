import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment, useRef } from "react";

import { classNames } from "../../utils";

export enum PopoverPosition {
  LEFT,
  RIGHT,
}

type PopoverProps = React.ComponentProps<typeof Popover.Button>;
type ButtonPopoverProps = PopoverProps & {
  title: string;
  child: React.ReactNode;
  timeout?: number;
  isArrow?: boolean;
  position?: PopoverPosition;
  children: React.ReactNode;
};
export function ButtonPopover({
  title,
  child,
  timeout = 120,
  className = "",
  isArrow = true,
  children,
  position = PopoverPosition.LEFT,
  ...props
}: ButtonPopoverProps) {
  const triggerPopoverRef = useRef<HTMLButtonElement | null>(null);
  const timeOutPopoverRef = useRef<NodeJS.Timeout>();

  const handlePopoverEnter = (isOpen: boolean) => {
    if (!timeout) return;
    clearTimeout(timeOutPopoverRef.current);
    !isOpen && triggerPopoverRef.current?.click();
  };

  const handlePopoverLeave = (isOpen: boolean) => {
    if (!timeout) return;
    timeOutPopoverRef.current = setTimeout(() => {
      isOpen && triggerPopoverRef.current?.click();
    }, timeout);
  };

  return (
    <Popover className="relative inline-flex">
      {({ open }) => (
        <div
          onMouseEnter={() => handlePopoverEnter(open)}
          onMouseLeave={() => handlePopoverLeave(open)}
        >
          <Popover.Button
            ref={triggerPopoverRef}
            {...props}
            className={classNames(
              "group inline-flex items-center whitespace-nowrap p-0 text-current transition focus-visible:outline-none",
              className
            )}
          >
            {child}
            {isArrow && (
              <ChevronDownIcon
                className={classNames(
                  open ? "" : "text-opacity-70",
                  "ml-1 h-3 w-3 text-gray-900 transition duration-150 ease-in-out group-hover:text-opacity-80"
                )}
                aria-hidden="true"
              />
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={classNames(
                "absolute z-40 mt-4 min-w-[10rem] bg-white shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-2",
                position === PopoverPosition.LEFT
                  ? "left-1 origin-top-left rounded-bl-2xl rounded-br-2xl rounded-tr-2xl"
                  : "right-1.5 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl"
              )}
            >
              {position === PopoverPosition.LEFT ? (
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="16px"
                  height="16px"
                  viewBox="0 0 27.7 24"
                  enableBackground="new 0 0 27.7 24"
                  className="absolute -top-3 left-0 -z-0"
                  fill="#ffffff"
                  style={{
                    filter: "drop-shadow(0px 1px -1px rgba(0,0,0,0.15))",
                  }}
                >
                  <polygon points="0,24 27.7,24 0,0 " />
                </svg>
              ) : (
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="16px"
                  height="16px"
                  viewBox="0 0 110.9 96"
                  enableBackground="new 0 0 110.9 96"
                  className="absolute -top-3 right-0 -z-0"
                  fill="#ffffff"
                  style={{
                    filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                  }}
                >
                  <polygon points="110.9,0 0,96 110.9,96 " />
                </svg>
              )}
              {children}
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
}
