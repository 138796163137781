import { ApolloQueryResult, gql } from "@apollo/client";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  CheckIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { evaluate } from "mathjs";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner, SpinnerInline } from "../../animations";
import { NotifyType, useNotifyContext } from "../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../hooks/useAlert";
import { classNames } from "../../utils";
import {
  Button,
  Field,
  FieldPricingMethod,
  selectStyles,
  SelectWrapper,
} from ".";

type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
  costUpdated: boolean;
};

type Variant = {
  id: string;
  stockCode: string;
  pricingLevelDetails?: Details[];
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: {
    id: string;
    name: string;
  }[];
  status: boolean;
};

type FieldWithValue = PricingMethodField & {
  fieldValue: string;
};

type IFormatedFormula = {
  key: string;
  value: string;
};

enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

type ProductPricing = {
  id: string;
  pricingId?: string;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};

const FETCH_VARIANTS = gql`
  query FetchProduct($id: ID!, $pricingLevelId: Int!) {
    fetchProduct(id: $id) {
      id
      name
      variants {
        id
        stockCode
        pricingLevelDetails(pricingLevelId: $pricingLevelId) {
          id
          priceFields
          sellPrice
          costUpdated
        }
        basePrice
        comparePrice
        variantTitle {
          name
          option {
            name
          }
        }
        cost
        marketCost
        minimumQuantity
        inventory
        variantImageUrl
      }
    }
  }
`;

const CREATE_PRODUCTPRICING = gql`
  mutation ProductPricingCreate(
    $pricingLevelId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    productPricingCreate(
      input: {
        params: {
          pricingLevelId: $pricingLevelId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const UPDATE_PRODUCTPRICING = gql`
  mutation ProductPricingUpdate(
    $id: ID!
    $pricingLevelId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    productPricingUpdate(
      input: {
        id: $id
        params: {
          pricingLevelId: $pricingLevelId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

const DELETE_PRODUCTPRICING = gql`
  mutation ProductPricingDelete($productId: Int!, $pricingLevelId: Int!) {
    productPricingDelete(
      input: { productId: $productId, pricingLevelId: $pricingLevelId }
    ) {
      message
    }
  }
`;

const UPDATE_PRICINGLEVEL = gql`
  mutation PricingLevelUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingLevelUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingLevel {
        id
        name
        pricingMethod {
          id
          name
          sellPriceFormula
          pricingMethodFields {
            id
            fieldType
            fieldKey
            fieldName
            fieldOptions
          }
          status
        }
        productType
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

const UPDATE_PRICING = gql`
  mutation PricingLevelUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $status: Boolean
  ) {
    pricingLevelUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          status: $status
        }
      }
    ) {
      pricingLevel {
        id
        name
        pricingMethod {
          id
          name
          sellPriceFormula
          pricingMethodFields {
            id
            fieldType
            fieldKey
            fieldName
            fieldOptions
          }
          status
        }
        productType
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

const CREATE_PRICING = gql`
  mutation PricingLevelCreate(
    $name: String!
    $pricingMethodId: Int!
    $productType: Int
    $productIds: [Int!]
    $status: Boolean
  ) {
    pricingLevelCreate(
      input: {
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productType: $productType
          productIds: $productIds
          status: $status
        }
      }
    ) {
      pricingLevel {
        id
        name
        pricingMethod {
          id
          name
          sellPriceFormula
          pricingMethodFields {
            id
            fieldType
            fieldKey
            fieldName
            fieldOptions
          }
          status
        }
        productType
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

const FETCH_PRICINGLEVELS = gql`
  query FetchPricingLevels {
    fetchPricingLevels {
      id
      name
      pricingMethod {
        id
        name
        sellPriceFormula
        pricingMethodFields {
          id
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        status
      }
      productType
      products {
        id
        name
      }
      status
    }
  }
`;

type PricingLevelForm = {
  id: string;
  name: string;
  pricingMethod: SingleValue<OptionProps>;
  productType: number;
  products: {
    id: string;
    name: string;
  }[];
  status: boolean;
};

export function FieldPricing({
  pricingLevel: pricingLevelProp,
  className,
}: {
  pricingLevel: PricingLevel | null;
  className?: string;
}) {
  const { t } = useTranslation();
  let { productId } = useParams();
  const navigate = useNavigate();
  const { addNotify } = useNotifyContext();

  const [fetchVariants] = useLazyQuery(FETCH_VARIANTS);

  const [createProductPricing, { loading: loadingCreate }] = useMutation(
    CREATE_PRODUCTPRICING
  );
  const [updateProductPricing, { loading: loadingUpdate }] = useMutation(
    UPDATE_PRODUCTPRICING
  );

  const [deleteProductPricing] = useMutation(DELETE_PRODUCTPRICING);
  const [updatePricingLevel] = useMutation(UPDATE_PRICINGLEVEL);

  const [updatePricing] = useMutation(UPDATE_PRICING);
  const [createPricing] = useMutation(CREATE_PRICING);

  const [pricingLevel, setPricingLevel] = useState<PricingLevel | null>(null);

  const [variants, setVariants] = useState<Variant[]>([]);
  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);

  const [newPricingLevel, setNewPricingLevel] = useState<boolean | undefined>(
    undefined
  );
  const [activePricingLevel, setActivePricingLevel] = useState<
    PricingLevelForm | undefined
  >(undefined);

  const { data, loading, refetch } = useQuery(FETCH_PRICINGLEVELS, {
    variables: {
      status: true,
    },
  });

  const pricingLevels: PricingLevel[] = useMemo(() => {
    if (data?.fetchPricingLevels) {
      return data.fetchPricingLevels;
    }
    return [];
  }, [data]);

  const localProductPricing = useCallback(
    (productPricing: ProductPricing[]) => {
      setProductPricing(productPricing);
    },
    [setProductPricing]
  );

  const didFetchVariants = useRef(false);
  const fetchingVariants = useCallback(
    async (pricingLevel: PricingLevel) => {
      setProductPricing([]);
      fetchVariants({
        variables: {
          id: productId,
          pricingLevelId: parseInt(pricingLevel.id),
        },
      })
        .then(({ data, error }) => {
          if (data?.fetchProduct) {
            setVariants(data.fetchProduct.variants);
          } else {
            return navigate("/error/401", {
              state: {
                message: error
                  ? error.message
                  : "Product read permission(s) is required to access this page.",
              },
            });
          }
        })
        .catch((error) => {
          return navigate("/error/500", {
            state: {
              error,
              message: error.message,
            },
          });
        });
    },
    [fetchVariants, navigate, productId]
  );

  useEffect(() => {
    if (!didFetchVariants.current && pricingLevelProp) {
      setPricingLevel(pricingLevelProp);
      fetchingVariants(pricingLevelProp);
      didFetchVariants.current = true;
    }
  }, [fetchingVariants, pricingLevelProp]);

  async function handleSave() {
    if (!pricingLevel || !productId) return;

    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const { pricingId, productSkuId, priceFields, sellPrice } = product;

      if (pricingId) {
        await updateProductPricing({
          variables: {
            id: pricingId,
            pricingLevelId: parseInt(pricingLevel.id),
            productId: parseInt(productId),
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.productPricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createProductPricing({
          variables: {
            pricingLevelId: parseInt(pricingLevel.id),
            productId: parseInt(productId),
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.productPricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    localProductPricing(newProductPricing);
  }

  enum ActionType {
    update,
    edit,
    delete,
  }
  type AlertProps = PricingLevel & {
    type: ActionType;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message:
      alert?.type === ActionType.delete ? (
        <p className="text-sm text-gray-500">
          Are you sure you wish to remove the pricing level{" "}
          <b className="text-gray-900">{pricingLevel?.name}</b>. This action
          can't be undone. All pricing records related to this product will be
          removed.
        </p>
      ) : alert?.type === ActionType.edit ? (
        <p className="text-sm text-gray-500">
          Are you sure you wish to edit the pricing level{" "}
          <b className="text-gray-900">{pricingLevel?.name}</b>. This action
          can't be undone. All pricing records related to this product may be
          removed.
        </p>
      ) : (
        <p className="text-sm text-gray-500">
          Are you sure you wish to update the pricing level to{" "}
          <b className="text-gray-900">{alert?.name}</b>. This action can't be
          undone. All pricing records related to this product will be removed.
        </p>
      ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      if (!alert) return;
      const { type, ...level } = alert;
      switch (type) {
        case ActionType.update:
          await handleUpdatePricing(level);
          await fetchingVariants(level);
          break;
        case ActionType.edit:
          setActivePricingLevel({
            id: level.id,
            name: level.name,
            pricingMethod: {
              label: level.pricingMethod.name,
              value: level.pricingMethod.id,
            },
            productType: level.productType,
            products: level.products,
            status: level.status,
          });
          break;
        case ActionType.delete:
          await handleDeletePricing(level);
          break;
      }
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const handleDeletePricing = async (level: PricingLevel) => {
    if (!productId) return;

    await deleteProductPricing({
      variables: {
        productId: parseInt(productId),
        pricingLevelId: parseInt(level.id),
      },
    })
      .then(({ data }) => {
        if (data?.productPricingDelete) {
          setPricingLevel(null);
          setVariants([]);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing level removed successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing level remove failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing level remove failed",
          message: error.message,
        });
      });
  };

  const handleAssignPricing = async (level: PricingLevel) => {
    if (!productId) return;

    let productIds = level.products.map((p) => parseInt(p.id));
    if (!productIds.includes(parseInt(productId))) {
      productIds.push(parseInt(productId));
    }

    await updatePricingLevel({
      variables: {
        id: level.id,
        name: level.name,
        pricingMethodId: parseInt(level.pricingMethod.id),
        productType: level.productType,
        productIds: productIds,
        status: level.status,
      },
    })
      .then(async ({ data }) => {
        if (data?.pricingLevelUpdate) {
          const { pricingLevel } = data.pricingLevelUpdate;
          let level: PricingLevel = {
            id: pricingLevel.id,
            name: pricingLevel.name,
            pricingMethod: pricingLevel.pricingMethod,
            productType: pricingLevel.productType,
            products: pricingLevel.products,
            status: pricingLevel.status,
          };
          setPricingLevel(level);
          await fetchingVariants(level);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "ProdcuPricingts update failed",
          message: error.message,
        });
      });
  };

  const handleUpdatePricing = async (level: PricingLevel) => {
    if (!pricingLevel || !productId) return;

    let productIds = level.products.map((p) => parseInt(p.id));
    if (!productIds.includes(parseInt(productId))) {
      productIds.push(parseInt(productId));
    }

    await deleteProductPricing({
      variables: {
        productId: parseInt(productId),
        pricingLevelId: parseInt(pricingLevel.id),
      },
      onCompleted(data) {
        if (data?.productPricingDelete) {
          if (!productId) return;
          updatePricingLevel({
            variables: {
              id: level.id,
              name: level.name,
              pricingMethodId: parseInt(level.pricingMethod.id),
              productType: level.productType,
              productIds: productIds,
              status: level.status,
            },
          })
            .then(async ({ data }) => {
              if (data?.pricingLevelUpdate) {
                const { pricingLevel } = data.pricingLevelUpdate;
                let level: PricingLevel = {
                  id: pricingLevel.id,
                  name: pricingLevel.name,
                  pricingMethod: pricingLevel.pricingMethod,
                  productType: pricingLevel.productType,
                  products: pricingLevel.products,
                  status: pricingLevel.status,
                };
                setPricingLevel(level);
                await fetchingVariants(level);
                addNotify({
                  type: NotifyType.SUCCESS,
                  title: "Products updated successfully",
                });
              } else {
                addNotify({
                  type: NotifyType.ERROR,
                  title: "Prodcuts update failed",
                  message: "Something went wrong, please try again later",
                });
              }
            })
            .catch((error) => {
              addNotify({
                type: NotifyType.ERROR,
                title: "Prodcuts update failed",
                message: error.message,
              });
            });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing level remove failed",
            message: "Something went wrong, please try again later",
          });
        }
      },
    });
  };

  const PricingLevelSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    pricingMethod: Yup.object().required("Required"),
  });

  const handleCreateLevel = async (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!productId) return;

    if (pricingLevel) {
      await deleteProductPricing({
        variables: {
          productId: parseInt(productId),
          pricingLevelId: parseInt(pricingLevel.id),
        },
      })
        .then(({ data }) => {
          if (data?.productPricingDelete) {
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Pricing level remove failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing level remove failed",
            message: error.message,
          });
        });
    }

    await createPricing({
      variables: {
        name: values.name,
        pricingMethodId: parseInt(values.pricingMethod.value),
        productType: 1,
        productIds: [parseInt(productId)],
        status: true,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingLevelCreate) {
          const { pricingLevel } = data.pricingLevelCreate;
          let level: PricingLevel = {
            id: pricingLevel.id,
            name: pricingLevel.name,
            pricingMethod: pricingLevel.pricingMethod,
            productType: pricingLevel.productType,
            products: pricingLevel.products,
            status: pricingLevel.status,
          };
          await refetch();
          setPricingLevel(level);
          await fetchingVariants(level);
          setNewPricingLevel(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing update failed",
          message: error.message,
        });
      });
  };

  const handleUpdateLevel = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!productId || !activePricingLevel) return;

    const productIds = activePricingLevel.products.map((p) => parseInt(p.id));
    if (!productIds.includes(parseInt(productId))) {
      productIds.push(parseInt(productId));
    }

    updatePricing({
      variables: {
        id: activePricingLevel.id,
        name: values.name,
        pricingMethodId: parseInt(values.pricingMethod.value),
        productType: activePricingLevel.productType,
        status: activePricingLevel.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingLevelUpdate) {
          const { pricingLevel } = data.pricingLevelUpdate;
          let level: PricingLevel = {
            id: pricingLevel.id,
            name: pricingLevel.name,
            pricingMethod: pricingLevel.pricingMethod,
            productType: pricingLevel.productType,
            products: pricingLevel.products,
            status: pricingLevel.status,
          };
          setPricingLevel(level);
          setActivePricingLevel(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing updated successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing update failed",
          message: error.message,
        });
      });
  };

  const createFormik = useFormik({
    initialValues: {
      name: "",
      pricingMethod: null,
    },
    validationSchema: PricingLevelSchema,
    onSubmit: handleCreateLevel,
  });

  const updateFormik = useFormik({
    initialValues: {
      name: activePricingLevel?.name ?? "",
      pricingMethod: activePricingLevel?.pricingMethod ?? null,
    },
    enableReinitialize: true,
    validationSchema: PricingLevelSchema,
    onSubmit: handleUpdateLevel,
  });

  return (
    <>
      <ResponseAlert />
      <div
        className={classNames(
          "rounded-xl bg-greyish px-4 py-5 sm:p-6",
          className ?? ""
        )}
      >
        <div className="mb-1 block text-sm font-medium text-gray-900">
          Pricing Level
        </div>
        <p className="text-sm text-gray-500">
          This will override the base price of the product.
        </p>
        <div className="mt-4 flex space-x-4">
          <FieldPricingLevel
            pricingLevels={pricingLevels}
            value={pricingLevel ? pricingLevel.id : null}
            onChange={async (value) => {
              if (value?.id === pricingLevel?.id) return;
              if (value && !pricingLevel) {
                await handleAssignPricing(value);
              } else if (value && pricingLevel) {
                setAlert({ ...value, type: ActionType.update });
              }
            }}
            className={classNames(
              "w-full rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
            )}
            loading={loading}
            refetch={refetch}
          />
          {pricingLevel ? (
            <>
              {pricingLevel.productType === 1 &&
              pricingLevel.products.length === 1 ? (
                <Button
                  variant="icon"
                  onClick={() => {
                    if (!pricingLevel) return;
                    setAlert({ ...pricingLevel, type: ActionType.edit });
                  }}
                  className="rounded-md border-0 bg-transparent p-2 text-sm text-gray-700 transition-all hover:bg-white hover:text-gray-800"
                >
                  <PencilIcon aria-hidden="true" className="h-5 w-5" />
                  <span className="sr-only">
                    Edit pricing level, {pricingLevel?.name}
                  </span>
                </Button>
              ) : null}
              <Button
                variant="icon"
                onClick={() => {
                  if (!pricingLevel) return;
                  setAlert({ ...pricingLevel, type: ActionType.delete });
                }}
                className="rounded-md border-0 bg-transparent p-2 text-sm text-gray-700 transition-all hover:bg-white hover:text-gray-800"
              >
                <TrashIcon aria-hidden="true" className="h-5 w-5" />
                <span className="sr-only">
                  Remove pricing level, {pricingLevel?.name}
                </span>
              </Button>
            </>
          ) : null}

          <Button
            variant="secondary"
            onClick={() => {
              setNewPricingLevel(true);
            }}
            className="whitespace-nowrap"
          >
            Create pricing level
          </Button>
        </div>
        {pricingLevel ? (
          <div className="mt-4 space-y-4">
            {variants.length
              ? variants.map((variant, index) => (
                  <ProductVariant
                    key={index}
                    variant={variant}
                    pricingMethod={pricingLevel.pricingMethod}
                    productPricing={productPricing}
                    setProductPricing={localProductPricing}
                  />
                ))
              : null}
          </div>
        ) : null}

        <div className="flex justify-end">
          {productPricing.filter(
            (pp) =>
              pp.status === ProductPricingStatus.pending ||
              pp.status === ProductPricingStatus.error
          ).length ? (
            <Button onClick={handleSave} className="mt-4">
              {loadingCreate || loadingUpdate ? (
                <>
                  <Spinner />
                  {t("text_save_pricing")}
                </>
              ) : (
                t("text_save_pricing")
              )}
            </Button>
          ) : null}
        </div>
      </div>

      <Transition.Root
        show={newPricingLevel ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewPricingLevel(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <form
                  className="flex h-full flex-col divide-y divide-gray-200"
                  onSubmit={createFormik.handleSubmit}
                >
                  <div className="h-0 flex-1">
                    <div className="px-4 py-8 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          <span>
                            Create Pricing Level -{" "}
                            {createFormik.values.name
                              ? createFormik.values.name
                              : t("text_untitled")}
                          </span>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setNewPricingLevel(undefined);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new pricing level.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pb-5">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                              <Field
                                title={t("text_name")}
                                name="name"
                                onChange={createFormik.handleChange}
                                onBlur={createFormik.handleBlur}
                                value={createFormik.values.name}
                                touched={createFormik.touched.name}
                                errors={createFormik.errors.name}
                              />
                            </div>
                            <div className="col-span-12">
                              <label className="block text-sm font-medium text-gray-900">
                                {t("text_pricing_method")}
                              </label>
                              <FieldPricingMethod
                                value={createFormik.values.pricingMethod}
                                onChange={(value: SingleValue<OptionProps>) => {
                                  createFormik.setFieldValue(
                                    "pricingMethod",
                                    value
                                  );
                                }}
                                className={classNames(
                                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                                  createFormik.touched.pricingMethod &&
                                    createFormik.errors.pricingMethod
                                    ? "border-red-600 text-red-900"
                                    : ""
                                )}
                              />
                              {createFormik.touched.pricingMethod &&
                              createFormik.errors.pricingMethod ? (
                                <p
                                  className="mt-2 text-sm text-red-600"
                                  id="pricingMethod-errors"
                                >
                                  {createFormik.errors.pricingMethod.toString()}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setNewPricingLevel(false);
                      }}
                    >
                      {t("text_cancel")}
                    </Button>
                    <Button type="submit" disabled={createFormik.isSubmitting}>
                      {createFormik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        t("text_create")
                      )}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={activePricingLevel ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActivePricingLevel(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <form
                  className="flex h-full flex-col divide-y divide-gray-200"
                  onSubmit={updateFormik.handleSubmit}
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="px-4 py-8 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-black">
                          <span>
                            Update Pricing Level -{" "}
                            {updateFormik.values.name
                              ? updateFormik.values.name
                              : t("text_untitled")}
                          </span>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setActivePricingLevel(undefined);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-gray-500">
                          Get started by filling in the information below to
                          create your new pricing level.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pb-5">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                              <Field
                                title={t("text_name")}
                                name="name"
                                onChange={updateFormik.handleChange}
                                onBlur={updateFormik.handleBlur}
                                value={updateFormik.values.name}
                                touched={updateFormik.touched.name}
                                errors={updateFormik.errors.name}
                              />
                            </div>
                            <div className="col-span-12">
                              <Field
                                title={t("text_pricing_method")}
                                name="name"
                                value={
                                  updateFormik.values.pricingMethod
                                    ? updateFormik.values.pricingMethod.label
                                    : "--"
                                }
                                disabled
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setActivePricingLevel(undefined);
                      }}
                    >
                      {t("text_cancel")}
                    </Button>
                    <Button type="submit" disabled={updateFormik.isSubmitting}>
                      {updateFormik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        t("text_update")
                      )}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const ProductVariant = ({
  variant,
  pricingMethod,
  productPricing,
  setProductPricing,
}: {
  variant: Variant;
  pricingMethod: PricingMethod;
  productPricing: ProductPricing[];
  setProductPricing: (productPricing: ProductPricing[]) => void;
}) => {
  const { t } = useTranslation();

  const [priceFields, setPriceFields] = useState<FieldWithValue[]>([]);
  const [formatedFormula, setFormatedFormula] = useState<IFormatedFormula[]>(
    []
  );
  const [sellPrice, setSellPrice] = useState<number>(0);
  const [pricingId, setPricingId] = useState<string | undefined>(undefined);

  const initialLoading = useCallback(() => {
    if (pricingMethod) {
      let pricingLevelDetails: Details;

      if (
        variant.pricingLevelDetails &&
        variant.pricingLevelDetails.length > 0
      ) {
        pricingLevelDetails = variant.pricingLevelDetails[0];
        if (pricingLevelDetails) {
          setPricingId(pricingLevelDetails.id);
          setSellPrice(pricingLevelDetails.sellPrice);
        } else {
          setSellPrice(0);
          setPricingId(undefined);
        }
      } else {
        setSellPrice(0);
        setPricingId(undefined);
      }

      if (pricingMethod?.pricingMethodFields) {
        const updatedPriceFields = pricingMethod.pricingMethodFields?.map(
          (field: PricingMethodField) => {
            let updatedValue = "";
            if (pricingLevelDetails) {
              let findValue = pricingLevelDetails.priceFields.find(
                (pf: any) => pf.fieldKey === field.fieldKey
              );
              if (findValue) {
                updatedValue = findValue.fieldValue;
              }
            }

            return {
              ...field,
              fieldValue: updatedValue,
            };
          }
        );
        setPriceFields(updatedPriceFields);
      } else {
        setPriceFields([]);
      }

      const updatedFormatedFormula = pricingMethod?.sellPriceFormula?.map(
        (formula: string) => {
          let updatedValue = "";
          if (/^\$[a-zA-Z0-9]+/.test(formula)) {
            if (pricingLevelDetails) {
              let findValue = pricingLevelDetails.priceFields.find(
                (pf: any) => pf.fieldKey === formula
              );
              if (findValue) {
                updatedValue = findValue.fieldValue;
              }
            }
          } else {
            updatedValue = formula;
          }

          return {
            key: formula,
            value: updatedValue,
          };
        }
      );
      setFormatedFormula(updatedFormatedFormula);
    } else {
      setSellPrice(0);
      setPricingId(undefined);
      setPriceFields([]);
      setFormatedFormula([]);
    }
  }, [pricingMethod, variant]);

  useEffect(() => {
    initialLoading();
  }, [initialLoading]);

  const handleCalculate = useCallback(
    (formatedFormula: IFormatedFormula[], priceFields: any[]) => {
      if (formatedFormula.length > 0) {
        if (formatedFormula.flatMap((f) => f.value).some((v) => v === ""))
          return setSellPrice(0);

        let updatedPrice: number = evaluate(
          formatedFormula.flatMap((ff) => ff.value).join(" ")
        );

        const isMinimumMarkup = pricingMethod?.pricingMethodFields?.some(
          (field) => field.fieldKey === "$minimumMarkup"
        );
        const isMaximumMarkup = pricingMethod?.pricingMethodFields?.some(
          (field) => field.fieldKey === "$maximumMarkup"
        );
        const cost = priceFields.find((pf: any) => pf.fieldKey === "$cost");

        if (isMinimumMarkup && cost) {
          const minimumMarkup = priceFields.find(
            (pf: any) => pf.fieldKey === "$minimumMarkup"
          );
          const costValue = parseFloat(cost.fieldValue);

          if (minimumMarkup) {
            if (
              updatedPrice - costValue <
              parseFloat(minimumMarkup.fieldValue)
            ) {
              updatedPrice = costValue + parseFloat(minimumMarkup.fieldValue);
            }
          }
        }

        if (isMaximumMarkup && cost) {
          const maximumMarkup = priceFields.find(
            (pf: any) => pf.fieldKey === "$maximumMarkup"
          );
          const costValue = parseFloat(cost.fieldValue);

          if (maximumMarkup) {
            if (
              updatedPrice - costValue >
              parseFloat(maximumMarkup.fieldValue)
            ) {
              updatedPrice = costValue + parseFloat(maximumMarkup.fieldValue);
            }
          }
        }

        let newPrice = Number((Math.ceil(updatedPrice * 20) / 20).toFixed(2));
        newPrice = Number(updatedPrice.toFixed(2));
        setSellPrice(newPrice);

        const updatedProductPrice: ProductPricing = {
          id: variant.id,
          pricingId,
          productSkuId: parseInt(variant.id),
          priceFields: priceFields.map((pf) => ({
            fieldType: pf.fieldType,
            fieldKey: pf.fieldKey,
            fieldValue: pf.fieldValue,
          })),
          sellPrice: newPrice,
          status: ProductPricingStatus.pending,
        };

        const updatedProductPricing = [...productPricing];
        const index = updatedProductPricing.findIndex(
          (pp) => pp.id === updatedProductPrice.id
        );
        if (index > -1) {
          updatedProductPricing[index] = updatedProductPrice;
        } else {
          updatedProductPricing.push(updatedProductPrice);
        }
        setProductPricing(updatedProductPricing);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [pricingMethod, productPricing, pricingId]
  );

  const renderStatus = useCallback(() => {
    const findStatus = productPricing.find((pp) => pp.id === variant.id);

    switch (findStatus?.status) {
      case ProductPricingStatus.pending:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full bg-greyish">
            <ExclamationTriangleIcon
              className="h-5 w-5 -rotate-45 text-yellow-400"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.success:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full bg-greyish">
            <CheckIcon
              className="h-5 w-5 -rotate-45 text-green-500"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.error:
        return (
          <div className="flex h-8 w-8 rotate-45 items-center justify-center rounded-full bg-greyish">
            <XMarkIcon
              className="h-5 w-5 -rotate-45 text-red-500"
              aria-hidden="true"
            />
          </div>
        );
      case ProductPricingStatus.loading:
        return (
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-greyish">
            <SpinnerInline className="text-green-500" />
          </div>
        );

      default:
        return null;
    }
  }, [productPricing, variant]);

  const handleFieldChange = (key: string, value: string) => {
    const updatedPriceFields = priceFields.map((field) => {
      if (field.fieldKey === key) {
        return {
          ...field,
          fieldValue: value,
        };
      }
      return field;
    });
    setPriceFields(updatedPriceFields);

    const updatedFormatedFormula = formatedFormula.map((f) => {
      if (f.key === key) {
        return {
          ...f,
          value,
        };
      }
      return f;
    });
    setFormatedFormula(updatedFormatedFormula);
    handleCalculate(updatedFormatedFormula, updatedPriceFields);
  };

  // console.log(1, evaluate("10 / (1 - 0.25)"));
  // console.log(2, evaluate("10 / (1 - 25%)"));

  return (
    <div>
      <div className="relative flex w-full flex-wrap rounded-md bg-white px-4 py-4 pr-12">
        <div className="mr-2 py-1">
          <Field
            title={t("text_stock_code")}
            value={variant.stockCode}
            disabled
            className="max-w-[7rem]"
          />
        </div>
        {priceFields.length
          ? priceFields.map((f) => {
              return (
                <div key={`${variant.id}-${f.fieldKey}`} className="px-2 py-1">
                  <Field
                    title={t(f.fieldName)}
                    name={f.fieldKey}
                    type={f.fieldType === "percentage" ? "text" : f.fieldType}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { name, value } = e.target;
                      if (f.fieldType === "number") {
                        if (!isNaN(parseFloat(value))) {
                          handleFieldChange(name, value);
                        }
                      } else if (f.fieldType === "percentage") {
                        if (!isNaN(parseFloat(value))) {
                          const updateValue = value.replace(/[^0-9.]/g, "");
                          handleFieldChange(name, `${updateValue}%`);
                        }
                      } else {
                        handleFieldChange(name, value);
                      }
                    }}
                    min={f.fieldType === "number" ? 0 : undefined}
                    value={
                      f.fieldType === "percentage"
                        ? f.fieldValue.replace(/[^0-9.]/g, "")
                        : f.fieldValue
                    }
                    className="max-w-[7rem]"
                  />
                </div>
              );
            })
          : null}

        <div className="ml-auto py-1 text-right">
          <Field
            title={t("text_sell_price")}
            value={sellPrice}
            disabled
            className="max-w-[6rem] border-0 text-right"
          />
        </div>
        <div className="absolute inset-y-0 right-5 m-auto h-5 w-5">
          {renderStatus()}
        </div>
      </div>
    </div>
  );
};

export function FieldPricingLevel({
  pricingLevels,
  value,
  onChange,
  className = "",
  loading,
  refetch,
}: {
  pricingLevels: PricingLevel[];
  value: string | null;
  onChange: (newValue: PricingLevel | null) => void;
  className?: string;
  loading: boolean;
  refetch: (
    variables?: Partial<{ status: boolean }> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);

  useEffect(() => {
    const updatedOptions: OptionProps[] = pricingLevels.map(
      (p: PricingLevel) => {
        return {
          label: p.name,
          value: p.id,
        };
      }
    );
    setOptions(updatedOptions);
  }, [pricingLevels]);

  useEffect(() => {
    let updatedValues = null;
    if (value) {
      const activeOption = options.find((o) => o.value === value);
      if (activeOption) {
        updatedValues = {
          label: activeOption.label,
          value: activeOption.value,
        };
      }
    }
    setValues(updatedValues);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(newValue: SingleValue<OptionProps>) => {
          const updateValue = newValue
            ? pricingLevels.find((p) => p.id === newValue.value)
            : null;
          onChange(updateValue ? updateValue : null);
        }}
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
