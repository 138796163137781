import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, Field, FieldImage } from "../../../../components/form";
import {
  checkBarCode,
  checkStockCode,
  VariantEx,
} from "../../../../graphql/inventory/products";
import { useFormikErrors } from "../../../../utils";

type VariantProps = {
  index?: number;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  basePrice?: number;
  comparePrice?: number;
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
  type: VariantEx;
};

export default function VariantUpdate({
  heading,
  initialValues,
  initialStockCodes,
  initialBarCodes,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: VariantProps;
  initialStockCodes: string[];
  initialBarCodes: string[];
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();
  const [checkingStockCode, setCheckingStockCode] = useState<boolean>(false);
  const [checkingBarCode, setCheckingBarCode] = useState<boolean>(false);

  const VariantSchema = Yup.object().shape({
    stockCode: Yup.string()
      .label("Stock Code")
      .matches(
        /^[a-zA-Z0-9-_]+$/,
        "Stock Code cannot contain white space and special characters"
      )
      .when("hasVariants", {
        is: false,
        then: (schema) =>
          schema
            .test("stockCode", "Stock code already exists", async (value) => {
              if (!value) return true;
              if (initialStockCodes.includes(value)) return true;
              if (checkingStockCode) return true;

              setCheckingStockCode(true);
              try {
                const response = await checkStockCode(value);
                return response?.data?.checkStockCode === false;
              } catch (error: any) {
                return false;
              } finally {
                setCheckingStockCode(false);
              }
            })
            .required(),
        otherwise: (schema) => schema.nullable(),
      }),
    barCode: Yup.string()
      .matches(
        /^[a-zA-Z0-9-_]+$/,
        "Barcode cannot contain white space and special characters"
      )
      .test("barCode", "Barcode already exists", async (value) => {
        if (!value) return true;
        if (initialBarCodes.includes(value)) return true;
        if (checkingBarCode) return true;

        setCheckingBarCode(true);

        try {
          const response = await checkBarCode(value);
          return response?.data?.checkBarCode === false;
        } catch (error: any) {
          return false;
        } finally {
          setCheckingBarCode(false);
        }
      })
      .nullable(),
    plu: Yup.number().nullable(),
    basePrice: Yup.number().min(0).required("Required"),
    comparePrice: Yup.number().min(0).required("Required"),
    cost: Yup.number().min(0).required("Required"),
    marketCost: Yup.number().min(0).required("Required"),
    minimumQuantity: Yup.number().min(0).required("Required"),
    inventory: Yup.number().label("Stock").required("Required"),
    variantImageUrl: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: VariantSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  const { errors, touched, isSubmitting } = formik;

  return (
    <form
      className="flex h-full flex-col divide-y divide-gray-200"
      onSubmit={formik.handleSubmit}
    >
      <div className="h-0 flex-1">
        <div className="px-4 py-8 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-black">
              {heading}
              <span>
                {formik.values.stockCode
                  ? formik.values.stockCode
                  : t("text_untitled")}
              </span>
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                onClick={onCancel}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-500">
              Edit the variant details below.
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5">
              <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <Field
                    title={t("text_stock_code")}
                    name="stockCode"
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue("stockCode", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.stockCode}
                    touched={touched.stockCode}
                    errors={errors.stockCode}
                  />
                  {checkingStockCode && (
                    <p className="mt-1 text-sm text-red-600">
                      Checking stock code availability...
                    </p>
                  )}
                </div>
                <div className="sm:col-span-6">
                  <Field
                    title={t("text_bar_code")}
                    name="barCode"
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue("barCode", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.barCode}
                    touched={touched.barCode}
                    errors={errors.barCode}
                  />
                  {checkingBarCode && (
                    <p className="mt-1 text-sm text-red-600">
                      Checking barcode availability...
                    </p>
                  )}
                </div>
                <div className="sm:col-span-6">
                  <Field
                    title={t("text_plu")}
                    name="plu"
                    type="number"
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue(
                        "plu",
                        value ? parseInt(value) : null
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.plu || ""}
                    touched={touched.plu}
                    errors={errors.plu}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Field
                    title={t("text_base_price")}
                    name="basePrice"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.basePrice}
                    touched={touched.basePrice}
                    errors={errors.basePrice}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Field
                    title={t("text_compare_price")}
                    name="comparePrice"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comparePrice}
                    touched={touched.comparePrice}
                    errors={errors.comparePrice}
                  />
                  <p className="mt-2 text-xs text-gray-500">
                    {t("text_compare_price_warning")}
                  </p>
                </div>

                <div className="sm:col-span-3">
                  <Field
                    title={t("text_cost")}
                    name="cost"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cost}
                    touched={touched.cost}
                    errors={errors.cost}
                  />
                  {formik.touched.cost &&
                  formik.values.cost !== initialValues.cost ? (
                    <p className="mt-2 text-xs text-blue-700">
                      Please update the corresponding sell prices on the pricing
                      pages for this product.
                    </p>
                  ) : null}
                </div>
                <div className="sm:col-span-3">
                  <Field
                    title={t("text_market_cost")}
                    name="marketCost"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.marketCost}
                    touched={touched.marketCost}
                    errors={errors.marketCost}
                  />
                </div>

                <div className="sm:col-span-3">
                  <Field
                    title={t("text_inventory")}
                    name="inventory"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.inventory}
                    touched={touched.inventory}
                    errors={errors.inventory}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Field
                    title={t("text_minimum_quantity")}
                    name="minimumQuantity"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.minimumQuantity}
                    touched={touched.minimumQuantity}
                    errors={errors.minimumQuantity}
                  />
                </div>

                <div className="sm:col-span-6">
                  <FieldImage
                    title={t("text_image")}
                    onChange={(value) => {
                      formik.setFieldValue("variantImageUrl", value);
                    }}
                    value={formik.values.variantImageUrl}
                    touched={touched.variantImageUrl}
                    errors={errors.variantImageUrl}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
        <Button variant="secondary" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            submitLabel
          )}
        </Button>
      </div>
    </form>
  );
}
