import { CustomersResource } from "./customers";
import { OrdersResource } from "./orders";
import { SettingsResource } from "./settings";

export * from "./Layout";

export const SalesResource = {
  name: "Sales",
  description: "Sales management module",
  path: "/sales",
  icon: (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
    >
      <path
        d="M13.9791 10.0295C13.9791 9.67065 13.8366 9.3264 13.5828 9.07254C13.3291 8.81868 12.9849 8.67603 12.6259 8.67581C12.267 8.67571 11.9228 8.81814 11.6688 9.07178C11.4148 9.32542 11.2719 9.66956 11.2717 10.0286C11.2714 10.3875 11.4137 10.7319 11.6673 10.9858C11.9208 11.2399 12.265 11.3829 12.6239 11.3833C12.9831 11.3833 13.3276 11.2406 13.5817 10.9869C13.8358 10.733 13.9788 10.3887 13.9792 10.0295H13.9791ZM11.8793 10.0295C11.8793 9.83209 11.9577 9.64269 12.0973 9.50304C12.237 9.36338 12.4264 9.28497 12.6239 9.28497C12.8213 9.28497 13.0107 9.36336 13.1504 9.50304C13.2901 9.64271 13.3685 9.83211 13.3685 10.0295C13.3685 10.227 13.2901 10.4164 13.1504 10.5561C13.0107 10.6957 12.8213 10.7741 12.6239 10.7741C12.4265 10.7738 12.2373 10.6952 12.0978 10.5556C11.9582 10.4161 11.8797 10.2269 11.8793 10.0295L11.8793 10.0295Z"
        fill="currentColor"
      />
      <path
        d="M14.9312 2.77362C14.8741 2.6964 14.7837 2.65062 14.6876 2.6503H5.72151L5.37279 1.19297C5.33179 1.02875 5.23726 0.882812 5.10406 0.778286C4.97086 0.673765 4.80663 0.61662 4.63733 0.615875H2.90438C2.73623 0.615875 2.59985 0.75226 2.59985 0.9204C2.59985 1.08865 2.73624 1.22492 2.90438 1.22492H4.63733C4.70946 1.22301 4.77298 1.27198 4.78955 1.34219L5.15803 2.88322H5.15813C5.13094 2.98286 5.15622 3.0895 5.22505 3.16651L6.09308 6.78769C6.1567 7.05398 6.30828 7.29107 6.52315 7.4607C6.73814 7.63033 7.00402 7.72263 7.27773 7.72263H12.8755C13.1478 7.72274 13.4123 7.6316 13.6268 7.46378C13.8413 7.29606 13.9933 7.0612 14.0587 6.79683L14.9921 3.03562C15.0135 2.94353 14.991 2.84676 14.9312 2.77368L14.9312 2.77362ZM13.4694 6.64905C13.4356 6.78002 13.3592 6.8959 13.2522 6.97864C13.1453 7.06139 13.0138 7.10611 12.8786 7.1059H7.27017C7.13325 7.1059 7.00037 7.05969 6.89288 6.97493C6.78539 6.89006 6.70965 6.77152 6.67778 6.63842L5.86765 3.25926H14.3021L13.4694 6.64905Z"
        fill="currentColor"
      />
      <path
        d="M8.51234 10.0295C8.51234 9.67054 8.36969 9.32618 8.11583 9.07232C7.86197 8.81846 7.5176 8.67581 7.15858 8.67581C6.79957 8.67581 6.4552 8.81846 6.20134 9.07232C5.94748 9.32618 5.80493 9.67054 5.80493 10.0295C5.80493 10.3886 5.94748 10.7329 6.20134 10.9868C6.4552 11.2406 6.79957 11.3833 7.15858 11.3833C7.51749 11.3829 7.86164 11.2401 8.11542 10.9863C8.36918 10.7325 8.51194 10.3885 8.51237 10.0295L8.51234 10.0295ZM6.41249 10.0295C6.41249 9.83209 6.49087 9.64269 6.63055 9.50304C6.77023 9.36336 6.95963 9.28497 7.15707 9.28497C7.35452 9.28497 7.54394 9.36336 7.6836 9.50304C7.82325 9.64271 7.90166 9.83211 7.90166 10.0295C7.90166 10.227 7.82327 10.4164 7.6836 10.5561C7.54392 10.6957 7.35452 10.7741 7.15707 10.7741C6.95972 10.7738 6.77054 10.6952 6.63096 10.5556C6.49139 10.4161 6.41279 10.2269 6.41247 10.0295L6.41249 10.0295Z"
        fill="currentColor"
      />
      <path
        d="M1.2873 4.73174H3.92929C4.09743 4.73174 4.23381 4.59535 4.23381 4.42721C4.23381 4.25896 4.09743 4.12269 3.92929 4.12269H1.2873C1.11905 4.12269 0.982666 4.25897 0.982666 4.42721C0.982666 4.59536 1.11905 4.73174 1.2873 4.73174Z"
        fill="currentColor"
      />
      <path
        d="M4.38655 5.64542H2.50594C2.3378 5.64542 2.20142 5.78169 2.20142 5.94994C2.20142 6.11809 2.3378 6.25447 2.50594 6.25447H4.38655C4.5548 6.25447 4.69118 6.11808 4.69118 5.94994C4.69118 5.78169 4.5548 5.64542 4.38655 5.64542Z"
        fill="currentColor"
      />
      <path
        d="M1.13501 7.4727C1.13501 7.55343 1.16709 7.63097 1.22423 7.68801C1.28127 7.74516 1.35881 7.77723 1.43954 7.77723H4.84291C5.01116 7.77723 5.14743 7.64085 5.14743 7.47271C5.14743 7.30446 5.01116 7.16818 4.84291 7.16818H1.43954C1.27139 7.16818 1.13502 7.30446 1.13502 7.47271L1.13501 7.4727Z"
        fill="currentColor"
      />
    </svg>
  ),
  access: ["read-salesmodules"],
  children: [OrdersResource, CustomersResource, SettingsResource],
};
