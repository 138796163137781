import { gql } from "@apollo/client";

import { Dock, DOCK_FRAGMENT } from "../docks";

export const GET_DELIVERYRUNS = gql`
  ${DOCK_FRAGMENT}
  query FetchDeliveryRuns($status: Boolean) {
    fetchDeliveryRuns(status: $status) {
      id
      name
      dock {
        ...DockFragment
      }
      createdAt
      status
    }
  }
`;

export const EDIT_DELIVERYRUN = gql`
  mutation DeliveryRunUpdate(
    $id: ID!
    $name: String!
    $dockId: Int!
    $status: Boolean!
  ) {
    deliveryRunUpdate(
      input: {
        id: $id
        params: { name: $name, dockId: $dockId, status: $status }
      }
    ) {
      deliveryRun {
        id
        name
        dock {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const ADD_DELIVERYRUN = gql`
  mutation DeliveryRunCreate($name: String!, $dockId: Int!, $status: Boolean!) {
    deliveryRunCreate(
      input: { params: { name: $name, dockId: $dockId, status: $status } }
    ) {
      deliveryRun {
        id
        name
        dock {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const REMOVE_DELIVERYRUN = gql`
  mutation DeliveryRunDelete($id: ID!) {
    deliveryRunDelete(input: { id: $id }) {
      deliveryRun {
        id
        name
      }
    }
  }
`;

export type DeliveryRun = {
  id: string;
  name: string;
  dock: Dock;
  createdAt: string;
  status: boolean;
};
