import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { Button, Field, FieldImage, Response } from "../../../components/form";

const NotificationSchema = Yup.object().shape({
  emailTemplateLogo: Yup.string().required("Required"),
  orderNotificationRecipients: Yup.array(
    Yup.string().email("Invalid email address").required("Required")
  )
    .min(1, "At least one email address is required")
    .required(),
});

const FETCH_NOTIFICATION_SETTING = gql`
  query FetchNotificationSettings {
    fetchNotificationSettings {
      id
      emailTemplateLogo
      orderNotificationRecipients
    }
  }
`;

const CREATE_NOTIFICATION_SETTING = gql`
  mutation NotificationSettingCreate(
    $orderNotificationRecipients: [String!]!
    $emailTemplateLogo: String
  ) {
    notificationSettingCreate(
      input: {
        params: {
          orderNotificationRecipients: $orderNotificationRecipients
          emailTemplateLogo: $emailTemplateLogo
        }
      }
    ) {
      notificationSetting {
        emailTemplateLogo
        orderNotificationRecipients
      }
    }
  }
`;

const UPDATE_NOTIFICATION_SETTING = gql`
  mutation NotificationSettingUpdate(
    $id: ID!
    $orderNotificationRecipients: [String!]!
    $emailTemplateLogo: String
  ) {
    notificationSettingUpdate(
      input: {
        id: $id
        params: {
          orderNotificationRecipients: $orderNotificationRecipients
          emailTemplateLogo: $emailTemplateLogo
        }
      }
    ) {
      notificationSetting {
        emailTemplateLogo
        orderNotificationRecipients
      }
    }
  }
`;

type Notification = {
  id: string;
  emailTemplateLogo: string;
  orderNotificationRecipients: string[];
};

const NotificationSettings = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<FormResponse | null>(null);

  const { data, loading, error, refetch } = useQuery(
    FETCH_NOTIFICATION_SETTING
  );

  const notification: Notification = useMemo(
    () => data?.fetchNotificationSettings,
    [data]
  );

  const [notificationSettingCreate, { loading: loadingCreate }] = useMutation(
    CREATE_NOTIFICATION_SETTING,
    {
      refetchQueries: [
        {
          query: FETCH_NOTIFICATION_SETTING,
        },
      ],
    }
  );
  const [notificationSettingUpdate, { loading: loadingUpdate }] = useMutation(
    UPDATE_NOTIFICATION_SETTING,
    {
      refetchQueries: [
        {
          query: FETCH_NOTIFICATION_SETTING,
        },
      ],
    }
  );

  const formik = useFormik({
    initialValues: {
      id: notification?.id ?? null,
      emailTemplateLogo: notification?.emailTemplateLogo ?? "",
      orderNotificationRecipients:
        notification?.orderNotificationRecipients ?? [""],
    },
    enableReinitialize: true,
    validationSchema: NotificationSchema,
    onSubmit: (values, actions) => {
      if (!values.id) {
        notificationSettingCreate({
          variables: {
            emailTemplateLogo: values.emailTemplateLogo,
            orderNotificationRecipients: values.orderNotificationRecipients,
          },
        })
          .then(({ data }) => {
            setResponse(null);
            if (data?.notificationSettingCreate?.notificationSetting) {
              refetch();
              setResponse({
                type: "success",
                message: "Notification settings created successfully",
              });
            } else {
              setResponse({
                type: "error",
                message: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            actions.setSubmitting(false);
            setResponse({
              type: "error",
              message: error.message,
            });
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
        return;
      }

      notificationSettingUpdate({
        variables: {
          id: values.id,
          emailTemplateLogo: values.emailTemplateLogo,
          orderNotificationRecipients: values.orderNotificationRecipients,
        },
      })
        .then(({ data }) => {
          setResponse(null);
          if (data?.notificationSettingUpdate?.notificationSetting) {
            refetch();
            setResponse({
              type: "success",
              message: "Notification settings updated successfully",
            });
          } else {
            setResponse({
              type: "error",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Notification"
        heading="Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Notification",
            href: "/settings/notification",
          },
        ]}
      />
      <div className="space-y-6 sm:px-6 lg:px-0">
        {loading ? (
          <Waiting />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="shadow-sm sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Notification Settings
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Configure your notification settings.
                  </p>
                </div>

                <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
                  <FieldImage
                    title={t("text_email_template_logo")}
                    onChange={(value) => {
                      formik.setFieldValue("emailTemplateLogo", value);
                    }}
                    value={formik.values.emailTemplateLogo}
                    touched={formik.touched.emailTemplateLogo}
                    errors={formik.errors.emailTemplateLogo}
                  />
                </div>

                <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
                  <div className="text-sm font-medium text-gray-900">
                    Order Notification Recipients
                  </div>
                  <div className="mt-1">
                    <div className="flex flex-col">
                      {formik.values.orderNotificationRecipients?.map(
                        (_, index) => (
                          <div
                            key={index}
                            className="mt-2 flex w-full items-center"
                          >
                            <div className="w-full">
                              <Field
                                title={t("text_email")}
                                name={`orderNotificationRecipients[${index}]`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={
                                  formik.values.orderNotificationRecipients[
                                    index
                                  ]
                                }
                                touched={
                                  formik.touched.orderNotificationRecipients
                                }
                                errors={
                                  formik.errors.orderNotificationRecipients
                                    ? formik.errors.orderNotificationRecipients[
                                        index
                                      ]
                                    : undefined
                                }
                                isLabel={false}
                                placeholder={t("text_email")}
                              />
                            </div>
                            {index !== 0 ? (
                              <Button
                                variant="text"
                                style={{
                                  display: "flex",
                                }}
                                onClick={() => {
                                  const newEmails = [
                                    ...formik.values
                                      .orderNotificationRecipients,
                                  ];
                                  newEmails.splice(index, 1);
                                  formik.setFieldValue(
                                    "orderNotificationRecipients",
                                    newEmails
                                  );
                                }}
                                className="ml-2 transition-all hover:bg-white"
                              >
                                <TrashIcon
                                  aria-hidden="true"
                                  className="mx-2 h-4 w-4 text-gray-700"
                                />
                              </Button>
                            ) : null}
                          </div>
                        )
                      )}
                    </div>
                    <div className="mt-2 flex justify-end">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          formik.setFieldValue("orderNotificationRecipients", [
                            ...formik.values.orderNotificationRecipients,
                            "",
                          ]);
                        }}
                      >
                        {t("text_add_email")}
                      </Button>
                    </div>
                  </div>
                </div>

                <Response
                  response={response}
                  onDismiss={() => {
                    setResponse(null);
                  }}
                  className="mt-5"
                />
              </div>
              <div className="border-t border-gray-200 bg-white px-4 py-4 text-right md:px-6 md:py-6">
                <Button
                  variant="primary"
                  type="submit"
                  className="justify-center md:w-36"
                  loading={loadingCreate || loadingUpdate}
                  disabled={
                    formik.isSubmitting || loadingCreate || loadingUpdate
                  }
                >
                  {formik.isSubmitting
                    ? t("text_processing")
                    : formik.values.id
                    ? t("text_update")
                    : t("text_create")}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default NotificationSettings;
export const NotificationResource: ResourceProps = {
  name: "Notification",
  description: "Notification page",
  access: ["read-settings"],
  path: "notification",
  icon: "bi bi-person",
};
