import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_PRICINGLEVEL,
  GET_PRICINGLEVELS,
} from "../../../../graphql/inventory/pricing/pricing-levels";
import Form from "./components/Form";

const PricingLevelCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createPricingLevel] = useMutation(ADD_PRICINGLEVEL, {
    refetchQueries: [
      {
        query: GET_PRICINGLEVELS,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createPricingLevel({
      variables: {
        name: values.name,
        pricingMethodId: Number(values.pricingMethod.value),
        productType: values.productType,
        productIds: values.productIds,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.pricingLevelCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Pricing level created successfully",
          });
          return navigate(
            `/inventory/pricing/pricing-levels/${data?.pricingLevelCreate?.pricingLevel?.id}`
          );
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing level creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing level creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Pricing Level"
        heading="Pricing Levels"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Pricing Levels",
            href: "/inventory/pricing/pricing-levels",
          },
          {
            name: PricingLevelCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_create_pricing_level")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_create_pricing_level")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              name: "",
              pricingMethod: null,
              productType: 0,
              productIds: [],
              status: true,
            }}
            products={[]}
            onSubmit={handleSubmit}
            actionLabel={t("text_create")}
          />
        </div>
      </div>
    </>
  );
};

export default PricingLevelCreate;
export const PricingLevelCreateResource: ResourceProps = {
  name: "Create Pricing Level",
  description: "Create a new pricing level",
  access: ["create-pricings"],
  path: "pricing-levels/add",
};
