import type { UniqueIdentifier } from "@dnd-kit/core";
import type { MutableRefObject } from "react";

export enum TreeType {
  HOME,
  CATEGORY,
  PRODUCT,
  PAGE,
}
export interface TreeItem {
  id: UniqueIdentifier;
  title: string;
  link: string;
  type: TreeType;
  children: TreeItem[];
  collapsed?: boolean;
}

export type TreeItems = TreeItem[];

export interface FlattenedItem extends TreeItem {
  parentId: UniqueIdentifier | null;
  depth: number;
  index: number;
}

export type SensorContext = MutableRefObject<{
  items: FlattenedItem[];
  offset: number;
}>;
