import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import confetti from "canvas-confetti";
import { useFormik } from "formik";
import QRCode from "qrcode";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import { Reveal, Spinner } from "../../../animations";
import { Head } from "../../../components/core";
import { Field, Response } from "../../../components/form";
import { classNames } from "../../../utils";

type Step = {
  id: number;
  name: string;
  description: string;
  component: React.ElementType;
  status: "complete" | "current" | "upcoming";
};

const Box = styled.div`
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.025);
`;

const PasswordVerifySchema = Yup.object().shape({
  password: Yup.string().required("Required"),
});

const MFA_ENABLE = gql`
  mutation enableMfa($password: String!) {
    enableMfa(input: { params: { password: $password } }) {
      provisionUri
      user {
        secretKeyMfa
      }
    }
  }
`;

const PasswordVerify = ({
  step,
  setMfaOption,
  nextHandler,
}: {
  step: Step;
  setMfaOption: React.Dispatch<SetStateAction<MfaOption | null>>;
  nextHandler: (step: Step) => void;
}) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<FormResponse | null>(null);

  const [enabledMfa] = useMutation(MFA_ENABLE);

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: PasswordVerifySchema,
    onSubmit: (values, actions) => {
      setResponse(null);

      enabledMfa({
        variables: {
          password: values.password,
        },
      })
        .then(({ data }) => {
          if (data?.enableMfa) {
            QRCode.toDataURL(data.enableMfa.provisionUri, function (err, url) {
              actions.setSubmitting(false);

              if (err) {
                setResponse({
                  type: "error",
                  message: err.message,
                });
                return;
              }

              setMfaOption({
                qrcode: url,
                secret: data.enableMfa.user.secretKeyMfa,
              });
              nextHandler(step);
            });
          } else {
            actions.setSubmitting(false);
            setResponse({
              type: "error",
              message: "Something went wrong",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <div className="ml-12 mt-4">
      <Box className={classNames("rounded-xl bg-greyish p-3")}>
        <div className="overflow-hidden bg-white shadow-sm sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Verify it's you
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Enter your password to confirm you would like to enable two-factor
              authentication.
            </p>
          </div>
          <div className="border-t border-gray-200 p-4 sm:p-6">
            <form onSubmit={formik.handleSubmit}>
              <div>
                <Field
                  title={t("text_password")}
                  autoComplete="password"
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  touched={touched.password}
                  errors={errors.password}
                />
              </div>

              <div className="relative z-0 mt-5 grid grid-cols-2 gap-4">
                <Link
                  to="/settings/security"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2.5 text-center text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner />
                      {t("text_processing")}
                    </>
                  ) : (
                    t("text_continue")
                  )}
                </button>
              </div>
            </form>

            <Response
              response={response}
              onDismiss={() => {
                setResponse(null);
              }}
              className="mt-5"
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

const MfaGenerate = ({
  step,
  mfaOption,
  nextHandler,
}: {
  step: Step;
  mfaOption: MfaOption | null;
  nextHandler: (step: Step) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="ml-12 mt-4">
      <Box className={classNames("rounded-xl bg-greyish p-3")}>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Multi-factor authentication
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Scan the QR code below with your authenticator app. If you don't
              have scan option you can use the code below.
            </p>
          </div>

          <div className="border-t border-gray-200 p-4 sm:p-6">
            <div className="mb-5">
              <label className="block text-sm font-medium text-gray-900">
                Scan QR Code
              </label>

              {mfaOption?.qrcode && (
                <div className="flex">
                  <div className="mt-2 rounded-md bg-gray-100 p-2">
                    <img
                      src={mfaOption.qrcode}
                      alt="QR Code"
                      className="rounded-md"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="mb-5">
              <div className="block text-sm font-medium text-gray-900">
                Manually enter the code:
              </div>
              <pre className="mt-2">
                <strong>{mfaOption?.secret}</strong>
              </pre>
            </div>

            <div className="relative z-0 mt-5 grid grid-cols-2 gap-4">
              <Link
                to="/settings/security"
                className="rounded-md border border-gray-300 bg-white px-4 py-2.5 text-center text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                Cancel
              </Link>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                onClick={() => {
                  nextHandler(step);
                }}
              >
                {t("text_continue")}
              </button>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

const MfaValidateSchema = Yup.object().shape({
  code: Yup.string().required("Required"),
});

const MFA_VALIDATE = gql`
  mutation ValidateMfa($code: String!, $type: String!, $platform: String!) {
    validateMfa(
      input: { params: { code: $code, type: $type, platform: $platform } }
    ) {
      codes
    }
  }
`;

const MfaValidate = ({
  step,
  nextHandler,
  setBackupCodes,
}: {
  step: Step;
  nextHandler: (step: Step) => void;
  setBackupCodes: React.Dispatch<SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation();
  const [response, setResponse] = useState<FormResponse | null>(null);

  const [validateMfa] = useMutation(MFA_VALIDATE);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: MfaValidateSchema,
    onSubmit: (values, actions) => {
      setResponse(null);

      validateMfa({
        variables: {
          code: values.code,
          type: "otp",
          platform: "web",
        },
      })
        .then(({ data }) => {
          if (data?.validateMfa?.codes) {
            setBackupCodes(data.validateMfa?.codes);
            setResponse({
              type: "success",
              message: "MFA validated successfully",
            });
            nextHandler(step);
          } else {
            actions.setSubmitting(false);
            setResponse({
              type: "error",
              message: "Invalid code, please try again",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          setResponse({
            type: "error",
            message: error.message,
          });
        });
    },
  });

  const { errors, touched, isSubmitting } = formik;

  return (
    <div className="ml-12 mt-4">
      <Box className={classNames("rounded-xl bg-greyish p-3")}>
        <div className="overflow-hidden bg-white shadow-sm sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Authentication activation
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Enter your code to confirm you would like to enable two-factor
              authentication.
            </p>
          </div>
          <div className="border-t border-gray-200 p-4 sm:p-6">
            <form onSubmit={formik.handleSubmit}>
              <Field
                title={t("text_code")}
                name="code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.code}
                touched={touched.code}
                errors={errors.code}
              />

              <div className="relative z-0 mt-5 grid grid-cols-2 gap-4">
                <Link
                  to="/settings/security"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2.5 text-center text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Spinner />
                      {t("text_processing")}
                    </>
                  ) : (
                    t("text_continue")
                  )}
                </button>
              </div>
            </form>

            <Response
              response={response}
              onDismiss={() => {
                setResponse(null);
              }}
              className="mt-5"
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

// const GENERATE_BACKUP_CODES = gql`
//   mutation GenerateBackupCodes {
//     generateBackupCodes(input: { params: [] }) {
//       codes
//     }
//   }
// `;

const GenerateCode = ({
  response,
  setResponse,
  backupCodes,
}: {
  response: FormResponse | null;
  setResponse: React.Dispatch<SetStateAction<FormResponse | null>>;
  backupCodes: string[];
}) => {
  //   const [generateBackupCodes] = useMutation(GENERATE_BACKUP_CODES);
  //   const [backupCode, setBackupCode] = useState<string[]>([]);
  const [file, setFile] = useState<Blob>();

  useEffect(() => {
    const codesFile = new Blob([backupCodes?.join("\r\n")], {
      type: "plain/text",
    });
    setFile(codesFile);

    confetti({
      particleCount: 150,
      spread: 150,
    });

    // generateBackupCodes()
    //   .then(({data}) => {
    //     if (data?.generateBackupCodes) {
    //       const codesList = JSON.parse(data.generateBackupCodes.codes);
    //       console.log(codesList);
    //       setBackupCode(codesList);
    //       const codesFile = new Blob([codesList?.join("\r\n")], {
    //         type: "plain/text",
    //       });
    //       setFile(codesFile);

    //       confetti({
    //         particleCount: 150,
    //         spread: 150,
    //       });
    //     } else {
    //       setResponse({
    //         type: "error",
    //         message: "Error generating codes",
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setResponse({
    //       type: "error",
    //       message: err.message,
    //     });
    //   });
  }, [backupCodes]);

  return (
    <div className="ml-12 mt-4 ">
      <Response
        response={response}
        onDismiss={() => {
          setResponse(null);
        }}
        className="mb-5"
      />

      <Box className={classNames("rounded-xl bg-greyish p-3")}>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Save your recovery codes
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Download and save your recovery codes. You will need them to
              access your account if you lose your phone or delete the app.
            </p>
          </div>
          <div className="border-t border-gray-200 p-4 sm:p-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              {backupCodes && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-900">
                    Backup Codes
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <ul className="mt-2 grid grid-cols-1 sm:grid-cols-2">
                      {backupCodes.map((b) => (
                        <li key={b} className="mb-2 flex space-x-3">
                          <CheckIcon
                            className="h-5 w-5 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="text-sm text-gray-500">{b}</span>
                        </li>
                      ))}
                    </ul>
                  </dd>
                </div>
              )}
            </dl>

            <button
              className="mt-5 inline-flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
              onClick={() => {
                const url = window.URL.createObjectURL(file as Blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "backup-codes.txt");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.location.reload();
              }}
            >
              Download Backup Codes
            </button>
          </div>
        </div>
      </Box>
    </div>
  );
};

type MfaOption = {
  qrcode: string;
  secret: string;
};

const MultifactorAuthentication = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const [mfaOption, setMfaOption] = useState<MfaOption | null>(null);
  const [response, setResponse] = useState<FormResponse | null>(null);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);

  const [steps, setSteps] = useState<Step[]>([
    {
      id: 1,
      name: "Verify it's you",
      component: PasswordVerify,
      description:
        "Enter your password to confirm you would like to enable two-factor authentication.",
      status: "current",
    },
    {
      id: 2,
      name: "Multi-factor authentication",
      description:
        "Multi-factor authentication (2FA) is an extra layer of security used when logging into websites or apps.",
      component: MfaGenerate,
      status: "upcoming",
    },
    {
      id: 3,
      name: "Authentication activation",
      description:
        "Verify your auth method to enable two-factor authentication.",
      component: MfaValidate,
      status: "upcoming",
    },
    {
      id: 4,
      name: "Save your recovery codes",
      description: "Save your recovery codes to a secure location.",
      component: GenerateCode,
      status: "upcoming",
    },
  ]);

  const nextHandler = (step: Step) => {
    const newSteps: Step[] = steps.map((s) => {
      if (s.id === step.id) {
        return {
          ...s,
          status: "complete",
        };
      }
      if (s.id === step.id + 1) {
        return {
          ...s,
          status: "current",
        };
      }
      return s;
    });
    setSteps(newSteps);
  };

  return (
    <>
      <Head
        title="Multifactor Authentication"
        heading="Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Security",
            href: "/settings/security",
          },
          {
            name: "Multifactor Authentication",
            href: "/settings/multi-factor-authentication",
          },
        ]}
      />
      <div className="flex items-center px-4 pb-8 pt-6 sm:px-6 sm:pb-12 sm:pt-8 lg:mx-auto lg:max-w-xl lg:px-8 lg:pb-16 lg:pt-12">
        <nav aria-label="Progress" className="w-full">
          <ol className="overflow-hidden">
            {steps.map((step: Step, index) => {
              return (
                <li
                  key={step.name}
                  className={classNames(
                    index !== steps.length - 1 ? "pb-10" : "",
                    "relative"
                  )}
                >
                  {step.status === "complete" ? (
                    <>
                      {index !== steps.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-600"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="group relative flex items-start">
                        <span className="flex h-9 items-center">
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 ">
                            <CheckIcon
                              className="h-5 w-5 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </span>
                        <span className="ml-4 flex min-w-0 flex-col">
                          <span className="text-md font-medium">
                            {step.name}
                          </span>
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        </span>
                      </div>
                    </>
                  ) : step.status === "current" ? (
                    <>
                      {index !== steps.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div
                        className="group relative flex items-start"
                        aria-current="step"
                      >
                        <span
                          className="flex h-9 items-center"
                          aria-hidden="true"
                        >
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-600 bg-white">
                            <span className="h-2.5 w-2.5 rounded-full bg-primary-600" />
                          </span>
                        </span>
                        <span className="ml-4 flex min-w-0 flex-col">
                          <span className="text-lg font-medium text-primary-600">
                            {step.name}
                          </span>
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        </span>
                      </div>

                      <Reveal
                        props={{
                          step,
                          mfaOption,
                          setMfaOption,
                          response,
                          setResponse,
                          component: step.component,
                          nextHandler,
                          backupCodes,
                          setBackupCodes,
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {index !== steps.length - 1 ? (
                        <div
                          className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                          aria-hidden="true"
                        />
                      ) : null}
                      <div className="group relative flex items-start">
                        <span
                          className="flex h-9 items-center"
                          aria-hidden="true"
                        >
                          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                          </span>
                        </span>
                        <span className="ml-4 flex min-w-0 flex-col">
                          <span className="text-sm font-medium text-gray-500">
                            {step.name}
                          </span>
                          <span className="text-sm text-gray-500">
                            {step.description}
                          </span>
                        </span>
                      </div>
                    </>
                  )}
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </>
  );
};

export default MultifactorAuthentication;
export const MultifactorAuthenticationResource: ResourceProps = {
  name: "Multifactor Authentication",
  description: "Multifactor Authentication page",
  access: [],
  path: "multi-factor-authentication",
};
