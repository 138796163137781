import { TruckIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import { Avatar } from "../../../../components/appearance";
import {
  AssignmentComment,
  AssignmentCommentType,
} from "../../../../graphql/fleets/vehicle-assignments";
import { classNames } from "../../../../utils";
import { useAuth } from "../../../auth";

export function CardMessage({
  user,
  comments,
  messageType,
  createdAt,
}: {
  user: AssignmentComment["user"];
  comments: AssignmentComment["comments"];
  messageType: AssignmentComment["messageType"];
  createdAt: AssignmentComment["createdAt"];
}) {
  const { currentUser } = useAuth();
  if (!currentUser) return null;
  const name = `${user.firstName} ${user.lastName}`;
  const isAuthor = parseInt(currentUser?.id) === parseInt(user.id);

  if (messageType === AssignmentCommentType.COMPLETED) {
    return (
      <div className="py-4">
        <div
          className={classNames(
            "rounded-lg border border-primary-100 bg-primary-100 px-4 py-2 text-center text-sm font-light text-primary-900 md:px-6 md:py-3 xl:px-8 xl:py-4"
          )}
        >
          <TruckIcon className="mr-3 inline h-8 w-8 text-primary-700" />
          <p
            className="inline-block flex-1"
            dangerouslySetInnerHTML={{
              __html: comments || "",
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "flex items-start space-x-4 font-light",
        isAuthor ? "flex-row-reverse" : "flex-row"
      )}
    >
      <div className="relative z-10 flex-shrink-0">
        <Avatar
          firstName={user?.firstName}
          lastName={user?.lastName}
          className={classNames(
            "h-12 w-12 text-white",
            isAuthor ? "-ml-7 bg-primary-700" : "-mr-10 bg-secondary-800"
          )}
        />
      </div>
      <div
        className={classNames(
          "min-w-0 flex-1",
          isAuthor ? "text-right" : "text-left"
        )}
      >
        {isAuthor ? (
          <div
            className={classNames(
              "mb-1 mr-8 text-xs font-normal text-gray-500"
            )}
          >
            {createdAt}
          </div>
        ) : (
          <div
            className={classNames(
              "mb-1 ml-8 text-xs font-normal text-gray-500"
            )}
          >
            {name}, {createdAt}
          </div>
        )}
        <div
          className={classNames(
            "inline-block rounded-lg py-2 text-sm md:py-3 xl:py-4",
            isAuthor
              ? "ml-[15%] pl-5 pr-8 md:pl-7"
              : "mr-[15%] pl-8 pr-5 md:pr-7",
            messageType === AssignmentCommentType.READY
              ? "bg-gray-100"
              : isAuthor
              ? "border border-transparent bg-greyish"
              : "border border-gray-200 bg-white"
          )}
        >
          <Link to={`/user-management/users/${user.id}`} target="_blank">
            @{user.firstName}
          </Link>
          , {comments}
        </div>
      </div>
    </div>
  );
}
