import { Dialog, RadioGroup, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldPricingMethod,
  FieldTableProducts,
} from "../../../../components/form";
import { PreviewProduct } from "../../../../graphql/inventory/products";
import { type PricingMethod } from "../../../../graphql/inventory/settings/pricing-methods";
import { PreviewCustomer } from "../../../../graphql/sales/customers";
import { classNames } from "../../../../utils";

type PricingStructure = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: PreviewProduct[];
  customers: PreviewCustomer[];
  status: boolean;
};

type Structure = {
  id: string;
  name: string;
  productType: number;
  pricingMethod: SingleValue<OptionProps>;
  productIds: number[];
  status: boolean;
};

export default function Form({
  heading,
  initialValues,
  products: initialProducts,
  occupiedProductIds: initialOccupiedProductIds,
  pricingStructures,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
  actionType,
}: {
  heading: string;
  initialValues: Structure;
  products: PreviewProduct[];
  occupiedProductIds: number[];
  pricingStructures: PricingStructure[];
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
  actionType: string;
}) {
  const { t } = useTranslation();
  const [products, setProducts] = useState<PreviewProduct[]>([]);

  useEffect(() => {
    if (initialProducts?.length > 0) {
      setProducts(initialProducts);
    }
  }, [initialProducts]);

  const StructureSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    pricingMethod: Yup.object().required("Required"),
    productType: Yup.number().required("Required"),
    productIds: Yup.array().when("productType", {
      is: 1,
      then: (schema) =>
        schema
          .min(1)
          .of(Yup.string().trim().required("Required"))
          .required("Required"),
      otherwise: (schema) => schema.of(Yup.string().trim()),
    }),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: StructureSchema,
    onSubmit: onSubmit,
  });

  const occupiedProductIds: number[] = useMemo(() => {
    if (initialValues.id && parseInt(initialValues.id) > 0) {
      const pricingStructureProductIds = pricingStructures.reduce(
        (acc: number[], structure) => {
          if (structure.id === initialValues.id) {
            return acc;
          }
          return [
            ...acc,
            ...(structure?.products?.flatMap((p) => Number(p.id)) || []),
          ];
        },
        []
      );
      return [...initialOccupiedProductIds, ...pricingStructureProductIds];
    } else {
      const pricingStructureProductIds = pricingStructures.reduce(
        (acc: number[], structure) => [
          ...acc,
          ...(structure?.products?.flatMap((p) => Number(p.id)) || []),
        ],
        []
      );
      return [...initialOccupiedProductIds, ...pricingStructureProductIds];
    }
  }, [initialOccupiedProductIds, initialValues.id, pricingStructures]);

  const productTypes = [
    {
      label: "All Products",
      value: 0,
      description:
        occupiedProductIds.length > 0
          ? "Cannot be selected as there are products already assigned to this customer on other pricing structures"
          : "All products will be charged this price",
      disabled: occupiedProductIds.length > 0,
    },
    {
      label: "Specific Products",
      value: 1,
      description: "Only specific products will be charged this price",
      disabled: false,
    },
  ];

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 py-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.name ? formik.values.name : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Pricing structures are used to define how products are priced.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <Field
                      title={t("text_name")}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    {initialValues.id && parseInt(initialValues.id) > 0 ? (
                      <Field
                        title={t("text_pricing_method")}
                        name="name"
                        value={
                          formik.values.pricingMethod
                            ? formik.values.pricingMethod.label
                            : "--"
                        }
                        disabled
                        readOnly
                      />
                    ) : (
                      <>
                        <label className="block text-sm font-medium text-gray-900">
                          {t("text_pricing_method")}
                        </label>
                        <FieldPricingMethod
                          value={formik.values.pricingMethod}
                          onChange={(value: SingleValue<OptionProps>) => {
                            formik.setFieldValue("pricingMethod", value);
                          }}
                          className={classNames(
                            "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                            formik.touched.pricingMethod &&
                              formik.errors.pricingMethod
                              ? "border-red-600 text-red-900"
                              : ""
                          )}
                        />
                        {formik.touched.pricingMethod &&
                        formik.errors.pricingMethod ? (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="pricingMethod-errors"
                          >
                            {formik.errors.pricingMethod.toString()}
                          </p>
                        ) : null}
                      </>
                    )}
                  </div>
                  {actionType === "create" ? (
                    <div className="sm:col-span-6">
                      <RadioGroup
                        value={formik.values.productType}
                        onChange={(value: number) => {
                          formik.setFieldValue("productType", value);
                        }}
                      >
                        <RadioGroup.Label className="text-sm font-medium text-gray-900">
                          Product Type
                        </RadioGroup.Label>

                        <div className="isolate mt-1 -space-y-px rounded-md bg-white">
                          {productTypes.map((type, typeIdx) => (
                            <RadioGroup.Option
                              key={type.label}
                              value={type.value}
                              className={({ checked }) =>
                                classNames(
                                  typeIdx === 0
                                    ? "rounded-tl-md rounded-tr-md"
                                    : "",
                                  typeIdx === productTypes.length - 1
                                    ? "rounded-bl-md rounded-br-md"
                                    : "",
                                  checked
                                    ? "z-10 border-primary-200 bg-primary-50"
                                    : "border-gray-200",
                                  "relative flex cursor-pointer border p-4 focus:outline-none"
                                )
                              }
                              disabled={type.disabled}
                            >
                              {({ active, checked }) => (
                                <>
                                  <span
                                    className={classNames(
                                      checked
                                        ? "border-transparent bg-primary-600"
                                        : "border-gray-300 bg-white",
                                      active
                                        ? "ring-2 ring-primary-500 ring-offset-2"
                                        : "",
                                      "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                                    )}
                                    aria-hidden="true"
                                  >
                                    <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                  </span>
                                  <span className="ml-3 flex flex-col">
                                    <RadioGroup.Label
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-primary-900"
                                          : "text-gray-900",
                                        "block text-sm font-medium"
                                      )}
                                    >
                                      {type.label}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="span"
                                      className={classNames(
                                        checked
                                          ? "text-primary-700"
                                          : "text-gray-500",
                                        "block text-sm"
                                      )}
                                    >
                                      {type.description}
                                    </RadioGroup.Description>
                                  </span>
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  ) : null}
                  {formik.values.productType === 1 ? (
                    <div className="sm:col-span-6">
                      <FieldTableProducts
                        title="Products"
                        data={products}
                        value={products.flatMap((p) => Number(p.id))}
                        onChange={(_products) => {
                          formik.setFieldValue(
                            "productIds",
                            _products.flatMap((p) => Number(p.id))
                          );
                          setProducts(_products);
                        }}
                        excludeIds={occupiedProductIds}
                        excludeNote={t("text_product_already_in_use")}
                      />

                      {formik.touched.productIds && formik.errors.productIds ? (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="productIds-errors"
                        >
                          {formik.errors.productIds.toString()}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="sm:col-span-3">
                    <fieldset>
                      <legend className="text-sm font-medium text-gray-900">
                        {t("text_status")}
                      </legend>
                      <Switch.Group
                        as="div"
                        className="mt-1.5 inline-flex items-center"
                      >
                        <Switch
                          checked={formik.values.status}
                          onChange={() => {
                            formik.setFieldValue(
                              "status",
                              !formik.values.status
                            );
                          }}
                          id="status"
                          className={classNames(
                            formik.values.status
                              ? "bg-primary-600"
                              : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              formik.values.status
                                ? "translate-x-5"
                                : "translate-x-0",
                              "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                        <Switch.Label
                          passive
                          htmlFor="status"
                          className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                        >
                          {formik.values.status
                            ? t("text_active")
                            : t("text_inactive")}
                        </Switch.Label>
                      </Switch.Group>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
