import { gql, useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon, InboxIcon } from "@heroicons/react/24/outline";
import { PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { customAlphabet } from "nanoid";
import { Fragment, useCallback, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Spinner } from "../../../../animations";
import placeholder from "../../../../assets/placeholder.svg";
import { Button } from "../../../../components/form";
import { LimitBy, TableCursor } from "../../../../components/table";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  ADD_ORDER,
  Customer,
  EDIT_ORDER,
  Order,
  renderStatus,
} from "../../../../graphql/sales/orders";
import { classNames, formatDate } from "../../../../utils";
import { AuthShield } from "../../../auth/core";
import FormCreateOrder from "./FormCreateOrder";
import FormUpdateOrder from "./FormUpdateOrder";

const nanoidCustom = customAlphabet("1234567890", 10);

const GET_CUSTOMER_BY_ID = gql`
  query FetchCustomer($id: ID!) {
    fetchCustomer(id: $id) {
      id
      customerName
      email
      tradingAddresses {
        id
        address
        suburb
        state
        postcode
        primaryAddress
      }
      orders {
        id
        orderNumber
        purchaseNumber
        customer {
          id
          customerName
          email
          tradingAddresses {
            id
            address
            suburb
            state
            postcode
          }
        }
        items {
          id
          price
          quantity
          orderComments
          variant {
            product {
              id
              name
              handle
              description
              featureImageUrl
              status
            }
            id
            stockCode
            variantImageUrl
            variantTitle {
              name
              option {
                name
              }
            }
            minimumQuantity
            inventory
          }
        }
        deliveryDate
        notes
        shippingAddress {
          id
          address
          suburb
          state
          postcode
        }
        status
        subTotal
        total
        updatedAt
        createdAt
      }
    }
  }
`;

export default function Orders({
  newOrder,
  handleNewOrder,
}: {
  newOrder?: boolean;
  handleNewOrder?: (status: boolean) => void;
}) {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const [refetching, setRefetching] = useState<boolean>(false);
  const { customerId } = useParams<{ customerId: string }>();
  const { addNotify } = useNotifyContext();
  const [editOrder, setEditOrder] = useState(false);
  const [activeOrder, setActiveOrder] = useState<Order | undefined>(undefined);
  const [updateOrder] = useMutation(EDIT_ORDER, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_BY_ID,
        variables: { id: customerId },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [createOrder] = useMutation(ADD_ORDER, {
    refetchQueries: [
      {
        query: GET_CUSTOMER_BY_ID,
        variables: { id: customerId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const { data, loading, refetch } = useQuery(GET_CUSTOMER_BY_ID, {
    variables: { id: customerId },
  });
  const customer = useMemo<Customer | undefined>(
    () => data?.fetchCustomer,
    [data]
  );

  const columns = useMemo<ColumnDef<Order, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Order Number",
        enableHiding: false,
        cell: ({ row }) => (
          <Link
            to={`/sales/orders/${row.original.id}`}
            className="text-gray-700 transition hover:text-primary-700"
          >
            #{row.original.orderNumber}
          </Link>
        ),
        accessorFn: (row) => row.orderNumber,
      },
      {
        accessorKey: "customer.email",
        header: "Customer",
        enableHiding: false,
        cell: ({ row }) => (
          <Link
            to={`/sales/customers/${row.original.customer.id}`}
            target="_blank"
            className="whitespace-nowrap text-gray-700 transition hover:text-primary-700"
          >
            {row.original.customer.email}
          </Link>
        ),
        accessorFn: (row) => row.customer.email,
      },
      {
        accessorKey: "total",
        header: "Order Total",
        enableHiding: false,
        cell: ({ row }) => (
          <span className="font-medium text-gray-900">
            ${row.original.total}
          </span>
        ),
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (props) => renderStatus(props.row.original.status),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-salesorders"]}>
              <Button
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
                onClick={() => {
                  setActiveOrder(props.row.original);
                  setEditOrder(true);
                }}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Edit order, {props.row.original.orderNumber}
                </span>
              </Button>
            </AuthShield>
            {/* <AuthShield access={["destroy-salesorders"]}>
              <button
                type="button"
                className="appearance-none py-2 text-red-700 hover:text-red-900"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.orderNumber,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.orderNumber}
                </span>
              </button>
            </AuthShield> */}
          </div>
        ),
      },
    ],
    []
  );

  const handleUpdateOrder = useCallback(
    (values: any, actions: { setSubmitting: (arg0: boolean) => void }) => {
      if (!activeOrder) return;

      updateOrder({
        variables: {
          id: activeOrder.id,
          customerId: values.customerId,
          purchaseNumber: values.purchaseNumber,
          customerTradingAddressId: values.customerTradingAddressId,
          subTotal: parseInt(values.subTotal),
          total: parseInt(values.total),
          deliveryDate: values.deliveryDate,
          notes: values.notes,
          status: values.status,
          products: values.products,
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.orderUpdate) {
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Order updated successfully",
            });
            setEditOrder(false);
            refetch();
            setTimeout(() => {
              setActiveOrder(undefined);
            }, 300);
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Order update failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          addNotify({
            type: NotifyType.ERROR,
            title: "Order update failed",
            message: error.message,
          });
        });
    },
    [activeOrder, addNotify, refetch, updateOrder]
  );

  const handleCreateOrder = useCallback(
    (values: any, actions: { setSubmitting: (arg0: boolean) => void }) => {
      createOrder({
        variables: {
          customerId: values.customerId,
          purchaseNumber: values.purchaseNumber,
          customerTradingAddressId: values.customerTradingAddressId,
          subTotal: parseInt(values.subTotal),
          total: parseInt(values.total),
          deliveryDate: values.deliveryDate,
          notes: values.notes,
          status: values.status,
          products: values.products,
        },
      })
        .then(({ data }) => {
          actions.setSubmitting(false);
          if (data?.orderCreate) {
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Order created successfully",
            });
            handleNewOrder && handleNewOrder(false);
            refetch();
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Order creation failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          addNotify({
            type: NotifyType.ERROR,
            title: "Order creation failed",
            message: error.message,
          });
        });
    },
    [addNotify, createOrder, handleNewOrder, refetch]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  return (
    <>
      <Fragment>
        {!loading && customer?.orders.length === 0 ? (
          <div>
            <div className="py-10 text-center md:py-16 xl:py-20">
              <InboxIcon
                type="outline"
                name="inbox"
                className="mx-auto h-6 w-6 text-gray-400"
              />
              <p className="mt-4 font-medium text-gray-900">
                No data available
              </p>
              <div className="flex items-center justify-center space-x-2">
                <AuthShield access={["create-salesorders"]}>
                  <Button
                    onClick={() => {
                      handleNewOrder && handleNewOrder(true);
                    }}
                    className="mt-5"
                  >
                    <PlusCircleIcon className="mr-2 h-5 w-5" />
                    Create order
                  </Button>
                </AuthShield>
                <Button
                  variant="info"
                  border
                  onClick={handleRefetch}
                  disabled={loading}
                  className="mt-5 px-2"
                >
                  <ArrowPathIcon
                    aria-hidden="true"
                    className={classNames(
                      "h-5 w-5",
                      refetching ? "animate-spin" : ""
                    )}
                  />
                  <span className="sr-only">Refresh order list</span>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <TableCursor
            data={
              customer?.orders.map((o) => ({
                ...o,
                createdAt: formatDate(o.createdAt),
              })) || []
            }
            columns={columns}
            loading={loading}
            totalRows={customer?.orders?.length || 0}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            getCoreRowModel={getCoreRowModel()}
            enableGlobalFilter={true}
          />
        )}
      </Fragment>

      {/* <TablePaginate
        data={customer?.orders || []}
        columns={columns}
        loading={loading}
        className="mt-4"
        renderSubComponent={() => (
          <div className="flex justify-end space-x-4">
            <AuthShield access={["create-salesorders"]}>
              <Button
                onClick={() => {
                  handleNewOrder && handleNewOrder(true);
                }}
              >
                Create order
              </Button>
            </AuthShield>
          </div>
        )}
      /> */}

      <Transition.Root
        show={editOrder}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setEditOrder(false);
            setTimeout(() => {
              setActiveOrder(undefined);
            }, 300);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-5xl transform divide-y divide-gray-100 rounded-2xl bg-white p-6 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                {activeOrder && activeOrder?.id ? (
                  <FormUpdateOrder
                    initialValues={{
                      customerId: parseInt(activeOrder.customer.id),
                      purchaseNumber: activeOrder.purchaseNumber ?? "",
                      products:
                        activeOrder.items.map((item) => ({
                          productId: parseInt(item.variant.id),
                          quantity: item.quantity,
                          price: item.price,
                          orderComments: item.orderComments ?? "",
                        })) ?? [],
                      customerTradingAddressId: activeOrder.shippingAddress
                        ? parseInt(activeOrder.shippingAddress.id)
                        : null,
                      deliveryDate: activeOrder.deliveryDate ?? null,
                      notes: activeOrder.notes ?? "",
                      subTotal: activeOrder.subTotal ?? 0,
                      total: activeOrder.total ?? 0,
                      status: activeOrder.status ?? 1,
                    }}
                    orderNumber={activeOrder.orderNumber}
                    orderUpdatedAt={activeOrder.updatedAt}
                    products={activeOrder.items.flatMap((item, index) => ({
                      index,
                      id: isNaN(parseInt(item.variant?.id))
                        ? nanoidCustom()
                        : item.variant?.id,
                      oid: item.id,
                      productId: isNaN(parseInt(item.variant?.product?.id))
                        ? Number(nanoidCustom())
                        : Number(item.variant.product.id),
                      name:
                        `${
                          item.variant?.product?.name
                        } - ${item.variant?.variantTitle
                          ?.map((v) => v.name)
                          ?.join(" / ")}` ?? "",
                      price: item.price,
                      quantity: item.quantity,
                      productApprove: item.productApprove ?? 0,
                      featureImageUrl:
                        item.variant?.variantImageUrl ??
                        item.variant?.product?.featureImageUrl ??
                        placeholder,
                      plu: item.variant?.plu ?? null,
                      stockCode: item.variant?.stockCode ?? "",
                      minimumQuantity: item.variant?.minimumQuantity ?? 0,
                      variantTitle: item.variant?.variantTitle ?? [],
                      inventory: item.variant?.inventory ?? 0,
                      status: item.variant?.product.status ?? 0,
                      orderComments: item.orderComments ?? "",
                    }))}
                    customer={{
                      id: activeOrder.customer.id,
                      customerName: activeOrder.customer.customerName,
                      email: activeOrder.customer.email,
                      tradingAddresses: activeOrder.customer.tradingAddresses,
                    }}
                    onSubmit={handleUpdateOrder}
                    onCancel={() => {
                      setEditOrder(false);
                      setTimeout(() => {
                        setActiveOrder(undefined);
                      }, 300);
                    }}
                  />
                ) : (
                  <Spinner />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={newOrder ? true : false} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            handleNewOrder && handleNewOrder(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-5xl transform divide-y divide-gray-100 rounded-2xl bg-white p-6 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                {customer ? (
                  <FormCreateOrder
                    initialValues={{
                      customerId: Number(customer.id),
                      purchaseNumber: "",
                      products: [],
                      customerTradingAddressId:
                        Number(
                          customer.tradingAddresses.find(
                            (v) => v.primaryAddress
                          )?.id
                        ) ?? null,
                      deliveryDate: null,
                      notes: "",
                      subTotal: 0,
                      total: 0,
                      status: 1,
                    }}
                    customer={{
                      id: customer.id,
                      customerName: customer.customerName,
                      email: customer.email,
                      tradingAddresses: customer.tradingAddresses,
                    }}
                    onSubmit={handleCreateOrder}
                    onCancel={() => handleNewOrder && handleNewOrder(false)}
                  />
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
