import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../../animations";
import { ErrorFallback, Head } from "../../../../../components/core";
import { VehicleBasic } from "../../../../../graphql/fleets/vehicles";
import {
  EDIT_METERENTRY,
  GET_METERENTRY_BY_ID,
  GET_METERENTRY_BY_VEHICLE_ID,
  VehicleMeterEntry,
  VehicleMeterEntryForm,
} from "../../../../../graphql/fleets/vehicles/pages/meter-entry";
import Form from "../../components/MeterEntryForm";

const MeterEntryUpdate = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId, meterEntryId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchMeterEntry: VehicleMeterEntry;
  }>(GET_METERENTRY_BY_ID, {
    variables: {
      id: meterEntryId,
    },
  });

  const meterEntry = useMemo<VehicleMeterEntry | undefined>(
    () => data?.fetchMeterEntry,
    [data]
  );

  const [updateVehicleMeterEntry] = useMutation(EDIT_METERENTRY, {
    refetchQueries: [
      { query: GET_METERENTRY_BY_VEHICLE_ID, variables: { vehicleId } },
    ],
    awaitRefetchQueries: true,
  });
  const handleUpdate = (
    values: VehicleMeterEntryForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!meterEntryId || !vehicleId) return;
    updateVehicleMeterEntry({
      variables: {
        id: meterEntryId,
        vehicleId: vehicleId,
        meterDate: values.meterDate,
        primaryMeter: values.primaryMeter,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleMeterEntryUpdate) {
          toast.success(`Vehicle meter entry updated successfully`);
          return navigate(`/fleets/vehicles/${vehicleId}/meter-entry`);
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={MeterEntryUpdateResource.name}
        heading={MeterEntryUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: MeterEntryUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="max-w-medium mx-auto">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_meter_entry_update")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_meter_entry_update")}
              </p>
            </div>
          </div>

          {loading || !meterEntry ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                vehicleId: Number(vehicleId),
                meterDate: meterEntry.meterDate ?? null,
                primaryMeter: meterEntry.primaryMeter ?? null,
              }}
              onSubmit={handleUpdate}
              submitLabel={t("text_update")}
              onCancel={() => {
                navigate(
                  `/fleets/vehicles/${vehicleId}/meter-entry/${meterEntryId}`
                );
              }}
              vehicleBasic={vehicleBasic}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MeterEntryUpdate;
export const MeterEntryUpdateResource: ResourceProps = {
  name: "Edit Meter Entry",
  description: "Update an existing meter entry",
  access: ["update-vehicles"],
  path: "/meter-entry/:meterEntryId/edit",
};
