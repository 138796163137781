import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { Listbox, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  FolderPlusIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";

import placeholder from "../../../assets/placeholder.svg";
import { ErrorFallback, Head } from "../../../components/core";
import { Button, selectStyles, SelectWrapper } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  type Category,
  GET_CATEGORIES,
} from "../../../graphql/inventory/categories";
import {
  type Department,
  GET_DEPARTMENTS,
} from "../../../graphql/inventory/departments";
import {
  GET_PRODUCTS,
  type Product,
  Status,
  Vendor,
} from "../../../graphql/inventory/products";
import { GET_VENDORS } from "../../../graphql/purchases/vendors";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { IconFilterClear } from "../../../svgs";
import { classNames } from "../../../utils";
import { AuthShield } from "../../auth/core";
import FormImport from "./components/FormImport";

type RowProduct = Omit<Product, "categories"> & {
  categories: string;
  department: string;
  subRows: RowProduct[];
};

const ProductList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t, i18n } = useTranslation();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [showImportForm, setShowImportForm] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [isMorefetching, setIsMorefetching] = useState(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string | null>(
    searchParams.get("query") || ""
  );
  const [department, setDepartment] = useState<SingleValue<OptionProps>>(null);
  const [category, setCategory] = useState<SingleValue<OptionProps>>(null);
  const [vendor, setVendor] = useState<SingleValue<OptionProps>>(null);
  const [stockCode, setStockCode] = useState<string>("");
  const [status, setStatus] = useState<SingleValue<OptionProps>>(null);

  const [rowSelection, setRowSelection] = useState({});
  const [expanded, setExpanded] = useState<ExpandedState>({});

  useEffect(() => {
    setSearchQuery(searchParams.get("query") || "");
  }, [searchParams]);

  const handleClearFilters = useCallback(() => {
    setSearchParams({ query: "" });
    setDepartment(null);
    setCategory(null);
    setVendor(null);
    setStockCode("");
    setStatus(null);
  }, [setSearchParams]);

  const {
    data: departmentsData,
    loading: departmentsLoading,
    refetch: departmentsRefetch,
  } = useQuery(GET_DEPARTMENTS);

  const {
    data: categoriesData,
    loading: categoriesLoading,
    refetch: categoriesRefetch,
  } = useQuery(GET_CATEGORIES);

  const {
    data: vendorsData,
    loading: vendorsLoading,
    refetch: vendorsRefetch,
  } = useQuery(GET_VENDORS);

  const statusData = [
    { value: String(Status.INACTIVE), label: t("text_inactive") },
    { value: String(Status.DRAFT), label: t("text_draft") },
    { value: String(Status.ACTIVE), label: t("text_active") },
  ];

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");
  if (department) searchFilter.push("department");
  if (category) searchFilter.push("category");
  if (vendor) searchFilter.push("vendor");
  if (stockCode) searchFilter.push("stockCode");
  if (status) searchFilter.push("status");

  const { data, loading, error, refetch, fetchMore } = useQuery(GET_PRODUCTS, {
    variables: {
      first: pageLimit,
      searchQuery,
      searchFilter,
      status: status ? Number(status.value) : null,
      stockCode,
      vendorId: vendor ? vendor.value : null,
      categoryId: category ? category.value : null,
      departmentId: department ? department.value : null,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const products: RowProduct[] = useMemo(
    () =>
      data?.fetchProducts.edges?.flatMap((edge: { node: Product }) => ({
        ...edge.node,
        stockCode: edge.node.variants.length
          ? edge.node.variants[0].stockCode
          : "",
        price: edge.node.variants.length ? edge.node.variants[0].basePrice : 0,
        vendor: edge.node.vendor?.companyName,
        department: edge.node.department?.name || "",
        categories: edge.node.categories?.flatMap((c) => c.name)?.join(", "),
        createdAt: DateTime.fromISO(edge.node?.createdAt, {
          locale: i18n.language,
        }).toLocaleString(DateTime.DATE_MED),
        subRows:
          edge.node.variants?.length > 1
            ? edge.node.variants.map((v) => ({
                ...v,
                price: v.basePrice,
                name: (
                  <div className="text-gray-500">
                    {edge.node.name}
                    <span className="ml-2 inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
                      {v.variantTitle?.map((v) => v.name)?.join(" / ")}
                    </span>
                  </div>
                ),
                department: edge.node.department?.name,
                categories: edge.node.categories
                  ?.flatMap((c) => c.name)
                  ?.join(", "),
                stockCode: v.stockCode,
              }))
            : [],
      })) ?? [],
    [data?.fetchProducts.edges, i18n.language]
  );

  const filtering: boolean = useMemo(
    () =>
      searchFilter.length > 0 ||
      vendor ||
      category ||
      department ||
      typeof status === "number" ||
      !!stockCode ||
      !!searchQuery
        ? true
        : false,
    [
      searchFilter.length,
      vendor,
      category,
      department,
      status,
      stockCode,
      searchQuery,
    ]
  );

  const fetchNextPage = useCallback(
    (table: any) => {
      setIsMorefetching(true);
      fetchMore({
        variables: {
          cursor: data?.fetchProducts?.pageInfo?.endCursor,
        },
      }).finally(() => {
        setTimeout(() => {
          setIsMorefetching(false);
          table.setPageIndex(table.getPageCount() - 1);
        }, 500);
      });
    },
    [data?.fetchProducts?.pageInfo?.endCursor, fetchMore]
  );

  const DELETE_PRODUCT = gql`
    mutation ProductDelete($id: ID!) {
      productDelete(input: { id: $id }) {
        product {
          id
          name
        }
      }
    }
  `;
  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: {
          first: 50,
          searchQuery: "",
          searchFilter: [],
          status: null,
          stockCode: "",
          vendorId: null,
          categoryId: null,
          departmentId: null,
        },
      },
    ],
  });
  const handleDelete = useCallback(
    async (id: string) => {
      return await deleteProduct({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data?.productDelete) {
            const deletedProduct = data?.productDelete?.product;
            refetch();
            addNotify({
              type: NotifyType.SUCCESS,
              title: `Product ${deletedProduct.name} deleted`,
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Product delete failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Product delete failed",
            message: error.message,
          });
        });
    },
    [deleteProduct, refetch, addNotify]
  );

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<RowProduct, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <div className="flex w-full items-center">
            <Link
              to={`/inventory/products/${props.row.original.id}`}
              className="relative z-10 mr-3 block h-12 w-12 cursor-pointer appearance-none overflow-hidden rounded-md ring-2 ring-white filter transition hover:hue-rotate-15"
            >
              <img
                className={classNames("h-full w-full object-cover")}
                src={
                  props.row.original.featureImageUrl
                    ? props.row.original.featureImageUrl
                    : placeholder
                }
                alt={props.getValue()}
              />
            </Link>
            <span className="max-w-[12rem] truncate lg:max-w-[14rem] 2xl:max-w-[18rem]">
              {props.getValue()}
            </span>
          </div>
        ),
      },
      {
        accessorKey: "stockCode",
        header: () => <span className="whitespace-nowrap">Stock Code</span>,
        size: 200,
      },
      {
        accessorKey: "department",
        header: "Department",
      },
      {
        accessorKey: "categories",
        header: "Categories",
      },
      {
        accessorKey: "vendor",
        header: "Vendor ",
      },
      {
        accessorKey: "price",
        header: "Price",
        cell: (props) => (
          <span className="whitespace-nowrap">${props.getValue()}</span>
        ),
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (props) =>
          props.getValue() === 2 ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : props.getValue() === 0 ? (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ) : props.getValue() === 1 ? (
            <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
              Draft
            </span>
          ) : null,
        sortingFn: (a, b) => {
          if (a.original.status === 2) {
            return -1;
          }
          if (a.original.status === 0) {
            return 1;
          }
          return 0;
        },
      },
      {
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-products"]}>
              <Button
                type="link"
                href={`/inventory/products/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-products"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
        enableHiding: false,
        enableSorting: false,
        size: 220,
      },
    ],
    []
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Products"
        heading="Products"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Products",
            href: "/inventory/products",
          },
          {
            name: ProductListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">Products</h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage your products, including their name, inventory, and more.
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["update-products"]}>
              <Button
                variant="tertiary"
                onClick={() => {
                  setShowImportForm(true);
                }}
              >
                <FolderPlusIcon className="mr-2 h-5 w-5" />
                Import
              </Button>
            </AuthShield>
            <AuthShield access={["create-products"]}>
              <Button type="link" href="/inventory/products/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add product
              </Button>
            </AuthShield>
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh producsts list</span>
            </Button>
            <Button
              variant="icon"
              className={classNames(
                "group relative flex h-9 items-center justify-center transition-all",
                showFilters ? "text-primary-900" : "text-black"
              )}
              onClick={() => {
                setShowFilters((prev) => !prev);
              }}
            >
              <div className="w-[1.4rem] space-y-[0.3rem]">
                <span className="relative block h-[1.5px] w-full rounded-full bg-current">
                  <span
                    className={classNames(
                      "absolute left-[30%] top-1/2 -ml-[3px] block h-[5px] w-[5px] -translate-y-1/2 rounded-full border border-current bg-greyish transition-all delay-100 duration-300",
                      showFilters
                        ? "left-[65%] bg-current"
                        : "group-hover:left-[50%] group-hover:bg-current"
                    )}
                  ></span>
                </span>
                <span className="relative block h-[1.5px] w-full rounded-full bg-current">
                  <span
                    className={classNames(
                      "absolute left-[65%] top-1/2 -ml-[3px] block h-[5px] w-[5px] -translate-y-1/2 rounded-full border border-current bg-greyish transition-all delay-75 duration-200",
                      showFilters
                        ? "left-[35%] bg-current"
                        : "group-hover:left-[25%] group-hover:bg-current"
                    )}
                  ></span>
                </span>
                <span className="relative block h-[1.5px] w-full rounded-full bg-current">
                  <span
                    className={classNames(
                      "absolute left-[20%] top-1/2 -ml-[3px] block h-[5px] w-[5px] -translate-y-1/2 rounded-full border border-current bg-greyish transition-all delay-100 duration-500",
                      showFilters
                        ? "left-[75%] bg-current"
                        : "group-hover:left-[65%] group-hover:bg-current"
                    )}
                  ></span>
                </span>
              </div>
              <span className="sr-only">Toggle Filters</span>
            </Button>
          </div>
        </div>
        {showFilters && (
          <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap">
            <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
              <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <DebounceInput
                type="search"
                className={classNames(
                  "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                  "shadow-none outline-none focus:ring-0",
                  "placeholder-black/50 placeholder-opacity-100"
                )}
                placeholder="Search for name"
                minLength={2}
                debounceTimeout={300}
                value={searchQuery ?? ""}
                onChange={(e) => {
                  setSearchParams({
                    query: e.target.value,
                  });
                }}
              />
            </div>

            <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
              <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                <Select
                  className="h-11 w-full xl:whitespace-nowrap"
                  closeMenuOnSelect={true}
                  styles={selectStyles}
                  value={department}
                  options={departmentsData?.fetchDepartments.map(
                    (d: Department) => ({
                      value: d.id,
                      label: d.name,
                    })
                  )}
                  onChange={setDepartment}
                  isLoading={departmentsLoading}
                  placeholder="Filter by department"
                  isClearable
                  onMenuOpen={() => {
                    departmentsRefetch();
                  }}
                />
              </SelectWrapper>
            </div>
            <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
              <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                <Select
                  className="h-11 w-full xl:whitespace-nowrap"
                  closeMenuOnSelect={true}
                  styles={selectStyles}
                  value={category}
                  options={categoriesData?.fetchCategories.map(
                    (d: Category) => ({
                      value: d.id,
                      label: d.name,
                    })
                  )}
                  onChange={setCategory}
                  isLoading={categoriesLoading}
                  placeholder="Filter by category"
                  isClearable
                  onMenuOpen={() => {
                    categoriesRefetch();
                  }}
                />
              </SelectWrapper>
            </div>
            <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
              <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                <Select
                  className="h-11 w-full xl:whitespace-nowrap"
                  closeMenuOnSelect={true}
                  styles={selectStyles}
                  value={vendor}
                  options={vendorsData?.fetchVendors.map((d: Vendor) => ({
                    value: d.id,
                    label: d.companyName,
                  }))}
                  onChange={setVendor}
                  isLoading={vendorsLoading}
                  placeholder="Filter by vendor"
                  isClearable
                  onMenuOpen={() => {
                    vendorsRefetch();
                  }}
                />
              </SelectWrapper>
            </div>
            <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
              <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <Listbox value={status} onChange={setStatus}>
                {({ open }) => (
                  <>
                    <Listbox.Label className="sr-only">Status</Listbox.Label>
                    <div className="relative">
                      <Listbox.Button className="relative h-11 w-full cursor-default pl-3 pr-10 text-left text-sm xl:whitespace-nowrap">
                        <span className="flex items-center">
                          <span
                            aria-label={status?.value}
                            className={classNames(
                              Number(status?.value) === Status.INACTIVE
                                ? "bg-red-400"
                                : Number(status?.value) === Status.DRAFT
                                ? "bg-yellow-400"
                                : Number(status?.value) === Status.ACTIVE
                                ? "bg-primary-400"
                                : "bg-gray-200",
                              "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                            )}
                          />
                          <span
                            className={classNames(
                              "ml-3 block truncate",
                              status ? "text-black" : "text-black/50"
                            )}
                          >
                            {status ? status.label : "Filter by status"}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute right-0 z-20 mt-5 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-3 md:w-44 md:px-2.5 md:py-3">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="16px"
                            height="16px"
                            viewBox="0 0 110.9 96"
                            enableBackground="new 0 0 110.9 96"
                            className="absolute -top-3 right-0 -z-0"
                            fill="#ffffff"
                            style={{
                              filter:
                                "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                            }}
                          >
                            <polygon points="110.9,0 0,96 110.9,96 " />
                          </svg>
                          {statusData.map((_status) => (
                            <Listbox.Option
                              key={`status-${_status.value}`}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "bg-primary-700/10 text-primary-700"
                                    : "",
                                  "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2"
                                )
                              }
                              value={_status}
                            >
                              {({ selected, active }) => (
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      Number(_status?.value) === Status.INACTIVE
                                        ? "bg-red-400"
                                        : Number(_status?.value) ===
                                          Status.DRAFT
                                        ? "bg-yellow-400"
                                        : Number(_status?.value) ===
                                          Status.ACTIVE
                                        ? "bg-primary-400"
                                        : "bg-gray-200",
                                      "inline-block h-2 w-2 flex-shrink-0 rounded-full"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={classNames(
                                      selected ? "text-gray-900" : "",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {_status.label}
                                    <span className="sr-only">
                                      {" "}
                                      is {status?.label}
                                    </span>
                                  </span>
                                </div>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div>
              <Button
                variant="icon"
                className="relative flex h-11 w-11 items-center justify-center rounded-md text-gray-600 transition-all hover:bg-primary-200 hover:text-primary-900"
                onClick={handleClearFilters}
                disabled={loading}
              >
                <IconFilterClear className="h-5 w-5" />
                <span className="sr-only">Clear Filters</span>
              </Button>
            </div>
          </div>
        )}

        <TableCursor
          data={products}
          columns={columns}
          loading={loading}
          totalRows={data?.fetchProducts?.totalCount || 0}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          filtering={filtering}
          state={{
            rowSelection,
            expanded,
            columnVisibility: {
              categories: false,
              createdAt: false,
            },
          }}
          enableRowSelection={false}
          onExpandedChange={setExpanded}
          onRowSelectionChange={setRowSelection}
          getSubRows={(row) => row.subRows}
          getExpandedRowModel={getExpandedRowModel()}
          getCoreRowModel={getCoreRowModel()}
          enableNavigation
          renderNavigation={(table) => (
            <Button
              variant="icon"
              onClick={() => {
                fetchNextPage(table);
              }}
              disabled={!data?.fetchProducts?.pageInfo?.hasNextPage}
              loading={isMorefetching}
              className="flex h-8 w-8 items-center justify-center rounded-md bg-primary-300 text-black disabled:bg-gray-200"
            >
              <span className="sr-only"> Go to next page</span>
              <span className="bi bi-chevron-bar-right"></span>
            </Button>
          )}
          fallbackTitle="No products found"
          fallbackText="There are no products available at the moment."
        />
      </div>
      <FormImport open={showImportForm} setOpen={setShowImportForm} />
    </>
  );
};

export default ProductList;
export const ProductListResource: ResourceProps = {
  name: "Products List",
  description: "A list of products",
  access: ["read-products"],
  path: "products",
};
