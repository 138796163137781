import { PricingCategoriesResource } from "./pricing-categories";

export const PricingResource = {
  name: "Pricing",
  description: "Pricing management module",
  path: "inventory/pricing",
  icon: "bi bi-bag",
  access: ["read-pricings"],
  children: [PricingCategoriesResource],
};
