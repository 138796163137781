import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

import { Dock, GET_DOCKS } from "../../graphql/fleets/settings/docks";
import { selectStyles, SelectWrapper } from ".";

export function FieldDock({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery(GET_DOCKS, {
    variables: {
      status: true,
    },
  });

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchDocks) {
      return data.fetchDocks.map((d: Dock) => ({
        value: d.id,
        label: d.name,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
