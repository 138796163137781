import { useMutation, useQuery } from "@apollo/client/react";
import { useContext, useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import { GenContext } from "../../../../contexts/GenContext";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  ADD_VEHICLESPECS,
  EDIT_VEHICLESPECS,
  GET_VEHICLESPECS,
  VehicleSpec,
  VehicleSpecForm,
} from "../../../../graphql/fleets/vehicles/pages/specifications";
import Form from "../components/SpecificationsForm";

const Specifications = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const { vehicleForm, storeVehicleForm } = useContext(GenContext);

  const { data, loading, error, refetch } = useQuery<{
    fetchSpecsByVehicle: VehicleSpec;
  }>(GET_VEHICLESPECS, {
    variables: {
      vehicleId,
    },
  });
  const specifications = useMemo<VehicleSpec | null>(
    () => data?.fetchSpecsByVehicle || null,
    [data]
  );

  const [createVehicleSpecification] = useMutation(ADD_VEHICLESPECS);
  const [updateVehicleSpecification] = useMutation(EDIT_VEHICLESPECS);

  const handleSubmit = (
    values: VehicleSpecForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!vehicleId) return;
    if (specifications?.id) {
      updateVehicleSpecification({
        variables: {
          id: specifications.id,
          vehicleId: parseInt(vehicleId),
          bedLength: values.bedLength,
          compression: values.compression,
          curbWeight: values.curbWeight,
          cylinders: values.cylinders,
          displacement: values.displacement,
          engineBore: values.engineBore,
          engineBrand: values.engineBrand,
          engineSummary: values.engineSummary,
          epaCity: values.epaCity,
          epaCombined: values.epaCombined,
          epaHighway: values.epaHighway,
          fuelQuality: values.fuelQuality,
          fuelTankOneCapacity: values.fuelTankOneCapacity,
          fuelTankTwoCapacity: values.fuelTankTwoCapacity,
          grossVehicleWeightRating: values.grossVehicleWeightRating,
          groundClearance: values.groundClearance,
          height: values.height,
          interiorVolume: values.interiorVolume,
          length: values.length,
          maxPayload: values.maxPayload,
          maxTorque: values.maxTorque,
          oilCapacity: values.oilCapacity,
          passengerVolume: values.passengerVolume,
          stroke: values.stroke,
          towingCapacity: values.towingCapacity,
          valves: values.valves,
          width: values.width,
        },
      })
        .then(async ({ data }) => {
          if (data?.vehicleSpecificationUpdate) {
            await refetch();
            storeVehicleForm({
              ...vehicleForm,
              specifications: null,
            });
            toast.success("Vehicle specifications updated successfully");
          } else {
            toast.error("Something went wrong, please try again later");
          }
          actions.setSubmitting(false);
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
      return;
    }

    createVehicleSpecification({
      variables: {
        vehicleId: parseInt(vehicleId),
        bedLength: values.bedLength,
        compression: values.compression,
        curbWeight: values.curbWeight,
        cylinders: values.cylinders,
        displacement: values.displacement,
        engineBore: values.engineBore,
        engineBrand: values.engineBrand,
        engineSummary: values.engineSummary,
        epaCity: values.epaCity,
        epaCombined: values.epaCombined,
        epaHighway: values.epaHighway,
        fuelQuality: values.fuelQuality,
        fuelTankOneCapacity: values.fuelTankOneCapacity,
        fuelTankTwoCapacity: values.fuelTankTwoCapacity,
        grossVehicleWeightRating: values.grossVehicleWeightRating,
        groundClearance: values.groundClearance,
        height: values.height,
        interiorVolume: values.interiorVolume,
        length: values.length,
        maxPayload: values.maxPayload,
        maxTorque: values.maxTorque,
        oilCapacity: values.oilCapacity,
        passengerVolume: values.passengerVolume,
        stroke: values.stroke,
        towingCapacity: values.towingCapacity,
        valves: values.valves,
        width: values.width,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleSpecificationCreate) {
          await refetch();
          toast.success("Vehicle specifications created successfully");
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={SpecificationsResource.name}
        heading={SpecificationsResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: SpecificationsResource.name,
            href: null,
          },
        ]}
      />
      <div className="py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_specs")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_specs")}
              </p>
            </div>
          </div>

          {loading ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                bedLength: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.bedLength : specifications?.bedLength ?? null, // prettier-ignore
                compression: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.compression : specifications?.compression ?? null, // prettier-ignore
                curbWeight:  (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.curbWeight : specifications?.curbWeight ?? null, // prettier-ignore
                cylinders: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.cylinders : specifications?.cylinders ?? null, // prettier-ignore
                displacement:  (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.displacement : specifications?.displacement ?? null, // prettier-ignore
                engineBore: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.engineBore : specifications?.engineBore ?? null, // prettier-ignore
                engineBrand: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.engineBrand : specifications?.engineBrand ?? "", // prettier-ignore
                engineSummary: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.engineSummary : specifications?.engineSummary ?? "", // prettier-ignore
                epaCity: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.epaCity : specifications?.epaCity ?? "", // prettier-ignore
                epaCombined: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.epaCombined : specifications?.epaCombined ?? "", // prettier-ignore
                epaHighway: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.epaHighway : specifications?.epaHighway ?? "", // prettier-ignore
                fuelQuality: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.fuelQuality : specifications?.fuelQuality ?? null, // prettier-ignore
                fuelTankOneCapacity: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.fuelTankOneCapacity : specifications?.fuelTankOneCapacity ?? null, // prettier-ignore
                fuelTankTwoCapacity: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.fuelTankTwoCapacity : specifications?.fuelTankTwoCapacity ?? null, // prettier-ignore
                grossVehicleWeightRating: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.grossVehicleWeightRating : specifications?.grossVehicleWeightRating ?? null, // prettier-ignore
                groundClearance: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.groundClearance : specifications?.groundClearance ?? null, // prettier-ignore
                height: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.height : specifications?.height ?? null, // prettier-ignore
                interiorVolume: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.interiorVolume : specifications?.interiorVolume ?? null, // prettier-ignore
                length: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.length : specifications?.length ?? null, // prettier-ignore
                maxPayload: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.maxPayload : specifications?.maxPayload ?? null, // prettier-ignore
                maxTorque: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.maxTorque : specifications?.maxTorque ?? null, // prettier-ignore
                oilCapacity: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.oilCapacity : specifications?.oilCapacity ?? null, // prettier-ignore
                passengerVolume: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.passengerVolume : specifications?.passengerVolume ?? null, // prettier-ignore
                stroke: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.stroke : specifications?.stroke ?? null, // prettier-ignore
                towingCapacity: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.towingCapacity : specifications?.towingCapacity ?? null, // prettier-ignore
                valves: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.valves : specifications?.valves ?? null, // prettier-ignore
                width: (vehicleForm.id === vehicleId && vehicleForm?.specifications) ? vehicleForm?.specifications?.width : specifications?.width ?? null, // prettier-ignore
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_save")}
              vehicleBasic={vehicleBasic}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Specifications;
export const SpecificationsResource: ResourceProps = {
  name: "Specifications",
  description: "Update an existing vehicle specifications",
  access: ["update-vehicles"],
  path: "specifications",
  icon: "bi bi-person",
};
