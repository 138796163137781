import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  GET_VEHICLE_BY_ID,
  Vehicle,
  VehicleBasic,
} from "../../../../graphql/fleets/vehicles";
import {
  EDIT_VEHICLEDETAILS,
  VehicleDetailsForm,
} from "../../../../graphql/fleets/vehicles/pages/details";
import Form from "../components/DetailsForm";

const Details = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery(GET_VEHICLE_BY_ID, {
    variables: {
      id: vehicleId,
    },
  });
  const vehicle = useMemo<Vehicle>(() => data?.fetchVehicle, [data]);

  const [updateVehicle] = useMutation(EDIT_VEHICLEDETAILS);

  const handleSubmit = (
    values: VehicleDetailsForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateVehicle({
      variables: {
        id: vehicleId,
        name: values.name,
        color: values.color,
        licensePlate: values.licensePlate,
        msrp: values.msrp,
        trim: values.trim,
        vehicleImageUrl: values.vehicleImageUrl,
        vinSn: values.vinSn,
        year: values.year,
        fleetManagerId: values.fleetManagerId,
        operatorId: values.operatorId,
        vehicleBodySubtypeId: values.vehicleBodySubtypeId,
        vehicleBodyTypeId: values.vehicleBodyTypeId,
        vehicleFuelTypeId: values.vehicleFuelTypeId,
        vehicleMakeId: values.vehicleMakeId,
        vehicleModelId: values.vehicleModelId,
        vehicleTypeId: values.vehicleTypeId,
        vehicleStatusId: values.vehicleStatusId,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleUpdate) {
          await refetch();
          toast.success("Vehicle details updated successfully");
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        toast.error(error.message);
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={DetailsResource.name}
        heading={DetailsResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: DetailsResource.name,
            href: null,
          },
        ]}
      />
      <div className="py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_details")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_details")}
              </p>
            </div>
          </div>

          {loading || !vehicle ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                name: vehicle.name || "",
                color: vehicle.color || "",
                licensePlate: vehicle.licensePlate || "",
                msrp: vehicle.msrp || null,
                trim: vehicle.trim || "",
                vehicleImageUrl: vehicle.vehicleImageUrl || "",
                vinSn: vehicle.vinSn || "",
                year: vehicle.year || null,
                fleetManagerId: vehicle.fleetManager
                  ? parseInt(vehicle.fleetManager.id)
                  : null,
                operatorId: vehicle.operator
                  ? parseInt(vehicle.operator.id)
                  : null,
                vehicleBodySubtypeId: vehicle.vehicleBodySubtype
                  ? parseInt(vehicle.vehicleBodySubtype.id)
                  : null,
                vehicleBodyTypeId: vehicle.vehicleBodyType
                  ? parseInt(vehicle.vehicleBodyType.id)
                  : null,
                vehicleFuelTypeId: vehicle.fuelType
                  ? parseInt(vehicle.fuelType.id)
                  : null,
                vehicleMakeId: vehicle.make ? parseInt(vehicle.make.id) : null,
                vehicleModelId: vehicle.model
                  ? parseInt(vehicle.model.id)
                  : null,
                vehicleTypeId: vehicle.vehicleType
                  ? parseInt(vehicle.vehicleType.id)
                  : null,
                vehicleStatusId: vehicle.status
                  ? parseInt(vehicle.status.id)
                  : null,
              }}
              onSubmit={handleSubmit}
              actionLabel={t("text_save")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Details;
export const DetailsResource: ResourceProps = {
  name: "Details",
  description: "Update an existing vehicle details",
  access: ["update-vehicles"],
  path: "details",
  icon: "bi bi-person",
};
