import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../../animations";
import {
  Button,
  Field,
  FieldImage,
  FieldPlaces,
} from "../../../../../components/form";
import { FleetVendorForm } from "../../../../../graphql/fleets/settings/vendors";
import { classNames } from "../../../../../utils";

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: FleetVendorForm;
  onSubmit: (values: FleetVendorForm, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const VendorSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .nullable(),
    webAddress: Yup.string().nullable(),
    address: Yup.string().nullable(),
    latitude: Yup.string().nullable(),
    longitude: Yup.string().nullable(),
    notes: Yup.string().nullable(),
    contactName: Yup.string().nullable(),
    contactPhoneNumber: Yup.string().nullable(),
    classification: Yup.array().of(Yup.number()).nullable(),
    profileImageUrl: Yup.string().nullable(),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: VendorSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  const classificationTypes = useMemo(
    () => [
      {
        label: "Fuel",
        value: 0,
        description:
          "Fuel classification allows vendors to be listed on Fuel Entries",
      },
      {
        label: "Vehicle",
        value: 1,
        description:
          "Vehicle classification allows vendors to be listed on Vehicle Entries",
      },
      {
        label: "Service",
        value: 2,
        description:
          "Service classification allows vendors to be listed on Service Entries",
      },
    ],
    []
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={touched.name}
            errors={errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_email")}
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            touched={touched.email}
            errors={errors.email}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_phone")}
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            touched={touched.phoneNumber}
            errors={errors.phoneNumber}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_website")}
            name="webAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.webAddress}
            touched={touched.webAddress}
            errors={errors.webAddress}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <FieldPlaces
            title={t("text_address")}
            name="address"
            onUpdate={(value, address) => {
              formik.setFieldValue("address", value);
              const { latitude = "", longitude = "" } = address;
              formik.setFieldValue("latitude", latitude.toString());
              formik.setFieldValue("longitude", longitude.toString());
            }}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            touched={touched.address}
            errors={errors.address}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_contact_name")}
            name="contactName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactName}
            touched={touched.contactName}
            errors={errors.contactName}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_contact_phone_number")}
            name="contactPhoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPhoneNumber}
            touched={touched.contactPhoneNumber}
            errors={errors.contactPhoneNumber}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <FieldImage
            title={t("text_profile_image")}
            onChange={(value) => {
              formik.setFieldValue("profileImageUrl", value);
            }}
            value={formik.values.profileImageUrl}
            touched={touched.profileImageUrl}
            errors={errors.profileImageUrl}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <Field
            title={t("text_notes")}
            name="notes"
            type="textarea"
            className="h-24"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.notes}
            touched={touched.notes}
            errors={errors.notes}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <div className="mb-1 block text-sm font-medium text-gray-900">
            {t("text_classification")}
          </div>
          <div className="space-y-4">
            {classificationTypes.map((type) => (
              <Field
                key={`classification-${type.value}`}
                title={type.label}
                caption={type.description}
                id={`classification-${type.value}`}
                name="classification"
                type="checkbox"
                onChange={(e) => {
                  const isChecked = (e.target as HTMLInputElement).checked;
                  if (isChecked) {
                    formik.setFieldValue("classification", [
                      ...formik.values.classification,
                      type.value,
                    ]);
                  } else {
                    formik.setFieldValue(
                      "classification",
                      formik.values.classification.filter(
                        (item) => item !== type.value
                      )
                    );
                  }
                }}
                checked={formik.values.classification.includes(type.value)}
                value={type.value}
              />
            ))}
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              {t("text_status")}
            </legend>
            <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
              <Switch
                checked={formik.values.status}
                onChange={() => {
                  formik.setFieldValue("status", !formik.values.status);
                }}
                id="status"
                className={classNames(
                  formik.values.status ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formik.values.status ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor="status"
                className="mb-0 ml-2 block text-sm font-normal text-gray-700"
              >
                {formik.values.status ? t("text_active") : t("text_inactive")}
              </Switch.Label>
            </Switch.Group>
          </fieldset>
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/purchases/vendors" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
