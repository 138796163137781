import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";

import { Layouts, Widget } from "../../graphql/dashboard";
import { Layout as WidgetLayout } from "../../widgets";
import { GridItem } from "./GridItem";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export function GridLayout({
  widgets: initialWidgets,
  layouts,
  updateLayouts,
  updateWidgets,
  setIsModified,
}: {
  widgets: Widget[];
  layouts: Layouts | undefined;
  updateLayouts: (layouts: Layouts) => void;
  updateWidgets: (widgets: Widget[]) => void;
  setIsModified: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const widgets = useMemo<Widget[]>(() => {
    return initialWidgets;
  }, [initialWidgets]);
  const isMounted = useRef(false);
  const [breakpoint, setBreakpoint] = useState("md");

  useEffect(() => {
    if (widgets.length === 0) return;
    isMounted.current = true;
  }, [widgets.length]);

  function handleLayoutChange(layout: Layout[]) {
    if (!isMounted.current) return;
    const updatedLayouts = { ...layouts, [breakpoint]: layout };
    updateLayouts(updatedLayouts);
  }

  const handleWidgetRemove = useCallback(
    (handle: string) => {
      const updatedWidgets = widgets.map((widget) => ({
        ...widget,
        status: widget.handle === handle ? false : widget.status,
      }));
      updateWidgets(updatedWidgets);
    },
    [widgets, updateWidgets]
  );

  return (
    <ResponsiveReactGridLayout
      layouts={layouts}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      measureBeforeMount={false}
      useCSSTransforms={isMounted.current}
      isResizable={true}
      isDraggable={true}
      rowHeight={10}
      margin={[20, 20]}
      containerPadding={[0, 0]}
      preventCollision={false}
      onLayoutChange={handleLayoutChange}
      onBreakpointChange={(breakpoint) => setBreakpoint(breakpoint)}
      draggableHandle=".widget-drag-handle"
      onDragStop={() => {
        setIsModified(true);
      }}
      onResizeStop={() => {
        setIsModified(true);
      }}
    >
      {widgets
        .filter((w) => w.status)
        .map((widget) => (
          <div key={widget.handle}>
            <WidgetLayout
              title={widget.name}
              handle={widget.handle}
              count={0}
              navigation={[
                {
                  name: t("text_hide"),
                  icon: "bi bi-x-circle",
                  onClick: () => {
                    handleWidgetRemove(widget.handle);
                  },
                },
              ]}
            >
              <GridItem
                handle={widget.handle}
                item={widget}
                widgets={widgets}
                updateWidgets={updateWidgets}
                setIsModified={setIsModified}
              />
            </WidgetLayout>
          </div>
        ))}
    </ResponsiveReactGridLayout>
  );
}
