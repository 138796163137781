import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query FetchBinLocations($status: Boolean) {
    fetchBinLocations(status: $status) {
      id
      name
      branch
      address
      parent {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export const GET_LOCATION_BY_ID = gql`
  query FetchBinLocation($id: ID!) {
    fetchBinLocation(id: $id) {
      id
      name
      parent {
        id
        name
      }
      type {
        id
        name
      }
      status
      branch
      description
      address
      returnAddress
      imageUrl
      latitude
      longitude
      timezone
    }
  }
`;

export const REMOVE_LOCATION = gql`
  mutation BinLocationDelete($id: ID!) {
    binLocationDelete(input: { id: $id }) {
      location {
        id
        name
      }
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation binLocationCreate(
    $name: String!
    $parentId: Int
    $binLocationTypesId: Int
    $status: Boolean!
    $branch: String
    $description: String
    $address: String
    $returnAddress: String
    $imageUrl: String
    $latitude: String
    $longitude: String
    $timezone: String
  ) {
    binLocationCreate(
      input: {
        params: {
          name: $name
          parentId: $parentId
          binLocationTypesId: $binLocationTypesId
          status: $status
          branch: $branch
          description: $description
          address: $address
          returnAddress: $returnAddress
          imageUrl: $imageUrl
          latitude: $latitude
          longitude: $longitude
          timezone: $timezone
        }
      }
    ) {
      location {
        id
        name
        description
        parent {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const EDIT_LOCATION = gql`
  mutation BinLocationUpdate(
    $id: ID!
    $name: String!
    $parentId: Int
    $binLocationTypesId: Int
    $status: Boolean!
    $branch: String
    $description: String
    $address: String
    $returnAddress: String
    $imageUrl: String
    $latitude: String
    $longitude: String
    $timezone: String
  ) {
    binLocationUpdate(
      input: {
        id: $id
        params: {
          name: $name
          parentId: $parentId
          binLocationTypesId: $binLocationTypesId
          status: $status
          branch: $branch
          description: $description
          address: $address
          returnAddress: $returnAddress
          imageUrl: $imageUrl
          latitude: $latitude
          longitude: $longitude
          timezone: $timezone
        }
      }
    ) {
      location {
        id
        name
        description
        parent {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export type Location = {
  id: string;
  name: string;
  description: string;
  branch: string;
  address: string;
  parent: Location | null;
  createdAt: string;
  status: boolean;
};
