import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

import {
  GET_VEHICLES_BASIC,
  Vehicle,
  VehicleBasic,
} from "../../graphql/fleets/vehicles";
import { selectStyles, SelectWrapper } from ".";

type SelectProps = React.ComponentProps<typeof Select>;
type SelectOptionalProps = Omit<SelectProps, "onChange" | "value">;
type FieldVehicleProps = SelectOptionalProps & {
  value: number | null;
  onChange: (newValue: VehicleBasic | null) => void;
};

export function FieldVehicle({
  value,
  onChange,
  className,
  ...props
}: FieldVehicleProps) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const [vehicles, setVehicles] = useState<VehicleBasic[]>([]);

  const { data, loading, refetch } = useQuery<{
    fetchVehicles: Vehicle[];
  }>(GET_VEHICLES_BASIC);

  const options: MultiValue<OptionProps> = useMemo(() => {
    const updatedVehicles = data?.fetchVehicles?.length
      ? data?.fetchVehicles?.map((vehicle: Vehicle) => ({
          id: parseInt(vehicle.id),
          name: vehicle.name,
          vehicleImageUrl: vehicle.vehicleImageUrl,
          make: vehicle.make,
          model: vehicle.model,
          year: vehicle.year,
          operator: vehicle.operator,
          status: vehicle.status,
          fuelUnit: vehicle.settings?.length ? vehicle.settings[0].fuelUnit : 0,
          measurementUnit: vehicle?.settings?.length
            ? vehicle.settings[0].measurementUnit
            : 0,
          primaryMeter: vehicle?.settings?.length
            ? vehicle.settings[0]?.primaryMeter
            : 0,
        }))
      : [];
    setVehicles(updatedVehicles);
    return updatedVehicles.map((p) => {
      return {
        label: p.name,
        value: p.id.toString(),
      };
    });
  }, [data?.fetchVehicles]);

  useEffect(() => {
    if (!value) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = useCallback(
    (newValue: SingleValue<OptionProps> | unknown) => {
      onChange(
        newValue
          ? vehicles.find(
              (vehicle) =>
                vehicle.id === parseInt((newValue as OptionProps).value)
            ) ?? null
          : null
      );
    },
    [onChange, vehicles]
  );

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
        {...props}
      />
    </SelectWrapper>
  );
}
