import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  EDIT_LOCATION,
  GET_LOCATION_BY_ID,
  GET_LOCATIONS,
} from "../../../graphql/inventory/locations";
import Form from "./components/Form";

const LocationUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { locationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  type TypeProps = {
    id: string;
    name: string;
  };
  type Location = {
    id: string;
    name: string;
    parent: Location;
    type: TypeProps;
    status: boolean;
    branch: string;
    description: string;
    address: string;
    returnAddress: string;
    imageUrl: string;
    latitude: string;
    longitude: string;
    timezone: string;
  };

  const { data, loading, error } = useQuery(GET_LOCATION_BY_ID, {
    variables: {
      id: locationId,
    },
  });
  const location = useMemo<Location>(() => data?.fetchBinLocation, [data]);

  const [updateLocation] = useMutation(EDIT_LOCATION, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateLocation({
      variables: {
        id: locationId,
        name: values.name,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        binLocationTypesId: values.type ? parseInt(values.type.value) : null,
        status: values.status,
        branch: values.branch,
        description: values.description,
        address: values.address,
        returnAddress: values.returnAddress,
        imageUrl: values.imageUrl,
        latitude: values.latitude,
        longitude: values.longitude,
        timezone: values.timezone ? values.timezone.value : "",
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.binLocationUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Location updated successfully",
          });
          return navigate(`/inventory/locations`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Location update failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_update_location")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_update_location")}
          </p>
        </div>
        <div className="mt-4 flex sm:ml-16 sm:mt-0">
          <Link to="/inventory/locations" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_update")}</Button>
        </div>
      </div>
    );
  }

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={LocationUpdateResource.name}
        heading={LocationUpdateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Locations",
            href: "/inventory/locations",
          },
          {
            name: LocationUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-6xl px-5 py-6 sm:py-8">
        {loading || !location ? (
          <Waiting />
        ) : (
          <Form
            initialValues={{
              name: location.name ?? "",
              parent: location.parent
                ? {
                    label: location.parent.name,
                    value: location.parent.id,
                  }
                : null,
              type: location.type
                ? {
                    label: location.type.name,
                    value: location.type.id,
                  }
                : null,
              status: location.status ? true : false,
              branch: location.branch ?? "",
              description: location.description ?? "",
              address: location.address ?? "",
              returnAddress: location.returnAddress ?? "",
              imageUrl: location.imageUrl ?? "",
              latitude: location.latitude ?? "",
              longitude: location.longitude ?? "",
              timezone: location.timezone
                ? {
                    label: location.timezone,
                    value: location.timezone,
                  }
                : null,
            }}
            onSubmit={handleSubmit}
            header={<FormHeader />}
          />
        )}
      </div>
    </>
  );
};

export default LocationUpdate;
export const LocationUpdateResource: ResourceProps = {
  name: "Edit Location",
  description: "Update an existing location",
  access: ["update-binlocations"],
  path: "locations/:locationId",
};
