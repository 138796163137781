import { gql } from "@apollo/client";

import { FleetVendor } from "../../../settings/vendors";
import { Vehicle } from "../..";

export const FUELENTRY_CALCULATION_FRAGMENT = gql`
  fragment FuelentrycalculationFragment on Fuelentrycalculation {
    costPerUnit
    fuelEconomy
    total
    usage
  }
`;

export const FUELENTRY_FRAGMENT = gql`
  ${FUELENTRY_CALCULATION_FRAGMENT}
  fragment VehicleFuelEntryFragment on VehicleFuelEntry {
    id
    comments
    files {
      createdAt
      fileType
      fileUrl
      id
      updatedAt
    }
    fuelStatistics {
      ...FuelentrycalculationFragment
    }
    fuelAmount
    fuelEntryDate
    fuelFlags
    fuelType {
      createdAt
      id
      name
      updatedAt
    }
    odometer
    previousMeterEntry
    pricePerUnit
    referenceNo
    vehicle {
      id
      name
    }
    vendor {
      id
      name
    }
    createdAt
    updatedAt
  }
`;

export const GET_FUELENTRIES = gql`
  ${FUELENTRY_FRAGMENT}
  query FetchFuelEntries {
    fetchFuelEntries {
      ...VehicleFuelEntryFragment
    }
  }
`;

export const GET_FUELENTRY_BY_ID = gql`
  ${FUELENTRY_FRAGMENT}
  query FetchFuelEntry($id: ID!) {
    fetchFuelEntry(id: $id) {
      ...VehicleFuelEntryFragment
    }
  }
`;

export const GET_FUELENTRIES_BY_VEHICLE_ID = gql`
  ${FUELENTRY_FRAGMENT}
  query FetchFuelEntriesByVehicle($vehicleId: ID!) {
    fetchFuelEntriesByVehicle(vehicleId: $vehicleId) {
      ...VehicleFuelEntryFragment
    }
  }
`;

export const GET_FUELENTRY_BY_VEHICLE_ID = gql`
  ${FUELENTRY_FRAGMENT}
  query FetchFuelEntriesByVehicle($vehicleId: ID!) {
    fetchFuelEntriesByVehicle(vehicleId: $vehicleId) {
      ...VehicleFuelEntryFragment
    }
  }
`;

export const ADD_FUELENTRY = gql`
  ${FUELENTRY_FRAGMENT}
  mutation VehicleFuelEntryCreate(
    $vehicleId: Int!
    $vehicleFuelTypeId: Int!
    $referenceNo: String
    $pricePerUnit: Float
    $odometer: Int
    $fuelFlags: Int
    $fuelEntryDate: ISO8601DateTime!
    $fuelAmount: Float
    $fleetVendorId: Int!
    $files: [VehiclefuelentrydirectoryInput!]
    $comments: String
  ) {
    vehicleFuelEntryCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          vehicleFuelTypeId: $vehicleFuelTypeId
          referenceNo: $referenceNo
          pricePerUnit: $pricePerUnit
          odometer: $odometer
          fuelFlags: $fuelFlags
          fuelEntryDate: $fuelEntryDate
          fuelAmount: $fuelAmount
          fleetVendorId: $fleetVendorId
          files: $files
          comments: $comments
        }
      }
    ) {
      vehicleFuelEntry {
        ...VehicleFuelEntryFragment
      }
    }
  }
`;

export const EDIT_FUELENTRY = gql`
  ${FUELENTRY_FRAGMENT}
  mutation VehicleFuelEntryUpdate(
    $id: ID!
    $vehicleId: Int!
    $vehicleFuelTypeId: Int!
    $referenceNo: String
    $pricePerUnit: Float
    $odometer: Int
    $fuelFlags: Int
    $fuelEntryDate: ISO8601DateTime!
    $fuelAmount: Float
    $fleetVendorId: Int!
    $files: [VehiclefuelentrydirectoryInput!]
    $comments: String
  ) {
    vehicleFuelEntryUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          vehicleFuelTypeId: $vehicleFuelTypeId
          referenceNo: $referenceNo
          pricePerUnit: $pricePerUnit
          odometer: $odometer
          fuelFlags: $fuelFlags
          fuelEntryDate: $fuelEntryDate
          fuelAmount: $fuelAmount
          fleetVendorId: $fleetVendorId
          files: $files
          comments: $comments
        }
      }
    ) {
      vehicleFuelEntry {
        ...VehicleFuelEntryFragment
      }
    }
  }
`;

export const REMOVE_FUELENTRY = gql`
  ${FUELENTRY_FRAGMENT}
  mutation VehicleFuelEntryDelete($id: ID!) {
    vehicleFuelEntryDelete(input: { id: $id }) {
      vehicleFuelEntry {
        ...VehicleFuelEntryFragment
      }
    }
  }
`;

export enum FuelFlags {
  PERSONAL,
  PARTIAL_FILL,
  MISSED_ENTRY,
}
export enum FuelFileType {
  IMAGE,
  DOCUMENT,
}
export type FuelFile = {
  fileUrl: string;
  fileType: FuelFileType;
};

export type VehicleFuelEntry = {
  id: string;
  comments: string;
  files: {
    id: string;
    fileType: FuelFileType;
    fileUrl: string;
    createdAt: string;
    updatedAt: string;
  }[];
  fuelStatistics: {
    costPerUnit: number | null;
    fuelEconomy: number | null;
    total: number | null;
    usage: number | null;
  };
  fuelAmount: number | null;
  fuelEntryDate: string | null;
  fuelFlags: FuelFlags | null;
  fuelType: {
    id: string;
    name: string;
    updatedAt: string;
    createdAt: string;
  };
  odometer: number | null;
  previousMeterEntry: number | null;
  pricePerUnit: number | null;
  referenceNo: string;
  vehicle: Vehicle;
  vendor: FleetVendor;
  createdAt: string;
  updatedAt: string;
};

export type VehicleFuelEntryForm = Omit<
  VehicleFuelEntry,
  | "id"
  | "vehicle"
  | "vendor"
  | "files"
  | "fuelType"
  | "createdAt"
  | "updatedAt"
  | "previousMeterEntry"
  | "fuelStatistics"
> & {
  id?: string;
  vehicleId: number | null;
  files: FuelFile[];
  vehicleFuelTypeId: number | null;
  fleetVendorId: number | null;
};

export const renderFuelFlags = (status: FuelFlags | null) => {
  switch (status) {
    case FuelFlags.PERSONAL:
      return "Personal";
    case FuelFlags.PARTIAL_FILL:
      return "Partial Fill";
    case FuelFlags.MISSED_ENTRY:
      return "Missed Entry";
    default:
      return null;
  }
};

export const renderFuelFileType = (status: FuelFileType | null) => {
  switch (status) {
    case FuelFileType.IMAGE:
      return "Image";
    case FuelFileType.DOCUMENT:
      return "Document";
    default:
      return null;
  }
};
