import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, Field, FieldVehicle } from "../../../../components/form";
import { VehicleIssueStatusForm } from "../../../../graphql/fleets/vehicles/pages/issues";
import { classNames } from "../../../../utils";

export default function Form({
  heading,
  caption,
  button,
  initialValues,
  onSubmit,
  onCancel,
}: {
  heading: string;
  caption: string;
  button: string;
  initialValues: VehicleIssueStatusForm;
  onSubmit: (values: VehicleIssueStatusForm, actions: any) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const ValidationSchema = Yup.object().shape({
    vehicleId: Yup.number().typeError("Must be a number").required("Required"),
    statusNotes: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 py-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">{caption}</p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label className="block text-sm font-medium text-gray-900">
                      {t("text_vehicle")}
                    </label>
                    <FieldVehicle
                      value={formik.values.vehicleId}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "vehicleId",
                          value ? value.id : null
                        );
                      }}
                      className={classNames(
                        "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                        formik.touched.vehicleId && formik.errors.vehicleId
                          ? "border-red-600 text-red-900"
                          : ""
                      )}
                      isDisabled
                    />
                    {formik.touched.vehicleId && formik.errors.vehicleId ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="vehicleId-errors"
                      >
                        {formik.errors.vehicleId}
                      </p>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Field
                      title={t("text_notes")}
                      name="statusNotes"
                      type="textarea"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.statusNotes}
                      touched={formik.touched.statusNotes}
                      errors={formik.errors.statusNotes}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {t("text_cancel")}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <Fragment>
                <Spinner />
                {t("text_processing")}
              </Fragment>
            ) : (
              button
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
