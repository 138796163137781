import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Switch } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { PlusIcon, PlusSmallIcon } from "@heroicons/react/24/solid";
import { FormikProps, getIn, useFormik } from "formik";
import { customAlphabet, nanoid } from "nanoid";
import {
  Fragment,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  components,
  MultiValue,
  MultiValueGenericProps,
  MultiValueProps,
  SingleValue,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import placeholder from "../../../../assets/placeholder.svg";
import {
  Button,
  Field,
  FieldImage,
  FieldPricing,
  FieldTableProducts,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  checkBarCode,
  checkStockCode,
  GET_PRODUCT_BY_ID,
  PreviewProduct,
  type Product,
  VariantEx,
  type VariantItem,
  type VariantOption,
  type VariantProps,
} from "../../../../graphql/inventory/products";
import {
  arrayMove,
  classNames,
  toCapitalize,
  toSlug,
  useFormikErrors,
} from "../../../../utils";
import {
  FieldBinLocation,
  FieldCategory,
  FieldDepartment,
  FieldStatus,
  FieldVendor,
} from "./Fields";
import VariantCreate from "./VariantCreate";
import VariantUpdate from "./VariantUpdate";

const nanoidCustom = customAlphabet("1234567890", 10);

type InitialValues = Omit<
  Product,
  | "createdAt"
  | "updatedAt"
  | "binLocation"
  | "categories"
  | "department"
  | "vendor"
  | "status"
> & {
  binLocation: SingleValue<OptionProps>;
  categories: MultiValue<OptionProps>;
  department: SingleValue<OptionProps>;
  vendor: SingleValue<OptionProps>;
  status: SingleValue<OptionProps>;
};

export default function FormUpdate({
  initialValues,
  initialVariantOptions,
  relatedProducts: initialRelatedProducts,
  initialStockCodes,
  initialBarCodes,
  onSubmit,
}: {
  initialValues: InitialValues;
  initialVariantOptions: VariantOption[];
  relatedProducts: PreviewProduct[];
  initialStockCodes: string[];
  initialBarCodes: string[];
  onSubmit: (values: any, actions: any) => void;
}) {
  const { t } = useTranslation();
  let { productId } = useParams();

  const [relatedProducts, setRelatedProducts] = useState<PreviewProduct[]>([]);

  const [variantOptions, setVariantOptions] = useState<VariantOption[]>([]);
  const [variantTypes, setVariantTypes] = useState<string[]>([]);
  const [checkingStockCode, setCheckingStockCode] = useState<boolean>(false);
  const [checkingBarCode, setCheckingBarCode] = useState<boolean>(false);

  const ProductSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    handle: Yup.string()
      .min(2, "Too Short!")
      .max(120, "Too Long!")
      .required("Required"),
    description: Yup.string(),
    basePrice: Yup.number()
      .min(0)
      .when("hasVariants", {
        is: false, // alternatively: (val) => val == false
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    comparePrice: Yup.number()
      .min(0)
      .when("hasVariants", {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    cost: Yup.number()
      .min(0)
      .when("hasVariants", {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    marketCost: Yup.number()
      .min(0)
      .when("hasVariants", {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    minimumQuantity: Yup.number()
      .min(0)
      .when("hasVariants", {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    inventory: Yup.number()
      .label("Stock")
      .when("hasVariants", {
        is: false,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    binLocation: Yup.object().nullable().required("Required"),
    categories: Yup.array().of(Yup.object()).required("Required"),
    department: Yup.object().nullable().required("Required"),
    featureImageUrl: Yup.string().nullable(),
    galleryImageUrls: Yup.array().of(Yup.string()),
    options: Yup.array()
      .of(Yup.string())
      .label("Variant Options")
      .when("hasVariants", {
        is: true,
        then: (schema) => schema.min(1).required(),
        otherwise: (schema) => schema.nullable(),
      }),
    hasVariants: Yup.boolean(),
    returnableItem: Yup.boolean(),
    stockCode: Yup.string()
      .matches(
        /^[a-zA-Z0-9-_]+$/,
        "Stock Code cannot contain white space and special characters"
      )
      .label("Stock Code")
      .when("hasVariants", {
        is: false,
        then: (schema) =>
          schema
            .test(
              "stockCodeValidate",
              "Stock code already exists",
              async (value) => {
                if (!value) return true;
                if (initialStockCodes.includes(value)) return true;
                if (checkingStockCode) return false;
                setCheckingStockCode(true);
                try {
                  const response = await checkStockCode(value);
                  return response?.data?.checkStockCode === false;
                } catch (error: any) {
                  return true;
                } finally {
                  setCheckingStockCode(false);
                }
              }
            )
            .required(),
        otherwise: (schema) => schema.nullable(),
      }),
    barCode: Yup.string()
      .matches(
        /^[a-zA-Z0-9-_]+$/,
        "Barcode cannot contain white space and special characters"
      )
      .test("barCode", "Barcode already exists", async (value) => {
        if (!value) return true;
        if (initialBarCodes.includes(value)) return true;
        if (checkingBarCode) return true;
        setCheckingBarCode(true);

        try {
          const response = await checkBarCode(value);
          return response?.data?.checkBarCode === false;
        } catch (error: any) {
          return false;
        } finally {
          setCheckingBarCode(false);
        }
      })
      .nullable(),
    plu: Yup.number().nullable(),
    vendor: Yup.object().nullable(),
    status: Yup.object().nullable().required("Required"),
    variants: Yup.array()
      .label("Variants")
      .when("hasVariants", {
        is: true,
        then: (schema) =>
          schema
            .of(
              Yup.object().shape({
                stockCode: Yup.string()
                  .label("Stock Code")
                  .required("Required"),
                plu: Yup.number().nullable(),
              })
            )
            .min(1)
            .required(),
        otherwise: (schema) => schema.nullable(),
      }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: ProductSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  const reloadVariants = useCallback(
    (vOptions: VariantOption[], vItems: VariantItem[]) => {
      if (vOptions.length === 0) {
        setVariantTypes([]);
        formik.setFieldValue("hasVariants", false);
        formik.setFieldValue("options", []);
        formik.setFieldValue("variants", []);
        return;
      }

      let attributes: {
        [key: string]: string[];
      } = {};
      vOptions.forEach((option) => {
        attributes[option.type] = option.value;
      });

      let attrs = [];

      for (const [attr, values] of Object.entries(attributes)) {
        if (attr === "" || !values.length) continue;
        attrs.push(values.map((v) => ({ [attr]: v })));
      }

      if (!attrs.length) return;

      attrs = attrs.reduce((a, b) =>
        a.flatMap((d) => b.map((e) => ({ ...d, ...e })))
      );

      const newVariantItems = attrs.map((attr, index) => {
        const optionValues: any = Object.keys(attr).map((key) => ({
          option: key,
          optionValue: attr[key],
        }));
        const variantItem = vItems.find((v) =>
          v.optionValues?.every((o) => {
            return optionValues.find(
              (v: { option: string; optionValue: string }) =>
                v.option === o.option && o.optionValue.includes(v.optionValue)
            );
          })
        );

        if (variantItem) {
          return {
            id: variantItem.id,
            optionValues,
            stockCode: variantItem.stockCode,
            barCode: variantItem.barCode,
            plu: variantItem.plu,
            basePrice: variantItem.basePrice,
            comparePrice: variantItem.comparePrice,
            cost: variantItem.cost,
            marketCost: variantItem.marketCost,
            minimumQuantity: variantItem.minimumQuantity,
            inventory: variantItem.inventory,
            variantImageUrl: variantItem.variantImageUrl,
            type: variantItem.type,
          };
        }

        return {
          id: nanoidCustom(),
          stockCode: "",
          barCode: "",
          plu: "",
          basePrice: formik.values.basePrice,
          comparePrice: formik.values.comparePrice,
          cost: 0,
          marketCost: 0,
          minimumQuantity: 0,
          inventory: formik.values.inventory,
          variantImageUrl: "",
          optionValues: Object.entries(attr).map(([option, optionValue]) => ({
            option,
            optionValue,
          })),
          type: VariantEx.NEW,
        };
      });

      formik.setFieldValue("variants", newVariantItems);
    },
    [formik.values]
  );

  useEffect(() => {
    if (initialRelatedProducts.length > 0) {
      setRelatedProducts(initialRelatedProducts);
    }
  }, [initialRelatedProducts]);

  useEffect(() => {
    if (initialVariantOptions.length > 0) {
      setVariantOptions(initialVariantOptions);
      const updatedVariantTypes = initialVariantOptions.map((v) => v.type);
      setVariantTypes(updatedVariantTypes);
    }
  }, [initialVariantOptions]);

  const handleVariantOptionsUpdate = useCallback(
    (options: VariantOption[]) => {
      setVariantOptions(options);
      const updatedOptions = options.map((v) => v.type);
      setVariantTypes(updatedOptions);
      formik.setFieldValue("options", updatedOptions);
      reloadVariants(options, formik.values.variants);
    },
    [reloadVariants, formik.values.variants]
  );

  const handleVariantUpdate = useCallback(
    (variants: VariantItem[]) => {
      if (variants?.length) {
        formik.setFieldValue("hasVariants", true);
        formik.setFieldValue("variants", variants);
      } else {
        if (formik.values.variants.length > 0) {
          const prevFirstVariant = formik.values.variants[0];
          formik.setFieldValue("stockCode", prevFirstVariant?.stockCode || "");
          formik.setFieldValue("barCode", prevFirstVariant?.stockCode || null);
          formik.setFieldValue("plu", prevFirstVariant?.plu || null);
          formik.setFieldValue("basePrice", prevFirstVariant?.basePrice || 0);
          formik.setFieldValue(
            "comparePrice",
            prevFirstVariant?.comparePrice || 0
          );
          formik.setFieldValue("cost", prevFirstVariant?.cost || 0);
          formik.setFieldValue("marketCost", prevFirstVariant?.marketCost || 0);
          formik.setFieldValue(
            "minimumQuantity",
            prevFirstVariant?.minimumQuantity || 0
          );
          formik.setFieldValue("inventory", prevFirstVariant?.inventory || 0);
        }
        formik.setFieldValue("hasVariants", false);
        formik.setFieldValue("variants", variants);
        formik.setFieldValue("options", []);
        setVariantOptions([]);
        setVariantTypes([]);
      }
    },
    [formik.values.variants]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_update_product")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_update_product")}
          </p>
        </div>
        <div className="mt-4 flex sm:ml-16 sm:mt-0">
          <Link to="/inventory/products" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_update")}</Button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
        <div className="space-y-4 md:col-span-8">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-12">
                <Field
                  title={t("text_name")}
                  name="name"
                  onChange={(e: { target: { value: string } }) => {
                    formik.setFieldValue("name", e.target.value);
                    formik.setFieldValue("handle", toSlug(e.target.value));
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                />
              </div>
              <div className="col-span-12 sm:col-span-12">
                <Field
                  title={t("text_description")}
                  name="description"
                  type="textarea"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  touched={formik.touched.description}
                  errors={formik.errors.description}
                />
              </div>

              <div className="col-span-12 sm:col-span-12">
                <div className="text-sm font-medium text-gray-900">
                  {t("text_returnable_item")}
                </div>
                <Switch.Group
                  as="div"
                  className="mt-1.5 inline-flex items-center"
                >
                  <Switch
                    checked={formik.values.returnableItem}
                    onChange={() => {
                      formik.setFieldValue(
                        "returnableItem",
                        !formik.values.returnableItem
                      );
                    }}
                    id="returnableItem"
                    className={classNames(
                      formik.values.returnableItem
                        ? "bg-primary-600"
                        : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        formik.values.returnableItem
                          ? "translate-x-5"
                          : "translate-x-0",
                        "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                  <Switch.Label
                    passive
                    htmlFor="returnableItem"
                    className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                  >
                    {formik.values.returnableItem
                      ? t("text_yes")
                      : t("text_no")}
                  </Switch.Label>
                </Switch.Group>
              </div>
              <div className="col-span-12 sm:col-span-12">
                <Field
                  title={t("text_handle")}
                  name="handle"
                  onChange={(e: { target: { value: string } }) => {
                    formik.setFieldValue("handle", toSlug(e.target.value));
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.handle}
                  touched={formik.touched.handle}
                  errors={formik.errors.handle}
                  editMode={true}
                />
              </div>
              <div className="col-span-12 sm:col-span-12">
                <FieldImage
                  title={t("text_featured_image")}
                  onChange={(value) => {
                    formik.setFieldValue("featureImageUrl", value);
                  }}
                  value={formik.values.featureImageUrl}
                  touched={formik.touched.featureImageUrl}
                  errors={formik.errors.featureImageUrl}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4 md:col-span-4">
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <fieldset>
              <label className="block text-sm font-medium text-gray-900">
                {t("text_status")}
              </label>
              <FieldStatus
                value={formik.values.status}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("status", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.status && formik.errors.status
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.status && formik.errors.status ? (
                <p className="mt-2 text-sm text-red-600" id="roles-errors">
                  {formik.errors.status.toString()}
                </p>
              ) : null}
            </fieldset>
          </div>

          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
            <fieldset>
              <label className="block text-sm font-medium text-gray-900">
                {t("text_category")}
              </label>
              <FieldCategory
                value={formik.values.categories}
                // fixedValue={formik.values.autoCategories?.flatMap(
                //   (ac) => ac.id
                // )}
                onChange={(value: MultiValue<OptionProps>) => {
                  formik.setFieldValue("categories", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.categories && formik.errors.categories
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {/* {formik.values.autoCategories?.length ? (
                <p className="mt-2 space-x-4 text-sm text-gray-500">
                  Default:{" "}
                  {formik.values.autoCategories
                    ?.flatMap((ac) => ac.name)
                    .map((name) => (
                      <span className="rounded-md border border-gray-300 bg-white py-0.5 px-1 text-xs">
                        {name}
                      </span>
                    ))}
                </p>
              ) : null} */}
              {formik.touched.categories && formik.errors.categories ? (
                <p className="mt-2 text-sm text-red-600" id="categories-errors">
                  {formik.errors.categories.toString()}
                </p>
              ) : null}
            </fieldset>

            <fieldset className="mt-4">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_department")}
              </label>
              <FieldDepartment
                value={formik.values.department}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("department", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.department && formik.errors.department
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.department && formik.errors.department ? (
                <p className="mt-2 text-sm text-red-600" id="department-errors">
                  {formik.errors.department.toString()}
                </p>
              ) : null}
            </fieldset>

            <fieldset className="mt-4">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_vendor")}
              </label>
              <FieldVendor
                value={formik.values.vendor}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("vendor", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.vendor && formik.errors.vendor
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.vendor && formik.errors.vendor ? (
                <p className="mt-2 text-sm text-red-600" id="vendor-errors">
                  {formik.errors.vendor.toString()}
                </p>
              ) : null}
            </fieldset>
            <fieldset className="mt-4">
              <label className="block text-sm font-medium text-gray-900">
                {t("text_bin_location")}
              </label>
              <FieldBinLocation
                value={formik.values.binLocation}
                onChange={(value: SingleValue<OptionProps>) => {
                  formik.setFieldValue("binLocation", value);
                }}
                className={classNames(
                  "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                  formik.touched.binLocation && formik.errors.binLocation
                    ? "border-red-600 text-red-900"
                    : ""
                )}
              />
              {formik.touched.binLocation && formik.errors.binLocation ? (
                <p className="mt-2 text-sm text-red-600" id="department-errors">
                  {formik.errors.binLocation.toString()}
                </p>
              ) : null}
            </fieldset>
          </div>
        </div>

        <div className="grid items-start gap-6 md:col-span-12 lg:grid-cols-12">
          {!formik.values.hasVariants ? (
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6 lg:col-span-8">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_base_price")}
                    name="basePrice"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.basePrice}
                    touched={formik.touched.basePrice}
                    errors={formik.errors.basePrice}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_compare_price")}
                    name="comparePrice"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comparePrice}
                    touched={formik.touched.comparePrice}
                    errors={formik.errors.comparePrice}
                  />
                  <p className="mt-2 text-xs text-gray-500">
                    {t("text_compare_price_warning")}
                  </p>
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_cost")}
                    name="cost"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cost}
                    touched={formik.touched.cost}
                    errors={formik.errors.cost}
                  />
                  {formik.touched.cost &&
                  formik.values.cost !== initialValues.cost ? (
                    <p className="mt-2 text-xs text-blue-700">
                      Please update the corresponding sell prices on the pricing
                      pages for this product.
                    </p>
                  ) : null}
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_market_cost")}
                    name="marketCost"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.marketCost}
                    touched={formik.touched.marketCost}
                    errors={formik.errors.marketCost}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {!formik.values.hasVariants ? (
            <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6 lg:col-span-8">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_stock_code")}
                    name="stockCode"
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue("stockCode", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.stockCode}
                    touched={formik.touched.stockCode}
                    errors={formik.errors.stockCode}
                  />
                  {checkingStockCode && (
                    <p className="mt-1 text-sm text-red-600">
                      Checking stock code availability...
                    </p>
                  )}
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_bar_code")}
                    name="barCode"
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue("barCode", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.barCode}
                    touched={formik.touched.barCode}
                    errors={formik.errors.barCode}
                  />
                  {checkingBarCode && (
                    <p className="mt-1 text-sm text-red-600">
                      Checking barcode availability...
                    </p>
                  )}
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_plu")}
                    name="plu"
                    type="number"
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue(
                        "plu",
                        value ? parseInt(value) : null
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.plu || ""}
                    touched={formik.touched.plu}
                    errors={formik.errors.plu}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_minimum_quantity")}
                    name="minimumQuantity"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.minimumQuantity}
                    touched={formik.touched.minimumQuantity}
                    errors={formik.errors.minimumQuantity}
                  />
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Field
                    title={t("text_quantity")}
                    name="inventory"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.inventory}
                    touched={formik.touched.inventory}
                    errors={formik.errors.inventory}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6 lg:col-span-8">
            <ProductVariantOptions
              formik={formik}
              variantOptions={variantOptions}
              variantOptionsUpdate={handleVariantOptionsUpdate}
            />
            {formik.touched.options && formik.errors.options ? (
              <p className="mt-2 text-sm text-red-600" id="options-errors">
                {formik.errors.options.toString()}
              </p>
            ) : null}
          </div>

          {formik.values.hasVariants && formik.values.variants.length > 0 ? (
            <div className="relative rounded-xl bg-greyish px-4 py-5 sm:p-6 lg:col-span-8">
              <div className="-mb-4 flex justify-end">
                <ProductVariantCreate
                  variantUpdate={handleVariantUpdate}
                  formik={formik}
                  variantTypes={variantTypes}
                />
              </div>
              <ProductVariantItems
                variantUpdate={handleVariantUpdate}
                formik={formik}
                variantTypes={variantTypes}
                initialStockCodes={initialStockCodes}
                initialBarCodes={initialBarCodes}
              />
            </div>
          ) : null}

          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6 lg:col-span-8">
            <FieldImage
              title={t("text_gallery")}
              onChange={(value) => {
                formik.setFieldValue("galleryImageUrls", value);
              }}
              value={formik.values.galleryImageUrls}
              touched={formik.touched.galleryImageUrls}
              errors={formik.errors.galleryImageUrls?.toString()}
              isMulti
            />
          </div>
          {typeof productId === "undefined" ? null : (
            <FieldPricing
              pricingLevel={initialValues.pricingLevel}
              className="lg:col-span-8"
            />
          )}
          <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6 lg:col-span-8">
            <FieldTableProducts
              title="Related Products"
              data={relatedProducts}
              value={formik.values.relatedIds}
              onChange={(products) => {
                formik.setFieldValue(
                  "relatedIds",
                  products.map((product) => parseInt(product.id))
                );
                setRelatedProducts(products);
              }}
              excludeIds={formik.values?.id ? [Number(formik.values.id)] : []}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

function ProductVariantOptions(props: {
  formik: FormikProps<InitialValues>;
  variantOptions: VariantOption[];
  variantOptionsUpdate: (variantOptions: VariantOption[]) => void;
}) {
  const { formik, variantOptions, variantOptionsUpdate } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addVariant = () => {
    const newVariants = [
      ...variantOptions,
      {
        id: nanoid(6),
        type: "",
        value: [],
      },
    ];
    variantOptionsUpdate(newVariants);
  };

  const removeVariant = (id: string) => {
    const deletedVariants = variantOptions.filter(
      (v: VariantOption) => v.id !== id
    );
    variantOptionsUpdate(deletedVariants);
  };

  const updateVariant = (id: string, variant: VariantOption) => {
    const updatedVariants = [...variantOptions];
    const index = updatedVariants.findIndex((v) => v.id === id);
    if (index !== -1) {
      updatedVariants[index] = variant;
      variantOptionsUpdate(updatedVariants);
      return;
    }
  };

  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = variantOptions.findIndex((v) => v.id === active.id);
      const newIndex = variantOptions.findIndex((v) => v.id === over.id);
      const updatedArray = arrayMove(variantOptions, oldIndex, newIndex);
      variantOptionsUpdate(updatedArray);
    }
  }

  return (
    <div className="space-y-5">
      <fieldset>
        <legend className="text-sm font-medium text-gray-900">Options</legend>
        <div className="relative mt-1 flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="variantOptions"
              name="variantOptions"
              type="checkbox"
              className={classNames(
                "h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500",
                "disabled:cursor-not-allowed"
              )}
              disabled={variantOptions.length > 0}
              checked={formik.values.hasVariants}
              onChange={(e) => {
                formik.setFieldValue("hasVariants", e.target.checked);
              }}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="variantOptions" className="text-gray-500">
              Enable options for creating variants
            </label>
          </div>
        </div>
      </fieldset>

      {formik.values.hasVariants && (
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={variantOptions.map((v) => v.id!)}
              strategy={verticalListSortingStrategy}
            >
              {variantOptions.map((v) => (
                <FieldsetVariant
                  key={v.id}
                  id={v.id!}
                  variant={v}
                  removeVariant={removeVariant}
                  updateVariant={updateVariant}
                  variantOptions={variantOptions}
                />
              ))}
            </SortableContext>
          </DndContext>
          {variantOptions.length === 0 ? (
            <div className="mt-2 py-6 text-center text-sm md:py-12">
              <ExclamationCircleIcon
                type="outline"
                name="exclamation-circle"
                className="mx-auto h-6 w-6 text-gray-400"
              />
              <p className="mt-4 text-gray-900">No variant options added</p>
              <Button variant="secondary" onClick={addVariant} className="mt-3">
                Add option
              </Button>
            </div>
          ) : variantOptions.length < 3 ? (
            <div className="mt-2 flex justify-end">
              <Button variant="secondary" onClick={addVariant}>
                <span className="sr-only">Add option</span>
                <PlusIcon
                  aria-hidden="true"
                  className="h-4 w-4 text-gray-700"
                />
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

function FieldsetVariant(props: {
  id: string;
  variant: VariantOption;
  updateVariant?: any;
  removeVariant?: any;
  variantOptions: VariantOption[];
}) {
  const { addNotify } = useNotifyContext();
  const { variant, updateVariant, removeVariant, variantOptions } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="mt-4 grid grid-cols-3 gap-4"
    >
      <div>
        <div className="flex w-full items-center">
          <Button variant="text" className="mr-4" {...listeners}>
            <span
              aria-hidden="true"
              className="bi bi-grip-vertical text-lg"
            ></span>
          </Button>
          <label
            htmlFor={`variant-type-${variant.id}`}
            className={`sr-only mb-1 block text-sm font-medium text-gray-900
`}
          >
            Variant type
          </label>
          <FieldVariantType
            value={variant.type}
            onChange={(value) => {
              const existing = variantOptions.find(
                (v) => v.type === value && v.id !== variant.id
              );
              if (existing) {
                addNotify({
                  type: NotifyType.ERROR,
                  title: "Variant type already exists",
                });
                return;
              }

              updateVariant(variant.id, {
                ...variant,
                type: value,
              });
            }}
            id={`variant-type-${variant.id}`}
            className={classNames(
              "w-full rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
            )}
          />
        </div>
      </div>
      <div className="col-span-2">
        <div className="flex w-full items-center">
          <label
            htmlFor={`variant-value-${variant.id}`}
            className={`sr-only mb-1 block text-sm font-medium text-gray-900`}
          >
            Variant value
          </label>
          <FieldVariantValues
            value={variant.value}
            onChange={(value) => {
              updateVariant(variant.id, {
                ...variant,
                value,
              });
            }}
            id={`variant-value-${variant.id}`}
            className={classNames(
              "w-full rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm"
            )}
          />

          {variantOptions.length > 0 ? (
            <Button
              variant="danger"
              border
              onClick={() => {
                removeVariant(variant.id);
              }}
              className="ml-4 px-2"
            >
              <TrashIcon aria-hidden="true" className="h-4 w-4" />
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function FieldVariantType({
  value,
  onChange,
  className,
  id,
}: {
  value: string;
  onChange: (newValue: string) => void;
  id?: string;
  className?: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const options = useMemo(
    () => [
      { label: "size", value: "size" },
      { label: "color", value: "color" },
    ],
    []
  );

  useEffect(() => {
    setValues({
      label: value,
      value,
    });
  }, [value]);

  const handleCreate = (inputValue: string) => {
    const newOption = {
      label: inputValue.toLowerCase(),
      value: inputValue.toLowerCase(),
    };
    setValues(newOption);
    onChange(inputValue.toLowerCase());
  };

  return (
    <SelectWrapper className={className}>
      <CreatableSelect
        styles={selectStyles}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
        onChange={(newValue) => {
          onChange(newValue?.value.toLowerCase() ?? "");
        }}
        onCreateOption={handleCreate}
        options={options}
        value={values}
        isClearable={false}
      />
    </SelectWrapper>
  );
}

const SortableMultiValueLabel = (
  props: MultiValueGenericProps<OptionProps>
) => {
  const { attributes, listeners } = useSortable({ id: props.data.value });
  return (
    <div {...attributes} {...listeners}>
      <components.MultiValueLabel {...props} />
    </div>
  );
};

const SortableMultiValue = (props: MultiValueProps<OptionProps>) => {
  const { setNodeRef, transform, transition } = useSortable({
    id: props.data.value,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return (
    <div ref={setNodeRef} style={style}>
      <components.MultiValue {...props} innerProps={innerProps} />
    </div>
  );
};

export function FieldVariantValues(props: {
  value: string[];
  onChange: (newValue: string[]) => void;
  id: string;
  className: string;
}) {
  const { value, onChange, id, className } = props;

  const [options, setOptions] = useState<OptionProps[]>([]);
  const [values, setValues] = useState<readonly OptionProps[]>([]);
  const didMount = useRef(false);

  useEffect(() => {
    const updatedValues = value.map((v) => ({
      label: v,
      value: v,
    }));
    setValues(updatedValues);
    if (!didMount.current) {
      setOptions(updatedValues);
      didMount.current = true;
    }
  }, [value]);

  const handleChange = (selectedOptions: MultiValue<OptionProps>) => {
    setValues(selectedOptions);
    onChange(selectedOptions.map((o) => o.value));
  };

  const handleCreate = (inputValue: string) => {
    const formattedValue = toCapitalize(inputValue, true);
    const newOption = {
      label: formattedValue,
      value: formattedValue,
    };
    setOptions([...options, newOption]);
    setValues([...values, newOption]);
    onChange([...value, formattedValue]);
  };

  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = values.findIndex((i) => i.value === active.id);
      const newIndex = values.findIndex((i) => i.value === over.id);
      const updateValues = arrayMove(values, oldIndex, newIndex);
      setValues(updateValues);
      onChange(updateValues.map((o) => o.value));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <SelectWrapper id={id} className={className}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={values.map((s) => s.value)}>
          <CreatableSelect
            isMulti
            styles={selectStyles}
            options={options}
            value={values}
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
            onChange={handleChange}
            onCreateOption={handleCreate}
            closeMenuOnSelect={false}
            components={{
              // @ts-ignore
              MultiValue: SortableMultiValue,
              MultiValueLabel: SortableMultiValueLabel,
            }}
            isClearable={false}
          />
        </SortableContext>
      </DndContext>
    </SelectWrapper>
  );
}

const DELETE_VARIANT = gql`
  mutation VariantDelete($id: ID!) {
    variantDelete(input: { id: $id }) {
      message
    }
  }
`;
function ProductVariantItems(props: {
  variantUpdate: (variants: VariantItem[]) => void;
  formik: FormikProps<InitialValues>;
  variantTypes: string[];
  initialStockCodes: string[];
  initialBarCodes: string[];
}) {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>();
  const { addNotify } = useNotifyContext();
  const {
    variantUpdate,
    formik,
    variantTypes,
    initialStockCodes,
    initialBarCodes,
  } = props;
  const [activeVariant, setActiveVariant] = useState<VariantProps | undefined>(
    undefined
  );

  const [deleteVariant] = useMutation(DELETE_VARIANT);

  const updateVariantItem = useCallback(
    (index: number, value: any) => {
      const newVariantItems = [...formik.values.variants];
      newVariantItems[index] = {
        ...newVariantItems[index],
        ...value,
      };
      variantUpdate(newVariantItems);
    },
    [formik.values.variants, variantUpdate]
  );

  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (activeVariant) {
      const newVariantItems = [...formik.values.variants];
      newVariantItems[activeVariant?.index] = {
        ...newVariantItems[activeVariant?.index],
        ...values,
      };
      variantUpdate(newVariantItems);
    }

    actions.setSubmitting(false);
    setActiveVariant(undefined);
  };

  const handleDelete = useCallback(
    (index: number) => {
      if (formik.values.variants[index].type === VariantEx.NEW) {
        const newVariantItems = [...formik.values.variants];
        newVariantItems.splice(index, 1);
        variantUpdate(newVariantItems);
        return;
      }

      deleteVariant({
        variables: {
          id: formik.values.variants[index].id,
        },
      })
        .then(({ data }) => {
          if (data.variantDelete.message) {
            const newVariantItems = [...formik.values.variants];
            newVariantItems.splice(index, 1);
            variantUpdate(newVariantItems);

            addNotify({
              type: NotifyType.SUCCESS,
              title: "Variant deleted successfully",
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Variant delete failed",
              message: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: "Variant delete failed",
            message: error.message,
          });
        });
    },
    [addNotify, deleteVariant, formik.values.variants, variantUpdate]
  );

  return (
    <>
      <div className="mb-1 text-sm font-medium text-gray-900">Variants</div>
      <div className="overflow-hidden rounded-md border border-gray-200 bg-white">
        <table className="block min-w-full divide-gray-300 md:table md:table-fixed md:divide-y">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6"
              >
                Image
              </th>
              {variantTypes.map((o: string, i: number) => (
                <th
                  key={`vth-${i}`}
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-medium capitalize text-gray-900"
                >
                  {o}
                </th>
              ))}
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
              >
                Stock Code
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
              >
                Inventory
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
              >
                Base Price
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {formik.values.variants.map((v, i) => (
              <tr key={`var-${i}`}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          v.variantImageUrl ? v.variantImageUrl : placeholder
                        }
                        alt="placeholder"
                      />
                    </div>
                  </div>
                </td>
                {v.optionValues?.map((ov, i) => (
                  <td
                    key={`vtb-${i}`}
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  >
                    <div className="text-gray-900">{ov.optionValue}</div>
                  </td>
                ))}
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="text-gray-900">
                    <input
                      type="text"
                      value={v.stockCode}
                      onChange={(e) => {
                        const stockCode = e.target.value;
                        updateVariantItem(i, { stockCode });
                      }}
                      className={classNames(
                        "relative block w-20 appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm md:w-32",
                        "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                        "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
                        getIn(formik.errors, `variants[${i}].stockCode`) &&
                          getIn(formik.touched, `variants[${i}].stockCode`)
                          ? "border-red-700"
                          : ""
                      )}
                    />
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="text-gray-900">
                    <input
                      type="number"
                      value={v.inventory}
                      onChange={(e) =>
                        updateVariantItem(i, {
                          inventory: Number(e.target.value),
                        })
                      }
                      className={classNames(
                        "relative block w-20 appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                        "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                        "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
                      )}
                    />
                    {getIn(formik.errors, `variants[${i}].inventory`) &&
                    getIn(formik.touched, `variants[${i}].inventory`) ? (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id={`variants[${i}].inventory-error`}
                      >
                        {getIn(formik.errors, `variants[${i}].inventory`)}
                      </p>
                    ) : null}
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="text-gray-900">{v.basePrice}</div>
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium text-primary-600 hover:text-primary-900 sm:pr-6">
                  {v.type === VariantEx.NEW && (
                    <span className="mr-2 inline-flex rounded-full bg-green-100 px-3 py-1 text-xs font-medium uppercase text-green-800">
                      New
                    </span>
                  )}
                  <Button
                    variant="info"
                    border
                    className="px-2"
                    onClick={() =>
                      setActiveVariant({
                        ...v,
                        index: i,
                      })
                    }
                  >
                    <PencilIcon aria-hidden="true" className="h-4 w-4" />
                    <span className="sr-only">Edit, {v.stockCode}</span>
                  </Button>
                  {productId !== "undefined" ? (
                    <Button
                      variant="danger"
                      border
                      className="ml-4 px-2"
                      onClick={() => {
                        handleDelete(i);
                      }}
                    >
                      <TrashIcon aria-hidden="true" className="h-4 w-4" />
                      <span className="sr-only">Delete, {v.stockCode}</span>
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Transition.Root
        show={activeVariant ? true : false}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveVariant(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <VariantUpdate
                  heading="Edit Variant - "
                  initialValues={{
                    stockCode: activeVariant?.stockCode ?? "",
                    barCode: activeVariant?.barCode ?? "",
                    plu: activeVariant?.plu ?? null,
                    basePrice: activeVariant?.basePrice ?? 0,
                    comparePrice: activeVariant?.comparePrice ?? 0,
                    cost: activeVariant?.cost ?? 0,
                    marketCost: activeVariant?.marketCost ?? 0,
                    minimumQuantity: activeVariant?.minimumQuantity ?? 0,
                    inventory: activeVariant?.inventory ?? 0,
                    variantImageUrl: activeVariant?.variantImageUrl ?? "",
                    type: activeVariant?.type ?? VariantEx.NEW,
                  }}
                  initialStockCodes={initialStockCodes}
                  initialBarCodes={initialBarCodes}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveVariant(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const CREATE_VARIANT = gql`
  mutation VariantCreate(
    $variantId: ID
    $productId: ID
    $options: [String!]
    $stockCode: String!
    $variantImageUrl: String
    $barCode: String
    $plu: Int
    $basePrice: Float
    $comparePrice: Float
    $cost: Float
    $marketCost: Float
    $inventory: Int
    $minimumQuantity: Int
    $optionValues: [ProductoptionvalueInput!]
  ) {
    variantCreate(
      input: {
        params: {
          variantId: $variantId
          productId: $productId
          options: $options
          stockCode: $stockCode
          variantImageUrl: $variantImageUrl
          barCode: $barCode
          plu: $plu
          basePrice: $basePrice
          comparePrice: $comparePrice
          cost: $cost
          marketCost: $marketCost
          inventory: $inventory
          minimumQuantity: $minimumQuantity
          optionValues: $optionValues
        }
      }
    ) {
      variant {
        id
      }
    }
  }
`;

function ProductVariantCreate(props: {
  variantUpdate: (variants: VariantItem[]) => void;
  formik: FormikProps<InitialValues>;
  variantTypes: string[];
}) {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>();
  const { addNotify } = useNotifyContext();
  const { variantUpdate, formik, variantTypes } = props;
  const [open, setOpen] = useState(false);
  const [createVariant] = useMutation(CREATE_VARIANT, {
    refetchQueries: [
      {
        query: GET_PRODUCT_BY_ID,
        variables: {
          id: productId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!productId) return;

    createVariant({
      variables: {
        variantId: "",
        productId: productId,
        options: variantTypes,
        stockCode: values.stockCode,
        variantImageUrl: values.variantImageUrl,
        barCode: values.barCode ? values.barCode : null,
        plu: values.plu ? values.plu : null,
        basePrice: values.basePrice,
        comparePrice: values.comparePrice ? values.comparePrice : 0,
        cost: values.cost,
        marketCost: values.marketCost,
        inventory: values.inventory,
        minimumQuantity: values.minimumQuantity,
        optionValues: values.optionValues,
      },
    })
      .then(({ data }) => {
        if (data.variantCreate.variant) {
          const newVariant = {
            id: data.variantCreate.variant.id,
            stockCode: values.stockCode,
            barCode: values.barCode ? values.barCode : null,
            plu: values.plu ? values.plu : null,
            basePrice: values.basePrice,
            comparePrice: values.comparePrice ? values.comparePrice : 0,
            cost: values.cost,
            marketCost: values.marketCost,
            minimumQuantity: values.minimumQuantity,
            inventory: values.inventory,
            variantImageUrl: values.variantImageUrl,
            optionValues: values.optionValues,
            type: VariantEx.OLD,
          };
          const newVariantItems = [...formik.values.variants, newVariant];
          variantUpdate(newVariantItems);
          setOpen(false);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Variant created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Variant create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Variant create failed",
          message: error.message,
        });
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
  return (
    <>
      <Button variant="info" border onClick={() => setOpen(true)}>
        <PlusSmallIcon aria-hidden="true" className="mr-2 h-5 w-5" />
        Add Variant
      </Button>

      <Transition.Root
        show={open}
        as={Fragment}
        // afterLeave={() => setQuery("")}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <VariantCreate
                  heading="Add Variant - "
                  initialValues={{
                    optionValues: variantTypes.map((vt) => ({
                      option: vt,
                      optionValue: "",
                    })),
                    stockCode: "",
                    barCode: "",
                    plu: null,
                    basePrice: 0,
                    comparePrice: 0,
                    cost: 0,
                    marketCost: 0,
                    minimumQuantity: 0,
                    inventory: 0,
                    variantImageUrl: "",
                    type: VariantEx.OLD,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setOpen(false)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
