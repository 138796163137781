import { useMutation } from "@apollo/client/react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Head } from "../../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../../contexts/NotifyContext";
import { VehicleBasic } from "../../../../../graphql/fleets/vehicles";
import {
  ADD_SERVICEREMAINDER,
  GET_SERVICEREMAINDER_BY_VEHICLE_ID,
  VehicleServiceReminderForm,
} from "../../../../../graphql/fleets/vehicles/pages/service-reminder";
import Form from "../../components/ServiceReminderForm";

const ServiceReminderCreate = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();

  const [createVehicleServiceReminder] = useMutation(ADD_SERVICEREMAINDER, {
    refetchQueries: [
      { query: GET_SERVICEREMAINDER_BY_VEHICLE_ID, variables: { vehicleId } },
    ],
  });
  const handleCreate = (
    values: VehicleServiceReminderForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!vehicleId) return;
    createVehicleServiceReminder({
      variables: {
        vehicleId: parseInt(vehicleId),
        vehicleServiceTaskId: values.vehicleServiceTaskId,
        remainderType: values.remainderType,
        timeInterval: values.timeInterval,
        timeIntervalUnit: values.timeIntervalUnit,
        timeDueSoonThreshold: values.timeDueSoonThreshold,
        timeDueSoonThresholdUnit: values.timeDueSoonThresholdUnit,
        notification: values.notification,
        status: values.status,
        watchers: values.watchers,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleServiceRemainderCreate) {
          return navigate(
            `/fleets/vehicles/${vehicleId}/service-reminder/${data.vehicleServiceRemainderCreate.vehicleServiceRemainder.id}`
          );
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Service reminder create failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={ServiceReminderCreateResource.name}
        heading={ServiceReminderCreateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ServiceReminderCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-4xl">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_service_reminder_create")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_service_reminder_create")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              vehicleId: Number(vehicleId),
              vehicleServiceTaskId: null,
              remainderType: false,
              timeInterval: null,
              timeIntervalUnit: null,
              timeDueSoonThreshold: null,
              timeDueSoonThresholdUnit: null,
              notification: false,
              status: 0,
              watchers: [],
            }}
            onSubmit={handleCreate}
            submitLabel={t("text_create")}
            onCancel={() => {
              navigate(`/fleets/vehicles/${vehicleId}/service-reminder`);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceReminderCreate;
export const ServiceReminderCreateResource: ResourceProps = {
  name: "Service Reminder",
  description: "Update an existing vehicle service reminders",
  access: ["update-vehicles"],
  path: "/service-reminder/add",
  icon: "bi bi-person",
};
