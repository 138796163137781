import { gql } from "@apollo/client";

import { User } from "../user-management/users";

export const GET_RECENT_ACTIVITIES = gql`
  query FetchRecentActivities($limit: Int) {
    fetchRecentActivities(limit: $limit) {
      id
      event
      eventDescription
      user {
        id
        fullName
        firstName
        lastName
        roles {
          roleName
        }
      }
      updatedAt
    }
  }
`;

export type RecentActivityApi = {
  id: string;
  event: string;
  eventDescription: string;
  user: User;
  updatedAt: string;
};
export interface RecentActivity extends RecentActivityApi {
  title: string;
}

export const GET_USER_NOTIFICATIONS = gql`
  query Me {
    me {
      id
      fullName
      firstName
      lastName
      notifications {
        id
        message
        updatedAt
      }
    }
  }
`;
