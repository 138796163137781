import { lazy, Suspense } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import {
  LayoutSplashScreen,
  NotAuthorized,
  NotFound,
} from "../../components/core";
import PageLayout from "../../layouts/PageLayout";
import { isAuthorizedForResource, useAuth } from "../auth";
import { ZonePickerListResource } from "./assign-zone-pickers/List";
import { PermissionListResource } from "./permissions/List";
import { ResourceListResource } from "./resources/List";
import { RoleCreateResource } from "./roles/Create";
import { RoleListResource } from "./roles/List";
import { RoleUpdateResource } from "./roles/Update";
import { UserCreateResource } from "./users/Create";
import { UserListResource } from "./users/List";
import { UserUpdateResource } from "./users/Update";

const UserList = lazy(() => import("./users/List"));
const UserCreate = lazy(() => import("./users/Create"));
const UserUpdate = lazy(() => import("./users/Update"));
const RoleList = lazy(() => import("./roles/List"));
const RoleCreate = lazy(() => import("./roles/Create"));
const RoleUpdate = lazy(() => import("./roles/Update"));
const PermissionList = lazy(() => import("./permissions/List"));
const ResourceList = lazy(() => import("./resources/List"));
const ZonePickerList = lazy(() => import("./assign-zone-pickers/List"));

const breadcrumbs = [
  {
    name: "Alpha Fresh",
    href: "/",
  },
];

export function UserManagementPage() {
  const { currentRole } = useAuth();

  let routes: RouteObject[] = [
    {
      element: <PageLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={UserListResource.path} replace={true} />,
        },
        {
          path: UserListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            UserListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <UserList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(UserListResource.access.join(", "))}
            />
          ),
        },
        {
          path: UserCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            UserCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <UserCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(UserCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: UserUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            UserUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <UserUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(UserUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: RoleListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            RoleListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <RoleList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(RoleListResource.access.join(", "))}
            />
          ),
        },
        {
          path: RoleCreateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            RoleCreateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <RoleCreate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(RoleCreateResource.access.join(", "))}
            />
          ),
        },
        {
          path: RoleUpdateResource.path,
          element: isAuthorizedForResource(
            currentRole,
            RoleUpdateResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <RoleUpdate breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(RoleUpdateResource.access.join(", "))}
            />
          ),
        },
        {
          path: PermissionListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            PermissionListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <PermissionList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(PermissionListResource.access.join(", "))}
            />
          ),
        },
        {
          path: ResourceListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            ResourceListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <ResourceList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(ResourceListResource.access.join(", "))}
            />
          ),
        },
        {
          path: ZonePickerListResource.path,
          element: isAuthorizedForResource(
            currentRole,
            ZonePickerListResource.access
          ) ? (
            <Suspense fallback={<LayoutSplashScreen />}>
              <ZonePickerList breadcrumbs={breadcrumbs} />
            </Suspense>
          ) : (
            <NotAuthorized
              error={new Error(ZonePickerListResource.access.join(", "))}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
