import { useQuery } from "@apollo/client";
import { Menu, RadioGroup, Switch, Transition } from "@headlessui/react";
import {
  ArrowDownOnSquareIcon,
  ArrowUpOnSquareIcon,
  ChevronDownIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner } from "../../../../../animations";
import {
  Button,
  Field,
  FieldCustomers,
  FieldDatepicker,
  selectStyles,
  SelectWrapper,
} from "../../../../../components/form";
import {
  type CustomerGroup,
  GET_CUSTOMERGROUPS,
} from "../../../../../graphql/sales/customer-groups";
import { PreviewCustomer } from "../../../../../graphql/sales/customers";
import { AlertType, useAlert } from "../../../../../hooks/useAlert";
import { classNames } from "../../../../../utils";

type SpecialPrice = {
  id?: number;
  name: string;
  pricingMethod: SingleValue<OptionProps>;
  customerType: number;
  customerIds: number[];
  customerGroupIds: number[];
  startDate: string;
  endDate: string;
  status: boolean;
};

export default function Form({
  initialValues,
  initialCustomers,
  onSubmit,
  actionLabel,
}: {
  initialValues: SpecialPrice;
  initialCustomers: PreviewCustomer[];
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState<PreviewCustomer[]>([]);

  useEffect(() => {
    setCustomers(initialCustomers);
  }, [initialCustomers]);

  const SpecialPriceSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    pricingMethod: Yup.object().nullable().required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: SpecialPriceSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  const customerTypes = [
    {
      label: "All Customers",
      value: 0,
      description: "All customers will be charged this price",
    },
    {
      label: "Specific Customers",
      value: 1,
      description: "Only specific customers will be charged this price",
    },
    // {
    //   label: "Customer Groups",
    //   value: 2,
    //   description: "Only specific customer groups will be charged this price",
    // },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <Field
              title={t("text_name")}
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              touched={touched.name}
              errors={errors.name}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-6">
            <div className="rounded-md bg-white p-5">
              <div className="mb-3 p-1 text-sm font-medium text-gray-900">
                Pricing
              </div>
              <div className="grid grid-cols-1 divide-x sm:grid-cols-2">
                <div className="p-1">
                  <p className="mb-2 text-sm">Currency</p>
                  <div className="text-2xl font-medium">AUD $</div>
                  <p className="text-sm font-light text-gray-500">
                    Australian Dollar
                  </p>
                </div>
                <div className="p-1 sm:pl-5">
                  <p className="mb-2 text-sm">Pricing method</p>
                  <div
                    className={classNames(
                      "relative block w-full appearance-none rounded-md border px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      "border-gray-200 bg-gray-50 text-gray-500"
                    )}
                  >
                    {formik.values.pricingMethod?.label}
                  </div>
                </div>
              </div>

              <div className="mt-5 flex items-center justify-between border-t border-gray-200 pt-5">
                <p className="mb-0 text-sm">Manage pricing adjustments</p>
                <ManagePricing />
              </div>
            </div>
          </div>

          <div className="col-span-12 space-y-4 sm:col-span-6 md:col-span-6">
            <div>
              <RadioGroup
                value={formik.values.customerType}
                onChange={(value: number) => {
                  formik.setFieldValue("customerType", value);
                }}
              >
                <RadioGroup.Label className="text-sm font-medium text-gray-900">
                  Customer Type
                </RadioGroup.Label>

                <div className="isolate mt-1 -space-y-px rounded-md bg-white">
                  {customerTypes.map((type, typeIdx) => (
                    <RadioGroup.Option
                      key={type.label}
                      value={type.value}
                      className={({ checked }) =>
                        classNames(
                          typeIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                          typeIdx === customerTypes.length - 1
                            ? "rounded-bl-md rounded-br-md"
                            : "",
                          checked
                            ? "z-10 border-primary-200 bg-primary-50"
                            : "border-gray-200",
                          "relative flex cursor-pointer border p-4 focus:outline-none"
                        )
                      }
                    >
                      {({ active, checked }) => (
                        <>
                          <span
                            className={classNames(
                              checked
                                ? "border-transparent bg-primary-600"
                                : "border-gray-300 bg-white",
                              active
                                ? "ring-2 ring-primary-500 ring-offset-2"
                                : "",
                              "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                            )}
                            aria-hidden="true"
                          >
                            <span className="h-1.5 w-1.5 rounded-full bg-white" />
                          </span>
                          <span className="ml-3 flex flex-col">
                            <RadioGroup.Label
                              as="span"
                              className={classNames(
                                checked ? "text-primary-900" : "text-gray-900",
                                "block text-sm font-medium"
                              )}
                            >
                              {type.label}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={classNames(
                                checked ? "text-primary-700" : "text-gray-500",
                                "block text-sm"
                              )}
                            >
                              {type.description}
                            </RadioGroup.Description>
                          </span>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            {formik.values.customerType === 1 ? (
              <div>
                <FieldCustomers
                  title="Customers"
                  data={customers}
                  value={formik.values.customerIds}
                  onChange={(_customers) => {
                    formik.setFieldValue(
                      "customerIds",
                      _customers.flatMap((c) => Number(c.id))
                    );
                    setCustomers(_customers);
                  }}
                />
              </div>
            ) : null}
            {formik.values.customerType === 2 ? (
              <div>
                <label className="block text-sm font-medium text-gray-900">
                  {t("text_customer_groups")}
                </label>
                <FieldCustomerGroups
                  value={formik.values.customerGroupIds}
                  // fixedValue={formik.values.autoCategories?.flatMap(
                  //   (ac) => ac.id
                  // )}
                  onChange={(value) => {
                    formik.setFieldValue("customerGroupIds", value);
                  }}
                  className={classNames(
                    "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                    formik.touched.customerGroupIds &&
                      formik.errors.customerGroupIds
                      ? "border-red-600 text-red-900"
                      : ""
                  )}
                />
                {/* {formik.values.autoCategories?.length ? (
                <p className="mt-2 space-x-4 text-sm text-gray-500">
                  Default:{" "}
                  {formik.values.autoCategories
                    ?.flatMap((ac) => ac.name)
                    .map((name) => (
                      <span className="rounded-md border border-gray-300 bg-white py-0.5 text-xs px-1">
                        {name}
                      </span>
                    ))}
                </p>
              ) : null} */}
                {formik.touched.customerGroupIds &&
                formik.errors.customerGroupIds ? (
                  <p
                    className="mt-2 text-sm text-red-600"
                    id="customerGroupIds-errors"
                  >
                    {formik.errors.customerGroupIds.toString()}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <FieldDatepicker
              title={t("text_start_date")}
              name="startDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "startDate",
                    value ? new Date(value).toISOString() : ""
                  );
                }
              }}
              selected={
                formik.values.startDate
                  ? new Date(formik.values.startDate)
                  : null
              }
              showTimeSelect
              timeFormat="p"
              timeIntervals={5}
              dateFormat="Pp"
              touched={touched.startDate}
              errors={errors.startDate}
            />
          </div>
          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <FieldDatepicker
              title={t("text_end_date")}
              name="endDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "endDate",
                    value ? new Date(value).toISOString() : ""
                  );
                }
              }}
              selected={
                formik.values.endDate ? new Date(formik.values.endDate) : null
              }
              showTimeSelect
              timeFormat="p"
              timeIntervals={5}
              dateFormat="Pp"
              touched={touched.endDate}
              errors={errors.endDate}
            />
          </div>

          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <fieldset>
              <legend className="text-sm font-medium text-gray-900">
                {t("text_status")}
              </legend>
              <Switch.Group
                as="div"
                className="mt-1.5 inline-flex items-center"
              >
                <Switch
                  checked={formik.values.status}
                  onChange={() => {
                    formik.setFieldValue("status", !formik.values.status);
                  }}
                  id="status"
                  className={classNames(
                    formik.values.status ? "bg-primary-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      formik.values.status ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label
                  passive
                  htmlFor="status"
                  className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                >
                  {formik.values.status ? t("text_active") : t("text_inactive")}
                </Switch.Label>
              </Switch.Group>
            </fieldset>
          </div>
        </div>

        <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
          <Link to="/inventory/pricing/special-prices" className="flex w-full">
            <Button variant="secondary" className="w-full justify-center">
              {t("text_cancel")}
            </Button>
          </Link>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              actionLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}

export function ManagePricing() {
  const { t } = useTranslation();

  const [warning, setWarning] = useState(false);

  const Message = useAlert({
    open: warning,
    title: "Under Development",
    message: "This area is under development and will be available soon.",
    type: AlertType.SUCCESS,
    delay: 3000,
    onDismiss: () => {
      setWarning(false);
    },
  });

  return (
    <>
      <Message />
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-200 bg-gray-800 px-5 py-2.5 text-sm font-normal text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-primary-100">
            <>{t("text_adjust_prices")}</>
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="pricing"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <TicketIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Set prices manually
                  </Link>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => setWarning(true)}
                  >
                    <ArrowUpOnSquareIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Import from CSV
                  </span>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => setWarning(true)}
                  >
                    <ArrowDownOnSquareIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Export to CSV
                  </span>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}

function FieldCustomerGroups({
  value,
  fixedValue,
  onChange,
  className,
}: {
  value: number[];
  fixedValue?: string[];
  onChange: (newValue: number[]) => void;
  className: string;
}) {
  const [values, setValues] = useState<MultiValue<OptionProps>>();

  const { data, loading, refetch } = useQuery<{
    fetchCustomerGroups: CustomerGroup[];
  }>(GET_CUSTOMERGROUPS, {
    variables: {
      status: true,
    },
  });
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchCustomerGroups) {
      const filteredOptions = data.fetchCustomerGroups?.filter(
        (p: CustomerGroup) => !fixedValue?.includes(p.id)
      );
      return filteredOptions?.map((p: CustomerGroup) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    }
    return [];
  }, [data, fixedValue]);

  useEffect(() => {
    if (!options) return;
    if (value.length) {
      const activeOptions = options.filter((v) =>
        value.includes(parseInt(v.value))
      );
      setValues(activeOptions);
      return;
    }
    setValues([]);
  }, [options, value]);

  const handleChange = (
    newValue: MultiValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(newValue ? newValue.flatMap((v) => parseInt(v.value)) : []);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={handleChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
