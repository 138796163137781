import { useQuery } from "@apollo/client";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";

import { Button, FieldDriver } from "../../../../components/form";
import { Vehicle } from "../../../../graphql/fleets/vehicles";
import {
  GET_SERVICEREMAINDER_BY_VEHICLE_ID,
  getDateByTimeInterval,
  renderTimeIntervalUnit,
  VehicleServiceReminder,
} from "../../../../graphql/fleets/vehicles/pages/service-reminder";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
      text: "Cost Statistics",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export function Overview({
  vehicle,
  setActiveTab,
}: {
  vehicle: Vehicle;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { data, loading, error, refetch } = useQuery<{
    fetchServiceRemaindersByVehicle: VehicleServiceReminder[] | null;
  }>(GET_SERVICEREMAINDER_BY_VEHICLE_ID, {
    variables: {
      vehicleId: vehicle.id,
    },
  });
  const serviceReminders = useMemo<VehicleServiceReminder[]>(
    () => data?.fetchServiceRemaindersByVehicle ?? [],
    [data]
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });

  const graphData = useMemo(() => {
    const pastTwelveMonths = Array.from({ length: 12 }, (_, i) => {
      const d = new Date();
      d.setMonth(d.getMonth() - i);
      return { month: d.getMonth() + 1, year: d.getFullYear() };
    });
    console.log(pastTwelveMonths);
    const statistics = vehicle?.costStatistics || [];
    const fuelData = pastTwelveMonths.map((m) => {
      const block = statistics.find(
        (b) => b.month === m.month && b.year === m.year
      );
      return block ? block.fuelCost : 0;
    });
    const fuelTotal = fuelData.reduce((a, b) => a + b, 0);
    const serviceData = pastTwelveMonths.map((m) => {
      const block = statistics.find(
        (b) => b.month === m.month && b.year === m.year
      );
      return block ? block.serviceCost : 0;
    });
    const serviceTotal = serviceData.reduce((a, b) => a + b, 0);
    const otherData = pastTwelveMonths.map((m) => {
      const block = statistics.find(
        (b) => b.month === m.month && b.year === m.year
      );
      return block ? block.otherCost : 0;
    });
    const otherTotal = otherData.reduce((a, b) => a + b, 0);

    const total = fuelTotal + serviceTotal + otherTotal;

    const data = {
      labels: pastTwelveMonths.map(
        (m) =>
          `${new Date(new Date().getFullYear(), m.month - 1, 1).toLocaleString(
            "default",
            {
              month: "short",
            }
          )} ${m.year}`
      ),
      datasets: [
        {
          label: "Fuel",
          data: fuelData,
          backgroundColor: "#01a7b9",
        },
        {
          label: "Service",
          data: serviceData,
          backgroundColor: "#ffc008",
        },
        {
          label: "Other",
          data: otherData,
          backgroundColor: "#937ecd",
        },
      ],
    };
    return { data, total, fuelTotal, serviceTotal, otherTotal };
  }, [vehicle?.costStatistics]);

  return (
    <div className="grid gap-4 sm:grid-cols-12">
      <div className="space-y-5 sm:col-span-7">
        <div className="rounded-xl bg-greyish p-5">
          <h3 className="mb-3 text-sm font-medium text-gray-900">Details</h3>
          <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Name</span>
                <span className="text-gray-500">{vehicle.name}</span>
              </li>
              <li className="flex items-center justify-between">
                <span>Status</span>
                <span className="text-gray-500">{vehicle.status.name}</span>
              </li>
              <li className="flex items-center justify-between">
                <span>Make</span>
                <span className="text-gray-500">
                  {vehicle.make ? vehicle.make.name : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Model</span>
                <span className="text-gray-500">
                  {vehicle.model ? vehicle.model.name : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Year</span>
                <span className="text-gray-500">
                  {vehicle.year ? vehicle.year : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Color</span>
                <span className="text-gray-500">
                  {vehicle.color ? vehicle.color : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>License Plate</span>
                <span className="text-gray-500">
                  {vehicle.licensePlate ? vehicle.licensePlate : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>VIN SN</span>
                <span className="text-gray-500">
                  {vehicle.vinSn ? vehicle.vinSn : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>MSRP</span>
                <span className="text-gray-500">
                  {vehicle.msrp ? vehicle.msrp : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Trim</span>
                <span className="text-gray-500">
                  {vehicle.trim ? vehicle.trim : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Body Type</span>
                <span className="text-gray-500">
                  {vehicle.vehicleBodyType ? vehicle.vehicleBodyType.name : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Body Sub Type</span>
                <span className="text-gray-500">
                  {vehicle.vehicleBodySubtype
                    ? vehicle.vehicleBodySubtype.name
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Vehicle Type</span>
                <span className="text-gray-500">
                  {vehicle.vehicleType ? vehicle.vehicleType.name : "-"}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="rounded-xl bg-greyish p-5">
          <h3 className="mb-3 text-sm font-medium text-gray-900">
            Last Known Location
          </h3>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "300px",
              }}
              center={{
                lat: -35.2813912,
                lng: 149.129001,
              }}
              zoom={16}
            >
              <></>
            </GoogleMap>
          ) : null}
        </div>
      </div>
      <div className="space-y-5 sm:col-span-5 ">
        <div className="rounded-xl bg-greyish p-5">
          <header className="mb-3 flex items-end justify-between">
            <h3 className="text-sm font-medium text-gray-900">Open Issues</h3>
            <Button
              variant="secondary"
              border
              onClick={(e) => {
                setActiveTab(5);
              }}
              className="px-2 py-1 text-sm"
            >
              View More
            </Button>
          </header>
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="flex items-center justify-between rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
              <span>Overdue</span>
              <strong className="text-3xl">
                {vehicle?.overDueIssuesCount ? vehicle.overDueIssuesCount : "0"}
              </strong>
            </div>
            <div className="flex items-center justify-between rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
              <span>Open</span>
              <strong className="text-3xl">
                {vehicle?.openIssuesCount ? vehicle.openIssuesCount : "0"}
              </strong>
            </div>

            {vehicle.openIssues.map((item) => (
              <div
                key={item.id}
                className="space-y-4 rounded-xl bg-white p-4 shadow-sm sm:col-span-2 md:p-6 xl:p-8"
              >
                <header className="flex items-start space-x-4 font-medium">
                  <Link
                    to={`/fleets/vehicles/${vehicle.id}/issue/${item.id}`}
                    target="_blank"
                    className="text-primary-700"
                  >
                    #{item.id}
                  </Link>
                  <h3>{item.summary}</h3>
                </header>
                <div>
                  Reported{" "}
                  {item.reportedDate
                    ? DateTime.fromISO(item.reportedDate).toRelative()
                    : null}{" "}
                  {item.reportedById ? "by" : null}{" "}
                  {item.reportedById ? (
                    <FieldDriver
                      title="Reporter"
                      value={item.reportedById}
                      isPreview
                      isString
                    />
                  ) : null}
                </div>
                <p>{item.description}</p>
                <Link
                  to={`/fleets/vehicles/${vehicle.id}/issue/${item.id}`}
                  target="_blank"
                  className="inline-block text-primary-700"
                >
                  <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className="rounded-xl bg-greyish p-5">
          <header className="mb-3 flex items-end justify-between">
            <h3 className="text-sm font-medium text-gray-900">
              Service Reminders
            </h3>
            <Button
              variant="secondary"
              border
              onClick={(e) => {
                setActiveTab(4);
              }}
              className="px-2 py-1 text-sm"
            >
              View More
            </Button>
          </header>

          {serviceReminders.map((item) => (
            <div
              key={item.id}
              className="space-y-4 rounded-xl bg-white p-4 shadow-sm sm:col-span-2 md:p-6 xl:p-8"
            >
              <header className="flex items-start space-x-4 font-medium">
                <Link
                  to={`/fleets/vehicles/${vehicle.id}/service-reminder/${item.id}`}
                  target="_blank"
                  className="text-primary-700"
                >
                  #{item.id}
                </Link>
                <h3>{item.vehicleServiceTask.name}</h3>
              </header>
              <p>
                Every {item.timeInterval}{" "}
                {renderTimeIntervalUnit(item.timeIntervalUnit, true, true)}
              </p>
              <p>
                Due Soon{" "}
                {item.timeInterval
                  ? getDateByTimeInterval(
                      item.timeInterval,
                      item.timeIntervalUnit
                    ).toRelative()
                  : null}{" "}
                from now
              </p>
              <Link
                to={`/fleets/vehicles/${vehicle.id}/service-reminder/${item.id}`}
                target="_blank"
                className="inline-block text-primary-700"
              >
                <ArrowTopRightOnSquareIcon className="h-5 w-5" />
              </Link>
            </div>
          ))}
        </div>

        <div className="rounded-xl bg-greyish p-5">
          <h3 className="mb-3 text-sm font-medium text-gray-900">
            Cost of Ownership
          </h3>
          <div className="rounded-lg bg-white p-4 shadow-sm">
            <header>
              <h3 className="flex items-center text-sm font-normal text-gray-900">
                Total Costs
              </h3>
              <p className="text-xl font-normal text-gray-900">
                ${graphData.total}
              </p>
            </header>
            <Bar options={options} data={graphData.data} />
            <footer className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <div>
                <h3 className="flex items-center text-sm font-normal text-gray-900">
                  <span className="mr-1 h-2 w-2 rounded-full bg-[#01a7b9]" />
                  Fuel Costs
                </h3>
                <p className="text-xl font-medium text-gray-900">
                  ${graphData.fuelTotal}
                </p>
              </div>
              <div>
                <h3 className="flex items-center text-sm font-normal text-gray-900">
                  <span className="mr-1 h-2 w-2 rounded-full bg-[#ffc008]" />
                  Service Costs
                </h3>
                <p className="text-xl font-medium text-gray-900">
                  ${graphData.serviceTotal}
                </p>
              </div>
              <div>
                <h3 className="flex items-center text-sm font-normal text-gray-900">
                  <span className="mr-1 h-2 w-2 rounded-full bg-[#937ecd]" />
                  Other Costs
                </h3>
                <p className="text-xl font-medium text-gray-900">
                  ${graphData.otherTotal}
                </p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
}
