import { useLazyQuery, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import {
  BellIcon,
  EyeIcon,
  PencilIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { Loader, Waiting } from "../../../../animations";
import { VehicleThumb } from "../../../../components/appearance";
import { Button, FieldDriver } from "../../../../components/form";
import { TableCursor } from "../../../../components/table";
import {
  DeliveryAllocation,
  GET_DELIVERY_ALLOCATIONS_ID_BY_OPERATOR,
} from "../../../../graphql/fleets/delivery-allocations";
import {
  CREATE_VEHICLE_ASSIGNMENT,
  DELETE_VEHICLE_ASSIGNMENT,
  GET_VEHICLE_ASSIGNMENT_BY_ID,
  GET_VEHICLE_ASSIGNMENTS,
  UPDATE_VEHICLE_ASSIGNMENT,
  VehicleAssignment,
  VehicleAssignmentForm,
} from "../../../../graphql/fleets/vehicle-assignments";
import {
  CREATE_VEHICLE_WATCHER,
  DELETE_VEHICLE_WATCHER,
  GET_VEHICLE_BY_ID,
  VehicleBasic,
} from "../../../../graphql/fleets/vehicles";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { renderStatus } from "../../../../graphql/sales/orders";
import { classNames, formatDateTime } from "../../../../utils";
import { useAuth } from "../../../auth";
import { Comments } from "./Comments";
import Form from "./Form";

type AssignmentProps = {
  showView: boolean;
  setShowView: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseView: () => void;
  showEdit: boolean;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseEdit: () => void;
  assignmentId: string | null;
  deliveryRunId: number | null;
  vehicleBasic: VehicleBasic | null;
  refetchCalendar: () => Promise<void>;
  currentDate: string | null;
  startDate: string | null;
  endDate: string | null;
};
export function Assignment({
  showView,
  setShowView,
  onCloseView,
  showEdit,
  setShowEdit,
  onCloseEdit,
  assignmentId,
  deliveryRunId,
  vehicleBasic,
  refetchCalendar,
  currentDate,
  startDate,
  endDate,
}: AssignmentProps) {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const [activeAssignment, setActiveAssignment] =
    useState<VehicleAssignment | null>(null);
  const [openDeliveryAllocation, setOpenDeliveryAllocation] = useState(false);
  const [activeDeliveryAllocations, setActiveDeliveryAllocations] = useState<
    DeliveryAllocation[]
  >([]);

  const [fetchingVehicleAssignment, { loading, refetch }] = useLazyQuery<{
    fetchVehicleAssignment: VehicleAssignment;
  }>(GET_VEHICLE_ASSIGNMENT_BY_ID, {
    onCompleted(data) {
      if (data?.fetchVehicleAssignment) {
        setActiveAssignment(data.fetchVehicleAssignment);
        setActiveDeliveryAllocations([]);
      }
    },
  });

  useEffect(() => {
    if (!assignmentId) {
      setActiveAssignment(null);
      setActiveDeliveryAllocations([]);
      return;
    }
    fetchingVehicleAssignment({
      variables: {
        id: assignmentId,
      },
    });
  }, [assignmentId, fetchingVehicleAssignment]);

  const [deleteVehicleAssignment, { loading: loadingDelete }] = useMutation<{
    vehicleAssignmentDelete: VehicleAssignment;
  }>(DELETE_VEHICLE_ASSIGNMENT, {
    refetchQueries: [{ query: GET_VEHICLE_ASSIGNMENTS }],
    awaitRefetchQueries: true,
  });

  const isWatching = useMemo<boolean>(() => {
    if (!currentUser || !activeAssignment) return false;
    return activeAssignment.vehicle.watchers.some(
      (watcher) => watcher.id === currentUser.id
    );
  }, [activeAssignment, currentUser]);

  const [createWatcher, { loading: loadingCreateWatcher }] = useMutation(
    CREATE_VEHICLE_WATCHER
  );
  const [deleteWatcher, { loading: loadingDeleteWatcher }] = useMutation(
    DELETE_VEHICLE_WATCHER
  );

  const handleWatch = useCallback(() => {
    if (!currentUser || !activeAssignment) return;
    const currentWatcher = activeAssignment.vehicle.watchers.find(
      (watcher) => watcher.id === currentUser.id
    );
    if (currentWatcher) {
      deleteWatcher({
        variables: {
          vehicleId: activeAssignment.vehicle.id,
          watcherId: currentUser.id,
        },
        refetchQueries: [
          {
            query: GET_VEHICLE_BY_ID,
            variables: {
              id: activeAssignment.vehicle.id,
            },
          },
        ],
      })
        .then(async ({ data }) => {
          if (data?.vehicleWatcherDelete) {
            await refetch();
            toast.success("You are unwatching this vehicle");
          } else {
            toast.error("Something went wrong. Please try again later");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
      return;
    }

    createWatcher({
      variables: {
        vehicleId: activeAssignment.vehicle.id,
        watcherId: currentUser.id,
      },
      refetchQueries: [
        {
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: activeAssignment.vehicle.id,
          },
        },
      ],
    })
      .then(async ({ data }) => {
        if (data?.vehicleWatcherCreate) {
          await refetch();
          toast.success("You are now watching this vehicle");
        } else {
          toast.error("Something went wrong. Please try again later");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [activeAssignment, createWatcher, currentUser, deleteWatcher, refetch]);

  const [updateVehicleAssignment] = useMutation(UPDATE_VEHICLE_ASSIGNMENT);
  const [createVehicleAssignment] = useMutation(CREATE_VEHICLE_ASSIGNMENT);

  const handleAssignment = useCallback(
    (
      values: VehicleAssignmentForm,
      actions: { setSubmitting: (arg0: boolean) => void }
    ) => {
      if (!currentDate) return new Error("Current date is not available");
      const selectedDate = DateTime.fromISO(currentDate);
      const fromDate = selectedDate.minus({ days: 15 }).toISO();
      const toDate = selectedDate.plus({ days: 15 }).toISO();

      if (assignmentId) {
        updateVehicleAssignment({
          variables: {
            id: assignmentId,
            vehicleId: values.vehicleId,
            operatorId: values.operatorId,
            deliveryRunId: values.deliveryRunId,
            startDate: values.startDate,
            startingOdometer: values.startingOdometer,
            endDate: values.endDate,
            endingOdometer: values.endingOdometer,
            duration: values.duration,
            usage: values.usage,
            comments: values.comments,
            status: values.status,
          },
          refetchQueries: [
            {
              query: GET_VEHICLE_ASSIGNMENTS,
              variables: {
                fromDate: fromDate,
                toDate: toDate,
                deliveryRunId,
              },
            },
          ],
          awaitRefetchQueries: true,
        })
          .then(async ({ data }) => {
            actions.setSubmitting(false);
            if (data?.vehicleAssignmentUpdate) {
              await refetchCalendar();
              toast.success("Vehicle Assignment updated successfully");
              setShowEdit(false);
              setShowView(false);
              onCloseView();
            } else {
              toast.error("Something went wrong, please try again later");
            }
          })
          .catch((error) => {
            actions.setSubmitting(false);
            toast.error(error.message);
          });
        return;
      }

      createVehicleAssignment({
        variables: {
          vehicleId: values.vehicleId,
          operatorId: values.operatorId,
          deliveryRunId: values.deliveryRunId,
          startDate: values.startDate,
          startingOdometer: values.startingOdometer,
          endDate: values.endDate,
          endingOdometer: values.endingOdometer,
          duration: values.duration,
          usage: values.usage,
          comments: values.comments,
          status: values.status,
        },
        refetchQueries: [
          {
            query: GET_VEHICLE_ASSIGNMENTS,
            variables: {
              fromDate: fromDate,
              toDate: toDate,
              deliveryRunId,
            },
          },
        ],
        awaitRefetchQueries: true,
      })
        .then(async ({ data }) => {
          actions.setSubmitting(false);
          if (data?.vehicleAssignmentCreate) {
            await refetchCalendar();
            toast.success("Vehicle Assignment created successfully");
            setShowEdit(false);
            setShowView(false);
            onCloseView();
          } else {
            toast.error("Something went wrong, please try again later");
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
    },
    [
      assignmentId,
      createVehicleAssignment,
      currentDate,
      deliveryRunId,
      onCloseView,
      refetchCalendar,
      setShowEdit,
      setShowView,
      updateVehicleAssignment,
    ]
  );

  const handleUnassignVehicle = useCallback(() => {
    if (!assignmentId) return;
    deleteVehicleAssignment({
      variables: {
        id: assignmentId,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleAssignmentDelete) {
          await refetchCalendar();
          toast.success("Vehicle unassigned successfully");
          setShowEdit(false);
          onCloseView();
        } else {
          toast.error("Something went wrong, please try again later");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [
    assignmentId,
    deleteVehicleAssignment,
    onCloseView,
    refetchCalendar,
    setShowEdit,
  ]);

  const [fetchingAllocationByOperator] = useLazyQuery<{
    fetchAllocationByOperator: DeliveryAllocation[];
  }>(GET_DELIVERY_ALLOCATIONS_ID_BY_OPERATOR);

  const handleViewDeliveryAllocations = useCallback(() => {
    if (!activeAssignment) return;
    fetchingAllocationByOperator({
      variables: {
        operatorId: activeAssignment.operator.id,
      },
    })
      .then(({ data }) => {
        if (data?.fetchAllocationByOperator) {
          setActiveDeliveryAllocations(data.fetchAllocationByOperator);
          setOpenDeliveryAllocation(true);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [activeAssignment, fetchingAllocationByOperator]);

  const deliveryAllocationcolumns = useMemo<ColumnDef<DeliveryAllocation>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Order Number",
        enableHiding: false,
      },
      {
        accessorKey: "customer",
        header: () => <span className="whitespace-nowrap">Customer</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.customer.customerName}
          </span>
        ),
        size: 200,
      },
      {
        accessorKey: "deliveryRun",
        header: () => <span className="whitespace-nowrap">Delivery Run</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.deliveryRun}
          </span>
        ),
      },
      {
        accessorKey: "status",
        header: () => <span className="whitespace-nowrap">Status</span>,
        cell: (props) => renderStatus(props.row.original.status),
      },
      {
        accessorKey: "operator",
        header: () => <span className="whitespace-nowrap">Operator</span>,
        cell: (props) => (
          <div className="w-56 max-w-full">
            <FieldDriver
              title={t("text_operator")}
              value={
                props.row.original.operator
                  ? parseInt(props.row.original.operator.id)
                  : null
              }
              isPreview
            />
          </div>
        ),
        size: 200,
      },
      {
        accessorKey: "notes",
        header: () => (
          <span className="whitespace-nowrap">Delivery Instructions</span>
        ),
        cell: (props) => props.row.original.notes,
      },
      {
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <Button
              type="link"
              href={`/fleets/delivery-allocations/${props.row.original.id}`}
              target="_blank"
              variant="icon"
              className="text-blue-500 hover:text-blue-600"
            >
              <EyeIcon aria-hidden="true" className="text-md h-4 w-4" />
              <span className="sr-only">
                View, {props.row.original.orderNumber}
              </span>
            </Button>
          </div>
        ),
      },
    ],
    [t]
  );

  return (
    <Fragment>
      <Loader loading={loading} />
      <Transition.Root show={showView} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseView}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="mb-4 border-b border-gray-200 p-6">
                        <div className="flex items-center justify-between">
                          <div className="mr-3 flex items-center justify-between">
                            <Dialog.Title className="flex items-start justify-between text-xl font-medium text-gray-900">
                              Assignment - {assignmentId}
                            </Dialog.Title>
                          </div>
                          <div className="flex items-center space-x-4">
                            <Button
                              border
                              onClick={() => {
                                setShowEdit(true);
                              }}
                            >
                              <PencilIcon
                                className="mr-2 h-5 w-5 text-current"
                                aria-hidden="true"
                              />
                              Edit
                            </Button>
                            <Button
                              variant="secondary"
                              border
                              onClick={handleWatch}
                              loading={
                                loadingCreateWatcher || loadingDeleteWatcher
                              }
                            >
                              <span className={classNames("relative mr-2")}>
                                <BellIcon className="h-5 w-5" />
                                <span
                                  className={classNames(
                                    "absolute left-1/2 top-1/2 h-[3px] w-6 -translate-x-1/2 -translate-y-1/2 rotate-45 transform rounded-full border border-white bg-gray-800",
                                    isWatching ? "block" : "hidden"
                                  )}
                                />
                              </span>
                              <span>{isWatching ? "Unwatch" : "Watch"}</span>
                            </Button>
                            <Button
                              onClick={handleUnassignVehicle}
                              loading={loadingDelete}
                            >
                              <span className="relative mr-2 after:absolute after:right-0.5 after:top-1.5 after:h-px after:w-2 after:bg-white after:content-['']">
                                <TruckIcon
                                  className="mr-2 h-5 w-5 text-current"
                                  aria-hidden="true"
                                />
                              </span>
                              Unassign Vehicle
                            </Button>

                            <button
                              type="button"
                              className="appearance-none rounded-md text-primary-900 transition-colors hover:text-primary-900 focus:outline-none focus-visible:border-primary-800 focus-visible:ring-4 focus-visible:ring-primary-50"
                              onClick={onCloseView}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      {loading || !activeAssignment ? (
                        <Waiting />
                      ) : (
                        <div className="flex-1 overflow-y-auto px-6 pb-6">
                          <div className="relative mt-6 space-y-4 md:space-y-6">
                            {vehicleBasic ? (
                              <div className="grid grid-cols-2 gap-4">
                                <span>Assigned Vehicle</span>
                                <VehicleThumb {...vehicleBasic} />
                              </div>
                            ) : null}
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_operator")}</span>
                              <div>
                                <FieldDriver
                                  title={t("text_operator")}
                                  value={parseInt(activeAssignment.operator.id)}
                                  isPreview
                                />
                              </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_delivery_allocation")}</span>
                              <p>{activeAssignment.deliveryRun.name}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_dock")}</span>
                              <p>{activeAssignment.deliveryRun.dock.name}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_start_date_time")}</span>
                              <p>
                                {activeAssignment.startDate
                                  ? formatDateTime(activeAssignment.startDate)
                                  : "-"}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_end_date_time")}</span>
                              <p>
                                {activeAssignment.endDate
                                  ? formatDateTime(activeAssignment.endDate)
                                  : "-"}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_duration")}</span>
                              <p>{activeAssignment.duration}</p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_starting_odometer")}</span>
                              <p>
                                {activeAssignment.startingOdometer}{" "}
                                {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_ending_odometer")}</span>
                              <p>
                                {activeAssignment.endingOdometer}{" "}
                                {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_usage")}</span>
                              <p>
                                {activeAssignment.usage}{" "}
                                {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                              </p>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                              <span>{t("text_comments")}</span>
                              <p>{activeAssignment.comments}</p>
                            </div>
                            <div>
                              <Button
                                border
                                onClick={handleViewDeliveryAllocations}
                              >
                                <EyeIcon className="mr-2 h-5 w-5 text-current" />
                                View Delivery Allocations
                              </Button>
                            </div>
                            <hr />
                            <Comments
                              assignmentId={activeAssignment.id}
                              messages={activeAssignment.messages}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={showEdit} as={Fragment} appear>
        <Dialog as="div" className="relative z-10" onClose={onCloseEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  initialValues={{
                    vehicleId: vehicleBasic ? vehicleBasic.id : null,
                    operatorId: activeAssignment?.operator
                      ? parseInt(activeAssignment.operator.id)
                      : vehicleBasic?.operator
                      ? parseInt(vehicleBasic.operator.id)
                      : null,
                    deliveryRunId: deliveryRunId ?? null,
                    startDate: startDate
                      ? startDate
                      : activeAssignment?.startDate
                      ? activeAssignment.startDate
                      : currentDate ?? "",
                    startingOdometer:
                      activeAssignment?.startingOdometer ?? null,
                    endDate: endDate
                      ? endDate
                      : activeAssignment?.endDate
                      ? activeAssignment.endDate
                      : currentDate ?? "",
                    endingOdometer: activeAssignment?.endingOdometer ?? null,
                    duration: activeAssignment?.duration ?? "",
                    usage: activeAssignment?.usage ?? null,
                    comments: activeAssignment?.comments ?? "",
                    status: activeAssignment?.status ?? 0,
                  }}
                  vehicleBasic={vehicleBasic}
                  onSubmit={handleAssignment}
                  onCancel={onCloseEdit}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={openDeliveryAllocation} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpenDeliveryAllocation(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-6xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <div className="flex h-full flex-col divide-y divide-gray-200">
                  <div className="h-0 flex-1">
                    <div className="px-4 pb-6 pt-8 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="mb-2 text-xl font-medium text-primary-900">
                          Delivery Allocations
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                            onClick={() => {
                              setOpenDeliveryAllocation(false);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">
                          Delivery Allocations for{" "}
                          {activeAssignment?.operator.name}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="px-4 pb-4 sm:px-6 sm:pb-6">
                        <TableCursor
                          data={activeDeliveryAllocations}
                          columns={deliveryAllocationcolumns}
                          loading={loading}
                          totalRows={activeDeliveryAllocations.length || 0}
                          getCoreRowModel={getCoreRowModel()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
}
