import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import * as Yup from "yup";

import { Waiting } from "../../../animations";
import { Head } from "../../../components/core";
import {
  Button,
  Field,
  FieldImage,
  selectStyles,
  SelectWrapper,
} from "../../../components/form";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  classNames,
  orderOptions,
  toCleanString,
  toNestedOptions,
} from "../../../utils";

enum DeviceType {
  MOBILE = 1,
  DESKTOP = 2,
}
enum ContentPosition {
  TOP_LEFT = "top-left",
  TOP_CENTER = "top-center",
  TOP_RIGHT = "top-right",
  CENTER_LEFT = "center-left",
  CENTER_CENTER = "center-center",
  CENTER_RIGHT = "center-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_CENTER = "bottom-center",
  BOTTOM_RIGHT = "bottom-right",
}
type HomeOptions = {
  id: string;
  title: string;
  caption: string;
  button: {
    title: string;
    link: string;
  }[];
  bannerImageUrl: string;
  categoryIds: number[];
  deviceType: DeviceType;
  contentPosition: ContentPosition;
};

const STORE_HOME_FRAGMENT = gql`
  fragment StoreHomeFragment on CustomerStoreHomePage {
    id
    bannerImageUrl
    title
    caption
    button
    categories {
      id
      name
    }
    contentPosition
    deviceType
  }
`;

const FETCH_STOREHOME = gql`
  ${STORE_HOME_FRAGMENT}
  query FetchStoreHome {
    fetchStoreHome {
      ...StoreHomeFragment
    }
  }
`;

const CREATE_STOREHOME = gql`
  mutation HomePageCreate(
    $title: String!
    $caption: String!
    $button: [JSON!]
    $bannerImageUrl: String
    $categoryIds: [Int!]
    $deviceType: Int
    $contentPosition: String
  ) {
    homePageCreate(
      input: {
        params: {
          title: $title
          caption: $caption
          button: $button
          bannerImageUrl: $bannerImageUrl
          categoryIds: $categoryIds
          deviceType: $deviceType
          contentPosition: $contentPosition
        }
      }
    ) {
      home {
        ...StoreHomeFragment
      }
    }
  }
`;

const UPDATE_STOREHOME = gql`
  mutation HomePageUpdate(
    $id: ID!
    $title: String!
    $caption: String!
    $button: [JSON!]
    $bannerImageUrl: String
    $categoryIds: [Int!]
    $deviceType: Int
    $contentPosition: String
  ) {
    homePageUpdate(
      input: {
        id: $id
        params: {
          title: $title
          caption: $caption
          button: $button
          bannerImageUrl: $bannerImageUrl
          categoryIds: $categoryIds
          deviceType: $deviceType
          contentPosition: $contentPosition
        }
      }
    ) {
      home {
        ...StoreHomeFragment
      }
    }
  }
`;

const HomeSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(80, "Too Long!")
    .required("Required"),
  caption: Yup.string().required("Required"),
  button: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      link: Yup.string(),
    })
  ),
  bannerImageUrl: Yup.string(),
  categoryIds: Yup.array(Yup.number()).required("Required"),
  deviceType: Yup.number().required("Required"),
  contentPosition: Yup.string()
    .min(2, "Please select content position")
    .required("Required"),
});

export default function Home({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) {
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();

  const { data, loading } = useQuery(FETCH_STOREHOME);

  const homeOptions: HomeOptions = useMemo(() => data?.fetchStoreHome, [data]);

  const [createHomePage, { loading: loadingCreate }] = useMutation(
    CREATE_STOREHOME,
    {
      refetchQueries: [
        {
          query: FETCH_STOREHOME,
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const [updateHomePage, { loading: loadingUpdate }] = useMutation(
    UPDATE_STOREHOME,
    {
      refetchQueries: [
        {
          query: FETCH_STOREHOME,
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const formik = useFormik({
    initialValues: {
      id: homeOptions?.id ?? null,
      title: homeOptions?.title ?? "",
      caption: homeOptions?.caption ?? "",
      button: homeOptions?.button ?? [
        {
          title: "",
          link: "",
        },
      ],
      bannerImageUrl: homeOptions?.bannerImageUrl ?? "",
      categoryIds: homeOptions?.categoryIds ?? [],
      deviceType: homeOptions?.deviceType ?? DeviceType.MOBILE,
      contentPosition: homeOptions?.contentPosition ?? ContentPosition.TOP_LEFT,
    },
    enableReinitialize: true,
    validationSchema: HomeSchema,
    onSubmit: (
      values: {
        id: string;
        title: string;
        caption: string;
        button: { title: string; link: string }[];
        bannerImageUrl: string;
        categoryIds: number[];
        deviceType: DeviceType;
        contentPosition: ContentPosition;
      },
      actions
    ) => {
      if (!values.id) {
        createHomePage({
          variables: {
            title: values.title,
            caption: values.caption,
            button: values.button,
            bannerImageUrl: values.bannerImageUrl,
            categoryIds: values.categoryIds,
            deviceType: values.deviceType,
            contentPosition: values.contentPosition,
          },
        })
          .then(({ data }) => {
            if (data?.homePageCreate?.home) {
              addNotify({
                type: NotifyType.SUCCESS,
                title: "Home Settings created successfully",
              });
            } else {
              addNotify({
                type: NotifyType.ERROR,
                title: "Something went wrong, please try again later",
              });
            }
          })
          .catch((error) => {
            actions.setSubmitting(false);
            addNotify({
              type: NotifyType.ERROR,
              title: error.message,
            });
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
        return;
      }

      updateHomePage({
        variables: {
          id: values.id,
          title: values.title,
          caption: values.caption,
          button: values.button,
          bannerImageUrl: values.bannerImageUrl,
          categoryIds: values.categoryIds,
          deviceType: values.deviceType,
          contentPosition: values.contentPosition,
        },
      })
        .then(({ data }) => {
          if (data?.homePageUpdate?.home) {
            addNotify({
              type: NotifyType.SUCCESS,
              title: "Home Settings updated successfully",
            });
          } else {
            addNotify({
              type: NotifyType.ERROR,
              title: "Something went wrong, please try again later",
            });
          }
        })
        .catch((error) => {
          addNotify({
            type: NotifyType.ERROR,
            title: error.message,
          });
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const deviceTypes = [
    {
      label: "Mobile",
      value: 1,
    },
    {
      label: "Desktop",
      value: 2,
    },
  ];

  return (
    <>
      <Head
        title="Home Settings"
        heading="Home Settings"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Home Settings",
            href: "/store/home",
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish px-4 py-5 sm:p-6">
          {loading ? (
            <Waiting />
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
                <div className="col-span-12 sm:col-span-6 md:col-span-6">
                  <Field
                    title={t("text_title")}
                    name="title"
                    onChange={(e) => {
                      const { value } = e.target;
                      formik.setFieldValue("title", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    touched={formik.touched.title}
                    errors={formik.errors.title}
                    placeholder="eg: Hello again,"
                  />
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-6">
                  <Field
                    title={t("text_caption")}
                    name="caption"
                    type="textarea"
                    onChange={(e) => {
                      const { value } = e.target;
                      formik.setFieldValue("caption", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.caption}
                    touched={formik.touched.caption}
                    errors={formik.errors.caption}
                    placeholder="eg: Alpha is your digital fresh produce market, allowing you to do all of your fresh produce shopping from the comfort of your couch."
                  />
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-6">
                  <div className="mb-1 text-sm font-medium text-gray-900">
                    {t("text_button")}
                  </div>
                  <FieldButton
                    value={formik.values.button}
                    onChange={(value) => {
                      formik.setFieldValue("button", value);
                    }}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-6">
                  <FieldImage
                    title={t("text_image")}
                    onChange={(value) => {
                      formik.setFieldValue("bannerImageUrl", value);
                    }}
                    value={formik.values.bannerImageUrl}
                    touched={formik.touched.bannerImageUrl}
                    errors={formik.errors.bannerImageUrl}
                  />
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-6">
                  <div className="mb-1 text-sm font-medium text-gray-900">
                    {t("text_device_type")}
                  </div>
                  <div className="space-x-4">
                    {deviceTypes.map((type, typeIdx) => (
                      <Field
                        key={`deviceType-${type.value}`}
                        title={type.label}
                        id={`deviceType-${type.value}`}
                        name="deviceType"
                        type="radio"
                        onChange={(e) => {
                          formik.setFieldValue(
                            "deviceType",
                            parseInt(e.target.value)
                          );
                        }}
                        value={String(type.value)}
                        checked={type.value === formik.values.deviceType}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-6">
                  <div className="mb-1 text-sm font-medium text-gray-900">
                    {t("text_content_position")}
                  </div>
                  <FieldContentPosition
                    value={formik.values.contentPosition}
                    onChange={(value) => {
                      formik.setFieldValue("contentPosition", value);
                    }}
                    className={classNames(
                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      formik.touched.contentPosition &&
                        formik.errors.contentPosition
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                  />
                  {formik.touched.contentPosition &&
                  formik.errors.contentPosition ? (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="contentPosition-errors"
                    >
                      {formik.errors.contentPosition.toString()}
                    </p>
                  ) : null}
                </div>

                <div className="col-span-12 sm:col-span-6 md:col-span-6">
                  <label className="block text-sm font-medium text-gray-900">
                    {t("text_category")}
                  </label>
                  <FieldCategory
                    value={formik.values.categoryIds}
                    onChange={(value: MultiValue<OptionProps>) => {
                      const categoryIds = value.flatMap((v) =>
                        parseInt(v.value)
                      );
                      formik.setFieldValue("categoryIds", categoryIds);
                    }}
                    className={classNames(
                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      formik.touched.categoryIds && formik.errors.categoryIds
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                  />
                  {formik.touched.categoryIds && formik.errors.categoryIds ? (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="categoryIds-errors"
                    >
                      {formik.errors.categoryIds.toString()}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
                <Button
                  variant="primary"
                  type="submit"
                  className="justify-center md:w-36"
                  loading={loadingCreate || loadingUpdate}
                  disabled={
                    formik.isSubmitting || loadingCreate || loadingUpdate
                  }
                >
                  {formik.isSubmitting
                    ? t("text_processing")
                    : formik.values.id
                    ? t("text_update")
                    : t("text_create")}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export const HomeResource: ResourceProps = {
  name: "Home Settings",
  description: "Home Settings for the store",
  access: ["read-settings"],
  path: "home",
};

const FETCH_CATEGORIES = gql`
  query FetchCategories($status: Boolean) {
    fetchCategories(status: $status) {
      id
      name
      parent {
        id
        name
      }
      status
    }
  }
`;

export function FieldCategory({
  value,
  onChange,
  className,
}: {
  value: number[];
  onChange: (newValue: MultiValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<MultiValue<OptionProps>>([]);

  const { data, loading, refetch } = useQuery(FETCH_CATEGORIES, {
    variables: {
      status: true,
    },
  });

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchCategories) {
      const fetchedCategories: OptionsData[] = data.fetchCategories;
      const optionsWithDepth: Array<OptionsData & { depth?: number }> =
        toNestedOptions(fetchedCategories);
      const updatedOptions = optionsWithDepth?.map((p) => {
        return {
          label: `${Array.from(
            Array(typeof p.depth === "number" ? p.depth : 0).keys()
          )
            .map((_) => "-")
            .join("")} ${p.name}`,
          value: p.id,
        };
      });

      return updatedOptions;
    }
    return [];
  }, [data]);

  useEffect(() => {
    const activeOptionsIds = value;
    const activeOptions = options.filter((v) =>
      activeOptionsIds.includes(Number(v.value))
    );
    const updatedValues = activeOptions.map((v) => {
      return {
        label: toCleanString(v.label),
        value: v.value,
      };
    });
    setValues(updatedValues);
  }, [options, value]);

  const handleChange = (
    newValue: MultiValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(orderOptions(newValue));
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={handleChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

type IButtonAttr = {
  title: string;
  name: string;
  value: string;
};
type IButtonValue = { title: string; link: string };

export function FieldButton({
  value,
  onChange,
}: {
  value: IButtonValue[];
  onChange: (newValue: IButtonValue[]) => void;
}) {
  const [values, setValues] = useState<IButtonValue[]>([]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <div>
      {values.map((v, i) => (
        <ButtonProperty
          key={i}
          value={v}
          onChange={(newValue) => {
            const updatedValues = values.map((p, j) => {
              if (i === j) {
                return newValue;
              }
              return p;
            });
            setValues(updatedValues);
            onChange(updatedValues);
          }}
        />
      ))}
    </div>
  );
}

function ButtonProperty({
  value,
  onChange,
}: {
  value: IButtonValue;
  onChange: (newValue: IButtonValue) => void;
}) {
  const { t } = useTranslation();

  const [values, setValues] = useState<IButtonAttr[]>([
    {
      title: t("text_title"),
      name: "title",
      value: value.title,
    },
    {
      title: t("text_link"),
      name: "link",
      value: value.link,
    },
  ]);

  useEffect(() => {
    setValues((prev) =>
      prev.map((v) => {
        return {
          ...v,
          value: v.name === "title" ? value.title : value.link,
        };
      })
    );
  }, [value]);

  const handleUpdate = (index: number, value: string) => {
    const newValues = values.map((p, j) => {
      if (index === j) {
        return {
          ...p,
          value,
        };
      }
      return p;
    });
    setValues(newValues);
    onChange({
      title: newValues[0].value,
      link: newValues[1].value,
    });
  };

  return (
    <div className="grid grid-cols-2 gap-6">
      {values.map((v, i) => {
        return (
          <div key={i}>
            <Field
              title={v.title}
              name={v.name}
              onChange={(e) => {
                const { value } = e.target;
                handleUpdate(i, value);
              }}
              value={v.value}
              isLabel={false}
              placeholder={v.title}
            />
          </div>
        );
      })}
    </div>
  );
}

export function FieldContentPosition({
  value,
  onChange,
  className,
}: {
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Top Left", value: ContentPosition.TOP_LEFT },
      { label: "Top Center", value: ContentPosition.TOP_CENTER },
      { label: "Top Right", value: ContentPosition.TOP_RIGHT },
      { label: "Center Left", value: ContentPosition.CENTER_LEFT },
      { label: "Center Center", value: ContentPosition.CENTER_CENTER },
      { label: "Center Right", value: ContentPosition.CENTER_RIGHT },
      { label: "Bottom Left", value: ContentPosition.BOTTOM_LEFT },
      { label: "Bottom Center", value: ContentPosition.BOTTOM_CENTER },
      { label: "Bottom Right", value: ContentPosition.BOTTOM_RIGHT },
    ],
    []
  );

  useEffect(() => {
    const activeOption = options.find((v) => v.value === value);
    setValues(activeOption || null);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(newValue) => {
          setValues(newValue);
          onChange(newValue?.value ? newValue.value : "");
        }}
      />
    </SelectWrapper>
  );
}
