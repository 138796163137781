import { useLazyQuery } from "@apollo/client";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";

import {
  DepartmentZonepicker,
  GET_DEPARTMENT_ZONEPICKERS,
} from "../../graphql/user-management/assign-zone-pickers";
import { Avatar, ButtonPopover, PopoverPosition } from "../appearance";
import { selectStyles, SelectWrapper } from ".";

type FieldDepartmentPickerProps = {
  title: string;
  departmentId: string;
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
  isPreview?: boolean;
  position?: PopoverPosition;
  loading?: boolean;
  disabled?: boolean;
};
export function FieldDepartmentPicker({
  title,
  departmentId,
  value,
  onChange,
  className,
  isPreview = false,
  position = PopoverPosition.RIGHT,
  loading: initialLoading,
  disabled: initialDisabled,
}: FieldDepartmentPickerProps) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);

  const [fetchingPickers, { data, loading, refetch }] = useLazyQuery<{
    fetchDepartment: DepartmentZonepicker;
  }>(GET_DEPARTMENT_ZONEPICKERS);

  useEffect(() => {
    if (departmentId) {
      fetchingPickers({
        variables: { id: departmentId },
      });
    }
  }, [departmentId, fetchingPickers]);

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchDepartment) {
      return data.fetchDepartment.pickers.map((picker) => ({
        label: picker.fullName,
        value: picker.id,
      }));
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (!value) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  if (isPreview) {
    if (value) {
      const user = data?.fetchDepartment.pickers.find(
        (p) => p.id === value.toString()
      );
      if (user) {
        return (
          <ButtonPopover
            title={user.fullName}
            child={
              <div className="flex items-center">
                <Avatar firstName={user.firstName} lastName={user.lastName} />
                <span className="ml-2">{user.fullName}</span>
              </div>
            }
            position={position}
          >
            <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
              <h3 className="mb-1 font-medium">{user.fullName}</h3>
              <p>{user.email}</p>
              <p>{user.phoneNumber}</p>
            </div>
            <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
              <Link
                to={`/user-management/users/${user.id}`}
                target="_blank"
                className="block text-center text-sm text-gray-500"
              >
                View {title}
              </Link>
            </div>
          </ButtonPopover>
        );
      }
    }
    return <Fragment>-</Fragment>;
  }

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
        isLoading={loading || initialLoading}
        isDisabled={initialDisabled}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
