import { classNames } from "../../utils";
export function Avatar({
  firstName,
  lastName,
  image,
  className = "",
}: {
  firstName: string | undefined;
  lastName?: string | undefined;
  image?: string | undefined;
  className?: string;
}) {
  let userLetter = "";
  if (firstName && lastName) {
    userLetter = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  } else if (firstName) {
    userLetter = firstName.charAt(0);
  } else if (lastName) {
    userLetter = lastName.charAt(0);
  } else {
    userLetter = "U";
  }
  return (
    <span
      className={classNames(
        "inline-flex h-10 w-10 items-center justify-center rounded-full bg-primary-700 text-white",
        className
      )}
    >
      {image ? (
        <img src={image} alt={`${firstName} ${lastName}`} />
      ) : (
        <span className="font-medium leading-none text-current">
          {userLetter}
        </span>
      )}
    </span>
  );
}
