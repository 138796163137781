import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  ADD_LOCATION_TYPE,
  EDIT_LOCATION_TYPE,
  GET_LOCATION_TYPES,
  LocationType,
  REMOVE_LOCATION_TYPE,
} from "../../../graphql/inventory/location-types";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { formatDate } from "../../../utils";
import { AuthShield } from "../../auth/core";
import Form from "./components/Form";

const LocationTypeList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);

  const { data, loading, error, refetch } = useQuery<{
    fetchBinLocationTypes: LocationType[];
  }>(GET_LOCATION_TYPES);
  const locationTypes = useMemo<LocationType[]>(
    () =>
      data?.fetchBinLocationTypes.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data]
  );

  const [activeLocationType, setActiveLocationType] = useState<
    LocationType | undefined
  >(undefined);
  const [newLocationType, setNewLocationType] = useState<boolean | undefined>(
    undefined
  );

  const [updateLocationType] = useMutation(EDIT_LOCATION_TYPE, {
    refetchQueries: [{ query: GET_LOCATION_TYPES }],
  });
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateLocationType({
      variables: {
        id: activeLocationType?.id,
        name: values.name,
        description: "",
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.binLocationTypeUpdate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Location Type updated successfully",
          });
          setActiveLocationType(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location Type update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Location Type update failed",
          message: error.message,
        });
      });
  };

  const [createLocationType] = useMutation(ADD_LOCATION_TYPE, {
    refetchQueries: [{ query: GET_LOCATION_TYPES }],
  });
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createLocationType({
      variables: {
        name: values.name,
        description: "",
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.binLocationTypeCreate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Location Type created successfully",
          });
          setNewLocationType(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location Type create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Location Type create failed",
          message: error.message,
        });
      });
  };

  const [deleteLocationType] = useMutation(REMOVE_LOCATION_TYPE, {
    refetchQueries: [{ query: GET_LOCATION_TYPES }],
  });
  const handleDelete = async (id: string) => {
    deleteLocationType({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.binLocationTypeDelete) {
          const deletedLocationType = data.binLocationTypeDelete?.type;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Location Type ${deletedLocationType.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Location Type deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Location Type deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<LocationType, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-binlocationtypes"]}>
              <Button
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
                onClick={() => setActiveLocationType(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-binlocationtypes"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Location Types"
        heading="Location Types"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: LocationTypeListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {LocationTypeListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {LocationTypeListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-binlocationtypes"]}>
              <Button onClick={() => setNewLocationType(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add location type
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={locationTypes}
          columns={columns}
          loading={loading}
          totalRows={locationTypes.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No location types found"
          fallbackText="You can add a new location type by clicking on add location type button."
        />
      </div>

      <Transition.Root
        show={activeLocationType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveLocationType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Location Type - "
                  initialValues={{
                    id: activeLocationType?.id,
                    name: activeLocationType?.name ?? "",
                    status: activeLocationType?.status ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveLocationType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newLocationType ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewLocationType(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Location Type - "
                  initialValues={{
                    name: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewLocationType(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default LocationTypeList;
export const LocationTypeListResource: ResourceProps = {
  name: "Location Types",
  description: "A list of location types",
  access: ["read-binlocationtypes"],
  path: "location-types",
};
