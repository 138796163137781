import { useMutation } from "@apollo/client/react";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  ADD_CUSTOMER,
  CustomerType,
  GET_CUSTOMERS,
} from "../../../graphql/sales/customers";
import Form from "./components/Form";

type TradingAddress = {
  address: string;
  suburb: string;
  state: string;
  postcode: string;
  primaryAddress: boolean;
};

const CustomerCreate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();

  const [createCustomer] = useMutation(ADD_CUSTOMER, {
    refetchQueries: [{ query: GET_CUSTOMERS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCustomer({
      variables: {
        customerName:
          values.customerType === CustomerType.COMPANY
            ? values.companyName
            : values.customerName,
        companyName:
          values.customerType === CustomerType.COMPANY
            ? values.companyName
            : values.customerName,
        profileImageUrl: values.profileImageUrl,
        customerType: values.customerType,
        email: values.email,
        storeContact: values.storeContact,
        storeEmail: values.storeEmail,
        storePhoneNumber: values.storePhoneNumber,
        storeFax: values.storeFax,
        abnNo: values.abnNo,
        customerPlu: values.customerPlu,
        packingInstruction: values.packingInstruction,
        deliveryInstruction: values.deliveryInstruction,
        salesRepId: values.salesRep ? parseInt(values.salesRep.value) : null,
        deliveryRunId: values.deliveryRun
          ? parseInt(values.deliveryRun.value)
          : null,
        creditApplicationStatus: values.creditApplicationStatus,
        creditLimit: values.creditLimit,
        customerGroupIds: values.customerGroups
          ? values.customerGroups.map((item: any) => parseInt(item.value))
          : [],
        tradingAddresses:
          values.tradingAddresses.map((a: TradingAddress) => ({
            address: a.address,
            suburb: a.suburb,
            state: a.state,
            postcode: a.postcode,
            primaryAddress: a.primaryAddress,
          })) ?? [],
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Customer created successfully",
          });
          return navigate(`/sales/customers`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Customer creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Customer creation failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title="Create Customer"
        heading="Customers"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customers",
            href: "/sales/customers",
          },
          {
            name: CustomerCreateResource.name,
            href: null,
          },
        ]}
      />
      <Form
        initialValues={{
          customerCode: "",
          customerName: "",
          companyName: "",
          profileImageUrl: "",
          customerType: 1,
          email: "",
          storeContact: "",
          storeEmail: "",
          storePhoneNumber: "",
          storeFax: "",
          abnNo: "",
          customerPlu: "",
          packingInstruction: "",
          deliveryInstruction: "",
          salesRep: null,
          deliveryRun: null,
          creditApplicationStatus: false,
          creditLimit: 0,
          customerGroups: [],
          tradingAddresses: [],
        }}
        customerStatus={0}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default CustomerCreate;
export const CustomerCreateResource: ResourceProps = {
  name: "Create Customer",
  description: "Create a new customer",
  access: ["create-customers"],
  path: "customers/add",
};
