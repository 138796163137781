import { useMutation, useSubscription } from "@apollo/client";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import wavTone from "../../../../assets/tone.wav";
import { Avatar } from "../../../../components/appearance";
import { Button } from "../../../../components/form";
import {
  ADD_ASSIGNMENT_COMMENT,
  AssignmentComment,
  SUBSCRIPTION_ASSIGNMENT_COMMENTS,
} from "../../../../graphql/fleets/vehicle-assignments";
import { classNames, formatDateTime } from "../../../../utils";
import { useAuth } from "../../../auth";
import { CardMessage } from "./CardMessage";

export function Comments({
  assignmentId,
  messages: messagesProps,
}: {
  assignmentId: string;
  messages: AssignmentComment[];
}) {
  const { t } = useTranslation();
  // const bottomRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useAuth();

  const [createAssignmentComment] = useMutation(ADD_ASSIGNMENT_COMMENT);
  const [comments, setComments] = useState<AssignmentComment[]>([]);

  useEffect(() => {
    if (messagesProps) {
      const updatedComments = messagesProps.map((comment) => ({
        ...comment,
        createdAt: formatDateTime(comment.createdAt),
      }));
      return setComments(updatedComments);
    }
    setComments([]);
  }, [messagesProps]);

  useSubscription<{
    assignmentComments: AssignmentComment;
  }>(SUBSCRIPTION_ASSIGNMENT_COMMENTS, {
    variables: {
      id: assignmentId,
    },
    onData({ data }) {
      if (data?.data?.assignmentComments) {
        const foundComment = comments.find(
          (comment) => comment.id === data?.data?.assignmentComments.id
        );
        if (foundComment) return;
        const updatedComment = data.data.assignmentComments;
        setComments((prev) => [
          ...prev,
          {
            ...updatedComment,
            createdAt: formatDateTime(updatedComment.createdAt),
          },
        ]);

        if (currentUser && updatedComment.user.id !== currentUser.id) {
          // bottomRef.current?.scrollIntoView({ behavior: "smooth" });
          toast.custom(
            (t) => (
              <div
                className={`${
                  t.visible ? "animate-enter" : "animate-leave"
                } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
              >
                <div className="w-0 flex-1 p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <Avatar
                        firstName={updatedComment.user.firstName}
                        lastName={updatedComment.user.lastName}
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        {`${updatedComment.user.firstName} ${updatedComment.user.lastName}`}
                      </p>
                      <div
                        className="mt-1 text-sm text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: updatedComment.comments || "",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Close
                  </button>
                </div>
              </div>
            ),
            {
              duration: 100000,
            }
          );
          const tone = new Audio(wavTone);
          tone.addEventListener("canplaythrough", (event) => {
            tone.play();
          });
        }
      }
    },
    onError(error) {
      toast.error(error.message, {
        position: "bottom-center",
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values, actions) => {
      createAssignmentComment({
        variables: {
          assignmentId: parseInt(assignmentId),
          message: values.message,
        },
      })
        .then(async ({ data }) => {
          actions.setSubmitting(false);
          if (data?.assignmentCommentCreate) {
            toast.success("Comment added successfully!");
            formik.resetForm();
          } else {
            toast.error("Failed to comment!");
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
    },
  });

  return (
    <>
      <div className="relative">
        <div className="mt-6 font-medium md:mt-8 xl:mt-10">Comments</div>
        {comments.length > 0 ? (
          <div className="space-y-4 px-2 py-6 md:space-y-6 md:px-4 md:py-8 lg:px-6 xl:space-y-8 xl:py-10">
            {comments.map((item) => (
              <CardMessage key={`comment-${item.id}`} {...item} />
            ))}
          </div>
        ) : (
          <div className="py-10 md:py-16 xl:py-20">
            <p className="mx-auto max-w-sm text-center font-light">
              No comments yet for this assignment.
            </p>
          </div>
        )}

        <hr />
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6 flex items-start space-x-4 md:mt-8 xl:mt-10">
            <div className="flex-shrink-0">
              <Avatar
                firstName={currentUser?.firstName}
                lastName={currentUser?.lastName}
                className={classNames("ml-3 h-12 w-12")}
              />
            </div>
            <div className="min-w-0 flex-1">
              <div className="relative">
                <div className="overflow-hidden rounded-lg ring-1 ring-inset ring-gray-200 focus-within:ring-2 focus-within:ring-primary-600">
                  <label htmlFor="message" className="sr-only">
                    Add your comment
                  </label>
                  <textarea
                    rows={3}
                    name="message"
                    id="message"
                    className="block w-full resize-none border-0 bg-transparent px-3.5 py-3.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Add your comment..."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />

                  <div className="py-2" aria-hidden="true">
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-3 pl-3 pr-3">
                  <div className="flex items-center space-x-5"></div>
                  <div className="flex flex-shrink-0 space-x-2">
                    <Button
                      type="submit"
                      className="px-3 py-2 text-sm"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        <>
                          <ChatBubbleBottomCenterTextIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          {t("text_comment")}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              {formik.touched && formik.errors ? (
                <p className="mt-2 text-sm text-red-600" id={`message-errors`}>
                  {formik.errors.message}
                </p>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
