import "react-lazy-load-image-component/src/effects/black-and-white.css";

import { TrashIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Spinner } from "../../../animations";
import { Button } from "../../../components/form";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { deleteMedia } from "../core/MediaHelper";
import { MediaProps } from "../core/types";

export default function Preview({
  file,
  onDelete,
  onClick,
  onOpen,
}: {
  file: MediaProps;
  onDelete: (url: string) => void;
  onClick: (file: MediaProps) => void;
  onOpen: (file: MediaProps) => void;
}) {
  const { addNotify } = useNotifyContext();
  const [loading, setLoading] = useState(false);

  const handleRemove = useCallback(() => {
    if (!file)
      return addNotify({
        type: NotifyType.ERROR,
        message: "Invalid file path",
      });
    const path = file.url.split(".com/alphafresh/")[1];
    if (!path)
      return addNotify({
        type: NotifyType.ERROR,
        message: "Invalid file path",
      });
    setLoading(true);
    deleteMedia(path)
      .then((data: { status: boolean; msg: string }) => {
        if (data.status === true) {
          addNotify({
            type: NotifyType.SUCCESS,
            message: data.msg,
          });
          onDelete(file.url);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            message: data.msg || "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          message: error.message,
        });
      })
      .finally(() => setLoading(false));
  }, [addNotify, file, onDelete]);

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          onClick(file);
        }}
        className="relative appearance-none"
        aria-label="View image"
        title={file.title}
      >
        <LazyLoadImage
          src={file.url}
          alt={file.name}
          effect="black-and-white"
          className="rounded-md"
        />
        {loading && (
          <div className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2">
            <Spinner className="mx-0 text-primary-700" />
          </div>
        )}
      </button>
      <div className="mt-2 flex items-center justify-between">
        <span
          className="mr-2 block cursor-pointer truncate text-sm first-letter:uppercase"
          title={file.title}
          onClick={() => {
            onOpen(file);
          }}
        >
          {file.title}
        </span>
        <Button variant="icon" onClick={handleRemove} className="text-red-700">
          <TrashIcon className="h-4 w-4" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}
