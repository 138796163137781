import { useQuery } from "@apollo/client/react";
import { useEffect, useMemo, useState } from "react";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";

import { selectStyles, SelectWrapper } from "../../../../components/form";
import {
  type Category,
  GET_CATEGORIES,
} from "../../../../graphql/inventory/categories";
import {
  type Department,
  GET_DEPARTMENTS,
} from "../../../../graphql/inventory/departments";
import {
  GET_LOCATIONS,
  type Location,
} from "../../../../graphql/inventory/locations";
import {
  GET_VENDORS,
  type Vendor,
} from "../../../../graphql/purchases/vendors";
import {
  orderOptions,
  toCleanString,
  toNestedOptions,
} from "../../../../utils";

export function FieldCategory({
  value,
  // fixedValue,
  onChange,
  className,
}: {
  value: MultiValue<OptionProps>;
  // fixedValue?: string[];
  onChange: (newValue: MultiValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<MultiValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery<{
    fetchCategories: Category[];
  }>(GET_CATEGORIES, {
    variables: {
      status: true,
    },
  });

  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchCategories) {
      const fetchedCategories: OptionsData[] = data.fetchCategories;
      const optionsWithDepth: Array<OptionsData & { depth?: number }> =
        toNestedOptions(fetchedCategories);
      // const filteredOptions = optionsWithDepth?.filter(
      //   (p) => !fixedValue?.includes(p.id)
      // );
      return optionsWithDepth?.map((p) => {
        return {
          label: `${Array.from(
            Array(typeof p.depth === "number" ? p.depth : 0).keys()
          )
            .map((_) => "-")
            .join("")} ${p.name}`,
          value: p.id,
        };
      });
    }
    return [];
  }, [data?.fetchCategories]);

  // useEffect(() => {
  //   const activeOptionsIds: string[] = value.flatMap((v) => v.value);
  //   const activeOptions = value.filter((v) =>
  //     activeOptionsIds.includes(v.value)
  //   );
  //   const updatedValues = activeOptions.map((v) => {
  //     return {
  //       label: toCleanString(v.label),
  //       value: v.value,
  //       isFixed: fixedValue?.includes(v.value),
  //     };
  //   });
  //   setValues(updatedValues);
  // }, [value]);

  useEffect(() => {
    const updatedValues =
      value.map((v) => ({
        ...v,
        label: toCleanString(v.label),
      })) || [];
    setValues(updatedValues);
  }, [value]);

  const handleChange = (
    newValue: MultiValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        newValue = options.filter((v) => v.isFixed);
        break;
    }

    onChange(orderOptions(newValue));
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        isMulti
        onChange={handleChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

export function FieldDepartment({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery<{
    fetchDepartments: Department[];
  }>(GET_DEPARTMENTS, {
    variables: {
      status: true,
    },
  });
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchDepartments) {
      const fetchedDepartments: OptionsData[] = data.fetchDepartments;
      const optionsWithDepth: Array<OptionsData & { depth?: number }> =
        toNestedOptions(fetchedDepartments);
      return optionsWithDepth?.map((p) => {
        return {
          label: `${Array.from(
            Array(typeof p.depth === "number" ? p.depth : 0).keys()
          )
            .map((_) => "-")
            .join("")} ${p.name}`,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    let updatedValues = value;
    if (value) {
      const activeOption = options.find((o) => o.value === value.value);
      if (activeOption) {
        updatedValues = {
          label: toCleanString(activeOption.label),
          value: activeOption.value,
        };
      }
    }
    setValues(updatedValues);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

export function FieldBinLocation({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery<{
    fetchBinLocations: Location[];
  }>(GET_LOCATIONS, {
    variables: {
      status: true,
    },
  });
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchBinLocations) {
      const fetchedBinLocations: OptionsData[] = data.fetchBinLocations;
      const optionsWithDepth: Array<OptionsData & { depth?: number }> =
        toNestedOptions(fetchedBinLocations);
      return optionsWithDepth?.map((p) => {
        return {
          label: `${Array.from(
            Array(typeof p.depth === "number" ? p.depth : 0).keys()
          )
            .map((_) => "-")
            .join("")} ${p.name}`,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    let updatedValues = value;
    if (value) {
      const activeOption = options.find((o) => o.value === value.value);
      if (activeOption) {
        updatedValues = {
          label: toCleanString(activeOption.label),
          value: activeOption.value,
        };
      }
    }
    setValues(updatedValues);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

export function FieldVendor({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);

  const { data, loading, refetch } = useQuery<{
    fetchVendors: Vendor[];
  }>(GET_VENDORS, {
    variables: {
      status: true,
    },
  });
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchVendors) {
      return data.fetchVendors?.map((p: Vendor) => {
        return {
          label: p.companyName,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    setValues(value);
  }, [value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
        isLoading={loading}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}

export function FieldStatus({
  value,
  onChange,
  className,
}: {
  value: SingleValue<OptionProps>;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(value);
  const options = useMemo(
    () => [
      { label: "Active", value: "2" },
      { label: "Draft", value: "1" },
      { label: "Inactive", value: "0" },
    ],
    []
  );

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChange}
        isClearable
      />
    </SelectWrapper>
  );
}
