import { UniqueIdentifier } from "@dnd-kit/core";

export enum IFieldType {
  symbol,
  variable,
  number,
}

export interface IFormula {
  id: UniqueIdentifier | string;
  name: string;
  type: IFieldType;
  color: string;
  description: string;
}
