import { Widget } from "../../graphql/dashboard";
import { RecentActivity } from "./components/RecentActivity";
import { SalesActivity } from "./components/SalesActivity";
import { SalesRevenue } from "./components/SalesRevenue";
import { StockItems } from "./components/StockItems";
import { TopSellingProducts } from "./components/TopSellingProducts";

export function GridItem({
  handle,
  item,
  widgets,
  updateWidgets,
  setIsModified,
}: {
  handle: string;
  item: Widget;
  widgets: Widget[];
  updateWidgets: (widgets: Widget[]) => void;
  setIsModified: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  switch (handle) {
    case "sales-activity":
      return (
        <SalesActivity
          item={item}
          updateBlocks={(blocks) => {
            const updatedBlocks =
              blocks.length === item.blocks.length
                ? blocks
                : [
                    ...blocks,
                    ...item.blocks.filter(
                      (block) => !blocks.find((b) => b.id === block.id)
                    ),
                  ];

            const updatedWidgets = widgets.map((widget) => ({
              ...widget,
              blocks:
                widget.handle === handle
                  ? updatedBlocks.map((b, i) => ({ ...b, position: i + 1 }))
                  : widget.blocks,
            }));
            updateWidgets(updatedWidgets);
            setIsModified(true);
          }}
        />
      );

    case "sales-revenue":
      return <SalesRevenue item={item} />;

    case "top-selling-products":
      return <TopSellingProducts item={item} />;

    case "recent-activity":
      return <RecentActivity item={item} />;

    case "stock-items":
      return (
        <StockItems
          item={item}
          updateBlocks={(blocks) => {
            const updatedBlocks =
              blocks.length === item.blocks.length
                ? blocks
                : [
                    ...blocks,
                    ...item.blocks.filter(
                      (block) => !blocks.find((b) => b.id === block.id)
                    ),
                  ];

            const updatedWidgets = widgets.map((widget) => ({
              ...widget,
              blocks:
                widget.handle === handle
                  ? updatedBlocks.map((b, i) => ({ ...b, position: i + 1 }))
                  : widget.blocks,
            }));
            updateWidgets(updatedWidgets);
            setIsModified(true);
          }}
        />
      );

    default:
      return <div>{handle}</div>;
  }
}
