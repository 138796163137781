import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import { GET_CUSTOMERCONTACTS } from "../../../../graphql/sales/settings/customer-contacts";
import Form from "./components/Form";

type ContactDetails = {
  name: string;
  contactType: any;
  email: string;
  phoneNumber: string;
  sendSms: boolean;
};

const CustomerContactCreate = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CREATE_CUSTOMERCONTACT = gql`
    mutation CustomerContactCreate(
      $customerId: Int!
      $firstName: String
      $surName: String
      $phoneNumber: String
      $mobileNumber: String
      $postalAddress: String
      $suburb: String
      $state: String
      $postcode: String
      $contactDetails: [ContactdetailInput!]
      $emailSpecials: Boolean
      $emailImportantUpdates: Boolean
    ) {
      customerContactCreate(
        input: {
          params: {
            customerId: $customerId
            firstName: $firstName
            surName: $surName
            phoneNumber: $phoneNumber
            mobileNumber: $mobileNumber
            postalAddress: $postalAddress
            suburb: $suburb
            state: $state
            postcode: $postcode
            contactDetails: $contactDetails
            emailSpecials: $emailSpecials
            emailImportantUpdates: $emailImportantUpdates
          }
        }
      ) {
        customerContact {
          id
          contactDetails {
            id
            name
            contactType {
              id
              name
            }
            phoneNumber
            email
            sendSms
          }
          createdAt
          customer {
            id
            customerName
          }
          emailImportantUpdates
          emailSpecials
          firstName
          mobileNumber
          phoneNumber
          postalAddress
          postcode
          state
          suburb
          surName
        }
      }
    }
  `;
  const [createCustomerContact] = useMutation(CREATE_CUSTOMERCONTACT, {
    refetchQueries: [{ query: GET_CUSTOMERCONTACTS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createCustomerContact({
      variables: {
        customerId: values.customer ? parseInt(values.customer.value) : null,
        firstName: values.firstName,
        surName: values.surName,
        phoneNumber: values.phoneNumber,
        mobileNumber: values.mobileNumber,
        postalAddress: values.postalAddress,
        suburb: values.suburb,
        state: values.state,
        postcode: values.postcode,
        contactDetails: values.contactDetails
          ? values.contactDetails.map((item: ContactDetails) => ({
              name: item.name,
              contactTypeId: item.contactType
                ? parseInt(item.contactType.value)
                : null,
              email: item.email,
              phoneNumber: item.phoneNumber,
              sendSms: item.sendSms,
            }))
          : [],
        emailSpecials: values.emailSpecials,
        emailImportantUpdates: values.emailImportantUpdates,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.customerContactCreate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "CustomerContact created successfully",
          });
          return navigate(`/sales/settings/customer-contacts`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "CustomerContact creation failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "CustomerContact creation failed",
          message: error.message,
        });
      });
  };

  function FormHeader() {
    return (
      <div className="mb-4 hidden sm:flex sm:items-center md:mb-8">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-medium text-gray-900">
            {t("heading_create_customer_contact")}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {t("description_create_customer_contact")}
          </p>
        </div>
        <div className="mt-4 flex sm:ml-16 sm:mt-0">
          <Link to="/sales/settings/customer-contacts" className="mr-2 flex">
            <Button variant="secondary">{t("text_cancel")}</Button>
          </Link>
          <Button type="submit">{t("text_create")}</Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <Head
        title="Create Customer Contact"
        heading="Customer Contacts"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Customer Contacts",
            href: "/sales/settings/customer-contacts",
          },
          {
            name: CustomerContactCreateResource.name,
            href: null,
          },
        ]}
      />
      <Form
        initialValues={{
          customer: null,
          firstName: "",
          surName: "",
          phoneNumber: "",
          mobileNumber: "",
          postalAddress: "",
          suburb: "",
          state: "",
          postcode: "",
          contactDetails: [],
          emailSpecials: false,
          emailImportantUpdates: false,
        }}
        onSubmit={handleSubmit}
        header={<FormHeader />}
      />
    </>
  );
};

export default CustomerContactCreate;
export const CustomerContactCreateResource: ResourceProps = {
  name: "Create CustomerContact",
  description: "Create a new customer contact",
  access: ["create-customers"],
  path: "customer-contacts/add",
};
