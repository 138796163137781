import { gql } from "@apollo/client";

import { UserModel } from "../../../modules/auth/@types/auth";
import { DeliveryRun } from "../settings/delivery-runs";
import { Driver } from "../settings/drivers";
import { Vehicle, VEHICLE_WATCHER_FRAGMENT } from "../vehicles";

export const ASSIGNMENT_COMMENT_FRAGMENT = gql`
  fragment VehicleAssignmentMessageFragment on VehicleAssignmentMessage {
    id
    comments
    messageType
    driver {
      id
      name
    }
    user {
      id
      fullName
      firstName
      lastName
    }
    createdAt
  }
`;

export const VEHICLE_ASSIGNMENTS_FRAGMENT = gql`
  ${VEHICLE_WATCHER_FRAGMENT}
  fragment VehicleAssignmentFragment on VehicleAssignment {
    id
    comments
    deliveryRun {
      id
      name
      dock {
        id
        name
      }
    }
    duration
    endDate
    endingOdometer
    messages {
      id
      messageType
      comments
      driver {
        id
        name
      }
      user {
        id
        fullName
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
    operator {
      id
      name
      profileImageUrl
    }
    startDate
    startingOdometer
    status
    usage
    vehicle {
      id
      name
      vehicleImageUrl
      make {
        id
        name
      }
      model {
        id
        name
      }
      year
      status {
        id
        name
        color
      }
      operator {
        id
        name
      }
      watchers {
        ...VehicleWatcherFragment
      }
    }
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLE_ASSIGNMENTS = gql`
  ${VEHICLE_ASSIGNMENTS_FRAGMENT}
  query FetchVehicleAssignments(
    $fromDate: String
    $toDate: String
    $deliveryRunId: ID
  ) {
    fetchVehicleAssignments(
      fromDate: $fromDate
      toDate: $toDate
      deliveryRunId: $deliveryRunId
    ) {
      ...VehicleAssignmentFragment
    }
  }
`;

export const GET_VEHICLE_ASSIGNMENT_BY_ID = gql`
  ${VEHICLE_ASSIGNMENTS_FRAGMENT}
  query FetchVehicleAssignment($id: ID!) {
    fetchVehicleAssignment(id: $id) {
      ...VehicleAssignmentFragment
    }
  }
`;

export const CREATE_VEHICLE_ASSIGNMENT = gql`
  ${VEHICLE_ASSIGNMENTS_FRAGMENT}
  mutation VehicleAssignmentCreate(
    $vehicleId: Int!
    $operatorId: Int!
    $deliveryRunId: Int!
    $startDate: ISO8601DateTime!
    $startingOdometer: Int
    $endDate: ISO8601DateTime
    $endingOdometer: Int
    $duration: String
    $usage: Int
    $comments: String
    $status: Int!
  ) {
    vehicleAssignmentCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          operatorId: $operatorId
          deliveryRunId: $deliveryRunId
          startDate: $startDate
          startingOdometer: $startingOdometer
          endDate: $endDate
          endingOdometer: $endingOdometer
          duration: $duration
          usage: $usage
          comments: $comments
          status: $status
        }
      }
    ) {
      vehicleAssignment {
        ...VehicleAssignmentFragment
      }
    }
  }
`;

export const UPDATE_VEHICLE_ASSIGNMENT = gql`
  ${VEHICLE_ASSIGNMENTS_FRAGMENT}
  mutation VehicleAssignmentUpdate(
    $id: ID!
    $vehicleId: Int!
    $operatorId: Int!
    $deliveryRunId: Int!
    $startDate: ISO8601DateTime!
    $startingOdometer: Int
    $endDate: ISO8601DateTime
    $endingOdometer: Int
    $duration: String
    $usage: Int
    $comments: String
    $status: Int!
  ) {
    vehicleAssignmentUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          operatorId: $operatorId
          deliveryRunId: $deliveryRunId
          startDate: $startDate
          startingOdometer: $startingOdometer
          endDate: $endDate
          endingOdometer: $endingOdometer
          duration: $duration
          usage: $usage
          comments: $comments
          status: $status
        }
      }
    ) {
      vehicleAssignment {
        ...VehicleAssignmentFragment
      }
    }
  }
`;

export const DELETE_VEHICLE_ASSIGNMENT = gql`
  ${VEHICLE_ASSIGNMENTS_FRAGMENT}
  mutation VehicleAssignmentDelete($id: ID!) {
    vehicleAssignmentDelete(input: { id: $id }) {
      vehicleAssignment {
        ...VehicleAssignmentFragment
      }
    }
  }
`;

export const SUBSCRIPTION_ASSIGNMENT_COMMENTS = gql`
  ${ASSIGNMENT_COMMENT_FRAGMENT}
  subscription AssignmentComments($id: ID!) {
    assignmentComments(id: $id) {
      ...VehicleAssignmentMessageFragment
    }
  }
`;

export const ADD_ASSIGNMENT_COMMENT = gql`
  ${ASSIGNMENT_COMMENT_FRAGMENT}
  mutation AssignmentCommentCreate($assignmentId: Int!, $message: String!) {
    assignmentCommentCreate(
      input: { params: { assignmentId: $assignmentId, message: $message } }
    ) {
      assignmentComment {
        ...VehicleAssignmentMessageFragment
      }
    }
  }
`;

export enum AssignmentCommentType {
  READY,
  NORMAL,
  COMPLETED,
}
export type AssignmentComment = {
  id: string;
  messageType: AssignmentCommentType;
  comments: string;
  driver: Pick<Driver, "id" | "name">;
  user: Pick<UserModel, "id" | "fullName" | "firstName" | "lastName">;
  createdAt: string;
  updatedAt: string;
};

export type VehicleAssignment = {
  id: string;
  comments: string;
  deliveryRun: Pick<DeliveryRun, "id" | "name" | "dock">;
  duration: string;
  endDate: string;
  endingOdometer: number | null;
  messages: AssignmentComment[];
  operator: Pick<Driver, "id" | "name" | "profileImageUrl">;
  startDate: string;
  startingOdometer: number | null;
  status: number | null;
  usage: number | null;
  vehicle: Pick<
    Vehicle,
    | "id"
    | "name"
    | "vehicleImageUrl"
    | "status"
    | "operator"
    | "make"
    | "model"
    | "year"
    | "watchers"
  >;
  createdAt: string;
  updatedAt: string;
};

export type VehicleAssignmentForm = Pick<
  VehicleAssignment,
  | "startDate"
  | "startingOdometer"
  | "endDate"
  | "endingOdometer"
  | "duration"
  | "usage"
  | "comments"
  | "status"
> & {
  id?: string;
  vehicleId: number | null;
  operatorId: number | null;
  deliveryRunId: number | null;
};
