import { gql } from "@apollo/client";

import { Vehicle } from "../..";

export const VEHICLESPECS_FRAGMENT = gql`
  fragment VehicleSpecsFragment on VehicleSpecification {
    id
    bedLength
    compression
    curbWeight
    cylinders
    displacement
    engineBore
    engineBrand
    engineSummary
    epaCity
    epaCombined
    epaHighway
    fuelQuality
    fuelTankOneCapacity
    fuelTankTwoCapacity
    grossVehicleWeightRating
    groundClearance
    height
    interiorVolume
    length
    maxPayload
    maxTorque
    oilCapacity
    passengerVolume
    stroke
    towingCapacity
    valves
    width
    vehicle {
      id
    }
  }
`;
export const GET_VEHICLESPECS = gql`
  ${VEHICLESPECS_FRAGMENT}
  query FetchSpecsByVehicle($vehicleId: ID!) {
    fetchSpecsByVehicle(vehicleId: $vehicleId) {
      ...VehicleSpecsFragment
    }
  }
`;

export const ADD_VEHICLESPECS = gql`
  ${VEHICLESPECS_FRAGMENT}
  mutation VehicleSpecificationCreate(
    $bedLength: Float
    $compression: Float
    $curbWeight: Float
    $cylinders: Int
    $displacement: Float
    $engineBore: Float
    $engineBrand: String
    $engineSummary: String
    $epaCity: String
    $epaCombined: String
    $epaHighway: String
    $fuelQuality: Int
    $fuelTankOneCapacity: Float
    $fuelTankTwoCapacity: Float
    $grossVehicleWeightRating: Int
    $groundClearance: Int
    $height: Float
    $interiorVolume: Float
    $length: Float
    $maxPayload: Int
    $maxTorque: Float
    $oilCapacity: Float
    $passengerVolume: Int
    $stroke: Float
    $towingCapacity: Int
    $valves: Int
    $vehicleId: Int!
    $width: Float
  ) {
    vehicleSpecificationCreate(
      input: {
        params: {
          bedLength: $bedLength
          compression: $compression
          curbWeight: $curbWeight
          cylinders: $cylinders
          displacement: $displacement
          engineBore: $engineBore
          engineBrand: $engineBrand
          engineSummary: $engineSummary
          epaCity: $epaCity
          epaCombined: $epaCombined
          epaHighway: $epaHighway
          fuelQuality: $fuelQuality
          fuelTankOneCapacity: $fuelTankOneCapacity
          fuelTankTwoCapacity: $fuelTankTwoCapacity
          grossVehicleWeightRating: $grossVehicleWeightRating
          groundClearance: $groundClearance
          height: $height
          interiorVolume: $interiorVolume
          length: $length
          maxPayload: $maxPayload
          maxTorque: $maxTorque
          oilCapacity: $oilCapacity
          passengerVolume: $passengerVolume
          stroke: $stroke
          towingCapacity: $towingCapacity
          valves: $valves
          vehicleId: $vehicleId
          width: $width
        }
      }
    ) {
      vehicleSpecification {
        ...VehicleSpecsFragment
      }
    }
  }
`;

export const EDIT_VEHICLESPECS = gql`
  ${VEHICLESPECS_FRAGMENT}
  mutation VehicleSpecificationUpdate(
    $id: ID!
    $bedLength: Float
    $compression: Float
    $curbWeight: Float
    $cylinders: Int
    $displacement: Float
    $engineBore: Float
    $engineBrand: String
    $engineSummary: String
    $epaCity: String
    $epaCombined: String
    $epaHighway: String
    $fuelQuality: Int
    $fuelTankOneCapacity: Float
    $fuelTankTwoCapacity: Float
    $grossVehicleWeightRating: Int
    $groundClearance: Int
    $height: Float
    $interiorVolume: Float
    $length: Float
    $maxPayload: Int
    $maxTorque: Float
    $oilCapacity: Float
    $passengerVolume: Int
    $stroke: Float
    $towingCapacity: Int
    $valves: Int
    $vehicleId: Int!
    $width: Float
  ) {
    vehicleSpecificationUpdate(
      input: {
        id: $id
        params: {
          bedLength: $bedLength
          compression: $compression
          curbWeight: $curbWeight
          cylinders: $cylinders
          displacement: $displacement
          engineBore: $engineBore
          engineBrand: $engineBrand
          engineSummary: $engineSummary
          epaCity: $epaCity
          epaCombined: $epaCombined
          epaHighway: $epaHighway
          fuelQuality: $fuelQuality
          fuelTankOneCapacity: $fuelTankOneCapacity
          fuelTankTwoCapacity: $fuelTankTwoCapacity
          grossVehicleWeightRating: $grossVehicleWeightRating
          groundClearance: $groundClearance
          height: $height
          interiorVolume: $interiorVolume
          length: $length
          maxPayload: $maxPayload
          maxTorque: $maxTorque
          oilCapacity: $oilCapacity
          passengerVolume: $passengerVolume
          stroke: $stroke
          towingCapacity: $towingCapacity
          valves: $valves
          vehicleId: $vehicleId
          width: $width
        }
      }
    ) {
      vehicleSpecification {
        ...VehicleSpecsFragment
      }
    }
  }
`;

export type VehicleSpec = {
  id: string;
  bedLength: number | null;
  compression: number | null;
  curbWeight: number | null;
  cylinders: number | null;
  displacement: number | null;
  engineBore: number | null;
  engineBrand: string;
  engineSummary: string;
  epaCity: string;
  epaCombined: string;
  epaHighway: string;
  fuelQuality: number | null;
  fuelTankOneCapacity: number | null;
  fuelTankTwoCapacity: number | null;
  grossVehicleWeightRating: number | null;
  groundClearance: number | null;
  height: number | null;
  interiorVolume: number | null;
  length: number | null;
  maxPayload: number | null;
  maxTorque: number | null;
  oilCapacity: number | null;
  passengerVolume: number | null;
  stroke: number | null;
  towingCapacity: number | null;
  valves: number | null;
  vehicle: Vehicle;
  width: number | null;
};

export type VehicleSpecForm = Omit<VehicleSpec, "id" | "vehicle"> & {
  id?: string;
};
