import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { useMutation, useQuery } from "@apollo/client";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import {
  CogIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon, PuzzlePieceIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { Waiting } from "../../animations";
import { ErrorFallback, Head } from "../../components/core";
import { Button } from "../../components/form";
import {
  EDIT_USER_WIDGET,
  GET_DASHBOARD,
  Layouts,
  LayoutSlot,
  Widget,
} from "../../graphql/dashboard";
import HeaderLayout from "../../layouts/HeaderLayout";
import { classNames } from "../../utils";
import { GridLayout } from "./GridLayout";

const Dashboard = () => {
  const [isModified, setIsModified] = useState(false);
  const { data, error, loading, refetch } = useQuery(GET_DASHBOARD);
  const initialWidgets = useMemo<Widget[]>(
    () => data?.fetchDashboard ?? [],
    [data?.fetchDashboard]
  );
  const [widgets, setWidgets] = useState<Widget[]>([]);

  useEffect(() => {
    if (initialWidgets.length === 0) return;
    setWidgets(initialWidgets);
  }, [initialWidgets]);

  const [updateDashboard, { loading: updateLoading }] = useMutation(
    EDIT_USER_WIDGET,
    {
      refetchQueries: [{ query: GET_DASHBOARD }],
      awaitRefetchQueries: true,
    }
  );

  const handleSaveChanges = useCallback(() => {
    const updatedWidgets = widgets.map((widget) => ({
      widgetId: widget.id,
      position: widget.position,
      status: widget.status,
      blockValues: widget.blocks.length
        ? widget.blocks.map((b) => ({
            widgetId: widget.id,
            widgetBlockId: b.id,
            position: b.position,
            status: b.status,
          }))
        : null,
      slot: widget.slot,
    }));
    updateDashboard({
      variables: {
        params: updatedWidgets,
      },
    })
      .then(async ({ data }) => {
        if (data?.userWidgetUpdate?.message) {
          await refetch();
          setIsModified(false);
          toast.success("Widgets updated successfully.");
          return;
        }
        toast.error("Something went wrong.");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, [refetch, updateDashboard, widgets]);

  const [layouts, setLayouts] = useState<Layouts | undefined>(undefined);

  useEffect(() => {
    if (initialWidgets.length === 0) return;

    const _layouts: Layouts = {};
    for (const breakpoint of ["lg", "md", "sm", "xs", "xxs"]) {
      _layouts[breakpoint] = [];
      for (const widget of initialWidgets) {
        if (widget.slot[breakpoint]) {
          _layouts[breakpoint].push(widget.slot[breakpoint]);
        }
      }
    }
    setLayouts(_layouts);
  }, [initialWidgets]);

  const [querySearch, setQuerySearch] = useState("");
  const [openSearch, setOpenSearch] = useState(false);
  const handleAddWidget = useCallback(() => {
    setOpenSearch(true);
  }, []);

  function updateLayouts(_layouts: Layouts) {
    if (!_layouts) return;
    let updatedWidgets = [...widgets];
    updatedWidgets.forEach((widget) => {
      let updatedSlot: LayoutSlot = {};
      Object.keys(_layouts).forEach((key) => {
        let newSlot = _layouts[key].find((item) => item.i === widget.handle);
        if (newSlot) {
          updatedSlot[key] = newSlot;
        }
      });
      const indexWidget = updatedWidgets.findIndex(
        (item) => item.handle === widget.handle
      );
      if (indexWidget !== -1) {
        updatedWidgets[indexWidget] = {
          ...widget,
          slot: updatedSlot,
        };
      }
    });
    setWidgets(updatedWidgets);
    setLayouts(_layouts);
  }

  const updateWidgets = useCallback(
    (updatedWidgets: Widget[]) => {
      setIsModified(true);
      setWidgets(updatedWidgets);
    },
    [setWidgets]
  );

  const insertWidget = useCallback((widget: Widget) => {
    setWidgets((prev) => {
      const updatedWidgets = [...prev];
      const indexWidget = updatedWidgets.findIndex(
        (item) => item.id === widget.id
      );
      if (indexWidget !== -1) {
        updatedWidgets[indexWidget] = {
          ...updatedWidgets[indexWidget],
          status: true,
        };
      }
      return updatedWidgets;
    });
    setIsModified(true);
    setOpenSearch(false);
  }, []);

  const inactiveWidgets = useMemo<Widget[]>(() => {
    return widgets.filter((item) => item.status === false);
  }, [widgets]);

  if (error) return <ErrorFallback error={error} />;

  const filteredWidgets =
    querySearch === ""
      ? inactiveWidgets
      : inactiveWidgets.filter((widget) => {
          return widget.name.toLowerCase().includes(querySearch.toLowerCase());
        });

  return (
    <>
      <Head
        title={t("heading_dashboard")}
        heading={t("heading_dashboard")}
        description={t("description_dashboard")}
        breadcrumbs={[
          {
            name: "Alpha Fresh",
            href: "/",
          },
          {
            name: t("heading_dashboard"),
            href: "/dashboard",
          },
        ]}
      />

      <div className="mx-auto max-w-6xl px-4 pb-10 sm:px-6 lg:px-8">
        <HeaderLayout />
        {loading ? (
          <Waiting />
        ) : (
          <Fragment>
            <nav className="mb-4 flex justify-end space-x-2 py-2">
              {isModified && (
                <Button
                  type="button"
                  border
                  onClick={handleSaveChanges}
                  loading={updateLoading}
                >
                  <PuzzlePieceIcon className="mr-2 h-5 w-5" />
                  Save Changes
                </Button>
              )}
              <Button type="button" onClick={handleAddWidget}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add Widget
              </Button>
            </nav>
            <GridLayout
              widgets={widgets}
              layouts={layouts}
              updateLayouts={updateLayouts}
              updateWidgets={updateWidgets}
              setIsModified={setIsModified}
            />
          </Fragment>
        )}
      </div>
      <Transition.Root
        show={openSearch}
        as={Fragment}
        afterLeave={() => setQuerySearch("")}
        appear
      >
        <Dialog as="div" className="relative z-10" onClose={setOpenSearch}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox onChange={insertWidget}>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search..."
                      onChange={(event) => setQuerySearch(event.target.value)}
                    />
                  </div>

                  {filteredWidgets.length > 0 && (
                    <Combobox.Options
                      static
                      className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3"
                    >
                      {filteredWidgets.map((item) => (
                        <Combobox.Option
                          key={item.id}
                          value={item}
                          className={({ active }) =>
                            classNames(
                              "flex cursor-default select-none rounded-xl p-3",
                              active ? "bg-gray-100" : ""
                            )
                          }
                        >
                          {({ active }) => (
                            <>
                              <div
                                className={classNames(
                                  "flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-primary-300"
                                )}
                              >
                                <CogIcon
                                  className="h-6 w-6 text-white"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 flex-auto">
                                <p
                                  className={classNames(
                                    "text-sm font-medium",
                                    active ? "text-gray-900" : "text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </p>
                                <p
                                  className={classNames(
                                    "text-sm",
                                    active ? "text-gray-700" : "text-gray-500"
                                  )}
                                >
                                  {item.handle}
                                </p>
                              </div>
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}

                  {querySearch !== "" && filteredWidgets.length === 0 ? (
                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                      <ExclamationCircleIcon
                        type="outline"
                        name="exclamation-circle"
                        className="mx-auto h-6 w-6 text-gray-400"
                      />
                      <p className="mt-4 font-semibold text-gray-900">
                        No results found
                      </p>
                      <p className="mt-2 text-gray-500">
                        No widgets found for this search term. Please try again.
                      </p>
                    </div>
                  ) : (
                    <div className="px-6 py-14 text-center text-sm sm:px-14">
                      <ExclamationCircleIcon
                        type="outline"
                        name="exclamation-circle"
                        className="mx-auto h-6 w-6 text-gray-400"
                      />
                      <p className="mt-4 font-semibold text-gray-900">
                        No widgets available
                      </p>
                      <p className="mt-2 text-gray-500">
                        There are no new widgets available.
                      </p>
                    </div>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Dashboard;
export const DashboardResource: ResourceProps = {
  name: "Dashboard",
  description: "A dashboard for your store.",
  path: "/dashboard",
  icon: (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
    >
      <path
        d="M7.33375 5.13708V0.832009C7.33375 0.362205 6.9762 0 6.50639 0H1.83464C1.36475 0 0.954102 0.362205 0.954102 0.832009V5.13708C0.954102 5.60689 1.36475 5.95426 1.83457 5.95426H6.50632C6.97604 5.95426 7.33359 5.6068 7.33359 5.13699L7.33375 5.13708ZM6.48323 5.10373H1.80496V0.850783H6.48345L6.48323 5.10373Z"
        fill="currentColor"
      />
      <path
        d="M12.1454 0.00012207H9.52565C9.05585 0.00012207 8.69434 0.362327 8.69434 0.832131V3.2034C8.69434 3.6732 9.05594 4.08315 9.52565 4.08315H12.1454C12.6152 4.08315 13.0325 3.67311 13.0325 3.2034V0.832131C13.0325 0.362327 12.6152 0.00012207 12.1454 0.00012207ZM12.1818 3.23236H9.54491V0.850757H12.1818L12.1818 3.23236H12.1818Z"
        fill="currentColor"
      />
      <path
        d="M12.1454 5.78442H9.52565C9.05585 5.78442 8.69434 6.18921 8.69434 6.65901V11.1434C8.69434 11.6132 9.05594 11.9938 9.52565 11.9938H12.1454C12.6152 11.9938 13.0325 11.6132 13.0325 11.1434V6.65901C13.0325 6.18921 12.6152 5.78442 12.1454 5.78442ZM12.1818 11.1432H9.54491V6.63483H12.1818L12.1818 11.1432H12.1818Z"
        fill="currentColor"
      />
      <path
        d="M1.83476 7.57059C1.36487 7.57059 0.954224 7.9908 0.954224 8.46052V11.1434C0.954224 11.6132 1.36487 11.9939 1.8347 11.9939H6.13977C6.60948 11.9939 6.99355 11.6132 6.99355 11.1434L6.99364 8.46052C6.99364 7.99071 6.60948 7.57059 6.13986 7.57059H1.83476ZM6.14298 11.1432H1.80511V8.42145H6.14321V11.1432H6.14298Z"
        fill="currentColor"
      />
    </svg>
  ),
  access: [],
};
