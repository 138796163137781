import { Fragment, useEffect, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

import { TradingAddress } from "../../graphql/sales/orders";
import { renderAddress } from "../../utils";
import { selectStyles, SelectWrapper } from ".";

export function FieldCustomerShippingAddress({
  title,
  data,
  value,
  onChange,
  disabled = false,
  className,
}: {
  title: string;
  data: TradingAddress[];
  value: number | null;
  onChange: (newValue: TradingAddress | null) => void;
  disabled?: boolean;
  className: string;
}) {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [values, setValues] = useState<SingleValue<OptionProps>>();
  const [options, setOptions] = useState<MultiValue<OptionProps>>([]);

  useEffect(() => {
    if (data) {
      const options = data.map((v) => ({
        value: v.id,
        label: v.address + " " + v.suburb + " " + v.state + " " + v.postcode,
      }));
      setOptions(options);
      return;
    }
    setOptions([]);
  }, [data]);

  useEffect(() => {
    if (value) {
      const selected = options.find((v) => parseInt(v.value) === value);
      if (selected) {
        setValues(selected);
        setEdit(false);
        return;
      }
    }
    setValues(null);
  }, [options, value]);

  return (
    <Fragment>
      <label className="mb-1 mt-3 flex text-sm font-medium text-gray-900">
        {title}
        {!isEdit && values ? (
          <span className="ml-2 text-sm font-light">- (default)</span>
        ) : null}
      </label>
      {!isEdit && values ? (
        <div className="mb-2 flex flex-row items-start justify-between">
          <div className="flex flex-col">
            <div className="text-sm">
              {renderAddress(data.find((v) => v.id === values.value))}
            </div>
          </div>
          <button
            className="focus:text-blue text-sm text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </div>
      ) : (
        <SelectWrapper className={className}>
          <Select
            closeMenuOnSelect={true}
            styles={selectStyles}
            value={values}
            options={options}
            onChange={(newValue) => {
              setValues(newValue);
              const updateValue = newValue
                ? data.find((v) => v.id === newValue.value)
                : null;
              onChange(updateValue ? updateValue : null);
            }}
            isDisabled={disabled}
          />
        </SelectWrapper>
      )}
    </Fragment>
  );
}
