import { gql } from "@apollo/client";

export const VEHICLEFUELTYPE_FRAGMENT = gql`
  fragment VehicleFuelTypeFragment on VehicleFuel {
    id
    name
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLEFUELTYPES = gql`
  ${VEHICLEFUELTYPE_FRAGMENT}
  query FetchVehicleFuelTypes {
    fetchVehicleFuelTypes {
      ...VehicleFuelTypeFragment
    }
  }
`;

// export const GET_VEHICLEFUELTYPE_BY_ID = gql`
//   ${VEHICLEFUELTYPE_FRAGMENT}
//   query FetchVehicleFuelType($id: ID!) {
//     fetchVehicleFuelType(id: $id) {
//       ...VehicleFuelTypeFragment
//     }
//   }
// `;

// export const REMOVE_VEHICLEFUELTYPE = gql`
//   ${VEHICLEFUELTYPE_FRAGMENT}
//   mutation VehicleFuelTypeDelete($id: ID!) {
//     vehicleFuelTypeDelete(input: { id: $id }) {
//       vehicleFuelType {
//         ...VehicleFuelTypeFragment
//       }
//     }
//   }
// `;

export const ADD_VEHICLEFUELTYPE = gql`
  ${VEHICLEFUELTYPE_FRAGMENT}
  mutation VehicleFuelTypeCreate($name: String!) {
    vehicleFuelTypeCreate(input: { params: { name: $name } }) {
      vehicleFuelType {
        ...VehicleFuelTypeFragment
      }
    }
  }
`;

// export const EDIT_VEHICLEFUELTYPE = gql`
//   ${VEHICLEFUELTYPE_FRAGMENT}
//   mutation VehicleFuelTypeUpdate($id: ID!, $name: String!) {
//     vehicleFuelTypeUpdate(input: { id: $id, params: { name: $name } }) {
//       vehicleFuelType {
//         ...VehicleFuelTypeFragment
//       }
//     }
//   }
// `;

export type VehicleFuelType = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};
