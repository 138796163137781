import { useLazyQuery } from "@apollo/client";
import { Tab } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import { getIn, useFormik } from "formik";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import * as Yup from "yup";

import { Spinner, Waiting } from "../../../../animations";
import { ErrorDebug } from "../../../../components/core";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldDiscountType,
  FieldFleetVendor,
  FieldInsertServiceReminder,
  FieldUser,
  FieldVehicle,
  selectStyles,
  SelectWrapper,
} from "../../../../components/form";
import { FleetVendorClassification } from "../../../../graphql/fleets/settings/vendors";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import {
  GET_VEHICLEISSUE_BY_VEHICLE_ID,
  renderPriority,
  renderStatus as renderIssueStatus,
  VehicleIssue,
  VehicleIssueStatus,
} from "../../../../graphql/fleets/vehicles/pages/issues";
import {
  VehicleServiceEntryDiscountType,
  VehicleServiceEntryForm,
} from "../../../../graphql/fleets/vehicles/pages/service-entry";
import {
  GET_SERVICEREMAINDER_BY_VEHICLE_ID,
  renderTimeIntervalUnit,
  VehicleServiceReminder,
} from "../../../../graphql/fleets/vehicles/pages/service-reminder";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import {
  classNames,
  formatCurrency,
  formatDate,
  formatFloat,
  renderTabClass,
  useFormikErrors,
} from "../../../../utils";

export default function Form({
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  vehicleBasic: vehicleBasicProp,
  issues: issuesProp,
  serviceRemainders: serviceRemaindersProp,
}: {
  initialValues: VehicleServiceEntryForm;
  onSubmit: (values: VehicleServiceEntryForm, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  vehicleBasic: VehicleBasic | null;
  issues: VehicleIssue[];
  serviceRemainders: VehicleServiceReminder[];
}) {
  const { vehicleId } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [showStartdate, setShowStartdate] = useState(false);

  const queryVehicleId = searchParams.get("vehicleId");
  const queryIssueId = searchParams.get("issueId");

  const [taxAmount, setTaxAmount] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);

  useEffect(() => {
    if (!queryVehicleId) return;
    formik.setFieldValue("vehicleId", parseInt(queryVehicleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVehicleId]);

  useEffect(() => {
    if (!queryIssueId) return;
    formik.setFieldValue("issues", [parseInt(queryIssueId)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryIssueId]);

  useEffect(() => {
    if (!initialValues.startDate) return;
    setShowStartdate(true);
  }, [initialValues.startDate]);

  const [vehicleBasic, setVehicleBasic] = useState<VehicleBasic | null>(null);
  useEffect(() => {
    if (vehicleId === undefined) return;
    setVehicleBasic(vehicleBasicProp);
  }, [vehicleBasicProp, vehicleId]);

  const [serviceRemainders, setServiceRemainders] = useState<
    VehicleServiceReminder[]
  >([]);
  const [fetchingServiceRemainders] = useLazyQuery<{
    fetchServiceRemaindersByVehicle: VehicleServiceReminder[];
  }>(GET_SERVICEREMAINDER_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });

  const [issues, setIssues] = useState<VehicleIssue[]>([]);
  const openIssues = useMemo(
    () => issues.filter((issue) => issue.status === VehicleIssueStatus.OPEN),
    [issues]
  );
  const resolvedIssues = useMemo(
    () =>
      issues.filter((issue) => issue.status === VehicleIssueStatus.RESOLVED),
    [issues]
  );
  const closedIssues = useMemo(
    () => issues.filter((issue) => issue.status === VehicleIssueStatus.CLOSED),
    [issues]
  );
  const [
    fetchingVehicleIssues,
    { loading: loadingIssues, error: errorIssues },
  ] = useLazyQuery<{
    fetchVehicleIssuesByVehicle: VehicleIssue[];
  }>(GET_VEHICLEISSUE_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });

  const ValidationSchema = Yup.object().shape({
    vehicleId: Yup.number().when(".", {
      is: (val: unknown) => vehicleId === undefined,
      then: Yup.number().typeError("Must be a number").required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    repairPriority: Yup.number().nullable(),
    odometer: Yup.number().typeError("Must be a number").required("Required"),
    completionDate: Yup.string().required("Required"),
    startDate: Yup.string().nullable(),
    referenceNo: Yup.string().nullable(),
    fleetVendorId: Yup.number().nullable(),
    totalLaborPrice: Yup.number().nullable(),
    totalPartsPrice: Yup.number().nullable(),
    subtotal: Yup.number().nullable(),
    total: Yup.number().nullable(),
    discount: Yup.number().nullable(),
    discountType: Yup.number().when("discount", {
      is: (val: unknown) => val !== null,
      then: Yup.number().typeError("Must be a number").required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    tax: Yup.number().nullable(),
    generalNotes: Yup.string().nullable(),
    comments: Yup.string().nullable(),
    issues: Yup.array().of(Yup.number()).nullable(),
    lineItems: Yup.array()
      .of(
        Yup.object().shape({
          vehicleServiceRemainderId: Yup.number().required("Required"),
          remainderTimeInterval: Yup.number().required("Required"),
          remainderTimeIntervalUnit: Yup.string().required("Required"),
          laborPrice: Yup.number().nullable(),
          partsPrice: Yup.number().nullable(),
          subtotal: Yup.number().nullable(),
          notes: Yup.string().nullable(),
        })
      )
      .nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  useEffect(() => {
    if (vehicleId === undefined) return;
    setIssues(issuesProp);
  }, [issuesProp, vehicleId]);

  useEffect(() => {
    if (!formik.values.vehicleId) return setIssues([]);
    fetchingVehicleIssues({
      variables: {
        vehicleId: formik.values.vehicleId,
      },
      onCompleted: (data) => {
        setIssues(data.fetchVehicleIssuesByVehicle);
      },
      onError: (error) => {
        toast.error(error.message);
        setIssues([]);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.vehicleId]);

  useEffect(() => {
    if (vehicleId === undefined) return;
    setServiceRemainders(serviceRemaindersProp);
  }, [serviceRemaindersProp, vehicleId]);

  useEffect(() => {
    if (!formik.values.vehicleId) return setServiceRemainders([]);
    fetchingServiceRemainders({
      variables: {
        vehicleId: formik.values.vehicleId,
      },
      onCompleted: (data) => {
        setServiceRemainders(data.fetchServiceRemaindersByVehicle);
      },
      onError: (error) => {
        toast.error(error.message);
        setServiceRemainders([]);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.vehicleId]);

  useEffect(() => {
    formik.setFieldValue("issues", []);
    formik.setFieldValue("lineItems", []);
    formik.setFieldValue("totalLaborPrice", null);
    formik.setFieldValue("totalPartsPrice", null);
    formik.setFieldValue("subtotal", null);
    formik.setFieldValue("total", null);
    setDiscountAmount(0);
    setTaxAmount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.vehicleId]);

  const handleServiceReminderChange = useCallback(
    (index: number, serviceRemainder: VehicleServiceReminder) => {
      const lineItems = [...formik.values.lineItems];
      lineItems[index].vehicleServiceRemainderId = parseInt(
        serviceRemainder.id
      );
      formik.setFieldValue("lineItems", lineItems);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.lineItems]
  );

  const handleServiceReminderAdd = useCallback(
    (serviceRemainder: VehicleServiceReminder) => {
      const remainderTimeIntervalUnit = renderTimeIntervalUnit(
        serviceRemainder.timeIntervalUnit,
        true
      );
      const lineItems = [...formik.values.lineItems];
      lineItems.push({
        vehicleServiceRemainderId: parseInt(serviceRemainder.id),
        remainderTimeInterval: serviceRemainder.timeInterval,
        remainderTimeIntervalUnit: remainderTimeIntervalUnit.toLowerCase(),
        laborPrice: null,
        partsPrice: null,
        subtotal: null,
        notes: "",
      });
      formik.setFieldValue("lineItems", lineItems);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.lineItems]
  );

  const handleServiceReminderRemove = useCallback(
    (index: number) => {
      const lineItems = [...formik.values.lineItems];
      lineItems.splice(index, 1);
      formik.setFieldValue("lineItems", lineItems);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.lineItems]
  );

  useEffect(() => {
    const totalLaborPrice = formik.values.lineItems.reduce(
      (acc, item) => acc + (item.laborPrice ?? 0),
      0
    );
    formik.setFieldValue("totalLaborPrice", formatFloat(totalLaborPrice, 2));
    const totalPartsPrice = formik.values.lineItems.reduce(
      (acc, item) => acc + (item.partsPrice ?? 0),
      0
    );
    const subtotal = totalLaborPrice + totalPartsPrice;
    formik.setFieldValue("totalPartsPrice", formatFloat(totalPartsPrice, 2));
    formik.setFieldValue("subtotal", formatFloat(subtotal, 2));
    const totalDiscount =
      formik.values.discountType === VehicleServiceEntryDiscountType.FIXED
        ? formik.values.discount ?? 0
        : (subtotal * (formik.values.discount ?? 0)) / 100;
    setDiscountAmount(totalDiscount);
    const total = subtotal - totalDiscount;

    const taxPercentage = formik.values.tax ?? 0;
    const tax = (total * taxPercentage) / 100;
    setTaxAmount(tax);
    const grandTotal = total + tax;
    formik.setFieldValue("total", formatFloat(grandTotal, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.lineItems,
    formik.values.discountType,
    formik.values.discount,
    formik.values.tax,
  ]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
        {vehicleId === undefined ? (
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_vehicle")}
            </label>
            <FieldVehicle
              value={formik.values.vehicleId}
              onChange={(value) => {
                formik.setFieldValue("vehicleId", value ? value.id : null);
                setVehicleBasic(value);
              }}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.vehicleId && formik.errors.vehicleId
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.vehicleId && formik.errors.vehicleId ? (
              <p className="mt-2 text-sm text-red-600" id="vehicleId-errors">
                {formik.errors.vehicleId}
              </p>
            ) : null}
          </div>
        ) : null}
        <div className="sm:col-span-3">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_repair_priority")}
          </label>
          <FieldRepairPriority
            value={formik.values.repairPriority}
            onChange={(value) => {
              formik.setFieldValue("repairPriority", value);
            }}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.repairPriority && formik.errors.repairPriority
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.repairPriority && formik.errors.repairPriority ? (
            <p className="mt-2 text-sm text-red-600" id="repairPriority-errors">
              {formik.errors.repairPriority}
            </p>
          ) : null}
        </div>
        <div className="sm:col-span-3">
          <Field
            title={t("text_odometer")}
            name="odometer"
            type="number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.odometer ?? ""}
            touched={formik.touched.odometer}
            errors={formik.errors.odometer}
            isSuffix
            suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
          />
        </div>
        <div className="sm:col-span-3">
          <FieldDatepicker
            title={t("text_completion_date")}
            name="completionDate"
            onChange={(value) => {
              if (!Array.isArray(value)) {
                formik.setFieldValue(
                  "completionDate",
                  value ? new Date(value).toISOString() : null
                );
              }
            }}
            selected={
              formik.values.completionDate
                ? new Date(formik.values.completionDate)
                : null
            }
            touched={formik.touched.completionDate}
            errors={formik.errors.completionDate}
          />
          <div className="mt-3">
            <Field
              title={t("text_enable_start_date")}
              name="showStartdate"
              type="checkbox"
              checked={showStartdate}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setShowStartdate(e.target.checked);
                formik.setFieldValue("startDate", null);
              }}
              onBlur={formik.handleBlur}
              touched={formik.touched.startDate}
              errors={formik.errors.startDate}
            />
          </div>
        </div>
        {showStartdate && (
          <div className="sm:col-span-3">
            <FieldDatepicker
              title={t("text_start_date")}
              name="startDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "startDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.startDate
                  ? new Date(formik.values.startDate)
                  : null
              }
              touched={formik.touched.startDate}
              errors={formik.errors.startDate}
            />
          </div>
        )}
        <div className="sm:col-span-3">
          <Field
            title={t("text_reference_no")}
            name="referenceNo"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.referenceNo}
            touched={formik.touched.referenceNo}
            errors={formik.errors.referenceNo}
          />
        </div>
        <div className="sm:col-span-3">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_fleet_vendor")}
          </label>
          <FieldFleetVendor
            title={t("text_fleet_vendor")}
            value={formik.values.fleetVendorId}
            onChange={(value) => {
              formik.setFieldValue("fleetVendorId", value);
            }}
            classification={FleetVendorClassification.SERVICE}
            className={classNames(
              "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              formik.touched.fleetVendorId && formik.errors.fleetVendorId
                ? "border-red-600 text-red-900"
                : ""
            )}
          />
          {formik.touched.fleetVendorId && formik.errors.fleetVendorId ? (
            <p className="mt-2 text-sm text-red-600" id="fleetVendorId-errors">
              {formik.errors.fleetVendorId}
            </p>
          ) : null}
        </div>

        {errorIssues ? (
          <ErrorDebug error={errorIssues} className="sm:col-span-6" />
        ) : (
          <Fragment>
            <div className="sm:col-span-6">
              <hr className="border-t border-gray-300" />
            </div>
            <div className="sm:col-span-6">
              <h3 className="mb-3 text-lg font-medium">Issues</h3>
              <div className="rounded-xl bg-white">
                <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
                  <Tab.List className="flex border-b border-solid border-gray-200 px-4">
                    <Tab className={renderTabClass}>Open</Tab>
                    <Tab className={renderTabClass}>Resolved</Tab>
                    <Tab className={renderTabClass}>Closed</Tab>
                  </Tab.List>
                  <Tab.Panels as={Fragment}>
                    <Tab.Panel className="px-4 py-6 text-sm font-normal">
                      {formik.values.vehicleId === null ? (
                        <p className="py-6 text-center md:py-8 xl:py-10">
                          Please select a vehicle first.
                        </p>
                      ) : loadingIssues ? (
                        <Waiting />
                      ) : openIssues.length ? (
                        <Fragment>
                          <header className="mb-4 flex items-start justify-between">
                            <p className="text-sm">
                              Select any issues that were resolved as part of
                              this service
                            </p>
                            <Button
                              type="link"
                              border
                              href={
                                vehicleId === undefined
                                  ? `/fleets/vehicles/issues/add`
                                  : `/fleets/vehicles/${vehicleId}/issue/add`
                              }
                            >
                              <PlusCircleIcon className="mr-2 h-5 w-5" />
                              Add issue
                            </Button>
                          </header>
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0"
                                >
                                  Priority
                                </th>
                                <th
                                  scope="col"
                                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 sm:table-cell"
                                >
                                  Issue
                                </th>
                                <th
                                  scope="col"
                                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 lg:table-cell"
                                >
                                  Summary
                                </th>
                                <th
                                  scope="col"
                                  className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 lg:table-cell"
                                >
                                  Status
                                </th>
                                <th
                                  scope="col"
                                  className="hidden px-3 py-3.5 text-right text-sm font-normal text-gray-900 sm:table-cell"
                                >
                                  Assigned
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 pr-4 text-right text-sm font-normal text-gray-900 sm:pr-0"
                                >
                                  Due Date
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {openIssues.map((item, index) => (
                                <tr key={item.id}>
                                  <td className="w-full max-w-0 py-1 pl-4 pr-3 text-sm font-normal text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                    <div className="flex items-center space-x-4">
                                      <Field
                                        title={`issues-${item.id}`}
                                        id={`issue-${item.id}`}
                                        name={`issues-${index}`}
                                        type="checkbox"
                                        checked={formik.values.issues.includes(
                                          parseInt(item.id)
                                        )}
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          const checked = e.target.checked;
                                          formik.setFieldValue(
                                            "issues",
                                            checked
                                              ? [
                                                  ...formik.values.issues,
                                                  parseInt(item.id),
                                                ]
                                              : formik.values.issues.filter(
                                                  (id) =>
                                                    id !== parseInt(item.id)
                                                )
                                          );
                                        }}
                                        isLabel={false}
                                      />
                                      <label htmlFor={`issue-${item.id}`}>
                                        {renderPriority(item.priority)}
                                      </label>
                                    </div>
                                    <dl className="font-normal lg:hidden">
                                      <dt className="sr-only sm:hidden">
                                        Issue
                                      </dt>
                                      <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                        <Link
                                          to={
                                            vehicleId === undefined
                                              ? `/fleets/vehicles/issues/${item.id}`
                                              : `/fleets/vehicles/${vehicleId}/issue/${item.id}`
                                          }
                                          className="text-primary-700 transition-all hover:text-primary-900"
                                        >
                                          #{item.id}
                                        </Link>
                                      </dd>
                                      <dt className="sr-only sm:hidden">
                                        Summary
                                      </dt>
                                      <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                        {item.summary}
                                      </dd>
                                      <dt className="sr-only">Status</dt>
                                      <dd className="mt-1 truncate text-gray-700">
                                        {renderIssueStatus(item.status)}
                                      </dd>
                                      <dt className="sr-only sm:hidden">
                                        Assigned
                                      </dt>
                                      <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                        <FieldUser
                                          title="User"
                                          value={item.assignedToId}
                                          isPreview
                                        />
                                      </dd>
                                    </dl>
                                  </td>
                                  <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                    <Link
                                      to={
                                        vehicleId === undefined
                                          ? `/fleets/vehicles/issues/${item.id}`
                                          : `/fleets/vehicles/${vehicleId}/issue/${item.id}`
                                      }
                                      className="text-primary-700 transition-all hover:text-primary-900"
                                    >
                                      #{item.id}
                                    </Link>
                                  </td>
                                  <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                    {item.summary}
                                  </td>
                                  <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 lg:table-cell">
                                    {renderIssueStatus(item.status)}
                                  </td>
                                  <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                    <FieldUser
                                      title="User"
                                      value={item.assignedToId}
                                      isPreview
                                    />
                                  </td>
                                  <td className="py-4 pl-3 pr-4 text-right text-sm font-normal sm:pr-0">
                                    <span className="whitespace-nowrap">
                                      {formatDate(item.overDueDate)}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Fragment>
                      ) : (
                        <p className="py-6 text-center md:py-8 xl:py-10">
                          No open issues found.
                        </p>
                      )}
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm font-normal">
                      {formik.values.vehicleId === null ? (
                        <p className="py-6 text-center md:py-8 xl:py-10">
                          Please select a vehicle first.
                        </p>
                      ) : loadingIssues ? (
                        <Waiting />
                      ) : resolvedIssues.length ? (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0"
                              >
                                Priority
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 sm:table-cell"
                              >
                                Issue
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 lg:table-cell"
                              >
                                Summary
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 lg:table-cell"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-right text-sm font-normal text-gray-900 sm:table-cell"
                              >
                                Assigned
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 pr-4 text-right text-sm font-normal text-gray-900 sm:pr-0"
                              >
                                Due Date
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {resolvedIssues.map((item, index) => (
                              <tr key={item.id}>
                                <td className="w-full max-w-0 py-1 pl-4 pr-3 text-sm font-normal text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                  <div>{renderPriority(item.priority)}</div>
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only sm:hidden">Issue</dt>
                                    <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                      <Link
                                        to={
                                          vehicleId === undefined
                                            ? `/fleets/vehicles/issues/${item.id}`
                                            : `/fleets/vehicles/${vehicleId}/issue/${item.id}`
                                        }
                                        className="text-primary-700 transition-all hover:text-primary-900"
                                      >
                                        #{item.id}
                                      </Link>
                                    </dd>
                                    <dt className="sr-only sm:hidden">
                                      Summary
                                    </dt>
                                    <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                      {item.summary}
                                    </dd>
                                    <dt className="sr-only">Status</dt>
                                    <dd className="mt-1 truncate text-gray-700">
                                      {renderIssueStatus(item.status)}
                                    </dd>
                                    <dt className="sr-only sm:hidden">
                                      Assigned
                                    </dt>
                                    <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                      <FieldUser
                                        title="User"
                                        value={item.assignedToId}
                                        isPreview
                                      />
                                    </dd>
                                  </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                  <Link
                                    to={
                                      vehicleId === undefined
                                        ? `/fleets/vehicles/issues/${item.id}`
                                        : `/fleets/vehicles/${vehicleId}/issue/${item.id}`
                                    }
                                    className="text-primary-700 transition-all hover:text-primary-900"
                                  >
                                    #{item.id}
                                  </Link>
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                  {item.summary}
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 lg:table-cell">
                                  {renderIssueStatus(item.status)}
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                  <FieldUser
                                    title="User"
                                    value={item.assignedToId}
                                    isPreview
                                  />
                                </td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm font-normal sm:pr-0">
                                  <span className="whitespace-nowrap">
                                    {formatDate(item.overDueDate)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p className="py-6 text-center md:py-8 xl:py-10">
                          No resolved issues found.
                        </p>
                      )}
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm font-normal">
                      {formik.values.vehicleId === null ? (
                        <p className="py-6 text-center md:py-8 xl:py-10">
                          Please select a vehicle first.
                        </p>
                      ) : loadingIssues ? (
                        <Waiting />
                      ) : closedIssues.length ? (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-normal text-gray-900 sm:pl-0"
                              >
                                Priority
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 sm:table-cell"
                              >
                                Issue
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 lg:table-cell"
                              >
                                Summary
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-normal text-gray-900 lg:table-cell"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-right text-sm font-normal text-gray-900 sm:table-cell"
                              >
                                Assigned
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 pr-4 text-right text-sm font-normal text-gray-900 sm:pr-0"
                              >
                                Due Date
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {closedIssues.map((item, index) => (
                              <tr key={item.id}>
                                <td className="w-full max-w-0 py-1 pl-4 pr-3 text-sm font-normal text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                  <div>{renderPriority(item.priority)}</div>
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only sm:hidden">Issue</dt>
                                    <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                      <Link
                                        to={
                                          vehicleId === undefined
                                            ? `/fleets/vehicles/issues/${item.id}`
                                            : `/fleets/vehicles/${vehicleId}/issue/${item.id}`
                                        }
                                        className="text-primary-700 transition-all hover:text-primary-900"
                                      >
                                        #{item.id}
                                      </Link>
                                    </dd>
                                    <dt className="sr-only sm:hidden">
                                      Summary
                                    </dt>
                                    <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                      {item.summary}
                                    </dd>
                                    <dt className="sr-only">Status</dt>
                                    <dd className="mt-1 truncate text-gray-700">
                                      {renderIssueStatus(item.status)}
                                    </dd>
                                    <dt className="sr-only sm:hidden">
                                      Assigned
                                    </dt>
                                    <dd className="mt-1 truncate text-gray-700 sm:hidden">
                                      <FieldUser
                                        title="User"
                                        value={item.assignedToId}
                                        isPreview
                                      />
                                    </dd>
                                  </dl>
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                  <Link
                                    to={
                                      vehicleId === undefined
                                        ? `/fleets/vehicles/issues/${item.id}`
                                        : `/fleets/vehicles/${vehicleId}/issue/${item.id}`
                                    }
                                    className="text-primary-700 transition-all hover:text-primary-900"
                                  >
                                    #{item.id}
                                  </Link>
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                  {item.summary}
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 lg:table-cell">
                                  {renderIssueStatus(item.status)}
                                </td>
                                <td className="hidden px-3 py-4 text-sm font-normal text-gray-700 sm:table-cell">
                                  <FieldUser
                                    title="User"
                                    value={item.assignedToId}
                                    isPreview
                                  />
                                </td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm font-normal sm:pr-0">
                                  <span className="whitespace-nowrap">
                                    {formatDate(item.overDueDate)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p className="py-6 text-center md:py-8 xl:py-10">
                          No closed issues found.
                        </p>
                      )}
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </Fragment>
        )}
        <div className="sm:col-span-6">
          <hr className="border-t border-gray-300" />
        </div>
        <div className="sm:col-span-6">
          <h3 className="mb-3 text-lg font-medium">Line Items</h3>
          <div className="rounded-xl bg-white p-4">
            {formik.values.vehicleId ? (
              <Fragment>
                <header className="pb-4">
                  <FieldInsertServiceReminder
                    title="Add service reminder"
                    type="search"
                    vehicleId={formik.values.vehicleId}
                    value={null}
                    excludeIds={[
                      ...formik.values.lineItems.flatMap(
                        (item) => item.vehicleServiceRemainderId
                      ),
                    ]}
                    excludeNote="Already added"
                    onChange={(sr) => {
                      handleServiceReminderAdd(sr);
                    }}
                    className="w-full justify-center"
                  >
                    <Fragment>
                      <PlusIcon className="h-4 w-4" />
                      <span className="ml-2">Add service reminder</span>
                    </Fragment>
                  </FieldInsertServiceReminder>
                </header>
                {formik.values.lineItems.length ? (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-0"
                        >
                          {t("text_name")}
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-900 sm:table-cell"
                        >
                          {t("text_labor_price")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-medium text-gray-900"
                        >
                          {t("text_parts_price")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-right text-sm font-medium text-gray-900"
                        >
                          {t("text_sub_total")}
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">{t("text_edit")}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {formik.values.lineItems.map((item, index) => {
                        const serviceRemainder = serviceRemainders.find(
                          (sr) =>
                            parseInt(sr.id) === item.vehicleServiceRemainderId
                        );
                        return (
                          <Fragment key={item.vehicleServiceRemainderId}>
                            <tr className="border-t border-gray-200">
                              <td className="w-full max-w-0 py-4 pl-4 pr-3 align-top text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                <div className="font-normal text-gray-900">
                                  {serviceRemainder?.vehicleServiceTask.name}
                                </div>
                              </td>
                              <td className="px-3 py-4 align-top text-sm">
                                <Field
                                  title={t("text_labor_price")}
                                  name={`lineItems[${index}].laborPrice`}
                                  type="number"
                                  min={0}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const lineItems = [
                                      ...formik.values.lineItems,
                                    ];
                                    const updatedLaborPrice = value
                                      ? parseFloat(value)
                                      : 0;
                                    const currentPartsPrice =
                                      lineItems[index].partsPrice ?? 0;
                                    lineItems[index].laborPrice =
                                      updatedLaborPrice;
                                    lineItems[index].subtotal =
                                      updatedLaborPrice + currentPartsPrice;
                                    formik.setFieldValue(
                                      "lineItems",
                                      lineItems
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={item.laborPrice ?? ""}
                                  touched={getIn(
                                    formik.touched,
                                    `lineItems[${index}].laborPrice`
                                  )}
                                  errors={getIn(
                                    formik.errors,
                                    `lineItems[${index}].laborPrice`
                                  )}
                                  isLabel={false}
                                  className="w-20"
                                />
                              </td>
                              <td className="px-3 py-4 align-top text-sm">
                                <Field
                                  title={t("text_parts_price")}
                                  name={`lineItems[${index}].partsPrice`}
                                  type="number"
                                  min={0}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const lineItems = [
                                      ...formik.values.lineItems,
                                    ];
                                    const updatedPartsPrice = value
                                      ? parseFloat(value)
                                      : 0;
                                    const currentLaborPrice =
                                      lineItems[index].laborPrice ?? 0;
                                    lineItems[index].partsPrice =
                                      updatedPartsPrice;
                                    lineItems[index].subtotal =
                                      updatedPartsPrice + currentLaborPrice;
                                    formik.setFieldValue(
                                      "lineItems",
                                      lineItems
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    item.partsPrice
                                      ? formatFloat(item.partsPrice)
                                      : ""
                                  }
                                  touched={getIn(
                                    formik.touched,
                                    `lineItems[${index}].partsPrice`
                                  )}
                                  errors={getIn(
                                    formik.errors,
                                    `lineItems[${index}].partsPrice`
                                  )}
                                  isLabel={false}
                                  className="w-20"
                                />
                              </td>
                              <td className="px-3 py-4 text-right align-middle text-sm">
                                <span className="text-gray-900">
                                  {item.subtotal}
                                </span>
                              </td>
                              <td className="py-4 pl-3 pr-4 text-right align-top text-sm font-normal sm:pr-0">
                                <FieldInsertServiceReminder
                                  title="Replace service reminder"
                                  vehicleId={formik.values.vehicleId}
                                  value={
                                    serviceRemainder?.id
                                      ? parseInt(serviceRemainder.id)
                                      : null
                                  }
                                  excludeIds={[
                                    ...formik.values.lineItems.flatMap(
                                      (item) => item.vehicleServiceRemainderId
                                    ),
                                  ]}
                                  excludeNote="Already added"
                                  onChange={(sr) => {
                                    handleServiceReminderChange(index, sr);
                                  }}
                                >
                                  <Fragment>
                                    <PencilIcon className="h-4 w-4" />
                                    <span className="sr-only">
                                      Replace service reminder,{" "}
                                      {
                                        serviceRemainder?.vehicleServiceTask
                                          .name
                                      }
                                    </span>
                                  </Fragment>
                                </FieldInsertServiceReminder>
                                <Button
                                  variant="danger"
                                  border
                                  className="ml-2 px-2"
                                  onClick={() => {
                                    handleServiceReminderRemove(index);
                                  }}
                                >
                                  <span className="sr-only">
                                    Remove service reminder,{" "}
                                    {serviceRemainder?.vehicleServiceTask.name}
                                  </span>
                                  <TrashIcon className="h-4 w-4" />
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="pb-4">
                                <Field
                                  title={t("text_notes")}
                                  name={`lineItems[${index}].notes`}
                                  type="textarea"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={item.notes || ""}
                                  touched={getIn(
                                    formik.touched,
                                    `lineItems[${index}].notes`
                                  )}
                                  errors={getIn(
                                    formik.errors,
                                    `lineItems[${index}].notes`
                                  )}
                                  className="h-16"
                                />
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div className="rounded-lg bg-greyish text-sm text-gray-700">
                    <p className="py-6 text-center md:py-8 xl:py-10">
                      No line items added.
                    </p>
                  </div>
                )}
              </Fragment>
            ) : (
              <div className="text-sm">
                <p className="py-6 text-center md:py-8 xl:py-10">
                  Please select a vehicle first.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="sm:col-span-3">
          <h3 className="mb-3 text-lg font-medium">
            {t("text_general_notes")}
          </h3>
          <Field
            title={t("text_general_notes")}
            name="generalNotes"
            type="textarea"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.generalNotes}
            touched={formik.touched.generalNotes}
            errors={formik.errors.generalNotes}
            className="h-64"
            isLabel={false}
          />
        </div>
        <div className="space-y-4 sm:col-span-3">
          <h3 className="mb-3 text-lg font-medium">{t("text_cost_summary")}</h3>

          <div className="grid grid-cols-2 gap-4">
            <div className="text-sm text-gray-700">{t("text_labor")}</div>
            <div className="text-right text-sm text-gray-700">
              {formatCurrency(formik.values.totalLaborPrice ?? 0)}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-sm text-gray-700">{t("text_parts")}</div>
            <div className="text-right text-sm text-gray-700">
              {formatCurrency(formik.values.totalPartsPrice ?? 0)}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="text-sm text-gray-700">{t("text_subtotal")}</div>
            <div className="text-right text-sm text-gray-700">
              {formatCurrency(formik.values.subtotal ?? 0)}
            </div>
          </div>
          <div className="grid grid-cols-3 items-center gap-4">
            <div className="text-sm text-gray-700">{t("text_discount")}</div>
            <div>
              <Field
                title={t("text_discount")}
                name="discount"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.discount ?? ""}
                touched={formik.touched.discount}
                errors={formik.errors.discount}
                isSuffix
                suffix={
                  <FieldDiscountType
                    value={formik.values.discountType}
                    onChange={(value) => {
                      formik.setFieldValue("discountType", value);
                    }}
                    className={classNames(
                      formik.touched.discountType && formik.errors.discountType
                        ? "text-red-900"
                        : ""
                    )}
                  />
                }
                isLabel={false}
              />
            </div>
            <div className="py-4 text-right text-sm text-gray-700">
              {formatCurrency(discountAmount)}
            </div>
          </div>
          <div className="grid grid-cols-3 items-center gap-4">
            <div className="text-sm text-gray-700">{t("text_tax")}</div>
            <div>
              <Field
                title={t("text_tax")}
                name="tax"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tax ?? ""}
                touched={formik.touched.tax}
                errors={formik.errors.tax}
                isLabel={false}
              />
            </div>
            <div className="py-4 text-right text-sm text-gray-700">
              {formatCurrency(taxAmount)}
            </div>
          </div>
          <hr className="border-t border-gray-300" />
          <div className="grid grid-cols-2 gap-4 text-lg font-medium text-gray-900">
            <div>{t("text_total")}</div>
            <div className="text-right">
              {formatCurrency(formik.values.total ?? 0)}
            </div>
          </div>
        </div>
        <div className="sm:col-span-6">
          <hr className="border-t border-gray-300" />
        </div>
        <div className="sm:col-span-6">
          <h3 className="mb-3 text-lg font-medium">Comments</h3>
          <Field
            title={t("text_comments")}
            name="comments"
            type="textarea"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comments}
            touched={formik.touched.comments}
            errors={formik.errors.comments}
            isLabel={false}
          />
        </div>
      </div>
      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Button
          variant="secondary"
          className="w-full justify-center"
          onClick={onCancel}
        >
          {t("text_cancel")}
        </Button>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <Fragment>
              <Spinner />
              {t("text_processing")}
            </Fragment>
          ) : (
            submitLabel
          )}
        </Button>
      </div>
    </form>
  );
}

export function FieldRepairPriority({
  value,
  onChange,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Scheduled", value: "0" },
      { label: "Non-Scheduled", value: "1" },
      { label: "Emergency", value: "2" },
    ],
    []
  );

  useEffect(() => {
    if (value === null) return setValues(null);
    const isInteger = Number.isInteger(value);
    if (isInteger) {
      const option =
        options.find((option) => option.value === value.toString()) ?? null;
      setValues(option);
      return;
    }
    setValues(null);
  }, [options, value]);

  const onChangeHandler = (newValue: SingleValue<OptionProps>) => {
    onChange(newValue ? Number(newValue.value) : null);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={onChangeHandler}
        isClearable
      />
    </SelectWrapper>
  );
}
