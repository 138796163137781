import { gql } from "@apollo/client/core";

import {
  type PricingLevel as IPricingLevel,
  type PricingMethod as IPricingMethod,
} from ".";

export const CREATE_PRODUCTPRICING = gql`
  mutation ProductPricingCreate(
    $pricingLevelId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    productPricingCreate(
      input: {
        params: {
          pricingLevelId: $pricingLevelId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export const UPDATE_PRODUCTPRICING = gql`
  mutation ProductPricingUpdate(
    $id: ID!
    $pricingLevelId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    productPricingUpdate(
      input: {
        id: $id
        params: {
          pricingLevelId: $pricingLevelId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export const DELETE_PRODUCTPRICING = gql`
  mutation ProductPricingDelete($productId: Int!, $pricingLevelId: Int!) {
    productPricingDelete(
      input: { productId: $productId, pricingLevelId: $pricingLevelId }
    ) {
      message
    }
  }
`;

export const FETCH_PRICINGLEVEL_DETAILS = gql`
  query FetchPricingLevel($id: ID!, $pricingLevelId: Int!) {
    fetchPricingLevel(id: $id) {
      id
      name
      pricingMethod {
        id
        name
        handle
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
      productType
      products {
        id
        name
        featureImageUrl
        variants {
          id
          stockCode
          productId
          basePrice
          comparePrice
          cost
          marketCost
          pricingLevelDetails(pricingLevelId: $pricingLevelId) {
            id
            priceFields
            sellPrice
            costUpdated
          }
          variantTitle {
            id
            name
            option {
              id
              name
            }
          }
        }
        status
      }
      createdAt
      status
    }
  }
`;

type Title = {
  id: string;
  name: string;
  option: {
    id: string;
    name: string;
  };
};
export type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
  costUpdated: boolean;
};

export type Variant = {
  id: string;
  stockCode: string;
  productId: number;
  basePrice: number;
  comparePrice: number;
  cost: number;
  marketCost: number;
  pricingLevelDetails?: Details[];
  variantTitle: Title[];
};

export type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
};

type PricingMethod = WithRequired<IPricingMethod, "id">;
export type PricingLevel = Pick<
  IPricingLevel,
  "id" | "name" | "productType" | "createdAt" | "status"
> & {
  pricingMethod: PricingMethod;
  products: Product[];
};

export enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

export type ProductPricing = {
  id: string;
  pricingId?: string;
  pricingLevelId: number;
  productId: number;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};
