import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { FormikProps, getIn, useFormik } from "formik";
import { DateTime } from "luxon";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import placeholder from "../../../../assets/placeholder.svg";
import {
  Button,
  Field,
  FieldCustomerShippingAddress,
  FieldDatepicker,
  FieldOrderProducts,
} from "../../../../components/form";
import {
  Customer,
  Order,
  OrderStatus,
  renderStatus,
  RowProduct,
  TradingAddress,
} from "../../../../graphql/sales/orders";
import { classNames } from "../../../../utils";

const orderSchema = Yup.object().shape({
  customerId: Yup.number().nullable().required("Required"),
  purchaseNumber: Yup.string().nullable(),
  products: Yup.array().of(
    Yup.object()
      .shape({
        productId: Yup.number().nullable(),
        price: Yup.number().nullable(),
        quantity: Yup.number().nullable(),
        orderComments: Yup.string().nullable(),
      })
      .nullable()
  ),
  customerTradingAddressId: Yup.number().nullable(),
  deliveryDate: Yup.string().nullable(),
  notes: Yup.string(),
  subTotal: Yup.number(),
  total: Yup.number(),
  status: Yup.number(),
});

type InitialValues = Pick<Order, "status" | "total" | "subTotal" | "notes"> & {
  purchaseNumber: string;
  customerTradingAddressId: number | null;
  deliveryDate: string | null;
  products: {
    productId: number;
    price: number;
    quantity: number;
    orderComments: string;
  }[];
  customerId: number | null;
};

export default function Form({
  initialValues,
  onSubmit,
  orderNumber,
  orderUpdatedAt,
  products: initialProducts,
  customer,
  onCancel,
}: {
  initialValues: InitialValues;
  onSubmit: (values: any, actions: any) => void;
  orderNumber: string;
  orderUpdatedAt: string;
  products: (RowProduct & {
    orderComments: string;
  })[];
  customer: Omit<Customer, "orders">;
  onCancel: () => void;
}) {
  const { t, i18n } = useTranslation();
  const [paymentEdit, setPaymentEdit] = useState<boolean>(false);
  const isEditable = useMemo(
    () =>
      initialValues.status === OrderStatus.DRAFT ||
      initialValues.status === OrderStatus.OPEN ||
      initialValues.status === OrderStatus.PACKING,
    [initialValues.status]
  );

  const [selectedProducts, setSelectedProducts] = useState<
    (RowProduct & {
      orderComments: string;
    })[]
  >([]);

  useEffect(() => {
    if (initialProducts.length > 0) {
      setSelectedProducts(initialProducts);
    }
  }, [initialProducts]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: orderSchema,
    onSubmit,
  });

  const handlePriceChange = useCallback(() => {
    const total = formik.values.products.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
    formik.setFieldValue("subTotal", total.toFixed(2));
    formik.setFieldValue("total", total.toFixed(2));
  }, [formik.values.products]);

  useEffect(() => {
    handlePriceChange();
  }, [selectedProducts, handlePriceChange]);

  const handleRemoveProduct = useCallback(
    (product: RowProduct) => {
      const newSelectedProducts = selectedProducts.filter(
        (selectedProduct) => selectedProduct.id !== product.id
      );
      setSelectedProducts(newSelectedProducts);

      if (newSelectedProducts.length === 0) {
        formik.setFieldValue("products", []);
        return;
      }

      const updatedProducts = formik.values.products.filter(
        (p) => parseInt(product.id) !== p.productId
      );
      console.log(updatedProducts);
      formik.setFieldValue("products", updatedProducts);
    },
    [formik, selectedProducts]
  );

  const handleUpdateProduct = useCallback(
    (
      variant: RowProduct,
      quantity: number,
      price: number,
      orderComments: string
    ) => {
      const updatedSelectedProducts = selectedProducts.map((p) => {
        if (p.id === variant.id) {
          return {
            ...p,
            quantity,
            price,
            orderComments,
          };
        }
        return p;
      });
      const updatedProducts = formik.values.products.map((p) => {
        if (p.productId === parseInt(variant.id)) {
          return {
            ...p,
            quantity,
            price,
            orderComments,
          };
        }
        return p;
      });
      setSelectedProducts(updatedSelectedProducts);
      formik.setFieldValue("products", updatedProducts);
    },
    [formik, selectedProducts]
  );

  return (
    <>
      <div className="mx-auto max-w-6xl">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_order")} - #{orderNumber} Updated on{" "}
                {DateTime.fromISO(orderUpdatedAt, {
                  locale: i18n.language,
                }).toLocaleString(DateTime.DATETIME_MED)}
                <span className="ml-2">
                  {renderStatus(initialValues.status)}
                </span>
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_order")}
              </p>
            </div>
            <div className="mt-4 flex sm:ml-16 sm:mt-0">
              <Button
                variant="secondary"
                onClick={onCancel}
                className="mr-2 flex"
              >
                {t("text_cancel")}
              </Button>

              {initialValues.status === OrderStatus.DRAFT ? (
                <div className="inline-flex rounded-md">
                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      formik.setFieldValue("status", OrderStatus.DRAFT);
                      formik.handleSubmit();
                    }}
                  >
                    {t("text_save_changes")}
                  </button>
                  <Menu as="div" className="relative -ml-px flex">
                    <Menu.Button
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                      disabled={formik.isSubmitting}
                    >
                      <span className="sr-only">Open options</span>
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 -mr-1 mt-12 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Button
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-primary-700 hover:text-white"
                            onClick={() => {
                              formik.setFieldValue("status", OrderStatus.DRAFT);
                              formik.handleSubmit();
                            }}
                          >
                            {t("text_save_as_draft")}
                          </Menu.Button>
                          <Menu.Button
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-primary-700 hover:text-white"
                            onClick={() => {
                              formik.setFieldValue("status", OrderStatus.OPEN);
                              formik.handleSubmit();
                            }}
                          >
                            {t("text_process_order")}
                          </Menu.Button>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              ) : initialValues.status === OrderStatus.OPEN ? (
                <Button
                  variant="primary"
                  onClick={() => {
                    formik.setFieldValue("status", OrderStatus.PACKING);
                    formik.handleSubmit();
                  }}
                >
                  {t("text_move_to_packing")}
                </Button>
              ) : null}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
            <div className="space-y-4 md:col-span-8">
              <div className="rounded-xl bg-greyish p-5">
                <label
                  htmlFor="description"
                  className="mb-1 block text-sm font-medium text-gray-900"
                >
                  {t("text_products")}
                </label>

                {formik.values.customerId ? (
                  <FieldOrderProducts
                    customerId={formik.values.customerId}
                    data={selectedProducts}
                    value={selectedProducts.flatMap((p) => Number(p.id))}
                    onChange={(products) => {
                      const updatedProducts = products.map((p) => {
                        const existingProduct = formik.values.products.find(
                          (product) => product.productId === Number(p.id)
                        );
                        return {
                          productId: Number(p.id),
                          quantity: existingProduct?.quantity || p.quantity,
                          price: existingProduct?.price || p.price,
                          orderComments: existingProduct?.orderComments || "",
                        };
                      });
                      formik.setFieldValue("products", updatedProducts);
                      setSelectedProducts(
                        products.map((p) => ({
                          ...p,
                          orderComments: "",
                        }))
                      );
                    }}
                    excludeIds={initialProducts.map((p) => Number(p.id))}
                    excludeNote="Already added to order"
                  />
                ) : null}

                <div className="mt-2">
                  {selectedProducts.length > 0 ? (
                    <div className="divide-y">
                      {selectedProducts.map((variant, index) => (
                        <OrderProductItem
                          key={variant.id}
                          variant={variant}
                          index={index}
                          formik={formik}
                          handleRemoveProduct={handleRemoveProduct}
                          handleUpdateProduct={handleUpdateProduct}
                          isEditable={isEditable}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <h4 className="text-md mb-2 font-medium text-gray-700">
                    Payment
                  </h4>
                  <Button
                    variant="text"
                    onClick={() => {
                      setPaymentEdit((prev) => !prev);
                    }}
                    className="text-sm text-primary-700 hover:text-primary-800"
                  >
                    {paymentEdit ? t("text_cancel") : t("text_edit")}
                  </Button>
                </div>
                <div className="space-y-4 rounded-xl bg-greyish p-5">
                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-900"
                    >
                      {t("text_sub_total")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="flex justify-end text-right">
                      {paymentEdit ? (
                        <Field
                          title={t("text_sub_total")}
                          name="subTotal"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subTotal}
                          touched={formik.touched.subTotal}
                          errors={formik.errors.subTotal}
                          isLabel={false}
                          placeholder={t("text_sub_total")}
                          className="w-24"
                        />
                      ) : (
                        <p className="text-sm font-medium text-gray-900">
                          $ {formik.values.subTotal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-700"
                    >
                      {t("text_add_shipping")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-900">
                        $ 0.00
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-700"
                    >
                      {t("text_estimated_tax")}
                    </label>
                    <span className="text-xs">Not calculated</span>
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-900">
                        $ 0.00
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="total"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-900"
                    >
                      {t("text_total")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="flex justify-end text-right">
                      {paymentEdit ? (
                        <Field
                          name="total"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.total}
                          touched={formik.touched.total}
                          errors={formik.errors.total}
                          isLabel={false}
                          placeholder={t("text_total")}
                          className="w-24"
                        />
                      ) : (
                        <p className="text-sm font-medium text-gray-900">
                          $ {formik.values.total}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4 space-y-4">
              <div className="divide-y divide-gray-300 rounded-xl bg-greyish px-4 py-5 sm:p-6">
                <fieldset className="pb-4">
                  <h4 className="text-md block font-medium text-gray-900">
                    {t("text_customer")}
                  </h4>
                  <span className="block text-sm font-normal text-gray-500">
                    <Link
                      to={`/sales/customers/${customer.id}`}
                      className="text-gray-700 transition hover:text-gray-900"
                    >
                      {customer.customerName}
                    </Link>
                  </span>
                </fieldset>
                <fieldset className="py-4">
                  <Field
                    title={t("text_purchase_number")}
                    name="purchaseNumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.purchaseNumber}
                    touched={formik.touched.purchaseNumber}
                    errors={formik.errors.purchaseNumber}
                  />
                </fieldset>
                <fieldset className="py-4">
                  <h4 className="block text-sm font-medium text-gray-900">
                    Contact Information
                  </h4>
                  <span className="block text-sm font-normal text-gray-500">
                    <a href={`mailto:${customer.email}`}>{customer.email}</a>
                  </span>
                </fieldset>
                <fieldset className="py-4">
                  <h4 className="text-md block font-medium text-gray-900">
                    Delivery Information
                  </h4>
                  <FieldCustomerShippingAddress
                    title={t("text_trading_address")}
                    data={customer.tradingAddresses}
                    onChange={(value: TradingAddress | null) => {
                      if (!value) {
                        formik.setFieldValue("customerTradingAddressId", null);
                        return;
                      }
                      formik.setFieldValue(
                        "customerTradingAddressId",
                        parseInt(value.id)
                      );
                    }}
                    value={formik.values.customerTradingAddressId}
                    className={classNames(
                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      formik.touched.customerTradingAddressId &&
                        formik.errors.customerTradingAddressId
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                  />
                  <fieldset className="mt-4">
                    <FieldDatepicker
                      title={t("text_delivery_date")}
                      name="deliveryDate"
                      onChange={(value) => {
                        if (!Array.isArray(value)) {
                          formik.setFieldValue(
                            "deliveryDate",
                            value ? new Date(value).toISOString() : ""
                          );
                        }
                      }}
                      minDate={new Date()}
                      selected={
                        formik.values.deliveryDate
                          ? new Date(formik.values.deliveryDate)
                          : null
                      }
                      touched={formik.touched.deliveryDate}
                      errors={formik.errors.deliveryDate}
                    />
                  </fieldset>
                  <div className="mt-3">
                    <Field
                      title={t("text_delivery_note")}
                      name="notes"
                      type="textarea"
                      className="h-24"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.notes}
                      touched={formik.touched.notes}
                      errors={formik.errors.notes}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

function OrderProductItem({
  variant,
  index,
  formik,
  handleRemoveProduct,
  handleUpdateProduct,
  isEditable,
}: {
  variant: RowProduct & {
    orderComments: string;
  };
  index: number;
  formik: FormikProps<InitialValues>;
  handleRemoveProduct: (product: RowProduct) => void;
  handleUpdateProduct: (
    variant: RowProduct,
    quantity: number,
    price: number,
    orderComments: string
  ) => void;
  isEditable: boolean;
}) {
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState<boolean>(false);
  useEffect(() => {
    setShowComments(variant.orderComments !== "");
  }, [variant.orderComments]);

  return (
    <div className="relative py-2 md:py-4">
      <Button
        variant="text"
        onClick={() => {
          handleRemoveProduct(variant);
        }}
        className="absolute right-0 top-4 mt-5"
      >
        <span className="sr-only">Remove {variant.name}</span>
        <span
          aria-hidden="true"
          className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
        />
      </Button>
      <div className="mr-6 flex items-center">
        <span className="mr-4 text-sm text-gray-700">{index + 1}.</span>
        <div className="h-10 w-10 flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={
              variant.featureImageUrl ? variant.featureImageUrl : placeholder
            }
            alt={variant.name}
          />
        </div>
        <div className="ml-4 mr-4">
          {String(variant?.productId)?.length === 10 ? (
            <span className="text-sm font-medium text-gray-900">
              {variant.name}
              <br />
              <span className="text-sm font-normal text-gray-500">Deleted</span>
            </span>
          ) : (
            <Link
              to={`/inventory/products/${variant.productId}`}
              target="_blank"
              className="text-sm font-medium text-gray-900"
            >
              {variant.name}
            </Link>
          )}
          {getIn(formik.errors, `products[${index}].productId`) &&
          getIn(formik.touched, `products[${index}].productId`) ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`products[${index}].quantity-error`}
            >
              {getIn(formik.errors, `products[${index}].productId`)}
            </p>
          ) : null}
        </div>
        <div className="ml-auto flex items-center">
          <div className="mr-4">
            <Field
              title={t("text_quantity")}
              name={`products[${index}].quantity`}
              type="number"
              onChange={(e) => {
                const quantity = parseInt(e.target.value);
                const minimum = variant.minimumQuantity || 1;
                if (quantity >= minimum && quantity <= variant.inventory) {
                  formik.setFieldValue(`products[${index}].quantity`, quantity);
                }
                if (!isNaN(quantity)) {
                  handleUpdateProduct(
                    variant,
                    quantity,
                    variant.price,
                    variant.orderComments
                  );
                }
              }}
              value={variant.quantity}
              minLength={variant.minimumQuantity || 1}
              className="w-24"
            />
          </div>
          <div className="mr-4">
            <Field
              title={t("text_price")}
              name={`products[${index}].price`}
              type="number"
              onChange={(e) => {
                const price = parseFloat(e.target.value);
                if (!isNaN(price)) {
                  handleUpdateProduct(
                    variant,
                    variant.quantity,
                    price,
                    variant.orderComments
                  );
                }
              }}
              value={variant.price}
              className="w-24"
            />
          </div>
        </div>
      </div>
      {isEditable && (
        <Fragment>
          {showComments && (
            <div className="ml-20 mr-6">
              <Field
                title={t("text_comments")}
                type="textarea"
                onChange={(e) => {
                  handleUpdateProduct(
                    variant,
                    variant.quantity,
                    variant.price,
                    e.target.value
                  );
                }}
                value={variant.orderComments}
                className="h-14"
              />
            </div>
          )}
          <div className="mr-10 mt-2 flex justify-end">
            {showComments ? (
              <Button
                variant="text"
                className="inline-flex text-xs font-medium text-blue-900 hover:underline"
                onClick={() => setShowComments(false)}
              >
                Hide Comment
              </Button>
            ) : (
              <Button
                variant="text"
                className="inline-flex text-xs font-medium text-blue-900 hover:underline"
                onClick={() => setShowComments(true)}
              >
                Add Comment
              </Button>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}
