import { RadioGroup } from "@headlessui/react";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldFleetVendor,
} from "../../../../components/form";
import { FleetVendorClassification } from "../../../../graphql/fleets/settings/vendors";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleFinancialForm } from "../../../../graphql/fleets/vehicles/pages/financial";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { classNames, useFormikErrors } from "../../../../utils";
import FinancialLeaseFields from "./FinancialLeaseFields";
import FinancialLoanFields from "./FinancialLoanFields";

export default function Form({
  initialValues,
  onChange = () => {},
  onClear = () => {},
  onSubmit,
  actionLabel,
  vehicleBasic,
}: {
  initialValues: VehicleFinancialForm;
  onChange?: (values: VehicleFinancialForm) => void;
  onClear?: () => void;
  onSubmit: (values: VehicleFinancialForm, actions: any) => void;
  actionLabel: string;
  vehicleBasic: VehicleBasic | null;
}) {
  const { t } = useTranslation();

  const VehicleFinancialsSchema = Yup.object().shape({
    fleetVendorId: Yup.number().nullable(),
    purchaseDate: Yup.string().nullable(),
    purchasePrice: Yup.number().nullable(),
    odometer: Yup.number().nullable(),
    notes: Yup.string().nullable(),
    warrantyExpiryDate: Yup.string().nullable(),
    maxOdometerValue: Yup.number().nullable(),
    financeType: Yup.number().nullable(),
    loanDetails: Yup.object()
      .shape({
        lender: Yup.string().nullable(),
        dateOfLoan: Yup.string().nullable(),
        amountOfLoan: Yup.number().nullable(),
        annualPercentageRate: Yup.number().nullable(),
        downPayment: Yup.number().nullable(),
        firstPaymentDate: Yup.string().nullable(),
        monthlyPayment: Yup.number().nullable(),
        noOfPayments: Yup.number().nullable(),
        loanEndDate: Yup.string().nullable(),
        notes: Yup.string().nullable(),
      })
      .nullable(),
    leaseDetails: Yup.object()
      .shape({
        vendor: Yup.string().nullable(),
        dateOfLease: Yup.string().nullable(),
        cost: Yup.number().nullable(),
        downPayment: Yup.number().nullable(),
        firstPaymentDate: Yup.string().nullable(),
        monthlyPayment: Yup.string().nullable(),
        noOfPayments: Yup.number().nullable(),
        leaseEndDate: Yup.string().nullable(),
        residualValue: Yup.number().nullable(),
        contractMileageCap: Yup.string().nullable(),
        excessMileageCap: Yup.number().nullable(),
        leaseNumber: Yup.string().nullable(),
        notes: Yup.string().nullable(),
      })
      .nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: VehicleFinancialsSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  useEffect(() => {
    onChange(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const financeTypes = useMemo(
    () => [
      {
        label: "Loan",
        value: 0,
        description: "This vehicle is associated with a loan",
      },
      {
        label: "Lease",
        value: 1,
        description: "This vehicle is being lease",
      },
      {
        label: "No Financing",
        value: 2,
        description: "This vehicle is not being financed",
      },
    ],
    []
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-2 text-base font-medium text-gray-900">
          Purchase Details
        </div>
        <p className="mb-4 text-sm">
          Enter the purchase details for this vehicle.
        </p>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <FieldDatepicker
              title={t("text_purchase_date")}
              name="purchaseDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "purchaseDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.purchaseDate
                  ? new Date(formik.values.purchaseDate)
                  : null
              }
              touched={formik.touched.purchaseDate}
              errors={formik.errors.purchaseDate}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_purchase_price")}
              name="purchasePrice"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.purchasePrice ?? ""}
              touched={formik.touched.purchasePrice}
              errors={formik.errors.purchasePrice}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_odometer")}
              name="odometer"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.odometer ?? ""}
              touched={formik.touched.odometer}
              errors={formik.errors.odometer}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_notes")}
              name="notes"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notes}
              touched={formik.touched.notes}
              errors={formik.errors.notes}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <label className="block text-sm font-medium text-gray-900">
              {t("text_fleet_vendor")}
            </label>
            <FieldFleetVendor
              title={t("text_fleet_vendor")}
              value={formik.values.fleetVendorId}
              onChange={(value) => {
                formik.setFieldValue("fleetVendorId", value);
              }}
              classification={FleetVendorClassification.VEHICLE}
              className={classNames(
                "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                formik.touched.fleetVendorId && formik.errors.fleetVendorId
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.fleetVendorId && formik.errors.fleetVendorId ? (
              <p
                className="mt-2 text-sm text-red-600"
                id="fleetVendorId-errors"
              >
                {formik.errors.fleetVendorId}
              </p>
            ) : null}
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-2 text-base font-medium text-gray-900">Warranty</div>
        <p className="mb-4 text-sm">
          Enter the warranty details for this vehicle.
        </p>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <div className="block text-sm font-medium text-gray-900">
              {t("text_expiry_date")}
            </div>
            <p className="mb-1 text-sm">Last day of warranty coverage</p>
            <FieldDatepicker
              title={t("text_expiry_date")}
              name="warrantyExpiryDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "warrantyExpiryDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.warrantyExpiryDate
                  ? new Date(formik.values.warrantyExpiryDate)
                  : null
              }
              touched={formik.touched.warrantyExpiryDate}
              errors={formik.errors.warrantyExpiryDate}
              isLabel={false}
              placeholderText={t("text_expiry_date")}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <div className="block text-sm font-medium text-gray-900">
              {t("text_max_odometer_value")}
            </div>
            <p className="mb-1 text-sm">
              Maximum odometer allowed by warranty coverage
            </p>
            <Field
              title={t("text_max_odometer_value")}
              name="maxOdometerValue"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.maxOdometerValue ?? ""}
              touched={formik.touched.maxOdometerValue}
              errors={formik.errors.maxOdometerValue}
              isLabel={false}
              placeholder={t("text_max_odometer_value")}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
        </div>
      </fieldset>

      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-12">
            <RadioGroup
              value={formik.values.financeType}
              onChange={(value: number) => {
                formik.setFieldValue("financeType", value);
                // if (value === VehicleFinanceType.LOAN) {
                //   formik.setFieldValue("leaseDetails", null);
                // } else if (value === VehicleFinanceType.LEASE) {
                //   formik.setFieldValue("loanDetails", null);
                // } else {
                //   formik.setFieldValue("loanDetails", null);
                //   formik.setFieldValue("leaseDetails", null);
                // }
              }}
            >
              <RadioGroup.Label className="mb-2 text-base font-medium text-gray-900">
                {t("text_loan_lease")}
              </RadioGroup.Label>

              <div className="isolate mt-1 flex -space-x-px rounded-md bg-white">
                {financeTypes.map((type, typeIdx) => (
                  <RadioGroup.Option
                    key={type.label}
                    value={type.value}
                    className={({ checked }) =>
                      classNames(
                        typeIdx === 0 ? "rounded-bl-md rounded-tl-md" : "",
                        typeIdx === financeTypes.length - 1
                          ? "rounded-br-md rounded-tr-md"
                          : "",
                        checked
                          ? "z-10 border-primary-200 bg-primary-50"
                          : "border-gray-200",
                        "relative flex w-full cursor-pointer border p-4 focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? "border-transparent bg-primary-600"
                              : "border-gray-300 bg-white",
                            active
                              ? "ring-2 ring-primary-500 ring-offset-2"
                              : "",
                            "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                          )}
                          aria-hidden="true"
                        >
                          <span className="h-1.5 w-1.5 rounded-full bg-white" />
                        </span>
                        <span className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? "text-primary-900" : "text-gray-900",
                              "block text-sm font-medium"
                            )}
                          >
                            {type.label}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? "text-primary-700" : "text-gray-500",
                              "block text-sm"
                            )}
                          >
                            {type.description}
                          </RadioGroup.Description>
                        </span>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
            {formik.touched.financeType && formik.errors.financeType ? (
              <p className="mt-2 text-sm text-red-600" id="financeType-errors">
                {formik.errors.financeType}
              </p>
            ) : null}
          </div>
          <FinancialLoanFields formik={formik} />
          <FinancialLeaseFields formik={formik} />
        </div>
      </fieldset>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/fleets/vehicles" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
