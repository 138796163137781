import {
  ApolloCache,
  ApolloQueryResult,
  DefaultContext,
  gql,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  ChatBubbleBottomCenterTextIcon,
  FolderIcon,
  InboxArrowDownIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { FormikProps, getIn, useFormik } from "formik";
import { DateTime } from "luxon";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import * as Yup from "yup";

import { Logo, Spinner } from "../../../../animations";
import placeholder from "../../../../assets/placeholder.svg";
import { Avatar, ButtonDropdown } from "../../../../components/appearance";
import {
  Button,
  Field,
  FieldCustomerShippingAddress,
  FieldDatepicker,
  FieldOrderProducts,
} from "../../../../components/form";
import {
  APPROVE_PACKING_PRODUCT,
  GET_PACKING_BY_ID,
  Packing,
  REJECT_PACKING_PRODUCT,
} from "../../../../graphql/pickpack/packing";
import {
  Customer,
  DELETE_ORDER_PRODUCT,
  GET_ORDER_BY_ID,
  Order,
  OrderStatus,
  ProductApprove,
  renderStatus,
  RowProduct,
  Timeline,
  TradingAddress,
} from "../../../../graphql/sales/orders";
import {
  OrderPage,
  OrderSlip,
  OrderSlipDepartment,
  OrderSlipProps,
} from "../../../../print/orders";
import { classNames, formatFloat, formatTime } from "../../../../utils";
import { useAuth } from "../../../auth";

interface RowOrderSlipProps extends OrderSlipProps {
  departments?: OrderSlipDepartment[];
}

type InitialValues = Pick<Order, "status" | "total" | "subTotal" | "notes"> & {
  purchaseNumber: string;
  customerTradingAddressId: number | null;
  deliveryDate: string | null;
  products: {
    productId: number;
    price: number;
    quantity: number;
    orderComments: string;
  }[];
  customerId: number | null;
};

const orderSchema = Yup.object().shape({
  customerId: Yup.number().required("Required"),
  products: Yup.array().of(
    Yup.object().shape({
      productId: Yup.number().test(
        "len",
        "Please remove deleted product",
        (val) => {
          return String(val).length < 10;
        }
      ),
      price: Yup.number(),
      quantity: Yup.number(),
      orderComments: Yup.string().nullable(),
    })
  ),
  purchaseNumber: Yup.string().nullable(),
  customerTradingAddressId: Yup.number().nullable(),
  deliveryDate: Yup.date().required("Required"),
  notes: Yup.string(),
  subTotal: Yup.number(),
  total: Yup.number(),
  status: Yup.number(),
});

export default function Form({
  initialValues,
  onSubmit,
  onRefetch,
  orderNumber,
  orderAddress,
  orderUpdatedAt,
  orderDate,
  products: initialProducts,
  customer,
  timelines,
}: {
  initialValues: InitialValues;
  onSubmit: (values: any, actions: any) => void;
  onRefetch: () => Promise<ApolloQueryResult<any>>;
  orderNumber: string;
  orderAddress: string;
  orderUpdatedAt: string;
  orderDate: string;
  products: (RowProduct & {
    orderComments: string;
  })[];
  customer: Customer;
  timelines: Timeline[];
}) {
  const { t, i18n } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();
  const { currentUser } = useAuth();
  const [paymentEdit, setPaymentEdit] = useState<boolean>(false);
  const isEditable = useMemo(
    () =>
      initialValues.status === OrderStatus.DRAFT ||
      initialValues.status === OrderStatus.OPEN ||
      initialValues.status === OrderStatus.PACKING,
    [initialValues.status]
  );

  const [selectedProducts, setSelectedProducts] = useState<
    (RowProduct & {
      orderComments: string;
    })[]
  >([]);

  useEffect(() => {
    if (initialProducts.length > 0) {
      setSelectedProducts(initialProducts);
    }
  }, [initialProducts]);

  const orderSlipComponentRef = useRef(null);
  const orderPageComponentRef = useRef(null);
  const [loadingPrint, setLoadingPrint] = useState(false);

  const dataOrderPage = {
    customer,
    orderNumber,
    address: orderAddress,
    notes: initialValues.notes,
    items: initialProducts.map((product) => ({
      id: String(product.id),
      name: product.name,
      plu: product.plu,
      stockCode: product.stockCode,
      variantTitle: product.variantTitle,
      price: product.price,
      quantity: product.quantity,
      productApprove: product.productApprove,
    })),
    orderStatus: initialValues.status,
    orderDate,
  };

  const [approvePackingProduct, { loading: approveLoading }] = useMutation(
    APPROVE_PACKING_PRODUCT,
    {
      refetchQueries: [
        {
          query: GET_ORDER_BY_ID,
          variables: { id: orderId },
        },
      ],
    }
  );
  const [rejectPackingProduct, { loading: rejectLoading }] = useMutation(
    REJECT_PACKING_PRODUCT,
    {
      refetchQueries: [
        {
          query: GET_ORDER_BY_ID,
          variables: { id: orderId },
        },
      ],
    }
  );

  const [deleteOrderProduct] = useMutation(DELETE_ORDER_PRODUCT, {
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID,
        variables: { id: orderId },
      },
    ],
  });

  const {
    data: dataPacking,
    loading: loadingPacking,
    refetch: refetchPacking,
  } = useQuery<{
    fetchPackingProduct: Packing;
  }>(GET_PACKING_BY_ID, {
    variables: { id: orderId },
  });

  useEffect(() => {
    refetchPacking();
  }, [refetchPacking]);

  const printData: RowOrderSlipProps | null = useMemo(() => {
    if (!dataPacking?.fetchPackingProduct) return null;
    const pdata = dataPacking?.fetchPackingProduct;
    const departments =
      pdata?.items?.map((item) => ({
        id: item.variant.product?.department.id ?? "",
        name: item.variant.product?.department.name ?? "",
      })) || [];
    const departmentsFiltered = departments.reduce((acc, current) => {
      const x = acc.find(
        (item) => item.id === current.id && item.name === current.name
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [] as OrderSlipDepartment[]);

    return {
      ...pdata,
      departmentName: "",
      departments: departmentsFiltered,
    };
  }, [dataPacking?.fetchPackingProduct]);

  const orderSlipPrintContent = useCallback(() => {
    return orderSlipComponentRef.current;
  }, []);

  const orderPagePrintContent = useCallback(() => {
    return orderPageComponentRef.current;
  }, []);

  const handlePrintOrderSlip = useReactToPrint({
    content: orderSlipPrintContent,
    documentTitle: `Alpha Fresh - Packing Slip - ${new Date().toISOString()}`,
    onBeforeGetContent: () => {
      setLoadingPrint(true);
    },
    onAfterPrint: () => {
      setLoadingPrint(false);
    },
    removeAfterPrint: true,
  });

  const handlePrintOrderPage = useReactToPrint({
    content: orderPagePrintContent,
    documentTitle: `Alpha Fresh - Order Page - ${new Date().toISOString()}`,
    onBeforeGetContent: () => {
      setLoadingPrint(true);
    },
    onAfterPrint: () => {
      setLoadingPrint(false);
    },
    removeAfterPrint: true,
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: orderSchema,
    onSubmit,
  });

  const handlePriceChange = useCallback(() => {
    const total = formik.values.products.reduce((total, product) => {
      const productApprove =
        selectedProducts.find((p) => p.id === String(product.productId))
          ?.productApprove === ProductApprove.APPROVED;
      if (productApprove) {
        return total + product.price * product.quantity;
      }
      return total;
    }, 0);
    formik.setFieldValue("subTotal", formatFloat(total));
    formik.setFieldValue("total", formatFloat(total));
  }, [formik.values.products, selectedProducts]);

  useEffect(() => {
    handlePriceChange();
  }, [selectedProducts, handlePriceChange]);

  const handleRemoveProduct = useCallback(
    (product: RowProduct) => {
      if (product.oid) {
        deleteOrderProduct({
          variables: {
            id: product.oid,
          },
        })
          .then(async () => {
            await onRefetch();
            toast.success("Product deleted successfully");
            return;
          })
          .catch((error) => {
            toast.error(error.message);
          });
        return;
      }
      const newSelectedProducts = selectedProducts.filter(
        (selectedProduct) => selectedProduct.id !== product.id
      );
      setSelectedProducts(newSelectedProducts);

      if (newSelectedProducts.length === 0) {
        formik.setFieldValue("products", []);
        return;
      }

      const updatedProducts = formik.values.products.filter(
        (p) => parseInt(product.id) !== p.productId
      );
      formik.setFieldValue("products", updatedProducts);
    },
    [selectedProducts, formik.values.products]
  );

  const handleUpdateProduct = useCallback(
    (
      variant: RowProduct,
      quantity: number,
      price: number,
      orderComments: string
    ) => {
      const updatedSelectedProducts = selectedProducts.map((p) => {
        if (p.id === variant.id) {
          return {
            ...p,
            quantity,
            price,
            orderComments,
          };
        }
        return p;
      });
      const updatedProducts = formik.values.products.map((p) => {
        if (p.productId === parseInt(variant.id)) {
          return {
            ...p,
            quantity,
            price,
            orderComments,
          };
        }
        return p;
      });
      setSelectedProducts(updatedSelectedProducts);
      formik.setFieldValue("products", updatedProducts);
    },
    [selectedProducts, formik.values.products]
  );

  const MessageSchema = Yup.object().shape({
    message: Yup.string().required("Required"),
  });

  const CREATE_ORDER_MESSAGE = gql`
    mutation OrderMessageCreate($orderId: Int!, $message: String!) {
      orderMessageCreate(
        input: { params: { orderId: $orderId, message: $message } }
      ) {
        message
      }
    }
  `;

  const DELETE_ORDER_MESSAGE = gql`
    mutation OrderMessageDelete($id: ID!) {
      orderMessageDelete(input: { id: $id }) {
        message
      }
    }
  `;

  const [createOrderMessage] = useMutation(CREATE_ORDER_MESSAGE, {
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID,
        variables: { id: orderId },
      },
    ],
  });
  const [deleteOrderMessage] = useMutation(DELETE_ORDER_MESSAGE, {
    refetchQueries: [
      {
        query: GET_ORDER_BY_ID,
        variables: { id: orderId },
      },
    ],
  });

  const handleDeleteMessage = useCallback(
    (id: string) => {
      deleteOrderMessage({
        variables: {
          id,
        },
      })
        .then(({ data }) => {
          if (data?.orderMessageDelete) {
            toast.success(data?.orderMessageDelete?.message);
            onRefetch();
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    },
    [deleteOrderMessage, onRefetch]
  );

  const formikMessage = useFormik({
    initialValues: {
      message: "",
    },
    enableReinitialize: true,
    validationSchema: MessageSchema,
    onSubmit: (values: { message: string }, actions) => {
      if (!orderId) return;
      createOrderMessage({
        variables: {
          orderId: parseInt(orderId),
          message: values.message,
        },
      })
        .then(({ data }) => {
          if (data?.orderMessageCreate) {
            toast.success(data?.orderMessageCreate?.message);
            formikMessage.resetForm();
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  return (
    <>
      {loadingPrint && (
        <div className="fixed left-1/2 top-1/2 z-30 m-0 -translate-x-1/2 -translate-y-1/2">
          <Logo />
        </div>
      )}
      <div className="mx-auto max-w-6xl px-5 py-6 sm:py-8">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_order")} - #{orderNumber} Updated on{" "}
                {DateTime.fromISO(orderUpdatedAt, {
                  locale: i18n.language,
                }).toLocaleString(DateTime.DATETIME_MED)}
                <span className="ml-2">
                  {renderStatus(initialValues.status)}
                </span>
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_order")}
              </p>
            </div>
            <div className="mt-4 flex space-x-2 sm:ml-16 sm:mt-0">
              <Button type="link" href="/sales/orders" variant="secondary">
                {t("text_cancel")}
              </Button>

              {initialValues.status === OrderStatus.DRAFT ? (
                <ButtonDropdown
                  childrens={[
                    {
                      label: t("text_save_as_draft"),
                      icon: () => (
                        <InboxArrowDownIcon
                          className="mr-2 h-5 w-5 text-current"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        formik.setFieldValue("status", OrderStatus.DRAFT);
                        formik.handleSubmit();
                      },
                    },
                    {
                      label: t("text_process_order"),
                      icon: () => (
                        <FolderIcon
                          className="mr-2 h-5 w-5 text-current"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        formik.setFieldValue("status", OrderStatus.OPEN);
                        formik.handleSubmit();
                      },
                    },
                  ]}
                  disabled={formik.isSubmitting || loadingPacking}
                >
                  {t("text_actions")}
                  <ChevronDownIcon
                    className="ml-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </ButtonDropdown>
              ) : initialValues.status === OrderStatus.OPEN ? (
                <>
                  <Button
                    variant="tertiary"
                    className="mr-2"
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      formik.setFieldValue("status", OrderStatus.OPEN);
                      formik.handleSubmit();
                    }}
                  >
                    {t("text_save_changes")}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      formik.setFieldValue(
                        "status",
                        OrderStatus.PICKING_PENDING
                      );
                      formik.handleSubmit();
                    }}
                  >
                    {t("text_move_to_picking")}
                  </Button>
                </>
              ) : initialValues.status === OrderStatus.PACKING ? (
                <Button
                  variant="tertiary"
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    formik.setFieldValue("status", OrderStatus.PACKING);
                    formik.handleSubmit();
                  }}
                >
                  {t("text_save_changes")}
                </Button>
              ) : (
                <ButtonDropdown
                  childrens={[
                    {
                      label: t("text_print_packing_slip"),
                      icon: () => (
                        <PrinterIcon
                          className="mr-2 h-5 w-5 text-current"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        handlePrintOrderSlip();
                      },
                    },
                    {
                      label: t("text_print_order_page"),
                      icon: () => (
                        <PrinterIcon
                          className="mr-2 h-5 w-5 text-current"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        handlePrintOrderPage();
                      },
                    },
                  ]}
                  disabled={formik.isSubmitting}
                >
                  {t("text_print_options")}
                  <ChevronDownIcon
                    className="ml-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </ButtonDropdown>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
            <div className="space-y-4 md:col-span-8">
              <div className="rounded-xl bg-greyish p-5">
                <label
                  htmlFor="description"
                  className="mb-1 block text-sm font-medium text-gray-900"
                >
                  {t("text_products")}
                </label>

                {formik.values.customerId ? (
                  <FieldOrderProducts
                    customerId={formik.values.customerId}
                    data={selectedProducts}
                    value={selectedProducts.flatMap((p) => Number(p.id))}
                    onChange={(products) => {
                      const updatedProducts = products.map((p) => {
                        const existingProduct = formik.values.products.find(
                          (product) => product.productId === Number(p.id)
                        );
                        return {
                          productId: Number(p.id),
                          quantity: existingProduct?.quantity || p.quantity,
                          price: existingProduct?.price || p.price,
                          orderComments: existingProduct?.orderComments || "",
                        };
                      });
                      formik.setFieldValue("products", updatedProducts);
                      setSelectedProducts(
                        products.map((p) => ({
                          ...p,
                          orderComments: "",
                        }))
                      );
                    }}
                    excludeIds={initialProducts.map((p) => Number(p.id))}
                    excludeNote="Already added to order"
                  />
                ) : null}

                <div className="mt-2">
                  {selectedProducts.length > 0 ? (
                    <div className="divide-y">
                      {selectedProducts.map((variant, index) => (
                        <OrderProductItem
                          key={variant.id}
                          variant={variant}
                          index={index}
                          formik={formik}
                          isEditable={isEditable}
                          handleRemoveProduct={handleRemoveProduct}
                          handleUpdateProduct={handleUpdateProduct}
                          approvePackingProduct={approvePackingProduct}
                          approveLoading={approveLoading}
                          rejectPackingProduct={rejectPackingProduct}
                          rejectLoading={rejectLoading}
                          onRefetch={onRefetch}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <h4 className="text-md mb-2 font-medium text-gray-700">
                    Payment
                  </h4>
                  <Button
                    variant="text"
                    onClick={() => {
                      setPaymentEdit((prev) => !prev);
                    }}
                    className="text-sm text-primary-700 hover:text-primary-800"
                  >
                    {paymentEdit ? t("text_cancel") : t("text_edit")}
                  </Button>
                </div>
                <div className="space-y-4 rounded-xl bg-greyish p-5">
                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-900"
                    >
                      {t("text_sub_total")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="flex justify-end text-right">
                      {paymentEdit ? (
                        <Field
                          title={t("text_sub_total")}
                          name="subTotal"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subTotal}
                          touched={formik.touched.subTotal}
                          errors={formik.errors.subTotal}
                          isLabel={false}
                          placeholder={t("text_sub_total")}
                          className="w-24"
                        />
                      ) : (
                        <p className="text-sm font-medium text-gray-900">
                          $ {formik.values.subTotal}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-700"
                    >
                      {t("text_add_shipping")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-900">
                        $ 0.00
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="subTotal"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-700"
                    >
                      {t("text_estimated_tax")}
                    </label>
                    <span className="text-xs">Not calculated</span>
                    <div className="text-right">
                      <p className="text-sm font-medium text-gray-900">
                        $ 0.00
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    <label
                      htmlFor="total"
                      className="col-span-2 mb-0 block text-sm font-medium text-gray-900"
                    >
                      {t("text_total")}
                    </label>
                    <span className="text-xs">-</span>
                    <div className="flex justify-end text-right">
                      {paymentEdit ? (
                        <Field
                          name="total"
                          type="number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.total}
                          touched={formik.touched.total}
                          errors={formik.errors.total}
                          isLabel={false}
                          className="w-24"
                        />
                      ) : (
                        <p className="text-sm font-medium text-gray-900">
                          $ {formik.values.total}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-4 space-y-4">
              <div className="divide-y divide-gray-300 rounded-xl bg-greyish px-4 py-5 sm:p-6">
                <fieldset className="pb-4">
                  <h4 className="text-md block font-medium text-gray-900">
                    {t("text_customer")}
                  </h4>
                  <span className="block text-sm font-normal text-gray-500">
                    <Link
                      to={`/sales/customers/${customer.id}`}
                      className="text-gray-700 transition hover:text-gray-900"
                    >
                      {customer.customerName}
                    </Link>
                  </span>
                </fieldset>
                <fieldset className="py-4">
                  <Field
                    title={t("text_purchase_number")}
                    name="purchaseNumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.purchaseNumber}
                    touched={formik.touched.purchaseNumber}
                    errors={formik.errors.purchaseNumber}
                  />
                </fieldset>
                <fieldset className="py-4">
                  <h4 className="block text-sm font-medium text-gray-900">
                    Contact Information
                  </h4>
                  <span className="block text-sm font-normal text-gray-500">
                    <a href={`mailto:${customer.email}`}>{customer.email}</a>
                  </span>
                </fieldset>
                <fieldset className="py-4">
                  <h4 className="text-md block font-medium text-gray-900">
                    Delivery Information
                  </h4>
                  <FieldCustomerShippingAddress
                    title={t("text_trading_address")}
                    data={customer.tradingAddresses}
                    onChange={(value: TradingAddress | null) => {
                      if (!value) {
                        formik.setFieldValue("customerTradingAddressId", null);
                        return;
                      }
                      formik.setFieldValue(
                        "customerTradingAddressId",
                        parseInt(value.id)
                      );
                    }}
                    value={formik.values.customerTradingAddressId}
                    className={classNames(
                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      formik.touched.customerTradingAddressId &&
                        formik.errors.customerTradingAddressId
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                  />
                  <div className="mt-3">
                    <FieldDatepicker
                      title={t("text_delivery_date")}
                      name="deliveryDate"
                      onChange={(value) => {
                        if (!Array.isArray(value)) {
                          formik.setFieldValue(
                            "deliveryDate",
                            value ? new Date(value).toISOString() : ""
                          );
                        }
                      }}
                      minDate={new Date()}
                      selected={
                        formik.values.deliveryDate
                          ? new Date(formik.values.deliveryDate)
                          : null
                      }
                      touched={formik.touched.deliveryDate}
                      errors={formik.errors.deliveryDate}
                    />
                  </div>
                  <div className="mt-3">
                    <Field
                      title={t("text_delivery_note")}
                      name="notes"
                      type="textarea"
                      className="h-24"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.notes}
                      touched={formik.touched.notes}
                      errors={formik.errors.notes}
                    />
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </form>
        <div className="mt-4 grid grid-cols-1 gap-6 md:grid-cols-12">
          <div className="space-y-4 md:col-span-8">
            <div>
              <div className="flex items-center justify-between">
                <h4 className="text-md mb-2 font-medium text-gray-700">
                  Timeline
                </h4>
              </div>
              <div className="rounded-xl bg-greyish px-4 py-8 md:px-6">
                <form onSubmit={formikMessage.handleSubmit}>
                  <div className="mb-8 flex items-start space-x-4">
                    <div className="relative flex-shrink-0">
                      <Avatar
                        firstName={currentUser?.firstName}
                        lastName={currentUser?.lastName}
                        className={classNames("relative z-10 h-12 w-12")}
                      />
                      {timelines.length ? (
                        <div className="absolute left-1/2 top-12 m-auto h-36 w-px -translate-x-1/2 bg-gray-300" />
                      ) : null}
                    </div>
                    <div className="min-w-0 flex-1">
                      <div className="relative">
                        <div className="overflow-hidden rounded-lg bg-white ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-primary-600">
                          <label htmlFor="message" className="sr-only">
                            Add message
                          </label>
                          <textarea
                            rows={3}
                            name="message"
                            id="message"
                            className="block w-full resize-none border-0 bg-transparent px-3.5 pb-10 pt-3.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Leave a message"
                            onChange={formikMessage.handleChange}
                            onBlur={formikMessage.handleBlur}
                            value={formikMessage.values.message}
                          />
                        </div>

                        <div className="absolute bottom-0 right-0 py-3 pl-3 pr-3">
                          <Button
                            type="submit"
                            className="px-3 py-2 text-sm"
                            disabled={formikMessage.isSubmitting}
                          >
                            {formikMessage.isSubmitting ? (
                              <>
                                <Spinner />
                                {t("text_processing")}
                              </>
                            ) : (
                              <>
                                <ChatBubbleBottomCenterTextIcon
                                  className="mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                {t("text_post")}
                              </>
                            )}
                          </Button>
                        </div>
                      </div>
                      {formikMessage.touched && formikMessage.errors ? (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id={`message-errors`}
                        >
                          {formikMessage.errors.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </form>
                <ul className="space-y-6 font-light">
                  {timelines.map((timeline, index) => {
                    if (timeline.message) {
                      return (
                        <li
                          key={timeline.id}
                          className="relative -ml-2 flex items-center gap-x-4 rounded-lg bg-white px-5 py-4 shadow-sm"
                        >
                          <div
                            className={classNames(
                              index === timelines.length - 1
                                ? "h-6"
                                : "-bottom-6",
                              "absolute left-5 top-0 flex w-6 justify-center"
                            )}
                          >
                            <div className="w-px bg-gray-300" />
                          </div>
                          <div className="absolute left-4 top-0 z-10 h-full w-6 bg-white"></div>
                          <div className="relative z-20 -ml-2 flex h-10 w-10 flex-none items-center justify-center overflow-hidden rounded-full bg-greyish">
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </div>
                          <div
                            className={classNames(
                              "flex-auto py-0.5 text-sm leading-5 text-gray-500"
                            )}
                            dangerouslySetInnerHTML={{
                              __html: timeline.message,
                            }}
                          />
                          <time
                            dateTime={timeline.updatedAt}
                            className="flex-none py-0.5 text-sm leading-5 text-gray-500"
                          >
                            {formatTime(timeline.updatedAt)}
                          </time>
                          <Button
                            variant="text"
                            onClick={() => {
                              handleDeleteMessage(timeline.id);
                            }}
                          >
                            <span className="sr-only">
                              Delete message, {timeline.message}
                            </span>
                            <span
                              aria-hidden="true"
                              className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
                            />
                          </Button>
                        </li>
                      );
                    }
                    return (
                      <li
                        key={timeline.id}
                        className="relative mx-3 flex gap-x-4"
                      >
                        <div
                          className={classNames(
                            index === timelines.length - 1
                              ? "h-6"
                              : "-bottom-6",
                            "absolute left-0 top-0 flex w-6 justify-center"
                          )}
                        >
                          <div className="w-px bg-gray-300" />
                        </div>

                        <div className="relative flex h-6 w-6 flex-none items-center justify-center rounded-full bg-greyish">
                          {index === 0 ? (
                            <CheckCircleIcon
                              className="h-6 w-6 text-primary-600"
                              aria-hidden="true"
                            />
                          ) : (
                            <div className="h-1.5 w-1.5 rounded-full bg-gray-500" />
                          )}
                        </div>
                        <div
                          className={classNames(
                            "flex-auto py-0.5 text-sm leading-5",
                            index === 0
                              ? "font-medium text-gray-900"
                              : "text-gray-500"
                          )}
                          dangerouslySetInnerHTML={{
                            __html: timeline.info,
                          }}
                        />
                        <time
                          dateTime={timeline.updatedAt}
                          className="flex-none py-0.5 text-sm leading-5 text-gray-500"
                        >
                          {formatTime(timeline.updatedAt)}
                        </time>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden">
        <div ref={orderSlipComponentRef}>
          {printData
            ? printData.departments?.map((department) => {
                const items = printData.items.filter(
                  (item) =>
                    item.variant?.product?.department?.id === department.id
                );
                return (
                  <Fragment key={`print-${department.id}`}>
                    <div className="page-break" />
                    <OrderSlip
                      data={{
                        ...printData,
                        items,
                        departmentName: department.name,
                      }}
                    />
                  </Fragment>
                );
              })
            : null}
        </div>
        <div ref={orderPageComponentRef}>
          <OrderPage data={dataOrderPage || null} />
        </div>
      </div>
    </>
  );
}

function OrderProductItem({
  variant,
  index,
  formik,
  isEditable,
  handleRemoveProduct,
  handleUpdateProduct,
  approvePackingProduct,
  approveLoading,
  rejectPackingProduct,
  rejectLoading,
  onRefetch,
}: {
  variant: RowProduct & {
    orderComments: string;
  };
  index: number;
  formik: FormikProps<InitialValues>;
  isEditable: boolean;
  handleRemoveProduct: (product: RowProduct) => void;
  handleUpdateProduct: (
    variant: RowProduct,
    quantity: number,
    price: number,
    orderComments: string
  ) => void;
  approvePackingProduct: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>;
  approveLoading: boolean;
  rejectPackingProduct: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>;
  rejectLoading: boolean;
  onRefetch: () => Promise<ApolloQueryResult<any>>;
}) {
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();
  const [showComments, setShowComments] = useState<boolean>(false);
  useEffect(() => {
    setShowComments(variant.orderComments !== "");
  }, [variant.orderComments]);

  const isApproved =
    variant.productApprove === undefined ||
    variant.productApprove === ProductApprove.APPROVED;
  return (
    <div className="relative py-2 md:py-4">
      <div className="absolute right-0 top-4 mt-5">
        {isApproved ? (
          <Button
            variant="text"
            onClick={() => {
              handleRemoveProduct(variant);
            }}
            className="mt-5"
          >
            <span className="sr-only">Remove {variant.name}</span>
            <span
              aria-hidden="true"
              className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
            />
          </Button>
        ) : (
          <div className="flex flex-col">
            <Button
              variant="text"
              onClick={() => {
                approvePackingProduct({
                  variables: {
                    orderId: orderId,
                    productIds: [variant.id],
                  },
                })
                  .then(async ({ data }) => {
                    await onRefetch();
                    toast.success(data?.approvePackingProduct?.message);
                  })
                  .catch((error) => {
                    toast.error(error.message);
                  });
              }}
              disabled={rejectLoading || approveLoading}
              className="m-1"
            >
              <span className="sr-only">Approve {variant.name}</span>
              <span
                aria-hidden="true"
                className="bi bi-check-circle h-6 w-6 text-lg text-primary-500"
              />
            </Button>
            <Button
              variant="text"
              onClick={() => {
                rejectPackingProduct({
                  variables: {
                    orderId: orderId,
                    productIds: [variant.id],
                  },
                })
                  .then(async ({ data }) => {
                    await onRefetch();
                    toast.success(data?.rejectPackingProduct?.message);
                  })
                  .catch((error) => {
                    toast.error(error.message);
                  });
              }}
              disabled={rejectLoading || approveLoading}
              className="m-1"
            >
              <span className="sr-only">Reject {variant.name}</span>
              <span
                aria-hidden="true"
                className="bi bi-x-circle h-6 w-6 text-lg text-red-500"
              />
            </Button>
          </div>
        )}
      </div>
      <div className="mr-6 flex items-center">
        <span className="mr-4 text-sm text-gray-700">{index + 1}.</span>
        <div className="h-10 w-10 flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={
              variant.featureImageUrl ? variant.featureImageUrl : placeholder
            }
            alt={variant.name}
          />
        </div>
        <div className="ml-4 mr-4">
          {String(variant?.productId).length === 10 ? (
            <span className="text-sm font-medium text-gray-900">
              {variant.name}
              <br />
              <span className="text-sm font-normal text-gray-500">Deleted</span>
            </span>
          ) : (
            <Link
              to={`/inventory/products/${variant.productId}`}
              target="_blank"
              className="text-sm font-medium text-gray-900"
            >
              {variant.name}
            </Link>
          )}
          {getIn(formik.errors, `products[${index}].productId`) &&
          getIn(formik.touched, `products[${index}].productId`) ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`products[${index}].quantity-error`}
            >
              {getIn(formik.errors, `products[${index}].productId`)}
            </p>
          ) : null}
        </div>
        <div className="ml-auto flex items-center">
          <div className="mr-4">
            <Field
              title={t("text_quantity")}
              name={`products[${index}].quantity`}
              type="number"
              onChange={(e) => {
                const quantity = parseInt(e.target.value);
                const minimum = variant.minimumQuantity || 1;
                if (quantity >= minimum && quantity <= variant.inventory) {
                  formik.setFieldValue(`products[${index}].quantity`, quantity);
                }
                if (!isNaN(quantity)) {
                  handleUpdateProduct(
                    variant,
                    quantity,
                    variant.price,
                    variant.orderComments
                  );
                }
              }}
              value={variant.quantity}
              minLength={variant.minimumQuantity || 1}
              disabled={!isApproved}
              className="w-24"
            />
          </div>
          <div className="mr-4">
            <Field
              title={t("text_price")}
              name={`products[${index}].price`}
              type="number"
              onChange={(e) => {
                const price = parseFloat(e.target.value);
                if (!isNaN(price)) {
                  handleUpdateProduct(
                    variant,
                    variant.quantity,
                    price,
                    variant.orderComments
                  );
                }
              }}
              value={variant.price}
              disabled={!isApproved}
              className="w-24"
            />
          </div>
        </div>
      </div>
      {isEditable && (
        <Fragment>
          {showComments && (
            <div className="ml-20 mr-6">
              <Field
                title={t("text_comments")}
                type="textarea"
                onChange={(e) => {
                  handleUpdateProduct(
                    variant,
                    variant.quantity,
                    variant.price,
                    e.target.value
                  );
                }}
                value={variant.orderComments}
                className="h-14"
              />
            </div>
          )}
          <div className="mr-10 mt-2 flex justify-end">
            {showComments ? (
              <Button
                variant="text"
                className="inline-flex text-xs font-medium text-blue-900 hover:underline"
                onClick={() => setShowComments(false)}
              >
                Hide Comment
              </Button>
            ) : (
              <Button
                variant="text"
                className="inline-flex text-xs font-medium text-blue-900 hover:underline"
                onClick={() => setShowComments(true)}
              >
                Add Comment
              </Button>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}
