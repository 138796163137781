import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  GET_VEHICLEFINANCIAL_BY_VEHICLE_ID,
  VehicleFinanceType,
  VehicleFinancial,
} from "../../../../graphql/fleets/vehicles/pages/financial";
import { formatDate } from "../../../../utils";

export function Financial({ vehicleId }: { vehicleId: string | undefined }) {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery<{
    fetchFinancialByVehicle: VehicleFinancial;
  }>(GET_VEHICLEFINANCIAL_BY_VEHICLE_ID, {
    variables: {
      vehicleId,
    },
  });

  const financial = useMemo<VehicleFinancial | null>(() => {
    let updatedFinancial = data?.fetchFinancialByVehicle as VehicleFinancial;
    if (!updatedFinancial) return null;
    updatedFinancial = {
      ...updatedFinancial,
      leaseDetails: Array.isArray(updatedFinancial.leaseDetails)
        ? updatedFinancial.leaseDetails.length
          ? updatedFinancial.leaseDetails[0]
          : null
        : updatedFinancial.leaseDetails,
      loanDetails: Array.isArray(updatedFinancial.loanDetails)
        ? updatedFinancial.loanDetails.length
          ? updatedFinancial.loanDetails[0]
          : null
        : updatedFinancial.loanDetails,
    };
    return updatedFinancial;
  }, [data]);

  return (
    <div className="space-y-5">
      <div className="rounded-xl bg-greyish p-5">
        <h3 className="text-md mb-3 font-medium text-gray-900">
          Purchase Details
        </h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
            <li className="flex items-center justify-between">
              <span>Purchase Date</span>
              <span className="text-gray-500">
                {financial?.purchaseDate
                  ? formatDate(financial.purchaseDate)
                  : "-"}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span>Purchase Price</span>
              <span className="text-gray-500">
                {financial?.purchasePrice ? financial.purchasePrice : "-"}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span>Odometer</span>
              <span className="text-gray-500">
                {financial?.odometer ? financial.odometer : "-"}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span>Notes</span>
              <span className="text-gray-500">
                {financial?.notes ? financial.notes : "-"}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span>Vendor</span>
              <span className="text-gray-500">
                {financial?.vendor ? financial.vendor.name : "-"}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="rounded-xl bg-greyish p-5">
        <h3 className="text-md mb-3 font-medium text-gray-900">Warranty</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
            <li className="flex items-center justify-between">
              <span>Expiry Date</span>
              <span className="text-gray-500">
                {financial?.warrantyExpiryDate
                  ? formatDate(financial.warrantyExpiryDate)
                  : "-"}
              </span>
            </li>
            <li className="flex items-center justify-between">
              <span>Max Odometer Value</span>
              <span className="text-gray-500">
                {financial?.maxOdometerValue ? financial.maxOdometerValue : "-"}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div className="rounded-xl bg-greyish p-5">
        <h3 className="text-md mb-3 font-medium text-gray-900">Loan/Lease</h3>
        <div className="rounded-xl bg-white p-4 shadow-sm md:p-6 xl:p-8">
          {financial?.financeType === VehicleFinanceType.LOAN ? (
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Lender</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.lender
                    ? financial.loanDetails.lender
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Date of Loan</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.dateOfLoan
                    ? financial.loanDetails.dateOfLoan
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Amount of Loan</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.amountOfLoan
                    ? financial.loanDetails.amountOfLoan
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Annual Percentage Rate</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.annualPercentageRate
                    ? financial.loanDetails.annualPercentageRate
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Down Payment</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.downPayment
                    ? financial.loanDetails.downPayment
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>First Payment Date</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.firstPaymentDate
                    ? financial.loanDetails.firstPaymentDate
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Monthly Payment</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.monthlyPayment
                    ? financial.loanDetails.monthlyPayment
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>No of Payments</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.noOfPayments
                    ? financial.loanDetails.noOfPayments
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Loan End Date</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.loanEndDate
                    ? financial.loanDetails.loanEndDate
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between sm:col-span-2">
                <span>Notes</span>
                <span className="text-gray-500">
                  {financial?.loanDetails?.notes
                    ? financial.loanDetails.notes
                    : "-"}
                </span>
              </li>
            </ul>
          ) : financial?.financeType === VehicleFinanceType.LEASE ? (
            <ul className="grid gap-4 sm:grid-cols-2 md:gap-6 xl:gap-8">
              <li className="flex items-center justify-between">
                <span>Vendor</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.vendor
                    ? financial.leaseDetails.vendor
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Date of Lease</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.dateOfLease
                    ? financial.leaseDetails.dateOfLease
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Cost</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.cost
                    ? financial.leaseDetails.cost
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Down Payment</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.downPayment
                    ? financial.leaseDetails.downPayment
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>First Payment Date</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.firstPaymentDate
                    ? financial.leaseDetails.firstPaymentDate
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Monthly Payment</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.monthlyPayment
                    ? financial.leaseDetails.monthlyPayment
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>No of Payments</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.noOfPayments
                    ? financial.leaseDetails.noOfPayments
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Lease End Date</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.leaseEndDate
                    ? financial.leaseDetails.leaseEndDate
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Residual Value</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.residualValue
                    ? financial.leaseDetails.residualValue
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Contract Mileage Cap</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.contractMileageCap
                    ? financial.leaseDetails.contractMileageCap
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Excess Mileage Cap</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.excessMileageCap
                    ? financial.leaseDetails.excessMileageCap
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span>Lease Number</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.leaseNumber
                    ? financial.leaseDetails.leaseNumber
                    : "-"}
                </span>
              </li>
              <li className="flex items-center justify-between sm:col-span-2">
                <span>Notes</span>
                <span className="text-gray-500">
                  {financial?.leaseDetails?.notes
                    ? financial.leaseDetails.notes
                    : "-"}
                </span>
              </li>
            </ul>
          ) : (
            <div className="py-4 text-center md:py-6 xl:py-8">No Financing</div>
          )}
        </div>
      </div>
    </div>
  );
}
