import { DashboardResource } from "./dashboard";
import { FleetsResource } from "./fleets";
import { InventoryResource } from "./inventory";
import { PickpackResource } from "./pickpack";
import { PurchaseResource } from "./purchases";
import { SalesResource } from "./sales";
import { UserManagementResource } from "./user-management";

export const pages: ResourceProps[] = [
  DashboardResource,
  UserManagementResource,
  InventoryResource,
  PurchaseResource,
  FleetsResource,
  SalesResource,
  PickpackResource,
  // {
  //   name: "Reports",
  //   description: "Reports management",
  //   path: "/reports",
  //   icon: "bi bi-journal-check",
  //   access: ["read-reports"],
  // },
];
