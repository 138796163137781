import { useMutation, useQuery } from "@apollo/client/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";

import { Head } from "../../../../components/core";
import { Button } from "../../../../components/form";
import { LimitBy, TableCursor } from "../../../../components/table";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  GET_PRICINGLEVELS,
  type PricingLevel,
  REMOVE_PRICINGLEVEL,
} from "../../../../graphql/inventory/pricing/pricing-levels";
import { AlertModal, AlertType, useAlert } from "../../../../hooks/useAlert";
import { formatDate } from "../../../../utils";
import { AuthShield } from "../../../auth/core";

const PricingLevelList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();

  const { data, loading, refetch } = useQuery(GET_PRICINGLEVELS);

  const pricingLevels: PricingLevel[] = useMemo(() => {
    let updatedPricingLevels = [];
    if (data?.fetchPricingLevels) {
      updatedPricingLevels = data.fetchPricingLevels.map(
        (item: PricingLevel) => ({
          ...item,
          createdAt: formatDate(item.createdAt),
        })
      );
    }
    return updatedPricingLevels;
  }, [data]);

  const [deletePricingLevel] = useMutation(REMOVE_PRICINGLEVEL, {
    refetchQueries: [
      {
        query: GET_PRICINGLEVELS,
      },
    ],
  });
  const handleDelete = async (id: string) => {
    return await deletePricingLevel({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.pricingLevelDelete) {
          const deletedPricingLevel = data?.pricingLevelDelete?.pricingLevel;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Pricing level ${deletedPricingLevel.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Pricing level delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Pricing level delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<PricingLevel, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "productType",
        header: "Product Type",
        cell: ({ row }) => {
          return row.original.productType === 1 ? (
            <span className="whitespace-nowrap">Specific Products</span>
          ) : (
            <span className="whitespace-nowrap">All Products</span>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-pricings"]}>
              <Button
                type="link"
                href={`/inventory/pricing/pricing-levels/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-pricings"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Head
        title="Pricing Levels"
        heading="Pricing Levels"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing",
            href: "/inventory/pricing",
          },
          {
            name: PricingLevelListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PricingLevelListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PricingLevelListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-pricings"]}>
              <Button type="link" href="/inventory/pricing/pricing-levels/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add pricing level
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={pricingLevels}
          columns={columns}
          loading={loading}
          totalRows={pricingLevels.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No pricing levels found"
          fallbackText="You can add a new pricing level by clicking on add pricing level button."
        />
      </div>
    </>
  );
};

export default PricingLevelList;
export const PricingLevelListResource: ResourceProps = {
  name: "Pricing Levels",
  description: "A list of pricing levels",
  access: ["read-pricings"],
  path: "pricing-levels",
};
