import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Head } from "../../../components/core";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { client, useAuth } from "../";

const USER_LOGOUT = gql`
  mutation Logout($token: String!) {
    logout(input: { params: { token: $token } }) {
      user {
        id
        userName
      }
    }
  }
`;

export function Logout() {
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();

  const { logout, refresh } = useAuth();
  const [userLogout] = useMutation(USER_LOGOUT);
  const logoutAndRedirect = useCallback(async () => {
    userLogout({
      variables: {
        token: refresh,
      },
    })
      .then(async ({ data }) => {
        if (data?.logout?.user) {
          await client.resetStore();
          logout();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Logout success",
          });
          return navigate("/auth/login", { replace: true });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Logout failed",
          });
          return navigate("/auth/login", { replace: true });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Logout failed",
          message: error.message,
        });
        return navigate("/auth/login", { replace: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogout, logout]);

  useEffect(() => {
    logoutAndRedirect();
  }, [logoutAndRedirect]);

  return (
    <>
      <Head title="Logout" heading="Logout" />
    </>
  );
}
