import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import {
  ADD_DEPARTMENT,
  type Department as RawDepartment,
  EDIT_DEPARTMENT,
  GET_DEPARTMENTS,
  REMOVE_DEPARTMENT,
} from "../../../graphql/inventory/departments";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { classNames, formatDate, toNestedTable } from "../../../utils";
import { AuthShield } from "../../auth/core";
import Form from "./components/Form";

type RowDepartment = RawDepartment & {
  subRows: RowDepartment[];
};

const DepartmentList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const { data, loading, error, refetch } = useQuery(GET_DEPARTMENTS);
  const departments: RowDepartment[] = useMemo(() => {
    const items: RowDepartment[] = data?.fetchDepartments.flatMap(
      (item: RawDepartment) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })
    );
    const updatedItems = toNestedTable(items) as RowDepartment[];
    return updatedItems || [];
  }, [data?.fetchDepartments]);

  const [activeDepartment, setActiveDepartment] = useState<
    RowDepartment | undefined
  >(undefined);
  const [newDepartment, setNewDepartment] = useState<boolean | undefined>(
    undefined
  );

  const [updateDepartment] = useMutation(EDIT_DEPARTMENT, {
    refetchQueries: [{ query: GET_DEPARTMENTS }],
  });
  const handleUpdate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateDepartment({
      variables: {
        id: activeDepartment?.id,
        name: values.name,
        handle: values.handle,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        imageUrl: values.imageUrl,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.departmentUpdate) {
          await refetch();
          setActiveDepartment(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Department update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Department update failed",
          message: error.message,
        });
      });
  };

  const [createDepartment] = useMutation(ADD_DEPARTMENT, {
    refetchQueries: [{ query: GET_DEPARTMENTS }],
  });
  const handleCreate = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createDepartment({
      variables: {
        name: values.name,
        handle: values.handle,
        description: values.description,
        parentId: values.parent ? parseInt(values.parent.value) : null,
        imageUrl: values.imageUrl,
        status: String(values.status) === "true" ? true : false,
      },
    })
      .then(async ({ data }) => {
        actions.setSubmitting(false);
        if (data?.departmentCreate) {
          await refetch();
          setNewDepartment(undefined);
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Department created successfully",
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Department create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Department create failed",
          message: error.message,
        });
      });
  };

  const [deleteDepartment] = useMutation(REMOVE_DEPARTMENT, {
    refetchQueries: [{ query: GET_DEPARTMENTS }],
  });
  const handleDelete = async (id: string) => {
    deleteDepartment({
      variables: {
        id: Number(id),
      },
    })
      .then(({ data }) => {
        if (data?.departmentDelete) {
          const deletedDepartment = data.departmentDelete?.department;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Department ${deletedDepartment.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Department deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Department deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<RowDepartment, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
        ),
        cell: ({ row }) => (
          <Fragment>
            {row.getCanExpand() ? (
              <button
                {...{
                  onClick: row.getToggleExpandedHandler(),
                  style: { cursor: "pointer" },
                }}
              >
                {row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            ) : (
              ""
            )}
          </Fragment>
        ),
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-departments"]}>
              <Button
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
                onClick={() => setActiveDepartment(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-departments"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon
                  aria-hidden="true"
                  className="text-md h-4 w-4 text-current"
                />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={DepartmentListResource.name}
        heading={DepartmentListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: DepartmentListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {DepartmentListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {DepartmentListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-departments"]}>
              <Button onClick={() => setNewDepartment(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add department
              </Button>
            </AuthShield>
            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh department list</span>
            </Button>
          </div>
        </div>

        <TableCursor
          data={departments}
          columns={columns}
          loading={loading}
          totalRows={departments.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          state={{
            expanded,
          }}
          enableRowSelection={false}
          onExpandedChange={setExpanded}
          getExpandedRowModel={getExpandedRowModel()}
          getSubRows={(row) => row.subRows}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No departments found"
          fallbackText="You can add a new department by clicking on add department button."
        />
      </div>

      <Transition.Root
        show={activeDepartment ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveDepartment(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Department - "
                  initialValues={{
                    id: Number(activeDepartment?.id),
                    name: activeDepartment?.name ?? "",
                    handle: activeDepartment?.handle ?? "",
                    description: activeDepartment?.description ?? "",
                    parent: activeDepartment?.parent
                      ? {
                          label: activeDepartment?.parent.name,
                          value: activeDepartment?.parent.id,
                        }
                      : null,
                    imageUrl: activeDepartment?.imageUrl ?? "",
                    status: activeDepartment?.status ? true : false,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveDepartment(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={newDepartment ? true : false} as={Fragment} appear>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewDepartment(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Department - "
                  initialValues={{
                    name: "",
                    handle: "",
                    description: "",
                    parent: null,
                    imageUrl: "",
                    status: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewDepartment(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DepartmentList;
export const DepartmentListResource: ResourceProps = {
  name: "Departments List",
  description: "A list of departments",
  access: ["create-departments", "read-departments"],
  path: "departments",
};
