import { gql } from "@apollo/client/core";

import { client } from "../../../modules/auth";
import { Category } from "../categories";
import { Department } from "../departments";

export async function checkStockCode(
  stockCode: string | undefined
): Promise<any> {
  console.log("checkStockCode", stockCode);
  if (!stockCode) return true;
  if (stockCode.length === 0) return true;

  const response = await client.query({
    query: gql`
      query CheckStockCode($stockCode: String!) {
        checkStockCode(stockCode: $stockCode)
      }
    `,
    variables: {
      stockCode,
    },
    fetchPolicy: "network-only",
  });
  return response;
}

export async function checkBarCode(barCode: string | undefined): Promise<any> {
  console.log("checkBarCode", barCode);
  if (!barCode) return true;
  if (barCode.length === 0) return true;

  const response = await client.query({
    query: gql`
      query CheckBarCode($barCode: String!) {
        checkBarCode(barCode: $barCode)
      }
    `,
    variables: {
      barCode,
    },
    fetchPolicy: "network-only",
  });
  return response;
}

export const ADD_PRODUCT = gql`
  mutation ProductCreate(
    $name: String!
    $handle: String!
    $description: String
    $departmentId: Int
    $categoryIds: [Int!]
    $vendorId: Int
    $binLocationId: Int
    $featureImageUrl: String
    $options: [String!]
    $hasVariants: Boolean
    $variants: [ProductskuInput!]
    $returnableItem: Boolean
    $relatedIds: [Int!]
    $galleryImageUrls: [String!]
    $status: Int!
  ) {
    productCreate(
      input: {
        params: {
          name: $name
          handle: $handle
          description: $description
          departmentId: $departmentId
          categoryIds: $categoryIds
          vendorId: $vendorId
          binLocationId: $binLocationId
          featureImageUrl: $featureImageUrl
          options: $options
          hasVariants: $hasVariants
          variants: $variants
          returnableItem: $returnableItem
          relatedIds: $relatedIds
          galleryImageUrls: $galleryImageUrls
          status: $status
        }
      }
    ) {
      product {
        id
        name
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query FetchProducts(
    $first: Int
    $cursor: String
    $status: Int
    $vendorId: ID
    $stockCode: String
    $categoryId: ID
    $departmentId: ID
    $searchFilter: [String!]
    $searchQuery: String!
  ) {
    fetchProducts(
      first: $first
      after: $cursor
      status: $status
      vendorId: $vendorId
      stockCode: $stockCode
      categoryId: $categoryId
      departmentId: $departmentId
      searchFilter: $searchFilter
      searchQuery: $searchQuery
    ) {
      edges {
        node {
          id
          name
          vendor {
            companyName
          }
          department {
            name
          }
          categories {
            name
          }
          hasVariants
          featureImageUrl
          variants {
            ...VariantFragment
          }
          createdAt
          updatedAt
          status
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const GET_PRODUCT_BY_ID = gql`
  query FetchProduct($id: ID!) {
    fetchProduct(id: $id) {
      id
      name
      handle
      description
      binLocation {
        id
        name
      }
      categories {
        id
        name
        categoryType
      }
      department {
        id
        name
      }
      pricingLevelName {
        id
        name
        pricingMethod {
          id
          name
          sellPriceFormula
          pricingMethodFields {
            fieldType
            fieldKey
            fieldName
            fieldOptions
          }
          status
        }
        productType
        products {
          id
          name
        }
        status
      }
      featureImageUrl
      hasVariants
      variants {
        ...VariantFragment
      }
      returnableItem
      vendor {
        id
        companyName
      }
      galleryImages {
        id
        imageUrl
      }
      relatedProducts {
        id
        name
        featureImageUrl
        status
      }
      status
    }
  }
`;

export const GET_PRODUCTS_BY_CUSTOMER = gql`
  query FetchProducts(
    $customerId: Int!
    $first: Int
    $cursor: String
    $status: Int
    $vendorId: ID
    $stockCode: String
    $categoryId: ID
    $departmentId: ID
    $searchFilter: [String!]
    $searchQuery: String!
  ) {
    fetchProducts(
      first: $first
      after: $cursor
      status: $status
      vendorId: $vendorId
      stockCode: $stockCode
      categoryId: $categoryId
      departmentId: $departmentId
      searchFilter: $searchFilter
      searchQuery: $searchQuery
    ) {
      edges {
        node {
          id
          name
          vendor {
            companyName
          }
          department {
            name
          }
          categories {
            name
          }
          hasVariants
          featureImageUrl
          variants {
            ...VariantFragment
            customerBasePrice(customerId: $customerId)
            customerSpecialPrice(customerId: $customerId)
          }
          createdAt
          updatedAt
          status
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation ProductUpdate(
    $id: ID!
    $name: String!
    $handle: String!
    $description: String
    $departmentId: Int
    $categoryIds: [Int!]
    $vendorId: Int
    $binLocationId: Int
    $featureImageUrl: String
    $options: [String!]
    $hasVariants: Boolean
    $variants: [ProductskuInput!]
    $returnableItem: Boolean
    $relatedIds: [Int!]
    $galleryImageUrls: [String!]
    $status: Int!
  ) {
    productUpdate(
      input: {
        id: $id
        params: {
          name: $name
          handle: $handle
          description: $description
          departmentId: $departmentId
          categoryIds: $categoryIds
          vendorId: $vendorId
          binLocationId: $binLocationId
          featureImageUrl: $featureImageUrl
          options: $options
          hasVariants: $hasVariants
          variants: $variants
          returnableItem: $returnableItem
          relatedIds: $relatedIds
          galleryImageUrls: $galleryImageUrls
          status: $status
        }
      }
    ) {
      product {
        id
        name
      }
    }
  }
`;

export type Vendor = {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  vendorCode: string;
  webAddress: string;
  address: string;
  comments: string;
  status: boolean;
};

export type Variant = {
  id: string;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  basePrice?: number;
  comparePrice?: number;
  optionValues?: {
    option: string;
    optionValue: string[];
  }[];
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
  customerBasePrice?: number;
  customerSpecialPrice?: number;
  variantTitle?: {
    id: string;
    name: string;
    option: {
      id: string;
      name: string;
    };
  }[];
  type?: VariantEx;
  productId?: number;
  product?: Product;
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  status: boolean;
};

export type PricingLevel = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  productType: number;
  products: {
    id: string;
    name: string;
  }[];
  status: boolean;
};

export type BinLocation = {
  id: string;
  name: string;
};

export enum Status {
  INACTIVE,
  DRAFT,
  ACTIVE,
}

export type Product = {
  id: string;
  name: string;
  handle: string;
  description: string;
  basePrice: number;
  binLocation: BinLocation;
  categories: Category[];
  comparePrice: number;
  department: Department;
  featureImageUrl?: string;
  options: string[];
  cost: number;
  marketCost: number;
  minimumQuantity: number;
  inventory: number;
  pricingLevel: PricingLevel | null;
  hasVariants: boolean;
  variants: Variant[];
  returnableItem: boolean;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  vendor: Vendor;
  relatedIds: number[];
  galleryImageUrls: string[];
  status: Status;
  subRows?: Product[];
  createdAt: string;
  updatedAt: string;
};
export type VariantTitle = {
  id: string;
  name: string;
  option: {
    id: string;
    name: string;
  };
};
export type VariantItem = {
  id?: string;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  basePrice?: number;
  comparePrice?: number;
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
  optionValues?: {
    option: string;
    optionValue: string[];
  }[];
  variantTitle?: VariantTitle[];
  type?: VariantEx;
};

export type VariantOption = {
  id: string;
  type: string;
  value: string[];
};

export type VariantProps = {
  index: number;
  stockCode: string;
  barCode?: string;
  plu?: number | null;
  basePrice?: number;
  comparePrice?: number;
  cost?: number;
  marketCost?: number;
  minimumQuantity?: number;
  inventory?: number;
  variantImageUrl?: string;
  type?: VariantEx;
};

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};
export enum VariantEx {
  OLD,
  NEW,
}

export type PreviewProduct = Pick<
  Product,
  "id" | "name" | "featureImageUrl" | "status"
>;

export type CustomerProduct = Pick<
  Product,
  "id" | "name" | "featureImageUrl" | "status"
> & {
  customerBasePrice: number;
  customerSpecialPrice: number;
};
