import {
  ExclamationCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";

import { classNames } from "../../utils";

interface FieldProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  touched?: boolean | undefined;
  errors?: string | undefined;
  isLabel?: boolean;
  editMode?: boolean;
  suffix?: React.ReactNode;
  isSuffix?: boolean;
  caption?: string;
}
export function Field(props: FieldProps) {
  const {
    title,
    caption,
    name,
    type,
    value,
    onChange,
    onBlur,
    readOnly = false,
    touched,
    errors,
    isLabel = true,
    id,
    className,
    placeholder,
    editMode = false,
    suffix = <Fragment />,
    isSuffix = false,
    ...rest
  } = props;

  const [passwordVisibile, setPasswordVisibile] = useState(false);
  const [isEdit, setEdit] = useState(false);

  switch (type) {
    case "checkbox":
      return (
        <>
          <div className="inline-flex items-start">
            <input
              name={name}
              id={id ? id : name}
              type={type}
              className={classNames(
                "relative h-4 w-4 cursor-pointer rounded border border-gray-300 text-primary-600 sm:text-sm",
                "focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:ring-primary-50",
                "checked:border-primary-500 checked:ring-primary-500",
                touched && errors ? "border-red-600 text-red-900" : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly}
              {...rest}
            />
            {isLabel && (
              <div className="ml-1.5">
                <label
                  htmlFor={id ? id : name}
                  className="block cursor-pointer text-sm font-normal text-gray-900"
                >
                  {title}
                </label>
                {caption ? (
                  <label
                    htmlFor={id ? id : name}
                    className="text-sm font-light text-gray-700"
                  >
                    {caption}
                  </label>
                ) : null}
              </div>
            )}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors}
            </p>
          ) : null}
        </>
      );
    case "radio":
      return (
        <>
          <div className="inline-flex items-center">
            <input
              name={name}
              id={id ? id : name}
              type={type}
              className={classNames(
                "relative h-4 w-4 cursor-pointer rounded-full border border-gray-300 text-primary-600 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:ring-primary-50",
                "checked:border-primary-500 checked:ring-primary-500",
                touched && errors ? "border-red-600 text-red-900" : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly}
              {...rest}
            />
            {isLabel && (
              <div className="ml-1.5">
                <label
                  htmlFor={id ? id : name}
                  className="block cursor-pointer text-sm font-normal text-gray-900"
                >
                  {title}
                </label>
                {caption ? (
                  <label
                    htmlFor={id ? id : name}
                    className="text-sm font-light text-gray-700"
                  >
                    {caption}
                  </label>
                ) : null}
              </div>
            )}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors}
            </p>
          ) : null}
        </>
      );
    case "textarea":
      return (
        <>
          <label
            htmlFor={id ? id : name}
            className={`block text-sm font-medium text-gray-900 ${
              isLabel ? "mb-1" : "sr-only"
            }`}
          >
            {title}
          </label>
          {caption ? (
            <p className="text-sm font-light text-gray-700">{caption}</p>
          ) : null}
          <div className="relative">
            <textarea
              name={name}
              id={id ? id : name}
              className={classNames(
                "relative block h-24 w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
                touched && errors ? "border-red-600 text-red-900" : "",
                className ? className : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              readOnly={readOnly}
              placeholder={placeholder ? placeholder : ""}
              value={value}
              {...rest}
            />
            {touched && errors ? (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            ) : null}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors}
            </p>
          ) : null}
        </>
      );
    case "password":
      return (
        <>
          <label
            htmlFor={id ? id : name}
            className={`block text-sm font-medium text-gray-900 ${
              isLabel ? "mb-1" : "sr-only"
            }`}
          >
            {title}
          </label>
          {caption ? (
            <p className="text-sm font-light text-gray-700">{caption}</p>
          ) : null}
          <div className="relative">
            <input
              name={name}
              id={id ? id : name}
              type={passwordVisibile ? "text" : "password"}
              className={classNames(
                "relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
                touched && errors ? "border-red-600 text-red-900" : "",
                className ? className : ""
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly}
              placeholder={placeholder ? placeholder : ""}
              {...rest}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 z-10 flex items-center pl-3 pr-3 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
              title={passwordVisibile ? "Hide Password" : "Show Password"}
              onClick={() => {
                setPasswordVisibile(!passwordVisibile);
              }}
            >
              {passwordVisibile ? (
                <span
                  className="bi bi-eye h-5 w-5 text-center text-xl leading-5 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                <span
                  className="bi bi-eye-slash h-5 w-5 text-center text-xl leading-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors}
            </p>
          ) : null}
        </>
      );
    default:
      return (
        <>
          <label
            htmlFor={id ? id : name}
            className={`block text-sm font-medium text-gray-900 ${
              isLabel ? "mb-1" : "sr-only"
            }`}
          >
            {title}
          </label>
          {caption ? (
            <p className="text-sm font-light text-gray-700">{caption}</p>
          ) : null}
          <div className="relative flex">
            <input
              name={name}
              id={id ? id : name}
              type={type ?? "text"}
              className={classNames(
                "relative block w-full flex-1 appearance-none border border-gray-300 px-3 py-2.5 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
                "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500",
                touched && errors ? "border-red-600 text-red-900" : "",
                className ? className : "",
                isSuffix ? "rounded-l-md border-r-0" : "rounded-md"
              )}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={readOnly || (editMode && !isEdit)}
              placeholder={placeholder ? placeholder : ""}
              {...rest}
            />
            {editMode ? (
              <button
                type="button"
                className="absolute inset-y-0 right-0 z-10 flex items-center pl-3 pr-3 text-gray-700 transition hover:text-gray-900 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50"
                title={isEdit ? "Edit Mode" : "Display Mode"}
                onClick={() => {
                  setEdit(!isEdit);
                }}
              >
                {isEdit ? (
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </button>
            ) : touched && errors ? (
              <div
                className={classNames(
                  "pointer-events-none absolute inset-y-0 flex items-center pr-3",
                  isSuffix ? "right-14" : "right-0"
                )}
              >
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            ) : null}
            {isSuffix ? (
              <div
                className={classNames(
                  "-ml-px rounded-r-md border  bg-primary-50 px-3 py-2.5 text-primary-900 sm:text-sm",
                  touched && errors ? "border-red-600" : "border-primary-200"
                )}
              >
                {suffix}
              </div>
            ) : null}
          </div>
          {touched && errors ? (
            <p
              className="mt-2 text-sm text-red-600"
              id={`${id ? id : name}-errors`}
            >
              {errors}
            </p>
          ) : null}
        </>
      );
  }
}
