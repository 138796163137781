import { UniqueIdentifier } from "@dnd-kit/core";
import { nanoid } from "nanoid";

export interface IField {
  id: UniqueIdentifier | string;
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  icon: string;
  fieldOptions: string[];
  description: string;
  disabled: boolean;
}

export const fieldItems: IField[] = [
  {
    id: nanoid(11),
    fieldType: "text",
    fieldKey: "",
    fieldName: "",
    icon: "bi bi-dash-lg",
    fieldOptions: [],
    description: "Input for text field",
    disabled: false,
  },
  {
    id: nanoid(11),
    fieldType: "number",
    fieldKey: "",
    fieldName: "",
    icon: "bi bi-123",
    fieldOptions: [],
    description: "Input for number field",
    disabled: false,
  },
  {
    id: nanoid(11),
    fieldType: "percentage",
    fieldKey: "",
    fieldName: "",
    icon: "bi bi-percent",
    fieldOptions: [],
    description: "Input for percentage field",
    disabled: false,
  },
  {
    id: nanoid(11),
    fieldType: "date",
    fieldKey: "",
    fieldName: "",
    icon: "bi bi-calendar4-event",
    fieldOptions: [],
    description: "Input for date field",
    disabled: false,
  },
  // {
  //   id: nanoid(11),
  //   fieldType: "select",
  //   fieldKey: "",
  //   fieldName: "",
  //   icon: "bi bi-menu-button-wide",
  //   fieldOptions: [""],
  //   description: "Input for select field",
  //   disabled: false,
  // },
  {
    id: nanoid(11),
    fieldType: "number",
    fieldKey: "$minimumMarkup",
    fieldName: "Minimum Markup",
    icon: "bi bi-sort-numeric-down",
    fieldOptions: [],
    description: "Default minimum markup",
    disabled: true,
  },
  {
    id: nanoid(11),
    fieldType: "number",
    fieldKey: "$maximumMarkup",
    fieldName: "Maximum Markup",
    icon: "bi bi-sort-numeric-up",
    fieldOptions: [],
    description: "Default maximum markup",
    disabled: true,
  },
];
