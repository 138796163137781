import { ApolloQueryResult, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";

import {
  CREATE_VEHICLE_WATCHER,
  DELETE_VEHICLE_WATCHER,
  GET_VEHICLE_BY_ID,
  Watcher,
} from "../../graphql/fleets/vehicles";
import { useAuth } from "../../modules/auth";
import { classNames } from "../../utils";
import { Button } from "../form";
import { Avatar } from "./Avatar";

export enum WatchersLayout {
  BUTTON,
  IMAGE,
}
export enum WatchersType {
  VEHICLE,
  SERVICE,
}
type WatcherProps = {
  layout?: WatchersLayout;
  type?: WatchersType;
  id?: string;
  title?: string;
  caption?: string;
  data: Watcher[];
  refetch?: () => Promise<ApolloQueryResult<any>>;
};
export function Watchers({
  layout = WatchersLayout.IMAGE,
  type = WatchersType.SERVICE,
  id: vehicleId,
  title,
  caption,
  data = [],
  refetch: handleRefetch,
}: WatcherProps) {
  const [openWatchers, setOpenWatchers] = useState(false);

  const { currentUser } = useAuth();
  const isWatching = useMemo(
    () => data.some((watcher) => watcher.id === currentUser?.id),
    [currentUser?.id, data]
  );
  const filteredData = useMemo(
    () => data.filter((watcher) => watcher.id !== currentUser?.id),
    [currentUser?.id, data]
  );

  const handleCloseWatchers = () => {
    setOpenWatchers(false);
  };

  const [createWatcher, { loading: loadingCreateWatcher }] = useMutation(
    CREATE_VEHICLE_WATCHER,
    {
      refetchQueries: [
        {
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: vehicleId,
          },
        },
      ],
    }
  );
  const [deleteWatcher, { loading: loadingDeleteWatcher }] = useMutation(
    DELETE_VEHICLE_WATCHER,
    {
      refetchQueries: [
        {
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: vehicleId,
          },
        },
      ],
    }
  );

  const handleWatch = useCallback(() => {
    if (!currentUser || !vehicleId || !handleRefetch) return;
    if (isWatching) {
      deleteWatcher({
        variables: {
          vehicleId: vehicleId,
          watcherId: currentUser.id,
        },
      })
        .then(async ({ data }) => {
          if (data?.vehicleWatcherDelete) {
            handleRefetch();
            toast.success("You are unwatching this vehicle");
          } else {
            toast.error("Something went wrong. Please try again later");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
      return;
    }

    createWatcher({
      variables: {
        vehicleId: vehicleId,
        watcherId: currentUser.id,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleWatcherCreate) {
          handleRefetch();
          toast.success("You are now watching this vehicle");
        } else {
          toast.error("Something went wrong. Please try again later");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [
    createWatcher,
    currentUser,
    deleteWatcher,
    handleRefetch,
    isWatching,
    vehicleId,
  ]);

  return (
    <Fragment>
      {data.length ? (
        layout === WatchersLayout.IMAGE ? (
          <button
            type="button"
            className="background-none isolate flex cursor-pointer appearance-none -space-x-1.5 overflow-hidden border-0 outline-0"
            onClick={() => {
              setOpenWatchers(true);
            }}
          >
            {data.map((watcher, index) => (
              <Avatar
                key={`${watcher.id}-${index}`}
                firstName={watcher.firstName}
                lastName={watcher.lastName}
                className={classNames(
                  "relative h-8 w-8 ring-2 ring-white",
                  `z-${(data.length - index) * 10}`
                )}
              />
            ))}
          </button>
        ) : (
          <button
            type="button"
            className="appearance-none whitespace-nowrap text-blue-700 transition-all transition-colors hover:text-blue-500"
            onClick={() => {
              setOpenWatchers(true);
            }}
          >
            {data.length} Watchers
          </button>
        )
      ) : (
        <span className="whitespace-nowrap">{data.length} Watchers</span>
      )}
      {currentUser ? (
        <Transition.Root show={openWatchers} as={Fragment} appear>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setOpenWatchers(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="mx-auto max-w-md transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                  <div className="flex h-full flex-col divide-y divide-gray-200">
                    <div className="h-0 flex-1">
                      {WatchersType.VEHICLE ? (
                        <div className="px-4 pb-6 pt-8 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="flex items-center">
                              <Avatar
                                firstName={currentUser.firstName}
                                lastName={currentUser.lastName}
                                className="h-12 w-12 text-lg"
                              />
                              <div className="ml-3">{currentUser.fullName}</div>
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                                onClick={handleCloseWatchers}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="px-4 pb-6 pt-8 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-black">
                              {title || "Watchers"} - ({data.length})
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                                onClick={handleCloseWatchers}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-gray-500">
                              {caption || "List of watchers"}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="flex flex-1 flex-col justify-between">
                        {type === WatchersType.VEHICLE ? (
                          <div className="space-y-6 pb-6">
                            <div className="px-4 sm:px-6">
                              <h3 className="text-lg font-medium text-black">
                                {isWatching ? "Watching" : "Not Watching"}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {isWatching
                                  ? "You're watching this record, so you will receive all Watched Notifications."
                                  : "You're not watching this record, so you will not receive any Watched Notifications."}
                              </p>
                              <Button
                                onClick={handleWatch}
                                loading={
                                  loadingCreateWatcher || loadingDeleteWatcher
                                }
                                className="mt-4"
                              >
                                <span className={classNames("relative mr-2")}>
                                  <BellIcon className="h-5 w-5" />
                                  <span
                                    className={classNames(
                                      "absolute left-1/2 top-1/2 h-[3px] w-6 -translate-x-1/2 -translate-y-1/2 rotate-45 transform rounded-full border border-white bg-primary-700",
                                      isWatching ? "block" : "hidden"
                                    )}
                                  />
                                </span>
                                <span>{isWatching ? "Unwatch" : "Watch"}</span>
                              </Button>
                            </div>
                            {filteredData.length ? (
                              <div className="px-4 sm:px-6">
                                <h3 className="mb-2 font-normal text-blue-700">
                                  Other watchers list
                                </h3>
                                <div className="space-y-4 rounded-xl bg-greyish p-4">
                                  {filteredData.map((watcher, index) => (
                                    <div
                                      key={`${watcher.id}-${index}`}
                                      className="flex items-center"
                                    >
                                      <Avatar
                                        firstName={watcher.firstName}
                                        lastName={watcher.lastName}
                                        className="h-12 w-12 text-lg"
                                      />
                                      <div className="ml-3">
                                        <div>{watcher.fullName}</div>
                                        <span className="text-sm text-gray-500">
                                          {watcher.email}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="space-y-6 pb-6">
                            {data.length ? (
                              <div className="px-4 sm:px-6">
                                <div className="space-y-4 rounded-xl bg-greyish p-4">
                                  {data.map((watcher, index) => (
                                    <div
                                      key={`${watcher.id}-${index}`}
                                      className="flex items-center"
                                    >
                                      <Avatar
                                        firstName={watcher.firstName}
                                        lastName={watcher.lastName}
                                        className="h-12 w-12 text-lg"
                                      />
                                      <div className="ml-3">
                                        <div>{watcher.fullName}</div>
                                        <span className="text-sm text-gray-500">
                                          {watcher.email}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </Fragment>
  );
}
