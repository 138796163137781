import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, Field } from "../../../../components/form";
import { classNames, toSlug } from "../../../../utils";
import { MenuTree } from ".";
import { TreeItem } from "./types";

type IMenu = {
  id?: string;
  name: string;
  handle: string;
  menuItems: TreeItem[];
  status: boolean;
};

export default function Form({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: IMenu;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const MenuSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    handle: Yup.string().required("Required"),
    menuItems: Yup.array(
      Yup.object()
        .shape({
          id: Yup.string(),
          title: Yup.string(),
          link: Yup.string(),
          type: Yup.number(),
        })
        .required("Required")
    )
      .min(1, "You need at least one menu item")
      .required("Required"),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: MenuSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_name")}
            name="name"
            onChange={(e: { target: { value: string } }) => {
              formik.setFieldValue("name", e.target.value);
              formik.setFieldValue("handle", toSlug(e.target.value));
            }}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={formik.touched.name}
            errors={formik.errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_handle")}
            name="handle"
            onChange={(e: { target: { value: string } }) => {
              formik.setFieldValue("handle", toSlug(e.target.value));
            }}
            onBlur={formik.handleBlur}
            value={formik.values.handle}
            touched={formik.touched.handle}
            errors={formik.errors.handle}
          />
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-6">
          <span className="text-sm font-medium text-gray-900">
            {t("text_menu_items")}
          </span>
          <div className="mt-1.5">
            <MenuTree
              defaultItems={formik.values.menuItems}
              collapsible
              indicator
              removable
              onChange={(items) => {
                formik.setFieldValue("menuItems", items);
              }}
              className={classNames(
                formik.touched.menuItems && formik.errors.menuItems
                  ? "border-red-600 text-red-900"
                  : ""
              )}
            />
            {formik.touched.menuItems && formik.errors.menuItems ? (
              <p className="mt-2 text-sm text-red-600" id="menuItems-errors">
                {formik.errors.menuItems.toString()}
              </p>
            ) : null}
          </div>
        </div>

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              {t("text_status")}
            </legend>
            <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
              <Switch
                checked={formik.values.status}
                onChange={() => {
                  formik.setFieldValue("status", !formik.values.status);
                }}
                id="status"
                className={classNames(
                  formik.values.status ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formik.values.status ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor="status"
                className="mb-0 ml-2 block text-sm font-normal text-gray-700"
              >
                {formik.values.status ? t("text_active") : t("text_inactive")}
              </Switch.Label>
            </Switch.Group>
          </fieldset>
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/purchases/vendors" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
