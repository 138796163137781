import LogRocket from "logrocket";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";

import { ScrollToTop } from "./components/appearance";
import Providers from "./contexts/Providers";
import { AuthInit } from "./modules/auth";

function App() {
  useEffect(() => {
    LogRocket.init("bssrut/alpha-fresh");
  }, []);
  return (
    <>
      <ScrollToTop />
      <Providers>
        <AuthInit>
          <Outlet />
        </AuthInit>
      </Providers>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 5000,
        }}
      />
    </>
  );
}

export default App;
