import {
  Picking,
  renderPickingStatusText,
} from "../../../../graphql/pickpack/picking";
import { renderStatusText } from "../../../../graphql/sales/orders";
import { formatVariantTitle } from "../../../../utils";

export function PrintTemplate({ pickings }: { pickings: Picking[] }) {
  if (!pickings) return null;

  return (
    <>
      <div className="px-4 py-8 sm:px-6">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-semibold uppercase">
              Alpha Fresh Foods
            </h2>
            <p className="font-normal uppercase">Picking Details</p>
          </div>
          <div className="w-1/4">
            <img src="/logo.png" alt="logo" />
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col justify-between px-4">
        <div className="space-y-4 px-4 sm:px-6">
          {pickings.map((picking) => (
            <div
              key={`picking-${picking.id}`}
              className="-mx-4 flex flex-col overflow-hidden rounded-lg border border-gray-300 sm:-mx-6 md:mx-0"
            >
              <table className="min-w-full bg-greyish">
                <tbody>
                  <tr>
                    <td className="py-4 pl-4 pr-3 text-left text-sm text-gray-500 sm:pl-6">
                      Order No.{" "}
                      <span className="text-gray-900">
                        #{picking.orderNumber}
                      </span>
                    </td>
                    <td className="table-cell px-3 py-4 text-left text-sm text-gray-500">
                      Customer:{" "}
                      <span className="text-gray-900">
                        {picking.customer.customerName}
                      </span>
                    </td>
                    <td className="table-cell px-3 py-4 text-left text-sm text-gray-500">
                      Delivery Run:{" "}
                      <span className="text-gray-900">
                        {picking.deliveryRun}
                      </span>
                    </td>
                    <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6">
                      Status:{" "}
                      <span className="text-gray-900">
                        {renderStatusText(picking.status)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Stock Code
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Variant
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                      Order Comments
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {picking.orderDetails.map((product) => (
                    <tr
                      key={`picking-${picking.id}-product-${product.id}`}
                      className="border-t border-gray-200"
                    >
                      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="font-medium text-gray-900">
                          {product.variant.product?.name}
                        </div>
                        <div className="mt-0.5 text-gray-500 sm:hidden">
                          #{product.variant.stockCode}
                        </div>
                        <div className="mt-0.5 text-gray-500 sm:hidden">
                          {formatVariantTitle(product.variant.variantTitle)}
                        </div>
                        <div className="mt-0.5 text-gray-500 sm:hidden">
                          {product.quantity} quantity
                        </div>
                      </td>
                      <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                        #{product.variant.stockCode}
                      </td>
                      <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                        {formatVariantTitle(product.variant.variantTitle)}
                      </td>
                      <td className="hidden px-3 py-4 text-right text-sm text-gray-500 sm:table-cell">
                        {product.quantity}
                      </td>
                      <td className="table-cell px-3 py-4 text-right text-sm text-gray-500">
                        {product.orderComments}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6">
                        {renderPickingStatusText(product.pickingStatus)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
