import { gql } from "@apollo/client";

export const VEHICLEBODYTYPE_FRAGMENT = gql`
  fragment VehicleBodyTypeFragment on VehicleBody {
    id
    name
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLEBODYTYPES = gql`
  ${VEHICLEBODYTYPE_FRAGMENT}
  query FetchVehicleBodyTypes {
    fetchVehicleBodyTypes {
      ...VehicleBodyTypeFragment
    }
  }
`;

// export const GET_VEHICLEBODYTYPE_BY_ID = gql`
//   ${VEHICLEBODYTYPE_FRAGMENT}
//   query FetchVehicleBodyType($id: ID!) {
//     fetchVehicleBodyType(id: $id) {
//       ...VehicleBodyTypeFragment
//     }
//   }
// `;

// export const REMOVE_VEHICLEBODYTYPE = gql`
//   ${VEHICLEBODYTYPE_FRAGMENT}
//   mutation VehicleBodyTypeDelete($id: ID!) {
//     vehicleBodyTypeDelete(input: { id: $id }) {
//       vehicleBodyType {
//         ...VehicleBodyTypeFragment
//       }
//     }
//   }
// `;

export const ADD_VEHICLEBODYTYPE = gql`
  ${VEHICLEBODYTYPE_FRAGMENT}
  mutation VehicleBodyTypeCreate($name: String!) {
    vehicleBodyTypeCreate(input: { params: { name: $name } }) {
      vehicleBodyType {
        ...VehicleBodyTypeFragment
      }
    }
  }
`;

// export const EDIT_VEHICLEBODYTYPE = gql`
//   ${VEHICLEBODYTYPE_FRAGMENT}
//   mutation VehicleBodyTypeUpdate($id: ID!, $name: String!) {
//     vehicleBodyTypeUpdate(input: { id: $id, params: { name: $name } }) {
//       vehicleBodyType {
//         ...VehicleBodyTypeFragment
//       }
//     }
//   }
// `;

export type VehicleBodyType = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};
