import { gql } from "@apollo/client";

export const FETCH_SPECIALPRICE = gql`
  query FetchSpecialPrice($id: ID!, $specialPriceId: Int!) {
    fetchSpecialPrice(id: $id) {
      id
      name
      pricingMethod {
        id
        name
        handle
        sellPriceFormula
        pricingMethodFields {
          fieldType
          fieldKey
          fieldName
          fieldOptions
        }
        createdAt
        status
      }
      products {
        id
        name
        featureImageUrl
        hasVariants
        variants {
          id
          stockCode
          productId
          basePrice
          comparePrice
          cost
          marketCost
          specialPriceDetails(specialPriceId: $specialPriceId) {
            id
            priceFields
            sellPrice
            costUpdated
          }
          variantTitle {
            id
            name
            option {
              id
              name
            }
          }
        }
        status
      }
      createdAt
      status
    }
  }
`;

export const CREATE_SPECIALPRICEPRICING = gql`
  mutation SpecialPricePricingCreate(
    $specialPriceId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    specialPricePricingCreate(
      input: {
        params: {
          specialPriceId: $specialPriceId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export const UPDATE_SPECIALPRICEPRICING = gql`
  mutation SpecialPricePricingUpdate(
    $id: ID!
    $specialPriceId: Int!
    $productId: Int!
    $productSkuId: Int!
    $priceFields: [JSON!]
    $sellPrice: Float
  ) {
    specialPricePricingUpdate(
      input: {
        id: $id
        params: {
          specialPriceId: $specialPriceId
          productId: $productId
          productSkuId: $productSkuId
          priceFields: $priceFields
          sellPrice: $sellPrice
        }
      }
    ) {
      message
    }
  }
`;

export const DELETE_PRODUCTPRICING = gql`
  mutation SpecialPricePricingDelete($productId: Int!, $specialPriceId: Int!) {
    specialPricePricingDelete(
      input: { productId: $productId, specialPriceId: $specialPriceId }
    ) {
      message
    }
  }
`;

export const UPDATE_SPECIALPRICE = gql`
  mutation SpecialPriceUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $productIds: [Int!]
    $status: Boolean
  ) {
    specialPriceUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productIds: $productIds
          status: $status
        }
      }
    ) {
      specialPrice {
        id
        name
        pricingMethod {
          id
          name
        }
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

export type PricingMethod = {
  id: string;
  name: string;
  handle: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type Title = {
  id: string;
  name: string;
  option: {
    id: string;
    name: string;
  };
};
export type Details = {
  id: string;
  priceFields: any[];
  sellPrice: number;
  costUpdated: boolean;
};

export type Variant = {
  id: string;
  stockCode: string;
  productId: number;
  basePrice: number;
  comparePrice: number;
  cost: number;
  marketCost: number;
  specialPriceDetails?: Details[];
  variantTitle: Title[];
};

export type Product = {
  id: string;
  name: string;
  featureImageUrl?: string;
  variants: Variant[];
};

export type SpecialPrice = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  products: Product[];
  createdAt: string;
  status: boolean;
};

export enum ProductPricingStatus {
  loading,
  success,
  pending,
  error,
  none,
}

export type ProductPricing = {
  id: string;
  pricingId?: string;
  specialPriceId: number;
  productId: number;
  productSkuId: number;
  priceFields: any[];
  sellPrice: number;
  status: ProductPricingStatus;
};
