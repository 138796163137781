import { useEffect, useMemo, useState } from "react";
import Select, { OnChangeValue, SingleValue } from "react-select";

import { selectStyles, SelectWrapper } from "../../../../components/form";

export function FieldStatus({
  value: initialValue,
  onChange,
  className,
  loading,
  disabled,
}: {
  value: number;
  onChange: (newValue: SingleValue<OptionProps>) => void;
  className: string;
  loading?: boolean;
  disabled?: boolean;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);
  const options = useMemo(
    () => [
      { label: "Pending", value: "0", disabled: true },
      { label: "In Progress", value: "1", disabled: false },
      { label: "Picked", value: "2", disabled: false },
    ],
    []
  );

  useEffect(() => {
    const value: SingleValue<OptionProps> =
      options.find((o) => o.value === initialValue.toString()) ?? null;
    setValues(value);
  }, [options, initialValue]);

  const handleChange = (newValue: OnChangeValue<OptionProps, false>) => {
    if (newValue?.disabled) return;
    onChange(newValue);
  };

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={handleChange}
        isClearable
        isDisabled={disabled}
        isLoading={loading}
      />
    </SelectWrapper>
  );
}
