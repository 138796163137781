import { useMutation, useQuery } from "@apollo/client/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../contexts/NotifyContext";
import {
  EDIT_SPECIALPRICE,
  GET_SPECIALPRICE_BY_ID,
} from "../../../../graphql/inventory/pricing/special-prices";
import { PreviewCustomer } from "../../../../graphql/sales/customers";
import Form from "./components/FormUpdate";

type PricingMethodField = {
  fieldType: string;
  fieldKey: string;
  fieldName: string;
  fieldOptions: string[];
};

type PricingMethod = {
  id: string;
  name: string;
  sellPriceFormula: string[];
  pricingMethodFields: PricingMethodField[];
  createdAt: string;
  status: boolean;
};

type Product = {
  id: string;
  name: string;
};

type SpecialPrice = {
  id: string;
  name: string;
  pricingMethod: PricingMethod;
  products: Product[];
  customerType: number;
  customers: PreviewCustomer[];
  customerGroups: {
    id: string;
  }[];
  startDate: string;
  endDate: string;
  createdAt: string;
  status: boolean;
};

const SpecialPriceUpdate = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const { addNotify } = useNotifyContext();
  let { specialPriceId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<{
    fetchSpecialPrice: SpecialPrice;
  }>(GET_SPECIALPRICE_BY_ID, {
    variables: {
      id: specialPriceId,
    },
  });
  const specialPrice = useMemo<SpecialPrice | undefined>(
    () => data?.fetchSpecialPrice,
    [data]
  );

  const [updateSpecialPrice] = useMutation(EDIT_SPECIALPRICE, {
    refetchQueries: [
      {
        query: GET_SPECIALPRICE_BY_ID,
        variables: {
          id: specialPriceId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = (
    values: any,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    updateSpecialPrice({
      variables: {
        id: specialPriceId,
        name: values.name,
        pricingMethodId: values.pricingMethod
          ? parseInt(values.pricingMethod.value)
          : null,
        customerType: values.customerType,
        customerIds: values.customerIds,
        customerGroupIds: values.customerGroupIds,
        startDate: values.startDate
          ? new Date(values.startDate).toISOString()
          : null,
        endDate: values.endDate ? new Date(values.endDate).toISOString() : null,
        status: values.status,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.specialPriceUpdate) {
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Special price updated successfully",
          });
          return navigate(`/inventory/pricing/special-prices`);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Special price update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Special price update failed",
          message: error.message,
        });
      });
  };

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={SpecialPriceUpdateResource.name}
        heading="Special Prices"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Special Prices",
            href: "/inventory/pricing/special-prices",
          },
          {
            name: SpecialPriceUpdateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-3xl py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_update_special_price")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_update_special_price")}
              </p>
            </div>
          </div>

          {loading || !specialPrice ? (
            <Waiting />
          ) : (
            <Form
              initialValues={{
                name: specialPrice.name ?? "",
                pricingMethod: specialPrice.pricingMethod
                  ? {
                      label: specialPrice.pricingMethod.name,
                      value: specialPrice.pricingMethod.id,
                    }
                  : null,
                customerType: specialPrice.customerType ?? 0,
                customerIds: specialPrice.customers
                  ? specialPrice.customers.map((c) => parseInt(c.id))
                  : [],
                customerGroupIds: specialPrice.customerGroups
                  ? specialPrice.customerGroups.map((c) => parseInt(c.id))
                  : [],
                startDate: specialPrice.startDate ?? "",
                endDate: specialPrice.endDate ?? "",
                status: specialPrice.status ? true : false,
              }}
              initialCustomers={specialPrice.customers}
              onSubmit={handleSubmit}
              actionLabel={t("text_update")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SpecialPriceUpdate;
export const SpecialPriceUpdateResource: ResourceProps = {
  name: "Edit Special Price",
  description: "Update an existing special price",
  access: ["update-pricings"],
  path: "special-prices/:specialPriceId",
};
