import { gql } from "@apollo/client";

export const GET_SPECIALPRICES = gql`
  query FetchSpecialPrices {
    fetchSpecialPrices {
      id
      name
      pricingMethod {
        id
        name
      }
      customerType
      products {
        id
        name
      }
      createdAt
      status
    }
  }
`;

export const REMOVE_SPECIALPRICE = gql`
  mutation SpecialPriceDelete($id: ID!) {
    specialPriceDelete(input: { id: $id }) {
      specialPrice {
        id
        name
      }
    }
  }
`;

export const ADD_SPECIALPRICE = gql`
  mutation SpecialPriceCreate(
    $name: String!
    $pricingMethodId: Int!
    $productIds: [Int!]
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
    $status: Boolean
  ) {
    specialPriceCreate(
      input: {
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          productIds: $productIds
          startDate: $startDate
          endDate: $endDate
          status: $status
        }
      }
    ) {
      specialPrice {
        id
        name
        pricingMethod {
          id
          name
        }
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const EDIT_SPECIALPRICE = gql`
  mutation SpecialPriceUpdate(
    $id: ID!
    $name: String!
    $pricingMethodId: Int!
    $customerType: Int
    $customerIds: [Int!]
    $customerGroupIds: [Int!]
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
    $status: Boolean
  ) {
    specialPriceUpdate(
      input: {
        id: $id
        params: {
          name: $name
          pricingMethodId: $pricingMethodId
          customerType: $customerType
          customerIds: $customerIds
          customerGroupIds: $customerGroupIds
          startDate: $startDate
          endDate: $endDate
          status: $status
        }
      }
    ) {
      specialPrice {
        id
        name
        pricingMethod {
          id
          name
        }
        products {
          id
          name
        }
        createdAt
        status
      }
    }
  }
`;

export const GET_SPECIALPRICE_BY_ID = gql`
  query FetchSpecialPrice($id: ID!) {
    fetchSpecialPrice(id: $id) {
      id
      name
      pricingMethod {
        id
        name
      }
      products {
        id
        name
      }
      customerType
      customers {
        id
        customerName
        profileImageUrl
        status
      }
      startDate
      endDate
      createdAt
      status
    }
  }
`;
