import { Switch } from "@headlessui/react";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../../animations";
import {
  Button,
  Field,
  FieldTableVehicles,
} from "../../../../../components/form";
import { DockForm } from "../../../../../graphql/fleets/settings/docks";
import { Vehicle } from "../../../../../graphql/fleets/vehicles";
import { classNames } from "../../../../../utils";

export default function Form({
  initialValues,
  vehicles: initialVehicles = [],
  onSubmit,
  actionLabel,
}: {
  initialValues: DockForm;
  vehicles: Vehicle[];
  onSubmit: (values: DockForm, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);

  useEffect(() => {
    if (initialVehicles.length > 0) {
      setVehicles(initialVehicles);
    }
  }, [initialVehicles]);

  const ResourceSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    vehicleIds: Yup.array().of(Yup.string()).nullable(),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ResourceSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 sm:col-span-6">
          <Field
            title={t("text_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={formik.touched.name}
            errors={formik.errors.name}
          />
        </div>
        <div className="col-span-12 sm:col-span-12">
          <FieldTableVehicles
            title={t("text_vehicles")}
            data={vehicles}
            value={formik.values.vehicleIds}
            onChange={(vehicles) => {
              formik.setFieldValue(
                "vehicleIds",
                vehicles.map((vehicle) => parseInt(vehicle.id))
              );
              setVehicles(vehicles);
            }}
          />
          {formik.touched.vehicleIds && formik.errors.vehicleIds ? (
            <p className="mt-2 text-sm text-red-600" id="vehicleIds-errors">
              {formik.errors.vehicleIds}
            </p>
          ) : null}
        </div>
        <div className="col-span-12 sm:col-span-6">
          <label className="block text-sm font-medium text-gray-900">
            {t("text_status")}
          </label>
          <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
            <Switch
              checked={formik.values.status}
              onChange={() => {
                formik.setFieldValue("status", !formik.values.status);
              }}
              id="status"
              className={classNames(
                formik.values.status ? "bg-primary-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  formik.values.status ? "translate-x-5" : "translate-x-0",
                  "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label
              passive
              htmlFor="status"
              className="mb-0 ml-2 block text-sm font-normal text-gray-700"
            >
              {formik.values.status ? t("text_active") : t("text_inactive")}
            </Switch.Label>
          </Switch.Group>

          {formik.touched.status && formik.errors.status ? (
            <p className="mt-2 text-sm text-red-600" id="roles-errors">
              {formik.errors.status.toString()}
            </p>
          ) : null}
        </div>
      </div>
      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/fleets/settings/docks" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <Fragment>
              <Spinner />
              {t("text_processing")}
            </Fragment>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
