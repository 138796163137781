import { useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, Field, FieldDatepicker } from "../../../../components/form";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { VehicleLifecycleForm } from "../../../../graphql/fleets/vehicles/pages/lifecycle";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { useFormikErrors } from "../../../../utils";

export default function Form({
  initialValues,
  onChange = () => {},
  onClear = () => {},
  onSubmit,
  actionLabel,
  vehicleBasic,
}: {
  initialValues: VehicleLifecycleForm;
  onChange?: (values: VehicleLifecycleForm) => void;
  onClear?: () => void;
  onSubmit: (values: VehicleLifecycleForm, actions: any) => void;
  actionLabel: string;
  vehicleBasic: VehicleBasic | null;
}) {
  const { t } = useTranslation();

  const VehicleLifecycleSchema = Yup.object().shape({
    inServiceDate: Yup.string().nullable(),
    inServiceOdometer: Yup.number().nullable(),
    estimatedServiceLifeInMonths: Yup.number().nullable(),
    estimatedServiceLifeInMeter: Yup.number().nullable(),
    estimatedResaleValue: Yup.number().nullable(),
    outOfServiceDate: Yup.string().nullable(),
    outOfServiceOdometer: Yup.number().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: VehicleLifecycleSchema,
    onSubmit: onSubmit,
  });

  const { init: initError } = useFormikErrors();
  useEffect(() => {
    initError(
      formik.isValid,
      formik.submitCount,
      formik.isSubmitting,
      formik.errors
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors, formik.isSubmitting, formik.isValid, formik.submitCount]);

  useEffect(() => {
    onChange(formik.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-2 text-base font-medium text-gray-900">
          Out of Service
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <FieldDatepicker
              title={t("text_out_of_service_date")}
              name="outOfServiceDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "outOfServiceDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.outOfServiceDate
                  ? new Date(formik.values.outOfServiceDate)
                  : null
              }
              touched={formik.touched.outOfServiceDate}
              errors={formik.errors.outOfServiceDate}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_out_of_service_odometer")}
              name="outOfServiceOdometer"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.outOfServiceOdometer ?? ""}
              touched={formik.touched.outOfServiceOdometer}
              errors={formik.errors.outOfServiceOdometer}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-2 text-base font-medium text-gray-900">
          In Service
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <FieldDatepicker
              title={t("text_in_service_date")}
              name="inServiceDate"
              onChange={(value) => {
                if (!Array.isArray(value)) {
                  formik.setFieldValue(
                    "inServiceDate",
                    value ? new Date(value).toISOString() : null
                  );
                }
              }}
              selected={
                formik.values.inServiceDate
                  ? new Date(formik.values.inServiceDate)
                  : null
              }
              touched={formik.touched.inServiceDate}
              errors={formik.errors.inServiceDate}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_in_service_odometer")}
              name="inServiceOdometer"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.inServiceOdometer ?? ""}
              touched={formik.touched.inServiceOdometer}
              errors={formik.errors.inServiceOdometer}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
        </div>
      </fieldset>
      <fieldset className="border-t border-gray-200 py-4 md:py-5 xl:py-6">
        <div className="mb-2 text-base font-medium text-gray-900">
          Estimation
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_estimated_service_life_in_months")}
              name="estimatedServiceLifeInMonths"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.estimatedServiceLifeInMonths ?? ""}
              touched={formik.touched.estimatedServiceLifeInMonths}
              errors={formik.errors.estimatedServiceLifeInMonths}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_estimated_service_life_in_meter")}
              name="estimatedServiceLifeInMeter"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.estimatedServiceLifeInMeter ?? ""}
              touched={formik.touched.estimatedServiceLifeInMeter}
              errors={formik.errors.estimatedServiceLifeInMeter}
              isSuffix
              suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
            />
          </div>
          <div className="col-span-12 sm:col-span-6">
            <Field
              title={t("text_estimated_resale_value")}
              name="estimatedResaleValue"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.estimatedResaleValue ?? ""}
              touched={formik.touched.estimatedResaleValue}
              errors={formik.errors.estimatedResaleValue}
            />
          </div>
        </div>
      </fieldset>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link to="/fleets/vehicles" className="flex w-full">
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
