import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldDriver,
  FieldVehicle,
} from "../../../../components/form";
import { VehicleAssignmentForm } from "../../../../graphql/fleets/vehicle-assignments";
import { VehicleBasic } from "../../../../graphql/fleets/vehicles";
import { renderPrimaryMeter } from "../../../../graphql/fleets/vehicles/pages/settings";
import { classNames, formatDate, formatDateDays } from "../../../../utils";

const ValidationSchema = Yup.object().shape({
  vehicleId: Yup.number().required("Required"),
  operatorId: Yup.number().required("Required"),
  deliveryRunId: Yup.number().required("Required"),
  startDate: Yup.string().required("Required"),
  startingOdometer: Yup.number().nullable(),
  endDate: Yup.string().nullable(),
  endingOdometer: Yup.number().nullable(),
  duration: Yup.string().nullable(),
  usage: Yup.number().nullable(),
  comments: Yup.string().nullable(),
  status: Yup.number().required("Required"),
});

export default function Form({
  initialValues,
  vehicleBasic,
  onSubmit,
  onCancel,
}: {
  initialValues: VehicleAssignmentForm;
  vehicleBasic: VehicleBasic | null;
  onSubmit: (values: VehicleAssignmentForm, actions: any) => void;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (formik.values.startDate && formik.values.endDate) {
      const diff = formatDateDays(
        formik.values.startDate,
        formik.values.endDate
      );
      formik.setFieldValue("duration", diff);
    }
  }, [formik.values.startDate, formik.values.endDate]);

  useEffect(() => {
    if (formik.values.startingOdometer && formik.values.endingOdometer) {
      const usage =
        formik.values.endingOdometer - formik.values.startingOdometer;
      formik.setFieldValue("usage", usage);
    }
  }, [formik.values.startingOdometer, formik.values.endingOdometer]);

  return (
    <form
      className="flex h-full flex-col divide-y divide-gray-200"
      onSubmit={formik.handleSubmit}
    >
      <div className="h-0 flex-1">
        <div className="px-4 py-8 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="mb-2 text-xl font-medium text-primary-900">
              Assignment - {vehicleBasic?.name}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                onClick={onCancel}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-500">
              Vehicle Assignment details for{" "}
              {formatDate(formik.values.startDate)} -{" "}
              {formatDate(formik.values.endDate)}
            </p>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5">
              <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium text-gray-900">
                    {t("text_vehicles")}
                  </label>
                  <FieldVehicle
                    value={formik.values.vehicleId}
                    onChange={(value) => {}}
                    isDisabled
                    className={classNames(
                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      formik.touched.vehicleId && formik.errors.vehicleId
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                  />
                  {formik.touched.vehicleId && formik.errors.vehicleId ? (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="vehicleId-errors"
                    >
                      {formik.errors.vehicleId}
                    </p>
                  ) : null}
                </div>
                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium text-gray-900">
                    {t("text_operator")}
                  </label>
                  <FieldDriver
                    title={t("text_operator")}
                    value={formik.values.operatorId}
                    onChange={(value) => {
                      formik.setFieldValue("operatorId", value);
                    }}
                    className={classNames(
                      "mt-1 rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                      formik.touched.operatorId && formik.errors.operatorId
                        ? "border-red-600 text-red-900"
                        : ""
                    )}
                  />
                  {formik.touched.operatorId && formik.errors.operatorId ? (
                    <p
                      className="mt-2 text-sm text-red-600"
                      id="operatorId-errors"
                    >
                      {formik.errors.operatorId}
                    </p>
                  ) : null}
                </div>
                <div className="sm:col-span-3">
                  <FieldDatepicker
                    title={t("text_start_date")}
                    name="startDate"
                    onChange={(value) => {
                      if (!Array.isArray(value)) {
                        formik.setFieldValue(
                          "startDate",
                          value ? new Date(value).toISOString() : null
                        );
                      }
                    }}
                    selected={
                      formik.values.startDate
                        ? new Date(formik.values.startDate)
                        : null
                    }
                    maxDate={
                      formik.values.endDate
                        ? DateTime.fromISO(formik.values.endDate).toJSDate()
                        : null
                    }
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={30}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    // minTime={undefined}
                    // maxTime={
                    //   formik.values.endDate
                    //     ? DateTime.fromISO(formik.values.endDate).toJSDate()
                    //     : undefined
                    // }
                    touched={formik.touched.startDate}
                    errors={formik.errors.startDate}
                    caption="When assignment starts"
                  />
                </div>
                <div className="sm:col-span-3">
                  <Field
                    title={t("text_starting_odometer")}
                    name="startingOdometer"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.startingOdometer ?? ""}
                    max={formik.values.endingOdometer ?? ""}
                    touched={formik.touched.startingOdometer}
                    errors={formik.errors.startingOdometer}
                    isSuffix
                    suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                    caption="Odometer reading when assignment started."
                  />
                </div>
                <div className="sm:col-span-3">
                  <FieldDatepicker
                    title={t("text_end_date")}
                    name="endDate"
                    onChange={(value) => {
                      if (!Array.isArray(value)) {
                        formik.setFieldValue(
                          "endDate",
                          value ? new Date(value).toISOString() : null
                        );
                      }
                    }}
                    selected={
                      formik.values.endDate
                        ? new Date(formik.values.endDate)
                        : null
                    }
                    minDate={
                      formik.values.startDate
                        ? DateTime.fromISO(formik.values.startDate).toJSDate()
                        : null
                    }
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={30}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    // minTime={
                    //   formik.values.startDate
                    //     ? DateTime.fromISO(formik.values.startDate).toJSDate()
                    //     : undefined
                    // }
                    // maxTime={undefined}
                    touched={formik.touched.endDate}
                    errors={formik.errors.endDate}
                    caption="When does this assignment end"
                  />
                </div>
                <div className="sm:col-span-3">
                  <Field
                    title={t("text_ending_odometer")}
                    name="endingOdometer"
                    type="number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.endingOdometer ?? ""}
                    min={formik.values.startingOdometer ?? ""}
                    touched={formik.touched.endingOdometer}
                    errors={formik.errors.endingOdometer}
                    isSuffix
                    suffix={renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                    caption="Odometer reading when assignment ended."
                  />
                </div>
                <div className="sm:col-span-6">
                  <Field
                    title={t("text_comments")}
                    name="comments"
                    type="textarea"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comments}
                    touched={formik.touched.comments}
                    errors={formik.errors.comments}
                    className="h-48"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
        <Button variant="secondary" onClick={onCancel}>
          {t("text_cancel")}
        </Button>
        <Button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            t("text_save")
          )}
        </Button>
      </div>
    </form>
  );
}
