import { gql } from "@apollo/client";

import { classNames } from "../../../../../utils";

export const VEHICLEISSUE_TIMELINE_FRAGMENT = gql`
  fragment VehicleIssueTimelineFragment on VehicleIssueTimeline {
    id
    info
    createdAt
    updatedAt
  }
`;
export const VEHICLEISSUE_FRAGMENT = gql`
  ${VEHICLEISSUE_TIMELINE_FRAGMENT}
  fragment VehicleIssueFragment on VehicleIssue {
    id
    assignedToId
    description
    issueType
    primaryMeter
    resolvedMeterEntry
    overDueDate
    priority
    reportedById
    reportedDate
    resolvedDate
    status
    statusNotes
    summary
    vehicleId
    timelines {
      ...VehicleIssueTimelineFragment
    }
    createdAt
    updatedAt
  }
`;

export const GET_VEHICLEISSUES = gql`
  ${VEHICLEISSUE_FRAGMENT}
  query FetchVehicleIssue {
    fetchVehicleIssues {
      ...VehicleIssueFragment
    }
  }
`;

export const GET_VEHICLEISSUE_BY_VEHICLE_ID = gql`
  ${VEHICLEISSUE_FRAGMENT}
  query FetchVehicleIssuesByVehicle($vehicleId: ID!) {
    fetchVehicleIssuesByVehicle(vehicleId: $vehicleId) {
      ...VehicleIssueFragment
    }
  }
`;

export const GET_VEHICLEISSUE_BY_ID = gql`
  ${VEHICLEISSUE_FRAGMENT}
  query FetchVehicleIssue($id: ID!) {
    fetchVehicleIssue(id: $id) {
      ...VehicleIssueFragment
    }
  }
`;

export const ADD_VEHICLEISSUE = gql`
  ${VEHICLEISSUE_FRAGMENT}
  mutation VehicleIssueCreate(
    $vehicleId: Int!
    $assignedToId: Int
    $description: String
    $issueType: String
    $primaryMeter: Float
    $overDueDate: ISO8601DateTime
    $priority: Int
    $reportedById: Int
    $reportedDate: ISO8601DateTime!
    $status: Int!
    $statusNotes: String
    $summary: String!
  ) {
    vehicleIssueCreate(
      input: {
        params: {
          vehicleId: $vehicleId
          assignedToId: $assignedToId
          description: $description
          issueType: $issueType
          primaryMeter: $primaryMeter
          overDueDate: $overDueDate
          priority: $priority
          reportedById: $reportedById
          reportedDate: $reportedDate
          status: $status
          statusNotes: $statusNotes
          summary: $summary
        }
      }
    ) {
      vehicleIssue {
        ...VehicleIssueFragment
      }
    }
  }
`;

export const EDIT_VEHICLEISSUE = gql`
  ${VEHICLEISSUE_FRAGMENT}
  mutation VehicleIssueUpdate(
    $id: ID!
    $vehicleId: Int!
    $assignedToId: Int
    $description: String
    $issueType: String
    $primaryMeter: Float
    $overDueDate: ISO8601DateTime
    $priority: Int
    $reportedById: Int
    $reportedDate: ISO8601DateTime!
    $status: Int!
    $statusNotes: String
    $summary: String!
  ) {
    vehicleIssueUpdate(
      input: {
        id: $id
        params: {
          vehicleId: $vehicleId
          assignedToId: $assignedToId
          description: $description
          issueType: $issueType
          primaryMeter: $primaryMeter
          overDueDate: $overDueDate
          priority: $priority
          reportedById: $reportedById
          reportedDate: $reportedDate
          status: $status
          statusNotes: $statusNotes
          summary: $summary
        }
      }
    ) {
      vehicleIssue {
        ...VehicleIssueFragment
      }
    }
  }
`;

export const UPDATE_VEHICLEISSUE_STATUS = gql`
  ${VEHICLEISSUE_FRAGMENT}
  mutation VehicleIssueStatusUpdate(
    $id: ID!
    $status: Int!
    $statusNote: String!
  ) {
    vehicleIssueStatusUpdate(
      input: { id: $id, status: $status, statusNote: $statusNote }
    ) {
      vehicleIssueStatus {
        ...VehicleIssueFragment
      }
    }
  }
`;

export const REMOVE_VEHICLEISSUE = gql`
  ${VEHICLEISSUE_FRAGMENT}
  mutation VehicleIssueDelete($id: ID!) {
    vehicleIssueDelete(input: { id: $id }) {
      vehicleIssue {
        ...VehicleIssueFragment
      }
    }
  }
`;
enum VehicleIssuePriority {
  NO_PRIORITY,
  LOW,
  MEDIUM,
  HIGH,
  CRITICAL,
}
export enum VehicleIssueStatus {
  OPEN,
  RESOLVED,
  CLOSED,
}
export type VehicleIssueTimeline = {
  id: string;
  info: string;
  createdAt: string;
  updatedAt: string;
};
export type VehicleIssue = {
  id: string;
  assignedToId: number | null;
  description: string;
  issueType: string;
  primaryMeter: number | null;
  resolvedMeterEntry: number | null;
  overDueDate: string | null;
  priority: VehicleIssuePriority | null;
  reportedById: number | null;
  reportedDate: string | null;
  resolvedDate: string | null;
  status: VehicleIssueStatus | null;
  statusNotes: string;
  summary: string;
  vehicleId: number;
  timelines: VehicleIssueTimeline[];
  createdAt: string;
  updatedAt: string;
};

export type VehicleIssueForm = Omit<
  VehicleIssue,
  | "id"
  | "vehicleId"
  | "createdAt"
  | "updatedAt"
  | "resolvedMeterEntry"
  | "resolvedDate"
  | "timelines"
> & {
  id?: string;
  vehicleId: number | null;
};

export type VehicleIssueStatusForm = {
  vehicleId: number | null;
  statusNotes: string;
};

export const renderPriority = (status: VehicleIssuePriority | null) => {
  switch (status) {
    case VehicleIssuePriority.NO_PRIORITY:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-gray-200")}
            aria-hidden="true"
          />
          No Priority
        </span>
      );
    case VehicleIssuePriority.LOW:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-green-800")}
            aria-hidden="true"
          />
          Low
        </span>
      );
    case VehicleIssuePriority.MEDIUM:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-yellow-800")}
            aria-hidden="true"
          />
          Medium
        </span>
      );
    case VehicleIssuePriority.HIGH:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-orange-800")}
            aria-hidden="true"
          />
          High
        </span>
      );
    case VehicleIssuePriority.CRITICAL:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-red-800")}
            aria-hidden="true"
          />
          Critical
        </span>
      );
    default:
      return null;
  }
};

export const renderPriorityText = (status: VehicleIssuePriority | null) => {
  switch (status) {
    case VehicleIssuePriority.NO_PRIORITY:
      return "No Priority";
    case VehicleIssuePriority.LOW:
      return "Low";
    case VehicleIssuePriority.MEDIUM:
      return "Medium";
    case VehicleIssuePriority.HIGH:
      return "High";
    case VehicleIssuePriority.CRITICAL:
      return "Critical";
    default:
      return null;
  }
};

export const renderStatus = (status: VehicleIssueStatus | null) => {
  switch (status) {
    case VehicleIssueStatus.OPEN:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-red-800")}
            aria-hidden="true"
          />
          Open
        </span>
      );
    case VehicleIssueStatus.RESOLVED:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-green-800")}
            aria-hidden="true"
          />
          Resolved
        </span>
      );
    case VehicleIssueStatus.CLOSED:
      return (
        <span className="flex items-center">
          <span
            className={classNames("mr-1.5 h-3 w-3 rounded-full bg-yellow-800")}
            aria-hidden="true"
          />
          Resolved
        </span>
      );

    default:
      return null;
  }
};

export const renderStatusText = (status: VehicleIssueStatus | null) => {
  switch (status) {
    case VehicleIssueStatus.OPEN:
      return "Open";
    case VehicleIssueStatus.RESOLVED:
      return "Resolved";
    case VehicleIssueStatus.CLOSED:
      return "Closed";
    default:
      return null;
  }
};
