import { useMutation, useQuery, useSubscription } from "@apollo/client/react";
import { Dialog, Menu, Tab, Transition } from "@headlessui/react";
import {
  ChatBubbleBottomCenterTextIcon,
  CheckBadgeIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import confetti from "canvas-confetti";
import { getIn, useFormik } from "formik";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";
import { createGlobalStyle } from "styled-components";
import * as Yup from "yup";

import { Logo, Spinner, SpinnerInline, Waiting } from "../../../animations";
import placeholder from "../../../assets/placeholder.svg";
import wavTone from "../../../assets/tone.wav";
import { Avatar } from "../../../components/appearance";
import { ErrorFallback } from "../../../components/core";
import {
  Button,
  Field,
  FieldDatepicker,
  FieldModifyProduct,
  selectStyles,
  SelectWrapper,
} from "../../../components/form";
import { Variant } from "../../../graphql/inventory/products";
import {
  ADD_PACKING_COMMENT,
  ADD_PACKING_PRODUCT,
  type Comment,
  GET_PACKING_BY_ID,
  GET_PACKING_COMMENTS_BY_ID,
  GET_PACKING_PRODUCTS,
  GET_PACKING_TIMELINE_BY_ID,
  MODIFIED_PACKAGE,
  type Packing,
  PackingVerificationStatus,
  renderVerificationStatus,
  UPDATE_PACKING,
  UPDATE_PACKING_STATUS,
  UPDATE_PACKING_VERIFICATION,
} from "../../../graphql/pickpack/packing";
import { OrderStatus, ProductApprove } from "../../../graphql/sales/orders";
import { GET_USERS } from "../../../graphql/user-management/users";
import {
  classNames,
  formatCurrency,
  formatDate,
  formatFloat,
  formatTime,
  formatVariantTitle,
} from "../../../utils";
import { isAdmin, useAuth } from "../../auth";
import { UserModel } from "../../auth/@types/auth";
import { CardMessage } from "./components/CardMessage";

const GlobalStyle = createGlobalStyle`
html body .tox-tinymce {
    border: 0;
}`;

const PackingSchema = Yup.object().shape({
  boxCount: Yup.number().min(1, "Minimum 1").required("Required"),
  packedUserId: Yup.number().required("Required").nullable(),
  products: Yup.array().of(
    Yup.object().shape({
      productId: Yup.number().required("Required"),
      oldProductId: Yup.number().nullable().notRequired(),
      quantity: Yup.number().required("Required"),
      price: Yup.number().required("Required"),
      comments: Yup.string().notRequired(),
    })
  ),
  status: Yup.string().required("Required"),
});

export default function PackingDetail() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const isCompleted = useRef<boolean>(false);
  const shipmentAnimateRef = useRef<HTMLButtonElement>(null);
  const [isReadyShip, setIsReadyShip] = useState(false);

  const [packing, setPacking] = useState<Packing | null>(null);
  const [activeTab, setActiveTab] = useState(0);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      return navigate(`/pickpack/packing`, { replace: true });
    }, 400);
  }, [navigate]);

  const [createPackingProduct] = useMutation(ADD_PACKING_PRODUCT);

  const [updatePacking] = useMutation(UPDATE_PACKING, {
    refetchQueries: [
      {
        query: GET_PACKING_PRODUCTS,
        variables: {
          first: 50,
          searchQuery: "",
          searchFilter: [],
          customerId: null,
          deliveryrunId: null,
          deliveryDate: "",
          departmentId: null,
          status: null,
        },
      },
      {
        query: GET_PACKING_COMMENTS_BY_ID,
        variables: { id },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [updatePackingStatus] = useMutation(UPDATE_PACKING_STATUS, {
    refetchQueries: [
      {
        query: GET_PACKING_PRODUCTS,
        variables: {
          first: 50,
          searchQuery: "",
          searchFilter: [],
          customerId: null,
          deliveryrunId: null,
          deliveryDate: "",
          departmentId: null,
          status: null,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [updatePackingVerification, { loading: loadingVerification }] =
    useMutation<{
      packingVerificationUpdate: {
        packing: Packing;
      };
    }>(UPDATE_PACKING_VERIFICATION, {
      refetchQueries: [
        {
          query: GET_PACKING_PRODUCTS,
          variables: {
            first: 50,
            searchQuery: "",
            searchFilter: [],
            customerId: null,
            deliveryrunId: null,
            deliveryDate: "",
            departmentId: null,
            status: null,
          },
        },
      ],
      awaitRefetchQueries: true,
    });

  const { data, loading, refetch } = useQuery<{
    fetchPackingProduct: Packing;
  }>(GET_PACKING_BY_ID, {
    variables: { id },
    onCompleted(data) {
      if (data?.fetchPackingProduct) {
        console.log(data?.fetchPackingProduct.status);
        setTimeout(() => {
          setOpen(true);
        }, 200);
        isCompleted.current =
          data?.fetchPackingProduct.status === OrderStatus.READY_SHIP;
        if (isCompleted.current) {
          setTimeout(() => {
            shipmentAnimateRef.current?.classList.add("animate-placing");
          }, 250);
        }
        setPacking(data.fetchPackingProduct);
        setTimeout(() => {
          shipmentAnimateRef.current?.classList.remove("animate-placing");
          shipmentAnimateRef.current?.classList.add("animate-done");
        }, 3000);
      }
    },
    onError(error) {
      toast.error(error.message);
      handleClose();
    },
  });

  const products = packing?.items.map((item) => ({
    packingComments: item.packingComments ?? "",
    price: item.price,
    oldProductId: item.oldProductId ?? null,
    productId: parseInt(item.variant.id),
    quantity: item.quantity,
  }));

  const formik = useFormik({
    initialValues: {
      boxCount: packing?.boxCount ?? 0,
      packedUserId: packing?.packedBy?.id
        ? parseInt(packing.packedBy.id)
        : null,
      packingDate: packing?.packingDate ?? null,
      products: products ?? [],
      status: packing?.status ?? null,
    },
    enableReinitialize: true,
    validationSchema: PackingSchema,
    onSubmit: async (
      values: {
        boxCount: number;
        packedUserId: number | null;
        packingDate: string | null;
        products: {
          productId: number;
          oldProductId: number | null;
          quantity: number;
          price: number;
          packingComments: string;
        }[];
        status: OrderStatus | null;
      },
      actions
    ) => {
      const loadingId = toast.loading("Processing...");

      if (values.status === OrderStatus.READY_SHIP) {
        updatePackingStatus({
          variables: {
            id,
            status: values.status,
          },
        })
          .then(async ({ data }) => {
            actions.setSubmitting(false);
            if (data?.packingStatusUpdate) {
              toast.dismiss(loadingId);
              confetti({
                particleCount: 150,
                spread: 150,
              });
              await refetch();
              setIsReadyShip(false);
            } else {
              setIsReadyShip(false);
              toast.dismiss(loadingId);
              toast.error("Failed to moved to shipped");
            }
          })
          .catch((error) => {
            actions.setSubmitting(false);
            setIsReadyShip(false);
            toast.dismiss(loadingId);
            toast.error(error.message);
          });
        return;
      }

      const total = values.products.reduce((acc, cur) => {
        const productApprove =
          packing?.items.find(
            (item) => item.variant.id === cur.productId.toString()
          )?.productApprove === ProductApprove.APPROVED;
        if (productApprove) {
          return acc + cur.price * cur.quantity;
        }
        return acc;
      }, 0);
      updatePacking({
        variables: {
          id,
          boxCount: values.boxCount,
          packedUserId: values.packedUserId,
          packingDate: values.packingDate || new Date().toISOString(),
          products: values.products,
          subTotal: total,
          total: total,
          status: values.status,
        },
      })
        .then(async ({ data }) => {
          actions.setSubmitting(false);
          if (data?.packingUpdate) {
            await refetch();
            toast.dismiss(loadingId);
            toast.success("Packaging updated successfully");
          } else {
            toast.dismiss(loadingId);
            toast.error("Packaging update failed");
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.dismiss(loadingId);
          toast.error(error.message);
        });
    },
  });

  const handlePackingVerification = useCallback(
    (packingVerificationStatus: PackingVerificationStatus) => {
      const loadingId = toast.loading("Processing...");

      updatePackingVerification({
        variables: {
          id,
          packingVerificationStatus,
        },
      })
        .then(async ({ data }) => {
          if (data?.packingVerificationUpdate?.packing) {
            refetch();
            toast.dismiss(loadingId);
            let rensponseText = "";
            switch (
              data.packingVerificationUpdate.packing.packingVerificationStatus
            ) {
              case PackingVerificationStatus.VERIFIED:
                rensponseText = "Packing verification completed!";
                break;
              case PackingVerificationStatus.REVISION:
                rensponseText = "Packing submitted for revision!";
                break;
              case PackingVerificationStatus.PENDING:
                rensponseText = "Packing moved to pending!";
                break;
              default:
                rensponseText = "Packing verification status updated";
                break;
            }

            toast.success(rensponseText);
          } else {
            setIsReadyShip(false);
            toast.dismiss(loadingId);
            toast.error("Failed to moved packing");
          }
        })
        .catch((error) => {
          toast.dismiss(loadingId);
          toast.error(error.message);
        });
    },
    [id, refetch, updatePackingVerification]
  );

  const handleTabClass = ({ selected }: { selected: boolean }) =>
    classNames(
      "group mr-5 flex border-b-4 relative font-medium -bottom-[1px] border-solid focus:outline-0 border-transparent py-1.5 px-2 text-base text-gray-500 transition-all",
      selected ? "border-primary-700 text-black" : "hover:text-gray-500"
    );

  const handleShipment = useCallback(() => {
    setIsReadyShip(true);
    formik.setFieldValue("status", OrderStatus.READY_SHIP);
    formik.handleSubmit();
  }, [formik]);

  const isEditable = useCallback(() => {
    return (
      packing?.packingVerificationStatus === null ||
      packing?.packingVerificationStatus === PackingVerificationStatus.REVISION
    );
  }, [packing?.packingVerificationStatus]);

  const handleProductChange = useCallback(
    (index: number, variant: Variant) => {
      if (!variant || !packing) return;

      const oldProductId =
        Number(data?.fetchPackingProduct.items[index].variant.id) || null;
      formik.setValues({
        ...formik.values,
        products: formik.values.products.map((product, i) => {
          if (i === index) {
            return {
              ...product,
              oldProductId,
              productId: Number(variant.id),
              price: variant?.basePrice ? variant.basePrice : 0,
            };
          }
          return product;
        }),
      });
      const updatedPacking = {
        ...packing,
        items: packing.items.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              oldProductId,
              productId: Number(variant.id),
              price: variant?.basePrice ? variant.basePrice : 0,
              variant,
            };
          }
          return item;
        }),
      };

      setPacking(updatedPacking);
    },
    [data?.fetchPackingProduct.items, formik, packing]
  );

  const handleProductAdd = useCallback(
    async (variant: Variant) => {
      if (!variant || !packing) return;

      await createPackingProduct({
        variables: {
          orderId: packing.id,
          productId: Number(variant.id),
          price: variant?.basePrice ? variant.basePrice : 0,
          quantity: 1,
        },
      });
      refetch();
      toast.success("Product sent for approval");
    },
    [createPackingProduct, packing, refetch]
  );

  const totalPrice = formatFloat(
    formik.values.products.reduce((acc, cur) => {
      const productApprove =
        packing?.items.find(
          (item) => item.variant.id === cur.productId.toString()
        )?.productApprove === ProductApprove.APPROVED;

      if (productApprove) {
        return acc + cur.price * cur.quantity;
      }
      return acc;
    }, 0)
  );

  const departments =
    packing?.items.filter((item) => item.variant.product?.department) || [];
  const departmentNames = departments.flatMap(
    (item) => item.variant.product?.department.name
  );
  const departmentFiltered = departmentNames.filter(
    (item, index) => departmentNames.indexOf(item) === index
  );

  return (
    <>
      <GlobalStyle />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="p-6">
                        <div className="flex items-start justify-between">
                          <div className="mr-3">
                            <Dialog.Title className="mb-2 text-xl font-medium text-primary-900">
                              <span className="mr-2">Packing</span>
                              {renderVerificationStatus(
                                packing?.packingVerificationStatus
                              )}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              Edit packing details and update status here
                            </p>
                          </div>
                          <div className="flex items-center space-x-4">
                            {packing?.status === OrderStatus.PACKING &&
                            (packing?.packingVerificationStatus ===
                              PackingVerificationStatus.REVISION ||
                              packing?.packingVerificationStatus === null) ? (
                              <Fragment>
                                <Button
                                  type="submit"
                                  disabled={formik.isSubmitting}
                                  onClick={() => formik.handleSubmit()}
                                  className="h-12 py-0"
                                >
                                  {formik.isSubmitting ? (
                                    <>
                                      <Spinner />
                                      {t("text_processing")}
                                    </>
                                  ) : (
                                    t("text_update")
                                  )}
                                </Button>
                                <Button
                                  variant="info"
                                  border
                                  disabled={
                                    !packing?.packedBy ||
                                    !packing?.boxCount ||
                                    packing?.boxCount === 0
                                  }
                                  loading={isReadyShip}
                                  className="h-12 justify-center py-0"
                                  onClick={() => {
                                    handlePackingVerification(
                                      PackingVerificationStatus.PENDING
                                    );
                                  }}
                                >
                                  Request Verification
                                </Button>
                              </Fragment>
                            ) : null}

                            {isCompleted.current && (
                              <button
                                ref={shipmentAnimateRef}
                                type="button"
                                className="button-shipping animate-placing pointer-events-none h-12 cursor-default rounded-md bg-secondary font-normal text-white"
                                disabled
                              >
                                <div className="default text leading-[3rem]">
                                  Ready To Ship
                                </div>
                                <div className="forklift">
                                  <div className="upper"></div>
                                  <div className="lower"></div>
                                </div>
                                <div className="box animation"></div>
                                <div className="done text leading-[3rem]">
                                  <svg xmlns="http://www.w3.org/2000/svg">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                  </svg>
                                  Completed
                                </div>
                              </button>
                            )}
                            {packing?.packingVerificationStatus ===
                            PackingVerificationStatus.PENDING ? (
                              <Menu as="div" className="relative">
                                <div>
                                  <Menu.Button className="flex h-12 items-center rounded-lg border border-primary-700 bg-transparent px-4 text-primary-700 hover:bg-primary-50 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50">
                                    {loadingVerification ? (
                                      <SpinnerInline className="mr-1" />
                                    ) : (
                                      <CheckBadgeIcon className="mr-1 h-6 w-6" />
                                    )}
                                    Verify
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    className="absolute right-0 z-10 mt-4 w-36 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-2 md:w-44 md:px-2.5 md:py-3"
                                    static
                                  >
                                    <svg
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      x="0px"
                                      y="0px"
                                      width="16px"
                                      height="16px"
                                      viewBox="0 0 110.9 96"
                                      enableBackground="new 0 0 110.9 96"
                                      className="absolute -top-3 right-0 -z-0"
                                      fill="#ffffff"
                                      style={{
                                        filter:
                                          "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
                                      }}
                                    >
                                      <polygon points="110.9,0 0,96 110.9,96 " />
                                    </svg>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div className="py-0.5">
                                          <button
                                            className={classNames(
                                              active
                                                ? "bg-primary-700/10 text-primary-700"
                                                : "",
                                              "group flex w-full items-center rounded-md px-1.5 py-1 text-left text-sm text-gray-700 transition ease-in-out md:px-2 md:py-1.5"
                                            )}
                                            onClick={() => {
                                              handlePackingVerification(
                                                PackingVerificationStatus.VERIFIED
                                              );
                                            }}
                                          >
                                            <span
                                              className="bi bi-check-circle mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current"
                                              aria-hidden="true"
                                            />
                                            <span className="flex-1 truncate">
                                              Verified
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <div className="py-0.5">
                                          <button
                                            className={classNames(
                                              active
                                                ? "bg-primary-700/10 text-primary-700"
                                                : "",
                                              "group flex w-full items-center rounded-md px-1.5 py-1 text-left text-sm text-gray-700 transition ease-in-out md:px-2 md:py-1.5"
                                            )}
                                            onClick={() => {
                                              handlePackingVerification(
                                                PackingVerificationStatus.REVISION
                                              );
                                            }}
                                          >
                                            <span
                                              className="bi bi-pencil-square mr-2 h-6 w-6 flex-shrink-0 text-center text-xl leading-6 text-current"
                                              aria-hidden="true"
                                            />
                                            <span className="flex-1 truncate">
                                              Revision
                                            </span>
                                          </button>
                                        </div>
                                      )}
                                    </Menu.Item>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            ) : null}
                            {packing?.status === OrderStatus.PACKING &&
                            packing?.packingVerificationStatus ===
                              PackingVerificationStatus.VERIFIED ? (
                              <Button
                                onClick={handleShipment}
                                variant="tertiary"
                                disabled={
                                  !packing?.packedBy ||
                                  !packing?.boxCount ||
                                  packing?.boxCount === 0
                                }
                                loading={isReadyShip}
                                className="h-12 w-[240px] justify-center py-0"
                              >
                                Ready To Ship
                              </Button>
                            ) : null}
                            <button
                              type="button"
                              className="appearance-none rounded-md text-primary-900 transition-colors hover:text-primary-900 focus:outline-none focus-visible:border-primary-800 focus-visible:ring-4 focus-visible:ring-primary-50"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {loading ? (
                        <div className="flex h-full flex-col items-center justify-center">
                          <Logo />
                        </div>
                      ) : packing ? (
                        <div>
                          <div className="flex-1 divide-y divide-gray-200 overflow-y-auto px-6 pb-6">
                            <div className="relative space-y-4 md:space-y-6">
                              <div>
                                <Tab.Group
                                  selectedIndex={activeTab}
                                  onChange={setActiveTab}
                                >
                                  <Tab.List className="mb-4 flex border-b border-solid border-gray-200 md:mb-6 xl:mb-8">
                                    <Tab className={handleTabClass}>
                                      Packing Slip
                                    </Tab>
                                    <Tab className={handleTabClass}>
                                      Timeline
                                    </Tab>
                                  </Tab.List>
                                  <Tab.Panels>
                                    <Tab.Panel className="focus:outline-0">
                                      <form onSubmit={formik.handleSubmit}>
                                        <div className="grid gap-4 text-sm sm:grid-cols-2">
                                          <div className="grid items-center gap-4 sm:grid-cols-2">
                                            <span>Packing No.</span>
                                            <span className="font-light">
                                              #{packing.packingNo}
                                            </span>
                                          </div>
                                          <div className="grid items-center gap-4 sm:grid-cols-2">
                                            <span>Purchase No.</span>
                                            <span className="font-light">
                                              #{packing.purchaseNumber}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Before</span>
                                            <span className="font-light">
                                              -
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>After</span>
                                            <span className="font-light">
                                              -
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>To</span>
                                            <span className="font-light">
                                              {packing.customer.customerName}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Customer PLU</span>
                                            <span className="font-light">
                                              {packing.customer.customerPlu ||
                                                "-"}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Notes</span>
                                            <span className="font-light">
                                              {packing.notes || "-"}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Departments</span>
                                            <span className="font-light">
                                              {departmentFiltered.length > 0
                                                ? departmentFiltered.join(", ")
                                                : "-"}
                                            </span>
                                          </div>

                                          <div className="grid items-center gap-4 sm:col-span-2 sm:grid-cols-2">
                                            <div className="grid gap-4 sm:grid-cols-2">
                                              <span>Delivery Date</span>
                                              <span className="font-light">
                                                {formatDate(
                                                  packing.deliveryDate
                                                )}
                                              </span>
                                            </div>
                                            <div className="grid items-center sm:grid-cols-2">
                                              <span>Packing Date</span>
                                              <div>
                                                <FieldDatepicker
                                                  title={t("text_packing_date")}
                                                  name="packingDate"
                                                  onChange={(value) => {
                                                    if (!Array.isArray(value)) {
                                                      formik.setFieldValue(
                                                        "packingDate",
                                                        value
                                                          ? new Date(
                                                              value
                                                            ).toISOString()
                                                          : ""
                                                      );
                                                    }
                                                  }}
                                                  selected={
                                                    formik.values.packingDate
                                                      ? new Date(
                                                          formik.values.packingDate
                                                        )
                                                      : null
                                                  }
                                                  disabled={!isEditable()}
                                                  touched={
                                                    formik.touched.packingDate
                                                  }
                                                  errors={
                                                    formik.errors.packingDate
                                                  }
                                                  isLabel={false}
                                                  placeholderText={t(
                                                    "text_packing_date"
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mt-6 md:mt-8 xl:mt-10">
                                          <div className="grid grid-cols-8 gap-4 border-b border-gray-200 pb-4">
                                            <div className="text-sm sm:col-span-2">
                                              <span>Items List</span>
                                            </div>
                                            <div className="sm:col-span-6">
                                              <table className="min-w-full divide-y divide-gray-300">
                                                <thead>
                                                  <tr>
                                                    <th
                                                      scope="col"
                                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900/60 sm:pl-0"
                                                    >
                                                      Name
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="hidden px-3 py-3.5 text-left text-sm font-medium text-gray-900/60 sm:table-cell"
                                                    >
                                                      Quantity
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="px-3 py-3.5 text-left text-sm font-medium text-gray-900/70"
                                                    >
                                                      Price
                                                    </th>
                                                    <th
                                                      scope="col"
                                                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                                    >
                                                      <span className="sr-only">
                                                        Edit
                                                      </span>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                  {formik.values.products.map(
                                                    (item, index) => {
                                                      const variant =
                                                        packing?.items[index]
                                                          ?.variant;
                                                      if (!variant) return null;

                                                      const productName =
                                                        variant.product?.name;
                                                      const variantName =
                                                        formatVariantTitle(
                                                          variant.variantTitle
                                                        );
                                                      const productTitle = `${productName} ${
                                                        variantName
                                                          ? `(${variantName})`
                                                          : ""
                                                      }`;

                                                      if (
                                                        packing?.items[index]
                                                          .productApprove ===
                                                        ProductApprove.REQUESTED
                                                      ) {
                                                        return (
                                                          <Fragment
                                                            key={
                                                              packing.items[
                                                                index
                                                              ].id
                                                            }
                                                          >
                                                            <tr className="border-t border-gray-200">
                                                              <td className="w-full max-w-0 py-4 pl-4 pr-3 align-middle text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                                                <div className="flex items-center">
                                                                  <div className="h-11 w-11 flex-shrink-0 overflow-hidden rounded-md">
                                                                    {variant.variantImageUrl ? (
                                                                      <img
                                                                        className={classNames(
                                                                          "h-full w-full object-cover"
                                                                        )}
                                                                        src={
                                                                          variant.variantImageUrl
                                                                        }
                                                                        alt={
                                                                          productTitle
                                                                        }
                                                                      />
                                                                    ) : (
                                                                      <img
                                                                        className={classNames(
                                                                          "h-full w-full object-cover"
                                                                        )}
                                                                        src={
                                                                          variant
                                                                            ?.product
                                                                            ?.featureImageUrl
                                                                            ? variant
                                                                                .product
                                                                                .featureImageUrl
                                                                            : placeholder
                                                                        }
                                                                        alt={
                                                                          productTitle
                                                                        }
                                                                      />
                                                                    )}
                                                                  </div>
                                                                  <div className="ml-4">
                                                                    <div className="font-normal text-gray-900">
                                                                      {
                                                                        productTitle
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                              <td
                                                                colSpan={3}
                                                                className="py-4 pl-3 pr-4 text-right align-middle text-sm sm:pr-0"
                                                              >
                                                                <p className="rounded-lg bg-red-50 px-4 py-3 text-center text-red-700">
                                                                  Product
                                                                  request sent
                                                                  for approval
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </Fragment>
                                                        );
                                                      }

                                                      return (
                                                        <Fragment
                                                          key={
                                                            packing.items[index]
                                                              .id
                                                          }
                                                        >
                                                          <tr className="border-t border-gray-200">
                                                            <td className="w-full max-w-0 py-4 pl-4 pr-3 align-top text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                                                              <div className="flex items-center">
                                                                <div className="h-11 w-11 flex-shrink-0 overflow-hidden rounded-md">
                                                                  {variant.variantImageUrl ? (
                                                                    <img
                                                                      className={classNames(
                                                                        "h-full w-full object-cover"
                                                                      )}
                                                                      src={
                                                                        variant.variantImageUrl
                                                                      }
                                                                      alt={
                                                                        productTitle
                                                                      }
                                                                    />
                                                                  ) : (
                                                                    <img
                                                                      className={classNames(
                                                                        "h-full w-full object-cover"
                                                                      )}
                                                                      src={
                                                                        variant
                                                                          ?.product
                                                                          ?.featureImageUrl
                                                                          ? variant
                                                                              .product
                                                                              .featureImageUrl
                                                                          : placeholder
                                                                      }
                                                                      alt={
                                                                        productTitle
                                                                      }
                                                                    />
                                                                  )}
                                                                </div>
                                                                <div className="ml-4">
                                                                  <div className="font-normal text-gray-900">
                                                                    {
                                                                      productTitle
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td className="px-3 py-4 align-top text-sm">
                                                              <Field
                                                                title={t(
                                                                  "text_quantity"
                                                                )}
                                                                name={`products[${index}].quantity`}
                                                                type="number"
                                                                min={0}
                                                                onChange={
                                                                  formik.handleChange
                                                                }
                                                                onBlur={
                                                                  formik.handleBlur
                                                                }
                                                                value={
                                                                  item.quantity
                                                                }
                                                                disabled={
                                                                  !isEditable()
                                                                }
                                                                touched={getIn(
                                                                  formik.touched,
                                                                  `products[${index}].quantity`
                                                                )}
                                                                errors={getIn(
                                                                  formik.errors,
                                                                  `products[${index}].quantity`
                                                                )}
                                                                isLabel={false}
                                                                placeholder={t(
                                                                  "text_quantity"
                                                                )}
                                                                className="w-20"
                                                              />
                                                            </td>
                                                            <td className="px-3 py-4 align-top text-sm">
                                                              <Field
                                                                title={t(
                                                                  "text_price"
                                                                )}
                                                                name={`products[${index}].price`}
                                                                type="number"
                                                                min={0}
                                                                onChange={
                                                                  formik.handleChange
                                                                }
                                                                onBlur={
                                                                  formik.handleBlur
                                                                }
                                                                value={formatFloat(
                                                                  item.price
                                                                )}
                                                                disabled={
                                                                  !isEditable()
                                                                }
                                                                touched={getIn(
                                                                  formik.touched,
                                                                  `products[${index}].price`
                                                                )}
                                                                errors={getIn(
                                                                  formik.errors,
                                                                  `products[${index}].price`
                                                                )}
                                                                isLabel={false}
                                                                placeholder={t(
                                                                  "text_price"
                                                                )}
                                                                className="w-20"
                                                              />
                                                            </td>
                                                            <td className="py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-0">
                                                              <FieldModifyProduct
                                                                title={
                                                                  productTitle
                                                                }
                                                                customerId={parseInt(
                                                                  packing.customerId
                                                                )}
                                                                value={
                                                                  item.productId
                                                                }
                                                                excludeIds={[
                                                                  ...formik.values.products.flatMap(
                                                                    (item) =>
                                                                      item.productId
                                                                  ),
                                                                ]}
                                                                excludeNote="Already added"
                                                                onChange={(
                                                                  variant
                                                                ) => {
                                                                  handleProductChange(
                                                                    index,
                                                                    variant
                                                                  );
                                                                }}
                                                                disabled={
                                                                  !isEditable()
                                                                }
                                                              >
                                                                <Fragment>
                                                                  <PencilIcon className="h-4 w-4" />
                                                                  <span className="sr-only">
                                                                    Replace
                                                                    product,{" "}
                                                                    {
                                                                      productTitle
                                                                    }
                                                                  </span>
                                                                </Fragment>
                                                              </FieldModifyProduct>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              colSpan={5}
                                                              className="pb-4"
                                                            >
                                                              <Field
                                                                title={t(
                                                                  "text_comment"
                                                                )}
                                                                name={`products[${index}].packingComments`}
                                                                type="textarea"
                                                                onChange={
                                                                  formik.handleChange
                                                                }
                                                                onBlur={
                                                                  formik.handleBlur
                                                                }
                                                                value={
                                                                  item.packingComments ||
                                                                  ""
                                                                }
                                                                disabled={
                                                                  !isEditable()
                                                                }
                                                                touched={getIn(
                                                                  formik.touched,
                                                                  `products[${index}].packingComments`
                                                                )}
                                                                errors={getIn(
                                                                  formik.errors,
                                                                  `products[${index}].packingComments`
                                                                )}
                                                                className="h-16"
                                                              />
                                                            </td>
                                                          </tr>
                                                        </Fragment>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                                <tfoot>
                                                  <tr>
                                                    <td
                                                      colSpan={2}
                                                      className="w-full max-w-0 py-4 pl-4 pr-3 align-middle text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
                                                    >
                                                      Insert new product to the
                                                      list
                                                    </td>
                                                    <td
                                                      colSpan={2}
                                                      className="py-4 pl-3 pr-4 text-right align-middle text-sm font-medium sm:pr-0"
                                                    >
                                                      <FieldModifyProduct
                                                        title="Add Product"
                                                        customerId={parseInt(
                                                          packing.customerId
                                                        )}
                                                        value={null}
                                                        excludeIds={[
                                                          ...formik.values.products.flatMap(
                                                            (item) =>
                                                              item.productId
                                                          ),
                                                        ]}
                                                        excludeNote="Already added"
                                                        onChange={(variant) => {
                                                          handleProductAdd(
                                                            variant
                                                          );
                                                        }}
                                                        disabled={!isEditable()}
                                                        className="w-full justify-center"
                                                      >
                                                        <Fragment>
                                                          <PlusIcon className="h-4 w-4" />
                                                          <span className="ml-2">
                                                            Add product
                                                          </span>
                                                        </Fragment>
                                                      </FieldModifyProduct>
                                                    </td>
                                                  </tr>
                                                </tfoot>
                                              </table>
                                              <div className="mb-4 mt-4 space-y-2 border-t border-gray-200 pt-4">
                                                <div className="flex flex-row justify-end">
                                                  <span className="mr-4">
                                                    Sub Total:
                                                  </span>
                                                  <span className="ml-2 font-medium text-gray-900">
                                                    {formatCurrency(totalPrice)}
                                                  </span>
                                                </div>
                                                <div className="flex flex-row items-end justify-end">
                                                  <span className="mr-4">
                                                    Total:
                                                  </span>
                                                  <span className="ml-2 text-lg font-semibold text-gray-900">
                                                    {formatCurrency(totalPrice)}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="my-6 grid gap-4 text-sm sm:grid-cols-2 md:my-8 md:gap-6 xl:my-10 xl:gap-8">
                                          <div className="grid items-center gap-4 sm:grid-cols-2">
                                            <span>Box Count</span>
                                            <div>
                                              <Field
                                                title={t("text_box_count")}
                                                name="boxCount"
                                                type="number"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.boxCount}
                                                disabled={!isEditable()}
                                                touched={
                                                  formik.touched.boxCount
                                                }
                                                errors={formik.errors.boxCount}
                                                isLabel={false}
                                                placeholder={t(
                                                  "text_box_count"
                                                )}
                                                className="w-20"
                                              />
                                            </div>
                                          </div>
                                          <div className="grid items-center gap-4 sm:grid-cols-3">
                                            <span>Packed By</span>
                                            <div className="sm:col-span-2">
                                              <FieldPackedBy
                                                value={
                                                  formik.values.packedUserId
                                                    ? formik.values.packedUserId
                                                    : null
                                                }
                                                onChange={(
                                                  value: number | null
                                                ) => {
                                                  formik.setFieldValue(
                                                    "packedUserId",
                                                    value
                                                  );
                                                }}
                                                disabled={!isEditable()}
                                                className={classNames(
                                                  "rounded-md border border-gray-300 bg-white text-black focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
                                                  formik.touched.packedUserId &&
                                                    formik.errors.packedUserId
                                                    ? "border-red-600 text-red-900"
                                                    : ""
                                                )}
                                              />
                                              {formik.touched.packedUserId &&
                                              formik.errors.packedUserId ? (
                                                <p
                                                  className="mt-2 text-sm text-red-600"
                                                  id="packedUserId-errors"
                                                >
                                                  {formik.errors.packedUserId}
                                                </p>
                                              ) : null}
                                            </div>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Sales Rep</span>
                                            <span className="font-light">
                                              {packing.salesRep}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Delivery Run</span>
                                            <span className="font-light">
                                              {packing.deliveryRun}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Loading Dock</span>
                                            <span className="font-light">
                                              {packing.loadingDock}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Order Number</span>
                                            <span className="font-light">
                                              #{packing.orderNumber}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Order Date</span>
                                            <span className="font-light">
                                              {formatDate(packing.createdAt)}
                                            </span>
                                          </div>

                                          <div className="grid gap-4 sm:grid-cols-2">
                                            <span>Taken By</span>
                                            <span className="font-light">
                                              -
                                            </span>
                                          </div>
                                        </div>
                                      </form>
                                      <hr />
                                      <Comments />
                                    </Tab.Panel>
                                    <Tab.Panel className="focus:outline-0">
                                      <Timeline />
                                    </Tab.Panel>
                                  </Tab.Panels>
                                </Tab.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export const PackingDetailResource: ResourceProps = {
  name: "Packing Details",
  description: "A list of products that are ready to be shipped.",
  access: ["update-packings"],
  path: "/:id",
};

function Comments() {
  const { t } = useTranslation();
  // const bottomRef = useRef<HTMLDivElement>(null);
  const { id } = useParams<{ id: string }>();
  const { currentUser } = useAuth();
  const {
    data,
    loading,
    error,
    refetch: refetchComments,
  } = useQuery<{
    fetchPackingProduct: Packing;
  }>(GET_PACKING_COMMENTS_BY_ID, {
    variables: { id },
  });

  const [createPackingComment] = useMutation(ADD_PACKING_COMMENT);
  const [comments, setComments] = useState<Comment[]>([]);
  useEffect(() => {
    if (data?.fetchPackingProduct?.comments) {
      const updatedComments = data.fetchPackingProduct.comments.map(
        (comment) => ({
          ...comment,
          createdAt: formatDate(comment.createdAt),
        })
      );
      setComments(updatedComments);
    }
  }, [data?.fetchPackingProduct?.comments]);

  useSubscription<{
    packageModified: Comment;
  }>(MODIFIED_PACKAGE, {
    variables: {
      packageModifiedId: id,
    },
    onData({ data }) {
      if (data?.data?.packageModified) {
        const foundComment = comments.find(
          (comment) => comment.id === data?.data?.packageModified.id
        );
        if (foundComment) return;
        const updatedComment = data?.data?.packageModified;
        if (updatedComment.comments?.includes("The order is ready to pack."))
          return;

        setComments((prev) => [
          ...prev,
          {
            ...updatedComment,
            createdAt: formatDate(updatedComment.createdAt),
          },
        ]);

        if (currentUser && updatedComment.userId !== parseInt(currentUser.id)) {
          // bottomRef.current?.scrollIntoView({ behavior: "smooth" });
          toast.custom(
            (t) => (
              <div
                className={`${
                  t.visible ? "animate-enter" : "animate-leave"
                } pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
              >
                <div className="w-0 flex-1 p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <Avatar
                        firstName={updatedComment.user.firstName}
                        lastName={updatedComment.user.lastName}
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        {`${updatedComment.user.firstName} ${updatedComment.user.lastName}`}
                      </p>
                      <div
                        className="mt-1 text-sm text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: updatedComment.comments || "",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Close
                  </button>
                </div>
              </div>
            ),
            {
              duration: 100000,
            }
          );
          const tone = new Audio(wavTone);
          tone.addEventListener("canplaythrough", (event) => {
            tone.play();
          });
        }
      }
    },
    onError(error) {
      toast.error(error.message, {
        position: "bottom-center",
      });
    },
  });
  const MessageSchema = Yup.object().shape({
    message: Yup.string().required("Required"),
    orderId: Yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
      orderId: id ? parseInt(id) : null,
    },
    enableReinitialize: true,
    validationSchema: MessageSchema,
    onSubmit: (values, actions) => {
      createPackingComment({
        variables: {
          message: values.message,
          orderId: values.orderId,
        },
      })
        .then(async ({ data }) => {
          actions.setSubmitting(false);
          if (data?.packingCommentCreate) {
            await refetchComments();
            toast.success("Comment added successfully!");
            formik.resetForm();
          } else {
            toast.error("Failed to comment!");
          }
        })
        .catch((error) => {
          actions.setSubmitting(false);
          toast.error(error.message);
        });
    },
  });

  if (error) return <ErrorFallback error={error} />;
  if (loading) return <Waiting />;

  return (
    <>
      <div className="relative">
        <div className="mt-6 font-medium md:mt-8 xl:mt-10">Comments</div>

        {comments.length > 0 ? (
          <div className="space-y-4 px-2 py-6 md:space-y-6 md:px-4 md:py-8 lg:px-6 xl:space-y-8 xl:py-10">
            {comments.map((item) => (
              <CardMessage key={`comment-${item.id}`} {...item} />
            ))}
          </div>
        ) : (
          <div className="py-10 md:py-16 xl:py-20">
            <p className="mx-auto max-w-sm text-center font-light">
              Start a conversation or mention someone to ask a questions in the
              below comment box
            </p>
          </div>
        )}

        <hr />
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6 flex items-start space-x-4 md:mt-8 xl:mt-10">
            <div className="flex-shrink-0">
              <Avatar
                firstName={currentUser?.firstName}
                lastName={currentUser?.lastName}
                className={classNames("ml-3 h-12 w-12")}
              />
            </div>
            <div className="min-w-0 flex-1">
              <div className="relative">
                <div className="overflow-hidden rounded-lg ring-1 ring-inset ring-gray-200 focus-within:ring-2 focus-within:ring-primary-600">
                  <label htmlFor="message" className="sr-only">
                    Add your comment
                  </label>
                  <textarea
                    rows={3}
                    name="message"
                    id="message"
                    className="block w-full resize-none border-0 bg-transparent px-3.5 py-3.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Add your comment..."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />

                  <div className="py-2" aria-hidden="true">
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-3 pl-3 pr-3">
                  <div className="flex items-center space-x-5">
                    {/* <div className="flex items-center">
                    <button
                        type="button"
                        className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    >
                        <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Attach a file</span>
                    </button>
                    </div> */}
                  </div>
                  <div className="flex flex-shrink-0 space-x-2">
                    {/* <Listbox
                      as="div"
                      value={assigned}
                      onChange={setAssigned}
                      className="flex-shrink-0"
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="sr-only">
                            Assign
                          </Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-md border border-gray-200 bg-gray-50 px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-100">
                              {assigned.value === null ? (
                                <UserCircleIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-500 sm:-ml-1"
                                  aria-hidden="true"
                                />
                              ) : (
                                <img
                                  src={assigned.avatar}
                                  alt=""
                                  className="h-5 w-5 flex-shrink-0 rounded-full"
                                />
                              )}

                              <span
                                className={classNames(
                                  assigned.value === null
                                    ? ""
                                    : "text-gray-900",
                                  "hidden truncate sm:ml-2 sm:block"
                                )}
                              >
                                {assigned.value === null
                                  ? "Assign"
                                  : assigned.name}
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute bottom-0 right-0 mb-12 w-64 origin-bottom-right rounded-bl-2xl rounded-tl-2xl rounded-tr-2xl bg-white py-3 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mb-14 md:w-56">
                                <svg
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="16px"
                                  height="16px"
                                  viewBox="0 0 27.7 24"
                                  enableBackground="new 0 0 27.7 24"
                                  className="absolute -bottom-[14px] right-0 -z-0"
                                  fill="#ffffff"
                                  style={{
                                    filter:
                                      "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 1px)",
                                  }}
                                >
                                  <polygon points="27.7,24 0,0 27.7,0 " />
                                </svg>
                                {assignees.map((assignee) => (
                                  <Listbox.Option
                                    key={assignee.value}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "bg-gray-100"
                                          : "bg-white",
                                        "relative cursor-default select-none px-3 py-2"
                                      )
                                    }
                                    value={assignee}
                                  >
                                    <div className="flex items-center">
                                      {assignee.avatar ? (
                                        <img
                                          src={assignee.avatar}
                                          alt=""
                                          className="h-5 w-5 flex-shrink-0 rounded-full"
                                        />
                                      ) : (
                                        <UserCircleIcon
                                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      )}

                                      <span className="ml-3 block truncate font-medium">
                                        {assignee.name}
                                      </span>
                                    </div>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox> */}
                    <Button
                      type="submit"
                      className="px-3 py-2 text-sm"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <>
                          <Spinner />
                          {t("text_processing")}
                        </>
                      ) : (
                        <>
                          <ChatBubbleBottomCenterTextIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          {t("text_comment")}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              {formik.touched && formik.errors ? (
                <p className="mt-2 text-sm text-red-600" id={`message-errors`}>
                  {formik.errors.message}
                </p>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

function Timeline() {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useQuery<{
    fetchPackingProduct: Packing;
  }>(GET_PACKING_TIMELINE_BY_ID, {
    variables: { id },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <Waiting />;

  if (error) return <ErrorFallback error={error} />;

  const timelines = data?.fetchPackingProduct?.timelines || [];

  return (
    <div className="px-4 py-4 md:px-6 md:py-6 lg:px-8 xl:py-8">
      <ul className="space-y-6 font-light">
        {timelines.map((timeline, index) => (
          <li key={timeline.id} className="relative flex gap-x-4">
            <div
              className={classNames(
                index === timelines.length - 1 ? "h-6" : "-bottom-6",
                "absolute left-0 top-0 flex w-6 justify-center"
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>

            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
              {index === 0 ? (
                <CheckCircleIcon
                  className="h-6 w-6 text-primary-600"
                  aria-hidden="true"
                />
              ) : (
                <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
              )}
            </div>
            <div
              className="flex-auto py-0.5 text-sm leading-5 text-gray-500"
              dangerouslySetInnerHTML={{
                __html: timeline.info,
              }}
            />
            <time
              dateTime={timeline.updatedAt}
              className="flex-none py-0.5 text-sm leading-5 text-gray-500"
            >
              {formatTime(timeline.updatedAt)}
            </time>
          </li>
        ))}
      </ul>
    </div>
  );
}

function FieldPackedBy({
  value,
  onChange,
  disabled = false,
  className,
}: {
  value: number | null;
  onChange: (newValue: number | null) => void;
  disabled?: boolean;
  className: string;
}) {
  const [values, setValues] = useState<SingleValue<OptionProps>>(null);

  const { data, loading, refetch } = useQuery(GET_USERS);
  const options: MultiValue<OptionProps> = useMemo(() => {
    if (data?.fetchUsers) {
      const filteredOptions = data.fetchUsers.filter((u: UserModel) =>
        u.roles.some((r) => !isAdmin(r))
      );
      return filteredOptions?.map((p: UserModel) => {
        return {
          label: p.fullName,
          value: p.id,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (value) {
      const user = options.find((o) => parseInt(o.value) === value) || null;
      setValues(user);
      return;
    }
    setValues(null);
  }, [options, value]);

  return (
    <SelectWrapper className={className}>
      <Select
        closeMenuOnSelect={true}
        styles={selectStyles}
        value={values}
        options={options}
        onChange={(value) => {
          setValues(value);
          onChange(value?.value ? parseInt(value.value) : null);
        }}
        isClearable
        isLoading={loading}
        isDisabled={disabled}
        onMenuOpen={() => {
          refetch();
        }}
      />
    </SelectWrapper>
  );
}
