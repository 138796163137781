import { Dialog, Switch } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useFormik } from "formik";
import pluralize from "pluralize";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Spinner } from "../../../../animations";
import { Button, Field } from "../../../../components/form";
import { classNames } from "../../../../utils";

type Resource = {
  resourceName: string;
  resourceDescription: string;
  status: boolean;
};

export default function Form({
  heading,
  initialValues,
  onSubmit,
  submitLabel,
  onCancel,
  cancelLabel,
}: {
  heading: string;
  initialValues: Resource;
  onSubmit: (values: any, actions: any) => void;
  submitLabel: string;
  onCancel: () => void;
  cancelLabel: string;
}) {
  const { t } = useTranslation();

  const ResourceSchema = Yup.object().shape({
    resourceName: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required")
      .test("is-alpha", "Alphabets only", (value) => {
        if (!value) return true;
        return /^[a-zA-Z]+$/.test(value);
      })
      .test("is-plural", "Plural only", (value) => {
        if (!value) return true;
        return pluralize.isPlural(value);
      }),
    resourceDescription: Yup.string(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ResourceSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form
        className="flex h-full flex-col divide-y divide-gray-200"
        onSubmit={formik.handleSubmit}
      >
        <div className="h-0 flex-1">
          <div className="px-4 py-8 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-black">
                {heading}
                <span>
                  {formik.values.resourceName
                    ? formik.values.resourceName
                    : t("text_untitled")}
                </span>
              </Dialog.Title>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="appearance-none rounded-md border-primary-700 text-primary-600 transition-colors hover:text-primary focus:outline-none focus-visible:border-primary-700 focus-visible:ring-4 focus-visible:ring-primary-50"
                  onClick={onCancel}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-gray-500">
                Get started by filling in the information below to create your
                new resource.
              </p>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5">
                <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <Field
                      title={t("text_resource_name")}
                      type="text"
                      name="resourceName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.resourceName}
                      touched={formik.touched.resourceName}
                      errors={formik.errors.resourceName}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <Field
                      title={t("text_description")}
                      name="resourceDescription"
                      type="textarea"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.resourceDescription}
                      touched={formik.touched.resourceDescription}
                      errors={formik.errors.resourceDescription}
                    />
                  </div>
                </div>
                <fieldset>
                  <legend className="text-sm font-medium text-gray-900">
                    {t("text_status")}
                  </legend>
                  <Switch.Group
                    as="div"
                    className="mt-1.5 inline-flex items-center"
                  >
                    <Switch
                      checked={formik.values.status}
                      onChange={() => {
                        formik.setFieldValue("status", !formik.values.status);
                      }}
                      id="status"
                      className={classNames(
                        formik.values.status ? "bg-primary-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          formik.values.status
                            ? "translate-x-5"
                            : "translate-x-0",
                          "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <Switch.Label
                      passive
                      htmlFor="status"
                      className="mb-0 ml-2 block text-sm font-normal text-gray-700"
                    >
                      {formik.values.status
                        ? t("text_active")
                        : t("text_inactive")}
                    </Switch.Label>
                  </Switch.Group>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 px-4 py-6 sm:px-6">
          <Button variant="secondary" onClick={onCancel}>
            {cancelLabel}
          </Button>
          <Button type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? (
              <>
                <Spinner />
                {t("text_processing")}
              </>
            ) : (
              submitLabel
            )}
          </Button>
        </div>
      </form>
    </>
  );
}
