import { createContext, useReducer } from "react";

import { VehicleDetailsForm } from "../graphql/fleets/vehicles/pages/details";
import { VehicleFinancialForm } from "../graphql/fleets/vehicles/pages/financial";
import { VehicleLifecycleForm } from "../graphql/fleets/vehicles/pages/lifecycle";
import { VehicleSettingForm } from "../graphql/fleets/vehicles/pages/settings";
import { VehicleSpecForm } from "../graphql/fleets/vehicles/pages/specifications";

type VehicleForm = {
  id: string | null;
  details: VehicleDetailsForm | null;
  lifecycle: VehicleLifecycleForm | null;
  financial: VehicleFinancialForm | null;
  specifications: VehicleSpecForm | null;
  settings: VehicleSettingForm | null;
};
type PageParams = {
  title: string;
  heading: string;
  description: string;
  breadcrumbs: Breadcrumb[];
};
export const GenReducer = (
  state: PageParams & {
    sidebarOpen: boolean;
    vehicleForm: VehicleForm | undefined;
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "UPDATE_PAGE":
      const { title, heading, description, breadcrumbs } = action.payload;
      return {
        ...state,
        title,
        heading,
        description,
        breadcrumbs,
      };
    case "HANDLE_MENU":
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    case "VEHICLE_FORM":
      return {
        ...state,
        vehicleForm: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  title: "Alpha Fresh",
  heading: "Alpha Fresh",
  description: "",
  breadcrumbs: [],
  sidebarOpen: false,
  vehicleForm: {},
};

export const GenContext = createContext<any>({});

export default function GenContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(GenReducer, initialState);

  const updatePage = (page: PageParams) => {
    dispatch({ type: "UPDATE_PAGE", payload: page });
  };
  const handleMenu = (sidebarOpen: boolean) => {
    dispatch({ type: "HANDLE_MENU", payload: sidebarOpen });
  };
  const storeVehicleForm = (data: VehicleForm) => {
    dispatch({ type: "VEHICLE_FORM", payload: data });
  };
  const contextValues = {
    updatePage,
    handleMenu,
    storeVehicleForm,
    ...state,
  };
  return (
    <GenContext.Provider value={contextValues}>{children}</GenContext.Provider>
  );
}
