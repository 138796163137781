import { VendorsResource } from "./vendors";

export * from "./Layout";

export const PurchaseResource = {
  name: "Purchases",
  description: "Purchase management module",
  path: "/purchases",
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9.51 13.31"
      className="h-full w-full"
    >
      <path
        fill="currentColor"
        d="M9.51,12.11,9,4.81a1.18,1.18,0,0,0-1.21-1H7.39V2.64a2.64,2.64,0,1,0-5.27,0V3.81H1.69a1.18,1.18,0,0,0-1.21,1L0,12.11a1,1,0,0,0,.25.79,1.25,1.25,0,0,0,1,.41H8.29a1.27,1.27,0,0,0,1-.41A1,1,0,0,0,9.51,12.11ZM2.83,2.64a1.93,1.93,0,1,1,3.85,0V3.81H2.83Zm5.92,9.83a.56.56,0,0,1-.46.19H1.22a.56.56,0,0,1-.46-.19.4.4,0,0,1-.11-.32l.48-7.28a.53.53,0,0,1,.56-.41h.43v.91a.36.36,0,1,0,.71,0V4.46H6.68v.91a.36.36,0,1,0,.71,0V4.46h.43a.53.53,0,0,1,.56.39l.48,7.32A.38.38,0,0,1,8.75,12.47Z"
      />
    </svg>
  ),
  access: ["read-purchasemodules"],
  children: [VendorsResource],
};
