import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Loader } from "../../../animations";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { formatBytes, getFileNameExt } from "../../../utils";
import { uploadMedia } from "../core/MediaHelper";
import { MediaApiProps, MediaProps, RejectedProps } from "../core/types";
import Rejected from "./Rejected";

const ACCEPTED_FILE_TYPES = {
  "image/jpeg": [".jpeg"],
  "image/png": [".png"],
  "image/jpg": [".jpg"],
};
const MAX_FILE_SIZE = 5;

const EXTENSIONS = Object.values(ACCEPTED_FILE_TYPES).flat().join(", ");

export default function Uploader({
  onUpload,
}: {
  onUpload: (files: MediaProps[]) => void;
}) {
  const { addNotify } = useNotifyContext();

  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      setLoading(true);
      const uploadedFiles: MediaProps[] = [];
      for (const file of acceptedFiles) {
        try {
          const data: MediaApiProps & {
            url: string;
          } = await uploadMedia(file);
          if (!data.url) throw new Error("No url returned from server.");
          const { title, extension } = getFileNameExt(data.url);

          uploadedFiles.push({
            title: title || data.name,
            name: data.name,
            url: data.url,
            size: formatBytes(data.size || 0),
            type: data.content_type,
            date: data.created_at,
            extension: extension || "",
          });
        } catch (e: any) {
          addNotify({
            type: NotifyType.ERROR,
            title: e.message,
          });
        }
      }
      setLoading(false);
      onUpload(uploadedFiles);
    },
    [addNotify, onUpload]
  );

  const { fileRejections, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      maxSize: 1024 * 1024 * MAX_FILE_SIZE,
      accept: ACCEPTED_FILE_TYPES,
      multiple: true,
      onDrop,
    });

  return (
    <>
      <Loader loading={loading} />
      <label
        htmlFor="uploader"
        className="mb-1 block text-sm font-medium text-gray-900"
      >
        Drop files to upload
      </label>
      <div
        {...getRootProps({
          className:
            "w-full flex justify-center items-center px-6 h-42 md:h-44 bg-white border border-gray-300 border-dashed rounded-md relative overflow-hidden hover:border-gray-400 hover:bg-gray-50 cursor-pointer",
        })}
      >
        <input id="uploader" {...getInputProps()} />
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            {isDragActive ? (
              <p className="pl-1">Drop the files here ...</p>
            ) : (
              <p className="pl-1">
                Drag 'n' drop some files here, or click to{" "}
                <span className="relative cursor-pointer rounded-md font-medium text-primary-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 hover:text-primary-500">
                  select files
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
      <p className="mt-2 space-x-4 text-sm text-gray-500">
        <span>Accepted File Types: {EXTENSIONS} only.</span>
        <span>Max File Size: {MAX_FILE_SIZE}MB.</span>
      </p>

      {fileRejections && (
        <ul className="divide-y divide-gray-200 text-red-600">
          {fileRejections.map(({ file, errors }: RejectedProps) => (
            <Rejected key={file.path} file={file} errors={errors} />
          ))}
        </ul>
      )}
    </>
  );
}
