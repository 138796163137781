import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { AppearanceVariant, bottonClassess, classNames } from "../../utils";

interface ButtonDropdownProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: AppearanceVariant;
  border?: boolean;
  loading?: boolean;
  childrens: {
    label: string;
    icon?: (props: React.ComponentProps<"svg" | "span">) => JSX.Element;
    href?: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
    onClick?: () => void;
    visible?: boolean;
    className?: string;
  }[];
}
export function ButtonDropdown(props: ButtonDropdownProps) {
  const {
    children,
    className = "",
    variant = "primary",
    border,
    loading,
    childrens,
    ...rest
  } = props;

  const classes = bottonClassess(className, variant, border, loading);

  return (
    <Menu as="div" className="relative flex">
      <Menu.Button className={classes} {...rest}>
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-20 mt-12 w-44 origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:mt-14 md:w-52 md:px-2.5 md:py-3">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 110.9 96"
            enableBackground="new 0 0 110.9 96"
            className="absolute -top-3 right-0 -z-0"
            fill="#ffffff"
            style={{
              filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
            }}
          >
            <polygon points="110.9,0 0,96 110.9,96 " />
          </svg>
          <div className="py-1">
            {childrens.map((item, index) => {
              const {
                label,
                icon: Icon,
                href,
                target = "",
                className = "",
                visible = true,
                ...rest
              } = item;
              if (!visible) return null;
              const defaultClass =
                "group flex w-full items-center rounded-md px-1.5 py-1.5 text-sm text-gray-700 transition ease-in-out md:px-2 md:py-2";
              const children = (
                <div className="flex items-center">
                  {Icon ? (
                    <Icon
                      className="mr-2 h-5 w-5 text-current"
                      aria-hidden="true"
                    />
                  ) : null}
                  <span className={classNames("block truncate")}>{label}</span>
                </div>
              );
              if (href) {
                return (
                  <Menu.Item key={`button-dropdown-${index}`}>
                    {({ active }) => (
                      <a
                        href={href}
                        className={classNames(
                          defaultClass,
                          className,
                          active ? "bg-primary-700/10 text-primary-700" : ""
                        )}
                        target={target}
                        {...rest}
                      >
                        {children}
                      </a>
                    )}
                  </Menu.Item>
                );
              }
              return (
                <Menu.Button
                  key={`button-dropdown-${index}`}
                  className={classNames(
                    defaultClass,
                    "hover:bg-primary-700/10 hover:text-primary-700",
                    className
                  )}
                  {...rest}
                >
                  {children}
                </Menu.Button>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
