import { gql } from "@apollo/client/core";

import { UserModel } from "../../../modules/auth/@types/auth";
import { type Variant } from "../../inventory/products";
import { BASIC_CUSTOMER_FRAGMENT } from "../../sales/customers";
import { Order, OrderStatus, ProductApprove } from "../../sales/orders";
import { BASIC_USER_FRAGMENT } from "../../user-management/users";

export const PACKAGE_MESSAGE_FRAGMENT = gql`
  ${BASIC_USER_FRAGMENT}
  fragment PackageMessageFragment on PackageMessage {
    id
    comments
    messageType
    order {
      id
      orderNumber
      purchaseNumber
    }
    orderId
    user {
      ...BasicUserFragment
    }
    createdAt
  }
`;

const PACKING_PRODUCT_FRAGMENT = gql`
  ${BASIC_CUSTOMER_FRAGMENT}
  ${PACKAGE_MESSAGE_FRAGMENT}
  fragment PackingProductFragment on Packing {
    id
    boxCount
    comments {
      ...PackageMessageFragment
    }
    customer {
      ...BasicCustomerFragment
    }
    customerId
    deliveryDate
    deliveryRun
    items {
      id
      price
      quantity
      productApprove
      orderComments
      packingComments
      variant {
        ...VariantFragment
      }
    }
    loadingDock
    packingNo
    purchaseNumber
    packingDate
    packedBy {
      id
      firstName
      lastName
    }
    orderNumber
    notes
    salesRep
    status
    packingVerificationStatus
    createdAt
  }
`;
const TIMELINE_FRAGMENT = gql`
  fragment TimelineFragment on PackageTimeline {
    id
    info
    order {
      id
      orderNumber
    }
    createdAt
    updatedAt
  }
`;
export const GET_PACKING_PRODUCTS = gql`
  ${PACKING_PRODUCT_FRAGMENT}
  query FetchPackingProducts(
    $first: Int
    $cursor: String
    $searchQuery: String!
    $searchFilter: [String!]
    $customerId: ID
    $deliveryrunId: ID
    $deliveryDate: String
    $departmentId: ID
    $status: Int
  ) {
    fetchPackingProducts(
      first: $first
      after: $cursor
      searchQuery: $searchQuery
      searchFilter: $searchFilter
      customerId: $customerId
      deliveryrunId: $deliveryrunId
      deliveryDate: $deliveryDate
      departmentId: $departmentId
      status: $status
    ) {
      edges {
        node {
          ...PackingProductFragment
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
export const GET_PACKING_BY_ID = gql`
  ${PACKING_PRODUCT_FRAGMENT}
  query FetchPackingProduct($id: ID!) {
    fetchPackingProduct(id: $id) {
      ...PackingProductFragment
    }
  }
`;

export const GET_PACKING_COMMENTS_BY_ID = gql`
  ${PACKAGE_MESSAGE_FRAGMENT}
  query FetchPackingProductComments($id: ID!) {
    fetchPackingProduct(id: $id) {
      id
      comments {
        ...PackageMessageFragment
      }
    }
  }
`;

export const GET_PACKING_TIMELINE_BY_ID = gql`
  ${TIMELINE_FRAGMENT}
  query FetchPackingProductTimeline($id: ID!) {
    fetchPackingProduct(id: $id) {
      id
      timelines {
        ...TimelineFragment
      }
    }
  }
`;

export const MODIFIED_PACKAGE = gql`
  ${BASIC_USER_FRAGMENT}
  subscription PackageModified($packageModifiedId: ID!) {
    packageModified(id: $packageModifiedId) {
      id
      comments
      orderId
      userId
      user {
        ...BasicUserFragment
      }
      createdAt
    }
  }
`;

export const ADD_PACKING_COMMENT = gql`
  ${PACKAGE_MESSAGE_FRAGMENT}
  mutation PackingCommentCreate($message: String!, $orderId: Int!) {
    packingCommentCreate(
      input: { params: { message: $message, orderId: $orderId } }
    ) {
      packingComment {
        ...PackageMessageFragment
      }
    }
  }
`;

export const ADD_PACKING_PRODUCT = gql`
  mutation PackingProductCreate(
    $orderId: ID!
    $productId: Int!
    $oldProductId: Int
    $price: Float!
    $quantity: Int!
  ) {
    packingProductCreate(
      input: {
        orderId: $orderId
        params: {
          productId: $productId
          oldProductId: $oldProductId
          price: $price
          quantity: $quantity
        }
      }
    ) {
      message
    }
  }
`;

export const APPROVE_PACKING_PRODUCT = gql`
  mutation ApprovePackingProduct($orderId: ID!, $productIds: [ID!]!) {
    approvePackingProduct(
      input: { orderId: $orderId, productIds: $productIds }
    ) {
      message
    }
  }
`;

export const REJECT_PACKING_PRODUCT = gql`
  mutation RejectPackingProduct($orderId: ID!, $productIds: [ID!]!) {
    rejectPackingProduct(
      input: { orderId: $orderId, productIds: $productIds }
    ) {
      message
    }
  }
`;

export const UPDATE_PACKING = gql`
  ${PACKING_PRODUCT_FRAGMENT}
  mutation PackingUpdate(
    $id: ID!
    $boxCount: Int
    $packedUserId: Int
    $packingDate: ISO8601DateTime
    $products: [OrderproductInput!]
    $subTotal: Float
    $total: Float
    $status: Int
  ) {
    packingUpdate(
      input: {
        id: $id
        params: {
          boxCount: $boxCount
          packedUserId: $packedUserId
          packingDate: $packingDate
          products: $products
          subTotal: $subTotal
          total: $total
          status: $status
        }
      }
    ) {
      packing {
        ...PackingProductFragment
      }
    }
  }
`;

export const UPDATE_PACKING_STATUS = gql`
  mutation PackingStatusUpdate($id: ID!, $status: Int) {
    packingStatusUpdate(input: { id: $id, params: { status: $status } }) {
      packing {
        id
      }
    }
  }
`;

export const UPDATE_PACKING_VERIFICATION = gql`
  mutation PackingVerificationUpdate(
    $id: ID!
    $packingVerificationStatus: Int
  ) {
    packingVerificationUpdate(
      input: {
        id: $id
        params: { packingVerificationStatus: $packingVerificationStatus }
      }
    ) {
      packing {
        id
        packingVerificationStatus
      }
    }
  }
`;

export type Timeline = {
  id: string;
  info: string;
  order: Order;
  createdAt: string;
  updatedAt: string;
};
export enum CommentType {
  READY,
  NORMAL,
  MODIFIED,
  COMPLETED,
}
export type Comment = {
  id: string;
  orderId: number;
  userId: number;
  user: Pick<UserModel, "id" | "firstName" | "lastName">;
  comments: string | null;
  messageType: CommentType;
  createdAt: string;
};
export type Item = {
  id: string;
  oldProductId?: number | null;
  price: number;
  quantity: number;
  productApprove: ProductApprove;
  orderComments: string;
  packingComments: string;
  variant: Variant;
};
export type Packing = {
  id: string;
  boxCount: number | null;
  comments: Comment[];
  customerId: string;
  customer: {
    id: string;
    customerName: string;
    customerPlu: string;
  };
  deliveryDate: string;
  deliveryRun: string;
  items: Item[];
  loadingDock: string;
  packingNo: string;
  purchaseNumber: string;
  packingDate: string;
  packedBy: UserModel;
  orderNumber: string;
  notes: string;
  salesRep: string;
  timelines: Timeline[];
  status: OrderStatus;
  packingVerificationStatus: PackingVerificationStatus;
  createdAt: string;
};

export enum PackingVerificationStatus {
  PENDING,
  VERIFIED,
  REVISION,
}

export const renderVerificationStatus = (
  status?: PackingVerificationStatus
) => {
  switch (status) {
    case PackingVerificationStatus.PENDING:
      return (
        <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
          Pending
        </span>
      );
    case PackingVerificationStatus.VERIFIED:
      return (
        <span className="inline-flex rounded-full bg-green-50 px-2 text-xs font-medium leading-5 text-green-700">
          Verified
        </span>
      );
    case PackingVerificationStatus.REVISION:
      return (
        <span className="inline-flex rounded-full bg-violet-100 px-2 text-xs font-medium leading-5 text-violet-800">
          Revision
        </span>
      );
    default:
      return null;
  }
};
