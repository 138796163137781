import { useMutation, useQuery } from "@apollo/client/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useCallback, useMemo, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../../animations";
import { ErrorFallback, Head } from "../../../../components/core";
import {
  Button,
  FieldPriceCategoryCalculator,
} from "../../../../components/form";
import {
  ADD_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID,
  EDIT_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID,
  GET_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID,
  type PricingStructure,
  type ProductPricing,
  ProductPricingStatus,
} from "../../../../graphql/inventory/pricing/pricing-categories/pricing";
import { classNames } from "../../../../utils";

const PricingStructurePricing = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { pricingCategoryId, pricingStructureId } = useParams();
  const [refetching, setRefetching] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { data, loading, error, refetch } = useQuery<{
    fetchPricingStructure: PricingStructure;
  }>(GET_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID, {
    variables: {
      id: pricingStructureId,
      pricingCategoryId: Number(pricingCategoryId),
      structureId: Number(pricingStructureId),
    },
  });

  const pricingStructure = useMemo<PricingStructure | undefined>(() => {
    if (data?.fetchPricingStructure) {
      const fetchedPricingStructure: PricingStructure =
        data.fetchPricingStructure;
      if (globalFilter === "") return fetchedPricingStructure;
      const updatedPricingStructure = {
        ...fetchedPricingStructure,
        products: fetchedPricingStructure.products.filter((product) => {
          const isMatchName = product.name
            .toLowerCase()
            .includes(globalFilter.toLowerCase());
          const isMatchSku = product.variants.some((variant) =>
            variant.stockCode.toLowerCase().includes(globalFilter.toLowerCase())
          );
          return isMatchName || isMatchSku;
        }),
      };
      return updatedPricingStructure;
    }
  }, [data, globalFilter]);

  const [productPricing, setProductPricing] = useState<ProductPricing[]>([]);

  const [createProductPricing, { loading: loadingCreate }] = useMutation(
    ADD_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID,
    {
      refetchQueries: [
        {
          query: GET_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID,
          variables: {
            id: pricingStructureId,
            pricingCategoryId: Number(pricingCategoryId),
            structureId: Number(pricingStructureId),
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );
  const [updateProductPricing, { loading: loadingUpdate }] = useMutation(
    EDIT_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID,
    {
      refetchQueries: [
        {
          query: GET_PRICINGSTRUCTURE_BY_ID_CATEGORY_ID,
          variables: {
            id: pricingStructureId,
            pricingCategoryId: Number(pricingCategoryId),
            structureId: Number(pricingStructureId),
          },
        },
      ],
      awaitRefetchQueries: true,
    }
  );

  const handleSave = useCallback(async () => {
    if (!pricingCategoryId) return;

    const newProductPricing = [...productPricing];

    for (let i = 0; i < productPricing.length; i++) {
      const product = productPricing[i];

      const index = newProductPricing.findIndex(
        (item) => item.id === product.id
      );

      if (index !== -1) {
        newProductPricing[index].status = ProductPricingStatus.loading;
      }

      const {
        pricingId,
        pricingStructureId,
        productId,
        productSkuId,
        priceFields,
        sellPrice,
      } = product;

      if (pricingId) {
        await updateProductPricing({
          variables: {
            id: pricingId,
            pricingCategoryId: parseInt(pricingCategoryId),
            pricingStructureId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructurePricingUpdate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      } else {
        await createProductPricing({
          variables: {
            pricingCategoryId: parseInt(pricingCategoryId),
            pricingStructureId,
            productId,
            productSkuId,
            priceFields,
            sellPrice,
          },
        })
          .then(({ data }) => {
            if (data?.pricingStructurePricingCreate) {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.success;
              }
            } else {
              if (index !== -1) {
                newProductPricing[index].status = ProductPricingStatus.error;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            if (index !== -1) {
              newProductPricing[index].status = ProductPricingStatus.error;
            }
          });
      }
    }
    setProductPricing(newProductPricing);
    refetch();
  }, [
    createProductPricing,
    pricingCategoryId,
    productPricing,
    refetch,
    updateProductPricing,
  ]);

  const handleCancel = () => {
    return navigate(
      `/inventory/pricing/pricing-categories/${pricingCategoryId}`
    );
  };

  const handleRefetch = useCallback(() => {
    window.location.reload();
  }, []);

  const isOutdated = useMemo<boolean>(() => {
    return (
      pricingStructure?.products.some((sp) =>
        sp.variants.some((v) =>
          v.structureDetails?.some((spd) => spd.costUpdated)
        )
      ) ?? false
    );
  }, [pricingStructure]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Manage Pricing"
        heading="Manage Pricing"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Manage Pricing",
            href: "/inventory/pricing",
          },
          {
            name: "Pricing Categories",
            href: "/inventory/pricing/pricing-categories",
          },
          {
            name: "Pricing Category",
            href: `/inventory/pricing/pricing-categories/${pricingCategoryId}`,
          },
          {
            name: PricingStructurePricingResource.name,
            href: null,
          },
        ]}
      />

      <div className="rounded-xl bg-greyish p-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {PricingStructurePricingResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {PricingStructurePricingResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <div className="flex w-80 max-w-full items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50 xl:flex-1">
              <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
              <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
              <DebounceInput
                type="search"
                className={classNames(
                  "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                  "shadow-none outline-none focus:ring-0",
                  "placeholder-black/50 placeholder-opacity-100"
                )}
                placeholder="Search product name or stock code"
                minLength={2}
                debounceTimeout={300}
                value={globalFilter}
                onChange={(e) => {
                  setGlobalFilter(e.target.value);
                }}
              />
            </div>

            <Button variant="secondary" onClick={handleCancel}>
              {t("text_back")}
            </Button>

            {productPricing.filter(
              (pp) =>
                pp.status === ProductPricingStatus.pending ||
                pp.status === ProductPricingStatus.error
            ).length ? (
              <Button
                onClick={handleSave}
                loading={loadingCreate || loadingUpdate}
              >
                {t("text_update")}
              </Button>
            ) : null}

            <Button
              variant="icon"
              onClick={handleRefetch}
              disabled={loading}
              className="flex h-9 items-center justify-center px-2 text-blue-700"
            >
              <ArrowPathIcon
                aria-hidden="true"
                className={classNames(
                  "h-5 w-5",
                  refetching ? "animate-spin" : ""
                )}
              />
              <span className="sr-only">Refresh pricing categories</span>
            </Button>
          </div>
        </div>

        {isOutdated && (
          <div className="mt-4 rounded-md border border-red-100 bg-red-50 p-4 md:mt-6">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Warning: Outdated cost price
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>
                    The sell price of some products are outdated. Please click
                    the update button to save the new sell prices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading || !pricingStructure ? (
          <Waiting />
        ) : (
          <div className="mt-4 space-y-2">
            {pricingStructure?.products.map((product) => (
              <FieldPriceCategoryCalculator
                key={product.id}
                product={product}
                pricingMethod={pricingStructure.pricingMethod}
                productPricing={productPricing}
                setProductPricing={setProductPricing}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PricingStructurePricing;
export const PricingStructurePricingResource: ResourceProps = {
  name: "Manage Pricing",
  description: "Manage pricing list for pricing category",
  access: ["read-pricings"],
  path: "pricing-categories/:pricingCategoryId/structure/:pricingStructureId/pricing",
};
