import { useMutation } from "@apollo/client/react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Head } from "../../../../../components/core";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../../contexts/NotifyContext";
import { VehicleBasic } from "../../../../../graphql/fleets/vehicles";
import {
  ADD_EXPENSEENTRY,
  GET_EXPENSEENTRY_BY_VEHICLE_ID,
  VehicleExpenseEntryForm,
} from "../../../../../graphql/fleets/vehicles/pages/expense-entry";
import Form from "../../components/ExpenseEntryForm";

const ExpenseEntryCreate = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const { t } = useTranslation();
  const { addNotify } = useNotifyContext();
  const navigate = useNavigate();

  const [createVehicleExpenseEntry] = useMutation(ADD_EXPENSEENTRY, {
    refetchQueries: [
      { query: GET_EXPENSEENTRY_BY_VEHICLE_ID, variables: { vehicleId } },
    ],
  });
  const handleCreate = (
    values: VehicleExpenseEntryForm,
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!vehicleId) return;
    createVehicleExpenseEntry({
      variables: {
        vehicleId: parseInt(vehicleId),
        vehicleExpenseTypeId: values.vehicleExpenseTypeId,
        fleetVendorId: values.fleetVendorId,
        amount: values.amount,
        expenseDate: values.expenseDate,
        notes: values.notes,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleExpenseEntryCreate) {
          return navigate(
            `/fleets/vehicles/${vehicleId}/expense-entry/${data.vehicleExpenseEntryCreate.vehicleExpenseEntry.id}`
          );
        } else {
          toast.error("Something went wrong, please try again later");
        }
        actions.setSubmitting(false);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Expense entry create failed",
          message: error.message,
        });
      });
  };

  return (
    <>
      <Head
        title={ExpenseEntryCreateResource.name}
        heading={ExpenseEntryCreateResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: ExpenseEntryCreateResource.name,
            href: null,
          },
        ]}
      />
      <div className="mx-auto max-w-4xl">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_vehicle_expense_entry_create")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_vehicle_expense_entry_create")}
              </p>
            </div>
          </div>

          <Form
            initialValues={{
              vehicleId: Number(vehicleId),
              vehicleExpenseTypeId: null,
              fleetVendorId: null,
              amount: null,
              expenseDate: null,
              notes: "",
            }}
            onSubmit={handleCreate}
            submitLabel={t("text_create")}
            onCancel={() => {
              navigate(`/fleets/vehicles/${vehicleId}/expense-entry`);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ExpenseEntryCreate;
export const ExpenseEntryCreateResource: ResourceProps = {
  name: "Expense Entry",
  description: "Update an existing vehicle expense entries",
  access: ["update-vehicles"],
  path: "/expense-entry/add",
  icon: "bi bi-person",
};
