import "react-horizontal-scrolling-menu/dist/styles.css";

import { useMutation, useQuery } from "@apollo/client/react";
import { Tab } from "@headlessui/react";
import {
  ArrowPathIcon,
  BanknotesIcon,
  BellIcon,
  ClockIcon,
  ExclamationTriangleIcon,
  FireIcon,
  PencilIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CogIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Waiting } from "../../../animations";
import placeholder from "../../../assets/placeholder.svg";
import {
  ButtonDropdown,
  ButtonPopover,
  PopoverPosition,
} from "../../../components/appearance";
import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import {
  CREATE_VEHICLE_WATCHER,
  DELETE_VEHICLE_WATCHER,
  GET_VEHICLE_BY_ID,
  Vehicle,
  VehicleBasic,
} from "../../../graphql/fleets/vehicles";
import { renderPrimaryMeter } from "../../../graphql/fleets/vehicles/pages/settings";
import { classNames, renderTabClass } from "../../../utils";
import { useAuth } from "../../auth";
import { LeftArrow, RightArrow } from "./components/HorizontalScrollArrow";
import { VehicleWatchers } from "./components/VehicleWatchers";
import {
  ExpenseEntry,
  Financial,
  FuelEntry,
  Issues,
  MeterEntry,
  Overview,
  ServiceEntries,
  ServiceReminders,
  Specifications,
} from "./widgets";

const VehicleView = ({
  breadcrumbs,
  vehicleBasic,
}: {
  breadcrumbs: Breadcrumb[];
  vehicleBasic: VehicleBasic | null;
}) => {
  let { vehicleId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [refetching, setRefetching] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    const isInteger = vehicleId && !isNaN(+vehicleId);
    if (!isInteger) {
      navigate(-1);
    }
  }, [vehicleId, navigate]);

  const { data, loading, error, refetch } = useQuery(GET_VEHICLE_BY_ID, {
    variables: {
      id: vehicleId,
    },
  });
  const vehicle = useMemo<Vehicle>(() => data?.fetchVehicle, [data]);

  const isWatching = useMemo<boolean>(() => {
    if (!currentUser || !vehicle) return false;
    return vehicle.watchers.some((watcher) => watcher.id === currentUser.id);
  }, [currentUser, vehicle]);

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  const [createWatcher, { loading: loadingCreateWatcher }] = useMutation(
    CREATE_VEHICLE_WATCHER,
    {
      refetchQueries: [
        {
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: vehicleId,
          },
        },
      ],
    }
  );
  const [deleteWatcher, { loading: loadingDeleteWatcher }] = useMutation(
    DELETE_VEHICLE_WATCHER,
    {
      refetchQueries: [
        {
          query: GET_VEHICLE_BY_ID,
          variables: {
            id: vehicleId,
          },
        },
      ],
    }
  );

  const handleWatch = useCallback(() => {
    if (!currentUser || !vehicle) return;
    const currentWatcher = vehicle.watchers.find(
      (watcher) => watcher.id === currentUser.id
    );
    if (currentWatcher) {
      deleteWatcher({
        variables: {
          vehicleId: vehicleId,
          watcherId: currentUser.id,
        },
      })
        .then(async ({ data }) => {
          if (data?.vehicleWatcherDelete) {
            handleRefetch();
            toast.success("You are unwatching this vehicle");
          } else {
            toast.error("Something went wrong. Please try again later");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
      return;
    }

    createWatcher({
      variables: {
        vehicleId: vehicleId,
        watcherId: currentUser.id,
      },
    })
      .then(async ({ data }) => {
        if (data?.vehicleWatcherCreate) {
          handleRefetch();
          toast.success("You are now watching this vehicle");
        } else {
          toast.error("Something went wrong. Please try again later");
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [
    createWatcher,
    currentUser,
    deleteWatcher,
    handleRefetch,
    vehicle,
    vehicleId,
  ]);

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={VehicleViewResource.name}
        heading={VehicleViewResource.name}
        breadcrumbs={breadcrumbs}
      />
      <div className="mx-auto px-5 py-6 sm:py-8">
        <div className="rounded-xl bg-greyish p-5">
          <div className="mb-6 sm:flex sm:items-center md:mb-8">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-medium text-gray-900">
                {t("heading_view_vehicle")}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                {t("description_view_vehicle")}
              </p>
            </div>
          </div>

          {loading || !vehicle ? (
            <Waiting />
          ) : (
            <Fragment>
              <header className="flex items-end rounded-xl bg-white p-4 md:p-5 xl:p-6">
                <div className="flex flex-1 items-center">
                  <figure className="mr-3 h-20 w-20 flex-none overflow-hidden rounded-md ring-1 ring-gray-900/10">
                    <img
                      src={
                        vehicle.vehicleImageUrl
                          ? vehicle.vehicleImageUrl
                          : placeholder
                      }
                      alt={vehicle.name}
                      className="h-full w-full object-cover"
                    />
                  </figure>
                  <div>
                    <h1 className="text-xl font-semibold text-gray-900">
                      {vehicle.name}
                    </h1>
                    <div className="flex flex-wrap items-center text-base font-light text-gray-500">
                      {vehicle.make?.name ? (
                        <Fragment>
                          <span className="block">{vehicle.make.name}</span>
                          <span className="m-2 inline-block h-1 w-1 rounded-full bg-gray-400" />
                        </Fragment>
                      ) : null}
                      {vehicle.model?.name ? (
                        <Fragment>
                          <span className="block">{vehicle.model.name}</span>
                          <span className="m-2 inline-block h-1 w-1 rounded-full bg-gray-400" />
                        </Fragment>
                      ) : null}
                      {vehicle.year ? (
                        <Fragment>
                          <span className="block">{vehicle.year}</span>
                          <span className="m-2 inline-block h-1 w-1 rounded-full bg-gray-400" />
                        </Fragment>
                      ) : null}
                      {vehicle.licensePlate ? (
                        <span className="block">{vehicle.licensePlate}</span>
                      ) : null}
                    </div>
                    <div className="flex flex-wrap items-center text-base font-light text-gray-900">
                      {vehicle.financial.length ? (
                        <span className="mr-3 mt-2 block">
                          {vehicle.financial[0].odometer}{" "}
                          {renderPrimaryMeter(vehicleBasic?.primaryMeter)}
                        </span>
                      ) : null}
                      <span className="mr-3 mt-2 flex items-center">
                        <span className="flex items-center whitespace-nowrap">
                          <span
                            className={classNames(
                              "mr-2 inline-block h-3 w-3 rounded-full align-middle"
                            )}
                            style={{
                              backgroundColor: vehicle.status.color,
                            }}
                          />
                          {vehicle.status.name}
                        </span>
                      </span>
                      {vehicle.vehicleType ? (
                        <span className="mr-3 mt-2 block">
                          {vehicle.vehicleType.name}
                        </span>
                      ) : null}
                      <span className="mt-2 block">
                        {vehicle.operator ? (
                          <ButtonPopover
                            title={vehicle.operator.name}
                            child={vehicle.operator.name}
                            position={PopoverPosition.RIGHT}
                          >
                            <div className="px-2.5 py-2 md:px-4 md:py-3 xl:px-5 xl:pb-3 xl:pt-5">
                              <h3 className="mb-1 font-medium">
                                {vehicle.operator.name}
                              </h3>
                              <p>#{vehicle.operator.driverCode}</p>
                            </div>
                            <div className="rounded-bl-2xl rounded-br-2xl border-t border-gray-300 bg-greyish px-1.5 py-2 md:px-2.5 md:py-3">
                              <Link
                                to={`/fleets/drivers/${vehicle.operator.id}`}
                                target="_blank"
                                className="block text-center text-sm text-gray-500"
                              >
                                View Operator
                              </Link>
                            </div>
                          </ButtonPopover>
                        ) : (
                          <span>Unassigned</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <VehicleWatchers />
                  <Button
                    variant="secondary"
                    border
                    onClick={handleWatch}
                    loading={loadingCreateWatcher || loadingDeleteWatcher}
                  >
                    <span className={classNames("relative mr-2")}>
                      <BellIcon className="h-5 w-5" />
                      <span
                        className={classNames(
                          "absolute left-1/2 top-1/2 h-[3px] w-6 -translate-x-1/2 -translate-y-1/2 rotate-45 transform rounded-full border border-white bg-gray-800",
                          isWatching ? "block" : "hidden"
                        )}
                      />
                    </span>
                    <span>{isWatching ? "Unwatch" : "Watch"}</span>
                  </Button>
                  <Button
                    type="link"
                    href={`/fleets/vehicles/${vehicle.id}/details`}
                    variant="tertiary"
                    border
                  >
                    <PencilIcon className="mr-2 h-4 w-4" />
                    Edit
                  </Button>

                  <ButtonDropdown
                    childrens={[
                      {
                        label: "Fuel Entry",
                        href: `/fleets/vehicles/${vehicleId}/fuel-entry/add`,
                        icon: () => (
                          <FireIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Expense Entry",
                        href: `/fleets/vehicles/${vehicleId}/expense-entry/add`,
                        icon: () => (
                          <BanknotesIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Service Entry",
                        href: `/fleets/vehicles/${vehicleId}/service-entry/add`,
                        icon: () => (
                          <ExclamationTriangleIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Service Reminder",
                        href: `/fleets/vehicles/${vehicleId}/service-reminder/add`,
                        icon: () => (
                          <PresentationChartBarIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Issue",
                        href: `/fleets/vehicles/${vehicleId}/issue/add`,
                        icon: () => (
                          <CogIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                      {
                        label: "Meter Entry",
                        href: `/fleets/vehicles/${vehicleId}/meter-entry/add`,
                        icon: () => (
                          <ClockIcon
                            className="mr-2 h-5 w-5 text-current"
                            aria-hidden="true"
                          />
                        ),
                      },
                    ]}
                  >
                    <PlusCircleIcon className="mr-2 h-5 w-5" />
                    {t("text_add")}
                    <ChevronDownIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </ButtonDropdown>
                  <Button
                    variant="icon"
                    onClick={handleRefetch}
                    disabled={loading}
                    className="flex h-9 items-center justify-center px-2 text-blue-700"
                  >
                    <ArrowPathIcon
                      aria-hidden="true"
                      className={classNames(
                        "h-5 w-5",
                        refetching ? "animate-spin" : ""
                      )}
                    />
                    <span className="sr-only">Refresh vehcile overview</span>
                  </Button>
                </div>
              </header>
              <div className="mt-4 rounded-xl bg-white py-5 sm:py-6">
                <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
                  <Tab.List className="border-b border-solid border-gray-200 px-2">
                    <ScrollMenu
                      LeftArrow={
                        <LeftArrow>
                          <ChevronLeftIcon className="h-5 w-5 text-gray-700" />
                        </LeftArrow>
                      }
                      RightArrow={
                        <RightArrow>
                          <ChevronRightIcon className="h-5 w-5 text-gray-700" />
                        </RightArrow>
                      }
                      options={{
                        ratio: 0.9,
                        rootMargin: "5px",
                        threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
                      }}
                    >
                      <Tab className={renderTabClass}>Overview</Tab>
                      <Tab className={renderTabClass}>Specifications</Tab>
                      <Tab className={renderTabClass}>Financial</Tab>
                      <Tab className={renderTabClass}>Service History</Tab>
                      <Tab className={renderTabClass}>Service Reminders</Tab>
                      <Tab className={renderTabClass}>Issues</Tab>
                      <Tab className={renderTabClass}>Meter History</Tab>
                      <Tab className={renderTabClass}>Fuel History</Tab>
                      <Tab className={renderTabClass}>Expense History</Tab>
                    </ScrollMenu>
                  </Tab.List>
                  <Tab.Panels as={Fragment}>
                    <Tab.Panel className="px-4 py-6 text-sm font-light">
                      <Overview vehicle={vehicle} setActiveTab={setActiveTab} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm font-light">
                      <Specifications vehicleId={vehicleId} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm font-light">
                      <Financial vehicleId={vehicleId} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm">
                      <ServiceEntries vehicleId={vehicleId} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm">
                      <ServiceReminders vehicleId={vehicleId} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm">
                      <Issues vehicleId={vehicleId} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm">
                      <MeterEntry vehicleId={vehicleId} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm">
                      <FuelEntry vehicleId={vehicleId} />
                    </Tab.Panel>
                    <Tab.Panel className="px-4 py-6 text-sm">
                      <ExpenseEntry vehicleId={vehicleId} />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleView;
export const VehicleViewResource: ResourceProps = {
  name: "Vehicle Details",
  description: "View an existing vehicle",
  access: ["update-vehicles"],
  path: "/",
};
