import { gql, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowsUpDownIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Spinner } from "../../animations";
import placeholder from "../../assets/placeholder.svg";
import { PreviewCustomer } from "../../graphql/sales/customers";
import { classNames } from "../../utils";
import { ErrorFallback } from "../core";
import { Button } from "../form";
import { IndeterminateCheckbox, TableStaticInfinite } from "../table";

const FETCH_CUSTOMERS = gql`
  query FetchCustomers {
    fetchCustomers {
      id
      customerName
      profileImageUrl
      status
    }
  }
`;

interface RowCustomer extends PreviewCustomer {
  index: number;
}

export function FieldCustomers({
  title,
  data: previews,
  value = [],
  onChange,
  excludeIds = [],
  excludeNote = "",
}: {
  title: string;
  data: PreviewCustomer[];
  value: number[];
  onChange: (newValue: PreviewCustomer[]) => void;
  excludeIds?: number[];
  excludeNote?: string;
}) {
  const [query, setQuery] = useState<string>("");
  const [pageLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [rowSelection, setRowSelection] = useState({});

  const { data, loading, error } = useQuery<{
    fetchCustomers: RowCustomer[];
  }>(FETCH_CUSTOMERS);

  const customers = useMemo<RowCustomer[]>(() => {
    const updatedCustomers: RowCustomer[] = data?.fetchCustomers || [];

    if (value.length > 0) {
      const updatedRowSelection: Record<string, boolean> = {};
      updatedCustomers.forEach((p, i) => {
        updatedRowSelection[String(i)] = value.includes(Number(p.id));
      });
      setRowSelection(updatedRowSelection);
    } else {
      setRowSelection({});
    }
    return updatedCustomers;
  }, [data?.fetchCustomers, value]);

  const columns = useMemo<ColumnDef<RowCustomer, any>[]>(
    () => [
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect() || row.depth > 0,
                  indeterminate: row.getIsSomeSelected(),
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = e.target;
                    row.getToggleSelectedHandler()(e);
                    if (checked) {
                      onChange([...previews, row.original]);
                      return;
                    }
                    onChange(previews.filter((p) => p.id !== row.original.id));
                  },
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "customerName",
        header: "Name",
        size: 100,
        enableHiding: false,
        cell: (props) => (
          <Link
            to={`/inventory/products/${props.row.original.id}`}
            target="_blank"
            className="flex items-center whitespace-nowrap"
          >
            <div className="relative z-10 mr-2 h-12 w-12 overflow-hidden rounded-full">
              <img
                className="h-full w-full object-cover"
                src={
                  props.row.original.profileImageUrl
                    ? props.row.original.profileImageUrl
                    : placeholder
                }
                alt={props.row.original.customerName}
              />
            </div>
            <span className="mr-2">{props.row.original.customerName}</span>
            {excludeNote ? (
              excludeIds.includes(Number(props.row.original.id)) ? (
                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                  {excludeNote}
                </span>
              ) : null
            ) : (
              <Fragment>
                {props.getValue() === 0 ? (
                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                    Inactive
                  </span>
                ) : props.getValue() === 1 ? (
                  <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
                    Draft
                  </span>
                ) : null}
              </Fragment>
            )}
          </Link>
        ),
      },
    ],
    [excludeIds, excludeNote, onChange, previews]
  );

  const previewColumns = useMemo<ColumnDef<PreviewCustomer>[]>(
    () => [
      {
        id: "index",
        header: "No.",
        size: 30,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.row.index + 1}.</span>
        ),
      },
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "customerName",
        header: "Name",
        size: 100,
        enableHiding: false,
        cell: (props) => (
          <Link
            to={`/inventory/products/${props.row.original.id}`}
            target="_blank"
            className="flex items-center whitespace-nowrap"
          >
            <div className="relative z-10 mr-2 h-12 w-12 overflow-hidden rounded-full">
              <img
                className="h-full w-full object-cover"
                src={
                  props.row.original.profileImageUrl
                    ? props.row.original.profileImageUrl
                    : placeholder
                }
                alt={props.row.original.customerName}
              />
            </div>
            <span className="mr-2">{props.row.original.customerName}</span>
            <Fragment>
              {props.getValue() === 0 ? (
                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
                  Inactive
                </span>
              ) : props.getValue() === 1 ? (
                <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-medium leading-5 text-yellow-800">
                  Draft
                </span>
              ) : null}
            </Fragment>
          </Link>
        ),
      },
      {
        id: "remove",
        header: "Remove",
        cell: (props) => (
          <div>
            <Button
              variant="text"
              onClick={() => {
                const newPreviewProducts = previews.filter(
                  (p) => p.id !== props.row.original.id
                );
                onChange(newPreviewProducts);
              }}
            >
              <span className="sr-only">
                Remove {props.row.original.customerName}
              </span>
              <span
                aria-hidden="true"
                className="bi bi-trash3 h-8 w-8 text-lg text-gray-500"
              />
            </Button>
          </div>
        ),
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
      },
    ],
    [onChange, previews]
  );

  const filteredSelectedProducts = useMemo(
    () =>
      query === ""
        ? previews
        : previews.filter((p) =>
            p.customerName?.toLowerCase().includes(query?.toLowerCase())
          ),
    [previews, query]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <label
        htmlFor="description"
        className="mb-1 block text-sm font-medium text-gray-900"
      >
        {title}
      </label>
      <header className="grid grid-cols-1 gap-4 sm:grid-cols-4">
        <div className="relative col-span-3">
          <label htmlFor="search" className="sr-only">
            Search customers
          </label>
          <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="search"
            name="search"
            id="search"
            className={classNames(
              "relative block w-full appearance-none rounded-md border border-gray-300 py-2.5 pl-10 pr-3 focus:outline-none focus-visible:border-primary-500 focus-visible:ring-4 focus-visible:ring-primary-50 sm:text-sm",
              "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500",
              "read-only:cursor-not-allowed read-only:border-primary-200 read-only:bg-primary-50 read-only:text-primary-500"
            )}
            placeholder="Search customers"
            value={query || ""}
            onChange={(e) => {
              previews.length > 0
                ? setQuery(e.target.value)
                : setSearchQuery(e.target.value);
            }}
          />
        </div>
        <div className="col-span-1 flex">
          <Button
            variant="secondary"
            className="w-full justify-center"
            onClick={() => {
              setSearchQuery("");
            }}
          >
            Browse
          </Button>
        </div>
      </header>
      <div className="mt-2">
        {loading ? (
          <div className="flex justify-center py-6 md:py-12">
            <Spinner className="h-8 w-8 text-primary-700" />
          </div>
        ) : previews.length > 0 ? (
          <TableStaticInfinite
            data={filteredSelectedProducts}
            columns={previewColumns}
            loading={loading}
            filtering={query.length > 0}
            totalRows={previews.length}
            pageLimit={pageLimit}
            getCoreRowModel={getCoreRowModel()}
            className=""
          />
        ) : (
          <div className="py-6 text-center text-sm md:py-12">
            <ExclamationCircleIcon
              type="outline"
              name="exclamation-circle"
              className="mx-auto h-6 w-6 text-gray-400"
            />
            <p className="mt-4 font-medium text-gray-900">
              No customers selected
            </p>
            <p className="mt-2 text-gray-500">
              Please{" "}
              <Button
                variant="text"
                onClick={() => {
                  setSearchQuery("");
                }}
              >
                browse
              </Button>{" "}
              customers to add customer.
            </p>
          </div>
        )}
      </div>

      <Transition.Root
        show={searchQuery === null ? false : true}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setSearchQuery(null);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-2xl bg-greyish p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <TableStaticInfinite
                  data={customers}
                  columns={columns}
                  loading={loading}
                  totalRows={customers.length}
                  pageLimit={pageLimit}
                  state={{
                    rowSelection,
                  }}
                  enableRowSelection={(row) =>
                    !excludeIds.includes(Number(row.original.id))
                  }
                  onRowSelectionChange={setRowSelection}
                  getCoreRowModel={getCoreRowModel()}
                  enableGlobalFilter
                  enableHeader={false}
                  renderHeader={() => (
                    <Button
                      onClick={() => {
                        setSearchQuery(null);
                      }}
                    >
                      <ArrowsUpDownIcon className="mr-2 h-5 w-5" />
                      Done
                    </Button>
                  )}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
