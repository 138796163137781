import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import commonEn from "./locales/en/common.json";
import errorEn from "./locales/en/error.json";
import commonFr from "./locales/fr/common.json";
import errorFr from "./locales/fr/error.json";

const resources = {
  en: {
    common: commonEn,
    error: errorEn,
  },
  fr: {
    common: commonFr,
    error: errorFr,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ["common", "error"],
    defaultNS: "common",
    fallbackLng: "en",
    lng: "en",
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
