import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react";
import { InboxIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";

import { ErrorFallback, Head } from "../../../components/core";
import { Button } from "../../../components/form";
import { LimitBy, TableCursor } from "../../../components/table";
import { NotifyType, useNotifyContext } from "../../../contexts/NotifyContext";
import { AlertModal, AlertType, useAlert } from "../../../hooks/useAlert";
import { formatDate } from "../../../utils";
import { AuthShield } from "../../auth/core";
import { TreeItem } from "./components/types";

type IStoreMenu = {
  id: string;
  name: string;
  handle: string;
  menuItems: TreeItem[];
  createdAt: string;
  status: boolean;
};

const FETCH_STOREMENUS = gql`
  query FetchStoreNavigations {
    fetchStoreNavigations {
      id
      name
      handle
      menuItems {
        id
        linkType
        linkValue {
          id
          name
          handle
        }
        menuName
        subMenu {
          id
          linkType
          linkValue {
            id
            name
            handle
          }
          menuName
        }
      }
      createdAt
      status
    }
  }
`;

const MenuList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();

  const { data, loading, error, refetch } = useQuery<{
    fetchStoreNavigations: IStoreMenu[];
  }>(FETCH_STOREMENUS);

  const storeMenu = useMemo<IStoreMenu[]>(
    () =>
      data?.fetchStoreNavigations.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data?.fetchStoreNavigations]
  );

  const DELETE_STOREMENU = gql`
    mutation StoreMenuDelete($id: ID!) {
      storeMenuDelete(input: { id: $id }) {
        menu {
          id
          name
        }
      }
    }
  `;
  const [deleteStoreMenu] = useMutation(DELETE_STOREMENU, {
    refetchQueries: [{ query: FETCH_STOREMENUS }],
  });
  const handleDelete = async (id: string) => {
    return await deleteStoreMenu({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.storeMenuDelete) {
          const deletedStoreMenu = data.storeMenuDelete?.menu;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Menu ${deletedStoreMenu.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Menu delete failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Menu delete failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<IStoreMenu, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "handle",
        header: "Handle",
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.handle,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Active
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              Inactive
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableSorting: false,
        enableHiding: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-menus"]}>
              <Button
                type="link"
                href={`/store/menu/${props.row.original.id}`}
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-menus"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title="Store Menu"
        heading="Store Menu"
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: "Store Menu",
            href: "/store/menu",
          },
          {
            name: MenuListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {MenuListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {MenuListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-menus"]}>
              <Button type="link" href="/store/menu/add">
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add store menu
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={storeMenu}
          columns={columns}
          loading={loading}
          totalRows={storeMenu.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No store menus found"
          fallbackText="You can add a new store menu by clicking on add store menu button."
        />
      </div>
    </>
  );
};

export default MenuList;
export const MenuListResource: ResourceProps = {
  name: "Store Menu",
  description: "A list of store menus",
  access: ["read-menus"],
  path: "menu",
};
