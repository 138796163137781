import { gql } from "@apollo/client";

export const GET_DEPARTMENTS = gql`
  query FetchDepartments($status: Boolean) {
    fetchDepartments(status: $status) {
      id
      name
      handle
      description
      parent {
        id
        name
      }
      imageUrl
      createdAt
      updatedAt
      status
    }
  }
`;

export const ADD_DEPARTMENT = gql`
  mutation DepartmentCreate(
    $name: String!
    $handle: String!
    $description: String
    $parentId: Int
    $imageUrl: String
    $status: Boolean!
  ) {
    departmentCreate(
      input: {
        params: {
          name: $name
          handle: $handle
          description: $description
          parentId: $parentId
          imageUrl: $imageUrl
          status: $status
        }
      }
    ) {
      department {
        id
        name
        handle
        description
        parent {
          id
          name
        }
        imageUrl
        createdAt
        status
      }
    }
  }
`;

export const EDIT_DEPARTMENT = gql`
  mutation DepartmentUpdate(
    $id: ID!
    $name: String!
    $handle: String!
    $description: String
    $parentId: Int
    $imageUrl: String
    $status: Boolean!
  ) {
    departmentUpdate(
      input: {
        id: $id
        params: {
          name: $name
          handle: $handle
          description: $description
          parentId: $parentId
          imageUrl: $imageUrl
          status: $status
        }
      }
    ) {
      department {
        id
        name
        handle
        description
        parent {
          id
          name
        }
        imageUrl
        createdAt
        status
      }
    }
  }
`;

export const REMOVE_DEPARTMENT = gql`
  mutation DepartmentDelete($id: ID!) {
    departmentDelete(input: { id: $id }) {
      department {
        id
        name
      }
    }
  }
`;

export type Department = {
  id: string;
  name: string;
  handle: string;
  description: string;
  parent: any;
  imageUrl: string;
  createdAt: string;
  updatedAt: string;
  status: boolean;
};
