import { useMutation, useQuery } from "@apollo/client/react";
import {
  ArrowPathIcon,
  CalendarDaysIcon,
  EyeIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import {
  Fragment,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DebounceInput } from "react-debounce-input";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  RouteObject,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { useReactToPrint } from "react-to-print";

import {
  ErrorFallback,
  Head,
  NotAuthorized,
  NotFound,
} from "../../../components/core";
import {
  Button,
  FieldDatepicker,
  FieldDriverImage,
  selectStyles,
  SelectWrapper,
} from "../../../components/form";
import {
  IndeterminateCheckbox,
  TableStaticInfinite,
} from "../../../components/table";
import {
  DeliveryAllocation,
  GET_DELIVERY_ALLOCATIONS,
  UPDATE_OPERATOR_ALLOCATION,
} from "../../../graphql/fleets/delivery-allocations";
import {
  DeliveryRun,
  GET_DELIVERYRUNS,
} from "../../../graphql/fleets/settings/delivery-runs";
import { renderStatus } from "../../../graphql/sales/orders";
import { IconFilterClear } from "../../../svgs";
import { classNames } from "../../../utils";
import { isAuthorizedForResource, useAuth } from "../../auth";
import { DeliveryAllocationsDetailResource } from "./Detail";
import { AllocationsPrint } from "./template/Print";

const DeliveryAllocationsDetail = lazy(() => import("./Detail"));

const DeliveryAllocationsList = ({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) => {
  const { t } = useTranslation();

  const [refetching, setRefetching] = useState<boolean>(false);

  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string | null>(
    searchParams.get("query") || ""
  );
  const [deliveryRun, setDeliveryRun] =
    useState<SingleValue<OptionProps>>(null);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  const [rowSelection, setRowSelection] = useState({});

  const [printData, setPrintData] = useState<DeliveryAllocation[]>([]);
  const printComponentRef = useRef(null);
  const [loadingPrints, setLoadingPrints] = useState(false);

  const ordersPrintContent = useCallback(() => {
    return printComponentRef.current;
  }, []);

  const handlePrints = useReactToPrint({
    content: ordersPrintContent,
    documentTitle: `Alpha Fresh - Delivery Allocations - ${new Date().toISOString()}`,
    onBeforeGetContent: () => {
      setLoadingPrints(true);
    },
    onAfterPrint: () => {
      setLoadingPrints(false);
    },
    removeAfterPrint: true,
  });

  useEffect(() => {
    setSearchQuery(searchParams.get("query") || "");
  }, [searchParams]);

  const handleClearFilters = useCallback(() => {
    setSearchParams({ query: "" });
    setDeliveryRun(null);
    setFromDate("");
    setToDate("");
  }, [setSearchParams]);

  const {
    data: deliveryRunsData,
    loading: deliveryRunsLoading,
    refetch: deliveryRunsRefetch,
  } = useQuery(GET_DELIVERYRUNS);

  const searchFilter: string[] = [];
  if (searchQuery) searchFilter.push("keyword");
  if (deliveryRun) searchFilter.push("deliveryrun");
  if (fromDate) searchFilter.push("fromdate");
  if (toDate) searchFilter.push("todate");

  const { data, loading, error, refetch } = useQuery<{
    fetchDeliveryAllocations: DeliveryAllocation[];
  }>(GET_DELIVERY_ALLOCATIONS, {
    variables: {
      searchQuery,
      searchFilter,
      deliveryrunId: deliveryRun ? deliveryRun.value : null,
      fromDate,
      toDate,
    },
  });

  const [updateOperatorAllocation] = useMutation(UPDATE_OPERATOR_ALLOCATION, {
    refetchQueries: [
      {
        query: GET_DELIVERY_ALLOCATIONS,
        variables: {
          searchQuery,
          searchFilter,
          deliveryrunId: null,
          fromDate,
          toDate,
        },
      },
    ],
  });

  const handleUpdateOperatorAllocation = useCallback(
    (operatorId: number | null, ids: string[]) => {
      updateOperatorAllocation({
        variables: {
          operatorId,
          ids,
        },
      })
        .then(async ({ data }) => {
          if (data?.operatorAllocationUpdate) {
            await refetch();
            toast.success("Operator allocation updated successfully");
          } else {
            toast.error("Failed to update operator allocation");
          }
        })
        .catch((e) => {
          toast.error("Failed to update operator allocation");
        });
    },
    [refetch, updateOperatorAllocation]
  );

  const deliveryAllocations = useMemo<DeliveryAllocation[]>(
    () => data?.fetchDeliveryAllocations || [],
    [data?.fetchDeliveryAllocations]
  );

  const filtering: boolean = useMemo(
    () =>
      searchFilter.length > 0 ||
      fromDate !== "" ||
      toDate !== "" ||
      deliveryRun ||
      !!searchQuery
        ? true
        : false,

    [searchFilter.length, fromDate, toDate, deliveryRun, searchQuery]
  );

  const handleRefetch = useCallback(() => {
    setRefetching(true);
    refetch().finally(() => {
      setRefetching(false);
    });
  }, [refetch]);

  const columns = useMemo<ColumnDef<DeliveryAllocation>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        id: "select",
        header: "Select",
        cell: ({ row }) => {
          return (
            <div className="px-1">
              <IndeterminateCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          );
        },
        size: 20,
        enableHiding: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Order Number",
        enableHiding: false,
      },
      {
        accessorKey: "customer",
        header: () => <span className="whitespace-nowrap">Customer</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.customer.customerName}
          </span>
        ),
        size: 200,
      },
      {
        accessorKey: "deliveryRun",
        header: () => <span className="whitespace-nowrap">Delivery Run</span>,
        cell: (props) => (
          <span className="whitespace-nowrap">
            {props.row.original.deliveryRun}
          </span>
        ),
      },
      {
        accessorKey: "status",
        header: () => <span className="whitespace-nowrap">Status</span>,
        cell: (props) => renderStatus(props.row.original.status),
      },
      {
        accessorKey: "operator",
        header: () => <span className="whitespace-nowrap">Operator</span>,
        cell: (props) => (
          <div className="w-56 max-w-full">
            <FieldDriverImage
              title={t("text_operator")}
              id={`operator-${props.row.original.id}`}
              value={
                props.row.original.operator
                  ? parseInt(props.row.original.operator.id)
                  : null
              }
              onChange={(value) => {
                handleUpdateOperatorAllocation(value, [props.row.original.id]);
              }}
              disabled={loading}
              isLabel={false}
            />
          </div>
        ),
        size: 200,
      },
      {
        accessorKey: "notes",
        header: () => (
          <span className="whitespace-nowrap">Delivery Instructions</span>
        ),
        cell: (props) => props.row.original.notes,
      },
      {
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <Button
              type="link"
              href={`/fleets/delivery-allocations/${props.row.original.id}`}
              variant="icon"
              className="text-blue-500 hover:text-blue-600"
            >
              <EyeIcon aria-hidden="true" className="text-md h-4 w-4" />
              <span className="sr-only">
                View, {props.row.original.orderNumber}
              </span>
            </Button>
          </div>
        ),
      },
    ],
    [handleUpdateOperatorAllocation, loading, t]
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={DeliveryAllocationsResource.name}
        heading={DeliveryAllocationsResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: DeliveryAllocationsResource.name,
            href: null,
          },
        ]}
      />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {DeliveryAllocationsResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {DeliveryAllocationsResource.description}
            </p>
          </div>
        </div>
        <TableStaticInfinite
          data={deliveryAllocations}
          columns={columns}
          loading={loading}
          totalRows={deliveryAllocations.length}
          filtering={filtering}
          state={{
            rowSelection,
          }}
          enableRowSelection={true}
          enableMultiRowSelection={true}
          onRowSelectionChange={setRowSelection}
          getCoreRowModel={getCoreRowModel()}
          renderAction={(table) => {
            const selectedRows = table
              .getSelectedRowModel()
              .rows.map((row) => row.original);
            return (
              <Fragment>
                <div className="mb-4 mt-2 flex justify-end sm:-mt-10">
                  <div className="w-56 max-w-full">
                    <FieldDriverImage
                      title={t("text_select_bulck_operator")}
                      id="assign-operator"
                      value={null}
                      onChange={(value) => {
                        handleUpdateOperatorAllocation(value, [
                          ...selectedRows.flatMap((row) => row.id),
                        ]);
                      }}
                      disabled={loading || !selectedRows.length}
                      isLabel={false}
                      placeholder="Assign operator"
                    />
                  </div>
                </div>
                <div className="mb-2 flex flex-wrap items-start space-x-2 xl:flex-nowrap xl:items-stretch">
                  <div className="flex flex-1 items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <MagnifyingGlassIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <DebounceInput
                      type="search"
                      className={classNames(
                        "ml-1 h-11 w-full border-none bg-transparent p-2 text-sm font-normal text-black",
                        "shadow-none outline-none focus:ring-0",
                        "placeholder-black/50 placeholder-opacity-100"
                      )}
                      placeholder="Search for name"
                      minLength={2}
                      debounceTimeout={300}
                      value={searchQuery ?? ""}
                      onChange={(e) => {
                        setSearchParams({
                          query: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <FunnelIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <SelectWrapper className="w-full text-sm font-normal text-primary-900/70">
                      <Select
                        className="h-11 w-full xl:whitespace-nowrap"
                        closeMenuOnSelect={true}
                        styles={selectStyles}
                        value={deliveryRun}
                        options={deliveryRunsData?.fetchDeliveryRuns.map(
                          (d: DeliveryRun) => ({
                            value: d.id,
                            label: d.name,
                          })
                        )}
                        onChange={setDeliveryRun}
                        isLoading={deliveryRunsLoading}
                        placeholder="Filter by delivery run"
                        isClearable
                        onMenuOpen={() => {
                          deliveryRunsRefetch();
                        }}
                      />
                    </SelectWrapper>
                  </div>

                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <CalendarDaysIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <FieldDatepicker
                      title={t("text_start_date")}
                      name="startDate"
                      onChange={(value) => {
                        if (!Array.isArray(value)) {
                          setFromDate(
                            value ? new Date(value).toISOString() : ""
                          );
                        }
                      }}
                      selected={fromDate ? new Date(fromDate) : null}
                      isClearable
                      isLabel={false}
                      placeholderText="Start date"
                      className="border-none focus-visible:ring-0"
                      disableIcon
                    />
                  </div>
                  <div className="flex items-center rounded-lg border border-gray-200 bg-white pl-3 text-black/50">
                    <CalendarDaysIcon className="h-4 w-4 min-w-[1rem] text-black/70" />
                    <span className="ml-3 block h-4 w-[1px] bg-gray-400"></span>
                    <FieldDatepicker
                      title={t("text_end_date")}
                      name="endDate"
                      onChange={(value) => {
                        if (!Array.isArray(value)) {
                          setToDate(value ? new Date(value).toISOString() : "");
                        }
                      }}
                      selected={toDate ? new Date(toDate) : null}
                      minDate={fromDate ? new Date(fromDate) : null}
                      isClearable
                      isLabel={false}
                      placeholderText="End date"
                      className="border-none focus-visible:ring-0"
                      disableIcon
                    />
                  </div>
                  <Button
                    variant="icon"
                    className="relative flex h-11 w-11 items-center justify-center rounded-md text-gray-600 transition-all hover:bg-primary-200 hover:text-primary-900"
                    onClick={handleClearFilters}
                    disabled={loading}
                  >
                    <IconFilterClear className="h-5 w-5" />
                    <span className="sr-only">Clear Filters</span>
                  </Button>
                  <Button
                    onClick={() => {
                      const printData = table
                        .getSelectedRowModel()
                        .rows.map((row) => row.original);
                      setPrintData(printData);
                      setTimeout(() => {
                        handlePrints();
                      }, 250);
                    }}
                    disabled={
                      loading ||
                      table.getSelectedRowModel().rows.length === 0 ||
                      refetching
                    }
                  >
                    <PrinterIcon
                      aria-hidden="true"
                      className="text-md mr-2 h-5 w-5"
                    />
                    {loadingPrints ? "Printing..." : "Print Selected"}
                  </Button>
                  <Button
                    variant="icon"
                    onClick={handleRefetch}
                    disabled={loading}
                    className="flex h-11 items-center justify-center px-2 text-blue-700"
                  >
                    <ArrowPathIcon
                      aria-hidden="true"
                      className={classNames(
                        "h-5 w-5",
                        refetching ? "animate-spin" : ""
                      )}
                    />
                    <span className="sr-only">
                      Refresh delivery allocations
                    </span>
                  </Button>
                </div>
              </Fragment>
            );
          }}
          enableAction
        />
      </div>
      <div className="hidden">
        <div ref={printComponentRef}>
          <AllocationsPrint data={printData} />
        </div>
      </div>
      <Outlet />
    </>
  );
};

function DeliveryAllocations({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) {
  const { currentRole } = useAuth();
  let routes: RouteObject[] = [
    {
      path: "/",
      element: <DeliveryAllocationsList breadcrumbs={breadcrumbs} />,
      children: [
        {
          path: DeliveryAllocationsDetailResource.path,
          element: isAuthorizedForResource(
            currentRole,
            DeliveryAllocationsDetailResource.access
          ) ? (
            <DeliveryAllocationsDetail />
          ) : (
            <NotAuthorized
              error={
                new Error(DeliveryAllocationsDetailResource.access.join(", "))
              }
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  return useRoutes(routes);
}
export default DeliveryAllocations;

export const DeliveryAllocationsResource: ResourceProps = {
  name: "Vehicle Assignment",
  description: "Manage your vehicle assignments here.",
  access: ["read-deliveryAllocations"],
  path: "delivery-allocations/*",
};
