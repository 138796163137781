import { Popover, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import React, { Fragment } from "react";

import { classNames } from "../../utils";

type PopoverProps = React.ComponentProps<typeof Popover.Button>;
type ButtonDotsProps = PopoverProps & {
  children: React.ReactNode;
};

export const itemClasses =
  "group flex w-full items-center justify-start rounded-md px-2.5 py-1.5 text-sm text-gray-700 transition ease-in-out hover:bg-primary-700/10 hover:text-primary-700 md:px-3 md:py-2";

export function ButtonDots({ className, children, ...props }: ButtonDotsProps) {
  return (
    <Popover className="relative inline-block text-left normal-case">
      <div>
        <Popover.Button
          className={classNames(
            "-my-2 flex items-center rounded-lg px-2  py-4 text-gray-700 hover:text-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-700",
            className
          )}
          {...props}
        >
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Popover.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute right-0 z-20 min-w-[9rem] origin-top-right rounded-bl-2xl rounded-br-2xl rounded-tl-2xl bg-white px-1.5 py-2 shadow-[0px_10px_70px_rgba(0,0,0,0.15)] ring-1 ring-black ring-opacity-5 focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-50 md:px-2.5 md:py-3">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 110.9 96"
            enableBackground="new 0 0 110.9 96"
            className="absolute -top-3 right-0 -z-0"
            fill="#ffffff"
            style={{
              filter: "drop-shadow(0px -1px 1px rgba(0,0,0,0.15))",
            }}
          >
            <polygon points="110.9,0 0,96 110.9,96 " />
          </svg>
          <div className="py-1">{children}</div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
