import { Outlet } from "react-router-dom";

import HeaderLayout from "./HeaderLayout";

export default function PageLayout() {
  return (
    <div className="max-w-xxl pb-6 lg:mx-auto xl:px-8 xl:pb-8">
      <HeaderLayout />
      <Outlet />
    </div>
  );
}
