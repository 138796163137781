import { useMutation, useQuery } from "@apollo/client/react";
import { Dialog, Transition } from "@headlessui/react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { ColumnDef, getCoreRowModel } from "@tanstack/react-table";
import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ErrorFallback, Head } from "../../../../../components/core";
import { Button } from "../../../../../components/form";
import { LimitBy, TableCursor } from "../../../../../components/table";
import {
  NotifyType,
  useNotifyContext,
} from "../../../../../contexts/NotifyContext";
import {
  ADD_VEHICLESTATUS,
  EDIT_VEHICLESTATUS,
  GET_VEHICLESTATUSES,
  REMOVE_VEHICLESTATUS,
  type VehicleStatus,
} from "../../../../../graphql/fleets/vehicles/sections/status";
import { AlertModal, AlertType, useAlert } from "../../../../../hooks/useAlert";
import { formatDate } from "../../../../../utils";
import { AuthShield } from "../../../../auth/core";
import Form from "./components/Form";

const VehicleStatusList = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  const [pageLimit, setPageLimit] = useState<LimitBy>(LimitBy.L3);
  const { addNotify } = useNotifyContext();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery<{
    fetchVehicleStatuses: VehicleStatus[];
  }>(GET_VEHICLESTATUSES);
  const vehicleStatuses = useMemo(
    () =>
      data?.fetchVehicleStatuses.flatMap((item) => ({
        ...item,
        createdAt: formatDate(item.createdAt),
      })) || [],
    [data?.fetchVehicleStatuses]
  );

  const [activeVehicleStatus, setActiveVehicleStatus] = useState<
    VehicleStatus | undefined
  >(undefined);
  const [newVehicleStatus, setNewVehicleStatus] = useState<boolean | undefined>(
    undefined
  );

  const [updateVehicleStatus] = useMutation(EDIT_VEHICLESTATUS, {
    refetchQueries: [{ query: GET_VEHICLESTATUSES }],
  });
  const handleUpdate = (
    values: {
      name: string;
      color: string;
      default: boolean;
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if (!activeVehicleStatus) return;
    updateVehicleStatus({
      variables: {
        id: activeVehicleStatus.id,
        name: values.name,
        color: values.color,
        default: values.default,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleStatusUpdate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vehicle status updated successfully",
          });
          setActiveVehicleStatus(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vehicle status update failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Vehicle status update failed",
          message: error.message,
        });
      });
  };

  const [createVehicleStatus] = useMutation(ADD_VEHICLESTATUS, {
    refetchQueries: [{ query: GET_VEHICLESTATUSES }],
  });
  const handleCreate = (
    values: {
      name: string;
      color: string;
      default: boolean;
    },
    actions: { setSubmitting: (arg0: boolean) => void }
  ) => {
    createVehicleStatus({
      variables: {
        name: values.name,
        color: values.color,
        default: values.default,
      },
    })
      .then(({ data }) => {
        actions.setSubmitting(false);
        if (data?.vehicleStatusCreate) {
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: "Vehicle status created successfully",
          });
          setNewVehicleStatus(undefined);
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vehicle status create failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        actions.setSubmitting(false);
        addNotify({
          type: NotifyType.ERROR,
          title: "Vehicle status create failed",
          message: error.message,
        });
      });
  };

  const [deleteVehicleStatus] = useMutation(REMOVE_VEHICLESTATUS);
  const handleDelete = async (id: string) => {
    deleteVehicleStatus({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        if (data?.vehicleStatusDelete) {
          const deletedVehicleStatus = data.vehicleStatusDelete?.vehicleStatus;
          refetch();
          addNotify({
            type: NotifyType.SUCCESS,
            title: `Vehicle status ${deletedVehicleStatus.name} deleted`,
          });
        } else {
          addNotify({
            type: NotifyType.ERROR,
            title: "Vehicle status deletion failed",
            message: "Something went wrong, please try again later",
          });
        }
      })
      .catch((error) => {
        addNotify({
          type: NotifyType.ERROR,
          title: "Vehicle status deletion failed",
          message: error.message,
        });
      });
  };

  type AlertProps = {
    id: string;
    name: string;
  };
  const [alert, setAlert] = useState<AlertProps | null>(null);
  const ResponseAlert = useAlert({
    open: alert ? true : false,
    title: "Do you want to proceed with this operation?",
    message: (
      <p className="text-sm text-gray-500">
        Are you sure you wish to delete{" "}
        <b className="text-gray-900">{alert?.name}</b> permanently. This action
        can't be undone. All records related to{" "}
        <b className="text-gray-900">{alert?.name}</b> will be deleted.
      </p>
    ),
    type: AlertType.DANGER,
    modal: AlertModal.CENTERED_DOUBLE_ACTION,
    delay: 3000,
    onConfirm: async () => {
      await handleDelete(alert?.id!);
      setAlert(null);
    },
    onCancel: () => {
      setAlert(null);
    },
  });

  const columns = useMemo<ColumnDef<VehicleStatus, any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 300,
        enableHiding: false,
        cell: (props) => (
          <span className="whitespace-nowrap">{props.getValue()}</span>
        ),
        accessorFn: (row) => row.name,
      },
      {
        accessorKey: "color",
        header: "Color",
        cell: (props) => (
          <span>
            <span
              className="mr-2 inline-block h-3 w-3 rounded-full align-middle"
              style={{
                backgroundColor: props.row.original.color,
              }}
            />
            <span className="whitespace-nowrap align-middle">
              {props.getValue()}
            </span>
          </span>
        ),
      },
      {
        accessorKey: "default",
        header: "Default",
        cell: (info) =>
          info.getValue() ? (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-medium leading-5 text-green-800">
              Yes
            </span>
          ) : (
            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-medium leading-5 text-red-800">
              No
            </span>
          ),
      },
      {
        id: "actions",
        header: "Actions",
        enableHiding: false,
        enableSorting: false,
        enableGlobalFilter: false,
        size: 220,
        cell: (props) => (
          <div className="flex space-x-2 md:space-x-4">
            <AuthShield access={["update-vehicle"]}>
              <Button
                variant="icon"
                className="text-blue-500 hover:text-blue-600"
                onClick={() => setActiveVehicleStatus(props.row.original)}
              >
                <PencilIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">Edit, {props.row.original.name}</span>
              </Button>
            </AuthShield>
            <AuthShield access={["destroy-vehicle"]}>
              <Button
                variant="icon"
                className="text-red-500 hover:text-red-600"
                onClick={() => {
                  setAlert({
                    id: props.row.original.id!,
                    name: props.row.original.name,
                  });
                }}
              >
                <TrashIcon aria-hidden="true" className="text-md h-4 w-4" />
                <span className="sr-only">
                  Delete, {props.row.original.name}
                </span>
              </Button>
            </AuthShield>
          </div>
        ),
      },
    ],
    []
  );

  if (error) return <ErrorFallback error={error} />;

  return (
    <>
      <Head
        title={VehicleStatusListResource.name}
        heading={VehicleStatusListResource.name}
        breadcrumbs={[
          ...breadcrumbs,
          {
            name: VehicleStatusListResource.name,
            href: null,
          },
        ]}
      />
      <ResponseAlert />
      <div className="rounded-xl bg-greyish p-5">
        <div className="mb-5 sm:flex sm:items-end">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-medium text-gray-900">
              {VehicleStatusListResource.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {VehicleStatusListResource.description}
            </p>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-3">
            <AuthShield access={["create-vendors"]}>
              <Button onClick={() => setNewVehicleStatus(true)}>
                <PlusCircleIcon className="mr-2 h-5 w-5" />
                Add Vehicle Status
              </Button>
            </AuthShield>
          </div>
        </div>

        <TableCursor
          data={vehicleStatuses}
          columns={columns}
          loading={loading}
          totalRows={vehicleStatuses.length}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          getCoreRowModel={getCoreRowModel()}
          enableGlobalFilter={true}
          fallbackTitle="No vehicle status found"
          fallbackText="You can add a new vehicle status by clicking on add vehicle status button."
        />
      </div>

      <Transition.Root
        show={activeVehicleStatus ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setActiveVehicleStatus(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-lg transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="Edit Vehicle Status - "
                  initialValues={{
                    id: activeVehicleStatus?.id ?? undefined,
                    name: activeVehicleStatus?.name ?? "",
                    color: activeVehicleStatus?.color ?? "",
                    default: activeVehicleStatus?.default ?? true,
                  }}
                  onSubmit={handleUpdate}
                  submitLabel={t("text_update")}
                  onCancel={() => setActiveVehicleStatus(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={newVehicleStatus ? true : false}
        as={Fragment}
        appear
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewVehicleStatus(undefined);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-lg transform divide-y divide-gray-100 rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Form
                  heading="New Vehicle Status - "
                  initialValues={{
                    name: "",
                    color: "",
                    default: true,
                  }}
                  onSubmit={handleCreate}
                  submitLabel={t("text_create")}
                  onCancel={() => setNewVehicleStatus(undefined)}
                  cancelLabel={t("text_cancel")}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default VehicleStatusList;
export const VehicleStatusListResource: ResourceProps = {
  name: "Vehicle Status",
  description: "A list of vehicle status",
  access: ["read-vehicle"],
  path: "status",
};
