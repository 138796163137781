import { RadioGroup, Switch } from "@headlessui/react";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { Spinner } from "../../../../../animations";
import {
  Button,
  Field,
  FieldTableProducts,
} from "../../../../../components/form";
import { PreviewProduct } from "../../../../../graphql/inventory/products";
import { classNames } from "../../../../../utils";

type PricingCategoryCreate = {
  name: string;
  description: string;
  productType: number;
  productIds: number[];
  status: boolean;
};

export default function FormCreate({
  initialValues,
  onSubmit,
  actionLabel,
}: {
  initialValues: PricingCategoryCreate;
  onSubmit: (values: any, actions: any) => void;
  actionLabel: string;
}) {
  const { t } = useTranslation();

  const [products, setProducts] = useState<PreviewProduct[]>([]);

  const PricingCategoryCreateSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(80, "Too Long!")
      .required("Required"),
    description: Yup.string().nullable(),
    status: Yup.boolean().required("Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: PricingCategoryCreateSchema,
    onSubmit: onSubmit,
  });

  const { errors, touched, isSubmitting } = formik;

  const productTypes = [
    {
      label: "All Products",
      value: 0,
      description: "Add all products to this pricing level.",
    },
    {
      label: "Specific Products",
      value: 1,
      description: "Add specific products to this pricing level.",
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="grid grid-cols-12 gap-6 sm:grid-cols-6">
        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <Field
            title={t("text_name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            touched={touched.name}
            errors={errors.name}
          />
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <Field
            title={t("text_description")}
            name="description"
            type="textarea"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            touched={formik.touched.description}
            errors={formik.errors.description}
          />
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-12">
          <RadioGroup
            value={formik.values.productType}
            onChange={(value: number) => {
              formik.setFieldValue("productType", value);
            }}
          >
            <RadioGroup.Label className="text-sm font-medium text-gray-900">
              Product Type
            </RadioGroup.Label>

            <div className="isolate mt-1 -space-y-px rounded-md bg-white">
              {productTypes.map((type, typeIdx) => (
                <RadioGroup.Option
                  key={type.label}
                  value={type.value}
                  className={({ checked }) =>
                    classNames(
                      typeIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                      typeIdx === productTypes.length - 1
                        ? "rounded-bl-md rounded-br-md"
                        : "",
                      checked
                        ? "z-10 border-primary-200 bg-primary-50"
                        : "border-gray-200",
                      "relative flex cursor-pointer border p-4 focus:outline-none"
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked
                            ? "border-transparent bg-primary-600"
                            : "border-gray-300 bg-white",
                          active ? "ring-2 ring-primary-500 ring-offset-2" : "",
                          "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                        )}
                        aria-hidden="true"
                      >
                        <span className="h-1.5 w-1.5 rounded-full bg-white" />
                      </span>
                      <span className="ml-3 flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            checked ? "text-primary-900" : "text-gray-900",
                            "block text-sm font-medium"
                          )}
                        >
                          {type.label}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={classNames(
                            checked ? "text-primary-700" : "text-gray-500",
                            "block text-sm"
                          )}
                        >
                          {type.description}
                        </RadioGroup.Description>
                      </span>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>

        {formik.values.productType === 1 ? (
          <div className="col-span-12 sm:col-span-12 md:col-span-12">
            <FieldTableProducts
              title="Products"
              data={products}
              value={formik.values.productIds}
              onChange={(products) => {
                formik.setFieldValue(
                  "productIds",
                  products.map((product) => parseInt(product.id))
                );
                setProducts(products);
              }}
            />
          </div>
        ) : null}

        <div className="col-span-12 sm:col-span-6 md:col-span-3">
          <fieldset>
            <legend className="text-sm font-medium text-gray-900">
              {t("text_status")}
            </legend>
            <Switch.Group as="div" className="mt-1.5 inline-flex items-center">
              <Switch
                checked={formik.values.status}
                onChange={() => {
                  formik.setFieldValue("status", !formik.values.status);
                }}
                id="status"
                className={classNames(
                  formik.values.status ? "bg-primary-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    formik.values.status ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label
                passive
                htmlFor="status"
                className="mb-0 ml-2 block text-sm font-normal text-gray-700"
              >
                {formik.values.status ? t("text_active") : t("text_inactive")}
              </Switch.Label>
            </Switch.Group>
          </fieldset>
        </div>
      </div>

      <div className="grid-col mt-4 grid grid-cols-3 gap-4 border-t border-gray-200 py-4 text-right md:mt-6 md:py-6">
        <Link
          to="/inventory/pricing/pricing-categories"
          className="flex w-full"
        >
          <Button variant="secondary" className="w-full justify-center">
            {t("text_cancel")}
          </Button>
        </Link>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Spinner />
              {t("text_processing")}
            </>
          ) : (
            actionLabel
          )}
        </Button>
      </div>
    </form>
  );
}
