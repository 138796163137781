import { gql } from "@apollo/client";

export const GET_VENDORS = gql`
  query FetchVendors {
    fetchVendors {
      id
      companyName
      email
      phoneNumber
      vendorCode
      webAddress
      vendorType
      address
      comments
      createdAt
      updatedAt
      status
    }
  }
`;

export const REMOVE_VENDOR = gql`
  mutation VendorDelete($id: ID!) {
    vendorDelete(input: { id: $id }) {
      vendor {
        id
        companyName
      }
    }
  }
`;

export const ADD_VENDOR = gql`
  mutation VendorCreate(
    $companyName: String
    $email: String!
    $phoneNumber: String
    $webAddress: String
    $vendorType: Int!
    $address: String
    $comments: String
    $status: Boolean!
  ) {
    vendorCreate(
      input: {
        params: {
          companyName: $companyName
          email: $email
          phoneNumber: $phoneNumber
          webAddress: $webAddress
          vendorType: $vendorType
          address: $address
          comments: $comments
          status: $status
        }
      }
    ) {
      vendor {
        id
        companyName
        email
        phoneNumber
        vendorCode
        webAddress
        vendorType
        address
        comments
        status
      }
    }
  }
`;

export const EDIT_VENDOR = gql`
  mutation VendorUpdate(
    $id: ID!
    $companyName: String
    $email: String!
    $phoneNumber: String
    $webAddress: String
    $vendorType: Int!
    $address: String
    $comments: String
    $status: Boolean!
  ) {
    vendorUpdate(
      input: {
        id: $id
        params: {
          companyName: $companyName
          email: $email
          phoneNumber: $phoneNumber
          webAddress: $webAddress
          vendorType: $vendorType
          address: $address
          comments: $comments
          status: $status
        }
      }
    ) {
      vendor {
        id
        companyName
        email
        phoneNumber
        vendorCode
        webAddress
        vendorType
        address
        comments
        status
      }
    }
  }
`;

export const GET_VENDOR_BY_ID = gql`
  query FetchVendor($id: ID!) {
    fetchVendor(id: $id) {
      id
      companyName
      email
      phoneNumber
      vendorCode
      webAddress
      vendorType
      address
      comments
      status
    }
  }
`;

export type Vendor = {
  id: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  vendorCode: string;
  webAddress: string;
  vendorType: number;
  address: string;
  comments: string;
  createdAt: string;
  updatedAt: string;
  status: boolean;
};
